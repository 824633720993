import { NavLink } from "react-router-dom";
import styled from "styled-components";
import pluse from "../../../../../assets/pluse.svg";

const AddLead = () => {
  return (
    <AddLeadCont>
      <NavLink to="/crm/addlead" id="newLead">
        <div className="icon">
          <img src={pluse} alt="plus" />
        </div>
        <div className="text">Create New Lead</div>
      </NavLink>
    </AddLeadCont>
  );
};

const AddLeadCont = styled.div`
  cursor: pointer;
  background: #f6f6f6;
  border-radius: 10px;
  text-align: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem 1rem 0 1rem;
  font-size: 12px;
  box-shadow: 5px 5px 6px #dadada, -5px -5px 6px #ffffff;

  text-transform: capitalize;
  .text {
    color: #1dbce5;
  }
`;
export default AddLead;
