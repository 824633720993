import styled from "styled-components";

export const BigSection = styled.div`
  .first {
    padding: 2rem 3rem;
  }
  .container {
    width: 100%;
    display: flex;
    flex-direction: row;
    /* padding: 2rem 3rem; */
    // margin: 0 auto;

    form {
      width: 100%;
      padding: 1rem;
      div {
        width: 100%;
      }
    }
    .btns {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      padding: 3rem 0;
      button {
        cursor: pointer;
        padding: 12px 24px;
        gap: 8px;
        border: 1px;
        border: 1px solid #000000;
        background: #000000;
        color: #ffffff;
        width: 100%;
        //styleName: Text/Regular/Normal;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        // text-align: left;
      }
      button:disabled,
      button[disabled] {
        border: 1px solid #999999;
        background-color: #cccccc;
        color: #666666;
      }
    }
  }
`;
