import { ArrowBackIos } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import addSolarAction from "../../../../../Redux/Actions/addSolarAction";
import BatteryForm from "./BatteryForm/BatteryForm";
import RoofingForm from "./RoofingForm/RoofingForm";
import SolarFormProposal from "./SolarForm/SolarFormPropsals";
import NewProposal from "../../../../newProposal";

const AddProposals = () => {
  const { id } = useParams();

  const location = useLocation();
  const path = location.pathname.split("/");

  console.log(path);
  const getRoutForm = (id) => {
    if (id === "1") {
      return <SolarFormProposal id={id} />;
      // return <NewProposal id={id} />;
    } else if (id === "2") {
      return <RoofingForm id={id} />;
    } else if (id === "7") {
      return <BatteryForm id={id} />;
    } else {
      return <p>Not Found </p>;
    }
  };

  return (
    <MainProposals>
      <Button className="btn-proposals" startIcon={<ArrowBackIos />}>
        <Link to="/crm/leads"> back to leads</Link>
      </Button>

      <div className="mainForm">
        <h1>{id === 1 ? "SOlar" : ""} </h1>

        <section>{getRoutForm(id)}</section>
      </div>
    </MainProposals>
  );
};

const MainProposals = styled.section`
  width: 100%;
  min-height: 100vh;
  background: #fff;
  color: #111111;
  .btn-proposals {
    background: #1c8686;
    margin: 2rem 0 0 1rem;
    border-radius: 1rem;
    font-size: 0.7rem;
    color: #ffffff;
    span {
      margin-right: 0;
      margin-left: 2px;
    }
    a {
      color: #fff;
    }
  }
  .mainForm {
    width: 95%;

    min-height: 80vh;
    padding: 3rem 1rem;
    margin: 2rem auto;
    border-radius: 1.5rem;
    box-shadow: 0 5px 15px inset rgb(0 0 0 / 7%);
  }
`;

export default AddProposals;
