import { Button } from "@mui/material";
import styled from "styled-components";

export const SubmitButton = styled(Button)`
  && {
    border-radius: 2rem;
    padding: 0.7rem 3rem;
    background-size: 400% 100%;
    transition: all 0.4s ease-in-out;
    background-image: linear-gradient(
      132.6deg,
      rgba(71, 139, 214, 1) 23.3%,
      rgba(37, 216, 211, 1) 84.7%
    );
    color: white;
    &:hover {
      background-position: 100% 0;
    }
  }
`;
