import styled from "styled-components";

export const Bigsection = styled.div`
  img {
    width: 100%;
  }
  .MuiFormControlLabel-root {
    display: flex !important;
    padding-right: 1rem;
    img {
      /* object-fit: cover; */
    }
  }
  .flex {
    display: flex;
    flex-direction: row;
    width: 100%;
    label {
      flex: 1 1 0px;
    }
  }
  @media (max-width: 950px) {
    .flex {
      flex-direction: column;
      label {
        flex: 1 1 20px;
        span {
          img {
            width: 50% !important;
          }
        }
      }
    }
  }
`;
