import styled from "styled-components";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";
import ModeStandbyIcon from "@mui/icons-material/ModeStandby";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
const Meeting = ({ time, topic, url, id }) => {
  return (
    <CardMeeting key={id}>
      <p className="title">
        <ModeStandbyIcon /> {topic}
      </p>
      <p>
        <AccessAlarmsIcon /> {time}
      </p>
      <a className="btn_meeting" href={url}>
        <CameraAltIcon /> join meeting
      </a>
    </CardMeeting>
  );
};

const CardMeeting = styled.div`
  padding: 1rem 1rem;
  background: #3c6fc7;
  border-radius: 1rem;
  text-align: center;
  .title {
    color: #fff;
    margin: 0.5rem 0;
    font-size: 1.2rem;
    svg {
      margin-right: 0.5rem;
    }
  }
  p {
    color: #fff;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    svg {
      margin-right: 0.5rem;
    }
  }

  .btn_meeting {
    width: 80%;
    margin: auto;
    background: #fff;
    border-radius: 0.5rem;
    color: #3c6fc7;
    padding: 0.3rem 1.2rem;
    font-size: 0.8rem;
    margin: 1rem auto;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 0.5rem;
    }
  }
`;
export default Meeting;
