import {
  AccountCircle,
  LocationOn,
  Mail,
  Phone,
  VerifiedUser,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import styled from "styled-components";

const TeamMemberLeads = ({ leads, leadId }) => {
  const LeadsList = leads?.map((lead) => {
    return (
      <div className="card" key={lead.id}>
        <Link to={`child=${lead.id}_${leadId}`}>
          <div className="card__items">
            <span className="key">
              <AccountCircle className="user" />
            </span>
            <span className="value name">
              {lead.first_name} {lead.last_name}{" "}
            </span>
          </div>
          <div className="card__items">
            <span className="key">
              <Phone className="phone" />
            </span>
            <span className="value">{lead.phone}</span>
          </div>
          <div className="card__items">
            <span className="key">
              <LocationOn className="mail" />
            </span>
            <span className="value">{lead.location}</span>
          </div>
        </Link>
      </div>
    );
  });
  return (
    <>
      <CardLeadStyle>
        {/* <div className="card">
        
            <div className="card__items">
              <span className="key">
                <AccountCircle className="user" />
              </span>
              <span className="value name">Ahmed Kabo</span>
            </div>
            <div className="card__items">
              <span className="key">
                <Phone className="phone" />
              </span>
              <span className="value">01141576780</span>
            </div>
            <div className="card__items">
              <span className="key">
                <LocationOn className="mail" />
              </span>
              <span className="value">ميت عقبة </span>
            </div>
          </div> */}
        {LeadsList}
      </CardLeadStyle>
    </>
  );
};

const CardLeadStyle = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  .card {
    background: #003c4c;
    border-radius: 1rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    padding: 0.8rem;
    &__heading {
      h3 {
        text-align: center;
        text-transform: capitalize;
        color: #003c4c;
        letter-spacing: 1.2px;
      }
    }
    &__items {
      display: flex;
      align-items: center;
      margin: 0.5rem;
      border-radius: 2rem;
      background: #7e7e7e4e;
      backdrop-filter: blur(20px);
      overflow: hidden;
      padding: 0.2rem 0.5rem;
      font-size: 0.75rem;
      color: #eeeeee;
      .key {
        display: flex;
        align-items: center;
        margin-right: 0.5rem;
        /* font-weight: bold; */
        svg {
          color: #fff;
          padding: 0.2rem;
          border-radius: 50%;
          font-size: 1rem;
          width: 30px;
          height: 30px;
          margin-right: 0.5rem;
          &.phone {
            background: #38afff;
          }
          &.user {
            background: #f14e64;
          }
          &.mail {
            background: #67ac5e;
          }
        }
      }
      .name {
        font-size: 1.1rem;
        color: #ffffff;
      }
    }
  }
`;

export default TeamMemberLeads;
