import Lottie from "react-lottie";
import styled from "styled-components";

const Loading = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require("../../animation/loading.json"),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <LodingStyled>
      <div className="loading-center">
        <Lottie options={defaultOptions} className="loading" />
      </div>
    </LodingStyled>
  );
};

const LodingStyled = styled.section`
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;

  .loading-center {
    /* width: 250px;
  height: 250px */

    overflow: hidden;
  }

  .loading {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: transparent;
  }
`;

export default Loading;
