import { Grid } from "@mui/material";
import styled from "styled-components";

import tri from "../../../../assets/unnamed.png";
import Solar from "../../../../assets/solar.jpg";
import { AccessTime, AttachFile } from "@mui/icons-material";
import { Link } from "react-router-dom";

const Training = () => {
  return (
    <BigSection>
      <FirstRow>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={10}
        >
          <Grid item lg={9} md={9} m={1}>
            <FirstRowLeftDiv>
              <FirstRowLeftDivContent>
                <Image src={tri}></Image>
                <FirstRowLeftDivHeader>APC Course</FirstRowLeftDivHeader>
                <FirstRowLeftDivLorem>
                  Introduction to APC Materials
                </FirstRowLeftDivLorem>
                <FirstRowLeftDivPaperAndUnit>
                  <span>
                    <AttachFile />
                  </span>
                  11 units <span style={{ padding: "0 35px" }}></span>{" "}
                  <span>
                    <AccessTime />
                  </span>
                  15.1 Minutes
                </FirstRowLeftDivPaperAndUnit>
                <FirstRowLeftDivLorem2>
                  Walking the customer through what you are there to do.
                  Breaking down their guard. Allowing you an opportunity to
                  explain simple value vs paying high electricity bills forever.
                </FirstRowLeftDivLorem2>
              </FirstRowLeftDivContent>
              <div style={{ textAlign: "center" }}>
                <Link to="videos">
                  <StartTrainingButton>Start Training</StartTrainingButton>
                </Link>
              </div>
            </FirstRowLeftDiv>
          </Grid>
          {/* <Grid item lg={6} md={6}>
            <FirstRowLeftDiv>
              <FirstRowLeftDivContent>
                <Image src={tri}></Image>
                <FirstRowLeftDivHeader>Solar</FirstRowLeftDivHeader>
                <FirstRowLeftDivLorem>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Mollitia, a? Lorem ipsum dolor sit amet consectetur,
                  adipisicing elit. Ea, repudiandae.
                </FirstRowLeftDivLorem>
                <FirstRowLeftDivPaperAndUnit>
                  <span>
                    <AttachFile />
                  </span>
                  11 units <span style={{ padding: "0 35px" }}></span>{" "}
                  <span>
                    <AccessTime />
                  </span>
                  15.1 hours
                </FirstRowLeftDivPaperAndUnit>
                <FirstRowLeftDivLorem2>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Nostrum magni obcaecati, repellat odit possimus deserunt
                  dicta. Sed aliquid quis accusamus omnis ipsa harum aspernatur
                  quos! Nam tempore id explicabo ratione.
                </FirstRowLeftDivLorem2>
              </FirstRowLeftDivContent>
              <div style={{ textAlign: "center" }}>
                <Link to="videos">
                  <StartTrainingButton>Start Training</StartTrainingButton>
                </Link>
              </div>
            </FirstRowLeftDiv>
          </Grid> */}
        </Grid>
      </FirstRow>
      <SecondRow>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={0}
        >
          <Grid item lg={6} md={6}>
            {" "}
            <SecondRowLeftDiv>
              <SolarImage src={Solar}></SolarImage>
            </SecondRowLeftDiv>
          </Grid>
          <Grid item lg={6} md={6}>
            <SecondRowRightDiv>
              <SecondRowRightDivContent>
                <SecondRowRightDivHeading>
                  Basic Sales 101{" "}
                </SecondRowRightDivHeading>
                <SecondRowRightDivlorem>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Temporibus, ab? Lorem ipsum dolor, sit amet consectetur
                  adipisicing elit. Qui, dicta.
                </SecondRowRightDivlorem>
                <SecondRowRightDivUnits>
                  <span>
                    <AttachFile />
                  </span>
                  11 units <span style={{ padding: "0 35px" }}></span>{" "}
                  <span>
                    <AccessTime />
                  </span>
                  15.1 hours
                </SecondRowRightDivUnits>
                <SecondRowRightDivlorem2>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia,
                  sit. Omnis, fuga! Incidunt blanditiis aut eos voluptates
                  dolorum accusantium? Laborum! Lorem ipsum dolor, sit amet
                  consectetur adipisicing elit. Dolorum ipsum iusto, voluptates,
                  aliquid ab doloremque porro maiores vel asperiores aspernatur
                  consequuntur assumenda totam fuga perspiciatis. Omnis minus
                  corrupti maiores voluptas.
                </SecondRowRightDivlorem2>
              </SecondRowRightDivContent>
              <div style={{ textAlign: "center", marginBottom: "6%" }}>
                <StartTrainingButton>Start Training</StartTrainingButton>
              </div>
            </SecondRowRightDiv>
          </Grid>
        </Grid>
      </SecondRow>
      <ThirdRow>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
        >
          <Grid item lg={6}>
            <ThirdRowleftDiv>
              <ThirdRowleftdivContent>
                <ThirdRowleftdivHeading>Finance Vs Cash</ThirdRowleftdivHeading>
                <ThirdRowleftdivlorem>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Architecto aperiam sapiente sint! Rem fugiat laudantium
                  tempora aperiam cum unde quae.
                </ThirdRowleftdivlorem>
                <ThirdRowleftdivunits>
                  {" "}
                  <span>
                    <AttachFile />
                  </span>
                  11 units <span style={{ padding: "0 35px" }}></span>{" "}
                  <span>
                    <AccessTime />
                  </span>
                  15.1 hours
                </ThirdRowleftdivunits>
                <ThirdRowleftdivlorem2>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Laborum molestiae repellat voluptates natus quod placeat
                  magni, ad aspernatur quidem hic est culpa reiciendis iste,
                  assumenda, blanditiis incidunt illo et eaque!
                </ThirdRowleftdivlorem2>
              </ThirdRowleftdivContent>
              <div style={{ textAlign: "center", marginBottom: "6%" }}>
                <StartTrainingButton>Start Training</StartTrainingButton>
              </div>
            </ThirdRowleftDiv>
          </Grid>
          <Grid item lg={6}>
            <ThirdRowRightdiv>
              <ThirdRowRightdivImage src={Solar}></ThirdRowRightdivImage>
            </ThirdRowRightdiv>
          </Grid>
        </Grid>
      </ThirdRow>
      <FourthRow>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
        >
          <Grid item lg={6}>
            <FourthRowleftDiv>
              <FourthRowleftImage src={Solar}></FourthRowleftImage>
            </FourthRowleftDiv>
          </Grid>
          <Grid item lg={6}>
            <FourthRowrightDiv>
              <FourthRowrightDivContent>
                <FourthRowrighHeading>HVC</FourthRowrighHeading>
                <FourthRowrightlorem>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi
                  culpa sequi obcaecati corrupti impedit quis. Deserunt quo
                  asperiores maxime corrupti!
                </FourthRowrightlorem>

                <FourthRowrightunits>
                  {" "}
                  <span>
                    <AttachFile />
                  </span>
                  11 units <span style={{ padding: "0 35px" }}></span>{" "}
                  <span>
                    <AccessTime />
                  </span>
                  15.1 hours
                </FourthRowrightunits>
                <FourthRowrightlorem2>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Perferendis modi praesentium amet commodi. Praesentium magnam
                  autem eveniet eos maiores asperiores sunt accusamus voluptates
                  quisquam quod voluptas voluptatibus, et dolores dolor.
                </FourthRowrightlorem2>
              </FourthRowrightDivContent>
              <div style={{ textAlign: "center", marginTop: "6%" }}>
                <StartTrainingButton>Start Training</StartTrainingButton>
              </div>
            </FourthRowrightDiv>
          </Grid>
        </Grid>
      </FourthRow>
      <SecondRow>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={0}
        >
          <Grid item lg={6} md={6}>
            {" "}
            <SecondRowLeftDiv>
              <SolarImage src={Solar}></SolarImage>
            </SecondRowLeftDiv>
          </Grid>
          <Grid item lg={6} md={6}>
            <SecondRowRightDiv>
              <SecondRowRightDivContent>
                <SecondRowRightDivHeading>
                  Basic Sales 101{" "}
                </SecondRowRightDivHeading>
                <SecondRowRightDivlorem>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Temporibus, ab? Lorem ipsum dolor, sit amet consectetur
                  adipisicing elit. Qui, dicta.
                </SecondRowRightDivlorem>
                <SecondRowRightDivUnits>
                  <span>
                    <AttachFile />
                  </span>
                  11 units <span style={{ padding: "0 35px" }}></span>{" "}
                  <span>
                    <AccessTime />
                  </span>
                  15.1 hours
                </SecondRowRightDivUnits>
                <SecondRowRightDivlorem2>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia,
                  sit. Omnis, fuga! Incidunt blanditiis aut eos voluptates
                  dolorum accusantium? Laborum! Lorem ipsum dolor, sit amet
                  consectetur adipisicing elit. Dolorum ipsum iusto, voluptates,
                  aliquid ab doloremque porro maiores vel asperiores aspernatur
                  consequuntur assumenda totam fuga perspiciatis. Omnis minus
                  corrupti maiores voluptas.
                </SecondRowRightDivlorem2>
              </SecondRowRightDivContent>
              <div style={{ textAlign: "center", marginBottom: "6%" }}>
                <StartTrainingButton>Start Training</StartTrainingButton>
              </div>
            </SecondRowRightDiv>
          </Grid>
        </Grid>
      </SecondRow>
    </BigSection>
  );
};

const BigSection = styled.section`
background-color:white;
margin:2%; 2%%;`;
const FirstRow = styled.div``;
const FirstRowLeftDiv = styled.div`
  background-color: #fafafa;
  box-shadow: 5px 5px 7px #efefef;
  border-radius: 15px;
`;
const FirstRowLeftDivContent = styled.div`
  padding-left: 5%;
  padding-right: 15%;
  padding-top: 6%;
  padding-bottom: 6%;
`;
const Image = styled.img`
  width: 15%;
  padding-bottom: 10px;
`;
const FirstRowLeftDivHeader = styled.p`
  font-size: 25px;
  font-weight: 650;
`;
const FirstRowLeftDivLorem = styled.p`
  font-weight: 600;
  font-size: 16px;
`;
const FirstRowLeftDivPaperAndUnit = styled.p``;
const FirstRowLeftDivLorem2 = styled.p`
  font-size: 12px;
  color: #6e6e6e;
`;
const StartTrainingButton = styled.button`
  background: transparent;
  padding: 10px 25px;
  cursor: pointer;
  color: #3d5ea7;
  border: 1px solid #3d5ea7;
  border-radius: 10px;
  margin-bottom: 25px;
`;
const SecondRow = styled.div`
  margin-top: 15%;
  width: 100%;
  border-radius: 15px;
  background-color: #fafafa;
  box-shadow: 5px 5px 7px #efefef;
`;
const SecondRowLeftDiv = styled.div`
  position: relative;
  width: 76%;
`;
const SolarImage = styled.img`
  position: absolute;
  width: 100%;
  height: 300px;
  left: 35%;
  top: 15px;
`;
const SecondRowRightDiv = styled.div`
  height: auto;
`;
const SecondRowRightDivContent = styled.div`
  padding-left: 35px;
  padding-right: 50px;
  background-color: #e7edfb;
  padding-top: 25px;
`;
const SecondRowRightDivHeading = styled.p`
  font-size: 25px;
  font-weight: bold;
`;
const SecondRowRightDivlorem = styled.p`
  font-weight: 600;
  font-size: 16px;
`;
const SecondRowRightDivUnits = styled.p``;
const SecondRowRightDivlorem2 = styled.p`
  font-size: 12px;
  color: #6e6e6e;
`;
const ThirdRow = styled.div`
  margin-top: 15%;
  width: 100%;
  border-radius: 15px;
  background-color: #fafafa;
  box-shadow: 5px 5px 7px #efefef;
`;
const ThirdRowRightdiv = styled.div`
  position: relative;
  width: 80%;
`;
const ThirdRowRightdivImage = styled.img`
  position: absolute;
  width: 100%;
  height: 300px;
  top: 20px;
  right: 5%;
`;
const ThirdRowleftDiv = styled.div``;
const ThirdRowleftdivContent = styled.div`
  background-color: #e7edfb;
  padding-left: 35px;
  padding-right: 50px;
  background-color: #e7edfb;
  padding-top: 25px;
`;

const ThirdRowleftdivHeading = styled.p`
  font-size: 25px;
  font-weight: bold;
`;
const ThirdRowleftdivlorem = styled.p`
  font-weight: 600;
  font-size: 16px;
`;
const ThirdRowleftdivunits = styled.p``;
const ThirdRowleftdivlorem2 = styled.p`
  font-size: 12px;
  color: #6e6e6e;
`;
const FourthRow = styled.div`
  margin-top: 15%;
  width: 100%;
  border-radius: 15px;
  background-color: #fafafa;
  box-shadow: 5px 5px 7px #efefef;
`;
const FourthRowleftDiv = styled.div`
  position: relative;
  width: 80%;
`;
const FourthRowleftImage = styled.img`
  position: absolute;
  width: 100%;
  height: 300px;
  left: 15%;
  top: 4%;
  background-size: cover;
`;
const FourthRowrightDiv = styled.div``;
const FourthRowrightDivContent = styled.div`
  padding-left: 35px;
  padding-right: 50px;
  padding-top: 25px;
`;
const FourthRowrighHeading = styled.p`
  font-size: 25px;
  font-weight: bold;
`;
const FourthRowrightlorem = styled.p`
  font-weight: 600;
  font-size: 16px;
`;
const FourthRowrightunits = styled.p``;
const FourthRowrightlorem2 = styled.p`
  font-size: 12px;
  color: #6e6e6e;
`;

export default Training;
