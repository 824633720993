import { ErrorMessage } from "@hookform/error-message";
import {
  TextField,
  InputLabel,
  FormHelperText,
  Box,
  InputAdornment,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import { MyInputLable } from "./standardLable";

const styles = {
  textField: {
    border: "none", // Remove the border
  },
};
const StanderInput = ({
  name,
  defaultValue,
  value,
  placeholder,
  type,
  Label,
  required,
  isSelect,
  children,
  disabled,
  icon,
  endIcon,
  style,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <InputFieldStyle>
      <MyInputLable
        sx={{ color: "#344054" }}
        htmlFor={name}
        required={required}
      >
        {Label}
      </MyInputLable>

      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextFieldStyle
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            fullWidth
            type={type}
            size="small"
            required={required}
            select={isSelect}
            disabled={disabled}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{icon}</InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">{endIcon}</InputAdornment>
              ),
              inputProps: { min: 0 },
            }}
            min="0"
            sx={{ border: "none", "& fieldset": { border: "none" } }}
            style={style}
          >
            {children}
          </TextFieldStyle>
        )}
      />
      <ErrorMessage
        render={({ message }) => (
          <FormHelperText sx={{ color: "#f00" }}>{message}</FormHelperText>
        )}
        errors={errors}
        name={name}
      />
    </InputFieldStyle>
  );
};

const InputFieldStyle = styled(Box)`
  && {
    /* height: 95px;
    overflow: hidden;
    width: 100%; */
  }
`;

const TextFieldStyle = styled(TextField)`
  && {
    .MuiOutlinedInput-root {
      width: 100%;
      background: var(--primary-whilte, #eef5ff);
      span {
        line-height: 0 !important;
      }
      border-radius: 0.375rem;

      .MuiOutlinedInput-input {
        /* padding: 13px 1rem; */
        background: var(--primary-whilte, #eef5ff);
      }
      .filedset {
        border: none;
      }
    }
    .MuiFilledInput-root {
      /* background: var(--primary-whilte, #f7f7fd); */
    }
  }
`;
export default StanderInput;
