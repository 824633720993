import { ThumbnailCard } from "../RightStyled";
import Achive from "../../../assets/trophy.png";
const Achievements = ({ SalseUser }) => {
  return (
    <>
      <ThumbnailCard>
        <div className="icon">
          <img src={Achive} alt="achivements" />
        </div>
        <div className="data">
          <div className="title">achivements </div>
          <div className="number">0</div>
        </div>
      </ThumbnailCard>
    </>
  );
};

export default Achievements;
