import { Button, Container, TextField } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "../../assets/lgBox.svg";
import { forgotUserPasAction } from "../../Redux/Actions/authActions";
const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState({
    email: "",
  });

  const hadelSubmit = (e) => {
    e.preventDefault();
    dispatch(forgotUserPasAction(data));
  };

  return (
    <ForgotPasswordStyled>
      <Container>
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="data">
          <h2>Forgot Password </h2>
          <form onSubmit={hadelSubmit}>
            <TextField
              type="email"
              fullWidth
              label="Enter your Mail"
              name="email"
              id="email"
              value={data.email}
              onChange={(e) => setData({ ...data, email: e.target.value })}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className="btn_submit"
            >
              Submit
            </Button>
          </form>
          <Link to="/login">Login</Link>
        </div>
      </Container>
    </ForgotPasswordStyled>
  );
};
const ForgotPasswordStyled = styled.div`
  .logo {
    background: #000;
    display: block;
    padding: 1rem 3rem;
    border-radius: 2rem;
    width: 300px;
    margin: 1rem auto;
    text-align: center;
  }
  .data {
    width: 60%;
    margin: 3rem auto;
    @media (max-width: 768px) {
      width: 90%;
    }
    h2 {
      margin: 1rem 0;
      color: #6eb5ca;
    }
    .btn_submit {
      margin: 2rem 0;
    }
  }
`;

export default ForgotPassword;
