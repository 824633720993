import {
  GET_ADDS_ON,
  GET_PANEL_MODEL,
  GET_ROOF_COLORS,
  GET_ROOF_TYPES,
  GET_SUB_SERVICES,
  GET_UTILITY,
} from "../Actions/type";

const initialState = {
  subServices: [],
  add_ons: [],
  utilities: [],
  Panels: [],
  roofTyps: [],
  colors: [],
  isUtility: true,
  isAddOns: true,
  isPanle: true,
  isRoof: true,
  isColor: true,
};

const servicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUB_SERVICES:
      return {
        ...state,
        subServices: action.sub_services,
      };
    case GET_ADDS_ON:
      return {
        ...state,
        add_ons: action.add_ons,
        isAddOns: false,
      };
    case GET_UTILITY:
      return {
        ...state,
        utilities: action.utilities,
        isUtility: false,
      };
    case GET_PANEL_MODEL:
      return {
        ...state,
        Panels: action.payload.panels,
        isPanle: false,
      };
    case GET_ROOF_TYPES:
      return {
        ...state,
        roofTyps: action.payload.roofTyps,
        isRoof: false,
      };
    case GET_ROOF_COLORS:
      return {
        ...state,
        colors: action.payload.colors,
        isColor: false,
      };
    case "IS_UTILITY":
      return {
        ...state,
        isUtility: true,
      };
    case "IS_ADD_ONS":
      return {
        ...state,
        isAddOns: true,
      };
    case "IS_PANLE":
      return {
        ...state,
        isPanle: true,
      };

    default:
      return { ...state };
  }
};

export default servicesReducer;
