import axios from "axios";
import swal from "sweetalert";
import {
  GET_ALL_DEPARTMENT_URL,
  GET_ALL_DEPARTMENT_USER_URL,
  GET_ALL_MESSAGES_URL,
  GET_SINGLE_MESSAGES_URL,
  MESSAGE_URL,
  CHANGE_STATUS_CHAT_URL,
} from "../../API/Api";
import { SignOut } from "./authActions";
import {
  GET_ALL_DEPARTMENT,
  GET_ALL_DEPARTMENT_USER,
  GET_ALL_MESSAGES,
  GET_UNSEEN_MESSAGE,
  GET_SINGLE_MESSAGES,
  CHANGE_STATUS_CHAT,
} from "./type";

export const getUnseenMessages = (data) => {
  return async (dispatch) => {
    const headers = {
      token: "bL4r$a$c$e6JA8cU",
      Accept: "application/json",
    };
    await axios({
      method: "POST",
      baseURL: `${MESSAGE_URL}`,
      headers: headers,
      data: data,
    })
      .then((res) => {
        dispatch({
          type: GET_UNSEEN_MESSAGE,
          unseenMessages: res.data.data,
        });
      })
      .catch((err) => {
        // swal({
        //   title: " error ",
        //   text: `${errors}`,
        //   icon: "error",
        //   button: "OK",
        // });
        console.log(err);
        if (err?.response?.status === 401) {
          dispatch(SignOut());
        }
      });
  };
};

export const getAllChats = (data) => {
  return async (dispatch) => {
    const headers = {
      token: "bL4r$a$c$e6JA8cU",
      Accept: "application/json",
    };
    await axios({
      method: "POST",
      baseURL: `${GET_ALL_MESSAGES_URL}`,
      headers: headers,
      data: data,
    })
      .then((res) => {
        dispatch({
          type: GET_ALL_MESSAGES,
          allChats: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status === 401) {
          dispatch(SignOut());
        }
      });
  };
};

export const getAllDeparments = () => {
  return async (dispatch) => {
    const headers = {
      token: "bL4r$a$c$e6JA8cU",
      Accept: "application/json",
    };
    await axios({
      method: "GET",
      baseURL: `${GET_ALL_DEPARTMENT_URL}`,
      headers: headers,
    })
      .then((res) => {
        dispatch({
          type: GET_ALL_DEPARTMENT,
          deparments: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status === 401) {
          dispatch(SignOut());
        }
      });
  };
};

export const getAllDeparmentUser = (data) => {
  return async (dispatch) => {
    const headers = {
      token: "bL4r$a$c$e6JA8cU",
      Accept: "application/json",
    };
    await axios({
      method: "POST",
      baseURL: `${GET_ALL_DEPARTMENT_USER_URL}`,
      headers: headers,
      data: data,
    })
      .then((res) => {
        dispatch({
          type: GET_ALL_DEPARTMENT_USER,
          users: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status === 401) {
          dispatch(SignOut());
        }
      });
  };
};

export const getAllSinglChat = (data) => {
  return async (dispatch) => {
    const headers = {
      token: "bL4r$a$c$e6JA8cU",
      Accept: "application/json",
    };
    await axios({
      method: "POST",
      baseURL: `${GET_SINGLE_MESSAGES_URL}`,
      headers: headers,
      data: data,
    })
      .then((res) => {
        dispatch({
          type: GET_SINGLE_MESSAGES,
          allSinglChat: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status === 401) {
          dispatch(SignOut());
        }
      });
  };
};

export const ChangeStatusChat = (data) => {
  return async (dispatch) => {
    const headers = {
      token: "bL4r$a$c$e6JA8cU",
      Accept: "application/json",
    };
    await axios({
      method: "POST",
      baseURL: `${CHANGE_STATUS_CHAT_URL}`,
      headers: headers,
      data: data,
    })
      .then((res) => {
        dispatch({
          type: CHANGE_STATUS_CHAT,
          changeStatus: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status === 401) {
          dispatch(SignOut());
        }
      });
  };
};
