import { Button, Container, FormControl, Grid, TextField } from "@mui/material";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  EditUserAction,
  GetSingleUser,
} from "../../../Redux/Actions/EditUserAction";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { MAIN_URL } from "../../../API/Api";

const EditProfileMain = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user_id, name } = useSelector((state) => state.auth);

  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(GetSingleUser(user_id));
  }, [dispatch]);

  const { getMainUser } = useSelector((s) => s.editUser);

  const arr = [getMainUser.id, getMainUser.parent_id, getMainUser.email];
  console.log(getMainUser);

  const [data, setData] = useState({
    name: name,
    image_path: "",
    address: getMainUser.address,
    phone: getMainUser.phone,
    mantras: getMainUser.mantras,
  });

  const handelSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("_method", "PATCH");
    formData.append("image_path", data.image_path);
    formData.append("name", data.name);
    formData.append("phone", data.phone);
    formData.append("address", data.address);
    formData.append("mantras", data.mantras);

    // dispatch(EditUserAction(user_id, formData));
    const headers = {
      "Content-Type": "multipart/form-data",
      enctype: "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    await axios({
      method: "POST",
      baseURL: `${MAIN_URL}/api/v1/users/${user_id}`,
      data: formData,
      arr,
      headers: headers,
    })
      .then((res) => {
        console.log(res.data);
        console.log("done");
        navigate("/profile/main");
        window.location.reload(false);
      })
      .catch((err) => {
        console.log(err.data);
      });

    // window.location.reload(false);
    //navigate("/profile/main");

    console.log(data);
  };

  return (
    <>
      <EditProfileStyled>
        <Container>
          <form onSubmit={handelSubmit}>
            <Grid container columns={18} spacing={3}>
              <Grid item sm={18}>
                <h2>Edit Your Profile</h2>
              </Grid>
              <Grid item sm={18}>
                <TextField
                  fullWidth
                  label="Edit Your Mantras"
                  value={data.mantras}
                  onChange={(e) =>
                    setData({ ...data, mantras: e.target.value })
                  }
                />
              </Grid>
              <Grid item sm={18}>
                <TextField
                  fullWidth
                  label="Edit Your Phone"
                  type="number"
                  value={data.phone}
                  onChange={(e) => setData({ ...data, phone: e.target.value })}
                />
              </Grid>
              <Grid item sm={18}>
                <TextField
                  fullWidth
                  label="Edit Your Address"
                  value={data.address}
                  onChange={(e) =>
                    setData({ ...data, address: e.target.value })
                  }
                />
              </Grid>
              <Grid item sm={18}>
                <label>Edit Your Image</label>
                <TextField
                  fullWidth
                  type="file"
                  className="imageUploade"
                  onChange={(e) =>
                    setData({ ...data, image_path: e.target.files[0] })
                  }
                />
              </Grid>
            </Grid>
            <Button variant="contained" className="btn-edit" type="submit">
              update
            </Button>
          </form>
        </Container>
      </EditProfileStyled>
    </>
  );
};

const EditProfileStyled = styled.div`
  display: grid;
  place-items: center;
  width: 80%;
  margin: auto;
  min-height: 80vh;

  h2 {
    text-align: center;
    font-size: 2rem;
    color: #92c7db;
  }
  label {
    color: #888;
  }
  .btn-edit {
    margin: 2rem 0;
    padding: 1rem 0;
    border-radius: 1.5rem;
    background-color: #4e9ec7;
    font-weight: bold;
    letter-spacing: 2px;
    font-size: 1.05rem;
    width: 100%;
  }
`;
export default EditProfileMain;
