import React, { useEffect, useRef, useState } from "react";
// Redux for leaflet
import { TotalArea } from "../../Redux/Actions/solutionsActions";
import L from "leaflet";
import "leaflet-draw";
import "leaflet-measure";
import styled from "styled-components";
// import "leaflet-distortableimage";
import "leaflet-distortableimage/dist/leaflet.distortableimage.css";
import "leaflet-toolbar/dist/leaflet.toolbar.css";
import { SimpleMapScreenshoter } from "leaflet-simple-map-screenshoter";
// import oneSolarPanel from "../../Asets/oneSolarPanel.PNG"
import oneSolarPanelsmall from "../../assets/Untitled-11.png";
import "leaflet-toolbar/dist/leaflet.toolbar.css";
// import "leaflet";
import "leaflet-toolbar";
import "leaflet-distortableimage";

// THESE TWO IMPORTS SOLVED THE PROBLEM 🎉
import "leaflet-distortableimage/dist/leaflet.distortableimage.css";
import "leaflet-distortableimage/dist/vendor.js";
// style for fixed size image
import "./systemDesign.css";
import { Grid, TextField } from "@mui/material";
//
import { useDispatch, useSelector } from "react-redux";
import { ScreenShot } from "../../Redux/Actions/solutionsActions";
import swal from "sweetalert";

// style
const DivDraggable = styled.div`
  width: 28px;
  height: 40px;
  float: right;
  z-index: 9999;
  margin: 0 1px;
  position: absolute;
  top: 50%;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
`;
const Reset = styled.button`
  border: none;
  padding: 5px 12px;
  cursor: pointer;
  width: 100%;
  height: 100%;
`;
const Delete = styled.button`
  border: none;
  padding: 5px 12px;
  margin: 0 2px;
  cursor: pointer;
  width: 100%;
  height: 100%;
`;
const AddImage = styled(Reset)`
  padding: 20px 0;
`;
const ScreenShotbtn = styled(AddImage)`
  color: white;
  margin: 0;
  font-size: 19px;
  background-color: #2a2a72;
  background-image: linear-gradient(315deg, #2a2a72 0%, #009ffd 74%);
`;

// Functional Component
const SystemDesign = ({
  coordinates,
  TotalPanle,
  showButton,
  showPanlesNo,
}) => {
  const dispatch = useDispatch();

  const [result, setResult] = React.useState([]);
  const FilteredArray = result.filter((e) => e !== undefined);
  let sum = 0;
  // Sum all elements of array
  for (let i = 0; i < FilteredArray.length; i++) {
    sum += FilteredArray[i];
  }
  // Delete all elements of array
  const RestSum = () => {
    setResult([]);
  };
  // Delete Last Element of array
  const DeleteLastElement = () => {
    setResult(result.slice(0, -1));
  };
  // console.log("areaaaaaaaaaaaaaaaaaaaa",area)
  // console.log("result", result);

  // var coordinates = {
  //   lat: 35.6541977,
  //   lng: -105.1424589,
  // };

  const sumTotal = {
    TotalSum: sum,
  };

  // State
  const [number, setNumber] = React.useState();
  const [newSum, setNewSum] = React.useState({
    value: 0,
  });
  let panel_area = 3389;
  let roof_area_sqInch = sum * 10.764 * 144;
  let max_panels = (roof_area_sqInch / panel_area).toFixed(0);
  const [thisScreen, setThisScreen] = React.useState();
  // disable button After click
  const [isDisabled, setDisabled] = useState(false);
  // ScrrenShot State
  const [aerialPhoto, setAerialPhoto] = React.useState();
  // Dispatch the screen shot
  // console.log("aerialPhoto", aerialPhoto);
  React.useEffect(() => {
    dispatch(ScreenShot(aerialPhoto));
    dispatch(TotalArea(Number((sum * 10.764).toFixed(2))));
  }, [aerialPhoto, sum]);

  // console.log("aerialPhoto",aerialPhoto)
  // Map state:
  const [mapInstance, setMapInstance] = useState(null);
  const [marker, setMarker] = useState(null);
  const [imagesSoalr, setImagesSoalr] = useState(null);

  // Map refs:
  const mapRef = useRef(null);
  const tileRef = useRef(null);
  const markerRef = useRef(null);
  const solarPanelsref = useRef(null);

  // Base tile for the map:
  tileRef.current = L.tileLayer(
    "https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
    {
      maxZoom: 22,
      subdomains: ["mt0", "mt1", "mt2", "mt3"],
    }
  );
  // Measure
  var measureOptions = {
    width: "50px",
    position: "topright",
    primaryLengthUnit: "meters",
    secondaryLengthUnit: "kilometers",
    primaryAreaUnit: "sqfeet",
    secondaryAreaUnit: "acres",
    activeColor: "#db4a29",
    completedColor: "#2b9b14",
    interactive: false,
    showArea: false,
    captureZIndex: -1,
    zIndexOffset: -1,
    active: false,
  };
  var measureControl = new L.Control.Measure(measureOptions);
  // var screenShot = new SimpleMapScreenshoter();

  const mapStyles = {
    overflow: "hidden",
    width: "100%",
    height: "80vh",
    margin: "10px 0",
  };

  // Options for our map instance:
  const mapParams = {
    center: [coordinates.lat, coordinates.lng], // USA
    zoom: 22,
    zoomControl: false,
    zoomSnap: 0.75,
    layers: [tileRef.current], // Start with just the base layer
  };
  // The refrence for image size
  const [imageSize, setImageSize] = React.useState();
  const [arrs, setArrs] = React.useState([]);
  // Map creation:
  useEffect(() => {
    var container = L.DomUtil.get("map");
    if (container != null) {
      container._leaflet_id = null;
    }
    mapRef.current = L.map("map", mapParams);
    // Add an event listener:
    // mapRef.current.on("click", () => {
    //   alert("map clicked");
    // });

    // Add Measure
    measureControl.addTo(mapRef.current);
    // Add Scale
    L.control.scale().addTo(mapRef.current);
    // Calculate Area
    mapRef.current.on("measurefinish", function (e) {
      // console.log("pure data", e);
      // sessionStorage.setItem("Area", e.area);
      setResult((preResult) => [...preResult, e.area]);
      // console.log("result in function", result);
    });
    // Screen Shot
    var Screen = new SimpleMapScreenshoter().addTo(mapRef.current);
    setThisScreen(Screen);
    // Add Marker
    var marker = L.marker(mapRef.current.getCenter());
    var marker_coor = marker.getLatLng();
    // Add Reactangle to map
    let lng_weshwash = 0.00001;

    var bounds = [
      [marker_coor.lat, marker_coor.lng],
      [marker_coor.lat + 0.000014, marker_coor.lng + 0.00001],
    ];
    var rect = L.rectangle(bounds, {
      color: "#ff7800",
      weight: 0,
      interactive: false,
      showArea: false,
    });

    // console.log("wwwwwwwwwwwwwwwwwww", rect.getLatLngs()[0]);
    var rectCoor = rect.getLatLngs()[0];
    // Try
    // var reectCoors=rects.getLatLngs();
    // console.log("reectCoors", newBounds);
    setImageSize(rectCoor);
    // Set map instance to state:
    setMapInstance(mapRef.current);
  }, []); // <- Empty dependency array, so it only runs once on the first render.

  // If you want to use the mapInstance in a useEffect hook,
  // you first have to make sure the map exists. Then, you can add your logic.
  useEffect(() => {
    // Check for the map instance before adding something (ie: another event listener).
    // If no map, return:
    if (!mapInstance) return;
    if (mapInstance) {
      mapInstance.on("zoomstart", () => {
        // console.log("Zooming!!!");
      });
    }
  }, [mapInstance]);
  // Toggle marker on button click:
  const handleClick = () => {
    if (marker) {
      marker.removeFrom(mapInstance);
      markerRef.current = null;
    } else {
      markerRef.current = L.marker([40.7128, -74.006]).addTo(mapInstance);
    }
    setMarker(markerRef.current);
  };
  const emptyarr = () => {
    setArrs([]);
  };
  const addImages = () => {
    if (imagesSoalr) {
      mapInstance.remove(solarPanelsref.current);
      // window.map.removeLayer(window.polygon);
    } else {
      // for (var i = 0; i < Number(TotalPanle); i++) {
      let arr = Array?.from(Array(TotalPanle||0)?.keys());
      var marker = L.marker(mapRef.current.getCenter());
      var marker_coor = marker.getLatLng();
      arr.map((e, i) => {
        var newBounds = [
          [marker_coor.lat, marker_coor.lng],
          [
            marker_coor.lat + 0.000014,
            marker_coor.lng + 0.00001 + Number(`0.0000${arr[i]}`),
          ],
        ];
        var rects = L.rectangle(newBounds, {
          color: "#ff7800",
          weight: 0,
          interactive: false,
          showArea: false,
        });

        let allrects = rects.getLatLngs();
        setArrs((prevValue) => [...prevValue, allrects]);
        console.log("allrects", allrects);
        console.log("setArrs", arrs);
        console.log("imageSize", imageSize);
        var layer = L.distortableImageOverlay(`${oneSolarPanelsmall}`, {
          fullResolutionSrc: oneSolarPanelsmall,
          corners: [
            L.latLng(
              imageSize[2].lat,
              imageSize[2].lng + Number(`0.0000${arr[i]}`)
            ),
            L.latLng(
              imageSize[3].lat,
              imageSize[3].lng + Number(`0.0000${arr[i]}`)
            ),
            L.latLng(
              imageSize[1].lat,
              imageSize[1].lng + Number(`0.0000${arr[i]}`)
            ),
            L.latLng(
              imageSize[0].lat,
              imageSize[0].lng + Number(`0.0000${arr[i]}`)
            ),
          ],
          // L.FreeRotateAction, L.ScaleAction
          actions: [L.RotateAction, L.DeleteAction],
          // mode:[L.distort ,L.drag,L.rotate,L.scale],
          selected: true,
          rotation: {
            deg: 90,
          },
        }).addTo(mapInstance);
      });
      // var layer = L.distortableImageOverlay(`${oneSolarPanelsmall}`, {
      //   fullResolutionSrc: oneSolarPanelsmall,
      //   corners: [
      //     L.latLng(imageSize[2].lat, imageSize[2].lng),
      //     L.latLng(imageSize[3].lat, imageSize[3].lng),
      //     L.latLng(imageSize[1].lat, imageSize[1].lng),
      //     L.latLng(imageSize[0].lat, imageSize[0].lng),
      //   ],
      //   // L.FreeRotateAction, L.ScaleAction
      //   actions: [L.RotateAction, L.DeleteAction],
      //   // mode:[L.distort ,L.drag,L.rotate,L.scale],
      //   selected: true,
      //   rotation: {
      //     deg: 90,
      //   },
      // }).addTo(mapInstance);
      // }
      setImagesSoalr(solarPanelsref.current);
      setDisabled(true);
    }
    // if (imagesSoalr) {
    //   imagesSoalr.removeFrom(mapInstance);
    //   solarPanelsref.current = null;
    // } else {
    //   for (var i = 0; i < number; i++) {
    //     solarPanelsref.current = L.distortableImageOverlay(
    //       `${oneSolarPanelsmall}`,
    //       {
    //         // fullResolutionSrc: oneSolarPanelsmall,
    //         // actions: [L.RotateAction, L.FreeRotateAction, L.ScaleAction],
    //         // mode:[L.distort ,L.drag,L.rotate,L.scale],
    //         selected: false,
    //         rotation: {
    //           deg: 90,
    //         },
    //       }
    //     ).addTo(mapInstance);
    //   }
    // }
    // setImagesSoalr(solarPanelsref.current);
  };
  const takeScreeeen = () => {
    try {
      thisScreen
        .takeScreen("image", {})
        .then((image) => {
          // console.log("image",image)
          setAerialPhoto(image);
          // swal("ScreenShot Taken!");
          swal({
            // title: "Congratulations You finally did it and ScreenShot Taken",
            title: "ScreenShot Taken",
            icon: "success",
            dangerMode: false,
          });
        })
        .catch((e) => {
          alert(e.toString());
        });
    } catch (e) {
      console.log("Error for screenShot", e);
    }
  };
  // check if the panel Solar changed
  useEffect(() => {
    setDisabled(false);
  }, [TotalPanle]);
  return (
    <div>
      {showButton ? (
        <>
          <Grid item md={6} sm={9} xs={18} mb={3} gap={0}>
            {/* <InputLabel>Total Area </InputLabel> */}
            <TextField
              fullWidth
              disabled
              label="Maximum Panels Number"
              value={max_panels}
              onChange={(e) => {
                setNewSum(e.target.value);
              }}
            />
          </Grid>
          <AddImage type="button" onClick={addImages} disabled={isDisabled}>
            Add Solar Panels
          </AddImage>
        </>
      ) : (
        <span></span>
      )}

      <div id="map" style={mapStyles} />
      <Grid item container spacing={2} columns={18}>
        <Grid item md={6} sm={9} xs={18} mb={3} gap={0}>
          {/* <InputLabel>Total Area </InputLabel> */}
          <Reset type="button" onClick={RestSum}>
            Rest sum{" "}
          </Reset>
        </Grid>
        <Grid item md={6} sm={9} xs={18} mb={3} gap={0}>
          {/* <InputLabel>Total Area </InputLabel> */}
          <TextField
            fullWidth
            disabled
            label="Total Area"
            // type="number"
            value={(sum * 10.764).toFixed(2)}
            onChange={(e) => {
              setNewSum(e.target.value);
            }}
          />
        </Grid>
        {/* <button type="button" onClick={(e)=>{  props.passData(sumTotal)}}>Send data</button> */}
        <Grid item md={6} sm={9} xs={18} mb={3} gap={0}>
          {/* <InputLabel>Total Area </InputLabel> */}
          <Delete type="button" onClick={DeleteLastElement}>
            Delete Last Area{" "}
          </Delete>
        </Grid>
        <Grid item md={18} sm={18} xs={18} mb={3} gap={0}>
          {/* <InputLabel>Total Area </InputLabel> */}
          <ScreenShotbtn required type="button" onClick={takeScreeeen}>
            {/* ScreenShot for the Design{" "} */}
            Don't Forget To Take ScreenShot plz!
          </ScreenShotbtn>
        </Grid>
      </Grid>
      {/* <p>
        Total Area is{" "}
        <span style={{ color: "blueviolet" }}>{(sum * 10.764).toFixed(2)}</span>{" "}
        sq feet
      </p> */}
    </div>
  );
};
export default React.memo(SystemDesign);
