import styled from "styled-components";
import { TextField, Grid, Button, Container } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SignIn } from "../../Redux/Actions/authActions";
import { Link, useNavigate } from "react-router-dom";
import loginImage from "../../assets/login.png";
import { VpnKey } from "@mui/icons-material";
import ResponsiveAppBar from "../landing Page/components/header/Header";
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const { authLogIn, token, loginLoading } = useSelector((state) => state.auth);
  const { isLoading } = useSelector((state) => state.proposal);

  console.log("isLoading", isLoading);
  //start ahndel submit api

  const handelSubmit = async (e) => {
    e.preventDefault();
    await dispatch(SignIn(user.email, user.password));
  };
  useEffect(() => {
    if (authLogIn && token) {
      navigate("/crm/leads");
    }
  }, [authLogIn && token]);
  if (authLogIn && token) {
    navigate("/crm/leads");
  }

  return (
    <>
      <div style={{ background: "black", padding: "2rem 0" }}>
        <ResponsiveAppBar />
      </div>
      <LoginStyled>
        <Container>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ height: "100vh" }}
          >
            <Grid item xs={12} md={6}>
              <div className="img" style={{ width: "100%" }}>
                <img src={loginImage} alt="login" style={{ width: "100%" }} />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <h2 className="heading">Login</h2>
              <form onSubmit={handelSubmit}>
                <TextField
                  fullWidth
                  label="Email"
                  required
                  id="fullWidth"
                  className="inputText"
                  value={user.email}
                  onChange={(e) => setUser({ ...user, email: e.target.value })}
                />
                <TextField
                  fullWidth
                  label="password"
                  type={"password"}
                  required
                  id="fullWidth"
                  className="inputText"
                  value={user.password}
                  onChange={(e) =>
                    setUser({ ...user, password: e.target.value })
                  }
                />

                <Button variant="contained" type="submit" fullWidth>
                  {isLoading ? "Loading..." : "Login"}
                </Button>
                <Link to="forgot-password" className="forgot_password">
                  <VpnKey /> Forgot Password
                </Link>
              </form>
            </Grid>
          </Grid>
        </Container>
      </LoginStyled>
    </>
  );
};

const LoginStyled = styled.section`
  width: 100%;
  min-height: 100vh;
  background: #fff;
  .heading {
    text-align: center;
    font-size: 2.5rem;
    letter-spacing: 5px;
    text-transform: uppercase;
  }
  .inputText {
    margin: 1rem 0;
  }
  button {
    margin: 1rem 0;
    padding: 0.8rem 0;
  }
  .forgot_password {
    display: flex;
    align-items: center;
    color: #5bb5d2;
    svg {
      margin-right: 0.5rem;
    }
  }
`;

export default Login;
