import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./helper/index.js";

export const leadsBankApi = createApi({
  reducerPath: "leadsBankApi",
  baseQuery,
  tagTypes: ["leadsBank"],
  endpoints: (builder) => ({
    getStates: builder.query({
      query: () => ({
        url: "/leads-bank-portal/states-transaction-types",
        method: "get",
      }),
    }),
    getLeadsBank: builder.query({
      query: ({ page, state_id, transaction_type }) => ({
        url: "/leads-bank-portal/index",
        method: "get",
        params: { page, state_id, transaction_type },
      }),
      providesTags: ["leadsBank"],
    }),

    addLeadsToBank: builder.mutation({
      query: (body) => ({
        url: "/leads-bank-portal/add-leads-to-cart",
        method: "POST",
        body,
      }),
      invalidatesTags: ["leadsBank"],
    }),
  }),
});

export const {
  useGetStatesQuery,
  useGetLeadsBankQuery,
  useAddLeadsToBankMutation,
} = leadsBankApi;
