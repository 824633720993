import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./helper/index.js";

export const templateApi = createApi({
  reducerPath: "templateApi",
  tagTypes: ["template"],
  baseQuery,
  endpoints: (builder) => ({
    getTemplates: builder.query({
      query: (page) => ({
        method: "GET",
        url: "business/add-templates",
        params: {
          page: page,
        },
      }),
      providesTags: ["template"],
    }),
    addTemplate: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "business/add-templates",
        body,
      }),
      providesTags: ["template"],
    }),
  }),
});

export const { useGetTemplatesQuery, useAddTemplateMutation } = templateApi;
