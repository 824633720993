import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLicense } from "../../../../Redux/Actions/licensesActions";
import { Loading } from "../../../Left/Routs/goals/style";
import { Grid } from "@mui/material";
import styled from "styled-components";

const SalesLicense = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.proposal);
  const { allLicenses } = useSelector((state) => state.license);
  const [selectedLicense, setSelectedLicense] = React.useState("");

  console.log("allLicenses", allLicenses?.data);
  console.log("isLoading", isLoading);
  React.useEffect(() => {
    dispatch(getLicense());
  }, []);
  return (
    <>
      {isLoading ? (
        <Loading>
          <span classNameName="loader"></span>
        </Loading>
      ) : (
        <Bigsection>
          <Grid container columns={18} spacing={3}>
            {allLicenses?.data?.map((e) => {
              return (
                <Grid item xs={18} md={4.5} key={e?.id}>
                  {" "}
                  <div className="singleLicense">
                    <div className="license">
                      {/* <div className="imgContainer">
                        <img src={licenseImage} alt="" />
                      </div> */}
                      <div className="info">
                        <div className="content">
                          <h4>License</h4>
                          <p>
                            State: <span>{e?.state?.name}</span>
                          </p>
                          <p>
                            Pdf:{" "}
                            <a href={e?.media_url} target="_blank">
                              {" "}
                              <span className="link">See Licesne</span>
                            </a>
                          </p>
                          <button
                            onClick={() => {
                              setSelectedLicense(e);
                              // handleOpen();
                            }}
                          >
                            Delete{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </Bigsection>
      )}
    </>
  );
};
const Bigsection = styled.div`
  padding: 1rem 0rem;
  .singleLicense {
    .license {
      background: #ffffff;
      box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
        0px 1px 2px rgba(16, 24, 40, 0.06);
      border-radius: 8px;
      .info {
        .content {
          padding: 1rem 0.5rem;
          h4 {
            text-align: center;
          }
          p,
          h4 {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #667085;
            margin-bottom: 1rem;
            font-family: "Passion One", Impact, condensed, sans-serif;
            font-size: 1.1em;
            line-height: 1rem;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: var(--data-color, #f78787);
            padding: 1rem 0;
            span {
              font-family: "Inter";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              color: #333333;
            }
          }
        }
        button {
          width: 100%;
          padding: 10px 16px;
          background: #ffffff;
          border: 1px solid #d0d5dd;
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
          border-radius: 8px;
          margin-bottom: 0.4rem;
          cursor: pointer;
        }
      }
    }
  }
`;

export default SalesLicense;
