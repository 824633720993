import { GET_CONTRACT } from "../Actions/SingleOpportunitieAction";
import {
  POST_LEADS,
  GET_LEAD,
  GET_SINGLE_LEAD,
  GET_OPPORTUNITY,
  EDIT_LEAD_PRFILE,
  GET_LEAD_SKIP_AND_TAKE,
  ASIGN_LEAD_TO_USER,
  GET_SINGLE_LEAD_CHILD,
  ARCHIVE_LEAD,
  GET_LEAD_ARCHIVE,
  ACHIEVEMENT,
  PARENT_ACHIEVEMENT,
  SALES_ACHIEVEMENT,
} from "../Actions/type";

const initialState = {
  leadlist: [],
  archiveLeads: [],
  leadsSkipTake: [],
  postLeadList: { service_types: [] },
  editLead: {},
  massage: [],
  submit: false,
  singlead: [],
  singleadCild: [],
  errors: [],
  status: "",
  asignLead: "",
  singleOpportunity: [],
  isLeadLoading: true,
  isSingleLeadLoading: true,
  archiveData: {},
  stringSearch: null,
  toggleSearch: null,
  contractData: "",
  leadBanks: [],
  all_invoices: [],
  singleInvoice: "",
  deleteInvoice: "",
  buyInvoice: "",
  checkOut: "",
  achievement: "",
  parentAchievement: "",
  salesAcievement: "",
};

const leadReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LEAD_ARCHIVE:
      return {
        ...state,
        archiveLeads: action.archiveLeads,
        isLeadLoading: false,
      };
    case GET_LEAD:
      return {
        ...state,
        leadlist: action.leads,
        isLeadLoading: false,
      };
    case GET_LEAD_SKIP_AND_TAKE:
      return {
        ...state,
        leadsSkipTake: action.leadsSkipTake,
        ...(state.leadlist = state.leadsSkipTake),
      };
    case "IS_LEAD_LOADING":
      return {
        ...state,
        isLeadLoading: true,
      };
    case GET_SINGLE_LEAD:
      return {
        ...state,
        singlead: action.singlead.data,
        isSingleLeadLoading: false,
      };
    case GET_SINGLE_LEAD_CHILD:
      return {
        ...state,
        singleadCild: action.singleadCild,
        isSingleLeadLoading: false,
      };
    case "IS_SINGLE_LEAD_LOADING":
      return {
        ...state,
        isSingleLeadLoading: true,
      };
    case POST_LEADS:
      return {
        ...state,
        postLeadList: action.leadListData,
        massage: action.massage,
        submit: action.submit,
      };
    case ASIGN_LEAD_TO_USER:
      return {
        ...state,
        asignLead: action.asignLead,
      };
    case EDIT_LEAD_PRFILE:
      return {
        ...state,
        editLead: action.editLead,
        massage: action.massage,
        submit: action.submit,
      };
    case "POST_LEAD_ERROR":
      return {
        ...state,
        errors: action.error,
      };
    case "POST_LEADS_SUCSESS":
      return {
        ...state,
        status: action.status,
      };
    case GET_OPPORTUNITY:
      return {
        ...state,
        singleOpportunity: action.singleOpportunity,
      };
    case ARCHIVE_LEAD:
      return {
        ...state,
        archiveData: action.payload.archiveData,
      };
    case "KEY_WORD":
      return {
        ...state,
        stringSearch: action.stringSearch,
      };
    case "KEY_TOGGLE":
      return {
        ...state,
        toggleSearch: action.toggleSearch,
      };
    case GET_CONTRACT:
      return {
        ...state,
        contractData: action.contractData,
      };
    case ACHIEVEMENT:
      return {
        ...state,
        achievement: action.achievement,
      };
    case PARENT_ACHIEVEMENT:
      return {
        ...state,
        parentAchievement: action.parentAchievement,
      };
    case SALES_ACHIEVEMENT:
      return {
        ...state,
        salesAcievement: action.salesAcievement,
      };

    default:
      return { ...state };
  }
};

export default leadReducer;
