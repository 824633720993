import { Grid, TextField } from "@mui/material";
import React from "react";
import { SubmitButton } from "../../../../components/common/submitButton";
import styled from "styled-components";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAddTemplateMutation } from "../../../../Redux/toolKit/template";
import { toast } from "react-toastify";

const validationSchema = Yup.object()
  .shape({
    empty_proposal: Yup.mixed()
      .nullable() // Accept null or undefined values
      .test("fileType", "Only PDF files are allowed", (value) => {
        if (!value) return true; // If value is undefined or null, pass the validation
        return value.type === "application/pdf"; // Only PDF files are allowed
      })
      .test(
        "fileSize",
        "File size is too large, maximum size is 5MB",
        (value) => {
          if (!value) return true; // If value is undefined or null, pass the validation
          return value.size <= 5 * 1024 * 1024; // Maximum file size is 5MB
        }
      ),

    draft_proposal: Yup.mixed()
      .nullable()
      .test("fileType", "Only PDF files are allowed", (value) => {
        if (!value) return true;
        return value.type === "application/pdf";
      })
      .test(
        "fileSize",
        "File size is too large, maximum size is 5MB",
        (value) => {
          if (!value) return true;
          return value.size <= 5 * 1024 * 1024;
        }
      ),

    empty_contract: Yup.mixed()
      .nullable()
      .test("fileType", "Only PDF files are allowed", (value) => {
        if (!value) return true;
        return value.type === "application/pdf";
      })
      .test(
        "fileSize",
        "File size is too large, maximum size is 5MB",
        (value) => {
          if (!value) return true;
          return value.size <= 5 * 1024 * 1024;
        }
      ),

    draft_contract: Yup.mixed()
      .nullable()
      .test("fileType", "Only PDF files are allowed", (value) => {
        if (!value) return true;
        return value.type === "application/pdf";
      })
      .test(
        "fileSize",
        "File size is too large, maximum size is 5MB",
        (value) => {
          if (!value) return true;
          return value.size <= 5 * 1024 * 1024;
        }
      ),
  })
  .test(
    "atLeastOneFile",
    "At least one file must be uploaded",
    function (values) {
      const { empty_proposal, draft_proposal, empty_contract, draft_contract } =
        values;

      // Check if at least one file is provided
      return (
        empty_proposal || draft_proposal || empty_contract || draft_contract
      );
    }
  );

const UploadTemplate = () => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [post, { isLoading }] = useAddTemplateMutation();
  const onSubmit = async (data) => {
    console.log("data", data);
    const formData = new FormData();
    for (const property in data) {
      if (data[property] !== undefined)
        formData.append(property, data[property]);
    }
    try {
      await post(formData).unwrap();
      toast.success("Templates has been updated");
    } catch (e) {
      console.error(e.response);
      toast.error("Err");
    }
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
    console.log("effect rendered");
  }, []);
  const watchData = watch();
  console.log("errors", errors);
  return (
    <Bigsection>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container columns={18} spacing={3}>
          <Grid item xs={18}>
            <h1>Upload Template</h1>
            <hr />
          </Grid>
          <Grid item xs={18} md={9}>
            <label htmlFor="">Empty proposal</label>
            <Controller
              name={"empty_proposal"}
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  fullWidth
                  type="file"
                  // inputProps={{ accept: ".pdf" }}
                  onChange={(event) => {
                    onChange(event.target.files[0]);
                  }}
                />
              )}
            />
            {errors.empty_proposal && (
              <span style={{ color: "red", fontSize: "0.8rem" }}>
                {errors.empty_proposal.message}
              </span>
            )}
          </Grid>
          <Grid item xs={18} md={9}>
            <label htmlFor="">Draft proposal</label>
            <Controller
              name={"draft_proposal"}
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  fullWidth
                  type="file"
                  // inputProps={{ accept: ".pdf" }}
                  onChange={(event) => {
                    onChange(event.target.files[0]);
                  }}
                />
              )}
            />

            {errors.draft_proposal && (
              <span style={{ color: "red", fontSize: "0.8rem" }}>
                {errors.draft_proposal.message}
              </span>
            )}
          </Grid>
          <Grid item xs={18} md={9}>
            <label htmlFor="">Empty contract</label>
            <Controller
              name={"empty_contract"}
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  fullWidth
                  type="file"
                  // inputProps={{ accept: ".pdf" }}
                  onChange={(event) => {
                    onChange(event.target.files[0]);
                  }}
                />
              )}
            />
            {errors.empty_contract && (
              <span style={{ color: "red", fontSize: "0.8rem" }}>
                {errors.empty_contract.message}
              </span>
            )}
          </Grid>
          <Grid item xs={18} md={9}>
            <label htmlFor="">Draft contract</label>
            <Controller
              name={"draft_contract"}
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  fullWidth
                  type="file"
                  // inputProps={{ accept: ".pdf" }}
                  onChange={(event) => {
                    onChange(event.target.files[0]);
                  }}
                />
              )}
            />
            {errors.draft_contract && (
              <span style={{ color: "red", fontSize: "0.8rem" }}>
                {errors.draft_contract.message}
              </span>
            )}
          </Grid>
          {errors?.[""] && (
            <Grid item xs={18}>
              <p style={{ color: "red", fontSize: "0.8rem" }}>
                {errors?.[""].message}
              </p>
            </Grid>
          )}

          <Grid item xs={18}>
            <SubmitButton type="submit" fullWidth>
              {isLoading ? "Submiting..." : "Submit"}
            </SubmitButton>
          </Grid>
        </Grid>
      </form>
    </Bigsection>
  );
};
const Bigsection = styled.section`
  padding-right: 2rem;
`;
export default UploadTemplate;
