import NavBarRouts from "../../../components/NavBar/NavBarRouts";
import RouterContent from "../../../components/RouterContent/RouterContent";
import styled from "styled-components";
import { useLocation, useParams } from "react-router-dom";
import LeadBanks from "../../../components/LeadBanks/LeadBanks";
// import { useSelector } from "react-redux";

const Routs = () => {
  // const { token, authLogIn } = useSelector((state) => state.auth);

  // if (token === null && !authLogIn) return null;
  const path = useLocation();
  console.log("path", path.pathname.includes("get-leads"));
  return (
    <>
      <ContentRouting>
        {/* <NavBarRouts /> */}
        {path.pathname.includes("get-leads") ? (
          // <LeadBanks></LeadBanks>
          <></>
        ) : (
          <>
            <NavBarRouts />
          </>
        )}
        <RouterContent />
      </ContentRouting>
    </>
  );
};

const ContentRouting = styled.section`
  background: #fff;
  padding: 1rem;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 14%);
  border-radius: 1rem;
`;
export default Routs;
