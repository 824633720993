import React, { useState } from "react";
import { Bigsection } from "./style";
import { Grid, MenuItem, TextField } from "@mui/material";
import { useGetStatesQuery } from "../../Redux/toolKit/leadsBank";
import { useForm } from "react-hook-form";
import ShowData from "./components/showData";

const NewLeadsBank = () => {
  // const [state, setState] = useState("");
  // const [transactionType, setTransactionType] = useState("");
  const { data, isLoading, isFetching } = useGetStatesQuery("");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const stateID = watch("state");
  const transactionType = watch("transactionType");

  if (isLoading || isFetching) {
    return <h3>Loading...</h3>;
  }
  return (
    <Bigsection>
      <Grid container columns={18} spacing={3}>
        <Grid item xs={18} md={9}>
          <label htmlFor="">Filter by state</label>
          <TextField
            id="outlined-select-currency"
            select
            fullWidth
            {...register("state")}
            size="small"
          >
            {data?.data?.states?.map((option) => (
              <MenuItem key={option.id} value={option?.id}>
                {option?.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={18} md={9}>
          <label htmlFor="">Filter by transaction types</label>
          <TextField
            id="outlined-select-currency"
            select
            fullWidth
            {...register("transactionType")}
            size="small"
            defaultValue={"immediate"}
          >
            {data?.data?.transaction_type?.map((option) => (
              <MenuItem value={option}>{option}</MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={18}>
          <ShowData stateID={stateID} transactionType={transactionType} />
        </Grid>
      </Grid>
    </Bigsection>
  );
};

export default NewLeadsBank;
