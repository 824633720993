import React from "react";
import styled from "styled-components";

const Alert = () => {
  const [open, setOpened] = React.useState(true);
  const handleChangeButton = () => {
    console.log("open", open);
    setOpened(!open);
  };
  return (
    <Bigsetion>
      {open ? (
        <>
          {" "}
          <div className="alert">
            <span className="closebtn" onClick={handleChangeButton}>
              &times;
            </span>
            <strong>Warning!</strong> The 3.99 rate solutions are no more valid
          </div>
        </>
      ) : (
        <>
          {" "}
          {/* <div className="show">
            <p onClick={handleChangeButton}>Show important News</p>
          </div> */}
        </>
      )}
    </Bigsetion>
  );
};

const Bigsetion = styled.div`
  .alert {
    padding: 1rem 2rem;
    background-color: #cc3300;
    color: white;
  }
  .show {
    padding: 1rem 2rem;
    color: black;
    cursor: pointer;
  }

  .closebtn {
    margin-left: 15px;
    color: white;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
  }

  .closebtn:hover {
    color: black;
  }
`;
export default Alert;
