import { Grass, Paid } from "@mui/icons-material";
import styled from "styled-components";

const FooterPDF = () => {
  return (
    <CopyRightPdf>
      <div className="copyRight">
        <p>2019, ALL PREMIUM CONTRACTORS – SOLAR</p>
        <p>1-877-356-8460</p>
        <p>CSLB License #920361</p>
      </div>
      <div className="infoData">
        <h4>ADVANTAGES OF SOLAR</h4>
        <p>
          <Paid /> <span>SAVES YOU MONENY </span>
        </p>
        <p>
          <Grass /> <span>GONING GREEN </span>
        </p>
        <p>
          <Paid /> <span>SAVES YOU MONENY </span>
        </p>
      </div>
      <div className="botom-footer"></div>
    </CopyRightPdf>
  );
};

const CopyRightPdf = styled.div`
  .copyRight {
    margin: 0.8rem 0;
    display: flex;
    justify-content: space-between;
    color: #0b395a;
    flex-wrap: wrap;
    p {
      flex: 1 0 0 150px;
    }
  }
  .infoData {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    svg {
      color: #0b395a;
      font-size: 2rem;
    }
    h4 {
      padding: 0.5rem 2rem 0.5rem 3rem;
      background: #87db52;
      color: white;
      border-radius: 0 0 2rem;
    }
    p {
      display: flex;
      align-items: center;
      color: #0b395a;
    }
  }
  .botom-footer {
    width: 100%;
    height: 6vh;
    background: #0b3d8d;
    /* -radius: 0 0 2rem 2rem; */
  }
`;
export default FooterPDF;
