import { Button, TextField } from "@mui/material";
import axios from "axios";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";
import { MAIN_URL } from "../../../API/Api";

const validationSchema = yup.object({
  current_password: yup
    .string("Enter your password ")
    .required("Password is required"),
  new_password: yup
    .string("Enter your New Password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});
const SalesChangePassword = () => {
  const { token } = useSelector((state) => state.auth);
  // const Navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      current_password: "",
      new_password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const headers = {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      };
      await axios({
        method: "POST",
        baseURL: `${MAIN_URL}/api/v1/users/user/change-password`,
        data: values,
        headers: headers,
      })
        .then((res) => {
          console.log(res.data);
          console.log("done");
          alert("Password Updated Successfully ");
          window.location.reload(false);
        })
        .catch((err) => {
          console.log(err.data);
        });
    },
  });
  return (
    <>
      <FormContianer>
        <h2>Change Password</h2>
        <form onSubmit={formik.handleSubmit}>
          <InputContianer>
            <TextField
              fullWidth
              id="current_password"
              name="current_password"
              label="Old Password"
              type="password"
              value={formik.values.current_password}
              onChange={formik.handleChange}
              error={
                formik.touched.current_password &&
                Boolean(formik.errors.current_password)
              }
              helperText={
                formik.touched.current_password &&
                formik.errors.current_password
              }
            />
          </InputContianer>
          <InputContianer>
            <TextField
              fullWidth
              id="new_password"
              name="new_password"
              label="New Password"
              type="password"
              value={formik.values.new_password}
              onChange={formik.handleChange}
              error={
                formik.touched.new_password &&
                Boolean(formik.errors.new_password)
              }
              helperText={
                formik.touched.new_password && formik.errors.new_password
              }
            />
          </InputContianer>
          <Button type="submit" className="OnUpdate" variant="contained">
            Update
          </Button>
        </form>
      </FormContianer>
    </>
  );
};
const FormContianer = styled.div`
  padding: 2rem;
`;
const InputContianer = styled.div`
  margin: 1rem 0;
  width: 80%;
`;
export default SalesChangePassword;
