import { ThumbnailCard } from "../RightStyled";
import royalites from "../../../assets/line-graph.png";

const Royalties = () => {
  return (
    <>
      <ThumbnailCard>
        <div className="icon Royalties">
          <img src={royalites} alt="Royalties" />
        </div>
        <div className="data">
          <div className="title">Royalties </div>
          <div className="number">$0</div>
        </div>
      </ThumbnailCard>
    </>
  );
};

export default Royalties;
