import {
  GET_COUNTRIES,
  GET_STATES,
  GET_CITIES,
  GET_SERVICES,
  GET_STATE_ID,
} from "../Actions/type";

const initState = {
  countries: [],
  states: [],
  cities: [],
  services: [],
  utilities: [],
  countryData: {},
  isServices: true,
};

const countriesReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_COUNTRIES:
      return {
        ...state,
        countries: action.countries,
      };
    case GET_STATES:
      return {
        ...state,
        states: action.states.states,
      };
    case GET_CITIES:
      return {
        ...state,
        cities: action.cities,
      };
    case GET_STATE_ID:
      return {
        ...state,
        countryData: action.countryData,
      };
    case GET_SERVICES:
      return {
        ...state,
        services: action.services,
        isServices: false,
      };

    case "IS_SERVICES":
      return {
        ...state,
        isServices: true,
      };

    default:
      return { ...state };
  }
};
export default countriesReducer;
