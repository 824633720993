import axios from "axios";
import { ZoomUrl } from "../../API/Api";
import { GET_ZOOM, POST_ZOOM } from "./type";

export const getZoomMeeiting = () => async (dispatch) => {
  const token = localStorage.getItem("token");
  const NewMeetingData = await axios.get(`${ZoomUrl}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  dispatch({
    type: GET_ZOOM,
    payload: {
      meetings: NewMeetingData.data,
    },
  });
};

export const addZoomMeeting = (user) => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    axios
      .post(`${ZoomUrl}`, user, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        dispatch({
          type: POST_ZOOM,
          topic: res.data.topic,
          start_time: res.data.start_time,
          duration: res.data.duration,
          password: res.data.password,
        });
      })
      .catch((err) => {
        console.log(err.data);
      });
  };
};
