import styled from "styled-components";

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.25rem;
  position: relative;
  p {
    color: #344054;
    /* font-family: Manrope; */
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    text-align: left;
  }
  .singleCard {
    border-radius: 0.5rem;
    /* background: #fbfbfb; */
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
    padding: 1rem 1.2rem;
    width: 100%;
    background-color: white;
    .firstRow {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      /* padding-bottom: 1rem; */
      .flex {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        /* justify-content: center; */
        /* text-align: center; */
      }
    }
    .container {
      all: unset;
      display: flex;
      width: 100%;
      align-items: center;
      gap: 1rem;
      flex-direction: row;
      /* flex-wrap: wrap; */
    }
    .column {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      /* gap: 0.5rem; */
      width: 30%;
      .timeWarranty {
        display: flex;
        flex-direction: column;
        font-size: 0.7rem;
        justify-content: center;
        align-items: center;
        p {
          font-size: 0.7rem;
        }
        .number {
          font-size: 2.5rem;
          font-size: bolder;
        }
        .years {
          font-size: 1rem;
        }
        .others {
          font-size: 0.6rem;
        }
      }
      .stars {
        font-size: 1.5rem !important;
      }
      .firstColumnContainer {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        width: 100%;
      }
      .img_time {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 0.55rem;
        div {
          /* width: 50%; */
        }
      }
      .imgContainer {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 0.55rem;
        position: relative;
        svg {
          order: 2;
        }

        p {
          display: flex;
          align-items: center;
        }
        .name {
          font-family: "Arial Black", Gadget, sans-serif;
          font-size: 19px;
          letter-spacing: 2px;
          word-spacing: 2px;
          font-weight: 700;
          text-decoration: none;
          font-style: normal;
          font-variant: normal;
          text-transform: capitalize;
          color: #585858;
        }
        .expire {
          color: var(--2nd-text-color, #808191);
          font-family: Manrope;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.375rem; /* 157.143% */
        }
      }
      .img {
        width: 100%;
        /* height: 8.375rem; */
        object-fit: cover;
      }

      .buttons {
        display: flex;
        align-items: center;
        gap: 0.625rem;
        align-self: stretch;
        p {
          display: flex;
          padding: 0.4375rem 0.625rem;
          align-items: center;
          gap: 0.625rem;
          border-radius: 0.9375rem;
          border: 1px solid #1e7d59;
          background: var(--White-color, #fcfcfc);
          color: #1e7d59;

          /* Semibold 12 */
          font-family: Manrope;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }
    .middleColumn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      width: 50%;
      .middleContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        .completionTime {
          all: unset;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          h5 {
            color: #344054;
            /* font-size: 0.875rem; */
            font-style: normal;
            font-weight: 500;
            text-align: left;
          }
        }
      }
      .locationDiv {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        .sinlgeicon {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 0.1rem;
          svg {
            width: 1.5rem;
            height: 1.5rem;
          }
        }
      }
      .moneyLocation {
        display: flex;
        align-items: center;
        /* justify-content: center; */
        div {
          display: flex !important;
          gap: 1rem;
          padding-top: 0.3rem;
        }
      }
      .buttons {
        display: flex;
        align-items: center;
        gap: 0.625rem;
        align-self: stretch;
        flex-wrap: wrap;
        p {
          display: flex;
          padding: 0.4375rem 0.625rem;
          align-items: center;
          gap: 0.625rem;
          border-radius: 0.9375rem;
          border: 1px solid #1e7d59;
          background: var(--White-color, #fcfcfc);
          color: #1e7d59;

          /* Semibold 12 */
          font-family: Manrope;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
      h5 {
        color: rgb(52, 64, 84);
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        text-align: left;
      }
      p {
        font-size: 0.7rem;
      }
    }
    .lastColumn {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 1rem;
      width: 15%;

      .buttonsContainer {
        display: flex;
        align-items: center;
        gap: 0.625rem;
        .details {
          color: #1e7d59;
          font-family: Manrope;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          display: flex;
          padding: 0.625rem 1rem;
          align-items: flex-start;
          gap: 0.625rem;
          border-radius: 0.375rem;
          border: 1px solid #1e7d59;
          background: var(--White-color, #fcfcfc);
        }
        .approve {
          display: flex;
          padding: 0.625rem 1rem;
          align-items: flex-start;
          gap: 0.625rem;
          color: var(--White-color, #fcfcfc);
          font-family: Manrope;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          border-radius: 0.375rem;
          background: #01545a;
        }
      }
      .moneyDiv {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        p {
          font-size: 1rem;
          font-weight: bolder;
          text-align: center;
        }
        .ppw {
          font-size: 0.8rem;
          font-weight: 500;
          position: relative;
          &:before {
            position: absolute;
            content: "";
            width: 100%;
            height: 1.9px;
            background-color: #ddd;
            top: -0.5rem;
            left: 0;
          }
        }
      }
    }
    button {
      cursor: pointer;
      border: none;
    }
    .selected {
      position: absolute; /* Position the badge relative to the container */
      top: 0; /* Distance from the top edge */
      right: 0; /* Distance from the right edge */
      background: #01545a;
      padding: 5px 10px;
      border-radius: 5px; /* Rounded corners */
      font-size: 12px; /* Adjust font size */
      /* height: 15px; */
      /* width: 2rem; */
      border-top-right-radius: 0.5rem;
      p {
        color: white;
      }
    }
    .verfied {
      position: absolute;
      top: -0.5px;
      left: -0.5px;
    }

    .reviews {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      width: 100% !important;
      height: 100%;
      gap: 5px;
      div:first-child {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  @media (max-width: 767px) {
    .singleCard {
      .container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .column,
        .middleColumn,
        .lastColumn {
          width: 100%;
        }
        .img_time {
          flex-direction: column !important;
        }
      }
    }
  }
`;
