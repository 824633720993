import {
  GET_LEADS_BANK,
  CheckOut,
  GetAllInvoices,
  GetInvoice,
  Buy_invoice,
  Delete_invoice,
} from "../../API/Api";
import { HideLoading, ShowLoading } from "./loadingAction";
import swal from "sweetalert";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { SignOut } from "./authActions";

export const GET_LEAD_BANK = "GET_LEAD_BANK";
export const CHECK_OUT_LEADS = "CHECK_OUT_LEADS";
export const GET_ALL_INVOICES = "GET_ALL_INVOICES";
export const GET_SINGLE_INVOICE = "GET_SINGLE_INVOICE";
export const BUY_INVOICE = "BUY_INVOICE";
export const DELETE_INVOICE = "DELETE_INVOICE";

export const GetLeadsBank = (data) => {
  return async (dispatch) => {
    dispatch(ShowLoading());
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    await axios({
      method: "GET",
      baseURL: `${GET_LEADS_BANK}/${data}`,
      headers: headers,
    })
      .then((res) => {
        dispatch(HideLoading());

        dispatch({
          type: GET_LEAD_BANK,
          leadBanks: res.data.data,
        });
      })
      .catch((err) => {
        dispatch(HideLoading());

        console.log(err.data);
        if (err?.response?.status === 401) {
          dispatch(SignOut());
        }
      });
  };
};

export const CheckOutLeads = (data) => {
  return async (dispatch) => {
    dispatch(ShowLoading());
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    await axios({
      method: "POST",
      baseURL: `${CheckOut}`,
      headers: headers,
      data: data,
    })
      .then((res) => {
        dispatch(HideLoading());
        // navigate(`/payment-leads/${res?.data?.data?.id?.toString()}`)
        dispatch({
          type: CHECK_OUT_LEADS,
          checkOut: res.data.data,
        });
      })
      .catch((err) => {
        dispatch(HideLoading());

        console.log(err.data);
        if (err?.response?.status === 401) {
          dispatch(SignOut());
        }
      });
  };
};

export const GetInvoices = (data) => {
  return async (dispatch) => {
    dispatch(ShowLoading());
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    await axios({
      method: "GET",
      baseURL: `${GetAllInvoices}`,
      headers: headers,
      data: data,
    })
      .then((res) => {
        dispatch(HideLoading());

        dispatch({
          type: GET_ALL_INVOICES,
          all_invoices: res.data.data,
        });
      })
      .catch((err) => {
        dispatch(HideLoading());

        console.log(err.data);
        if (err?.response?.status === 401) {
          dispatch(SignOut());
        }
      });
  };
};

export const GetInvoiceById = (data) => {
  return async (dispatch) => {
    dispatch(ShowLoading());
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    await axios({
      method: "GET",
      baseURL: `${GetInvoice}/${data}`,
      headers: headers,
    })
      .then((res) => {
        dispatch(HideLoading());

        dispatch({
          type: GET_SINGLE_INVOICE,
          singleInvoice: res.data,
        });
      })
      .catch((err) => {
        dispatch(HideLoading());

        console.log(err.data);
        if (err?.response?.status === 401) {
          dispatch(SignOut());
        }
      });
  };
};

export const BuyInvoice = (data) => {
  return async (dispatch) => {
    dispatch(ShowLoading());
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    await axios({
      method: "POST",
      baseURL: `${Buy_invoice}`,
      headers: headers,
      data: data,
    })
      .then((res) => {
        dispatch({
          type: BUY_INVOICE,
          buyInvoice: res.data,
        });
        console.log("Response", res.data);
        dispatch(HideLoading());
      })
      .catch((err) => {
        dispatch(HideLoading());
        console.log(err.data);
        if (err?.response?.status === 401) {
          dispatch(SignOut());
        }
      });
  };
};

export const DeleteInvoice = (data) => {
  return async (dispatch) => {
    dispatch(ShowLoading());
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    await axios({
      method: "DELETE",
      baseURL: `${Delete_invoice}/${data}`,
      headers: headers,
    })
      .then((res) => {
        dispatch(HideLoading());

        swal({
          title: "Success",
          text: `Leads has been deleted`,
          icon: "success",
          button: true,
        });
        dispatch({
          type: DELETE_INVOICE,
          deleteInvoice: res.data,
        });
      })
      .catch((err) => {
        dispatch(HideLoading());

        console.log(err.data);
        if (err?.response?.status === 401) {
          dispatch(SignOut());
        }
      });
  };
};
