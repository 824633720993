import React from "react";
import styled from "styled-components";
import image from "../../assets/soon.png";
const ComingSoon = () => {
  return (
    <ComingSoonStyled>
      <div className="image">
        <img src={image} alt="soon" />
      </div>
      <h2>coming soon ....</h2>
    </ComingSoonStyled>
  );
};

const ComingSoonStyled = styled.div`
  .image {
    width: 100%;
    height: 400px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  h2 {
    font-size: 3.2rem;
    text-align: center;
    color: #82b1ff;
  }
`;

export default ComingSoon;
