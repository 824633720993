import styled from "styled-components";
import React from "react";
import Grid from "@mui/material/Grid";
import getPaid from "../../../../assets/GetPaidOnTime.png";
import speed from "../../../../assets/SpeedToRoof.png";
import permit from "../../../../assets/Permits&Paperwork.png";
import communications from "../../../../assets/Communications.png";
import training from "../../../../assets/Training.png";
import projectprogression from "../../../../assets/ProjectProgression.png";
const BigSection = styled.div`
  padding: 100px 0;
`;
const Header = styled.p`
  text-align: center;
  font-size: 30px;
  font-weight: 800;
`;
const SecondHeader = styled.p`
  font-size: 18px;
  text-align: center;
`;
const Thediv = styled.div`
  padding: 0 20px;
  @media (max-width: 425px) {
    padding: 0;
  } ;
`;
const Image = styled.img``;
const Image1 = styled.img`
  position: absolute;
  left: -60px;
  @media (max-width:600px) {
    display: none;
  }
`;
const Image2 = styled.img`
  position: absolute;
  top: -15px;
  left: -50px;
  @media (max-width:600px) {
    display: none;
  }
`;
const Image3 = styled.img`
  position: absolute;
  top: -15px;
  left: -70px;
  @media (max-width:600px) {
    display: none;
  }
`;
const ThedivHeader = styled.p`
  text-align: left;
`;
const Description = styled.p`
  text-align: left;
  /* padding-right: 70px; */
  width: 80%;
  font-size: 12px;
  @media (max-width: 1024px) {
    /* padding-right: 45px; */
    width: 100%;
    padding: 0;
    margin: 0;
  }
  @media (max-width:600px) {
    width: 100%;
    padding: 0;
    margin: 0;
  }
  @media (max-width: 425px) {
    padding-right: 0;
  } ;
`;
const Content = styled.div`
  position: relative;
  padding: 0 20px;
`;
const ButtonContainer = styled.div`
  text-align: center;
  padding-top: 100px;
`;
const Button = styled.button`
  color: white;
  background-color: #150700;
  padding: 15px 42px;
  border: none;
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  @media (max-width: 425px) {
    font-size: 10px;
  }
`;
const BigDiv=styled.div`
padding-top: 100px;
margin-left: 270px;
@media (max-width:600px) {
  margin-left: 0;
}
`
const HowWeSell = () => {
  return (
    <BigSection>
      <Grid
        container
        spacing={0}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item lg={8} md={8} sm={8} xs={12}>
          <Header>
            You know how to sell solar, but it doesn't matter if panels never
            make it to the roof.
          </Header>
        </Grid>
        <Grid item lg={8} md={8} sm={8} xs={12}>
          <SecondHeader>
            By entrusting your installs to our dedicated team of experts, you
            can focus on selling and increase your pipeline velocity.
          </SecondHeader>
        </Grid>
      </Grid>
      <BigDiv >
        {/* First Row */}
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="flex-start"
        // alignItems="center"
        >
          {" "}
          <Grid item lg={3.5} md={3} sm={9} xs={7}>
            <Thediv>
              <Content>
                <Image1 src={getPaid}></Image1>
                <ThedivHeader>Get Paid On Time</ThedivHeader>
                <Description>
                  Tired of chasing your earnings?  We pay weekly every Friday based upon job completion.
                </Description>
              </Content>
            </Thediv>
          </Grid>
          <Grid item lg={3.5} md={3} sm={9} xs={7}>
            <Thediv>
              <Content>
                <Image1 src={speed}></Image1>
                <ThedivHeader>Job Completion</ThedivHeader>
                <Description>
                  Waiting too long for your projects to complete? Our high-level Project Management team can ensure a speedy turnaround on projects. Our approach ensures an amazing customer experience, while you can get paid quickly and get referrals.
                </Description>
              </Content>
            </Thediv>
          </Grid>
          <Grid item lg={3.5} md={3} sm={9} xs={7}>
            <Thediv>
              <Content>
                <Image2 src={permit}></Image2>
                <ThedivHeader>Permits & Paperwork</ThedivHeader>
                <Description>
                  Have you been spending too much time processing your own jobs? Our dedicated team secured and processes your job every step of the way from point of sale to completion. Giving you the ability to focus on your sales.

                </Description>
              </Content>
            </Thediv>
          </Grid>
        </Grid>
        {/* Second Row */}
        <div style={{ paddingTop: "100px" }}>
          <Grid
            container
            spacing={0}
            col={12}
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
          >
            {" "}
            <Grid item lg={3.5} md={3} sm={9} xs={7}>
              <Thediv>
                <Content>
                  <Image2 src={communications}></Image2>
                  <ThedivHeader>Customer Experience</ThedivHeader>
                  <Description>
                    Our staff prides themselves on the internal and external customer experience. Our platform auto triggers your clients to keep them updated throughout the process along with a customer portal access for full transparency.

                  </Description>
                </Content>
              </Thediv>
            </Grid>
            <Grid item lg={3.5} md={3} sm={9} xs={7}>
              <Thediv>
                <Content>
                  <Image2 src={training}></Image2>
                  <ThedivHeader>Sales &Product Training:</ThedivHeader>
                  <Description>
                    Elevate and grow your team quicker with the BoxBYLD support platform that has training curriculums for all products sold through our platform. Have access to learning and selling more home improvement products and understanding how and when to use our finance options.
                  </Description>
                </Content>
              </Thediv>
            </Grid>
            {/* <Grid item lg={3.5} md={3} sm={9} xs={7}>
              <Thediv>
                <Content>
                  <Image3 src={projectprogression}></Image3>
                  <ThedivHeader>Project Progression</ThedivHeader>
                  <Description>
                    Don't know which projects are at what stage? Track every
                    project on your dashboard and receive notifications
                    alongside your customer at every step.
                  </Description>
                </Content>
              </Thediv>
            </Grid> */}
          </Grid>
        </div>
      </BigDiv>
      <ButtonContainer>
        <Button>Become An BoxBYLD Authorized Dealer</Button>
      </ButtonContainer>
    </BigSection>
  );
};
export default HowWeSell;
