import styled from "styled-components";
import React from "react";
import Grid from "@mui/material/Grid";
import solarpanel from "../../../../assets/solarpanel.png";
import windtubines from "../../../../assets/windtubines.png";
import greenequipment from "../../../../assets/greenequipment.png";
import Slider from "react-slick";
const BigSection = styled.div`
  padding: 100px 0;
`;
const FirstDiv = styled.div`
  background-color: #f8f8f8;
  text-align: center;
  margin-top: 100px;
  height: 300px;
`;
const Content = styled.div`
  padding: 30px 15px;
`;
const OurServiceWord = styled.p`
  text-align: center;
  font-size: 35px;
  font-weight: Bold;
`;
const Description = styled.p`
  text-align: center;
`;
const Image = styled.img``;
const Name = styled.p`
  font-weight: bold;
  padding-top: 10px;
`;
const OurServiceDes = styled.p``;
var settingSlider = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};
const OurService = () => {
  return (
    <BigSection>
      <Grid
        container
        spacing={0}
        // col={12}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item lg={8}>
          <OurServiceWord>BoxBYLD Features</OurServiceWord>
          <Description>
            Solar has a powerful impact. Generate your own clean energy, reduce
            your monthly electricity bills, protect yourself from rate hikes and
            use those savings to empower your life.
          </Description>
        </Grid>
      </Grid>
      <Grid
        // container
        spacing={2}
        // col={12}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
      >
        <Grid item xs={12} md={4} lg={3} sm={4}>
          <FirstDiv>
            <Content>
              <Image src={solarpanel}></Image>
              <Name>Solar Panel</Name>
              <OurServiceDes>
                Team BYLDing and performance monitoring applications.
              </OurServiceDes>
            </Content>
          </FirstDiv>
        </Grid>
        <Grid item xs={12} md={4} lg={3} sm={4}>
          <FirstDiv>
            <Content>
              <Image src={windtubines}></Image>
              <Name>Wind Turbines</Name>
              <OurServiceDes>
                Customer portal for real-time project status updates from sales and project teams.
              </OurServiceDes>
            </Content>
          </FirstDiv>
        </Grid>
        <Grid item xs={12} md={4} lg={3} sm={4}>
          <FirstDiv>
            <Content>
              <Image src={greenequipment}></Image>
              <Name>Green Equipment</Name>
              <OurServiceDes>
                AI/ML to enhance your sales capabilities
              </OurServiceDes>
            </Content>
          </FirstDiv>
        </Grid>
      </Grid>
       {/* <Slider {...settingSlider}>
              <div style={{color:"white",textAlign:"center",margin:"auto"}}>
                <h3 style={{textAlign:"center",color:"white"}}>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Suscipit eligendi neque quibusdam unde consectetur similique tenetur quae. Officiis, minima accusamus cum, id libero ipsum aspernatur fugit cumque repellat cupiditate error.</h3>
              </div>
              <div>
                <h3>2</h3>
              </div>
            </Slider> */}
    </BigSection>
  );
};
export default OurService;
