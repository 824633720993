import styled from "styled-components";

export const RightStyle = styled.section`
  padding: 0 1rem 0 2rem;
`;
export const GridConatiner = styled.div`
  display: grid;
  gap: 15px;
  margin: 1rem 0;
  grid-template-columns: repeat(auto-fill, minmax(150px, 2fr));
  padding-top: 50px;
`;
export const ThumbnailCard = styled.div`
  padding: 1.5rem 1rem;
  border-radius: 1rem;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.12);
  background: #f9f9f9;
  display: flex;
  align-items: center;
  overflow: hidden;

  .icon {
    width: 70px;
    height: 70px;
    background: #eee;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    &.challenges {
      background: #45c8f126;
    }
    &.conis {
      background: #4ab2716b;
    }
    &.teams {
      background: #45c8f155;
    }
    &.Royalties {
      background: #fed42d52;
    }
    &.solution {
      background: #55a4f947;
    }
    &.leads {
      background: #f0692333;
    }
    img {
      width: 50px;
      height: 50px;
      object-fit: cover;
    }
  }
  .data {
    .title {
      text-transform: capitalize;
      white-space: nowrap;
      overflow: hidden;
      font-size: 0.7rem;
      text-overflow: ellipsis;

      color: #888;
    }
    .number {
      font-size: 1.5rem;
      font-weight: bold;
      color: #555;
    }
  }
`;
