import { ThumbnailCard } from "../RightStyled";
import team from "../../../assets/teamwork.png";

const Teams = ({ SalseUser }) => {
  return (
    <>
      <ThumbnailCard>
        <div className="icon teams">
          <img src={team} alt="team" />
        </div>
        <div className="data">
          <div className="title">Teams </div>
          <div className="number">{SalseUser?.children?.length}</div>
        </div>
      </ThumbnailCard>
    </>
  );
};

export default Teams;
