import {
  ADD_SUCCESSFULLY,
  FEATCH_ERROR,
  HIDE_LOADING,
  SHOW_LOADING,
} from "./type";

export const ShowLoading = () => (dispatch) => {
  dispatch({
    type: SHOW_LOADING,
  });
};
export const HideLoading = () => (dispatch) => {
  dispatch({
    type: HIDE_LOADING,
  });
};
export const featchSuccess = () => (dispatch) => {
  dispatch({
    type: ADD_SUCCESSFULLY,
  });
};
export const featchError = () => (dispatch) => {
  dispatch({
    type: FEATCH_ERROR,
  });
};
