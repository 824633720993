import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Modal,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
// import { AddCircle } from "@mui/icons-material";
import styled from "styled-components";
import ProjectsList from "./ProjectsList";
import { useDispatch, useSelector } from "react-redux";
// import { SunlightAction } from "../../../Redux/Actions/sunlightActions";
import { useEffect, useState } from "react";
// import { SunLightCalc } from "../../../Redux/Actions/CalculatorActions";
import { loadSunLightCalc } from "../../../Redux/Actions/CalculatorActions";
import {
  // ShowLoadingSun,
  SunlightAction,
} from "../../../Redux/Actions/sunlightActions";
import Loading from "../../../components/loading/Loading";
import LoadingSubmit from "../../../components/loading/LoadingSubmit";

//styled model
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  borderRadius: "2rem",
  boxShadow: 24,
  p: 8,
};
const PrequalProjects = ({ LeadId }) => {
  //dispatch model
  const dispatch = useDispatch();

  /***************************** */

  //model const
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //create state
  const [data, setData] = useState({
    termId: "",
    term: "",
    apr: "",
  });

  //get sunlight Calc
  useEffect(() => {
    dispatch(loadSunLightCalc());
  }, [dispatch]);

  const { sunlightCalc, isCalc } = useSelector((state) => state.calc);

  //get the TERM depends on ID
  const TermID = data.termId;
  const singleTerm = !isCalc
    ? sunlightCalc?.find((item) => item.id == TermID)
    : null;

  console.log(singleTerm);
  ///////////////////////////////
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(SunlightAction(LeadId, data));
  };

  return (
    <>
      <PrequalProjectStyled>
        <div className="AddProjectList">
          {/* comment the onclick  */}
          {/* <Button
            onClick={handleOpen}
            startIcon={<AddCircle />}
            className="add-project"
          >
            Add New project
          </Button> */}
          <div className="ProjectsConatiner">
            <ProjectsList LeadId={LeadId} />
          </div>
        </div>
      </PrequalProjectStyled>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {!isCalc && (
            <>
              <form onSubmit={handleSubmit}>
                <FormConatiner>
                  <FormControl fullWidth className="formControl">
                    <InputLabel id="demo-simple-select-label">
                      Term in Year
                    </InputLabel>
                    <Select
                      id="demo-simple-select"
                      required
                      value={data.termId}
                      label="Term in Years "
                      onChange={(e) =>
                        setData({ ...data, termId: e.target.value })
                      }
                    >
                      {sunlightCalc?.map((data) => (
                        <MenuItem key={data.id} value={data.id}>
                          {data.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </FormConatiner>
                <FormConatiner>
                  <TextField
                    className="formControl"
                    fullWidth
                    label="Term in Month"
                    disabled={true}
                    value={(data.term = singleTerm?.term) || "Term in Month"}
                    onChange={(e) =>
                      setData({ ...data, term: e.target.value }) || false
                    }
                  />
                </FormConatiner>
                <FormConatiner>
                  <TextField
                    label="APR"
                    className="formControl"
                    fullWidth
                    disabled={true}
                    value={(data.apr = singleTerm?.apr) || "Apr"}
                    onChange={(e) =>
                      setData({ ...data, apr: e.target.value }) || false
                    }
                  />
                </FormConatiner>
                <FormConatiner>
                  <Button type="submit" className="btnAddProject">
                    Add new Project
                  </Button>
                </FormConatiner>
              </form>
            </>
          )}
          {isCalc && <Loading />}
        </Box>
      </Modal>

      <LoadingSubmit />
    </>
  );
};

const PrequalProjectStyled = styled.div`
  .add-project {
    background: #1c9266;
    border: 1px solid #1c9266;
    color: #fff;
    padding: 0.4rem 1rem;
    border-radius: 2rem;
    &:hover {
      background: transparent;
      color: #1c9266;
      box-shadow: 0 2px 8px #1c926655;
    }
  }
  .ProjectsConatiner {
    margin: 2rem 0 1rem 0;
    background: #f5f5f5;
    padding: 1rem;
    border-radius: 1rem;
  }
`;

const FormConatiner = styled.div`
  .formControl {
    margin-bottom: 1.5rem;
  }
  .btnAddProject {
    background: #479dcc;
    color: #fff;
    border-radius: 2rem;
    padding: 0.5rem 1rem;
  }
`;
export default PrequalProjects;
