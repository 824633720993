import {
  ACHIEVEMENT,
  PARENT_ACHIEVEMENT,
  SALES_ACHIEVEMENT,
  EDIT_ACHIEVEMENT,
  GET_ACHIEVEMENT,
} from "../Actions/type";

const initialState = {
  achievementRes: "",
  parentAchievement: "",
  salesAcievement: "",
  singleAcievement: "",
  editAcievement: "",
};

const achievementsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACHIEVEMENT:
      return {
        ...state,
        achievementRes: action.payload,
      };
    case PARENT_ACHIEVEMENT:
      return {
        ...state,
        parentAchievement: action.payload,
      };
    case SALES_ACHIEVEMENT:
      return {
        ...state,
        salesAcievement: action.payload,
      };
    case GET_ACHIEVEMENT:
      return {
        ...state,
        singleAcievement: action.payload,
      };
    case EDIT_ACHIEVEMENT:
      return {
        ...state,
        editAcievement: action.payload,
      };

    default:
      return { ...state };
  }
};

export default achievementsReducer;
