import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./helper/index.js";

export const coinsApi = createApi({
  reducerPath: "coinsAPI",
  tagTypes: ["coins"],
  baseQuery,
  endpoints: (builder) => ({
    getCoins: builder.query({
      query: (page) => ({
        method: "GET",
        url: "/coins/get-balance",
        params: {
          page: page,
        },
      }),
      providesTags: ["coins"],
    }),
    checkOut: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "/coins/check-out",
        body,
      }),
      providesTags: ["coins"],
    }),
    getcoinsResult: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "/coins/exchange-balance",
        body,
      }),
    }),
  }),
});

export const {
  useGetCoinsQuery,
  useGetcoinsResultMutation,
  useCheckOutMutation,
} = coinsApi;
