import {
  AttachMoney,
  EditLocationAlt,
  EventNote,
  NotificationImportant,
  Payment,
  PictureAsPdf,
  Send,
  Visibility,
} from "@mui/icons-material";
import { Button, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { singleLeadAction } from "../../Redux/Actions/singleLeadAction";
import { sendContractToSign } from "../../Redux/Actions/solutionsActions";
import LoadingSoultion from "../loading/loadingSolution";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import { SendContractToSign } from "../../Redux/Actions/addRoofingAction";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom";
import { MAIN_URL } from "../../API/Api";
import { CreateTicket } from "../../Redux/Actions/CreateTicket";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ContractProposals = ({ contracts, LeadId }) => {
  const { leads } = useSelector((state) => state);
  // const singleLead = leads?.singlead;
  const { singlead } = useSelector((state) => state.leads);
  const roofingContracts = singlead?.roof_contracts || [];
  console.log("singleLead contracts", singlead.solar_contracts);
  // Modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const [images, setImages] = React.useState([]);
  const location = useLocation();
  LeadId = location.pathname.split("/")[2];

  // React.useEffect(()=>{
  //   dispatch(singleLeadAction(LeadId))
  // },[])
  // Steps
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = [
    {
      label: "Select campaign settings",
      description: `For each ad campaign that you create, you can control how much
                you're willing to spend on clicks and conversions, which networks
                and geographical locations you want your ads to show on, and more.`,
    },
    {
      label: "Create an ad group",
      description:
        "An ad group contains one or more ads which target a shared set of keywords.",
    },
    {
      label: "Create an ad",
      description: `Try out different ad text to see what brings in the most customers,
                and learn how to enhance your ads using features like ad extensions.
                If you run into any problems with your ads, find out how to tell if
                they're running and how to resolve approval issues.`,
    },
  ];
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const dispatch = useDispatch();
  const { isLoading, createContact } = useSelector((state) => state.proposal);
  console.log("contracts", contracts);

  const YearFn = (date) => {
    return new Date(date).getFullYear();
  };
  const DayFn = (date) => {
    const day = new Date(date).getDate();
    if (day < 10) {
      return `0${day}`;
    } else {
      return day;
    }
  };
  const MonthFn = (date) => {
    const month = new Date(date).getMonth() + 1;
    if (month < 10) {
      return `0${month}`;
    } else {
      return month;
    }
  };

  React.useEffect(() => {
    axios({
      method: "get",
      url: `${MAIN_URL}/api/v1/leads/${id}/media`,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    }).then(function (response) {
      setImages(response.data.data);
    });
  }, []);
  let ticektData = {
    lead_id: id,
  };
  const createTicket = () => {
    dispatch(CreateTicket(ticektData));
  };
  const FindFinantial = (id) => {
    return singlead?.opportunities.find((e) => e.id === id);
  };
  const [selectedContract, setSeletedContract] = React.useState();
  console.log("selectedContract", selectedContract);
  return (
    <>
      <OpportunitiesStyled>
        <div className="container">
          {/* <h2>Contracts</h2> */}
          {/* Solar Contract */}

          {singlead?.solar_contracts?.length !== 0 ? (
            <>
              <h3>Solar Contracts</h3>

              <hr></hr>
              <div className="buttons">
                {singlead?.solar_contracts?.map((prop) => (
                  <div key={prop.id} className="opportunity">
                    <div className="prpo_data_flex">
                      <div className="prposals_Data">
                        <Link
                          to={`/profilelead/single-contract/${LeadId}/${prop.id}`}
                        >
                          <p className="data">
                            <span className="key">
                              <EditLocationAlt />
                            </span>
                            <span className="value">
                              {" "}
                              Contract ID :{prop.id}
                            </span>
                            {/* <Stauts className="value"> */}{" "}
                            {/* <GetStatus onClick={handleOpen}>Status :</GetStatus> */}
                            {/* </Stauts> */}
                          </p>
                          <p className="data">
                            <span className="key">
                              <AttachMoney />
                            </span>
                            <span className="vlaue">
                              Cost : &#36;{Math.ceil(prop.cost)}
                            </span>
                          </p>
                          <p className="data">
                            <span className="key">
                              <Payment />
                            </span>
                            <span className="vlaue">
                              {prop.is_finance === 1 ? (
                                <span>
                                  Financed:{" "}
                                  {
                                    FindFinantial(prop?.opportunity_id)
                                      ?.financial_institution
                                  }
                                </span>
                              ) : (
                                <span>CASH</span>
                              )}
                            </span>
                          </p>
                          <p className="data">
                            <span className="key">
                              <EventNote />
                            </span>

                            <span className="value">
                              {/* {MonthFn(prop.created_at) +
                              "-" +
                              DayFn(prop.created_at) +
                              "-" +
                              YearFn(prop.created_at)} */}
                              {new Date(prop.created_at).toLocaleDateString(
                                "en-US",
                                {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                }
                              )}
                            </span>
                          </p>
                        </Link>
                      </div>
                      <div className="links">
                        <a
                          href={prop.contract_url}
                          target="_blank"
                          className="show"
                        >
                          <PictureAsPdf />
                        </a>
                        <Button
                          endIcon={
                            prop.document_hash === null ? (
                              <Send />
                            ) : (
                              <NotificationImportant />
                            )
                          }
                          className={
                            prop.document_hash === null
                              ? "sendBtn"
                              : "sendBtn reminder"
                          }
                          onClick={async () => {
                            await dispatch(sendContractToSign(prop.id));
                            await dispatch(singleLeadAction(LeadId));
                          }}
                        >
                          {prop.document_hash === null
                            ? "Send contract to sign"
                            : "Send Reminder"}
                        </Button>
                      </div>
                      {prop.is_finance === 0 ? null : (
                        <div className="createButton">
                          {/* <Link
                            to={`/profilelead/${LeadId}/finance/${
                              FindFinantial(prop?.opportunity_id)
                                ?.financial_institution === "goodleap"
                                ? "finance-goodleap"
                                : "finance-sunlight"
                            }`}
                          >
                            <button>Finance</button>
                          </Link> */}
                          {/* <button
                            onClick={() => {
                              setSeletedContract(prop);
                              handleOpen();
                            }}
                          >
                            Finance
                          </button> */}
                        </div>
                      )}

                      <div className="createButton">
                        {prop.document_hash !== null ? (
                          <>
                            {prop.has_ticket === 0 ? (
                              <button
                                onClick={async () => {
                                  await dispatch(
                                    CreateTicket({
                                      lead_id: Number(id),
                                      contract_id: prop?.id,
                                    })
                                  );
                                  await dispatch(singleLeadAction(LeadId));
                                }}
                              >
                                Create Ticket
                              </button>
                            ) : (
                              <button>Already has Ticket</button>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      {prop.is_finance === 1 ? (
                        <Link
                          to={`/profilelead/single-contract/${LeadId}/${prop.id}`}
                        >
                          <Button
                            style={{
                              width: "100%",
                              color: "green",
                              border: "1px solid green",
                              marginTop: "10px",
                            }}
                          >
                            Finace Now
                          </Button>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ))}
                {/* -------------------------------- */}

                {/* {Object.keys(createContact).length !== 0 && (
                  <div className="opportunity">
                    <div className="prpo_data_flex">
                      <div className="prposals_Data">
                        <p className="data">
                          <span className="key">
                            <EditLocationAlt />
                          </span>

                          <span className="value">
                            {" "}
                            Contract ID :{createContact.id}
                          </span>
                        </p>
                        <p className="data">
                          <span className="key">
                            <AttachMoney />
                          </span>
                          <span className="vlaue">
                            Cost : &#36;{Math.ceil(createContact.cost)}
                          </span>
                        </p>
                        <p className="data">
                          <span className="key">
                            <Payment />
                          </span>
                          <span className="vlaue">
                            {createContact.is_finance === 0 ? (
                              <span>Cach</span>
                            ) : (
                              <span>Financed</span>
                            )}
                          </span>
                        </p>
                        <p className="data">
                          <span className="key">
                            <EventNote />
                          </span>

                          <span className="value">
                            {MonthFn(createContact.created_at) +
                              "-" +
                              DayFn(createContact.created_at) +
                              "-" +
                              YearFn(createContact.created_at)}
                          </span>
                        </p>
                      </div>
                      <div className="links">
                        <a
                          href={createContact.contract_url}
                          target="_blank"
                          className="show"
                        >
                          <Visibility />
                        </a>
                        <Button
                          endIcon={
                            createContact.document_hash === null ? (
                              <Send />
                            ) : (
                              <NotificationImportant />
                            )
                          }
                          className={
                            createContact.document_hash === null
                              ? "sendBtn"
                              : "sendBtn reminder"
                          }
                          onClick={() =>
                            dispatch(sendContractToSign(createContact.id))
                          }
                        >
                          {createContact.document_hash === null
                            ? "Send contract to sign"
                            : "Send Reminder"}
                        </Button>
                      </div>
                    </div>
                  </div>
                )} */}
              </div>
              {/* -------------------------------- */}
            </>
          ) : (
            <br />
          )}
          {/* Roofing Contracts */}

          {singlead?.roof_contracts?.length > 0 ? (
            <>
              <h3>Roofing Contracts</h3>
              <hr></hr>
              <div className="buttons">
                {singlead?.roof_contracts?.map((prop) => (
                  <div key={prop.id} className="opportunity">
                    <div className="prpo_data_flex">
                      <div className="prposals_Data">
                        <p className="data">
                          <span className="key">
                            <EditLocationAlt />
                          </span>
                          <span className="value"> Contract ID :{prop.id}</span>
                          <Stauts className="value">
                            {" "}
                            {/* <GetStatus onClick={handleOpen}>Status :</GetStatus> */}
                          </Stauts>
                          {/* Modal */}
                          <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style}>
                              <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                              >
                                Status
                              </Typography>
                              <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2 }}
                              >
                                {/* Steeper */}
                                <Box sx={{ maxWidth: 400 }}>
                                  <Stepper
                                    activeStep={activeStep}
                                    orientation="vertical"
                                  >
                                    <Step></Step>
                                  </Stepper>
                                </Box>
                              </Typography>
                            </Box>
                          </Modal>
                        </p>
                        <p className="data">
                          <span className="key">
                            <AttachMoney />
                          </span>
                          <span className="vlaue">
                            Cost : &#36;{Math.ceil(prop.total_cost)}
                          </span>
                        </p>
                        <p className="data">
                          <span className="key">
                            <Payment />
                          </span>
                          <span className="vlaue">
                            {/* {prop.is_finance === 0 ? (
                              <span>Cach</span>
                            ) : (
                              <span>Financed</span>
                            )} */}
                            <span>Cash</span>
                          </span>
                        </p>
                        <p className="data">
                          <span className="key">
                            <EventNote />
                          </span>

                          <span className="value">
                            {new Date(prop.created_at).toLocaleDateString(
                              "en-US",
                              {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              }
                            )}
                          </span>
                        </p>
                      </div>
                      <div className="links">
                        <a
                          href={prop.contract_url}
                          target="_blank"
                          className="show"
                        >
                          <PictureAsPdf />
                        </a>
                        <Button
                          endIcon={
                            prop.document_hash === null ? (
                              <Send />
                            ) : (
                              <NotificationImportant />
                            )
                          }
                          className={
                            prop.document_hash === null
                              ? "sendBtn"
                              : "sendBtn reminder"
                          }
                          onClick={() => dispatch(SendContractToSign(prop.id))}
                        >
                          {prop.document_hash === null
                            ? "Send contract to sign"
                            : "Send Reminder"}
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}
                {/* -------------------------------- */}

                {/* {Object.keys(createContact).length !== 0 && (
                  <div className="opportunity">
                    <div className="prpo_data_flex">
                      <div className="prposals_Data">
                        <p className="data">
                          <span className="key">
                            <EditLocationAlt />
                          </span>

                          <span className="value">
                            {" "}
                            Contract ID :{createContact.id}
                          </span>
                        </p>
                        <p className="data">
                          <span className="key">
                            <AttachMoney />
                          </span>
                          <span className="vlaue">
                            Cost : &#36;{Math.ceil(createContact.cost)}
                          </span>
                        </p>
                        <p className="data">
                          <span className="key">
                            <Payment />
                          </span>
                          <span className="vlaue">
                            {createContact.is_finance === 0 ? (
                              <span>Cach</span>
                            ) : (
                              <span>Financed</span>
                            )}
                          </span>
                        </p>
                        <p className="data">
                          <span className="key">
                            <EventNote />
                          </span>

                          <span className="value">
                            {MonthFn(createContact.created_at) +
                              "-" +
                              DayFn(createContact.created_at) +
                              "-" +
                              YearFn(createContact.created_at)}
                          </span>
                        </p>
                      </div>
                      <div className="links">
                        <a
                          href={createContact.contract_url}
                          target="_blank"
                          className="show"
                        >
                          <Visibility />
                        </a>
                        <Button
                          endIcon={
                            createContact.document_hash === null ? (
                              <Send />
                            ) : (
                              <NotificationImportant />
                            )
                          }
                          className={
                            createContact.document_hash === null
                              ? "sendBtn"
                              : "sendBtn reminder"
                          }
                          onClick={() =>
                            dispatch(sendContractToSign(createContact.id))
                          }
                        >
                          {createContact.document_hash === null
                            ? "Send contract to sign"
                            : "Send Reminder"}
                        </Button>
                      </div>
                    </div>
                  </div>
                )} */}
              </div>
              {/* -------------------------------- */}
            </>
          ) : (
            <br />
          )}

          {/* -------------------------------- */}
        </div>
      </OpportunitiesStyled>
      {isLoading && <LoadingSoultion />}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

const OpportunitiesStyled = styled.div`
  font-family: "Poppins", sans-serif;
  padding: 24px;
  .opportunity {
    position: relative;
  }
  .createButton {
    /* position: absolute;
    bottom: -1rem;
    z-index: 999;
    width: 100%; */

    button {
      width: 100%;
      background-color: #00a4f0;
      border: 0;
      padding: 0.5rem;
      color: white;
      font-weight: 600;
      font-size: 15px;
      margin: 0.5rem 0;
      /* border-top-right-radius: 8px; */
      cursor: pointer;
    }
  }
  a {
    color: #919191;
  }
  .buttons {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1rem;
    grid-auto-rows: 1fr;

    .prpo_data_flex {
      /* height: 100%; */
      position: relative;
      display: flex;
      padding: 1rem;
      flex-direction: column;
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
      border-radius: 1rem;
      margin: 1rem 0;
      transition: all 0.3s;
      &:hover {
        transform: translateY(-6px);
        box-shadow: 0 3px 8px #0f009533;
        background: #51a1e2;
        p {
          color: #fff;
        }
        svg {
          color: #fff;
        }
      }
      .prposals {
        padding: 2rem;
        background: #f6f6f6;
        color: #777;
        font-weight: 700;
        font-size: 1.1rem;
        box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
        border-radius: 1rem;
      }
      .prposals_Data {
        padding: 1rem 0.5rem;
        color: #888;
        .data {
          display: flex;
          align-items: center;
          margin: 0.5rem 0;
          .key {
            margin-right: 1rem;
            svg {
              color: #9dbfe7;
            }
          }
        }
      }
      .links {
        display: flex;
        align-items: center;
        padding: 0 0.5rem;
        .show {
          color: #00a4f0;
          margin-right: 1rem;

          display: inline-block;
          svg {
            font-size: 2rem;
          }
        }
        .sendBtn {
          background: #639199;
          color: #f0f0f0;
          font-size: 0.7rem;
          border-radius: 2rem;
          text-transform: capitalize;
          padding-inline: 0.7rem;
          &.reminder {
            background-color: #e29b6c;
          }
        }
      }
    }
  }
`;
const Stauts = styled.p`
  position: absolute;
  top: 8px;
  right: 15px;
`;
const GetStatus = styled.button`
  background-image: linear-gradient(
    to right,
    #314755 0%,
    #26a0da 51%,
    #314755 100%
  );
  padding: 10px 15px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
  cursor: pointer;
  &:hover {
    background-position: right center;
    color: #fff;
    text-decoration: none;
  }
`;
export default ContractProposals;
