import { Button, TextField, Modal } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Meeting from "./meeting";
import { useState } from "react";
import VideocamIcon from "@mui/icons-material/Videocam";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  addZoomMeeting,
  getZoomMeeiting,
} from "../../../../Redux/Actions/zoomActions";

const Meetings = () => {
  const { meetings } = useSelector((state) => state.zoom);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getZoomMeeiting());
  }, [dispatch]);

  //form sumit data

  const navigate = useNavigate();
  const [value, setValue] = useState({
    topic: "",
    start_time: "",
    duration: "30",
    password: "123",
  });

  const handelSumbitZoom = (e) => {
    e.preventDefault();
    dispatch(addZoomMeeting(value));
    // console.log("addZoomMeeting");
    setValue({
      topic: "",
      start_time: "",
      duration: "30",
      password: "123",
    });
    navigate("/");
  };

  //handel popup
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Box>
        <AddMeetingContainer>
          <Button className="btn-join" onClick={handleOpen}>
            <VideocamIcon /> create a meeting
          </Button>
        </AddMeetingContainer>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <FormMeeting>
            <form onSubmit={handelSumbitZoom}>
              <TextField
                id="demo-helper-text-misaligned-no-helper"
                label="Name"
                className="inputFeld"
                value={value.topic}
                onChange={(e) => setValue({ ...value, topic: e.target.value })}
              />

              <TextField
                id="datetime-local"
                label="Next appointment"
                type="datetime-local"
                className="inputFeld"
                InputLabelProps={{
                  shrink: true,
                }}
                value={value.start_time}
                onChange={(e) =>
                  setValue({ ...value, start_time: e.target.value })
                }
              />
              <TextField
                id="demo-helper-text-misaligned-no-helper"
                label="duration"
                value={30}
                className="inputFeld"
                disabled
              />
              <TextField
                id="demo-helper-text-misaligned-no-helper"
                label="password"
                value={123}
                className="inputFeld"
                disabled
              />
              <Button
                variant="outlined"
                type="submit"
                className="add-meeting"
                startIcon={<AddCircleOutlineIcon />}
              >
                ADD
              </Button>
            </form>
          </FormMeeting>
        </Modal>
        <BoxMeeting>
          {meetings.map((meeting) => (
            <Meeting
              key={meeting.id}
              topic={meeting.topic}
              time={meeting.start_time}
              url={meeting.join_url}
            />
          ))}
        </BoxMeeting>
      </Box>
    </>
  );
};

const BoxMeeting = styled(Box)`
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
`;
const AddMeetingContainer = styled.div`
  padding: 1rem 0;
  margin-bottom: 1rem;
  border-bottom: 1px solid #3c6fc740;
  .btn-join {
    background: #2774f7;
    color: #fff;
    padding: 0.8rem 1rem;
    border-radius: 0.5rem;

    svg {
      margin-right: 0.5rem;
    }
  }
`;
const FormMeeting = styled.div`
  width: 40%;
  background: #fff;
  margin: 4rem auto;
  border-radius: 1rem;
  padding: 2rem;
  @media screen and (max-width: 992px) {
    width: 70%;
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    .inputFeld {
      margin: 1rem 0;
      width: 80%;
      border-radius: 1rem;
      &:focus {
        border: 1px solid #1b84f1;
      }
      @media screen and (max-width: 992px) {
        width: 100%;
      }
    }
    .add-meeting {
      background: #000;
      color: #fff;
      border: 1px solid #000;
      border-radius: 0.5rem;
    }
  }
`;

export default Meetings;
