import calc from "../../assets/calc.svg";

const Calculator = () => {
  return (
    <div className="image">
      <img src={calc} alt="calc" />
    </div>
  );
};

export default Calculator;
