import styled from "styled-components";

export const Bigsection = styled.section`
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 1rem;
    margin: 1rem 0;
    .firstDiv {
      display: flex;
      width: 100%;
      justify-content: center;
      gap: 0.5rem;
      align-items: center;
      .coins {
        color: #0dbf7c;
        font-weight: bold;
        /* filter: drop-shadow(4px 1px 9px #0dbf7c); */
      }
    }
    .secondDiv {
      width: 100%;
      text-align: center;
    }
  }
`;
