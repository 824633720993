import {
  Button,
  Grid,
  Modal,
  MenuItem,
  Select,
  TextField,
  Box,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Calculate } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CalcoulateActions,
  // loadAprCalc,
  loadSunLightCalc,
  officalCalc,
  TotalAmount,
} from "../../Redux/Actions/CalculatorActions";
import styled from "styled-components";
import img from "../../assets/enfin.png";
import {
  useClacEnfinMutation,
  useGetEnfinRatesQuery,
} from "../../Redux/toolKit/enfin";
import { LoadingContainer } from "../../pages/Left/Routs/customers/AddProposals/SolarForm/SolarFormStyle";
import Loading from "../loading/Loading";
import LoadingSoultion from "../loading/loadingSolution";
import { updateEnfinData } from "../../Redux/toolKit/formData";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "1rem",
  p: 4,
};

const Enfin = () => {
  /*--------------- start a state --------- */
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const { singlead } = useSelector((state) => state.leads);
  const StatName = singlead.state?.name;
  const { data: enfinRates, isLoading, isFetching } = useGetEnfinRatesQuery("");
  const [clacEnfin, { isLoading: calcIsloading, data: enfinCalcData }] =
    useClacEnfinMutation();
  console.log("enfinRates", enfinRates?.data);
  const handleOpen = () => {
    setOpen(true);
    // dispatch(loadSunLightCalc());
  };

  const [data, setData] = useState({
    termId: "",
    term: "",
    state_name: "",
    loanAmount: "",
    paydownPercentage: "30",
    apr: "",
    alternatePaydown: "",
    dealer_fee: "",
    deposit: "",
  });

  const { sunlightCalc, isCalc, totalAmount } = useSelector(
    (state) => state.calc
  );
  const allData = useSelector((state) => state.calc);

  console.log("sunlightCalcsunlightCalcsunlightCalc", sunlightCalc);
  console.log("allData", allData);
  console.log("enfinCalcData", enfinCalcData);
  useEffect(() => {
    dispatch(updateEnfinData({ ...data, ...enfinCalcData }));
  }, [enfinCalcData]);
  const TermID = data.termId;
  const singleTerm = !isCalc
    ? enfinRates?.data?.find((item) => item.id == TermID)
    : null;

  //console.log(FindTheTerm, "falsee");
  /************************************************ */
  const handleChangeCalc = () => {
    dispatch(officalCalc({ ...data }));
  };
  useEffect(() => {
    dispatch(officalCalc({ ...data }));
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // dispatch(CalcoulateActions({ ...data }));
    clacEnfin({ ...data });
    dispatch(officalCalc({ ...data }));
    handleClose();
  };

  console.log("Delaer feee", data);

  return (
    <>
      {isLoading || calcIsloading ? (
        <LoadingSoultion />
      ) : (
        <>
          {!isCalc && (
            <div>
              <Button
                onClick={handleOpen}
                startIcon={<Calculate />}
                variant="contained"
                fullWidth
              >
                Enfin Financial Calculator
              </Button>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <form>
                    <Div>
                      <h2>Enfin Financial Calculator </h2>
                      <img src={img}></img>
                    </Div>
                    <br />
                    <Grid item container spacing={2} columns={18}>
                      <Grid item md={6} sm={9} xs={18} mb={3}>
                        <FormControl fullWidth>
                          <TextField
                            // required
                            // disabled
                            label="Cash Deposit"
                            value={data.deposit}
                            onChange={async (e) => {
                              await setData({
                                ...data,
                                deposit: Number(e.target.value),
                              });
                              // await dispatch(TotalAmount(totalAmount-data.deposit))
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={6} sm={9} xs={18} mb={3}>
                        <FormControl fullWidth className="formControl">
                          <InputLabel id="demo-simple-select-label">
                            Term in Year
                          </InputLabel>
                          <Select
                            id="demo-simple-select"
                            required
                            value={data.termId}
                            label="Term in Years "
                            onChange={async (e) => {
                              setData({ ...data, termId: e.target.value });
                              await handleChangeCalc();
                              // dispatch(officalCalc({ ...data }));
                              // await dispatch(TotalAmount(totalAmount));
                            }}
                          >
                            {enfinRates?.data?.map((data) => (
                              <MenuItem key={data.id} value={data.id}>
                                {data.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item md={6} sm={9} xs={18} mb={3}>
                        <FormControl fullWidth>
                          <TextField
                            disabled
                            label="Loan Amount"
                            value={
                              (data.loanAmount = Math.ceil(totalAmount) || "")
                            }
                            onChange={(e) =>
                              setData({
                                ...data,
                                loanAmount: Number(e.target.value),
                              })
                            }
                          />
                        </FormControl>
                      </Grid>

                      <Grid
                        item
                        md={6}
                        sm={9}
                        xs={18}
                        mb={3}
                        sx={{ display: "none" }}
                      >
                        <TextField
                          className="formControl"
                          fullWidth
                          label="Term in Month"
                          disabled={true}
                          value={(data.term = singleTerm?.term) || ""}
                          onChange={(e) =>
                            setData({ ...data, term: e.target.value }) || false
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        sm={9}
                        xs={18}
                        mb={3}
                        sx={{ display: "none" }}
                      >
                        <TextField
                          label="APR"
                          className="formControl"
                          fullWidth
                          disabled={true}
                          value={(data.apr = singleTerm?.apr) || ""}
                          onChange={(e) =>
                            setData({ ...data, apr: e.target.value }) || false
                          }
                        />
                      </Grid>

                      <Grid
                        item
                        md={6}
                        sm={9}
                        xs={18}
                        mb={3}
                        style={{ display: "none" }}
                      >
                        <TextField
                          label="Dealar Fees"
                          className="formControl"
                          fullWidth
                          disabled={true}
                          value={
                            (data.dealer_fee = singleTerm?.dealer_fee || "")
                          }
                          onChange={(e) =>
                            setData({ ...data, dealer_fee: e.target.value }) ||
                            false
                          }
                        />
                      </Grid>

                      <Grid item md={6} sm={9} xs={18} mb={3}>
                        <FormControl fullWidth>
                          <TextField
                            label="State Name"
                            disabled={true}
                            value={(data.state_name = StatName) || ""}
                            onChange={(e) =>
                              setData({
                                ...data,
                                state_name: e.target.value,
                              }) || false
                            }
                          />
                        </FormControl>
                      </Grid>

                      <Grid item md={6} sm={9} xs={18} mb={3}>
                        <FormControl fullWidth>
                          <TextField
                            required
                            disabled
                            label="Voluntary Payment Percentage"
                            step="0.01"
                            value={data.paydownPercentage}
                            onChange={(e) =>
                              setData({
                                ...data,
                                paydownPercentage: Number(e.target.value),
                              })
                            }
                          />
                        </FormControl>
                      </Grid>

                      {/* 
                    <Grid item md={6} sm={9} xs={18} mb={3}>
                      <FormControl fullWidth>
                        <TextField
                          required
                          label="Alternate Paydown"
                          value={data.alternatePaydown}
                          onChange={(e) =>
                            setData({
                              ...data,
                              alternatePaydown: Number(e.target.value),
                            })
                          }
                        />
                      </FormControl>
                    </Grid> */}
                      <Grid
                        container
                        xs={18}
                        item
                        alignItems="center"
                        justifyContent="center"
                      >
                        <ButtonStyled
                          onClick={handleSubmit}
                          fullWidth
                          type="submit"
                          className="btnCalc"
                          endIcon={<Calculate />}
                          variant="contained"
                        >
                          Calculate
                        </ButtonStyled>
                      </Grid>
                    </Grid>
                  </form>
                </Box>
              </Modal>
            </div>
          )}
        </>
      )}
    </>
  );
};

const ButtonStyled = styled(Button)`
  background: #ff9800;
  border-radius: 2rem;
  padding: 0.8rem 2rem;
  width: 250px;
  margin: auto;
`;
const Div = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 15%;
  }
`;
export default Enfin;
