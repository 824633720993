import {
  GET_APR_CALC,
  GET_SUNLIGHT_CALC,
  POST_CALCOULATOR,
  POST_CALC_FINANCE,
} from "../Actions/type";

const initialState = {
  calculator: {},
  info: {},
  financeCalc: {},
  AprCalc: [],
  sunlightCalc: {},
  isCalc: true,
  toggleSubmit: true,
  totalAmount: {},
  enfin: {},
};

const CalcoulatorRducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_CALCOULATOR:
      return {
        ...state,
        calculator: action.data,
      };
    case "CALC_OFFICAL":
      return {
        ...state,
        info: action.info,
      };
    case "Enfin_CALC_OFFICAL":
      return {
        ...state,
        enfin: action.info,
      };
    case "TOTAL_AMOUNT":
      return {
        ...state,
        totalAmount: action.totalAmount,
      };
    case POST_CALC_FINANCE:
      return {
        ...state,
        financeCalc: action.financeCalc,
        toggleSubmit: false,
      };
    case GET_APR_CALC:
      return {
        ...state,
        AprCalc: action.AprCalc,
      };
    case GET_SUNLIGHT_CALC:
      return {
        ...state,
        sunlightCalc: action.sunlightCalc,
        isCalc: false,
      };
    case "IS_CALC":
      return {
        ...state,
        isCalc: true,
      };
    case "TOGGLE_SUBMIT_CALC":
      return {
        ...state,
        toggleSubmit: true,
      };
    default:
      return { ...state };
  }
};

export default CalcoulatorRducer;
