// import { Grid } from "@mui/material";
import React from "react";
import { FormHelperText, Grid, TextField } from "@mui/material";
import { BigSection } from "./style";
import SelectInput from "../../../../../components/inputs/SelectInput/SelectInput";
import StanderInput, {
  CustomTextField,
} from "../../../../../components/inputs/standerInput";
import NumberFormat from "react-number-format";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { MyInputLable } from "../../../../../components/inputs/label";
const data = [
  {
    id: "1",
    name: "Install and BOS only",
    value: "install_bos",
  },
  {
    id: "2",
    name: "Permit",
    value: "permit",
  },
  {
    id: "3",
    name: "Project Management",
    value: "project_management",
  },
  {
    id: "4",
    name: "All services",
    value: "all_services",
  },
];
const FirstForm = ({ methods }) => {
  const watchPhone = methods.watch("phone");
  return (
    <BigSection>
      <Grid container columns={12} spacing={3}>
        <Grid item xs={12} md={6} lg={6}>
          <StanderInput name="name" Label="Name" required={true} />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <StanderInput
            name="email"
            Label="Email"
            type="email"
            required={true}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <MyInputLable
            sx={{ color: "#344054" }}
            htmlFor={"phone"}
            required={true}
          >
            phone
          </MyInputLable>
          <Controller
            name={"phone"}
            control={methods.control}
            render={({ field: { onChange, onBlur, value } }) => (
              <NumberFormat
                format="+1 (###) ###-####"
                fullWidth
                // allowEmptyFormatting
                customInput={CustomTextField}
                mask="_"
                // name="phone"
                size="small"
                onValueChange={(values) => {
                  onChange(values.value);
                }}
                value={watchPhone}
              />
            )}
          />
          <FormHelperText sx={{ color: "#f00" }}>
            {methods.formState.errors?.phone?.message}
          </FormHelperText>
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <StanderInput
            name="referrer"
            Label="Referrer email"
            type="email"
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <StanderInput
            name="password"
            Label="Password"
            type="password"
            // required={true}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <StanderInput
            name="password_confirmation"
            Label="Password confirmation"
            type="password"
          />
        </Grid>
      </Grid>
    </BigSection>
  );
};

export default FirstForm;
