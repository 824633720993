import axios from "axios";
import { LEAD_URL, MAIN_URL } from "../../API/Api";
import { SignOut } from "./authActions";
import { GET_SINGLE_LEAD, GET_SINGLE_LEAD_CHILD } from "./type";

export const singleLeadAction = (id) => {
  return async (dispatch) => {
    dispatch({ type: "IS_SINGLE_LEAD_LOADING" });
    const token = localStorage.getItem("token");
    await axios
      .get(`${LEAD_URL}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      })
      .then((res) => {
        dispatch({
          type: GET_SINGLE_LEAD,
          singlead: res.data,
        });
      })
      .catch((err) => {
        console.log(err.data);
        if (err?.response?.status === 401) {
          dispatch(SignOut());
        }
      });
  };
};

export const singleLeadForChild = (id, userId) => {
  return async (dispatch) => {
    dispatch({ type: "IS_SINGLE_LEAD_LOADING" });
    const token = localStorage.getItem("token");
    await axios
      .get(`${MAIN_URL}/api/v1/leads/${id}/user/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      })
      .then((res) => {
        dispatch({
          type: GET_SINGLE_LEAD_CHILD,
          singleadCild: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err.data);
      });
  };
};
