import { MenuItem, TextField } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
  editSalesAchievement,
  getParentAchievement,
} from "../../../../Redux/Actions/achievementsActions";

const MyTargets = () => {
  const {
    salesAcievement,
    parentAchievement,
    editAcievement,
    singleAcievement,
    achievement,
  } = useSelector((state) => state?.achievements);
  console.log("parentAchievement", parentAchievement.data);
  const dispatch = useDispatch();
  const salesInfo = JSON.parse(localStorage.getItem("salesAgent"));
  const [singleStatus, setSignleStatus] = React.useState("");
  const [status, setStatus] = React.useState("");
  const options = [
    { value: "active", name: "Active" },
    { value: "expired", name: "Expired" },
    { value: "disabled", name: "Disable" },
  ];
  const changeStatus = () => {
    console.log("singleStatus", singleStatus);
    Swal.fire({
      title: "Do you want to change the status?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Change",
      denyButtonText: `Don't change`,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        let sendData = {
          data: { status: status },
          id: singleStatus?.id,
        };

        await dispatch(editSalesAchievement(sendData));
        await dispatch(getParentAchievement());
      } else if (result.isDenied) {
      }
    });
  };
  React.useEffect(() => {
    console.log("status", status);
    if (status && singleStatus) {
      changeStatus();
    }
  }, [singleStatus, status]);
  const getTheAmericanDate = (date) => {
    return new Date(date).toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };
  const dataMappingParent = () => {
    return parentAchievement.data?.map((goal, index) => {
      return (
        <tr key={goal?.id}>
          <td>{goal?.index}</td>
          <td>{getTheAmericanDate(goal?.start_at)}</td>
          <td>{goal?.end_at ? getTheAmericanDate(goal?.end_at) : null}</td>
          <td>{goal?.award}</td>
          <td>{goal?.status}</td>
          {salesInfo.children.length > 0 ? (
            <td style={{ width: "12rem" }}>
              <TextField
                select
                required
                id="outlined-required"
                label="change status"
                fullWidth
                value={status}
                onChange={async (e) => {
                  await setSignleStatus(goal);
                  await setStatus(e.target.value);
                }}
              >
                {options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </td>
          ) : null}
        </tr>
      );
    });
  };
  const dataMappingChild = () => {
    return salesAcievement.data?.map((e, index) => {
      return (
        <tr key={e?.id}>
          <td>{e?.index}</td>
          <td>{getTheAmericanDate(e?.start_at)}</td>
          <td>{e?.end_at ? getTheAmericanDate(e?.end_at) : null}</td>
          <td>{e?.award}</td>
          <td>{e?.status}</td>
        </tr>
      );
    });
  };

  return (
    <div className="talbeOfTargets">
      <table>
        <tr>
          <th>#</th>
          <th>Start date</th>
          <th>End date</th>
          <th>Award</th>
          <th>Status</th>
          {salesInfo.children.length > 0 ? <th>Actions</th> : null}
        </tr>
        {salesInfo.children.length > 0 ? (
          <>{dataMappingParent()}</>
        ) : (
          <>{dataMappingChild()}</>
        )}
      </table>
    </div>
  );
};

export default MyTargets;
