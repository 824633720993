import axios from "axios";
import swal from "sweetalert";
import { Create_Ticket } from "../../API/Api";
import { ShowLoading, HideLoading } from "./loadingAction";

export const CREATE_TICKET = "CREATE_TICKET";

export const CreateTicket = (data) => async (dispatch) => {
  dispatch(ShowLoading());
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  await axios({
    method: "POST",
    baseURL: `${Create_Ticket}`,
    headers: headers,
    data:data
  })
    .then((res) => {
      dispatch(HideLoading());
      dispatch({
        type: CREATE_TICKET,
        payload: res.data,
      });
      swal({
        title: "Ticket has been created ",
        icon: "success",
        button: "ok",
      });
      console.log("res", res);
    })
    .catch((err) => {
      dispatch(HideLoading());
      console.log("Erorrrrrrrrrrr", err.response.data.message);
      swal({
        title: "Error",
        text: `${err.response.data.message}`,
        icon: "warning",
        button: true,
        dangerMode: true,
      });
    });
};
