export const adderNames = [
  { id: 1, name: "attic_runs", label: "Attic Runs" },
  { id: 2, name: "tree_trimming", label: "Tree Trimming" },
  {
    id: 3,
    name: "mpu_relocation",
    label: "MPU Relocation (100 Watt)",
  },
  { id: 4, name: "derate", label: "DeRate" },
  { id: 5, name: "sub_panel", label: "Sub Panel" },
  { id: 6, name: "meter_socket", label: "Meter Socket" },
  { id: 7, name: "ev_charger", label: "EV Charger" },
  { id: 8, name: "ev_mlo", label: "EV MLO" },
  { id: 9, name: "designated_plugs", label: "Designated Plugs" },
  { id: 10, name: "structual", label: "Structural" },
  { id: 11, name: "online_monitoring", label: "Online Monitoring" },
  { id: 12, name: "ducting", label: "Ducting" },
  { id: 13, name: "energy_efficient_plugs", label: "Energy Efficient Plugs" },
  { id: 14, name: "solar_lip", label: "Solar Lip" },
  { id: 15, name: "bird_netting", label: "Bird Netting" },
  {
    id: 16,
    name: "warranty_package_standard",
    label: "Warranty Package standard",
  },
  {
    id: 17,
    name: "warranty_package_premium",
    label: "Warranty Package Premium",
  },
  { id: 18, name: "trenching_dirt", label: "Trenching Dirt" },
  { id: 19, name: "trenching_concrete", label: "Trenching Concrete" },
  { id: 20, name: "2_ton_same_location", label: "2 ton same location" },
  { id: 21, name: "3_ton_same_location", label: "3 ton same location" },
  { id: 22, name: "4_ton_same_location", label: "4 ton same location" },
  {
    id: 23,
    name: "4_ton_split_same_location",
    label: "4 ton split same location",
  },
  {
    id: 24,
    name: "5_ton_same_location",
    label: "5 ton split same location",
  },
  { id: 25, name: "2_ton_roof_unit", label: "2 ton roof unit" },
  { id: 26, name: "3_ton_roof_unit", label: "3 ton roof unit" },
  { id: 27, name: "4_ton_roof_unit", label: "4 ton roof unit" },
  { id: 28, name: "4_ton_split_roof_unit", label: "4 ton split roof unit" },
  { id: 29, name: "5_ton_roof_unit", label: "5 ton roof unit" },
  { id: 30, name: "generator_power_5kwh", label: "generator power 5kwh" },
  { id: 31, name: "generator_power_10kwh", label: "generator power 10kwh" },
  { id: 32, name: "battery_power_3kwh", label: "Battery power 3kwh" },
  { id: 33, name: "battery_power_10kwh", label: "Battery power 10kwh" },
  { id: 33, name: "shingle_replace", label: "Shingle replace" },
  { id: 34, name: "shingle_new", label: "Shingle new" },

  { id: 35, name: "presidential_replace", label: "Presidential replace" },
  { id: 36, name: "presidential_new", label: "Presidential new" },

  { id: 37, name: "flat_torch_down_replace", label: "Flat Torch Down replace" },
  { id: 38, name: "flat_torch_down_new", label: "Flat Torch Down new" },

  { id: 39, name: "spanish_tile_replace", label: "Spanish Tile replace" },
  { id: 40, name: "spanish_tile_new", label: "Spanish Tile new" },

  { id: 41, name: "flat_tile_replace", label: "Flat Tile replace" },
  { id: 42, name: "flat_tile_new", label: "Flat Tile new" },

  { id: 43, name: "metal_replace", label: "Metal replace" },
  { id: 44, name: "metal_new", label: "Metal new" },

  { id: 45, name: "foam_replace", label: "Foam replace" },
  { id: 46, name: "foam_new", label: "Foam new" },

  { id: 47, name: "woodshake_replace", label: "Woodshake replace" },
  { id: 48, name: "woodshake_new", label: "Woodshake new" },

  { id: 49, name: "gravel_replace", label: "Gravel replace" },
  { id: 50, name: "gravel_new", label: "Gravel new" },
];

export const electricalAdders = [
  { id: 1, name: "attic_runs", label: "Attic Runs" },
  { id: 2, name: "tree_trimming", label: "Tree Trimming" },
  { id: 3, name: "mpu_relocation", label: "MPU Relocation (100 Watt)" },
  { id: 4, name: "derate", label: "DeRate" },
  { id: 5, name: "sub_panel", label: "Sub Panel" },
  { id: 6, name: "meter_socket", label: "Meter Socket" },
  { id: 7, name: "ev_charger", label: "EV Charger" },
  { id: 8, name: "ev_mlo", label: "EV MLO" },
  { id: 9, name: "designated_plugs", label: "Designated Plugs" },
  { id: 10, name: "structual", label: "Structural" },
  { id: 11, name: "online_monitoring", label: "Online Monitoring" },
  { id: 12, name: "ducting", label: "Ducting" },
  { id: 13, name: "energy_efficient_plugs", label: "Energy Efficient Plugs" },
  { id: 14, name: "solar_lip", label: "Solar Lip" },
  { id: 15, name: "bird_netting", label: "Bird Netting" },
  {
    id: 16,
    name: "warranty_package_standard",
    label: "Warranty Package Standard",
  },
  {
    id: 17,
    name: "warranty_package_premium",
    label: "Warranty Package Premium",
  },
  { id: 18, name: "trenching_dirt", label: "Trenching Dirt" },
  { id: 19, name: "trenching_concrete", label: "Trenching Concrete" },
];
export const hvacsAdders = [
  { id: 20, name: "2_ton_same_location", label: "2 ton same location" },
  { id: 21, name: "3_ton_same_location", label: "3 ton same location" },
  { id: 22, name: "4_ton_same_location", label: "4 ton same location" },
  {
    id: 23,
    name: "4_ton_split_same_location",
    label: "4 ton split same location",
  },
  { id: 24, name: "5_ton_same_location", label: "5 ton same location" },
  { id: 25, name: "2_ton_roof_unit", label: "2 ton roof unit" },
  { id: 26, name: "3_ton_roof_unit", label: "3 ton roof unit" },
  { id: 27, name: "4_ton_roof_unit", label: "4 ton roof unit" },
  { id: 28, name: "4_ton_split_roof_unit", label: "4 ton split roof unit" },
  { id: 29, name: "5_ton_roof_unit", label: "5 ton roof unit" },
];
export const generatorsAdders = [
  { id: 30, name: "generator_power_5kwh", label: "generator power 5kwh" },
  { id: 31, name: "generator_power_10kwh", label: "generator power 10kwh" },
];
export const batteriesAdders = [
  { id: 32, name: "battery_power_3kwh", label: "Battery power 3kwh" },
  { id: 33, name: "battery_power_10kwh", label: "Battery power 10kwh" },
];
export const roofingAdders = [
  { id: 33, name: "shingle_replace", label: "Shingle replace" },
  { id: 34, name: "shingle_new", label: "Shingle new" },
  { id: 35, name: "presidential_replace", label: "Presidential replace" },
  { id: 36, name: "presidential_new", label: "Presidential new" },
  { id: 37, name: "flat_torch_down_replace", label: "Flat Torch Down replace" },
  { id: 38, name: "flat_torch_down_new", label: "Flat Torch Down new" },
  { id: 39, name: "spanish_tile_replace", label: "Spanish Tile replace" },
  { id: 40, name: "spanish_tile_new", label: "Spanish Tile new" },
  { id: 41, name: "flat_tile_replace", label: "Flat Tile replace" },
  { id: 42, name: "flat_tile_new", label: "Flat Tile new" },
  { id: 43, name: "metal_replace", label: "Metal replace" },
  { id: 44, name: "metal_new", label: "Metal new" },
  { id: 45, name: "foam_replace", label: "Foam replace" },
  { id: 46, name: "foam_new", label: "Foam new" },
  { id: 47, name: "woodshake_replace", label: "Woodshake replace" },
  { id: 48, name: "woodshake_new", label: "Woodshake new" },
  { id: 49, name: "gravel_replace", label: "Gravel replace" },
  { id: 50, name: "gravel_new", label: "Gravel new" },
];
