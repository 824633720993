import axios from "axios";
import React from "react";
// Api
import { GET_LEAD_FILTER } from "../../API/Api";
import { SignOut } from "./authActions";
import { ShowLoading, HideLoading } from "./loadingAction";

// Type
export const LEAD_FILTER = "LEAD_FILTER";
export const LOADER_SEARCH_START = "LOADER_SEARCH_START";
export const LOADER_SEARCH_END = "LOADER_SEARCH_END";

// Action
export const getLeadsFiltered = (param) => async (dispatch) => {
  dispatch({ type: LOADER_SEARCH_START });
  const token = localStorage.getItem("token");
  // dispatch(ShowLoading())
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  };
  await axios({
    method: "GET",
    baseURL: `${GET_LEAD_FILTER}`,
    headers: headers,
    params: {
      keyword: param,
    },
  })
    .then((res) => {
      // For Loader
      dispatch({ type: LOADER_SEARCH_END });
      dispatch({
        type: LEAD_FILTER,
        payload: res.data.data,
      });
      // console.log("Response from Action", res.data.data);
    })
    .catch((err) => {
      console.log(err);
      // swal({
      //   title: "Error",
      //   text: `${err}`,
      //   icon: "warning",
      //   button: true,
      //   dangerMode: true,
      // });
      if (err?.response?.status === 401) {
        dispatch(SignOut());
      }
    });
};
