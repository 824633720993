import { Grid } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import singleLeadAction from "../../../Redux/Actions/singleLeadAction";
import FooterPDF from "./FooterPDF";
import { useParams } from "react-router-dom";

const PageSevenPdf = ({ singleOpportunity }) => {
  // const dispatch = useDispatch();
  // const location = useLocation();
  // const ID = location.pathname.split("/")[2];

  /* -------------------- */
  // const { id } = useParams();

  // useEffect(() => {
  //   dispatch(singleLeadAction(ID));
  // }, [dispatch]);

  // const { singlead } = useSelector((state) => state.leads);

  // const singleOpportunity = singlead.opportunities;
  // const ShowYears = () => {
  //    const singleOpportunity = singlead.opportunities;
  //   if (singleOpportunity) {
  //     singleOpportunity.find((item) => item.id == id);
  //     const years = singleOpportunity.electricity_years_plan.split(",");
  //     return (
  //       <>
  //         {years.map((year) => (
  //           <div className="annualFlex" key={year}>
  //             <span>
  //               <div className="annualFlex">
  //                 <span>Year ……………….…………</span>
  //                 <span className="dataFromAPI">....</span>
  //               </div>
  //             </span>
  //             <span className="dataFromAPI"> $ {Math.ceil(year)}</span>
  //           </div>
  //         ))}
  //       </>
  //     );
  //   } else {
  //     singleOpportunity = [];
  //   }
  // };

  // const arr = years;
  // const firstEle = arr[0];
  // const firstEl = years.slice(2);
  // console.log(years);
  // const term = info.term / 12;

  const yearMange = () => {
    if (singleOpportunity) {
      const years = singleOpportunity.electricity_years_plan;
      const MainYears = years?.split(",");
      console.log(MainYears);
      return (
        <>
          {MainYears?.map((year) => (
            <div className="annualFlex" key={year}>
              <span>
                <div className="annualFlex">
                  <span>Year ……………….…………</span>
                  <span className="dataFromAPI">....</span>
                </div>
              </span>
              <span className="dataFromAPI"> $ {Math.ceil(year)}</span>
            </div>
          ))}
        </>
      );
    }
  };

  return (
    <PageSevenStyle className="seven">
      <h2>25 YEAR CHALLENGE</h2>
      <Grid container columns={18}>
        <Grid item sm={9} xs={18}>
          <div className="data">
            <h3>Now</h3>
            <p>
              If you continue to stay with your electric co. PGE, which has a
              steady increase of 5 % on their annual billing this is what your
              monthly & annual bills would be over the next 25 years:
            </p>
            <div className="annual">
              <p>ANNUAL / MONTHLY</p>
              {yearMange()}
            </div>
          </div>
        </Grid>
        <Grid item sm={9} xs={18}>
          <div className="data">
            <h3>With Solar</h3>
            <p>
              Installing your solar power system and using the sun to power your
              home your NEW monthly and annual bills would look like this:
              <br />
              <br />
            </p>
            <div className="annual">
              <p>ANNUAL / MONTHLY</p>
              {/* {solarYearPlan()} */}
            </div>
          </div>
        </Grid>
      </Grid>
      <p className="dataPay">
        My current monthly payment is{" "}
        <span>$ {singleOpportunity.avg_monthly_bill}</span> and my new monthly
        payment will be <span>$ {singleOpportunity.monthlyPayment}</span> ,
        showing immediate savings of{" "}
        <span>
          $
          {singleOpportunity.solar_years_plan -
            singleOpportunity.avg_annual_bill}
        </span>
        / year. That does not include the increase you will get annually with
        your electric provider of <span>{singleOpportunity.rate * 100} %</span>.
        System cost is <span>${singleOpportunity.estimated_cost}</span> but my
        tax credit eligibility is <span>${singleOpportunity.federal_tax}</span>{" "}
        leaving me with a total out of pocket cost of only{" "}
        <span>
          {singleOpportunity.estimated_cost - singleOpportunity.federal_tax}
        </span>{" "}
        I would have paid that amount in just <span>7</span> years if I stayed
        with my current electric company.
      </p>
      <div className="benfis"></div>
      <div className="disclosure">
        Disclosure: The information above is an estimated amount based on the
        information given to the representative and agreed by the customer. This
        estimate is assuming the customer has a tax appetite and can appreciate
        from the value of the Federal Tax Credit. Customer should seek council
        with their tax preparer or accountant to confirm their tax eligibility.
        The system output will have up to a 50% decrease in production
        throughout the 25 years. Based on the 25-year manufacturers warranty.
        The current electric provider may still charge the customer a connection
        fee to tie into the electric grid. When customer is using more power
        than is generated from their solar, customer will be charged for the
        electricity used. Any excess power generated by the customer will be
        credited on their future bill cycles. These numbers are not stated on
        this 25-year challenge. The amounts vary based on electrical provider.
      </div>
      <FooterPDF />
    </PageSevenStyle>
  );
};

const PageSevenStyle = styled.div`
  h2 {
    text-align: center;
    margin: 2.5rem 0;
    color: #113474;
  }
  .data {
    h3 {
      margin: 0 0 2.5rem 0;
      color: #113474;
    }
    p {
      margin: 1rem 0;
    }
    .annual {
      padding: 1rem 0;
      p {
        background: #929292;
        margin: 0 1rem 0 0;
        text-align: right;
        color: #fff;
        padding: 0.5rem;
      }
      .annualFlex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 2rem 0 0;
        color: #777;
      }
    }
  }
  .dataPay {
    font-size: 1.3rem;
    margin: 2rem 0;
  }
  .disclosure {
    color: #777;
    font-size: 0.8rem;
  }
`;

export default PageSevenPdf;
