/*------------------------- Imports---------------------*/
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { loadServices } from "../../../../../../Redux/Actions/CountriesActions";
import {
  loadSubServices,
  loadAddsOn,
  loadUtility,
  getPanleModels,
  getRoofTypsAction,
  getColorRoofing,
} from "../../../../../../Redux/Actions/servicesAction";
import { TotalArea } from "../../../../../../Redux/Actions/solutionsActions";
import { singleLeadAction } from "../../../../../../Redux/Actions/singleLeadAction";
import { loadSunLightCalc } from "../../../../../../Redux/Actions/CalculatorActions";
import { goodLeapCalc } from "../../../../../../Redux/Actions/goodLeapAction";
import Loading from "../../../../../../components/loading/Loading";
import CostEstemation from "../SolarForm/CostEstemation";
import Calc from "../../../../../../components/Calc/Calc";
import CalcGoodLeadp from "../../../../../../components/Calc/CalcGoodLeap";
import { AddRoofingSolution } from "../../../../../../Redux/Actions/addRoofingAction";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import {
  AccountCircle,
  AttachMoney,
  CalculateSharp,
  CheckCircle,
  Email,
  Language,
  LocalPhone,
  LocationOn,
  Map,
  MyLocation,
} from "@mui/icons-material";
import "./Swall.css";
/*----------- Leaflet-------------*/
import L from "leaflet";
import "leaflet-draw";
import "leaflet-measure";
import "leaflet-distortableimage/dist/leaflet.distortableimage.css";
import "leaflet-toolbar/dist/leaflet.toolbar.css";
import "leaflet-toolbar/dist/leaflet.toolbar.css";
import "leaflet";
import "leaflet-toolbar";
import "leaflet-distortableimage";
import { SimpleMapScreenshoter } from "leaflet-simple-map-screenshoter";
import "leaflet-distortableimage/dist/leaflet.distortableimage.css";
import "leaflet-distortableimage/dist/vendor.js";
import SystemDesign from "../../../../../../components/SystemDesign/SystemDesign";
/*----------- Leaflet-------------*/
import styled from "styled-components";
/*----------------google-Geocoding---------------*/
import Geocode from "react-geocode";
import LoadingSoultion from "../../../../../../components/loading/loadingSolution";
import swal from "sweetalert";

/*----------------Style---------------*/
const ColorRoofing = styled.div`
  cursor: pointer;
  color: #b8b8b8;
  width: 100%;
  .select-box {
    position: relative;
    width: 100%;
    color: #fff;

    &__current {
      position: relative;
      width: 100%;
      &:focus {
        & + .select-box__list {
          opacity: 1;
          z-index: 10;
          .select-box__option {
            cursor: pointer;
          }
        }
      }
    }
    &__value {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__input {
      display: none;
      &:checked + .select-box__input-text {
        display: block;
        background: #444;
      }
    }
    &__input-text {
      display: none;
      cursor: pointer;
      width: 100%;
      height: 55px;
      margin: 0;
      padding: 13px;
      font-weight: bold;

      &.show {
        background: #444;
        display: block;
      }
      &.work {
        background: #222;
        display: block;
      }
      /* background: #ff6161; */
      /* &.work {
        background: #fec983;
      }
      &.stuck {
        background: #ff6161;
      }
      &.done {
        background: #32d392;
      }
      &.high {
        background: #4c4fdb;
      }
      &.low {
        background: #371688;
      }
      &.critical {
        background: #e8677f;
      } */
    }
    &__list {
      position: absolute;
      width: 100%;
      height: 60px;
      list-style: none;
      background: #fff;
      padding-top: 0.5rem;
      z-index: 1;
      opacity: 0;
      box-shadow: 0 5px 15px rgba(56, 59, 61, 0.5);
      transition: all 0.3s;
      &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: -8%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 20px solid #fff;
      }
    }
    &__option {
      padding: 1rem;
      display: block;
      margin: 0.2rem 0;
      transition: all 0.3s;
      &:hover {
        opacity: 0.9;
      }
      /* &.work {
        background: #fec983;
      }
      &.stuck {
        background: #fb6b6b;
      }
      &.done {
        background: #32d392;
      }
      &.high {
        background: #4c4fdb;
      }
      &.low {
        background: #371688;
      }
      &.critical {
        background: #e8677f;
      } */
    }
  }
`;
const UserData = styled.div`
  .leadInfo {
    text-align: center;
    color: #0dbf7c55;
    margin-bottom: 1rem;
  }
  .data {
    display: flex;
    margin: 0.3rem;
    .key {
      text-transform: capitalize;
      margin-right: 0.5rem;
      min-width: 5rem;
      color: #474747;
      display: flex;
      align-items: center;
      svg {
        margin-right: 0.5rem;
      }
    }
    .value {
      color: #888;
    }
  }
`;
const SolarHeading = styled.div`
  text-align: center;
  text-transform: uppercase;
  h2 {
    font-size: 2.5rem;
    color: #0dbf7c55;
    margin-bottom: 1.5rem;
    margin-top: 1rem;
  }
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: 50vh;
`;
const CalcToggle = styled.div`
  position: fixed;
  top: 20%;
  right: 1%;
  transform: translate(-20%, -1%);
  width: 60px;
  height: 60px;
  background: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  padding: 0.2rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  cursor: pointer;
  svg {
    font-size: 2.8rem;
    color: #0dc091;
  }
`;
const ButtonContainer = styled.div`
  text-align: center;
  button {
    border-radius: 2rem;
    padding: 0.7rem 3rem;
    background-size: 400% 100%;
    transition: all 0.4s ease-in-out;
    background-image: linear-gradient(
      132.6deg,
      rgba(71, 139, 214, 1) 23.3%,
      rgba(37, 216, 211, 1) 84.7%
    );
    &:hover {
      background-position: 100% 0;
    }
  }
`;
const ClacButton = styled.div`
  width: 50%;
  margin: 1rem auto;
  button {
    border-radius: 2rem;
    letter-spacing: 2px;
    word-spacing: 6px;
    font-size: 0.8rem;
    padding: 0.7rem 1rem;
    background: #eea700;
  }
`;
const CalcSunLightData = styled.div`
  border: 1px solid #eea700;
  padding: 2rem;
  border-radius: 1rem;
  text-align: center;
  height: 190px;
  span {
    display: block;
    font-size: 2.8rem;
    color: #eea700;
    font-weight: bold;
  }
`;
const ModelConatiner = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  .icon {
    svg {
      color: #0dc091;
      font-size: 5rem;
    }
  }
  p {
    font-size: 1.5rem;
    text-transform: capitalize;
    margin: 1.5rem;
    color: #777;
  }
  .btn_sucess {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin: 1rem 0;
    .go_lead {
      background: #0dc091;
    }

    .add_lead {
      background: #979696;
    }
  }
`;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
/*----------------Main Component---------------*/
const RoofingForm = ({ id }) => {
  const location = useLocation();
  const leadId = Number(location.pathname.split("/")[3]);
  const serviceID = Number(location.pathname.split("/")[4]);
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  /*------------------------- Requests--------------------------*/
  React.useEffect(() => {
    dispatch(singleLeadAction(leadId));
    dispatch(loadServices());
    // dispatch(loadSubServices(id));
    dispatch(loadAddsOn(id));
    dispatch(loadSunLightCalc());
    dispatch(getPanleModels());
    dispatch(getRoofTypsAction());
    dispatch(TotalArea());
    dispatch(goodLeapCalc());
  }, []);
  /*------------------------- State--------------------------*/
  const { singlead, isSingleLeadLoading } = useSelector((state) => state.leads);
  const { totalAmount } = useSelector((state) => state.calc);
  const theUserLocation = singlead?.location;
  const total_cost = {
    total_cost: totalAmount,
  };
  const [value, setValue] = React.useState({
    lead_id: leadId,
    service_type_id: serviceID,
    royalties: "",
    is_finance: false,
    is_roof_overlay: false,
    is_re_roofing: false,
    is_roof_relay: false,
    is_re_roof_shingle: false,
    is_re_roof_tear_off: false,
    is_re_roof_presidential: false,
    is_re_roof_spanish_tiles: false,
    is_re_roof_flat_tiles: false,
    is_re_roof_re_decking: false,
    is_re_roof_fascia_board: false,
    is_air_vent: false,
    is_rafter_exchange: false,
    air_vent: "0",
    number_of_vents: "0",
    is_steep_roof: false,
    is_double_hand: false,
    is_smoke_detectors: false,
    is_warranty_extend: false,
    number_of_layers: "0",
    is_design: false,
    number_of_designated_plugs: 1,

    //start roofing
    is_roofing: true,
    roof_job_type_id: "",
    existing_roof_material_id: "",
    roof_plan_roof_material_id: "",
    number_of_old_layers: "",
    number_of_old_squares: 1,
    number_of_steep_sq: 1,
    new_roof_material_id: "",
    number_of_new_layers: 1,
    number_of_new_squares: 1,
    color_id: "",
    new_color_cost: 0,
    is_roof_planes: false,
    number_of_planes_squares: 1,
    number_of_planes_layers: 1,
    is_redecking: false,
    number_of_redecking_squares: 0,
    is_rafter: false,
    number_of_rafter_ft: 0,
    is_fascia: false,
    number_of_fascia_ft: 0,
    is_steep_pitch: false,
    roof_pitch_degree: 0,
    is_hvac_replace: false,
    number_of_hvac_units: 0,
    is_air_vent_replacement: false,
    number_of_replaced_vents: 0,
    is_new_vent: false,
    number_of_new_vents: 0,
    is_smoke_monitors: false,
    number_of_smoke_monitors: 0,
    is_double_hands: false,
    number_of_double_hand_squares: 0,
    is_monoxide_detectors: false,
    number_of_monoxide_detectors: 0,
    added_cost: 0,
    royalties_cost: 0,
    notes: "",
    tile_relay_no_of_squares: 1,
    overlay_no_of_squares: 1,
    add_work_description: "",
    add_job_type_for_roof_tile: false,
    roof_job_type_id_roof_planes: "",
    overlay_material_id_for_add_roof_planes: "",
    overlay_no_of_squares_for_add_roof_planes: 1,
    number_of_planes_squares_for_add_planes: 1,
    tile_relay_no_of_squares_for_add_roof_planes: 1,
    number_of_steep_sq_for_roof_planes: 1,
    is_sky_light: false,
    // System Design
    roof_area: 0,
    aerial_photo: "",
    // financial_institution
    financial_institution: "",
    tile_relay_no_of_squares_for_add_roof_planes_tile_relay: 1,
    number_of_planes_squares_for_add_planes_tile_relay: 1,
    overlay_no_of_squares_for_add_roof_planes_overlay: 1,
  });
  const [showPanlesNo, setShowPanlesNo] = React.useState(false);
  console.log("value", value);
  const [coordinates, setCoordinates] = React.useState({
    lat: "",
    lng: "",
  });
  const [openCalc, setOpenCalc] = React.useState(false);
  /*------------------------- Select Reducers--------------------------*/
  const {
    // utilities,
    // isUtility,
    // Panels,
    // isPanle,
    // isRoof,
    roofTyps,
    // isColor,
    colors,
  } = useSelector((state) => state.services);
  const { isLoading, screenShot } = useSelector((state) => state.proposal);

  const dataAarea = useSelector((state) => state.proposal.totalArea);
  const StatName = singlead?.state?.abbr;
  const isSuccess = useSelector((state) => state.RoofingReducer.isSuccess);
  /*------------------------- SungLight Reducers--------------------------*/

  const { calculator, info } = useSelector((state) => state.calc);

  const YEAR_DATA = info?.term;
  const FINAL_MONTH_PAY = calculator.finalMonthlyPayment;
  const FINAL_ESC_MONTH_PAY = calculator.finalEscalatedMonthlyPayment;
  const MONTH_PAY = calculator.monthlyPayment;
  const ESC_MONTH_PAY = calculator.escalatedMonthlyPayment;
  const PAYDOWN_PERCENTAGE = calculator.paydownPercentage;
  const ALTERNATE_MONTH_PAY = calculator.alternateMonthlyPayment;

  /*------------------------- GoodLeap Reducers--------------------------*/
  const allDataGoodLeap = useSelector(
    (state) => state.GoodLeapReducer.allGoodLeap
  );
  const allOffers =
    useSelector((state) => state.GoodLeapReducer.allOffers) || [];
  const getThePayment =
    useSelector((state) => state.GoodLeapReducer.payment) || [];
  var resultFromPayment = [];

  // Get The All Result From GoodLeap
  getThePayment.map((e) => {
    resultFromPayment = e;
  });
  // Get The Calculation Form GoodLeap
  var resultFromPaymentCalc = [];
  getThePayment.map((e) => {
    resultFromPaymentCalc = e.calculations;
  });
  // Get The Term Year
  const TheOffer = allOffers?.find((e) => e.id == getThePayment[0]?.offerId);
  const OfferData = allOffers?.find((e) => e.id == allDataGoodLeap?.offers[0]);
  const good_leap_promotion = allDataGoodLeap?.promotion || [];
  console.log("allDataGoodLeap", allDataGoodLeap);
  console.log("allDataGoodLeap", allDataGoodLeap);
  console.log("allDataGoodLeap", allDataGoodLeap);
  let offerID = allDataGoodLeap?.offers || [];
  const goodLeapData = {
    offer_id: offerID[0],
    paydown_amount: allDataGoodLeap?.extraPaydownAmount,
    paydown_percent: allDataGoodLeap?.extraPaydownPercent / 100,
    category_id: allDataGoodLeap?.category,
    gl_dealer_fee: OfferData?.dealerFee,
    gl_rate: OfferData?.rate,
    gl_term: OfferData?.term,
    gl_promotion_id: good_leap_promotion[0],
  };
  /*---------------------- Request For Shingle DropDown--------------------*/
  React.useEffect(() => {
    if (value.new_roof_material_id) {
      dispatch(getColorRoofing(value.new_roof_material_id));
    }
  }, [value.new_roof_material_id]);

  React.useEffect(() => {
    value.overlay_number_of_new_squares = value.number_of_old_squares;
  }, [value.number_of_old_squares]);
  React.useEffect(() => {
    if (value.overlay_layers > 1) {
      swal({
        // title: "Watch Out!",
        text: "please be advised there is too much weight on your roof structure. Job type reroof suggested!",
        icon: "warning",
        button: "Ok",
      });
    }
  }, [value.overlay_layers]);

  /*---------------------- Set the Roof measurment to squares--------------------*/
  React.useEffect(() => {
    setValue({
      ...value,
      number_of_old_squares: Math.ceil(dataAarea / 100 + 1),
    });
  }, [dataAarea]);
  /*------------------------- Geo-coding--------------------------*/
  if (singlead.lat === null || singlead.lat === "") {
    Geocode.setLocationType("ROOFTOP");
    Geocode.setApiKey("AIzaSyCrr_bBNHxQ0lZKadmJYpqS2vOebczD1m4");
    Geocode.fromAddress(theUserLocation).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        coordinates.lat = lat;
        coordinates.lng = lng;
      },
      (error) => {
        console.error(error);
      }
    );
  } else {
    coordinates.lat = singlead.lat;
    coordinates.lng = singlead.lng;
  }

  /*------------------------- All reRoofing Arrays from backned --------------------------*/
  const RoofJobsTypes = roofTyps?.roof_job_types;
  const ExistingRoofing = roofTyps?.roof_existing_materials;
  const NewRoofJobTypes = roofTyps?.roof_new_materials;
  const TileRelayMatrial = roofTyps?.tile_relay_materials || [];
  const RoofOverlayMatrials = roofTyps?.roof_overlay_materials;
  const newRooginOverLay = NewRoofJobTypes?.filter(
    (e) => e.id === 1 || e.id === 2 || e.id === 3
  );
  console.log("ExistingRoofing", ExistingRoofing);
  /*------------------------- Get The single data from Arrays   --------------------------*/
  // new Roof plan matrial
  const NewRoofPlanMatrial = ExistingRoofing?.find(
    (e) => e.id === value.planes_material_id
  );
  // new Roof plan matrial of add planes
  const NewRoofPlanMatrialPlanes = NewRoofJobTypes?.find(
    (e) => e.id === value.new_planes_material_id
  );
  // Re Roofing Material for Add Planes
  const ReRoofingMaterialForAddPlanes = ExistingRoofing?.find(
    (e) => e.id === value.planes_material_id_for_add_planes
  );
  // start  old JOB Type Object  Data
  const RoofJobsTypesObject = RoofJobsTypes?.find(
    (e) => e.id === value.roof_job_type_id
  );
  // add planes new job type
  const RoofJobsForAddPlanes = RoofJobsTypes?.find(
    (e) => e.id === value.roof_job_type_id_roof_planes
  );
  // job type for add roof planes for tile relay
  const jobTypeForRoofTileRealy = RoofJobsTypes?.find(
    (e) => e.id === value.add_roof_planes_job_type_id_for_roof_tile
  );
  // job type for add roof planes for roof overlay
  const jobTypeForRoofRoofOverlay = RoofJobsTypes?.find(
    (e) => e.id === value.add_roof_planes_job_type_id_for_roof_overlay
  );
  // get Tile Relay data
  const TileRelayMaterial = TileRelayMatrial.find(
    (e) => e.id === value.tile_relay_material_id
  );
  console.log("TileRelayMaterial", TileRelayMaterial);
  // Tile Relay For add Roof Planes Tile Relay
  const TileRelayForAddRoofPlanesTileRelay = TileRelayMatrial?.find(
    (e) => e.id === value.tile_relay_material_id_for_add_roof_planes_tile_relay
  );
  // Tile Relay For add Roof Planes
  const TileRelayForAddRoofPlanes = TileRelayMatrial?.find(
    (e) => e.id === value.tile_relay_material_id_for_add_roof_planes
  );
  // Tile Relay For add Roof Planes Overlay
  const TileRelayForAddRoofPlanesOverLay = TileRelayMatrial?.find(
    (e) =>
      e.id === value.tile_relay_material_id_for_add_roof_planes_roof_overlay
  );
  // Overlay Roof For add Roof Planes
  const RoofOverLayForAddRoofPlanes = RoofOverlayMatrials?.find(
    (e) => e.id === value.overlay_material_id_for_add_roof_planes
  );
  // Re Roofing Material for Add Planes Tile Relay
  const ReRoofingMaterialForAddPlanesTileRelay = ExistingRoofing?.find(
    (e) => e.id === value.planes_material_id_for_add_planes_tile_relay
  );
  // Re Roofing Material for Add Planes Roof Overlay
  const ReRoofingMaterialForAddPlanesRoofOverlay = ExistingRoofing?.find(
    (e) => e.id === value.planes_material_id_for_add_planes_roof_overlay
  );
  // start  old exisiting e Object  Data
  const ExistingRoofingObject = ExistingRoofing?.find(
    (e) => e.id === value.existing_roof_material_id
  );

  // start  old JOB Type Object  Data
  const NewRoofJobTypesObject = NewRoofJobTypes?.find(
    (e) => e.id === value.new_roof_material_id
  );
  const overlayNewMaterial = NewRoofJobTypes?.find(
    (e) => e.id === value.overlay_new_roof_material_id
  );
  const overlayNewMaterialPlanes = NewRoofJobTypes?.find(
    (e) => e.id === value.overlay_planes_new_roof_material_id
  );
  const tileRelayNewMaterialPlanes = NewRoofJobTypes?.find(
    (e) => e.id === value.tileRelay_overlay_new_roof_material_id
  );
  const reroofNewMaterialPlanes = NewRoofJobTypes?.find(
    (e) => e.id === value.reroof_overlay_new_roof_material_id
  );
  console.log("overlayNewMaterialPlanes", overlayNewMaterialPlanes);
  // object color in roofing
  const colorObject = colors?.find((e) => e.id === value.color_id);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(AddRoofingSolution({ ...value, ...total_cost, ...goodLeapData }));
  };
  const handleGoToLeads = () => {
    Navigate(`/profilelead/${leadId}/solutions`);
    window.location.reload(false);
  };
  value.aerial_photo = screenShot;
  return (
    <>
      {!isSingleLeadLoading && (
        <>
          <div>
            <CalcToggle onClick={() => setOpenCalc(!openCalc)}>
              <CalculateSharp />
            </CalcToggle>
            <CostEstemation
              ToggleCalc={openCalc}
              // NumberOfPanls={TotalPanle}
              // DC={SystemSize}
              // AC={SystemOutput_AC}
              ISfinance={value.is_finance}
              // AnnualPrduction={AnnualPrduction}
              InvertType={value.inverter_type}
              IsTowStory={value.two_story_home}
              IsReverseTilt={value.is_reverse_tilt}
              IsNorthFaced={value.is_north_faced}
              RoofMaterials={value.roof_materials}
              IsAttic={value.is_attic}
              NumberOfAttics={value.number_of_conduit_arrays}
              IsOnlineMonitoring={value.is_online_monitoring}
              JopType={value.job_type}
              IsTrenching={value.is_trenching_dirt}
              HowManyTrench={value.how_many_ft_trench_dirt}
              IsTrenchingConcret={value.is_trenching_concrete}
              HowManyConcret={value.how_many_ft_trench_concrete}
              IsStandAloneRoof={value.is_standalone_roof_mount}
              IsDiggingSlope={value.digging_slope}
              HowManyDigging={value.how_many_digging_ft}
              IsStucco={value.is_stucco}
              IsDeRate={value.is_de_rate}
              ISMPUMore3={value.is_more_than_3ft}
              NumberOfMPU={value.number_of_mpu_relocation_foot}
              IsSubPanel={value.is_sub_panel}
              IsMeterSocket={value.is_meter_socket}
              IsEV={value.is_ev_charger}
              IsEVMLO={value.is_ev_mlo}
              IsReRoofing={value.is_re_roofing}
              NumberOfReroofSquares={value.number_of_re_roof_squares}
              IsRoofRelay={value.is_roof_relay}
              IsReRoofShingle={value.is_re_roof_shingle}
              IsReRoofTear={value.is_re_roof_tear_off}
              NumberOfLayers={value.number_of_layers}
              IsReRoofPresidential={value.is_re_roof_presidential}
              IsReRoofSpanish={value.is_re_roof_spanish_tiles}
              IsReRoofFlat={value.is_re_roof_flat_tiles}
              IsReRoofReDecking={value.is_re_roof_re_decking}
              IsReRoofFascia={value.is_re_roof_fascia_board}
              NumberOfFeet={value.number_of_feet}
              IsRafterExchange={value.is_rafter_exchange}
              IsAirVet={value.is_air_vent}
              AirVent={value.air_vent}
              NumberOfVent={value.number_of_vents}
              IsDoubleHand={value.is_double_hand}
              IsSmoke={value.is_smoke_detectors}
              NumberOfSmoke={value.number_of_smoke_detectors}
              IsWarrantyExtend={value.is_warranty_extend}
              IsHVAC={value.is_hvac}
              HvacLocation={value.hvac_location}
              HvacHorsPower={value.hvac_horse_power}
              NumberOfHvacFt={value.number_hvac_ft}
              IsDucting={value.is_ducting}
              NumberOfDuctingRun={value.number_of_ducting_run}
              IsEnergyEfficient={value.is_energy_efficient_plugs}
              NumberOfEfficient={value.number_of_efficient_plugs}
              IsolarLip={value.is_solar_lip}
              NumberOfSolarLip={value.number_of_solar_lip}
              IsBirdNetting={value.is_bird_netting}
              NumberOfBirdNetting={value.number_of_bird_netting}
              IsBattery={value.is_battery}
              NumberOfBattery={value.number_of_battaries}
              BatteryPower={value.battery_power}
              IsWarrantyPackage={value.is_warranty_package}
              Warranty={value.warranty}
              IsSteep={value.is_steep}
              Royaltiy={value.royalties}
              value={value}
              RoofJobsTypesObject={RoofJobsTypesObject}
              ExistingRoofingObject={ExistingRoofingObject}
              NewRoofJobTypesObject={NewRoofJobTypesObject}
              colorObject={colorObject}
              NewRoofPlanMatrial={NewRoofPlanMatrial}
              is_roof_planes={value.is_roof_planes}
              Roof_plane_squares={value.number_of_planes_squares}
              Roof_plane_layers={value.number_of_planes_layers}
              Tile_Rekay_no_squares={value.tile_relay_no_of_squares}
              overlay_no_of_squares={value.overlay_no_of_squares}
              NewRoofPlanMatrialPlanes={NewRoofPlanMatrialPlanes}
              RoofJobsForAddPlanes={RoofJobsForAddPlanes}
              TileRelayForAddRoofPlanes={TileRelayForAddRoofPlanes}
              RoofOverLayForAddRoofPlanes={RoofOverLayForAddRoofPlanes}
              ReRoofingMaterialForAddPlanes={ReRoofingMaterialForAddPlanes}
              jobTypeForRoofTileRealy={jobTypeForRoofTileRealy}
              TileRelayForAddRoofPlanesTileRelay={
                TileRelayForAddRoofPlanesTileRelay
              }
              ReRoofingMaterialForAddPlanesTileRelay={
                ReRoofingMaterialForAddPlanesTileRelay
              }
              jobTypeForRoofRoofOverlay={jobTypeForRoofRoofOverlay}
              ReRoofingMaterialForAddPlanesRoofOverlay={
                ReRoofingMaterialForAddPlanesRoofOverlay
              }
              overlayNewMaterial={overlayNewMaterial}
              TileRelayMaterial={TileRelayMaterial}
              TileRelayForAddRoofPlanesOverLay={
                TileRelayForAddRoofPlanesOverLay
              }
              overlayNewMaterialPlanes={overlayNewMaterialPlanes}
              tileRelayNewMaterialPlanes={tileRelayNewMaterialPlanes}
              reroofNewMaterialPlanes={reroofNewMaterialPlanes}
            />{" "}
            <UserData>
              <h2 className="leadInfo">Lead Info.</h2>
              <Grid container columns={18} spacing={3}>
                <Grid item md={9} xs={18}>
                  <div className="data">
                    <h4 className="key">
                      <AccountCircle /> name
                    </h4>
                    <span className="value">
                      {singlead?.first_name} {singlead?.last_name}
                    </span>
                  </div>
                  <div className="data">
                    <h4 className="key">
                      <LocalPhone /> phone
                    </h4>
                    <span className="value">{singlead?.phone}</span>
                  </div>
                  <div className="data">
                    <h4 className="key">
                      <Email /> email
                    </h4>
                    <span className="value">{singlead?.email}</span>
                  </div>
                  <div className="data">
                    <h4 className="key">
                      <AttachMoney /> price
                    </h4>
                    <span className="value">${singlead?.price}</span>
                  </div>
                </Grid>
                <Grid item md={9} xs={18}>
                  <div className="data">
                    <h4 className="key">
                      <Language /> country
                    </h4>
                    <span className="value">{singlead?.country?.name}</span>
                  </div>
                  <div className="data">
                    <h4 className="key">
                      <LocationOn /> state
                    </h4>
                    <span className="value">{singlead?.state?.name}</span>
                  </div>
                  <div className="data">
                    <h4 className="key">
                      <MyLocation /> street
                    </h4>
                    <span className="value">{singlead?.street}</span>
                  </div>

                  <div className="data">
                    <h4 className="key">
                      <Map /> zip code
                    </h4>
                    <span className="value">{singlead?.zip_code}</span>
                  </div>
                </Grid>
              </Grid>
            </UserData>
            <SolarHeading>
              <h2>Roofing Solution</h2>
            </SolarHeading>
            <form onSubmit={handleSubmit}>
              {/* System Design */}
              <Grid item xs={18} mb={3}>
                <h3> Customer Location </h3>
                <hr />
              </Grid>
              <Grid item xs={18} mb={3}>
                <SystemDesign
                  coordinates={coordinates}
                  showButton={value.showButton}
                  showPanlesNo={showPanlesNo}
                ></SystemDesign>
              </Grid>
              {/*------------- start the Roofing   ------------*/}
              <Grid item xs={18} mb={3}>
                <h3> Roofing Information</h3>
                <hr />
              </Grid>
              <Grid item container spacing={2} columns={18}>
              {value.is_roofing && (
                  <>
                    {/*  Job Type*/}
                    <Grid item md={6} sm={9} xs={18} mb={3}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Job Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          required
                          value={value.roof_job_type_id}
                          label="Job Type"
                          onChange={(e) =>
                            setValue({
                              ...value,
                              roof_job_type_id: e.target.value,
                            })
                          }
                        >
                          {RoofJobsTypes?.map((job) => (
                            <MenuItem value={job.id} key={job.id}>
                              {job.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    {/* content based on Job type */}
                    {value.roof_job_type_id === 1 && (
                      <>
                        <Grid item md={6} sm={9} xs={18} mb={3}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Tile relay Material
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              required
                              value={value.tile_relay_material_id}
                              label=" Tile relay Material"
                              onChange={(e) =>
                                setValue({
                                  ...value,
                                  tile_relay_material_id: e.target.value,
                                })
                              }
                            >
                              {TileRelayMatrial?.map((tile) => (
                                <MenuItem value={tile.id} key={tile.id}>
                                  {tile.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item md={6} sm={9} xs={18} mb={3}>
                          <TextField
                            fullWidth
                            label="Number of Tile Relay squares "
                            // type="number"
                            value={value.tile_relay_no_of_squares}
                            onChange={(e) =>
                              setValue({
                                ...value,
                                tile_relay_no_of_squares: Number(
                                  e.target.value
                                ),
                              })
                            }
                          />
                        </Grid>
                        {/* broken */}
                        <Grid item md={6} sm={9} xs={18} mb={3}>
                          <TextField
                            fullWidth
                            label="Number of Broken Tiles "
                            // type="number"
                            value={value.tile_relay_broken}
                            onChange={(e) =>
                              setValue({
                                ...value,
                                tile_relay_broken: Number(e.target.value),
                              })
                            }
                          />
                        </Grid>
                        {/* Add roof planes for new roof */}
                        <Grid item md={6} sm={9} xs={18} mb={3}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  value.is_new_roof_planes_for_tile_relay
                                }
                                onChange={(e) =>
                                  setValue({
                                    ...value,
                                    is_new_roof_planes_for_tile_relay:
                                      e.target.checked,
                                  })
                                }
                              />
                            }
                            label="Add Roof Planes ? "
                          />
                        </Grid>
                        {/* Add Roof Planes For Tile Relay */}
                        {value.is_new_roof_planes_for_tile_relay === true && (
                          <>
                            <Grid item md={6} sm={9} xs={18} mb={3}>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Job Type
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  required
                                  value={
                                    value.add_roof_planes_job_type_id_for_roof_tile
                                  }
                                  label="Job Type"
                                  onChange={(e) =>
                                    setValue({
                                      ...value,
                                      add_roof_planes_job_type_id_for_roof_tile:
                                        e.target.value,
                                    })
                                  }
                                >
                                  {RoofJobsTypes?.map((job) => (
                                    <MenuItem value={job.id} key={job.id}>
                                      {job.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            {value.add_roof_planes_job_type_id_for_roof_tile ===
                              1 && (
                              <>
                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Tile relay Material
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      required
                                      value={
                                        value.tile_relay_material_id_for_add_roof_planes_tile_relay
                                      }
                                      label=" Tile relay Material"
                                      onChange={(e) =>
                                        setValue({
                                          ...value,
                                          tile_relay_material_id_for_add_roof_planes_tile_relay:
                                            e.target.value,
                                        })
                                      }
                                    >
                                      {TileRelayMatrial?.map((tile) => (
                                        <MenuItem value={tile.id} key={tile.id}>
                                          {tile.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <TextField
                                    fullWidth
                                    label="Number of Tile Relay squares "
                                    // type="number"
                                    value={
                                      value.tile_relay_no_of_squares_for_add_roof_planes_tile_relay
                                    }
                                    onChange={(e) =>
                                      setValue({
                                        ...value,
                                        tile_relay_no_of_squares_for_add_roof_planes_tile_relay:
                                          Number(e.target.value),
                                      })
                                    }
                                  />
                                </Grid>
                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <TextField
                                    fullWidth
                                    label="Number of Broken Tiles "
                                    // type="number"
                                    value={value.roof_planes_tile_relay_broken}
                                    onChange={(e) =>
                                      setValue({
                                        ...value,
                                        roof_planes_tile_relay_broken: Number(
                                          e.target.value
                                        ),
                                      })
                                    }
                                  />
                                </Grid>
                              </>
                            )}
                            {value.add_roof_planes_job_type_id_for_roof_tile ===
                              2 && (
                              <>
                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Roof Overlay Matrials
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      required
                                      value={
                                        value.overlay_material_id_for_add_roof_planes_overlay
                                      }
                                      label="Roof Overlay Matrials"
                                      onChange={(e) =>
                                        setValue({
                                          ...value,
                                          overlay_material_id_for_add_roof_planes_overlay:
                                            e.target.value,
                                        })
                                      }
                                    >
                                      {RoofOverlayMatrials?.map((roofover) => (
                                        <MenuItem
                                          value={roofover.id}
                                          key={roofover.id}
                                        >
                                          {roofover.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                {/* <Grid item md={6} sm={9} xs={18} mb={3}>
                                    <TextField
                                      fullWidth
                                      label="Number of Overlay squares "
                                      // type="number"
                                      value={
                                        value.overlay_no_of_squares_for_add_roof_planes_overlay
                                      }
                                      onChange={(e) =>
                                        setValue({
                                          ...value,
                                          overlay_no_of_squares_for_add_roof_planes_overlay:
                                            Number(e.target.value),
                                        })
                                      }
                                    />
                                  </Grid> */}
                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      New Roof Material
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      required
                                      value={
                                        value.tileRelay_overlay_new_roof_material_id
                                      }
                                      label="New Roof Material"
                                      onChange={(e) =>
                                        setValue({
                                          ...value,
                                          tileRelay_overlay_new_roof_material_id:
                                            e.target.value,
                                        })
                                      }
                                    >
                                      {newRooginOverLay?.map((roof) => (
                                        <MenuItem value={roof.id} key={roof.id}>
                                          {roof.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>

                                {/* <Grid item md={6} sm={9} xs={18} mb={3}>
                            <TextField
                              fullWidth
                              label="Number of New Squares "
                              // type="number"
                              // disabled
                              value={
                                (value.overlay_number_of_new_squares)
                              }
                              onChange={(e) =>
                                setValue({
                                  ...value,
                                  overlay_number_of_new_squares: Number(e.target.value),
                                })
                              }
                            />
                          </Grid> */}

                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <TextField
                                    fullWidth
                                    label="Number of Existing squares "
                                    // type="number"
                                    value={
                                      value.TileRelay_overlay_no_of_squares
                                    }
                                    onChange={(e) =>
                                      setValue({
                                        ...value,
                                        TileRelay_overlay_no_of_squares: Number(
                                          e.target.value
                                        ),
                                      })
                                    }
                                  />
                                </Grid>
                                {/*  Number of Layers */}
                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <TextField
                                    fullWidth
                                    label="Number of Layers"
                                    // type="number"
                                    value={value.overlay_layers}
                                    onChange={(e) =>
                                      setValue({
                                        ...value,
                                        overlay_layers: Number(e.target.value),
                                      })
                                    }
                                  />
                                </Grid>

                                {/*  Number of Old Squers */}
                                {/* <Grid item md={6} sm={9} xs={18} mb={3}>
                                    <TextField
                                      fullWidth
                                      label="Number of Existing Squares "
                                      // type="number"
                                      value={
                                        value.number_of_planes_squares_for_add_planes_roof_overlay
                                      }
                                      onChange={(e) =>
                                        setValue({
                                          ...value,
                                          number_of_planes_squares_for_add_planes_roof_overlay:
                                            Number(
                                              e.target.value
                                            ),
                                        })
                                      }
                                    />
                                  </Grid> */}
                                {/* <Grid item md={6} sm={9} xs={18} mb={3}>
                                    <FormControl fullWidth>
                                      <InputLabel id="demo-simple-select-label">
                                        Existing Roof Material
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={
                                          value.planes_material_id_for_add_planes_roof_overlay
                                        }
                                        label="Existing Roof Material"
                                        onChange={(e) =>
                                          setValue({
                                            ...value,
                                            planes_material_id_for_add_planes_roof_overlay:
                                              e.target.value,
                                          })
                                        }
                                      >
                                        {ExistingRoofing?.map((job) => (
                                          <MenuItem value={job.id} key={job.id}>
                                            {job.name}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </Grid> */}
                                {/*  Roof Pitch Degree. */}
                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <TextField
                                    fullWidth
                                    label="Roof Pitch Degree "
                                    value={value.overlay_pitch}
                                    onChange={(e) =>
                                      setValue({
                                        ...value,
                                        overlay_pitch: Number(e.target.value),
                                      })
                                    }
                                  />
                                </Grid>
                                {/* Roof Pitch Number of squares */}
                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <TextField
                                    fullWidth
                                    label="Number Of Steep Squares"
                                    value={value.overlay_steep}
                                    onChange={(e) =>
                                      setValue({
                                        ...value,
                                        overlay_steep: Number(e.target.value),
                                      })
                                    }
                                  />
                                </Grid>
                              </>
                            )}
                            {value.add_roof_planes_job_type_id_for_roof_tile ===
                              3 && (
                              <>
                                {/*  Number of Layers */}
                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <TextField
                                    fullWidth
                                    label="Number of Layers"
                                    // type="number"
                                    value={
                                      value.number_of_planes_layers_for_add_roof_planes_tile_relay
                                    }
                                    onChange={(e) =>
                                      setValue({
                                        ...value,
                                        number_of_planes_layers_for_add_roof_planes_tile_relay:
                                          Number(e.target.value),
                                      })
                                    }
                                  />
                                </Grid>
                                {/*  Number of Old Squers */}
                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <TextField
                                    fullWidth
                                    label="Number of Old Squares "
                                    // type="number"
                                    value={
                                      value.number_of_planes_squares_for_add_planes_tile_relay
                                    }
                                    onChange={(e) =>
                                      setValue({
                                        ...value,
                                        number_of_planes_squares_for_add_planes_tile_relay:
                                          Number(
                                            e.target.value
                                            // , number_of_new_planes_sq: Number(e.target.value)
                                          ),
                                      })
                                    }
                                  />
                                </Grid>
                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Existing Roof Material
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={
                                        value.planes_material_id_for_add_planes_tile_relay
                                      }
                                      label="Existing Roof Material"
                                      onChange={(e) =>
                                        setValue({
                                          ...value,
                                          planes_material_id_for_add_planes_tile_relay:
                                            e.target.value,
                                        })
                                      }
                                    >
                                      {ExistingRoofing?.map((job) => (
                                        <MenuItem value={job.id} key={job.id}>
                                          {job.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                {/*  Roof Pitch Degree. */}
                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <TextField
                                    fullWidth
                                    label="Roof Pitch Degree "
                                    value={
                                      value.roof_pitch_degree_for_roof_planes_tile_relay
                                    }
                                    onChange={(e) =>
                                      setValue({
                                        ...value,
                                        roof_pitch_degree_for_roof_planes_tile_relay:
                                          Number(e.target.value),
                                      })
                                    }
                                  />
                                </Grid>
                                {/* Roof Pitch Number of squares */}
                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <TextField
                                    fullWidth
                                    label="Number Of Steep Squares"
                                    value={
                                      value.number_of_steep_sq_for_roof_planes_tile_relay
                                    }
                                    onChange={(e) =>
                                      setValue({
                                        ...value,
                                        number_of_steep_sq_for_roof_planes_tile_relay:
                                          Number(e.target.value),
                                      })
                                    }
                                  />
                                </Grid>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}

                    {value.roof_job_type_id === 2 && (
                      <>
                        <Grid item md={6} sm={9} xs={18} mb={3}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Existing Roof Material
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              required
                              value={value.overlay_material_id}
                              // label="Roof Overlay Matrials"
                              label="Existing Roof Material"
                              onChange={(e) =>
                                setValue({
                                  ...value,
                                  overlay_material_id: e.target.value,
                                })
                              }
                            >
                              {RoofOverlayMatrials?.map((roofover) => (
                                <MenuItem value={roofover.id} key={roofover.id}>
                                  {roofover.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item md={6} sm={9} xs={18} mb={3}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              New Roof Material
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              required
                              value={value.overlay_new_roof_material_id}
                              label="New Roof Material"
                              onChange={(e) =>
                                setValue({
                                  ...value,
                                  overlay_new_roof_material_id: e.target.value,
                                })
                              }
                            >
                              {newRooginOverLay?.map((roof) => (
                                <MenuItem value={roof.id} key={roof.id}>
                                  {roof.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>

                        {/* <Grid item md={6} sm={9} xs={18} mb={3}>
                 <TextField
                   fullWidth
                   label="Number of New Squares "
                   // type="number"
                   // disabled
                   value={
                     (value.overlay_number_of_new_squares)
                   }
                   onChange={(e) =>
                     setValue({
                       ...value,
                       overlay_number_of_new_squares: Number(e.target.value),
                     })
                   }
                 />
               </Grid> */}

                        <Grid item md={6} sm={9} xs={18} mb={3}>
                          <TextField
                            fullWidth
                            label="Number of Existing squares "
                            // type="number"
                            value={value.overlay_no_of_squares}
                            onChange={(e) =>
                              setValue({
                                ...value,
                                overlay_no_of_squares: Number(e.target.value),
                              })
                            }
                          />
                        </Grid>
                        {/*  Number of Layers */}
                        <Grid item md={6} sm={9} xs={18} mb={3}>
                          <TextField
                            fullWidth
                            label="Number of Layers"
                            // type="number"
                            value={value.overlay_layers}
                            onChange={(e) =>
                              setValue({
                                ...value,
                                overlay_layers: Number(e.target.value),
                              })
                            }
                          />
                        </Grid>

                        {/*  Number of Old Squers */}
                        {/* <Grid item md={6} sm={9} xs={18} mb={3}>
                         <TextField
                           fullWidth
                           label="Number of Existing Squares "
                           // type="number"
                           value={
                             value.number_of_planes_squares_for_add_planes_roof_overlay
                           }
                           onChange={(e) =>
                             setValue({
                               ...value,
                               number_of_planes_squares_for_add_planes_roof_overlay:
                                 Number(
                                   e.target.value
                                 ),
                             })
                           }
                         />
                       </Grid> */}
                        {/* <Grid item md={6} sm={9} xs={18} mb={3}>
                         <FormControl fullWidth>
                           <InputLabel id="demo-simple-select-label">
                             Existing Roof Material
                           </InputLabel>
                           <Select
                             labelId="demo-simple-select-label"
                             id="demo-simple-select"
                             value={
                               value.planes_material_id_for_add_planes_roof_overlay
                             }
                             label="Existing Roof Material"
                             onChange={(e) =>
                               setValue({
                                 ...value,
                                 planes_material_id_for_add_planes_roof_overlay:
                                   e.target.value,
                               })
                             }
                           >
                             {ExistingRoofing?.map((job) => (
                               <MenuItem value={job.id} key={job.id}>
                                 {job.name}
                               </MenuItem>
                             ))}
                           </Select>
                         </FormControl>
                       </Grid> */}
                        {/*  Roof Pitch Degree. */}
                        <Grid item md={6} sm={9} xs={18} mb={3}>
                          <TextField
                            fullWidth
                            label="Roof Pitch Degree "
                            value={value.overlay_pitch}
                            onChange={(e) =>
                              setValue({
                                ...value,
                                overlay_pitch: Number(e.target.value),
                              })
                            }
                          />
                        </Grid>
                        {/* Roof Pitch Number of squares */}
                        <Grid item md={6} sm={9} xs={18} mb={3}>
                          <TextField
                            fullWidth
                            label="Number Of Steep Squares"
                            value={value.overlay_steep}
                            onChange={(e) =>
                              setValue({
                                ...value,
                                overlay_steep: Number(e.target.value),
                              })
                            }
                          />
                        </Grid>
                        {/* Add roof planes for new roof */}
                        <Grid item md={6} sm={9} xs={18} mb={3}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  value.is_new_roof_planes_for_roof_overlay
                                }
                                onChange={(e) =>
                                  setValue({
                                    ...value,
                                    is_new_roof_planes_for_roof_overlay:
                                      e.target.checked,
                                  })
                                }
                              />
                            }
                            label="Add Roof Planes ? "
                          />
                        </Grid>
                        {value.is_new_roof_planes_for_roof_overlay === true && (
                          <>
                            <Grid item md={6} sm={9} xs={18} mb={3}>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Job Type
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  required
                                  value={
                                    value.add_roof_planes_job_type_id_for_roof_overlay
                                  }
                                  label="Job Type"
                                  onChange={(e) =>
                                    setValue({
                                      ...value,
                                      add_roof_planes_job_type_id_for_roof_overlay:
                                        e.target.value,
                                    })
                                  }
                                >
                                  {RoofJobsTypes?.map((job) => (
                                    <MenuItem value={job.id} key={job.id}>
                                      {job.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            {value.add_roof_planes_job_type_id_for_roof_overlay ===
                              1 && (
                              <>
                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Tile relay Material
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      required
                                      value={
                                        value.tile_relay_material_id_for_add_roof_planes_roof_overlay
                                      }
                                      label=" Tile relay Material"
                                      onChange={(e) =>
                                        setValue({
                                          ...value,
                                          tile_relay_material_id_for_add_roof_planes_roof_overlay:
                                            e.target.value,
                                        })
                                      }
                                    >
                                      {TileRelayMatrial?.map((tile) => (
                                        <MenuItem value={tile.id} key={tile.id}>
                                          {tile.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <TextField
                                    fullWidth
                                    label="Number of Tile Relay squares "
                                    // type="number"
                                    value={
                                      value.tile_relay_no_of_squares_for_add_roof_planes_roof_overlay
                                    }
                                    onChange={(e) =>
                                      setValue({
                                        ...value,
                                        tile_relay_no_of_squares_for_add_roof_planes_roof_overlay:
                                          Number(e.target.value),
                                      })
                                    }
                                  />
                                </Grid>
                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <TextField
                                    fullWidth
                                    label="Number of Broken Tiles "
                                    // type="number"
                                    value={
                                      value.roof_planes_tile_relay_broken_overlay
                                    }
                                    onChange={(e) =>
                                      setValue({
                                        ...value,
                                        roof_planes_tile_relay_broken_overlay:
                                          Number(e.target.value),
                                      })
                                    }
                                  />
                                </Grid>
                              </>
                            )}
                            {value.add_roof_planes_job_type_id_for_roof_overlay ===
                              2 && (
                              <>
                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Roof Overlay Matrials
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      required
                                      value={
                                        value.overlay_material_id_for_add_roof_planes_overlay_overlay
                                      }
                                      label="Roof Overlay Matrials"
                                      onChange={(e) =>
                                        setValue({
                                          ...value,
                                          overlay_material_id_for_add_roof_planes_overlay_overlay:
                                            e.target.value,
                                        })
                                      }
                                    >
                                      {RoofOverlayMatrials?.map((roofover) => (
                                        <MenuItem
                                          value={roofover.id}
                                          key={roofover.id}
                                        >
                                          {roofover.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                {/* <Grid item md={6} sm={9} xs={18} mb={3}>
                         <TextField
                           fullWidth
                           label="Number of Overlay squares"
                           // type="number"
                           value={
                             value.overlay_no_of_squares_for_add_roof_planes_overlay_overlay
                           }
                           onChange={(e) =>
                             setValue({
                               ...value,
                               overlay_no_of_squares_for_add_roof_planes_overlay_overlay:
                                 Number(e.target.value),
                             })
                           }
                         />
                       </Grid> */}
                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      New Roof Material
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      required
                                      value={
                                        value.overlay_planes_new_roof_material_id
                                      }
                                      label="New Roof Material"
                                      onChange={(e) =>
                                        setValue({
                                          ...value,
                                          overlay_planes_new_roof_material_id:
                                            e.target.value,
                                        })
                                      }
                                    >
                                      {newRooginOverLay?.map((roof) => (
                                        <MenuItem value={roof.id} key={roof.id}>
                                          {roof.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>

                                {/* <Grid item md={6} sm={9} xs={18} mb={3}>
                 <TextField
                   fullWidth
                   label="Number of New Squares "
                   // type="number"
                   // disabled
                   value={
                     (value.overlay_number_of_new_squares)
                   }
                   onChange={(e) =>
                     setValue({
                       ...value,
                       overlay_number_of_new_squares: Number(e.target.value),
                     })
                   }
                 />
               </Grid> */}

                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <TextField
                                    fullWidth
                                    label="Number of Existing squares "
                                    // type="number"
                                    value={value.overlay_planes_no_of_squares}
                                    onChange={(e) =>
                                      setValue({
                                        ...value,
                                        overlay_planes_no_of_squares: Number(
                                          e.target.value
                                        ),
                                      })
                                    }
                                  />
                                </Grid>
                                {/*  Number of Layers */}
                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <TextField
                                    fullWidth
                                    label="Number of Layers"
                                    // type="number"
                                    value={value.overlay_layers}
                                    onChange={(e) =>
                                      setValue({
                                        ...value,
                                        overlay_layers: Number(e.target.value),
                                      })
                                    }
                                  />
                                </Grid>

                                {/*  Number of Old Squers */}
                                {/* <Grid item md={6} sm={9} xs={18} mb={3}>
                         <TextField
                           fullWidth
                           label="Number of Existing Squares "
                           // type="number"
                           value={
                             value.number_of_planes_squares_for_add_planes_roof_overlay
                           }
                           onChange={(e) =>
                             setValue({
                               ...value,
                               number_of_planes_squares_for_add_planes_roof_overlay:
                                 Number(
                                   e.target.value
                                 ),
                             })
                           }
                         />
                       </Grid> */}
                                {/* <Grid item md={6} sm={9} xs={18} mb={3}>
                         <FormControl fullWidth>
                           <InputLabel id="demo-simple-select-label">
                             Existing Roof Material
                           </InputLabel>
                           <Select
                             labelId="demo-simple-select-label"
                             id="demo-simple-select"
                             value={
                               value.planes_material_id_for_add_planes_roof_overlay
                             }
                             label="Existing Roof Material"
                             onChange={(e) =>
                               setValue({
                                 ...value,
                                 planes_material_id_for_add_planes_roof_overlay:
                                   e.target.value,
                               })
                             }
                           >
                             {ExistingRoofing?.map((job) => (
                               <MenuItem value={job.id} key={job.id}>
                                 {job.name}
                               </MenuItem>
                             ))}
                           </Select>
                         </FormControl>
                       </Grid> */}
                                {/*  Roof Pitch Degree. */}
                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <TextField
                                    fullWidth
                                    label="Roof Pitch Degree "
                                    value={value.overlay_pitch}
                                    onChange={(e) =>
                                      setValue({
                                        ...value,
                                        overlay_pitch: Number(e.target.value),
                                      })
                                    }
                                  />
                                </Grid>
                                {/* Roof Pitch Number of squares */}
                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <TextField
                                    fullWidth
                                    label="Number Of Steep Squares"
                                    value={value.overlay_steep}
                                    onChange={(e) =>
                                      setValue({
                                        ...value,
                                        overlay_steep: Number(e.target.value),
                                      })
                                    }
                                  />
                                </Grid>
                              </>
                            )}
                            {value.add_roof_planes_job_type_id_for_roof_overlay ===
                              3 && (
                              <>
                                {/*  Number of Layers */}
                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <TextField
                                    fullWidth
                                    label="Number of Layers"
                                    // type="number"
                                    value={
                                      value.number_of_planes_layers_for_add_roof_planes_roof_overlay
                                    }
                                    onChange={(e) =>
                                      setValue({
                                        ...value,
                                        number_of_planes_layers_for_add_roof_planes_roof_overlay:
                                          Number(e.target.value),
                                      })
                                    }
                                  />
                                </Grid>
                                {/*  Number of Old Squers */}
                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <TextField
                                    fullWidth
                                    label="Number of Old Squares "
                                    // type="number"
                                    value={
                                      value.number_of_planes_squares_for_add_planes_roof_overlay
                                    }
                                    onChange={(e) =>
                                      setValue({
                                        ...value,
                                        number_of_planes_squares_for_add_planes_roof_overlay:
                                          Number(
                                            e.target.value
                                            // , number_of_new_planes_sq: Number(e.target.value)
                                          ),
                                      })
                                    }
                                  />
                                </Grid>
                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Existing Roof Material
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={
                                        value.planes_material_id_for_add_planes_roof_overlay
                                      }
                                      label="Existing Roof Material"
                                      onChange={(e) =>
                                        setValue({
                                          ...value,
                                          planes_material_id_for_add_planes_roof_overlay:
                                            e.target.value,
                                        })
                                      }
                                    >
                                      {ExistingRoofing?.map((job) => (
                                        <MenuItem value={job.id} key={job.id}>
                                          {job.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                {/*  Roof Pitch Degree. */}
                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <TextField
                                    fullWidth
                                    label="Roof Pitch Degree "
                                    value={
                                      value.roof_pitch_degree_for_roof_planes_roof_overlay
                                    }
                                    onChange={(e) =>
                                      setValue({
                                        ...value,
                                        roof_pitch_degree_for_roof_planes_roof_overlay:
                                          Number(e.target.value),
                                      })
                                    }
                                  />
                                </Grid>
                                {/* Roof Pitch Number of squares */}
                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <TextField
                                    fullWidth
                                    label="Number Of Steep Squares"
                                    value={
                                      value.number_of_steep_sq_for_roof_planes_roof_overlay
                                    }
                                    onChange={(e) =>
                                      setValue({
                                        ...value,
                                        number_of_steep_sq_for_roof_planes_roof_overlay:
                                          Number(e.target.value),
                                      })
                                    }
                                  />
                                </Grid>
                              </>
                            )}
                          </>
                        )}
                        {/* <Grid item md={18} sm={18} xs={18} mb={3}>
                 New Roofing try
                 <hr></hr>
               </Grid> */}

                        {/* <Grid item md={6} sm={9} xs={18} mb={3}>
                 <FormControl fullWidth>
                   <InputLabel id="demo-simple-select-label">
                     New Roof Material
                   </InputLabel>
                   <Select
                     labelId="demo-simple-select-label"
                     id="demo-simple-select"
                     required
                     value={value.overlay_new_roof_material_id}
                     label="New Roof Material"
                     onChange={(e) =>
                       setValue({
                         ...value,
                         overlay_new_roof_material_id: e.target.value,
                       })
                     }
                   >
                     {newRooginOverLay?.map((roof) => (
                       <MenuItem value={roof.id} key={roof.id}>
                         {roof.name}
                       </MenuItem>
                     ))}
                   </Select>
                 </FormControl>
               </Grid>

               <Grid item md={6} sm={9} xs={18} mb={3}>
                 <TextField
                   fullWidth
                   label="Number of Squares "
                   // type="number"
                   // disabled
                   value={
                     (value.overlay_number_of_new_squares)
                   }
                   onChange={(e) =>
                     setValue({
                       ...value,
                       overlay_number_of_new_squares: Number(e.target.value),
                     })
                   }
                 />
               </Grid> */}

                        {/* Add Roof Planes For Roof Overlay */}
                      </>
                    )}
                    {value.roof_job_type_id === 3 && (
                      <>
                        {/*  Existing Roof*/}
                        <Grid item md={6} sm={9} xs={18} mb={3}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Existing Roof Material
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={value.existing_roof_material_id}
                              label="Existing Roof Material"
                              onChange={(e) =>
                                setValue({
                                  ...value,
                                  existing_roof_material_id: e.target.value,
                                })
                              }
                            >
                              {ExistingRoofing?.map((job) => (
                                <MenuItem value={job.id} key={job.id}>
                                  {job.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>

                        {/*  Number of Layers */}
                        <Grid item md={6} sm={9} xs={18} mb={3}>
                          <TextField
                            fullWidth
                            label="Number of Layers  "
                            // type="number"
                            value={value.number_of_old_layers}
                            onChange={(e) =>
                              setValue({
                                ...value,
                                number_of_old_layers: Number(e.target.value),
                              })
                            }
                          />
                        </Grid>
                        {/*  Number of Old Squers */}
                        <Grid item md={6} sm={9} xs={18} mb={3}>
                          <TextField
                            fullWidth
                            label="Number of Existing Squares "
                            // type="number"
                            value={value.number_of_old_squares}
                            onChange={(e) =>
                              setValue({
                                ...value,
                                number_of_old_squares: Number(e.target.value),
                              })
                            }
                          />
                        </Grid>
                        {/*  Roof Pitch Degree. */}
                        <Grid item md={6} sm={9} xs={18} mb={3}>
                          <TextField
                            fullWidth
                            label="Roof Pitch Degree "
                            value={value.roof_pitch_degree}
                            onChange={(e) =>
                              setValue({
                                ...value,
                                roof_pitch_degree: Number(e.target.value),
                              })
                            }
                          />
                        </Grid>
                        {/* Roof Pitch Number of squares */}
                        <Grid item md={6} sm={9} xs={18} mb={3}>
                          <TextField
                            fullWidth
                            label="Number Of Steep Squares"
                            value={value.number_of_steep_sq}
                            onChange={(e) =>
                              setValue({
                                ...value,
                                number_of_steep_sq: Number(e.target.value),
                              })
                            }
                          />
                        </Grid>
                        {/*  Steep Roof ?*/}
                        {/* <Grid item md={6} sm={9} xs={18} mb={3}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={value.is_steep_pitch}
                              onChange={(e) =>
                                setValue({
                                  ...value,
                                  is_steep_pitch: e.target.checked,
                                })
                              }
                            />
                          }
                          label="Roof Pitch ?"
                        />
                      </Grid> */}
                        {/* {value.is_steep_pitch && (
                        <>
                           Roof Pitch Degree.
                          <Grid item md={6} sm={9} xs={18} mb={3}>
                            <TextField
                              fullWidth
                              label="Roof Pitch Degree "
                              value={value.roof_pitch_degree}
                              onChange={(e) =>
                                setValue({
                                  ...value,
                                  roof_pitch_degree: Number(e.target.value),
                                })
                              }
                            />
                          </Grid>
                          Roof Pitch Number of squares
                          <Grid item md={6} sm={9} xs={18} mb={3}>
                            <TextField
                              fullWidth
                              label="Number Of Steep Squares"
                              value={value.number_of_steep_sq}
                              onChange={(e) =>
                                setValue({
                                  ...value,
                                  number_of_steep_sq: Number(e.target.value),
                                })
                              }
                            />
                          </Grid>
                        </>
                      )} */}
                        {/* is roof planes for re roofing */}
                        <Grid item md={6} sm={9} xs={18} mb={3}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={value.is_roof_planes}
                                onChange={(e) =>
                                  setValue({
                                    ...value,
                                    is_roof_planes: e.target.checked,
                                  })
                                }
                              />
                            }
                            label="Add Roof Planes ? "
                          />
                        </Grid>

                        {value.is_roof_planes && (
                          <>
                            {/*  Job Type for roof planes*/}
                            <Grid item md={6} sm={9} xs={18} mb={3}>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Job Type
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  required
                                  value={value.roof_job_type_id_roof_planes}
                                  label="Job Type"
                                  onChange={(e) =>
                                    setValue({
                                      ...value,
                                      roof_job_type_id_roof_planes:
                                        e.target.value,
                                    })
                                  }
                                >
                                  {RoofJobsTypes?.map((job) => (
                                    <MenuItem value={job.id} key={job.id}>
                                      {job.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            {value.roof_job_type_id_roof_planes === 1 && (
                              <>
                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Tile relay Material
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      required
                                      value={
                                        value.tile_relay_material_id_for_add_roof_planes
                                      }
                                      label=" Tile relay Material"
                                      onChange={(e) =>
                                        setValue({
                                          ...value,
                                          tile_relay_material_id_for_add_roof_planes:
                                            e.target.value,
                                        })
                                      }
                                    >
                                      {TileRelayMatrial?.map((tile) => (
                                        <MenuItem value={tile.id} key={tile.id}>
                                          {tile.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <TextField
                                    fullWidth
                                    label="Number of Tile Relay squares "
                                    // type="number"
                                    value={
                                      value.tile_relay_no_of_squares_for_add_roof_planes
                                    }
                                    onChange={(e) =>
                                      setValue({
                                        ...value,
                                        tile_relay_no_of_squares_for_add_roof_planes:
                                          Number(e.target.value),
                                      })
                                    }
                                  />
                                </Grid>
                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <TextField
                                    fullWidth
                                    label="Number of Broken Tiles "
                                    // type="number"
                                    value={
                                      value.roof_planes_tile_relay_broken_reroof
                                    }
                                    onChange={(e) =>
                                      setValue({
                                        ...value,
                                        roof_planes_tile_relay_broken_reroof:
                                          Number(e.target.value),
                                      })
                                    }
                                  />
                                </Grid>
                              </>
                            )}
                            {value.roof_job_type_id_roof_planes === 2 && (
                              <>
                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Roof Overlay Matrials
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      required
                                      value={
                                        value.overlay_material_id_for_add_roof_planes
                                      }
                                      label="Roof Overlay Matrials"
                                      onChange={(e) =>
                                        setValue({
                                          ...value,
                                          overlay_material_id_for_add_roof_planes:
                                            e.target.value,
                                        })
                                      }
                                    >
                                      {RoofOverlayMatrials?.map((roofover) => (
                                        <MenuItem
                                          value={roofover.id}
                                          key={roofover.id}
                                        >
                                          {roofover.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <TextField
                                    fullWidth
                                    label="Number of Overlay squares "
                                    // type="number"
                                    value={
                                      value.overlay_no_of_squares_for_add_roof_planes
                                    }
                                    onChange={(e) =>
                                      setValue({
                                        ...value,
                                        overlay_no_of_squares_for_add_roof_planes:
                                          Number(e.target.value),
                                      })
                                    }
                                  />
                                </Grid>
                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      New Roof Material
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      required
                                      value={
                                        value.reroof_overlay_new_roof_material_id
                                      }
                                      label="New Roof Material"
                                      onChange={(e) =>
                                        setValue({
                                          ...value,
                                          reroof_overlay_new_roof_material_id:
                                            e.target.value,
                                        })
                                      }
                                    >
                                      {newRooginOverLay?.map((roof) => (
                                        <MenuItem value={roof.id} key={roof.id}>
                                          {roof.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>

                                {/* <Grid item md={6} sm={9} xs={18} mb={3}>
                        <TextField
                          fullWidth
                          label="Number of New Squares "
                          // type="number"
                          // disabled
                          value={
                            (value.overlay_number_of_new_squares)
                          }
                          onChange={(e) =>
                            setValue({
                              ...value,
                              overlay_number_of_new_squares: Number(e.target.value),
                            })
                          }
                        />
                      </Grid> */}

                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <TextField
                                    fullWidth
                                    label="Number of Existing squares "
                                    // type="number"
                                    value={value.reroof_overlay_no_of_squares}
                                    onChange={(e) =>
                                      setValue({
                                        ...value,
                                        reroof_overlay_no_of_squares: Number(
                                          e.target.value
                                        ),
                                      })
                                    }
                                  />
                                </Grid>
                                {/*  Number of Layers */}
                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <TextField
                                    fullWidth
                                    label="Number of Layers"
                                    // type="number"
                                    value={value.overlay_layers}
                                    onChange={(e) =>
                                      setValue({
                                        ...value,
                                        overlay_layers: Number(e.target.value),
                                      })
                                    }
                                  />
                                </Grid>

                                {/*  Number of Old Squers */}
                                {/* <Grid item md={6} sm={9} xs={18} mb={3}>
                                <TextField
                                  fullWidth
                                  label="Number of Existing Squares "
                                  // type="number"
                                  value={
                                    value.number_of_planes_squares_for_add_planes_roof_overlay
                                  }
                                  onChange={(e) =>
                                    setValue({
                                      ...value,
                                      number_of_planes_squares_for_add_planes_roof_overlay:
                                        Number(
                                          e.target.value
                                        ),
                                    })
                                  }
                                />
                              </Grid> */}
                                {/* <Grid item md={6} sm={9} xs={18} mb={3}>
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    Existing Roof Material
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={
                                      value.planes_material_id_for_add_planes_roof_overlay
                                    }
                                    label="Existing Roof Material"
                                    onChange={(e) =>
                                      setValue({
                                        ...value,
                                        planes_material_id_for_add_planes_roof_overlay:
                                          e.target.value,
                                      })
                                    }
                                  >
                                    {ExistingRoofing?.map((job) => (
                                      <MenuItem value={job.id} key={job.id}>
                                        {job.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid> */}
                                {/*  Roof Pitch Degree. */}
                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <TextField
                                    fullWidth
                                    label="Roof Pitch Degree "
                                    value={value.overlay_pitch}
                                    onChange={(e) =>
                                      setValue({
                                        ...value,
                                        overlay_pitch: Number(e.target.value),
                                      })
                                    }
                                  />
                                </Grid>
                                {/* Roof Pitch Number of squares */}
                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <TextField
                                    fullWidth
                                    label="Number Of Steep Squares"
                                    value={value.overlay_steep}
                                    onChange={(e) =>
                                      setValue({
                                        ...value,
                                        overlay_steep: Number(e.target.value),
                                      })
                                    }
                                  />
                                </Grid>
                              </>
                            )}
                            {value.roof_job_type_id_roof_planes === 3 && (
                              <>
                                {/*  Number of Layers */}
                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <TextField
                                    fullWidth
                                    label="Number of Layers  "
                                    // type="number"
                                    value={
                                      value.number_of_planes_layers_for_add_roof_planes
                                    }
                                    onChange={(e) =>
                                      setValue({
                                        ...value,
                                        number_of_planes_layers_for_add_roof_planes:
                                          Number(e.target.value),
                                      })
                                    }
                                  />
                                </Grid>
                                {/*  Number of Old Squers */}
                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <TextField
                                    fullWidth
                                    label="Number of Old Squares "
                                    // type="number"
                                    value={
                                      value.number_of_planes_squares_for_add_planes
                                    }
                                    onChange={(e) =>
                                      setValue({
                                        ...value,
                                        number_of_planes_squares_for_add_planes:
                                          Number(
                                            e.target.value
                                            // , number_of_new_planes_sq: Number(e.target.value)
                                          ),
                                      })
                                    }
                                  />
                                </Grid>
                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Existing Roof Material
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={
                                        value.planes_material_id_for_add_planes
                                      }
                                      label="Existing Roof Material"
                                      onChange={(e) =>
                                        setValue({
                                          ...value,
                                          planes_material_id_for_add_planes:
                                            e.target.value,
                                        })
                                      }
                                    >
                                      {ExistingRoofing?.map((job) => (
                                        <MenuItem value={job.id} key={job.id}>
                                          {job.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                {/*  Roof Pitch Degree. */}
                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <TextField
                                    fullWidth
                                    label="Roof Pitch Degree "
                                    value={
                                      value.roof_pitch_degree_for_roof_planes
                                    }
                                    onChange={(e) =>
                                      setValue({
                                        ...value,
                                        roof_pitch_degree_for_roof_planes:
                                          Number(e.target.value),
                                      })
                                    }
                                  />
                                </Grid>
                                {/* Roof Pitch Number of squares */}
                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <TextField
                                    fullWidth
                                    label="Number Of Steep Squares"
                                    value={
                                      value.number_of_steep_sq_for_roof_planes
                                    }
                                    onChange={(e) =>
                                      setValue({
                                        ...value,
                                        number_of_steep_sq_for_roof_planes:
                                          Number(e.target.value),
                                      })
                                    }
                                  />
                                </Grid>
                              </>
                            )}

                            {/* <Grid item md={6} sm={9} xs={18} mb={3}>
                            <InputLabel>Add Job Type ? </InputLabel>
                            <span>No</span>
                            <Switch
                              checked={value.add_job_type_for_new_roof}
                              onChange={(e) =>
                                setValue({
                                  ...value,
                                  add_job_type_for_new_roof: e.target.checked,
                                })
                              }
                              inputProps={{ "aria-label": "controlled" }}
                            />
                            <span>Yes</span>
                          </Grid> */}
                            {value.add_job_type_for_new_roof === true && (
                              <>
                                <Grid item md={6} sm={9} xs={18} mb={3}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Job Type
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      required
                                      value={
                                        value.roof_job_type_id_for_Tilerelay
                                      }
                                      label="Job Type"
                                      onChange={(e) =>
                                        setValue({
                                          ...value,
                                          roof_job_type_id_for_Tilerelay:
                                            e.target.value,
                                        })
                                      }
                                    >
                                      {RoofJobsTypes?.map((job) => (
                                        <MenuItem value={job.id} key={job.id}>
                                          {job.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                {/* <Grid item md={6} sm={9} xs={18} mb={3}>
                                <TextField
                                  fullWidth
                                  label="Number of Old Squares "
                                  // type="number"
                                  value={value.number_of_old_squares_new_job_type}
                                  onChange={(e) =>
                                    setValue({
                                      ...value,
                                      number_of_old_squares_new_job_type: Number(e.target.value)
                                    })


                                  }
                                />
                              </Grid> */}
                              </>
                            )}
                          </>
                        )}
                        {/* End of Try */}

                        <Grid item xs={18} mb={3}>
                          <h5> New Roofing </h5>
                        </Grid>

                        {/*  Existing Roof*/}
                        <Grid item md={6} sm={9} xs={18} mb={3}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              New Roof Material
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              required
                              value={value.new_roof_material_id}
                              label="New Roof Material"
                              onChange={(e) =>
                                setValue({
                                  ...value,
                                  new_roof_material_id: e.target.value,
                                })
                              }
                            >
                              {NewRoofJobTypes?.map((roof) => (
                                <MenuItem value={roof.id} key={roof.id}>
                                  {roof.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        {/*  Number of new Squers */}
                        <Grid item md={6} sm={9} xs={18} mb={3}>
                          <TextField
                            fullWidth
                            label="Number of Squares "
                            // type="number"
                            // disabled
                            value={
                              (value.number_of_new_squares =
                                value.number_of_old_squares)
                            }
                            onChange={(e) =>
                              setValue({
                                ...value,
                                number_of_new_squares: Number(e.target.value),
                              })
                            }
                          />
                        </Grid>

                        {value.new_roof_material_id === 1 && (
                          <>
                            {/*  color of shigle  Roof*/}
                            {/* <Grid item md={6} sm={9} xs={18} mb={3}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                color Roof
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={value.color_id}
                                label="Existing Roof "
                                onChange={(e) =>
                                  setValue({
                                    ...value,
                                    color_id: e.target.value,
                                  })
                                }
                              >
                                {colors?.map((color) => (
                                  <MenuItem value={color.id} key={color.id}>
                                    {color.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid> */}
                            {/* start a color dropdown */}
                            <Grid item md={6} sm={9} xs={18} mb={3}>
                              <ColorRoofing>
                                <div className="select-box">
                                  <div
                                    className="select-box__current"
                                    tabIndex="1"
                                  >
                                    <div class="select-box__value">
                                      <input
                                        type="radio"
                                        class="select-box__input show"
                                        // id="0q"
                                        // value="1q"
                                        name="color"
                                        // checked
                                      />
                                      <p class="select-box__input-text work">
                                        Choose Color{" "}
                                      </p>
                                    </div>
                                    {colors?.map((color) => (
                                      <div
                                        className="select-box__value"
                                        key={color.id}
                                      >
                                        <input
                                          type="radio"
                                          className="select-box__input"
                                          id={color.id}
                                          value={color.id}
                                          name="color"
                                          // checked={color.id}
                                          onChange={(e) =>
                                            setValue({
                                              ...value,
                                              color_id: e.target.value,
                                            })
                                          }
                                        />
                                        <p
                                          className="select-box__input-text "
                                          style={{
                                            background: `url(${color.image_path})`,
                                          }}
                                        >
                                          {color.name}
                                        </p>
                                      </div>
                                    ))}
                                  </div>
                                  <ul class="select-box__list">
                                    {colors?.map((color) => (
                                      <li key={color.id}>
                                        <label
                                          class="select-box__option "
                                          htmlFor={color.id}
                                          style={{
                                            background: `url(${color.image_path})`,
                                          }}
                                        >
                                          {color.name}
                                        </label>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </ColorRoofing>
                            </Grid>

                            {/*  Number of Old Squers */}
                            {/* <Grid item md={6} sm={9} xs={18} mb={3}>
                            <TextField
                              fullWidth
                              label="New Material Cost per Sq. "
                              // type="number"
                              value={value.new_color_cost}
                              onChange={(e) =>
                                setValue({
                                  ...value,
                                  new_color_cost: Number(e.target.value),
                                })
                              }
                            />
                          </Grid> */}
                          </>
                        )}
                        {/*  Number of new Squers */}
                        <Grid
                          item
                          md={6}
                          sm={9}
                          xs={18}
                          mb={3}
                          style={{ display: "none" }}
                        >
                          <TextField
                            fullWidth
                            label="Number of Squares "
                            // type="number"
                            disabled
                            value={value.number_of_old_squares}
                            onChange={(e) =>
                              setValue({
                                ...value,
                                number_of_new_squares: Number(e.target.value),
                              })
                            }
                          />
                        </Grid>
                        {/* Add roof planes for new roof */}
                        <Grid item md={6} sm={9} xs={18} mb={3}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={value.is_new_roof_planes}
                                onChange={(e) =>
                                  setValue({
                                    ...value,
                                    is_new_roof_planes: e.target.checked,
                                  })
                                }
                              />
                            }
                            label="Add Roof Planes  ? "
                          />
                        </Grid>

                        {value.is_new_roof_planes && (
                          <>
                            {/*  Number of Old Squers */}
                            <Grid item md={6} sm={9} xs={18} mb={3}>
                              <TextField
                                fullWidth
                                label="Number of new Squares "
                                // type="number"
                                // disabled
                                value={value.number_of_planes_squares}
                                onChange={(e) =>
                                  setValue({
                                    ...value,
                                    number_of_planes_squares: Number(
                                      e.target.value
                                    ),
                                  })
                                }
                              />
                            </Grid>
                            {/*  Number of Layers */}
                            {/* <Grid item md={6} sm={9} xs={18} mb={3}>
                            <TextField
                              fullWidth
                              label="Number of Layers  "
                              // type="number"
                              value={value.number_of_planes_layers}
                              onChange={(e) =>
                                setValue({
                                  ...value,
                                  number_of_planes_layers: Number(e.target.value),
                                })
                              }
                            />
                          </Grid> */}
                            <Grid item md={6} sm={9} xs={18} mb={3}>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  New Roof Material
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  required
                                  value={value.new_planes_material_id}
                                  label="New Roof Material"
                                  onChange={(e) =>
                                    setValue({
                                      ...value,
                                      new_planes_material_id: e.target.value,
                                    })
                                  }
                                >
                                  {NewRoofJobTypes?.map((roof) => (
                                    <MenuItem value={roof.id} key={roof.id}>
                                      {roof.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        )}
                        {/*  Number of Layers */}
                        {/* <Grid item md={6} sm={9} xs={18} mb={3}>
                        <TextField
                          fullWidth
                          label="Number of Layers  "
                          // type="number"
                          value={value.number_of_new_layers}
                          onChange={(e) =>
                            setValue({
                              ...value,
                              number_of_new_layers: Number(e.target.value),
                            })
                          }
                        />
                      </Grid> */}
                      </>
                    )}

                    <Grid item xs={18} mb={3}>
                      <hr />
                    </Grid>

                    {/*  Redecking*/}
                    <Grid item md={6} sm={9} xs={18} mb={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={value.is_redecking}
                            onChange={(e) =>
                              setValue({
                                ...value,
                                is_redecking: e.target.checked,
                              })
                            }
                          />
                        }
                        label="Redecking ?"
                      />
                    </Grid>
                    {value.is_redecking && (
                      <>
                        {/*  Number of Redecking Squares */}
                        <Grid item md={6} sm={9} xs={18} mb={3}>
                          <TextField
                            fullWidth
                            label="Number of Redecking Squares  "
                            // type="number"
                            value={value.number_of_redecking_squares}
                            onChange={(e) =>
                              setValue({
                                ...value,
                                number_of_redecking_squares: Number(
                                  e.target.value
                                ),
                              })
                            }
                          />
                        </Grid>
                      </>
                    )}

                    {/*  Rafter Exchage */}
                    <Grid item md={6} sm={9} xs={18} mb={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={value.is_rafter}
                            onChange={(e) =>
                              setValue({
                                ...value,
                                is_rafter: e.target.checked,
                              })
                            }
                          />
                        }
                        label="Rafter Exchage ?"
                      />
                    </Grid>
                    {value.is_rafter && (
                      <>
                        {/*  Number of Rafter ft. */}
                        <Grid item md={6} sm={9} xs={18} mb={3}>
                          <TextField
                            fullWidth
                            label="Number of Rafter ft. "
                            // type="number"
                            value={value.number_of_rafter_ft}
                            onChange={(e) =>
                              setValue({
                                ...value,
                                number_of_rafter_ft: Number(e.target.value),
                              })
                            }
                          />
                        </Grid>
                      </>
                    )}

                    {/*  Is Light House ?  */}
                    {/* <Grid item md={6} sm={9} xs={18} mb={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={value.is_sky_light}
                            onChange={(e) =>
                              setValue({
                                ...value,
                                is_sky_light: e.target.checked,
                              })
                            }
                          />
                        }
                        label="Sky Light?"
                      />
                    </Grid> */}
                    {/*  Fascia Replace Board ?  */}
                    <Grid item md={6} sm={9} xs={18} mb={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={value.is_fascia}
                            onChange={(e) =>
                              setValue({
                                ...value,
                                is_fascia: e.target.checked,
                              })
                            }
                          />
                        }
                        label="Fascia Replace Board ?"
                      />
                    </Grid>
                    {value.is_fascia && (
                      <>
                        {/*  Number of Fasciz ft. */}
                        <Grid item md={6} sm={9} xs={18} mb={3}>
                          <TextField
                            fullWidth
                            label="Number of Fasciz ft. "
                            // type="number"
                            value={value.number_of_fascia_ft}
                            onChange={(e) =>
                              setValue({
                                ...value,
                                number_of_fascia_ft: Number(e.target.value),
                              })
                            }
                          />
                        </Grid>
                      </>
                    )}

                    {/*  Steep Roof ?*/}
                    {/* <Grid item md={6} sm={9} xs={18} mb={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={value.is_steep_pitch}
                            onChange={(e) =>
                              setValue({
                                ...value,
                                is_steep_pitch: e.target.checked,
                              })
                            }
                          />
                        }
                        label="Roof Pitch ?"
                      />
                    </Grid> */}
                    {/*  Roof Pitch Degree. */}
                    {/* {value.is_steep_pitch && (
                      <>
                       
                        <Grid item md={6} sm={9} xs={18} mb={3}>
                          <TextField
                            fullWidth
                            label="Roof Pitch Degree "
                            value={value.roof_pitch_degree}
                            onChange={(e) =>
                              setValue({
                                ...value,
                                roof_pitch_degree: Number(e.target.value),
                              })
                            }
                          />
                        </Grid>
                      </>
                    )} */}

                    <Grid item xs={18} mb={3}>
                      <hr />
                    </Grid>
                    {/*  HVAC Unit on Roof ?*/}
                    <Grid item md={6} sm={9} xs={18} mb={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={value.is_hvac_replace}
                            onChange={(e) =>
                              setValue({
                                ...value,
                                is_hvac_replace: e.target.checked,
                              })
                            }
                          />
                        }
                        label="HVAC Unit on Roof ?"
                      />
                    </Grid>
                    {value.is_hvac_replace && (
                      <>
                        {/*  Number of HVAC */}
                        <Grid item md={6} sm={9} xs={18} mb={3}>
                          <TextField
                            fullWidth
                            label="Number of HVAC Units"
                            value={value.number_of_hvac_units}
                            onChange={(e) =>
                              setValue({
                                ...value,
                                number_of_hvac_units: Number(e.target.value),
                              })
                            }
                          />
                        </Grid>
                      </>
                    )}

                    {/*  Air Vent Replacement ?*/}
                    <Grid item md={6} sm={9} xs={18} mb={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={value.is_air_vent_replacement}
                            onChange={(e) =>
                              setValue({
                                ...value,
                                is_air_vent_replacement: e.target.checked,
                              })
                            }
                          />
                        }
                        label="Air Vent Replacement ?"
                      />
                    </Grid>
                    {value.is_air_vent_replacement && (
                      <>
                        {/*  Number of Vents to Be Replaced */}
                        <Grid item md={6} sm={9} xs={18} mb={3}>
                          <TextField
                            fullWidth
                            label="Number of Vents to Be Replaced "
                            value={value.number_of_replaced_vents}
                            onChange={(e) =>
                              setValue({
                                ...value,
                                number_of_replaced_vents: Number(
                                  e.target.value
                                ),
                              })
                            }
                          />
                        </Grid>
                      </>
                    )}

                    {/*  New Air Vent ?*/}
                    <Grid item md={6} sm={9} xs={18} mb={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={value.is_new_vent}
                            onChange={(e) =>
                              setValue({
                                ...value,
                                is_new_vent: e.target.checked,
                              })
                            }
                          />
                        }
                        label="New Air Vent ?"
                      />
                    </Grid>
                    {value.is_new_vent && (
                      <>
                        {/*  Number of New Vents. */}
                        <Grid item md={6} sm={9} xs={18} mb={3}>
                          <TextField
                            fullWidth
                            label="Number of New Vents "
                            value={value.number_of_new_vents}
                            onChange={(e) =>
                              setValue({
                                ...value,
                                number_of_new_vents: Number(e.target.value),
                              })
                            }
                          />
                        </Grid>
                      </>
                    )}

                    {/*  Smoke Monitors ? */}
                    <Grid item md={6} sm={9} xs={18} mb={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={value.is_smoke_monitors}
                            onChange={(e) =>
                              setValue({
                                ...value,
                                is_smoke_monitors: e.target.checked,
                              })
                            }
                          />
                        }
                        label="Smoke Monitors ?"
                      />
                    </Grid>
                    {value.is_smoke_monitors && (
                      <>
                        {/*  Number of Smoke Delectors. */}
                        <Grid item md={6} sm={9} xs={18} mb={3}>
                          <TextField
                            fullWidth
                            label="Number of Smoke Delectors"
                            value={value.number_of_smoke_monitors}
                            onChange={(e) =>
                              setValue({
                                ...value,
                                number_of_smoke_monitors: Number(
                                  e.target.value
                                ),
                              })
                            }
                          />
                        </Grid>
                      </>
                    )}

                    {/*  Double Hand ?*/}
                    <Grid item md={6} sm={9} xs={18} mb={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={value.is_double_hands}
                            onChange={(e) =>
                              setValue({
                                ...value,
                                is_double_hands: e.target.checked,
                              })
                            }
                          />
                        }
                        label="Double Hand ?"
                      />
                    </Grid>
                    {value.is_double_hands && (
                      <>
                        {/*  Number of Squares  */}
                        <Grid item md={6} sm={9} xs={18} mb={3}>
                          <TextField
                            fullWidth
                            label="Number of Squares "
                            value={value.number_of_double_hand_squares}
                            onChange={(e) =>
                              setValue({
                                ...value,
                                number_of_double_hand_squares: Number(
                                  e.target.value
                                ),
                              })
                            }
                          />
                        </Grid>
                      </>
                    )}

                    {/*  Carbon Monoxide Monitors ? */}
                    <Grid item md={6} sm={9} xs={18} mb={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={value.is_monoxide_detectors}
                            onChange={(e) =>
                              setValue({
                                ...value,
                                is_monoxide_detectors: e.target.checked,
                              })
                            }
                          />
                        }
                        label="Carbon Monoxide Monitors ?"
                      />
                    </Grid>
                    {value.is_monoxide_detectors && (
                      <>
                        {/*  Number of Carbon Delectors. */}
                        <Grid item md={6} sm={9} xs={18} mb={3}>
                          <TextField
                            fullWidth
                            label="Number of Carbon Delectors  "
                            value={value.number_of_monoxide_detectors}
                            onChange={(e) =>
                              setValue({
                                ...value,
                                number_of_monoxide_detectors: Number(
                                  e.target.value
                                ),
                              })
                            }
                          />
                        </Grid>
                      </>
                    )}

                    {/*  Added Roof Cost  */}
                    <Grid item md={6} sm={9} xs={18} mb={3}>
                      <TextField
                        fullWidth
                        label="Added Roof Cost "
                        value={value.added_cost}
                        onChange={(e) =>
                          setValue({
                            ...value,
                            added_cost: Number(e.target.value),
                          })
                        }
                      />
                    </Grid>
                    {/* Description of Added Work */}
                    <Grid item md={6} sm={9} xs={18} mb={3}>
                      <TextField
                        fullWidth
                        label="Description of Added Work"
                        value={value.add_work_description}
                        onChange={(e) =>
                          setValue({
                            ...value,
                            add_work_description: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    {/* Roof Royalties */}
                    <Grid item md={6} sm={9} xs={18} mb={3}>
                      <TextField
                        fullWidth
                        label="Roof Royalties"
                        value={value.royalties_cost}
                        onChange={(e) =>
                          setValue({
                            ...value,
                            royalties_cost: Number(e.target.value),
                          })
                        }
                      />
                    </Grid>

                    {/*Notes */}
                    <Grid item md={6} sm={9} xs={18} mb={3}>
                      {/* <span>Notes</span> */}
                      <TextField
                        fullWidth
                        label="Notes"
                        value={value.notes}
                        onChange={(e) =>
                          setValue({
                            ...value,
                            notes: e.target.value,
                          })
                        }
                      />
                    </Grid>
                  </>
                )}


                {/* is_finance*/}
                <Grid item md={18} sm={18} xs={18} mb={3}>
                  <InputLabel>Cash / Finance</InputLabel>
                  <Switch
                    checked={value.is_finance}
                    onChange={(e) =>
                      setValue({
                        ...value,
                        is_finance: e.target.checked,
                      })
                    }
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Grid>
                {value.is_finance && (
                  <Grid item container spacing={2} columns={18}>
                    {/* calc*/}
                    {value.is_finance && (
                      <Grid
                        item
                        container
                        spacing={2}
                        columns={18}
                        // style={{ textAlign: "center" }}
                      >
                        <Grid item md={18} sm={18} xs={18} mb={3}>
                          <FormControl>
                            {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                            >
                              <Grid
                                item
                                container
                                // spacing={1}
                                columns={18}
                                gap={3}
                              >
                                {/* <Grid item md={6} sm={6} xs={18} mb={1}>
                                  {" "}
                                  <FormControlLabel
                                    value="sunlight"
                                    onChange={(e) => {
                                      setValue({
                                        ...value,
                                        financial_institution: e.target.value,
                                      });
                                    }}
                                    control={<Radio />}
                                    label="SunLight"
                                  />
                                </Grid> */}
                                {StatName === "CA" ||
                                "CO" ||
                                "FL" ||
                                "KY" ||
                                "NY" ? (
                                  <Grid item md={6} sm={6} xs={18} mb={1}>
                                    <FormControlLabel
                                      value="goodleap"
                                      // disabled
                                      onChange={(e) => {
                                        setValue({
                                          ...value,
                                          financial_institution: e.target.value,
                                        });
                                      }}
                                      control={<Radio />}
                                      label="GoodLeap"
                                    />
                                  </Grid>
                                ) : (
                                  <span style={{ padding: "0 20px" }}></span>
                                )}

                                {/* <Grid item md={6} sm={6} xs={18} mb={1}>
                                  <FormControlLabel
                                    value="mosaic"
                                    disabled
                                    onChange={(e) => {
                                      setValue({
                                        ...value,
                                        financial_institution: e.target.value,
                                      });
                                    }}
                                    control={<Radio />}
                                    label="Mosaic"
                                  />
                                </Grid> */}
                              </Grid>
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        {/* SunLight Screen */}
                        {value.financial_institution === "sunlight" && (
                          <>
                            <Grid item xs={18}>
                              <ClacButton>
                                <Calc />
                              </ClacButton>
                            </Grid>
                            {/* loan_years*/}
                            <Grid item md={6} sm={9} xs={18} mb={3}>
                              {/* <InputLabel> Loan Month</InputLabel>
                    <FormControl fullWidth>
                      <TextField
                        required
                        disabled={true}
                        value={info?.term || " Loan Month"}
                      />
                    </FormControl> */}
                              <CalcSunLightData>
                                <span>{info?.term || 0}</span>
                                <h5>Loan Term</h5>
                              </CalcSunLightData>
                            </Grid>
                            {/* finalMonthlyPayment*/}
                            {/* <Grid item md={6} sm={9} xs={18} mb={3}>
                    <InputLabel> Final Monthly Payment </InputLabel>
                    <TextField
                      fullWidth
                      required
                      disabled={true}
                      value={calculator.finalMonthlyPayment}
        
                    />
                  </Grid> */}
                            {/* finalEscalatedMonthlyPayment*/}
                            {/* <Grid item md={6} sm={9} xs={18} mb={3}>
                    <InputLabel> Final Escalated Monthly Payment </InputLabel>
                    <TextField
                      fullWidth
                      required
                      disabled={true}
                      value={calculator.finalEscalatedMonthlyPayment}
         
                    />
                  </Grid> */}
                            {/* monthlyPayment*/}
                            <Grid item md={6} sm={9} xs={18} mb={3}>
                              {/* <InputLabel> Monthly Payment </InputLabel>
                    <TextField
                      fullWidth
                      disabled={true}
                      required
                      value={calculator.monthlyPayment}
                      onChange={(e) =>
                        setValue({
                          ...value,
                          monthlyPayment: calculator.monthlyPayment,
                        })
                      }
                    /> */}
                              <CalcSunLightData>
                                <span>${Math.ceil(MONTH_PAY) || 0}</span>
                                <h5>Monthly payment for 1-17</h5>
                              </CalcSunLightData>
                            </Grid>
                            {/* escalatedMonthlyPayment*/}
                            <Grid item md={6} sm={9} xs={18} mb={3}>
                              {/* <InputLabel> Escalated Monthly Payment </InputLabel>
                    <TextField
                      fullWidth
                      disabled={true}
                      required
                      value={calculator.escalatedMonthlyPayment}
                      onChange={MonthChage}
                      
                    /> */}
                              <CalcSunLightData>
                                <span>
                                  $
                                  {Math.floor(
                                    calculator.escalatedMonthlyPayment
                                  ) || 0}
                                </span>
                                <h5>Monthly payment for 18+</h5>
                                <p style={{ fontSize: "10px" }}>
                                  Assuming tax credit is used for Solar
                                </p>
                              </CalcSunLightData>
                            </Grid>
                          </>
                        )}
                        {/* Good Leap Screen */}
                        {value.financial_institution === "goodleap" && (
                          <>
                            <Grid item xs={18}>
                              <ClacButton>
                                <CalcGoodLeadp
                                  servicId={value.service_type_id}
                                  disable={true}
                                />
                              </ClacButton>
                            </Grid>
                            {/* loan_years*/}
                            <Grid item md={4.5} sm={9} xs={18} mb={3}>
                              <CalcSunLightData>
                                <span>{TheOffer?.term || 0}</span>
                                <p style={{ fontSize: "14px" }}>Loan Term</p>
                                <p style={{ fontSize: "10px" }}>
                                  Term in Years
                                </p>
                              </CalcSunLightData>
                            </Grid>
                            {/* monthlyPayment*/}
                            <Grid item md={4.5} sm={9} xs={18} mb={3}>
                              <CalcSunLightData>
                                <span>
                                  $
                                  {
                                    // Math.ceil(
                                    resultFromPaymentCalc[0]?.roundedAmount
                                      ?.value || 0
                                    // ) || 0
                                  }
                                </span>
                                <p style={{ fontSize: "10px" }}>
                                  Estimated initial monthly payment for 18
                                  months
                                </p>
                                {/* <p style={{fontSize:"10px"}}>Estimated initial monthly payment for 18 months</p> */}
                              </CalcSunLightData>
                            </Grid>
                            {/* escalatedMonthlyPayment*/}
                            <Grid item md={4.5} sm={9} xs={18} mb={3}>
                              <CalcSunLightData>
                                <span>
                                  $
                                  {
                                    // Math.floor(
                                    resultFromPaymentCalc[1]?.roundedAmount
                                      ?.value || 0
                                    // ) || 0
                                  }
                                </span>
                                <p style={{ fontSize: "10px" }}>
                                  Estimated adjusted monthly payment after 18
                                  months without paydown
                                </p>
                                {/* <p style={{ fontSize: "10px" }}>
                             Assuming tax credit is used for Solar
                             Estimated adjusted monthly payment after 18 months without paydown
                              </p> */}
                              </CalcSunLightData>
                            </Grid>
                            {/* New In Good Leap */}
                            <Grid item md={4.5} sm={9} xs={18} mb={3}>
                              <CalcSunLightData>
                                <span>
                                  $
                                  {
                                    // Math.floor(
                                    resultFromPaymentCalc[2]?.roundedAmount
                                      ?.value || 0
                                    // ) || 0
                                  }
                                </span>
                                <p style={{ fontSize: "10px" }}>
                                  {" "}
                                  Estimated adjusted monthly payment after 18
                                  months with paydown
                                </p>
                                {/* <p style={{ fontSize: "10px" }}>
                             Assuming tax credit is used for Solar
                             Estimated adjusted monthly payment after 18 months with paydown
                              </p> */}
                              </CalcSunLightData>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    )}
                    {/* loan_years*/}
                    {/* <Grid item md={6} sm={9} xs={18} mb={3}> */}
                    {/* <InputLabel> Loan Month</InputLabel>
                    <FormControl fullWidth>
                      <TextField
                        required
                        disabled={true}
                        value={info?.term || " Loan Month"}
                      />
                    </FormControl> */}
                    {/* <CalcSunLightData>
                      <span>{info?.term || 0}</span>
                      <h5>Loan Term</h5>
                    </CalcSunLightData>
                  </Grid> */}
                    {/* finalMonthlyPayment*/}
                    {/* <Grid item md={6} sm={9} xs={18} mb={3}>
                    <InputLabel> Final Monthly Payment </InputLabel>
                    <TextField
                      fullWidth
                      required
                      disabled={true}
                      value={calculator.finalMonthlyPayment}
        
                    />
                  </Grid> */}
                    {/* finalEscalatedMonthlyPayment*/}
                    {/* <Grid item md={6} sm={9} xs={18} mb={3}>
                    <InputLabel> Final Escalated Monthly Payment </InputLabel>
                    <TextField
                      fullWidth
                      required
                      disabled={true}
                      value={calculator.finalEscalatedMonthlyPayment}
         
                    />
                  </Grid> */}
                    {/* monthlyPayment*/}
                    {/* <Grid item md={6} sm={9} xs={18} mb={3}> */}
                    {/* <InputLabel> Monthly Payment </InputLabel>
                    <TextField
                      fullWidth
                      disabled={true}
                      required
                      value={calculator.monthlyPayment}
                      onChange={(e) =>
                        setValue({
                          ...value,
                          monthlyPayment: calculator.monthlyPayment,
                        })
                      }
                    /> */}
                    {/* <CalcSunLightData>
                      <span>${Math.ceil(MONTH_PAY) || 0}</span>
                      <h5>Monthly payment for 1-17</h5>
                    </CalcSunLightData>
                  </Grid> */}
                    {/* escalatedMonthlyPayment*/}
                    {/* <Grid item md={6} sm={9} xs={18} mb={3}> */}
                    {/* <InputLabel> Escalated Monthly Payment </InputLabel>
                    <TextField
                      fullWidth
                      disabled={true}
                      required
                      value={calculator.escalatedMonthlyPayment}
                      onChange={MonthChage}
                      
                    /> */}
                    {/* <CalcSunLightData>
                      <span>
                        ${Math.floor(calculator.escalatedMonthlyPayment) || 0}
                      </span>
                      <h5>Monthly payment for 18+</h5>
                      <p style={{ fontSize: "10px" }}>
                        Assuming tax credit is used for Solar
                      </p>
                    </CalcSunLightData>
                  </Grid> */}
                    {/* paydownPercentage*/}
                    {/* <Grid item md={6} sm={9} xs={18} mb={3}>
                    <InputLabel> Paydown Percentage</InputLabel>
                    <TextField
                      fullWidth
                      disabled={true}
                      required
                      value={calculator.paydownPercentage}
                      onChange={(e) =>
                        setValue({
                          ...value,
                          paydownPercentage: calculator.paydownPercentage,
                        })
                      }
                    />
                  </Grid> */}
                    {/* alternateMonthlyPayment*/}
                    {/* <Grid item md={6} sm={9} xs={18} mb={3}>
                    <InputLabel> Alternate Monthly Payment</InputLabel>
                    <TextField
                      fullWidth
                      disabled={true}
                      required
                      type="number"
                      value={calculator.alternateMonthlyPayment}
                      onChange={(e) =>
                        setValue({
                          ...value,
                          alternateMonthlyPayment: Number(e.target.value),
                        })
                      }
                    />
                  </Grid> */}
                  </Grid>
                )}
                <Grid item lg={18} md={18} sm={18} xs={18}>
                  <ButtonContainer>
                    <Button type="submit" variant="contained">
                      Add Solution
                    </Button>
                  </ButtonContainer>
                </Grid>
              </Grid>
            </form>
          </div>
          {isLoading && <LoadingSoultion />}
        </>
      )}

      {isSingleLeadLoading && (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      )}
      {isSuccess && (
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <ModelConatiner>
              <div className="icon">
                <CheckCircle />
              </div>
              <p>Solution has been created successfully</p>
              <div className="btn_sucess">
                <Button
                  variant="contained"
                  className="go_lead"
                  onClick={handleGoToLeads}
                >
                  Go to Lead Profile
                </Button>
                {/* <Button
                      variant="contained"
                      className="add_lead"
                      onClick={handleAddLead}
                    >
                      Add New Solution
                    </Button> */}
              </div>
            </ModelConatiner>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default RoofingForm;
