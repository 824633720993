import axios from "axios";
import swal from "sweetalert";
import Swal from "sweetalert2";
import {
  CREATE_CONTRACT_URL,
  LEAD_URL,
  Opportunities_URL,
  SEND_CONTREACT_EMAIL_URL,
  SEND_NOTIFICATION_EMAIL_URL,
} from "../../API/Api";
import { ShowLoading, HideLoading } from "./loadingAction";
import {
  CREATE_CONTRACT,
  DELETE_SOLUTION,
  SEND_CONTRACT_TO_SIGN,
  SEND_MAIL_PROP_NOTIFICATION,
  UPLOAD_SITE_MEDIA,
  UPLOAD_UTILITY_BILL,
} from "./type";

export const createContractAction = (data) => async (dispatch) => {
  dispatch(ShowLoading());
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  await axios({
    method: "POST",
    baseURL: `${CREATE_CONTRACT_URL}`,
    data: data,
    headers: headers,
  })
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: CREATE_CONTRACT,
        payload: {
          createContact: res.data.data,
        },
      });
      dispatch(HideLoading());

      swal({
        title: " succsfully ",
        text: " Project created successfully",
        icon: "success",
        button: "OK",
      });
    })
    .catch((err) => {
      console.log(err.response.data);
      let errors = err?.response?.data || [];
      if (errors.length > 0) {
        errors?.map((e) => {
          for (const property in e) {
            Swal.fire({
              title: "Error!",
              html: `${property}: ${e[property]}<p> please navigate to Documents tab to upload </p>`,
              icon: "error",
              confirmButtonText: "Ok",
            });
          }
        });
      } else {
        for (const property in errors) {
          Swal.fire({
            title: "Error!",
            html: `${property}: ${errors[property]}`,
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      }

      console.log("errors", errors);
      dispatch(HideLoading());
    });
};

/// ------------- send contarct to sign --------------------///

export const sendContractToSign = (id) => async (dispatch) => {
  dispatch(ShowLoading());
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  await axios({
    method: "POST",
    baseURL: `${SEND_CONTREACT_EMAIL_URL}/${id}`,
    headers: headers,
  })
    .then((res) => {
      dispatch({
        type: SEND_CONTRACT_TO_SIGN,
        payload: {
          sendEmail: res.data,
        },
      });

      dispatch(HideLoading());
      swal({
        title: " succsfully ",
        text: " Contract Sent successfully",
        icon: "success",
        button: `OK`,
      });
    })
    .catch((err) => {
      console.log(err.response.data.message);
      dispatch(HideLoading());
      swal({
        title: " error ",
        text: `${err.response.data.message}`,
        icon: "error",
        button: "OK",
      });
    });
};

//----------- SEND PROPSALS TO EMAIL NOTIFICATION ---------//

export const sendMailPropsals = (id) => async (dispatch) => {
  dispatch(ShowLoading());
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  await axios({
    method: "POST",
    baseURL: `${SEND_NOTIFICATION_EMAIL_URL}/${id}`,
    headers: headers,
  })
    .then((res) => {
      dispatch({
        type: SEND_MAIL_PROP_NOTIFICATION,
        payload: {
          sendMailProp: res.data,
        },
      });

      dispatch(HideLoading());

      swal({
        title: " succsfully ",
        text: " The Mail sent Successfully",
        icon: "success",
        button: "OK",
      });
    })
    .catch((err) => {
      swal({
        title: " error ",
        text: " There is a problem with sending an email",
        icon: "error",
        button: "OK",
      });
      dispatch(HideLoading());
    });
};

//----------- SEND PROPSALS TO EMAIL NOTIFICATION ---------//

export const DeleteSolutionAction = (id) => async (dispatch) => {
  dispatch(ShowLoading());
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  await axios({
    method: "DELETE",
    baseURL: `${Opportunities_URL}/${id}`,
    headers: headers,
  })
    .then((res) => {
      dispatch({
        type: DELETE_SOLUTION,
        payload: {
          deleteSolution: res.data,
        },
      });
      dispatch(HideLoading());
      swal({
        title: " succsfully ",
        text: " The Solution Deleted Successfully",
        icon: "success",
        button: "OK",
      });
    })
    .catch((err) => {
      swal({
        title: " error ",
        text: " There is a problem with Deleting a Solution",
        icon: "error",
        button: "OK",
      });
      dispatch(HideLoading());
    });
};

// LeafLet Area
export const TotalArea = (data) => {
  return async (dispatch) => {
    await dispatch({
      type: "TOTAL_AREA",
      totalArea: data,
    });
  };
};
// LeafLet ScreenShot
export const ScreenShot = (data) => {
  return async (dispatch) => {
    await dispatch({
      type: "SCREEN_SHOT",
      screenShot: data,
    });
  };
};

// All Static ADDERA
export const Adder = (data) => {
  return async (dispatch) => {
    await dispatch({
      type: "ADDERS",
      all_Adders : data,
    });
  };
};
/////////////////////////////////////////////

export const UploadUtilityBill = (id, data) => async (dispatch) => {
  dispatch(ShowLoading());
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    enctype: "multipart/form-data",
  };
  await axios({
    method: "POST",
    baseURL: `${LEAD_URL}/${id}/media/utility_bill`,
    headers: headers,
    data: data,
  })
    .then((res) => {
      dispatch({
        type: UPLOAD_UTILITY_BILL,
        payload: {
          uploadUtility: res.data,
        },
      });
      dispatch(HideLoading());
      swal({
        title: " succsfully ",
        text: " The files added  Successfully",
        icon: "success",
        button: "OK",
      });
    })
    .catch((err) => {
      swal({
        title: " error ",
        text: " There is a problem for upload file ",
        icon: "error",
        button: "OK",
      });
      dispatch(HideLoading());
    });
};

///////////////////////

export const UploadSiteMedia = (id, data) => async (dispatch) => {
  dispatch(ShowLoading());
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    enctype: "multipart/form-data",
  };
  await axios({
    method: "POST",
    baseURL: `${LEAD_URL}/${id}/media/site_media`,
    headers: headers,
    data: data,
  })
    .then((res) => {
      dispatch({
        type: UPLOAD_SITE_MEDIA,
        payload: {
          uploadMedia: res.data,
        },
      });

      dispatch(HideLoading());

      swal({
        title: " succsfully ",
        text: " The files added  Successfully",
        icon: "success",
        button: "OK",
      });
    })
    .catch((err) => {
      swal({
        title: " error ",
        text: " There is a problem for upload file ",
        icon: "error",
        button: "OK",
      });
      dispatch(HideLoading());
    });
};

//////////////////////
// All Static ADDERA
export const RoofingPrices = (data) => {
  return async (dispatch) => {
    await dispatch({
      type: "ROOFINGPRICES",
      all_roofing : data,
    });
  };
};