import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ComingSoon from "../../../../components/soon/CommingSoon";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  FormHelperText,
  Grid,
  InputLabel,
  Tab,
  TextField,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import {
  getParentAchievement,
  getSalesAchievement,
  postAchievement,
} from "../../../../Redux/Actions/achievementsActions";
import { useEffect } from "react";
import { Bigsection, Loading, MyInputLable } from "./style";
const CreateTarget = () => {
  const dispatch = useDispatch();
  const {
    salesAcievement,
    parentAchievement,
    editAcievement,
    singleAcievement,
    achievement,
    achievementRes,
  } = useSelector((state) => state?.achievements);
  const salesInfo = JSON.parse(localStorage.getItem("salesAgent"));
  const checkRoleAdmin = () => {
    return salesInfo.roles.some((item) => item.title === "admin");
  };

  const { isLoading } = useSelector((state) => state.proposal);

  const getApis = async () => {
    await dispatch(getParentAchievement());
    await dispatch(getSalesAchievement());
    await checkRoleAdmin();
  };
  const schema = yup.object().shape({
    start_at: yup.date().required("this start date is required"),
    end_at: yup
      .date()
      .nullable()
      .notRequired()
      .min(yup.ref("start_at"), "end date can't be before start date"),
    // yup.date(),
    //   .nullable()
    //   .notRequired()
    //   .min(yup.ref("start_at"), "end date can't be before start date"),
    count: yup.number("must be number").required("count is required").min(1),
    award: yup.number("must be number").required("award is required").min(1),
  });

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {},
  });
  const {
    formState: { errors },
  } = methods;
  const [apiErrors, setApiErrors] = React.useState("");
  const onSubmit = async (data) => {
    console.log("Data", data);
    const sendData = {
      start_at: data?.start_at.toISOString().split("T")[0],
      end_at: data?.end_at ? data?.end_at.toISOString().split("T")[0] : null,
      count: data?.count,
      award: data?.award,
    };
    try {
      await dispatch(postAchievement(sendData));
      await dispatch(getParentAchievement());
      await dispatch(getSalesAchievement());
    } catch (e) {
      setApiErrors(e);
    }
  };
  console.log("achievement", achievement);
  console.log("apiErrors", apiErrors);
  console.log("achievementRes", achievementRes?.data?.errors);
  let errorsApi = achievementRes?.data?.errors;
  const showError = () => {
    for (const property in errorsApi) {
      return (
        <p style={{ color: "red" }}>{`${property}: ${errorsApi[property]}`}</p>
      );
    }
  };

  return (
    <div className="form">
      {showError()}

      {salesInfo?.children?.length > 0 ? (
        <>
          {" "}
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Grid container columns={18} spacing={3}>
                <Grid item xs={18} md={6}>
                  <MyInputLable
                    sx={{ color: "#344054" }}
                    htmlFor="Start Date"
                    required
                  >
                    Start Date
                  </MyInputLable>
                  <Controller
                    name="start_at"
                    // control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        type="date"
                        placeholder="22/6/23"
                        fullWidth
                        error={Boolean(errors.start_at)}
                        onBlur={onBlur}
                        onChange={onChange}
                      ></TextField>
                    )}
                  ></Controller>
                  <ErrorMessage
                    render={({ message }) => (
                      <FormHelperText sx={{ color: "#f00" }}>
                        {message}
                      </FormHelperText>
                    )}
                    errors={errors}
                    name="start_at"
                  />
                </Grid>
                <Grid item xs={18} md={6}>
                  <MyInputLable
                    sx={{ color: "#344054" }}
                    htmlFor={"end_at"}
                    // required
                  >
                    End Date
                  </MyInputLable>
                  <Controller
                    name="end_at"
                    // control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        type="date"
                        placeholder="22/6/23"
                        fullWidth
                        error={Boolean(errors.end_at)}
                        onBlur={onBlur}
                        onChange={onChange}
                      ></TextField>
                    )}
                  ></Controller>
                  <ErrorMessage
                    render={({ message }) => (
                      <FormHelperText sx={{ color: "#f00" }}>
                        {message}
                      </FormHelperText>
                    )}
                    errors={errors}
                    name="end_at"
                  />
                </Grid>
                <Grid item xs={18} md={6}>
                  <MyInputLable
                    sx={{ color: "#344054" }}
                    htmlFor={"count"}
                    required
                  >
                    Number of signed contracts{" "}
                  </MyInputLable>
                  <Controller
                    name="count"
                    // control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        type="number"
                        placeholder="10 contracts"
                        fullWidth
                        error={Boolean(errors.count)}
                        onBlur={onBlur}
                        onChange={onChange}
                      ></TextField>
                    )}
                  ></Controller>
                  <ErrorMessage
                    render={({ message }) => (
                      <FormHelperText sx={{ color: "#f00" }}>
                        {message}
                      </FormHelperText>
                    )}
                    errors={errors}
                    name="count"
                  />
                </Grid>
                <Grid item xs={18} md={6}>
                  <MyInputLable
                    sx={{ color: "#344054" }}
                    htmlFor="award"
                    required
                  >
                    Award
                  </MyInputLable>
                  <Controller
                    name="award"
                    // control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        type="num"
                        placeholder="coins"
                        fullWidth
                        error={Boolean(errors.award)}
                        onBlur={onBlur}
                        onChange={onChange}
                      ></TextField>
                    )}
                  ></Controller>
                  <ErrorMessage
                    render={({ message }) => (
                      <FormHelperText sx={{ color: "#f00" }}>
                        {message}
                      </FormHelperText>
                    )}
                    errors={errors}
                    name="award"
                  />
                </Grid>
                <Grid item xs={18}>
                  <div className="btContainer">
                    <button type="submit" className="create">
                      Set Target
                    </button>
                  </div>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CreateTarget;
