import { Grid } from "@mui/material";
import FooterPDF from "./FooterPDF";
import bgFooter from "../../../assets/bus.png";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import singleLeadAction from "../../../Redux/Actions/singleLeadAction";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const PageSixPdf = ({ singleOpportunity }) => {
  return (
    <PageSixStyled>
      <h2>THE ASSUMPTIONS</h2>
      <div className="createProposal">
        <h4>What We Used to Create Your Proposal</h4>
        <p>
          The calculations in this proposal are based in part on the information
          you have provided to your solar consultant. Please review the usage
          and rate assumptions below for accuracy. If you provided 12 months of
          electric consumption data, the proposal will be more accurate than if
          you provided a monthly average or annual total. These calculations
          assume that your electric consumption behavior in the future does not
          change from your prior year's usage.{" "}
        </p>
        <br />
        <p>
          Your solar consultant may suggest you switch to a Time-of-Use plan
          after your solar system has been installed. If so, please check with
          your utility company to determine if this plan is right for you.{" "}
        </p>
      </div>
      <Grid container columns={18}>
        <Grid item sm={9}>
          <h5>Electricity Usage </h5>
          <div className="assumptions">
            <div className="assumptions__items">
              <span>Est. Annual Electric Usage </span>
              <span className="dataFromApi">
                {singleOpportunity.annual_kwh_usage}
              </span>
            </div>
            <div className="assumptions__items">
              <span>Usage Source Data </span>
              <span className="dataFromApi">Given By Customer</span>
            </div>
          </div>
        </Grid>
        <Grid item sm={9}>
          <h5>Utility Assumptions</h5>
          <div className="assumptions">
            <div className="assumptions__items">
              <span>Utility Company </span>
              <span className="dataFromApi">
                {/* {singleOpportunity.utility_provider.title} */}
              </span>
            </div>
            <div className="assumptions__items">
              <span>Current Rate Plan </span>
              <span className="dataFromApi">0.05</span>
            </div>
          </div>
        </Grid>
        <Grid item sm={9}>
          <h5>System Configuration </h5>
          <div className="assumptions">
            <div className="assumptions__items">
              <span>System Size (DC)</span>
              <span className="dataFromApi">
                {" "}
                {Math.floor(singleOpportunity.system_size)}
              </span>
            </div>
            <div className="assumptions__items">
              <span>System Size (AC) </span>
              <span className="dataFromApi">
                {" "}
                {Math.floor(singleOpportunity.system_size_ac)}
              </span>
            </div>
            <div className="assumptions__items">
              <span>Number of Panels </span>
              <span className="dataFromApi">
                {singleOpportunity.number_of_panels}
              </span>
            </div>
            <div className="assumptions__items">
              <span>Panel Watts </span>
              <span className="dataFromApi">
                {singleOpportunity.panel_wattage}
              </span>
            </div>
            <div className="assumptions__items">
              <span>Inverter Type </span>
              <span className="dataFromApi">
                {singleOpportunity.inverter_type}
              </span>
            </div>
            <div className="assumptions__items">
              <span>Annual System Degradation</span>
              <span className="dataFromApi">0.007</span>
            </div>
            <div className="assumptions__items">
              <span>Panel Model</span>
              <span className="dataFromApi"> {singleOpportunity.panels}</span>
            </div>
            <div className="assumptions__items">
              <span>Inverter Model</span>
              <span className="dataFromApi">
                {singleOpportunity.inverter_type}
              </span>
            </div>
          </div>
        </Grid>
        <Grid item sm={9}>
          <h5>Product Assumptions</h5>
          <div className="assumptions">
            <div className="assumptions__items">
              <span>Estimated % of Usage </span>
              <span className="dataFromApi">
                {singleOpportunity.productivity_offset}
              </span>
            </div>
            <div className="assumptions__items">
              <span>Estimated Annual</span>
              <span className="dataFromApi">
                {Math.floor(singleOpportunity.system_size)}
              </span>
            </div>
            <div className="assumptions__items">
              <span>Production </span>
              <span className="dataFromApi"></span>
            </div>
          </div>

          <h5>Incentives & Credits</h5>
          <div className="assumptions">
            <div className="assumptions__items">
              <span>Estimated Federal Tax Credit (Not Guaranteed)</span>
              <span className="dataFromApi">
                $ {singleOpportunity.federal_tax}
              </span>
            </div>
            {/* <div className="assumptions__items">
              <span></span>
              <span className="dataFromApi">....</span>
            </div> */}
          </div>
        </Grid>
      </Grid>
      <div className="content">
        <p>
          Savings on your total electricity costs is not guaranteed. All Premium
          Contractors, Inc. can control only its charges to you. Future utility
          rates and charges, as well as net metering value (where available) may
          change. The more information you provide us, the more accurate these
          estimates can be. Calculations assume you qualify for full investment
          tax credit. These estimates are subject to change after site
          inspection and the design of your system, which can be affected by
          shading, roof type, compliance with fire codes, location, system size,
          government rebates and local utility rates. First years savings may be
          less if your loan payments commence before your system is
          interconnected by the utility.{" "}
        </p>
        <br />
        <p>
          Changes in net energy metering policy or changes in utility rates or
          rate structures may result in lower utility bill savings than
          estimated or none at all; All Premium Contractors, Inc. will not be
          responsible for reassessing the contract rate. CA CSLB 920361.
        </p>
      </div>

      <div className="imagePgagSix">
        <img src={bgFooter} alt="img" />
      </div>
      <FooterPDF />
    </PageSixStyled>
  );
};

const PageSixStyled = styled.div`
  h2 {
    text-align: center;
    margin: 2.5rem 0;
    color: #113474;
  }
  .createProposal {
    h4 {
      color: #29ca29;
      margin: 1rem 0;
    }
    p {
      color: #888;
    }
  }
  h5 {
    margin: 1rem 0;
    color: #113474;
    font-size: 1.2rem;
  }
  .assumptions {
    margin: 1rem 0;
    &__items {
      padding: 0.5rem 2rem 0 0;
      display: flex;
      justify-content: space-between;
    }
  }
  .content {
    font-size: 0.8rem;
    color: #777;
  }
  .imagePgagSix {
    width: 100%;
    img {
      width: 100%;
    }
  }
`;
export default PageSixPdf;
