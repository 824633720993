import {
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import styled from "styled-components";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  AddInstaller,
  GetInstallerStates,
  GetInstallerLecenseType,
} from "../../Redux/Actions/addInstallerAction";
import { useDispatch } from "react-redux";
import NumberFormat from "react-number-format";
import { v4 as uuidv4 } from "uuid";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import animationData from "./96943-app-loader.json";
import Lottie from "react-lottie";
import ReCAPTCHA from "react-google-recaptcha";
import {
  SubmitButton,
  FormConatiner,
  MyButton,
  AddLicense,
  Result,
  AddBtn,
  Table,
  DeleteButton,
  Loading,
} from "./InstallerStyle";
// Google Maps
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from "react-places-autocomplete";
import Geocode from "react-geocode";
import {
  GoogleMap,
  LoadScript,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import NewHeader from "../NewLandingPage/LandingPageComponents/Header/NewHeader";
import { WindowSharp } from "@mui/icons-material";
import { useLocation } from "react-router-dom";

// Style

const Div = styled.div`
  position: relative;
  margin-bottom: 1rem;
`;

// lottie
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "green",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "black",
    },
    "&:hover fieldset": {
      borderColor: "yellow",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#808080",
    },
  },
});
// Style for MUI multi select
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const InstallPartnerPage = () => {
  const dispatch = useDispatch();
  // google Recaptcha

  React.useEffect(() => {
    dispatch(GetInstallerStates());
    dispatch(GetInstallerLecenseType());
  }, []);

  const loader = useSelector((state) => state.installerReducer.isLoading);
  const installerStates =
    useSelector((state) => state.installerReducer.allStates) || [];
  const installerLicenseTypes =
    useSelector((state) => state.installerReducer.allLicnsesType) || [];
  // console.log("installerStates", installerStates);
  // console.log("installerLicenseTypes", installerLicenseTypes);

  // Single license_image
  const [license_image, setLicense_image] = React.useState("");
  // console.log("license_image",license_image)
  // Single logo
  const [logo, setLogo] = React.useState("");
  // The Array of Objects (license)
  const [license, setLicense] = React.useState([]);
  // console.log("license", license);
  //the One object of Array (license)
  const [licenseInfo, setLicenseInfo] = React.useState({
    license: "",
    license_type_id: "",
    state_id: "",
    license_expiration_date: new Date().toISOString().split("T")[0],
    image: "",
  });
  const initialState = {
    license: "",
    license_type_id: "",
    state_id: "",
    license_expiration_date: new Date().toISOString().split("T")[0],
    image: "",
  };
  // console.log("licenseInfo",licenseInfo)
  const [time, setTime] = React.useState({});
  const [data, setData] = React.useState({
    company_name: "",
    business_phone: "",
    business_fax: "",
    business_reachable_hours: "from am to pm",
    date_of_business_origination: new Date().toISOString().split("T")[0],
    name: "",
    email: "",
    logo: "",
    another_license: false,
    days: [],
    password_confirmation: "",
    password: "",
  });
  // Disable button
  const [disable, setDisable] = React.useState(true);
  // Google Recaptcha
  const recaptchaRef = React.useRef(null);
  const token = recaptchaRef.current?.getValue();
  // console.log("token",token)
  // console.log("data", data);

  var licenseToBackend = {
    licenses: license,
  };
  const daysInWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  // Google Maps
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyCrr_bBNHxQ0lZKadmJYpqS2vOebczD1m4",
    libraries: ["places"],
  });

  const mapStyles = {
    width: "100%",
    height: "80vh",
  };
  const [address, setAddress] = React.useState("");
  const [full_address, setFullAddress] = React.useState("");
  const [zip_code, setPostalCode] = React.useState("");
  const [street, setStreetLoc] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [state, setState] = React.useState("");
  const [route, setRoute] = React.useState("");
  const [city, setCity] = React.useState("");
  const [manualAddress, setManualAddres] = React.useState("");
  const [alter_address, setAlterAddress] = React.useState("");
  const [coordinates, setCoordinates] = React.useState({
    lat: 35.6541977,
    lng: -105.1424589,
  });
  const [allCoordinates, setAllCoordinates] = React.useState([]);
  // GeoCoding
  React.useEffect(() => {
    Geocode.setLocationType("ROOFTOP");
    Geocode.setApiKey("AIzaSyCrr_bBNHxQ0lZKadmJYpqS2vOebczD1m4");
    Geocode.fromLatLng(coordinates.lat, coordinates.lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        let street, city, state, country, postalCode, route;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "street_number":
                street = response.results[0].address_components[i].long_name;
                break;
              case "locality":
                city = response.results[0].address_components[i].long_name;
                break;
              case "postal_code":
                postalCode =
                  response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                break;

              case "route":
                route = response.results[0].address_components[i].long_name;
                break;
            }
          }
        }
        // Set the Normal states
        // setPostalCode({...data,zip_code:postalCode});
        setPostalCode(postalCode);
        setFullAddress(address);
        setStreetLoc(street);
        setCountry(country);
        setState(state);
        setRoute(route);
        setCity(city);
      },
      (error) => {
        console.error(error);
      }
    );
  }, [coordinates]);
  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    setCoordinates(latLng);
  };

  const [workingDays, setWorkingDays] = React.useState([]);

  React.useEffect(() => {
    data.business_reachable_hours = `from ${time.start_business_reachable_hours} to ${time.end_business_reachable_hours} `;
  }, [time]);
  const [disabled, setDisabled] = React.useState(false);

  const onChange = (e) => {
    let files = e.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {
      setLicense_image(e.target.result);
      // setData({ ...data, license_image: e.target.result });
    };
  };

  const onChangeForLogo = (e) => {
    let files = e.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {
      // setLogo(e.target.result);onChangeForLogo
      setData({ ...data, logo: e.target.result });
    };
  };

  // license.map((e, i) => {
  //   for (const property in e) {
  //     console.log(`${property}: ${e[property]}`);
  //   }
  // });

  const handlSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    recaptchaRef.current.reset();
    // console.log("token", token);
    formData.append("logo", data.logo);
    formData.append("password", data.password);
    formData.append("password_confirmation", data.password_confirmation);
    formData.append("company_name", data.company_name);
    formData.append("business_phone", data.business_phone);
    formData.append("business_fax", data.business_fax);
    formData.append("business_reachable_hours", data.business_reachable_hours);
    formData.append(
      "date_of_business_origination",
      data.date_of_business_origination
    );
    formData.append("name", data.name);
    formData.append("business_address", data.business_address);
    formData.append("rate", data.rate);
    formData.append("email", data.email);
    formData.append("logo", data.logo);
    formData.append("business_reachable_days", workingDays);
    formData.append("g-recaptcha-response", token);
    license.map((e, i) => {
      for (const property in e) {
        // console.log(`${property}: ${e[property]}`);
        formData.append(`licenses[${i}][${property}]`, e[property]);
      }
    });

    dispatch(AddInstaller(formData));
    // dispatch(AddInstaller({ ...data, ...licenseToBackend}));
    // dispatch(AddInstaller(data));
  };

  const deleteFromLicenses = (id) => {
    const newLicense = [...license];
    const index = license.findIndex((e) => e.id === id);
    // console.log("TheRow", index);
    newLicense.splice(index, 1);
    setLicense(newLicense);
  };
  const deleteFromLocations = (id) => {
    const newCoordinate = [...allCoordinates];
    const index = allCoordinates.findIndex((e) => e.id === id);
    // console.log("TheRow", index);
    newCoordinate.splice(index, 1);
    setAllCoordinates(newCoordinate);
  };
  // for The Working Days
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setWorkingDays(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  // For loading Google Maps
  if (!isLoaded) {
    return <p>loading...</p>;
  }

  const GetTheType = (id) => {
    var theType = installerLicenseTypes.find((e) => e.id === id);
    return theType?.title;
  };
  const GetTheStateID = (id) => {
    var theState = installerStates.find((e) => e.id === id);
    return theState?.name;
  };

  const onVerify = async () => {};
  return (
    <>
      <FormConatiner>
        <form onSubmit={handlSubmit}>
          <Grid container columns={18} spacing={3}>
            <Grid item xs={18} mb={3}>
              <h2 style={{ textAlign: "center" }}>Form For Installer</h2>
            </Grid>
            <Grid item xs={18} mb={3}>
              <h3> Personal Data</h3>
              <br></br>
              <hr />
            </Grid>
            {/* Name of installer */}
            <Grid item md={6} sm={9} xs={18} mb={3}>
              <TextField
                fullWidth
                id="outlined-basic"
                variant="outlined"
                label="Name"
                type="text"
                // step="any"
                // disabled
                required
                value={data.name}
                onChange={(e) => setData({ ...data, name: e.target.value })}
              />
            </Grid>
            {/* email of installer */}
            <Grid item md={6} sm={9} xs={18} mb={3}>
              <TextField
                fullWidth
                id="outlined-basic"
                variant="outlined"
                label="Email"
                type="email"
                // step="any"
                // disabled
                required
                value={data.email}
                onChange={(e) => setData({ ...data, email: e.target.value })}
              />
            </Grid>
            {/* Password of installer */}
            <Grid item md={6} sm={9} xs={18} mb={3}>
              <TextField
                fullWidth
                id="outlined-basic"
                variant="outlined"
                label="password"
                type="password"
                // step="any"
                // disabled
                required
                value={data.password}
                onChange={(e) => setData({ ...data, password: e.target.value })}
              />
            </Grid>
            {/* Confirm Password of installer */}
            <Grid item md={6} sm={9} xs={18} mb={3}>
              <TextField
                fullWidth
                id="outlined-basic"
                variant="outlined"
                label="Confirm Password"
                type="password"
                // step="any"
                // disabled
                required
                value={data.password_confirmation}
                onChange={(e) =>
                  setData({ ...data, password_confirmation: e.target.value })
                }
              />
            </Grid>
            {/*  Business  Info  */}
            <Grid item xs={18} mb={3}>
              <h3> Business Info </h3>
              <br></br>
              <hr />
            </Grid>
            {/* Company Name */}
            <Grid item md={6} sm={9} xs={18} mb={3}>
              <TextField
                fullWidth
                id="outlined-basic"
                variant="outlined"
                label="Company Name"
                type="text"
                // step="any"
                // disabled
                required
                value={data.company_name}
                onChange={(e) =>
                  setData({ ...data, company_name: e.target.value })
                }
              />
            </Grid>
            {/* business Phone */}
            <Grid item md={6} sm={9} xs={18} mb={3}>
              {/* <TextField
                fullWidth
                id="outlined-basic"
                variant="outlined"
                label="business Phone"
                type="tel"
                // step="any"
                // disabled
                value={data.business_phone}
                onChange={(e) =>
                  setData({ ...data, business_phone: e.target.value })
                }
              /> */}
              <NumberFormat
                format="+1 (###) ###-####"
                fullWidth
                allowEmptyFormatting
                customInput={TextField}
                mask="_"
                label="Phone"
                required
                value={data.business_phone}
                // onChange={(e) => setData({ ...data, phone: e.target.value })}
                onValueChange={(values) => {
                  setData({ ...data, business_phone: values.value });
                }}
              />
            </Grid>
            {/* Rate*/}
            <Grid item md={6} sm={9} xs={18} mb={3}>
              <TextField
                fullWidth
                id="outlined-basic"
                variant="outlined"
                label="Rate"
                type="text"
                // step="any"
                // disabled
                required
                value={data.rate}
                onChange={(e) => setData({ ...data, rate: e.target.value })}
              />
            </Grid>
            {/* Business Fax */}
            <Grid item md={6} sm={9} xs={18} mb={3}>
              <TextField
                fullWidth
                id="outlined-basic"
                variant="outlined"
                label="Business Fax"
                type="text"
                // step="any"
                // disabled
                required
                value={data.business_fax}
                onChange={(e) =>
                  setData({ ...data, business_fax: e.target.value })
                }
              />
            </Grid>
            {/* Logo */}
            <Grid item md={6} sm={9} xs={18} mb={3}>
              {/* <FormControl fullWidth>
                <TextField
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  variant="outlined"
                  // label="Password Confirmation"
                  type="file"
                  // step="any"
                  // disabled
                  multiple
                  value={data.license_image}
                  // onChange={(e) => onChangeForLogo(e)}
                  onChange={(e) => onChange(e)}
                />
              </FormControl> */}
              <MyButton variant="contained" component="label">
                Upload File for company logo
                {/* <input
                  type="file"
                  hidden
                  onChange={(e) => onChangeForLogo(e)}
                /> */}
                <input
                  type="file"
                  hidden
                  required-
                  onChange={(e) =>
                    setData({ ...data, logo: e.target.files[0] })
                  }
                />
              </MyButton>
            </Grid>
            {/* Business Address */}
            <Grid item md={6} sm={9} xs={18} mb={3}>
              <TextField
                fullWidth
                id="outlined-basic"
                variant="outlined"
                label="Business Address"
                // step="any"
                // disabled
                required
                value={data.business_address}
                onChange={(e) =>
                  setData({
                    ...data,
                    business_address: e.target.value,
                  })
                }
              />
            </Grid>
            {/* Start Of Business Reachable Hours */}
            {/* <Grid item md={6} sm={9} xs={18} mb={3}>
              <TextField
                fullWidth
                id="outlined-basic"
                variant="outlined"
                label="Start Of Business Reachable Hours"
                type="time"
                // step="any"
                // disabled
                value={time.start_business_reachable_hours}
                onChange={(e) =>
                  setTime({
                    ...time,
                    start_business_reachable_hours: e.target.value,
                  })
                }
              />
            </Grid> */}
            {/* End Of Business Reachable Hours */}
            {/* <Grid item md={6} sm={9} xs={18} mb={3}>
              <TextField
                fullWidth
                id="outlined-basic"
                variant="outlined"
                label="End Of Business Reachable Hours"
                type="time"
                // step="any"
                // disabled
                value={time.end_business_reachable_hours}
                onChange={(e) =>
                  setTime({
                    ...time,
                    end_business_reachable_hours: e.target.value,
                  })
                }
              />
            </Grid> */}
            {/* Working Days */}
            <Grid item md={6} sm={9} xs={18} mb={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-multiple-name-label">
                  Working Days
                </InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  value={workingDays}
                  onChange={handleChange}
                  input={<OutlinedInput label="Working Days" />}
                  MenuProps={MenuProps}
                  required
                >
                  {daysInWeek.map((data) => (
                    <MenuItem
                      key={data}
                      value={data}
                      // style={getStyles(name, personName, theme)}
                    >
                      {data}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* Date Of Business Origination */}
            <Grid item md={6} sm={9} xs={18} mb={3}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    fullWidth
                    required
                    label="Date Of Business Origination"
                    value={data.date_of_business_origination}
                    onChange={(e) =>
                      // setData({ ...data, date_of_business_origination:new Date(e).toLocaleDateString("en-US",{ day: "2-digit", month: "2-digit", year: "numeric" })})
                      setData({
                        ...data,
                        date_of_business_origination: new Date(e)
                          .toISOString()
                          .split("T")[0],
                      })
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            {/*Leicense  Info  */}
            <Grid item xs={18} mb={3}>
              <h3> License Info</h3>
              <br></br>
              <hr />
            </Grid>
            {/* Leicense */}
            <Grid item md={6} sm={9} xs={18} mb={3}>
              <TextField
                fullWidth
                id="outlined-basic"
                variant="outlined"
                label="License Number"
                type="text"
                // step="any"
                // disabled
                required
                value={licenseInfo.license}
                onChange={(e) =>
                  setLicenseInfo({ ...licenseInfo, license: e.target.value })
                }
                // onChange={(e) => {
                //   setLicense((preResult) => [
                //     ...preResult,
                //     { leicense:e.target.value},
                //   ]);
                // }}
              />
            </Grid>
            {/* License Image */}
            <Grid item md={6} sm={9} xs={18} mb={3}>
              {/* <FormControl fullWidth>
                <TextField
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  variant="outlined"
                  // label="Password Confirmation"
                  type="file"
                  // step="any"
                  // disabled
                  multiple
                  value={data.license_image}
                  //={(e) => onChange(e)}
                  // onChange={(e) => {
                  //   setLicense((preResult) => [
                  //     ...preResult,
                  //     { license_image:e.target.value},
                  //   ]);
                  // }}
                  // onChange={(e)=>setData({...data,})}
                />
              </FormControl> */}
              <MyButton variant="contained" component="label">
                Upload License Image
                <input
                  type="file"
                  hidden
                  required
                  // onChange={(e) => onChange(e)}
                  onChange={(e) => {
                    setLicenseInfo({
                      ...licenseInfo,
                      image: e.target.files[0],
                    });
                    onChange(e);
                  }}
                />
              </MyButton>
            </Grid>
            {/* Leicense type id */}
            <Grid item md={6} sm={9} xs={18} mb={3}>
              {/* <TextField
                fullWidth
                id="outlined-basic"
                variant="outlined"
                label="License Type Id"
                type="text"
                // step="any"
                // disabled
                value={licenseInfo.types}
                onChange={(e) =>
                  setLicenseInfo({
                    ...licenseInfo,
                    license_type_id: e.target.value,
                  })
                }
                // onChange={(e) => {
                //   setLicense((preResult) => [
                //     ...preResult,
                //     { license_types:e.target.value},
                //   ]);
                // }}
              /> */}
              <FormControl fullWidth required={true}>
                <InputLabel id="demo-simple-select-label">
                  License Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={licenseInfo.license_type_id}
                  label="License Type"
                  required={true}
                  onChange={(e) =>
                    setLicenseInfo({
                      ...licenseInfo,
                      license_type_id: e.target.value,
                    })
                  }
                >
                  {installerLicenseTypes?.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* State Id */}
            <Grid item md={6} sm={9} xs={18} mb={3}>
              {/* <TextField
                fullWidth
                id="outlined-basic"
                variant="outlined"
                label="State Id"
                type="text"
                // step="any"
                // disabled
                value={licenseInfo.state_id}
                onChange={(e) =>
                  setLicenseInfo({
                    ...licenseInfo,
                    state_id: e.target.value,
                  })
                }
                // onChange={(e) => {
                //   setLicense((preResult) => [
                //     ...preResult,
                //     { license_types:e.target.value},
                //   ]);
                // }}
              /> */}
              <FormControl fullWidth required={true}>
                <InputLabel id="demo-simple-select-label">State</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={licenseInfo.state_id}
                  label="State"
                  required={true}
                  onChange={(e) =>
                    setLicenseInfo({
                      ...licenseInfo,
                      state_id: e.target.value,
                    })
                  }
                >
                  {installerStates?.map((state) => (
                    <MenuItem key={state.id} value={state.id}>
                      {state.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* license  expiration data */}
            <Grid item md={6} sm={9} xs={18} mb={3}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    fullWidth
                    label="License Expiration Date"
                    value={licenseInfo.license_expiration_date}
                    required
                    onChange={(e) => {
                      // setLicenseInfo({
                      //   ...licenseInfo,
                      //   license_expiration_date: e,
                      // });
                      setLicenseInfo({
                        ...licenseInfo,
                        license_expiration_date: new Date(e)
                          .toISOString()
                          .split("T")[0],
                      });
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>

            {/* Add to array */}
            <Grid item md={6} sm={9} xs={18} mb={3}>
              <AddLicense
                type="button"
                disabled={disabled}
                onClick={(e) => {
                  setLicense((preResult) => [
                    ...preResult,
                    {
                      license: licenseInfo.license,
                      image: licenseInfo.image,
                      license_type_id: licenseInfo.license_type_id,
                      license_expiration_date:
                        licenseInfo.license_expiration_date,
                      state_id: licenseInfo.state_id,
                      image_64: license_image,
                      // image: license_image,
                      id: uuidv4(),
                    },
                  ]);
                  // alert("clicked");
                  setDisabled(true);
                  setData({ ...data, another_license: false });
                  // setLicenseInfo({ ...initialState });
                  setLicense_image({ ...initialState });
                  // setLicenseInfo({...licenseInfo,state_id:""})
                  // setLicenseInfo({...licenseInfo,license_expiration_date:""})
                }}
              >
                Add License Fields
              </AddLicense>
            </Grid>
            {/* Search Input */}
            <Grid item xs={18} sm={18} sx={{ mb: 2 }}>
              <PlacesAutocomplete
                value={address}
                onChange={setAddress}
                onSelect={handleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <TextField
                      sx={{ mb: 3 }}
                      fullWidth
                      {...getInputProps({
                        placeholder: "Type Your address",
                      })}
                    ></TextField>

                    <Result>
                      {loading ? <div>...loading</div> : null}

                      {suggestions.map((suggestion) => {
                        const style = {
                          backgroundColor: suggestion.active
                            ? "#41b6e6"
                            : "#fff",
                          textAlign: "center",
                          fontSize: "13px",
                        };

                        return (
                          <MenuItem
                            style={{ border: "1px solid black" }}
                            {...getSuggestionItemProps(suggestion, {
                              style,
                            })}
                          >
                            {suggestion.description}
                          </MenuItem>
                        );
                      })}
                    </Result>
                  </div>
                )}
              </PlacesAutocomplete>
            </Grid>
            {/* Add Coordinates */}
            <Grid item lg={6} md={6} sm={9} xs={18}>
              <AddBtn
                type="button"
                onClick={(e) => {
                  setAllCoordinates((preResult) => [
                    ...preResult,
                    {
                      lat: coordinates.lat,
                      lng: coordinates.lng,
                      state: state,
                      city: city,
                      full_address: full_address,
                      id: uuidv4(),
                    },
                  ]);
                }}
              >
                Add This Working Area
              </AddBtn>
            </Grid>
            {/* Add new License */}
            <Grid item md={6} sm={9} xs={18} mb={3}>
              <InputLabel>Add more License</InputLabel>
              <Checkbox
                checked={data.another_license}
                onChange={(e) => {
                  setData({
                    ...data,
                    another_license: e.target.checked,
                  });
                  setDisabled(false);
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Grid>

            {/* All leicense */}
            {license.length > 0 && (
              <Grid item xs={18} mb={3}>
                <Table>
                  <thead>
                    <tr>
                      <th>Index</th>
                      <th>License</th>
                      <th>license_expiration_date</th>
                      <th>License image</th>
                      <th>License type id</th>
                      <th>State id</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {license.map((e, i) => {
                      return (
                        <tr key={i}>
                          <td>{i}</td>
                          <td>{e.license}</td>
                          <td>
                            {new Date(
                              e.license_expiration_date
                            ).toLocaleDateString("en-US", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            })}
                          </td>
                          <td>
                            <img height="50" width="150" src={e.image_64}></img>
                          </td>
                          <td>{GetTheType(e.license_type_id)}</td>
                          <td>{GetTheStateID(e.state_id)}</td>
                          <td>
                            {/* <button type="button">Edit</button> */}
                            <DeleteButton
                              type="button"
                              onClick={() => {
                                deleteFromLicenses(e.id);
                              }}
                            >
                              <DeleteIcon></DeleteIcon>
                            </DeleteButton>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Grid>
            )}
          </Grid>
          {/*Leicense  Info  */}
          <Grid item xs={18} mb={3}>
            <h3> Insurance Info</h3>
            <br></br>
            <hr />
          </Grid>
          <Grid item xs={18} mb={3}>
            <h3> Banking Info</h3>
            <br></br>
            <hr />
          </Grid>
          {/* Working Locataions */}
          {/* <Grid item xs={18} mb={3}>
            <h3> Working Areas</h3>
            <br></br>
            <hr />
          </Grid> */}
          {/* Google Maps */}
          {/* <Grid item xs={18} md={18} sm={18} lg={18}>
            <GoogleMap
              mapContainerStyle={mapStyles}
              zoom={18}
              center={coordinates}
              mapTypeId={"satellite"}
            >
              <Marker
                position={coordinates}
                draggable={true}
                onDragEnd={(e) =>
                  setCoordinates({
                    ...coordinates,
                    lat: e.latLng.lat(),
                    lng: e.latLng.lng(),
                  })
                }
              />
            </GoogleMap>
          </Grid> */}
          {/* Add Coordinates  */}
          {/* <Grid item md={6} sm={9} xs={18}>
            <AddBtn
              type="button"
              onClick={(e) => {
                setAllCoordinates((preResult) => [
                  ...preResult,
                  {
                    lat: coordinates.lat,
                    lng: coordinates.lng,
                    state: state,
                    city: city,
                    full_address: full_address,
                    id: uuidv4(),
                  },
                ]);
              }}
            >
              Add This Working Area
            </AddBtn>
          </Grid> */}
          {allCoordinates.length > 0 && (
            <Grid item xs={18} mb={3}>
              <Table>
                <thead>
                  <tr>
                    <th>N.o</th>
                    <th>State</th>
                    <th>City</th>
                    <th>Full Address</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allCoordinates.map((e, i) => {
                    return (
                      <tr key={i}>
                        <td>{i}</td>
                        <td>{e.state}</td>
                        <td>{e.city}</td>

                        <td>{e.full_address}</td>
                        <td>
                          {/* <button type="button">Edit</button> */}
                          <DeleteButton
                            type="button"
                            onClick={() => {
                              deleteFromLocations(e.id);
                            }}
                          >
                            <DeleteIcon></DeleteIcon>
                          </DeleteButton>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Grid>
          )}

          <Grid item xs={18} mb={3}>
            <ReCAPTCHA
              ref={recaptchaRef}
              // size="invisible"
              sitekey="6LdDRIcgAAAAAPlYCZb2TpxVrkbQtoeNLPrCLJzA"
              // onChange={onChangeCaptcha}
              onChange={async () => {
                await onVerify();
                await setDisable(false);
              }}
              style={{ padding: "18px 0" }}
              onExpired={() => {
                setDisable(true);
              }}
              onErrored={() => {
                setDisable(true);
              }}
            />
          </Grid>
          <Grid item xs={18} mb={3}>
            <SubmitButton
              data-sitekey="reCAPTCHA_site_key"
              data-callback="onSubmit"
              data-action="submit"
              disabled={disable}
            >
              Submit
            </SubmitButton>
          </Grid>
        </form>
        {loader && (
          <div
            style={{
              width: "100%",
              height: "100vh",
              backgroundColor: "#ffffffbd",
              position: "fixed",
              top: "0",
              left: "0",
            }}
          >
            <Loading>
              <Lottie options={defaultOptions} height={200} width={200} />
            </Loading>
          </div>
        )}
      </FormConatiner>
    </>
  );
};

export default InstallPartnerPage;
