import styled from "styled-components";
import React from "react";
import Lottie from 'react-lottie';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle, faPauseCircle } from "@fortawesome/free-solid-svg-icons";
import animationData from './music.json'

// style
const Bigsection = styled.div`
  position: fixed;
  bottom: 10px;
  right: 0;
  z-index: 2;
  text-align: center;
`
const CustomeDiv = styled.div`
`
const PlayButton = styled.button`
background-color:transparent;
border:none;
&:hover{
    cursor: pointer;
}
`

const TheMusic = () => {
  // lottile
  const [isStopped, setIsStopped] = React.useState(true)
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  // Audio
  const AudioLink="https://boxbyld.tech/song.mpeg"
  // const audioRef = React.useRef(new Audio(song));
  const audioRef = React.useRef(new Audio(AudioLink));
  const [isPlaying, setIsPlaying] = React.useState(false)

  const play = () => {
    setIsPlaying(true);
    audioRef.current.play();
    setIsStopped(false)
  };
  const pause = () => {
    setIsPlaying(false);
    audioRef.current.pause();
    setIsStopped(true)
  };

  return <Bigsection>
    <Lottie
      options={defaultOptions}
      height={100}
      width={100}
      isStopped={isStopped}

    />
    {!isPlaying ? <PlayButton onClick={play}>
      <FontAwesomeIcon color="blue" size="3x" icon={faPlayCircle}></FontAwesomeIcon>
    </PlayButton> : <PlayButton onClick={pause}>
      <FontAwesomeIcon color="red" size="3x" icon={faPauseCircle}></FontAwesomeIcon>
    </PlayButton>}
  </Bigsection>
}
export default TheMusic