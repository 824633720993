import styled from "styled-components";

export const Bigsection = styled.section`
  min-height: 60dvh;
  /* background: #f5f5f5; */
  padding: 2rem 1rem;
  border-radius: 1rem;
  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
    width: 100%;
    :is(th, td):first-child {
      text-align: left;
    }
    th,
    td {
      text-align: center;
      padding: 16px;
    }
    th {
      text-transform: capitalize;
    }

    tr:nth-child(even) {
      background-color: #f2f2f2;
    }
  }
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
    flex-direction: column;
    gap: 2rem;
    &:nth-child(1) {
      order: 2;
    }
  }

  .submit {
    &:hover {
      width: 160px;
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      cursor: pointer;
      margin: 20px;
      height: 55px;
      text-align: center;
      border: none;
      background-size: 300% 100%;
      border-radius: 50px;
      moz-transition: all 0.4s ease-in-out;
      -o-transition: all 0.4s ease-in-out;
      -webkit-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
    }

    &:hover {
      background-position: 100% 0;
      moz-transition: all 0.4s ease-in-out;
      -o-transition: all 0.4s ease-in-out;
      -webkit-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
    }

    &:focus {
      outline: none;
    }

    background-image: linear-gradient(
      to right,
      #0ba360,
      #3cba92,
      #30dd8a,
      #2bb673
    );
    box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75);
    width: 160px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin: 20px;
    height: 55px;
    text-align: center;
    border: none;
    border-radius: 50px;
  }
`;
