import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { GetInvoices } from "../../Redux/Actions/leadBanksAction";
import LoadingSoultion from "../loading/loadingSolution";

// Style
const Div = styled.div`
  height: 10rem;
  overflow-y: scroll;
  a {
    color: #4545ff;
  }
`;
const Cart = () => {
  const dispatch = useDispatch();
  const invoices =
    useSelector((state) => state?.LeadBankReducer?.all_invoices) || [];
  const { isLoading } = useSelector((state) => state.proposal);

  React.useEffect(() => {
    dispatch(GetInvoices());
  }, []);
  console.log("invoices", invoices.length);
  return (
    <>
      {isLoading && <LoadingSoultion />}

      <Div>
        <p>Shop Cart</p>
        {invoices.length > 0 ? (
          <>
            {" "}
            {invoices?.map((e) => {
              return (
                <>
                  <Link to={`/payment-leads/${e.id}`}>
                    <p>Total Price: {e.total_price}</p>
                  </Link>
                  <Link to={`/payment-leads/${e.id}`}>
                    {" "}
                    <p>status : {e.status}</p>
                  </Link>
                  <hr></hr>
                </>
              );
            })}
          </>
        ) : (
          <p>No Invoices</p>
        )}
      </Div>
    </>
  );
};

export default Cart;
