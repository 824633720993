import { AccountCircle, Article, UnpublishedSharp } from "@mui/icons-material";
import axios from "axios";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import React from "react";
import styled from "styled-components";
import Loading from "../../../../components/loading/Loading";
import {singleOpportunitiesAction} from "../../../../Redux/Actions/SingleOpportunitieAction";
import { Grid } from "@mui/material";
import { textAlign } from "@mui/system";

const SolutionDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(singleOpportunitiesAction(`${id}`));
  }, []);
  const { singleOpportunity, isSingleLeadLoading } = useSelector(
    (state) => state.leads
  );

  const NumberOfPanelsNeeded = Math.ceil(
    singleOpportunity.data?.annual_kwh_usage /
      365 /
      4.2 /
      (singleOpportunity.data?.panel_wattage / 1000)
  );

  const TotalPanle =
    NumberOfPanelsNeeded +
    Number(singleOpportunity.data?.number_of_solar_arrays);
  const SystemSize = TotalPanle * singleOpportunity.data?.panel_wattage;
  const AnnualPrduction = (SystemSize / 1000) * 365 * 4.2;
  const OffsetPercentage = Math.ceil(
    (AnnualPrduction / singleOpportunity.data?.annual_kwh_usage) * 100
  );
  // opprtunity
  const singData = singleOpportunity.data || "";
  // Utility provider
  const utilityProvider = singData.utility_provider || "";
  // User
  const user = singData.user || "";
  // contractor
  const contractor = singData.contractor || "";
  // lead
  const lead = singData.lead || "";
  /////////////////////////////
  // show Sunlight
  var financial;
  if (singData.is_finance === 1) {
    financial = "sunlight";
  }
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const [allTerms, setAllTerms] = React.useState([]);
  // console.log("AllTerms", allTerms);
  React.useState(() => {
    axios({
      method: "get",
      url: "https://crm.boxbyld.tech/api/v1/sunlight/rates",
      headers: headers,
    })
      .then((res) => {
        setAllTerms(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  var singleTerm = allTerms.find(
    (e) => e.apr === singData?.apr && e.term === singData?.loan_years
  );
  console.log("singleTerm", singleTerm);
  console.log("singData", singData);
  console.log("allTerms",allTerms)
  const imageOfDesing = singData.aerial_photo || "";
  const netContractAmount=singData?.estimated_cost- (singData?.estimated_cost*(singleTerm?.dealer_fee/100))


  console.log("netContractAmount",netContractAmount)
  return (
    <>
      <SolutionConatiner>
        <h2>Solution Details</h2>
        <div className="solution">
          <div className="card">
            <h3 className="header">
              <span className="icon">
                <AccountCircle />
              </span>
              <span>USER</span>
            </h3>
            <GridConatiner>
              <div className="grid_items">
                <div className="data">
                  <div className="key">Name</div>
                  <div className="value">{user.name}</div>
                </div>
                <div className="data">
                  <div className="key">Email</div>
                  <div className="value">{user.email}</div>
                </div>
                <div className="data">
                  <div className="key">Phone</div>
                  <div className="value">{user.phone}</div>
                </div>
                <div className="data">
                  <div className="key">Mantras</div>
                  <div className="value">{user.mantras}</div>
                </div>
                <div className="data">
                  <div className="key">Adress</div>
                  <div className="value">{user.address}</div>
                </div>
                <div className="data">
                  <div className="key">Business</div>
                  <div className="value">{user.business}</div>
                </div>
                <div className="data">
                  <div className="key">Lead Count</div>
                  <div className="value">{user.leads_count}</div>
                </div>
                <div className="data">
                  <div className="key">Opportunities count</div>
                  <div className="value">{user.opportunities_count} </div>
                </div>
                <div className="data">
                  <div className="key">Solar contract count</div>
                  <div className="value">{user.solar_contracts_count}</div>
                </div>
                <div className="data">
                  <div className="key">Job title</div>
                  <div className="value">{user.Job_title}</div>
                </div>
              </div>
            </GridConatiner>
          </div>

          <div className="card">
            <h3 className="header">
              <span className="icon">
                <Article />
              </span>
              <span>Contractor</span>
            </h3>
            <GridConatiner>
              <div className="grid_items">
                <div className="data">
                  <div className="key">Name </div>
                  <div className="value">{contractor.name}</div>
                </div>
                <div className="data">
                  <div className="key">Address</div>
                  <div className="value">{contractor.address}</div>
                </div>
                <div className="data">
                  <div className="key">Status</div>
                  <div className="value">{contractor.status}</div>
                </div>
                <div className="data">
                  <div className="key">Expiry Date</div>
                  <div className="value">
                    {new Date(contractor.expiry_date).toLocaleDateString(
                      "en-US",
                      { day: "2-digit", month: "2-digit", year: "numeric" }
                    )}
                  </div>
                </div>
                <div className="data">
                  <div className="key">Entity</div>
                  <div className="value">{contractor.entity}</div>
                </div>
                <div className="data">
                  <div className="key">License</div>
                  <div className="value">{contractor.license}</div>
                </div>
                <div className="data">
                  <div className="key">Contract amount</div>
                  <div className="value">
                    $ {(singData.estimated_cost || 0).toFixed(2)}
                  </div>
                </div>
                <div className="data">
                  <div className="key">Royalties</div>
                  <div className="value">$ {singData.royalties}</div>
                </div>
              </div>
            </GridConatiner>
          </div>

          <div className="card">
            <h3 className="header">
              <span className="icon">
                <Article />
              </span>
              <span>Lead</span>
            </h3>
            <GridConatiner>
              <div className="grid_items">
                <div className="data">
                  <div className="key">Cutomer Type </div>
                  <div className="value">{lead.customer_type}</div>
                </div>
                <div className="data">
                  <div className="key">First name</div>
                  <div className="value">{lead.first_name}</div>
                </div>
                <div className="data">
                  <div className="key">Last Name</div>
                  <div className="value">{lead.last_name}</div>
                </div>
                <div className="data">
                  <div className="key">Phone</div>
                  <div className="value">{lead.phone}</div>
                </div>
                <div className="data">
                  <div className="key">Mobile</div>
                  <div className="value">{lead.mobile}</div>
                </div>
                <div className="data">
                  <div className="key">Email</div>
                  <div className="value">{lead.email}</div>
                </div>
                <div className="data">
                  <div className="key">Perferred language</div>
                  <div className="value">{lead.preferred_language}</div>
                </div>
                {lead.is_hoa === 1 && (
                  <div className="data">
                    <div className="key">Is hoa</div>
                    <div className="value">
                      {Boolean(lead.is_hoa).toString()}
                    </div>
                  </div>
                )}

                <div className="data">
                  <div className="key">Street</div>
                  <div className="value">{lead.street}</div>
                </div>
                <div className="data">
                  <div className="key">Zip Code</div>
                  <div className="value">{lead.zip_code}</div>
                </div>
                <div className="data">
                  <div className="key">Created</div>
                  <div className="value">{lead.days} Days ago</div>
                </div>
                <div className="data">
                  <div className="key">Status</div>
                  <div className="value">{lead.status}</div>
                </div>
                <div className="data">
                  <div className="key">Building type</div>
                  <div className="value">{lead.building_type}</div>
                </div>
                <div className="data">
                  <div className="key">Date</div>
                  <div className="value">
                    {new Date(lead.time_to_contact).toLocaleDateString(
                      "en-US",
                      { day: "2-digit", month: "2-digit", year: "numeric" }
                    )}
                  </div>
                </div>
                <div className="data">
                  <div className="key">House ownership</div>
                  <div className="value">{lead.house_ownership}</div>
                </div>
              </div>
            </GridConatiner>
          </div>

          <div className="card">
            <h3 className="header">
              <span className="icon">
                <Article />
              </span>
              <span>Opportunity</span>
            </h3>
            <GridConatiner>
              <div className="grid_items">
                <div className="data">
                  <div className="key">Opportunity date time </div>
                  <div className="value">
                    {new Date(
                      singData.opportunity_date_time
                    ).toLocaleDateString("en-US", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </div>
                </div>
                {singData.is_any_non_permitted_structures === 1 && (
                  <div className="data">
                    <div className="key">Is any non permitted structures</div>
                    <div className="value">
                      {Boolean(
                        singData.is_any_non_permitted_structures
                      ).toString()}
                    </div>
                  </div>
                )}

                <div className="data">
                  <div className="key">Installation type</div>
                  <div className="value">{singData.installation_type}</div>
                </div>
                <div className="data">
                  <div className="key">Rate</div>
                  <div className="value">{singData.rate}</div>
                </div>
                <div className="data">
                  <div className="key">Avg monthly bill</div>
                  <div className="value">{singData.avg_monthly_bill}</div>
                </div>
                <div className="data">
                  <div className="key">avg annual bill</div>
                  <div className="value">{singData.avg_annual_bill}</div>
                </div>
                <div className="data">
                  <div className="key">Monthly cost</div>
                  <div className="value">
                    {(singData.monthly_cost || 0.0).toFixed(2)}
                  </div>
                </div>
                <div className="data">
                  <div className="key">Aannual increases Percentage</div>
                  <div className="value">
                    {singData.annual_increases_percentage}%
                  </div>
                </div>
                <div className="data">
                  <div className="key">Roof Materials</div>
                  <div className="value">{singData.roof_materials}</div>
                </div>
                <div className="data">
                  <div className="key">Shading</div>
                  <div className="value">{singData.shading}</div>
                </div>
                <div className="data">
                  <div className="key">Panels</div>
                  <div className="value">{singData.panels}</div>
                </div>
                <div className="data">
                  <div className="key">Number of panels</div>
                  <div className="value">{singData.number_of_panels}</div>
                </div>
                <div className="data">
                  <div className="key">Panle price</div>
                  <div className="value">{singData.panel_price}</div>
                </div>
                <div className="data">
                  <div className="key">Panel wattage</div>
                  <div className="value">{singData.panel_wattage}</div>
                </div>
                <div className="data">
                  <div className="key">PDF path</div>
                  <div className="value">value</div>
                </div>
                <div className="data">
                  <div className="key">Status</div>
                  <div className="value">{singData.status}</div>
                </div>
                <div className="data">
                  <div className="key">Job type</div>
                  <div className="value">{singData.job_type}</div>
                </div>
                {singData.number_of_solar_arrays === 0 ? (
                  <span style={{ display: "none" }}></span>
                ) : (
                  <div className="data">
                    <div className="key">Added/(Subtracted Panels)</div>
                    <div className="value">
                      {singData.number_of_solar_arrays}
                    </div>
                  </div>
                )}

                <div className="data">
                  <div className="key">Offset percentage</div>
                  <div className="value">{OffsetPercentage}%</div>
                </div>
                {singData.is_attic === 1 && (
                  <div className="data">
                    <div className="key">Is attic</div>
                    <div className="value">
                      {Boolean(singData.is_attic).toString()}
                    </div>
                  </div>
                )}
                {singData.number_of_conduit_arrays === 0 ? (
                  <span style={{ display: "none" }}></span>
                ) : (
                  <div className="data">
                    <div className="key">Number of attic arrays</div>
                    <div className="value">
                      {singData.number_of_conduit_arrays}
                    </div>
                  </div>
                )}
                {singData.is_reverse_tilt === 1 && (
                  <div className="data">
                    <div className="key">Is reverse tilt</div>
                    <div className="value">
                      {Boolean(singData.is_reverse_tilt).toString()}
                    </div>
                  </div>
                )}
                {singData.is_north_faced === 1 && (
                  <div className="data">
                    <div className="key">Is north faced</div>
                    <div className="value">
                      {Boolean(singData.is_north_faced).toString()}
                    </div>
                  </div>
                )}
                {singData.two_story_home === 1 && (
                  <div className="data">
                    <div className="key">Two story home</div>
                    <div className="value">
                      {Boolean(singData.two_story_home).toString()}
                    </div>
                  </div>
                )}
                {singData.is_online_monitoring === 1 && (
                  <div className="data">
                    <div className="key">Is online monitoring</div>
                    <div className="value">
                      {Boolean(singData.is_online_monitoring).toString()}
                    </div>
                  </div>
                )}

                <div className="data">
                  <div className="key">Inverter type</div>
                  <div className="value">{singData.inverter_type}</div>
                </div>
                {singData.no_of_ft_from_main_service === 0 ? (
                  <span style={{ display: "none" }}></span>
                ) : (
                  <div className="data">
                    <div className="key">Number of ft from main service</div>
                    <div className="value">
                      {singData.no_of_ft_from_main_service}
                    </div>
                  </div>
                )}
                {singData.how_many_ft_trench_dirt === 0 ? (
                  <span style={{ display: "none" }}></span>
                ) : (
                  <div className="data">
                    <div className="key">How many ft trench dirt</div>
                    <div className="value">
                      {singData.how_many_ft_trench_dirt}
                    </div>
                  </div>
                )}
                {singData.is_trenching_dirt === 1 && (
                  <div className="data">
                    <div className="key">Is trenching dirt</div>
                    <div className="value">
                      {Boolean(singData.is_trenching_dirt).toString()}
                    </div>
                  </div>
                )}
                {singData.how_many_ft_trench_concrete === 0 ? (
                  <span style={{ display: "none" }}></span>
                ) : (
                  <div className="data">
                    <div className="key">How many ft trench concrete</div>
                    <div className="value">
                      {singData.how_many_ft_trench_concrete}
                    </div>
                  </div>
                )}
                {singData.is_trenching_concrete === 1 && (
                  <div className="data">
                    <div className="key">Is trenching concrete</div>
                    <div className="value">
                      {Boolean(singData.is_trenching_concrete).toString()}
                    </div>
                  </div>
                )}
                {singData.digging_slope === 1 && (
                  <div className="data">
                    <div className="key">Digging slope</div>
                    <div className="value">
                      {Boolean(singData.digging_slope).toString()}
                    </div>
                  </div>
                )}
                {singData.slope_degree === 0 ? (
                  <span style={{ display: "none" }}></span>
                ) : (
                  <div className="data">
                    <div className="key">Slope degree</div>
                    <div className="value">{singData.slope_degree}</div>
                  </div>
                )}
                {singData.how_many_digging_ft === 0 ? (
                  <span style={{ display: "none" }}></span>
                ) : (
                  <div className="data">
                    <div className="key">How many digging ft</div>
                    <div className="value">{singData.how_many_digging_ft}</div>
                  </div>
                )}

                {singData.is_separate_electrical_meter === 1 && (
                  <div className="data">
                    <div className="key">Is separate electrical meter</div>
                    <div className="value">
                      {Boolean(
                        singData.is_separate_electrical_meter
                      ).toString()}
                    </div>
                  </div>
                )}
                {singData.is_electric_work_currently_on_garage === 1 && (
                  <div className="data">
                    <div className="key">
                      Is electric work currently on garage
                    </div>
                    <div className="value">
                      {Boolean(
                        singData.is_electric_work_currently_on_garage
                      ).toString()}
                    </div>
                  </div>
                )}
                {singData.is_sub_panel_on_detached_garage === 1 && (
                  <div className="data">
                    <div className="key">Is sub panel on detached garage</div>
                    <div className="value">
                      {Boolean(
                        singData.is_sub_panel_on_detached_garage
                      ).toString()}
                    </div>
                  </div>
                )}
                {singData.finalMonthlyPayment === null ? (
                  <span style={{ display: "none" }}></span>
                ) : (
                  <div className="data">
                    <div className="key">Final monthly payment</div>
                    <div className="value">{singData.finalMonthlyPayment}</div>
                  </div>
                )}
                {singData.finalEscalatedMonthlyPayment === null ? (
                  <span style={{ display: "none" }}></span>
                ) : (
                  <div className="data">
                    <div className="key">Final Escalated Monthly Payment</div>
                    <div className="value">
                      {singData.finalEscalatedMonthlyPayment}
                    </div>
                  </div>
                )}
                {singData.monthlyPayment === null ? (
                  <span style={{ display: "none" }}></span>
                ) : (
                  <div className="data">
                    <div className="key">Monthly payment</div>
                    <div className="value">{singData.monthlyPayment}</div>
                  </div>
                )}
                {singData.escalatedMonthlyPayment === null ? (
                  <span style={{ display: "none" }}></span>
                ) : (
                  <div className="data">
                    <div className="key">Escalated monthly payment</div>
                    <div className="value">
                      {singData.escalatedMonthlyPayment}
                    </div>
                  </div>
                )}

                {singData.paydownPercentage === 0 ? (
                  <span style={{ display: "none" }}></span>
                ) : (
                  <div className="data">
                    <div className="key">Escalated monthly payment</div>
                    <div className="value">
                      {singData.escalatedMonthlyPayment}
                    </div>
                  </div>
                )}
                {singData.alternateMonthlyPayment === null ? (
                  <span style={{ display: "none" }}></span>
                ) : (
                  <div className="data">
                    <div className="key">Alternate monthly payment</div>
                    <div className="value">
                      {singData.alternateMonthlyPayment}
                    </div>
                  </div>
                )}

                {singData.is_add_on === 1 && (
                  <div className="data">
                    <div className="key">Is add on</div>
                    <div className="value">
                      {Boolean(singData.is_add_on).toString()}
                    </div>
                  </div>
                )}

                <div className="data">
                  <div className="key">System size ac</div>
                  <div className="value">{singData.system_size_ac}</div>
                </div>
                <div className="data">
                  <div className="key">Solar years plan</div>
                  <div className="value">{singData.solar_years_plan}</div>
                </div>
                {singData.is_finance === 1 && (
                  <div className="data">
                    <div className="key">Is finance</div>
                    <div className="value">
                      {Boolean(singData.is_finance).toString()}
                    </div>
                  </div>
                )}
                {singData.is_sandbox === 1 && (
                  <div className="data">
                    <div className="key">Is sandbox</div>
                    <div className="value">
                      {Boolean(singData.is_sandbox).toString()}
                    </div>
                  </div>
                )}

                <div className="data">
                  <div className="key">Loan Month</div>
                  <div className="value">{singData.loan_years}</div>
                </div>
                <div className="data">
                  <div className="key">Productivity offset</div>
                  <div className="value">{singData.productivity_offset}</div>
                </div>
                <div className="data">
                  <div className="key">Federal tax</div>
                  <div className="value">{singData.federal_tax}</div>
                </div>
                {singData.apr === null ? (
                  <span style={{ display: "none" }}></span>
                ) : (
                  <div className="data">
                    <div className="key">Apr</div>
                    <div className="value">{singData.apr}</div>
                  </div>
                )}
                {singData.is_re_roofing === 1 && (
                  <div className="data">
                    <div className="key">Is re roofing</div>
                    <div className="value">
                      {Boolean(singData.is_re_roofing).toString()}
                    </div>
                  </div>
                )}
                {singData.is_roof_overlay === 1 && (
                  <div className="data">
                    <div className="key">Is roof overlay</div>
                    <div className="value">
                      {Boolean(singData.is_roof_overlay).toString()}
                    </div>
                  </div>
                )}
                {singData.is_roof_relay === 1 && (
                  <div className="data">
                    <div className="key">Is roof relay</div>
                    <div className="value">
                      {Boolean(singData.is_roof_relay).toString()}{" "}
                    </div>
                  </div>
                )}
                {singData.is_re_roof_shingle === 1 && (
                  <div className="data">
                    <div className="key">Is re roof shingle</div>
                    <div className="value">
                      {" "}
                      {Boolean(singData.is_re_roof_shingle).toString()}
                    </div>
                  </div>
                )}
                {singData.is_re_roof_tear_off === 1 && (
                  <div className="data">
                    <div className="key">Is re roof tear off</div>
                    <div className="value">
                      {Boolean(singData.is_re_roof_tear_off).toString()}
                    </div>
                  </div>
                )}
                {singData.is_re_roofing === 1 && (
                  <div className="data">
                    <div className="key">Is re roofing</div>
                    <div className="value">
                      {Boolean(singData.is_re_roofing).toString()}
                    </div>
                  </div>
                )}
                {singData.number_of_layers === 0 ? (
                  <span style={{ display: "none" }}></span>
                ) : (
                  <div className="data">
                    <div className="key">Number of layers</div>
                    <div className="value">{singData.number_of_layers}</div>
                  </div>
                )}
                {singData.is_re_roof_presidential === 1 && (
                  <div className="data">
                    <div className="key">is re roof presidential</div>
                    <div className="value">
                      {Boolean(singData.is_re_roof_presidential).toString()}
                    </div>
                  </div>
                )}
                {singData.is_re_roof_spanish_tiles === 1 && (
                  <div className="data">
                    <div className="key">Is re roof spanish tiles</div>
                    <div className="value">
                      {" "}
                      {Boolean(singData.is_re_roof_spanish_tiles).toString()}
                    </div>
                  </div>
                )}
                {singData.is_re_roof_flat_tiles === 1 && (
                  <div className="data">
                    <div className="key">Is re roof flat tiles</div>
                    <div className="value">
                      {Boolean(singData.is_re_roof_flat_tiles).toString()}
                    </div>
                  </div>
                )}
                {singData.is_re_roof_re_decking === 1 && (
                  <div className="data">
                    <div className="key">Is re roof re decking</div>
                    <div className="value">
                      {" "}
                      {Boolean(singData.is_re_roof_re_decking).toString()}
                    </div>
                  </div>
                )}
                {singData.is_re_roof_fascia_board === 1 && (
                  <div className="data">
                    <div className="key">Is re roof fascia board</div>
                    <div className="value">
                      {" "}
                      {Boolean(singData.is_re_roof_fascia_board).toString()}
                    </div>
                  </div>
                )}

                {singData.number_of_feet === 0 ? (
                  <span style={{ display: "none" }}></span>
                ) : (
                  <div className="data">
                    <div className="key">Number of feet</div>
                    <div className="value">{singData.number_of_feet}</div>
                  </div>
                )}
                {singData.is_warranty_extend === 1 && (
                  <div className="data">
                    <div className="key">Is warranty extend</div>
                    <div className="value">
                      {Boolean(singData.is_warranty_extend).toString()}
                    </div>
                  </div>
                )}
                {singData.is_air_vent === 1 && (
                  <div className="data">
                    <div className="key">Is air vent</div>
                    <div className="value">
                      {Boolean(singData.is_air_vent).toString()}
                    </div>
                  </div>
                )}
                {singData.air_vent === "" ? (
                  <span style={{ display: "none" }}></span>
                ) : (
                  <div className="data">
                    <div className="key">Air vent</div>
                    <div className="value">{singData.air_vent}</div>
                  </div>
                )}
                {singData.number_of_vents === 0 ? (
                  <span style={{ display: "none" }}></span>
                ) : (
                  <div className="data">
                    <div className="key">Number of vents</div>
                    <div className="value">{singData.number_of_vents}</div>
                  </div>
                )}
                {singData.is_double_hand === 1 && (
                  <div className="data">
                    <div className="key">Is double hand</div>
                    <div className="value">
                      {Boolean(singData.is_double_hand).toString()}
                    </div>
                  </div>
                )}
                {singData.is_smoke_detectors === 1 && (
                  <div className="data">
                    <div className="key">Is smoke detectors</div>
                    <div className="value">
                      {Boolean(singData.is_smoke_detectors).toString()}
                    </div>
                  </div>
                )}
                {singData.number_of_smoke_detectors === 0 ? (
                  <span style={{ display: "none" }}></span>
                ) : (
                  <div className="data">
                    <div className="key">Number of smoke detectors</div>
                    <div className="value">
                      {singData.number_of_smoke_detectors}
                    </div>
                  </div>
                )}
                {singData.number_of_re_roof_squares === 0 ? (
                  <span style={{ display: "none" }}></span>
                ) : (
                  <div className="data">
                    <div className="key">Number of re roof squares</div>
                    <div className="value">
                      {singData.number_of_re_roof_squares}
                    </div>
                  </div>
                )}
                {singData.is_led_lighting === 1 && (
                  <div className="data">
                    <div className="key">Is led lightning</div>
                    <div className="value">
                      {Boolean(singData.is_led_lighting).toString()}
                    </div>
                  </div>
                )}
                {singData.number_of_led_lighting === 0 ? (
                  <span style={{ display: "none" }}></span>
                ) : (
                  <div className="data">
                    <div className="key">Number of led lighting</div>
                    <div className="value">
                      {singData.number_of_led_lighting}
                    </div>
                  </div>
                )}
                {singData.is_energy_efficient_plugs === 1 && (
                  <div className="data">
                    <div className="key">Is energy efficient plugs</div>
                    <div className="value">
                      {Boolean(singData.is_energy_efficient_plugs).toString()}
                    </div>
                  </div>
                )}
                {singData.is_solar_lip === 1 && (
                  <div className="data">
                    <div className="key">Is solar lip</div>
                    <div className="value">
                      {Boolean(singData.is_solar_lip).toString()}
                    </div>
                  </div>
                )}
                {singData.number_of_solar_lip === 0 ? (
                  <span style={{ display: "none" }}></span>
                ) : (
                  <div className="data">
                    <div className="key">Number of solar lip</div>
                    <div className="value">{singData.number_of_solar_lip}</div>
                  </div>
                )}
                {singData.is_bird_netting === 1 && (
                  <div className="data">
                    <div className="key">Is bird netting</div>
                    <div className="value">
                      {Boolean(singData.is_bird_netting).toString()}
                    </div>
                  </div>
                )}
                {singData.number_of_bird_netting === 0 ? (
                  <span style={{ display: "none" }}></span>
                ) : (
                  <div className="data">
                    <div className="key">Number of bird netting</div>
                    <div className="value">
                      {singData.number_of_bird_netting}
                    </div>
                  </div>
                )}
                {singData.is_battery === 1 && (
                  <div className="data">
                    <div className="key">Is battery</div>
                    <div className="value">
                      {Boolean(singData.is_battery).toString()}
                    </div>
                  </div>
                )}
                {singData.battery_power === "" ? (
                  <span style={{ display: "none" }}></span>
                ) : (
                  <div className="data">
                    <div className="key">Battery power</div>
                    <div className="value">{singData.battery_power}</div>
                  </div>
                )}
                {singData.is_warranty_package === 1 && (
                  <div className="data">
                    <div className="key">Is warranty package</div>
                    <div className="value">
                      {Boolean(singData.is_warranty_package).toString()}
                    </div>
                  </div>
                )}
                {singData.warranty === "" ? (
                  <span style={{ display: "none" }}></span>
                ) : (
                  <div className="data">
                    <div className="key">Warranty</div>
                    <div className="value">{singData.warranty}</div>
                  </div>
                )}
                {singData.is_hvac === 1 && (
                  <div className="data">
                    <div className="key">Is hvac</div>
                    <div className="value">
                      {Boolean(singData.is_hvac).toString()}
                    </div>
                  </div>
                )}
                {singData.hvac_location === "" ? (
                  <span style={{ display: "none" }}></span>
                ) : (
                  <div className="data">
                    <div className="key">Hvac location</div>
                    <div className="value">{singData.hvac_location}</div>
                  </div>
                )}
                {singData.hvac_horse_power === "" ? (
                  <span style={{ display: "none" }}></span>
                ) : (
                  <div className="data">
                    <div className="key">hvac horse power</div>
                    <div className="value">{singData.hvac_horse_power}</div>
                  </div>
                )}
                {singData.is_ducting === 1 && (
                  <div className="data">
                    <div className="key">Is ducting</div>
                    <div className="value">
                      {Boolean(singData.is_ducting).toString()}
                    </div>
                  </div>
                )}
                {singData.number_of_ducting_run === 0 ? (
                  <span style={{ display: "none" }}></span>
                ) : (
                  <div className="data">
                    <div className="key">Number of ducting run</div>
                    <div className="value">
                      {singData.number_of_ducting_run}
                    </div>
                  </div>
                )}
                {singData.is_standalone_roof_mount === 1 && (
                  <div className="data">
                    <div className="key">Is standalone roof mount </div>
                    <div className="value">
                      {Boolean(singData.is_standalone_roof_mount).toString()}
                    </div>
                  </div>
                )}

                <div className="data">
                  <div className="key">Existing electrical panel</div>
                  <div className="value">
                    {singData.existing_electrical_panel}
                  </div>
                </div>
                {Number(singData.upgrade_electrical_panel) === 0 ? (
                  <span style={{ display: "none" }}></span>
                ) : (
                  <div className="data">
                    <div className="key">Upgrade elctrical panel</div>
                    <div className="value">
                      {singData.upgrade_electrical_panel}
                    </div>
                  </div>
                )}
                {singData.is_ev_charger === 1 && (
                  <div className="data">
                    <div className="key">Is ev charger</div>
                    <div className="value">
                      {Boolean(singData.is_ev_charger).toString()}
                    </div>
                  </div>
                )}
                {singData.is_meter_socket === 1 && (
                  <div className="data">
                    <div className="key">Is meter socket</div>
                    <div className="value">
                      {Boolean(singData.is_meter_socket).toString()}
                    </div>
                  </div>
                )}
                {singData.is_ev_mlo === 1 && (
                  <div className="data">
                    <div className="key">Is ev mlo</div>
                    <div className="value">
                      {Boolean(singData.is_ev_mlo).toString()}
                    </div>
                  </div>
                )}
                {singData.is_sub_panel === 1 && (
                  <div className="data">
                    <div className="key">Is sub Panel</div>
                    <div className="value">
                      {Boolean(singData.is_sub_panel).toString()}
                    </div>
                  </div>
                )}
                {singData.is_mpu_relocation === 1 && (
                  <div className="data">
                    <div className="key">Is mpu relocation</div>
                    <div className="value">
                      {Boolean(singData.is_mpu_relocation).toString()}
                    </div>
                  </div>
                )}
                {singData.number_of_mpu_relocation_foot === 0 ? (
                  <span style={{ display: "none" }}></span>
                ) : (
                  <div className="data">
                    <div className="key">Number of mpu relocation foot</div>
                    <div className="value">
                      {singData.number_of_mpu_relocation_foot}
                    </div>
                  </div>
                )}
                {singData.is_de_rate === 1 && (
                  <div className="data">
                    <div className="key">Is de rate</div>
                    <div className="value">
                      {Boolean(singData.is_de_rate).toString()}
                    </div>
                  </div>
                )}
                {singData.is_stucco === 1 && (
                  <div className="data">
                    <div className="key">Is stucco</div>
                    <div className="value">
                      {Boolean(singData.is_stucco).toString()}
                    </div>
                  </div>
                )}
                {singData.is_rafter_exchange === 1 && (
                  <div className="data">
                    <div className="key">Is rafter exchange</div>
                    <div className="value">
                      {Boolean(singData.is_rafter_exchange).toString()}
                    </div>
                  </div>
                )}
                {singData.number_hvac_ft === 0 ? (
                  <span style={{ display: "none" }}></span>
                ) : (
                  <div className="data">
                    <div className="key">Number hvac ft</div>
                    <div className="value">{singData.number_hvac_ft}</div>
                  </div>
                )}

                {singData.number_of_battaries === null ? (
                  <span style={{ display: "none" }}></span>
                ) : (
                  <div className="data">
                    <div className="key">Number of batteries</div>
                    <div className="value">{singData.number_of_battaries}</div>
                  </div>
                )}
                {singData.is_steep === 1 && (
                  <div className="data">
                    <div className="key">Is steep</div>
                    <div className="value">
                      {Boolean(singData.is_steep).toString()}
                    </div>
                  </div>
                )}
                {singData.roof_number_of_sq === null ? (
                  <span style={{ display: "none" }}></span>
                ) : (
                  <div className="data">
                    <div className="key">Roof number of sq</div>
                    <div className="value">{singData.roof_number_of_sq}</div>
                  </div>
                )}
                {singData.has_contract === true ? (
                  <div className="data">
                    <div className="key">Has contract</div>
                    <div className="value">
                      {Boolean(singData.has_contract).toString()}
                    </div>
                  </div>
                ) : (
                  <span style={{ display: "hidden" }}></span>
                )}

                {/* {singData.has_contract === true && (
                  <div className="data">
                    <div className="key">Has contract</div>
                    <div className="value">
                      {Boolean(singData.has_contract).toString()}
                    </div>
                  </div>
                )} */}

                {/* {singData.roof_area === null ? (
                  <div></div>
                ) : (
                  <div className="data">
                    <div className="key">Roof Area</div>
                    <div className="value">
                      {Boolean(singData.roof_area).toString()}
                    </div>
                  </div>
                )} */}
     
              </div>
            </GridConatiner>

            {imageOfDesing === "" ? (
                  <div></div>
                ) : (
                  <Grid container columns={18}>
                    <Grid item lg={18} md={18} sm={18} xs={18} alignItems="center" justifyContent="center">
                      <img style={{ width: "60%"  , display:"block",margin:" 0.5rem auto", borderRadius:"1rem"}} src={imageOfDesing}></img>
                    </Grid>
                  </Grid>
                )}

          </div>


          {/* Financial */}
          {singData.is_finance === 1 && (
            <div className="card">
              <h3 className="header">
                <span className="icon">
                  <AccountCircle />
                </span>
                <span>Financial info.</span>
              </h3>
              <GridConatiner>
                <div className="grid_items">
                  <div className="data">
                    <div className="key">financial Institution</div>
                    <div
                      style={{ textTransform: "capitalize" }}
                      className="value"
                    >
                      {financial}
                    </div>
                  </div>
                  <div className="data">
                    <div className="key">Term</div>
                    <div className="value">{singleTerm?.term} m</div>
                  </div>
                  <div className="data">
                    <div className="key">Rate</div>
                    <div className="value">{singData?.apr}%</div>
                  </div>
                  <div className="data">
                    <div className="key">Delaer Fee</div>
                    <div className="value">{singleTerm?.dealer_fee}%</div>
                  </div>
                  <div className="data">
                    <div className="key">Contract amount</div>
                    <div className="value">
                      $ {(singData.estimated_cost || 0).toFixed(2)}
                    </div>
                  </div>
                  {/* <div className="data">
                              <div className="key">Adress</div>
                              <div className="value">{user.address}</div>
                            </div>
                            <div className="data">
                              <div className="key">Business</div>
                              <div className="value">{user.business}</div>
                            </div>
                            <div className="data">
                              <div className="key">Lead Count</div>
                              <div className="value">{user.leads_count}</div>
                            </div>
                            <div className="data">
                              <div className="key">Opportunities count</div>
                              <div className="value">{user.opportunities_count} </div>
                            </div>
                            <div className="data">
                              <div className="key">Solar contract count</div>
                              <div className="value">{user.solar_contracts_count}</div>
                            </div>
                            <div className="data">
                              <div className="key">Job title</div>
                              <div className="value">{user.Job_title}</div>
                            </div> */}
                </div>
              </GridConatiner>
            </div>
          )}

          <div className="card">
            <h3 className="header">
              <span className="icon">
                <Article />
              </span>
              <span>Utility Provider</span>
            </h3>
            <GridConatiner>
              <div className="grid_items">
                <div className="data">
                  <div className="key">Title</div>
                  <div className="value">{utilityProvider.title}</div>
                </div>
                <div className="data">
                  <div className="key">Rate</div>
                  <div className="value">{utilityProvider.rate}</div>
                </div>
                {utilityProvider.discounted_rate === 0 ? (
                  <span style={{ display: "hidden" }}></span>
                ) : (
                  <div className="data">
                    <div className="key">Discount rate</div>
                    <div className="value">
                      {utilityProvider.discounted_rate}
                    </div>
                  </div>
                )}

                <div className="data">
                  <div className="key">Annual Increase Percentage</div>
                  <div className="value">
                    {utilityProvider.annual_increase_percentage}%
                  </div>
                </div>
                {utilityProvider.meter_rate === null ? (
                  <span style={{ display: "hidden" }}></span>
                ) : (
                  <div className="data">
                    <div className="key">Meter rate</div>
                    <div className="value">{utilityProvider.meter_rate}</div>
                  </div>
                )}
                {utilityProvider.rebates === null ? (
                  <span style={{ display: "hidden" }}></span>
                ) : (
                  <div className="data">
                    <div className="key">Rebates</div>
                    <div className="value">{utilityProvider.rebates}</div>
                  </div>
                )}
              </div>
            </GridConatiner>
          </div>
        </div>
      </SolutionConatiner>
    </>
  );
};

const SolutionConatiner = styled.section`
  margin: 1rem 0;
  overflow: hidden;
  h2 {
    text-align: center;
    margin: 1rem 0;
    font-size: 2.3rem;
    color: #005c75;
  }
  .solution {
    .card {
      background: #fefefe;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.18);
      padding: 1rem;
      margin: 1.5rem 1rem;
      border-radius: 1rem;
      .header {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;
        font-size: 1.5rem;
        color: #003c4cd6;
        svg {
          margin-right: 0.5rem;
          font-size: 1.5rem;
        }
        &:after {
          content: "";
          position: absolute;
          top: 90%;
          left: 0;
          background: linear-gradient(135deg, #5efce8 10%, #736efe 100%);
          width: 200px;
          height: 10px;
          border-radius: 1rem;
        }
      }
    }
  }
`;

const GridConatiner = styled.div`
  margin: 0.5rem 0;

  .grid_items {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 10px;
    .data {
      display: flex;
      align-items: center;
      font-size: 0.9rem;
      .key {
        color: #4e4e4e;
        font-weight: bold;
        margin-right: 0.5rem;
        text-transform: capitalize;
      }
      .value {
        color: #999;
      }
    }
  }
`;
export default SolutionDetails;
