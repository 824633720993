import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import styled from "styled-components";
import backVideo from "../../../../assets/BackgroundVideo/background-video.mp4";
import logo from "../../../../assets/logo.png";
import Grid from "@mui/material/Grid";
import styles from "./NewHeader.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { HashLink as Links } from "react-router-hash-link";
import Slider from "react-slick";
import newLogo from "../../../../assets/newLogoBox.svg";

const pages = ["HOME", "WHY US", "PRICING", "FAQ", "CONTACT US"];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

var settingSlider = {
  dots: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  cssEase: "linear",
  // swipeToSlide: true,
  fade: true,
  autoplaySpeed: 5000,
};
const BigSection = styled.div`
  position: relative;
  overflow: hidden;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  transition: 1s all;
`;
const LoginButton = styled.button`
  padding: 0px 50px;
  border: 1px solid white;
  border-radius: 10px;
  background: transparent;
  color: white;
`;
const MyVideo = styled.video`
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: -1;
`;
const Logo = styled.img`
  width: 50%;
`;
const Content = styled.div`
  text-align: center;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const SliderDiv = styled.div`
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 50px;
  color: white;
`;
const SliderP = styled.p`
  color: white !important;
  width: 60%;
  margin: auto;
  font-size: 1.8rem;
`;
const ContentHeader = styled.p`
  font-size: 55px;
  font-weight: 700;
  @media (max-width: 1024px) {
    font-size: 40px;
  }
  @media (max-width: 768px) {
    font-size: 20px;
  } ;
`;
const ContentLorem = styled.p`
  font-size: 20px;
  @media (max-width: 768px) {
    font-size: 15px;
  } ;
`;

const DropdownButton = styled.button`
  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  /* padding: 14px 16px; */
  background-color: inherit;
  font-family: inherit;
  margin: 0;
  cursor: pointer;
`;
const DropDownContent = styled.div`
  display: none;
  position: absolute;
  min-width: 160px;
  z-index: 1;
  a {
    float: none;
    color: white;
    padding: 10px 0;
    text-decoration: none;
    display: block;
    text-align: left;
  }
`;
const DropDown = styled.div`
  float: left;
  overflow: hidden;
  &:hover ${DropDownContent} {
    display: block;
  }
`;

const NewHeader = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <BigSection>
      <Grid container>
        <Grid item lg={12}>
          <AppBar
            position="static"
            style={{ background: "transparent", boxShadow: "none" }}
          >
            <MyVideo id="background-video" autoPlay muted loop>
              <source src={backVideo} type="video/mp4" />
            </MyVideo>
            <Container maxWidth="xl">
              <Toolbar disableGutters>
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  sx={{ mr: 4, display: { xs: "none", md: "flex" } }}
                  className={styles.logo}
                >
                  <Logo src={newLogo}></Logo>
                </Typography>

                <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                  >
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                      display: { xs: "block", md: "none" },
                    }}
                  >
                    {/* {pages.map((page) => (
                      <MenuItem key={page} onClick={handleCloseNavMenu}>
                        <Typography textAlign="center">{page}</Typography>
                      </MenuItem>
                    ))} */}
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">HOME</Typography>
                    </MenuItem>
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">WHY US</Typography>
                    </MenuItem>
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">PRICING</Typography>
                    </MenuItem>
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">FAQ</Typography>
                    </MenuItem>
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Links to="#footer">
                        {" "}
                        <Typography textAlign="center">CONTACT US</Typography>
                      </Links>
                    </MenuItem>
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">
                        <Link to="/login"> LOG IN</Link>
                      </Typography>
                    </MenuItem>
                  </Menu>
                </Box>
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
                >
                  <Logo src={newLogo}></Logo>
                </Typography>
                <Box
                  sx={{ flexGrow: 3, display: { xs: "none", md: "flex" } }}
                  className={styles.pages}
                  style={{ justifyContent: "center" }}
                >
                  {/* {pages.map((page) => (
                    <Button
                      key={page}
                      onClick={handleCloseNavMenu}
                      sx={{ my: 2, color: "white", display: "block" }}
                    >
                      {page}
                    </Button>
                  ))} */}
                  <Button
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, color: "white", display: "block", pr: 3 }}
                  >
                    Home
                  </Button>
                  <Links smooth to="#whyus">
                    {" "}
                    <Button
                      onClick={handleCloseNavMenu}
                      sx={{ my: 2, color: "white", display: "block", pr: 3 }}
                    >
                      WHY US
                    </Button>
                  </Links>
                  <Button
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, color: "white", display: "block", pr: 3 }}
                  >
                    PRICING
                  </Button>
                  <Button
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, color: "white", display: "block", pr: 3 }}
                  >
                    FAQ
                  </Button>
                  <Links smooth to="#footer">
                    {" "}
                    <Button
                      onClick={handleCloseNavMenu}
                      sx={{ my: 2, color: "white", display: "block", pr: 3 }}
                    >
                      CONTACT US
                    </Button>
                  </Links>
                  {/* <Button
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, color: "white", display: "block", pr: 3 }}
                  >
                    CONTACT US
                  </Button> */}
                  <Button>
                    <DropDown class="dropdown">
                      <DropdownButton class="dropbtn">
                        Become BYLDer
                        <i class="fa fa-caret-down"></i>
                      </DropdownButton>
                      <DropDownContent class="dropdown-content">
                        <a href="https://www.technician.boxbyld.tech/register">Install Partner</a>
                        <Link to="/sale-form">
                          {" "}
                          <a href="#">Sales Partner</a>
                        </Link>
                        {/* <a href="#">Link 3</a> */}
                      </DropDownContent>
                    </DropDown>
                  </Button>
                  <Button></Button>
                  <Link to="/login">
                    <Button
                      // onClick={handleCloseNavMenu}
                      sx={{ my: 2, color: "white", display: "block" }}
                      style={{
                        border: "1px solid white",
                        padding: "5px 50px",
                        borderRadius: "15px",
                      }}
                    >
                      Login
                    </Button>
                  </Link>
                </Box>

                {/* <Box sx={{ flexGrow: 0 }} className={styles.button}> */}
                {/* <Tooltip title="Open settings"> */}
                {/* <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}> */}
                {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" /> */}
                {/* <LoginButton>Login</LoginButton> */}
                {/* </IconButton> */}
                {/* </Tooltip> */}
                {/* <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {settings.map((setting) => (
                      <MenuItem key={setting} onClick={handleCloseUserMenu}>
                        <Typography textAlign="center">{setting}</Typography>
                      </MenuItem>
                    ))}
                  </Menu> */}
                {/* </Box> */}
              </Toolbar>
            </Container>
          </AppBar>
          <Content>
            <ContentHeader>Welcome To The </ContentHeader>
            <ContentHeader>BoxBYLD Experience</ContentHeader>
            <ContentLorem>
              Connect With Your Customers More Effectively
            </ContentLorem>
            {/* <h1 style={{color:"white"}}>Slider</h1> */}
          </Content>
        </Grid>
      </Grid>
      <SliderDiv>
        <Slider {...settingSlider}>
          <div>
            <SliderP style={{ color: "black", textAlign: "center" }}>
              Capability of Managing Your Team from Anywhere.
            </SliderP>
          </div>
          <div>
            <SliderP style={{ color: "black", textAlign: "center" }}>
              Sales Learning and Development for Our Products and Services{" "}
            </SliderP>
          </div>
          <div>
            <SliderP style={{ color: "black", textAlign: "center" }}>
              Financing Available for all Solar and Home Improvement Projects{" "}
            </SliderP>
          </div>
          <div>
            <SliderP style={{ color: "black", textAlign: "center" }}>
              Drive Your Teams Success with Performance Management Tools{" "}
            </SliderP>
          </div>
        </Slider>
      </SliderDiv>
    </BigSection>
  );
};
export default NewHeader;
