import { Outlet } from "react-router-dom";

// import Calender from "../../pages/Left/Routs/calendar/Calender";
// import Customer from "../../pages/Left/Routs/customers/Customers";
// import Goals from "../../pages/Left/Routs/goals/Goals";
// import Meetings from "../../pages/Left/Routs/meetings/Meetings";
// import Reports from "../../pages/Left/Routs/reports/Reports";
// import Tasks from "../../pages/Left/Routs/tasks/Tasks";
// import Training from "../../pages/Left/Routs/training/Training";
// import Login from "../../pages/Login/Login";
// import AddLeadForm from "../../pages/Left/Routs/customers/AddLead/AddLeadForm";
const RouterContent = () => {
  // const { authLogIn } = useSelector((state) => state.auth);

  return (
    <>
      {/* <Routes>
        {!authLogIn && <Route path="/" element={<Login />} />}
        {authLogIn && <Route path="/customer" element={<Customer />} />}
        {authLogIn && <Route path="/addlead" element={<AddLeadForm />} />}
        {authLogIn && <Route path="/calendar" element={<Calender />} />}
        {authLogIn && <Route path="/tasks" element={<Tasks />} />}
        {authLogIn && <Route path="/goals" element={<Goals />} />}
        {authLogIn && <Route path="/meetings" element={<Meetings />} />}
        {authLogIn && <Route path="/training" element={<Training />} />}
        {authLogIn && <Route path="/reports" element={<Reports />} />}
      </Routes> */}

      <Outlet />
    </>
  );
};

export default RouterContent;
