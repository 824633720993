import { combineReducers } from "redux";
import authReducer from "./authReducer";
import leadReducer from "./leadReducer";
import zoomReducer from "./ZoomReducer";
import countriesReducer from "./countriesReducer";
import servicesReducer from "./servicesReducer";
import ProposalsReducer from "./solutionsReducer";
import EditUserReducer from "./EditReducer";
import EditFinnanceReducer from "./EditFinnanceReducer";
import CalcoulatorRducer from "./CalcoulatorRducer";
import sunlightReducer from "./sunlightReducer";
import allEventsReducer from "./allEventsReducer";
import NotificationReducer from "./NotificationReducer";
import GoodLeapReducer from "./goodLeapReducer";
import FilteredLeads from "./leadFilterReducer.jsx";
import installerReducer from "./addInstallerReducer";
import RoofingReducer from "./addRoofingReducer";
import CreateTicketReducer from "./CreateTicketReducer";
import LeadBankReducer from "./leadBankReducer";
import MessageReducer from "./MessageReducer";
import AddersReducer from "./AdderReducer";
import MosiacReducer from "./MosaicReducer";
import ConcertReducer from "./ConcertReducer";
import achievementsReducer from "./achievementsReducers";
import licenseReducer from "./licenseReducer";
import { leadsApi } from "../toolKit/leads.js";
import { addersApi } from "../toolKit/adders.js";
import { coinsApi } from "../toolKit/coins.js";
import { AddersFormApi } from "../toolKit/addersForm.js";
import { leadsBankApi } from "../toolKit/leadsBank.js";
import myFormDataReducer from "../toolKit/formData.js";
import { distributor } from "../toolKit/distributor.js";
import { EnfinApi } from "../toolKit/enfin.js";
import { salesForm } from "../toolKit/salesForm.js";
import { templateApi } from "../toolKit/template.js";
import { salesAddersApi } from "../toolKit/salesAdders.js";
const rootRducer = combineReducers({
  auth: authReducer,
  zoom: zoomReducer,
  leads: leadReducer,
  countries: countriesReducer,
  services: servicesReducer,
  proposal: ProposalsReducer,
  editUser: EditUserReducer,
  editFinance: EditFinnanceReducer,
  calc: CalcoulatorRducer,
  sunlight: sunlightReducer,
  events: allEventsReducer,
  notifications: NotificationReducer,
  GoodLeapReducer: GoodLeapReducer,
  FilteredLeads: FilteredLeads,
  installerReducer: installerReducer,
  RoofingReducer: RoofingReducer,
  Ticket: CreateTicketReducer,
  LeadBankReducer: LeadBankReducer,
  messages: MessageReducer,
  Adders: AddersReducer,
  Mosiac: MosiacReducer,
  Concert: ConcertReducer,
  achievements: achievementsReducer,
  license: licenseReducer,
  // [leadsApi.reducerPath]: leadsApi.reducer,
  [addersApi.reducerPath]: addersApi.reducer,
  [coinsApi.reducerPath]: coinsApi.reducer,
  [AddersFormApi.reducerPath]: AddersFormApi.reducer,
  [leadsApi.reducerPath]: leadsApi.reducer,
  [leadsBankApi.reducerPath]: leadsBankApi.reducer,
  [distributor.reducerPath]: distributor.reducer,
  [EnfinApi.reducerPath]: EnfinApi.reducer,
  [salesForm.reducerPath]: salesForm.reducer,
  [templateApi.reducerPath]: templateApi.reducer,
  [salesAddersApi.reducerPath]: salesAddersApi.reducer,
  myFormData: myFormDataReducer,
});
export default rootRducer;
