import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
body{
    font-family: 'Poppins', sans-serif;
}
::-webkit-scrollbar {
  width: .5em;
 height: 0.5rem;
}
 
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #888;
}
 
::-webkit-scrollbar-thumb {
  background-color: #101010;
 
  
}

h1{
    color: #252525;
}
a{
    text-decoration: none;
    color: black;
    
}
ul{
    list-style: none;
}
iframe {
  overflow: hidden;
  border: none;
  width: calc(100% + 16px);
  height: calc(100% + 16px);
}
#iframe{
  #legendeW{
    display: none;
  }
  #legendeW{
    display: none;
  }
}
#legendeW{
  display: none !important;
}
a{
  color:inherit
}
.swal2-container {
  z-index: 20000 !important;
}
`;
