import { Grid } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../../components/Header/Header";
import Loading from "../../components/loading/Loading";
import { singleLeadAction } from "../../Redux/Actions/singleLeadAction";
import LeadMainInfo from "./LeadMainInfo";
import LeadMainTaps from "./LeadMainTaps";

const LeadProfile = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const LeadId = location.pathname.split("/")[2];
  const userID = location.pathname.split("/")[4];
  // console.log(userID);

  useEffect(() => {
    if (LeadId) dispatch(singleLeadAction(LeadId));
  }, [LeadId]);

  const { singlead, isSingleLeadLoading } = useSelector((state) => state.leads);

  /*********************************** */
  return (
    <>
      <Header />
      {!isSingleLeadLoading && (
        <Grid container columns={18}>
          <Grid item lg={5} md={8} xs={18}>
            <LeadMainInfo singlead={singlead} />
          </Grid>
          <Grid item lg={13} md={10} xs={18}>
            <LeadMainTaps
              LeadId={LeadId}
              opportunities={singlead?.opportunities}
              contracts={singlead?.solar_contracts}
            />
          </Grid>
        </Grid>
      )}
      {isSingleLeadLoading && <Loading />}
    </>
  );
};

export default LeadProfile;
