import { Container, Grid } from "@mui/material";
import React from "react";
import styled from "styled-components";
import FooterPDF from "../proposalsPdfComponents/SolarPdfComponents/FooterPDF";

const PageTowContract = ({ singleOpportunity }) => {
  const tax = (singleOpportunity.estimated_cost * 26) / 100;
  return (
    <>
      <PageTwoContractStyled>
        <Container>
          <Grid container item columns={18} spacing={3}>
            <Grid item xs={18} sm={9} md={6}>
              <h3>Customer & Property Owner(s)</h3>
              <p></p>
            </Grid>
            <Grid item xs={18} sm={9} md={6}>
              <h3>Property Location</h3>
              <p></p>
              <h3>Date: </h3>
            </Grid>
            <Grid item xs={18} sm={9} md={6}>
              <h3>Contractor</h3>
              <p>
                All Premium Contractors, Inc. 9530 Hageman Rd., Ste B394
                Bakersfield, CA 95355 1-(877)-356-8460 Date License # 920361
              </p>
            </Grid>

            <Grid item xs={18}>
              <p>
                This HOME INSTALLATION CONTRACT (the “Contract”) is entered into
                by and between ALL PREMIUM CONTRACTORS, INC., (the “CONTRACTOR”
                or “APC”), and the party or parties identified herein as the
                “CUSTOMER”, under the following terms and conditions:
              </p>
            </Grid>
          </Grid>
          <div className="systemDetails">
            <h2>System Details</h2>
            <Grid container item columns={12} spacing={3}>
              <Grid item xs={12} sm={4} md={3}>
                <div className="data">
                  <p className="key">0</p>
                  <h4 className="value">Due At Signing</h4>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <div className="data">
                  <p className="key">{singleOpportunity.estimated_cost} </p>
                  <h4 className="value">Installation Costs</h4>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <div className="data">
                  <p className="key">{singleOpportunity.system_size}</p>
                  <h4 className="value">System Size</h4>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <div className="data">
                  <p className="key">{singleOpportunity.system_size_ac}</p>
                  <h4 className="value">Est. First Year Production</h4>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <div className="data">
                  <p className="key">25 Years</p>
                  <h4 className="value">Workmanship Warranty</h4>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <div className="data">
                  <p className="key">25 Years</p>
                  <h4 className="value">Equipment Warranty</h4>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <div className="data">
                  <p className="key">{singleOpportunity.federal_tax}</p>
                  <h4 className="value">*Potential Tax Credit</h4>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <div className="data">
                  <p className="key">{tax}</p>
                  <h4 className="value">Est. Set-Up & Delivery</h4>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="systemMaterial">
            <h2>System Material & Description</h2>
            <p></p>
          </div>
          <div className="schedule">
            <h2>Schedule Of Progress Payments</h2>
            <p>
              ___________You are entering an agreement to purchase an energy
              generation system. You will own the system installed on your
              property. You may be entitled to federal tax credits because of
              the purchase. You should consult your tax advisor. If Owner is
              financing the Installation through a third-party, Certain of the
              rights and obligations of this Contract are subject to the Owner
              receiving third-party financing for the cost of the Installation.
              OR
            </p>
            <p>
              ___________If Owner is paying Contractor directly, Owner shall pay
              to the Contractor the Contract Price according to the following
              Schedule of Progress Payments:
            </p>

            <Grid container item columns={18} spacing={3}>
              <Grid item xs={18} sm={9} md={6}>
                <div className="data">
                  <p></p>
                  <h3>Deposit At Signing</h3>
                </div>
              </Grid>
              <Grid item xs={18} sm={9} md={6}>
                <div className="data">
                  <p>{singleOpportunity.estimated_cost - tax}</p>
                  <h3>Day Of Installation</h3>
                </div>
              </Grid>
              <Grid item xs={18} sm={9} md={6}>
                <div className="data">
                  <p>{tax}</p>
                  <h3>City Inspection Approval</h3>
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
        <FooterPDF />
      </PageTwoContractStyled>
    </>
  );
};

const PageTwoContractStyled = styled.div`
  h2 {
    margin: 2rem 0;
    color: #0b3d8d;
  }
  .systemDetails {
    h2 {
      text-align: left;
    }
    padding: 1rem 0;
    text-align: center;
    .key {
      padding: 1rem;
      height: 50px;
      background: #eee;
    }
    .value {
      background: #0b3d8d;
      padding: 1rem;
      color: #fff;
    }
  }
  .schedule {
    padding: 1rem 0;
    p {
      margin: 1rem 0;
    }
    .data {
      p {
        padding: 1rem;
        height: 50px;
        background: #eee;
        margin: 0;
      }
      h3 {
        background: #0b3d8d;
        padding: 1rem;
        color: #fff;
      }
    }
  }
`;
export default PageTowContract;
