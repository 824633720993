import React from "react";
import { FormControlLabel, FormHelperText, Grid } from "@mui/material";
import { BigSection } from "../firstForm/style";
import SelectInput from "../../../../../components/inputs/SelectInput/SelectInput";
import StanderInput from "../../../../../components/inputs/standerInput";
import RadioButton from "../../../../../components/inputs/radio";
import TextArea from "../../../../../components/inputs/textArea";
import CheckBox from "../../../../../components/inputs/checkBox";
import {
  adderNames,
  batteriesAdders,
  electricalAdders,
  generatorsAdders,
  hvacsAdders,
  roofingAdders,
} from "./staticData";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Checkbox } from "@mui/material";

const data = [
  { id: "ground_mount", name: "Ground mount", value: "ground_mount" },
  { id: "roof_mount", name: "Roof mount", value: "roof_mount" },
];
const radioList = [
  { id: 1, title: "Yes" },
  { id: 2, title: "No" },
];
const roofTypes = [
  { id: "shingle", name: "shingle", value: "shingle" },
  { id: "spanish", name: "spanish", value: "spanish" },
  { id: "metal", name: "metal", value: "metal" },
  { id: "concrete", name: "concrete", value: "concrete" },
];
const ThirdForm = ({ methods, error }) => {
  const skip = methods.watch("skip");
  console.log("error", error?.data?.error);

  return (
    <BigSection>
      <Grid container columns={12} spacing={3}>
        <Grid item xs={12}>
          <Controller
            control={methods.control}
            name={"skip"}
            render={({ field: { value, onChange, ...field } }) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      // style={{ width: "1.2rem", height: "1.2rem" }}
                      value={skip}
                      onChange={(event) => {
                        onChange(+event.target.checked);
                      }}
                      defaultChecked={skip == "1" ? true : false}
                      id="skip"
                    />
                  }
                  label={"skip the adders price list"}
                />
              );
            }}
          />
          <ErrorMessage
            render={({ message }) => (
              <FormHelperText
                sx={{
                  color: "#f00",
                  mt: "-1px",
                  fontSize: 11,
                  fontWeight: 600,
                }}
              >
                {message}
              </FormHelperText>
            )}
            // errors={errors}
            name={"skip"}
          />
        </Grid>
        {skip !== 1 && (
          <>
            <Grid item xs={12}>
              <div>
                <h3>Electrical</h3>
                <hr />
              </div>
            </Grid>
            {electricalAdders?.map((e) => {
              return (
                <Grid item xs={12} md={6} lg={6} key={e?.id}>
                  <StanderInput
                    fullWidth
                    id={e?.id}
                    type="number"
                    name={`adders.${e?.name}`}
                    Label={e?.label}
                    defaultValue={0}
                    inputProps={{ min: 0 }}
                  />
                </Grid>
              );
            })}
            <Grid item xs={12}>
              <div>
                <h3>Hvacs</h3>
                <hr />
              </div>
            </Grid>
            {hvacsAdders?.map((e) => {
              return (
                <Grid item xs={12} md={6} lg={6} key={e?.id}>
                  <StanderInput
                    fullWidth
                    id={e?.id}
                    type="number"
                    name={`adders.${e?.name}`}
                    Label={e?.label}
                    defaultValue={0}
                    inputProps={{ min: 0 }}
                  />
                </Grid>
              );
            })}
            <Grid item xs={12}>
              <div>
                <h3>Generators</h3>
                <hr />
              </div>
            </Grid>
            {generatorsAdders?.map((e) => {
              return (
                <Grid item xs={12} md={6} lg={6} key={e?.id}>
                  <StanderInput
                    fullWidth
                    id={e?.id}
                    type="number"
                    name={`adders.${e?.name}`}
                    Label={e?.label}
                    defaultValue={0}
                    inputProps={{ min: 0 }}
                  />
                </Grid>
              );
            })}
            <Grid item xs={12}>
              <div>
                <h3>Batteries</h3>
                <hr />
              </div>
            </Grid>
            {batteriesAdders?.map((e) => {
              return (
                <Grid item xs={12} md={6} lg={6} key={e?.id}>
                  <StanderInput
                    fullWidth
                    id={e?.id}
                    type="number"
                    name={`adders.${e?.name}`}
                    Label={e?.label}
                    defaultValue={0}
                    inputProps={{ min: 0 }}
                  />
                </Grid>
              );
            })}{" "}
            <Grid item xs={12}>
              <div>
                <h3>Roofing</h3>
                <hr />
              </div>
            </Grid>
            {roofingAdders?.map((e) => {
              return (
                <Grid item xs={12} md={6} lg={6} key={e?.id}>
                  <StanderInput
                    fullWidth
                    id={e?.id}
                    type="number"
                    name={`adders.${e?.name}`}
                    Label={e?.label}
                    defaultValue={0}
                    inputProps={{ min: 0 }}
                  />
                </Grid>
              );
            })}{" "}
          </>
        )}
      </Grid>
    </BigSection>
  );
};

export default ThirdForm;
