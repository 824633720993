import { createSlice, createAction, configureStore } from "@reduxjs/toolkit";
let initialState = {
  formdata: {},
  enfinData: {},
};
const myFormData = createSlice({
  name: "myFormData",
  initialState: initialState,
  reducers: {
    updataForm: (state, action) => {
      state.formdata = action.payload;
    },
    updateSystemSize: (state, action) => {
      state.systemSize = action.payload;
    },
    updateTotalPanels: (state, action) => {
      state.totalPanels = action.payload;
    },
    updateTotalsum: (state, action) => {
      state.totalSum = action.payload;
    },
    updateInstaller: (state, action) => {
      state.installerId = action.payload;
    },
    updatedistributor: (state, action) => {
      state.distributorId = action.payload;
    },
    updateSalesAdders: (state, action) => {
      state.salesAdders = action.payload;
    },
    updateEnfinData: (state, action) => {
      state.enfinData = action.payload;
    },
  },
});

export const {
  updataForm,
  updateSystemSize,
  updateTotalPanels,
  updateTotalsum,
  updateInstaller,
  updateSalesAdders,
  updatedistributor,
  updateEnfinData,
} = myFormData.actions;
export default myFormData.reducer;
