// import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
// import { GetSingleUser } from "../../../Redux/Actions/EditUserAction";
import TeamCardsUser from "./TeamCards";
const MainContentProfile = () => {
  // const dispatch = useDispatch();
  // const { user_id } = useSelector((state) => state.auth);
  // useEffect(() => {
  //   dispatch(GetSingleUser(user_id));
  // }, [dispatch]);
  const { getMainUser } = useSelector((s) => s.editUser);
  const ChildTeams = getMainUser?.descendants;

  return (
    <>
      <ProfileRightStyle>
        <div className="gridContiner">
          {ChildTeams?.map((child) => (
            <TeamCardsUser key={child.id} Team={child} />
          ))}
        </div>
      </ProfileRightStyle>
    </>
  );
};
const ProfileRightStyle = styled.section`
  padding: 3rem;
  background: #fefefe;
  height: 100vh;
  .gridContiner {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

    gap: 2rem;
  }
`;
export default MainContentProfile;
