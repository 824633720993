import { ThumbnailCard } from "../RightStyled";
import challage from "../../../assets/goal.png";

const Challenges = () => {
  return (
    <>
      <ThumbnailCard>
        <div className="icon challenges">
          <img src={challage} alt="achivements" />
        </div>
        <div className="data">
          <div className="title">challenges </div>
          <div className="number">0</div>
        </div>
      </ThumbnailCard>
    </>
  );
};

export default Challenges;
