import NewHeader from "./LandingPageComponents/Header/NewHeader";
import NewServices from "./LandingPageComponents/Services/NewServices";
import Mangement from "./LandingPageComponents/Mangement/Mangement";
import OurService from "./LandingPageComponents/Our services/OurService";
import Break from "./LandingPageComponents/Break/Break";
import WhiteShirt from "./LandingPageComponents/WhiteShirt/WhiteShirt";
import HowWeSell from "./LandingPageComponents/Howwesell/HowWeSell";
import Slider from "./LandingPageComponents/Slider/Slider";
import RecentBlog from "./LandingPageComponents/RecentBlog/RecentBlog";
import Footer from "./LandingPageComponents/Footer/Footer";
import TheMusic from "./LandingPageComponents/TheMusic/TheMusic";



const NewLandingPage = () => {

  

  return (
    <div>

      <NewHeader></NewHeader>
      <TheMusic></TheMusic>
      {/* <NewServices></NewServices> */}
      <Mangement></Mangement>
      <OurService></OurService>
      <Break></Break>
      <WhiteShirt></WhiteShirt>
      <HowWeSell></HowWeSell>
      {/* <Slider></Slider> */}
      {/* <RecentBlog></RecentBlog> */}
      <Footer></Footer>

    </div>
  );
};
export default NewLandingPage;
