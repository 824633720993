import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./helper/index.js";

export const salesAddersApi = createApi({
  reducerPath: "salesAddersApi",
  tagTypes: ["salesAdders"],
  baseQuery,
  endpoints: (builder) => ({
    getSalesAdders: builder.query({
      query: (page) => ({
        method: "GET",
        url: "adders",
      }),
      providesTags: ["salesAdders"],
    }),
    editSalesAdders: builder.mutation({
      query: (body) => ({
        method: "PATCH",
        url: "adders/edit",
        body,
      }),
      providesTags: ["salesAdders"],
    }),
  }),
});

export const { useGetSalesAddersQuery, useEditSalesAddersMutation } =
  salesAddersApi;
