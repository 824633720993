import { ThumbnailCard } from "../RightStyled";
import start from "../../../assets/star.png";

const Rating = () => {
  return (
    <>
      <ThumbnailCard>
        <div className="icon Rating">
          <img src={start} alt="Rating" />
        </div>
        <div className="data">
          <div className="title">Rating </div>
          <div className="number">5</div>
        </div>
      </ThumbnailCard>
    </>
  );
};

export default Rating;
