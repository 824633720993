import { Google } from "@mui/icons-material";
import { Button } from "@mui/material";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const NavCalender = () => {
  return (
    <>
      <NavStyled>
        <NavLink to="bigcalender">
          <Button className="btn"> Calnder</Button>
        </NavLink>
        <NavLink to="googlecalender">
          <Button className="google" startIcon={<Google />}>
            Calender
          </Button>
        </NavLink>
      </NavStyled>
    </>
  );
};

const NavStyled = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin: 2rem 0;
  width: 100%;
  background: #eeeeee;
  border-radius: 1rem;
  a {
    Button {
      margin: 0.5rem 1rem;
      padding: 0.2rem 0.5rem;
      color: #0dbf7c;
      font-size: 1rem;
      &:hover {
        color: #06623f;
        background: none;
      }
    }
    &.active {
      Button {
        color: #06623f;
      }
    }
  }
`;

export default NavCalender;
