import {
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  TextField,
} from "@mui/material";
import React from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import styled from "styled-components";
import { CreateTicket } from "../../../../Redux/Actions/CreateTicket";
import { useDispatch, useSelector } from "react-redux";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { GetInstallerStates } from "../../../../Redux/Actions/addInstallerAction";
import { loadStates } from "../../../../Redux/Actions/CountriesActions";
import { Loading } from "../../../Left/Routs/goals/style";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import { postLicense } from "../../../../Redux/Actions/licensesActions";

const CreateLicense = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.proposal);

  React.useEffect(() => {
    dispatch(loadStates(231));
  }, []);
  const { states } = useSelector((state) => state.countries);
  const schema = yup.object().shape({
    licenses: yup.array().of(
      yup.object().shape({
        state_id: yup
          .string()
          .typeError("A number is required")
          .required("This state filed is required"),
        license: yup
          .mixed()
          .test("fileType", "Only PDF files are allowed", (value) => {
            // Check if the file is a PDF
            return value && value.type === "application/pdf";
          })
          .test("fileSize", "File size must be less than 5MB", (value) => {
            // Check if the file size is less than 5MB (5 * 1024 * 1024 bytes)
            return value && value.size <= 5 * 1024 * 1024;
          })
          .required("the license is required"),
      })
    ),
  });
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      licenses: [{ state_id: "", license: "" }],
    },
  });
  const {
    formState: { errors },
  } = methods;

  const { control } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "licenses",
  });
  console.log("errors", errors);
  const onSubmit = async (data) => {
    // Handle form submission here
    console.log("data", data);
    const formData = new FormData();
    data.licenses.map((e, i) => {
      formData.append(`licenses[${i}][state]`, e?.state_id);
      formData.append(`licenses[${i}][media]`, e?.license);
    });

    await dispatch(postLicense(formData));
  };
  return (
    <>
      {states?.length > 0 ? (
        <>
          {" "}
          <Bigsection>
            {/* <h3>Create License/s</h3> */}
            <IconButton
              aria-label="delete"
              size="big"
              onClick={() =>
                append({
                  state_id: "",
                  license: "",
                })
              }
              className="icon"
            >
              Add more license
              <AddCircleIcon fontSize="inherit" />
            </IconButton>
            {/* <button
        type="button"
        className="add"
        onClick={() =>
          append({
            file: "",
            license: "",
          })
        }
      >
        Add more license
      </button> */}
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                {fields.map((item, index) => (
                  <Grid
                    container
                    key={item.id}
                    alignItems="center"
                    columns={18}
                    justifyContent="flex-start"
                    spacing={5}
                    sx={{ padding: "0.8rem 0" }}
                  >
                    <Grid item xs={18} md={8}>
                      {" "}
                      <Controller
                        name={`licenses[${index}].state_id`}
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, onBlur, value } }) => (
                          <TextField
                            size="small"
                            select
                            fullWidth
                            // required
                            label="State"
                            name={`licenses[${index}].state_id`}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                          >
                            {states?.map((s) => (
                              <MenuItem key={s.id} value={s.id}>
                                {s.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                      <ErrorMessage
                        render={({ message }) => (
                          <FormHelperText sx={{ color: "#f00" }}>
                            {message}
                          </FormHelperText>
                        )}
                        errors={
                          errors?.licenses ? errors?.licenses[index] : null
                        }
                        name="state_id"
                      />
                    </Grid>
                    <Grid item xs={18} md={8}>
                      {" "}
                      <Controller
                        name={`licenses[${index}].license`}
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <input
                            type="file"
                            name={`licenses[${index}].license`}
                            // required
                            accept="application/pdf"
                            onChange={(e) => {
                              field.onChange(e.target.files[0]);
                            }}
                          />
                        )}
                      />
                      <ErrorMessage
                        render={({ message }) => (
                          <FormHelperText sx={{ color: "#f00" }}>
                            {message}
                          </FormHelperText>
                        )}
                        errors={
                          errors?.licenses ? errors?.licenses[index] : null
                        }
                        name="license"
                      />
                    </Grid>
                    <Grid item xs={18} md={2}>
                      <IconButton
                        type="button"
                        onClick={() => remove(index)}
                        className="delete"
                      >
                        <DeleteIcon></DeleteIcon>
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
                <Grid item xs={18}>
                  <div className="btContainer">
                    <button type="submit" className="create">
                      {isLoading ? "Creating..." : " Create License/s"}{" "}
                    </button>
                  </div>{" "}
                </Grid>
              </form>
            </FormProvider>
          </Bigsection>
        </>
      ) : (
        <>
          <Loading>
            <span className="loader"></span>
          </Loading>{" "}
        </>
      )}
    </>
  );
};
const Bigsection = styled.div`
  input {
    width: 100%;
    /* padding: 1rem 0; */
  }
  padding: 1rem;
  .btContainer {
    text-align: center;
  }
  .create {
    padding: 1rem 0;
    width: 50%;
    margin: 0 auto;
    cursor: pointer;
    border: none;
    border-radius: 2rem;
    padding: 0.7rem 3rem;
    background-size: 400% 100%;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    background-image: linear-gradient(
      132.6deg,
      rgba(71, 139, 214, 1) 23.3%,
      rgba(37, 216, 211, 1) 84.7%
    );
    color: white;
    &:hover {
      background-position: 100% 0;
      -webkit-text-decoration: none;
      text-decoration: none;
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
      background-image: linear-gradient(
        132.6deg,
        rgba(71, 139, 214, 1) 23.3%,
        rgba(37, 216, 211, 1) 84.7%
      );
    }
  }
  .add {
    padding: 0.5rem 2rem;
    margin: 1rem 0;
  }
  input[type="file"]::file-selector-button {
    /* margin-right: 20px; */
    border: none;
    background: black;
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
  }

  input[type="file"]::file-selector-button:hover {
    background: #0d45a5;
  }
  .icon {
    display: flex;
    gap: 0.5rem;
    font-size: 1.2rem;
    &:hover {
      color: green;
      background: none;
    }
  }
  .delete {
    &:hover {
      color: red;
      background: none;
    }
  }
`;
export default CreateLicense;
