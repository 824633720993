import {
  CREATE_PROJECT,
  SEND_PREQUAL,
  GET_PREQUAL_PROJECT,
  PULL_STATUS,
  SUBMIT_CREDIT,
  ADD_QUOTE,
} from "../Actions/type";

const initialState = {
  projects: {},
  prequal: {},
  allProjects: [],
  pullStatus: {},
  SUBMIT_CREDIT: {},
  addQuote: {},
  statusProject: "",
  isSubmitProject: false,
  isAddQoute: false,
};

const sunlightReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_PROJECT:
      return {
        ...state,
        projects: action.projects,
      };
    case "SHOW_LOADING_SUNLIGHT":
      return {
        ...state,
        isSubmitProject: true,
        isAddQoute: true,
      };
    case "HIDE_LOADING_SUNLIGHT":
      return {
        ...state,
        isSubmitProject: false,
        isAddQoute: false,
      };
    case "POST_PROJECT_SUCSESS":
      return {
        ...state,
        statusProject: action.statusProject,
      };
    case ADD_QUOTE:
      return {
        ...state,
        addQuote: action.addQuote,
      };
    case GET_PREQUAL_PROJECT:
      return {
        ...state,
        allProjects: action.allProjects,
      };
    case SEND_PREQUAL:
      return {
        ...state,
        prequal: action.prequal,
      };
    case SUBMIT_CREDIT:
      return {
        ...state,
        submitCredit: action.submitCredit,
      };
    case PULL_STATUS:
      return {
        ...state,
        pullStatus: action.pullStatus,
      };
    default:
      return { ...state };
  }
};

export default sunlightReducer;
