import { Grid } from "@mui/material";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import background from "../../assets/RoofingBackGround.jpg";
import Header from "../../components/Header/Header";
import BadgeIcon from "@mui/icons-material/Badge";
import { useNavigate } from "react-router-dom";
import { GET_SINGLE_ROOFING } from "../../API/Api";
import axios from "axios";
import LoadingSoultion from "../../components/loading/loadingSolution";
import Loading from "../../components/loading/Loading";
// style
const Section = styled.section`
  .blur {
    padding: 1rem 2rem;
    position: relative;
  }
  .LeadInfo {
    /* backdrop-filter: blur(16px) saturate(180%);
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 100%;
    color: white; */
    width: 100%;
    /* border: 1px solid black; */
    border-radius: 1rem;
    background: white;
    box-shadow: 0px 0px 5px 2px rgba(126, 122, 122, 0.75);

    h3 {
      text-align: center;
      padding: 0.5rem;
    }
    .userData {
      padding: 1rem;
      span {
        color: #7c7b7b;
      }
    }
    .HeaderDiv {
      position: relative;
      &:after {
        position: absolute;
        width: 100%;
        height: 0.0099rem;
        content: "";
        background-color: black;
      }
    }
  }
  .rightDiv {
    /* backdrop-filter: blur(16px) saturate(180%);
    background: rgba(255, 255, 255, 0.2);
    border-radius: 1rem;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 100%;
    color: white; */
    width: 100%;
    border-radius: 1rem;
    background: white;
    box-shadow: 0px 0px 5px 2px rgba(126, 122, 122, 0.75);
    h3 {
      padding: 0.5rem;
    }
    .solutionDetails {
      .HeaderDiv {
        position: relative;
        &:after {
          position: absolute;
          width: 100%;
          height: 0.0099rem;
          content: "";
          background-color: black;
        }
      }
    }
    .data {
      padding: 1rem;
      span {
        color: #7c7b7b;
      }
    }
  }
`;
const BackButton = styled.button`
  padding: 10px 10px;
  background-color: black;
  color: white;
  border-radius: 2rem;
  &:hover {
    cursor: pointer;
  }
`;
const SingleRoofingSolution = () => {
  const { id } = useParams();
  let navigate = useNavigate();
  const location = useLocation();
  const leadId = Number(location.pathname.split("/")[2]);
  const goBack = () => {
    // navigate(-1);
  };
  const [data, setData] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  console.log("data from api", data);

  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  React.useEffect(() => {
    axios(
      {
        method: "GET",
        baseURL: `${GET_SINGLE_ROOFING}/${id}`,
        headers: headers,
      },
      setIsLoading(true)
    )
      .then((res) => {
        setData(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err.data);
        setIsLoading(false);
      });
  }, []);
  /**************All Data **************/
  // User Data
  const user = data?.user;
  // Contractor data
  const constractor = data?.lead;

  console.log("data?.plan_job_type?.id ", data?.plan_job_type?.id);
  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          <Section>
            <Header></Header>
            <div className="blur">
              <Grid container columns={12} spacing={4} alignItems="start">
                <Grid item lg={12} md={12} sm={12}>
                  {" "}
                  <Link to={`/profilelead/${leadId}`} replace>
                    {" "}
                    <BackButton onClick={goBack}>
                      Back to all solutions
                    </BackButton>
                  </Link>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <div className="LeadInfo">
                    <div className="HeaderDiv">
                      <h3>User Info</h3>
                    </div>
                    <div className="userData">
                      <p>
                        Name : <span>{user?.name}</span>
                      </p>
                      <p>
                        Email : <span>{user?.email}</span>
                      </p>
                      <p>
                        Phone : <span>{user?.phone}</span>
                      </p>
                      <p>
                        Mantras : <span>{user?.name}</span>
                      </p>
                      <p>
                        Business :<span> {user?.name}</span>
                      </p>
                      <p>
                        Lead Count : <span>{user?.leads_count}</span>
                      </p>
                      <p>
                        Opportunities count :
                        <span> {user?.opportunities_count}</span>
                      </p>
                      <p>
                        Solar contract count :{" "}
                        <span>{user?.solar_contracts_count}</span>
                      </p>
                      <p>
                        Job title :<span> {user?.Job_title} </span>
                      </p>
                    </div>
                    <div className="HeaderDiv">
                      <h3>Contractor Info</h3>
                    </div>
                    <div className="userData">
                      <p>
                        Name :{" "}
                        <span>
                          {constractor?.first_name} {constractor?.last_name}
                        </span>{" "}
                      </p>
                      <p>
                        Address : <span>{constractor?.location}</span>
                      </p>
                      <p>
                        Status : <span>{constractor?.is_active}</span>
                      </p>
                      <p>
                        Mobile : <span>{constractor?.mobile}</span>
                      </p>
                      {/* <p>Expiry Date : <span>{constractor?.name}</span></p> */}
                      {/* <p>Entity : <span>{constractor?.name}</span></p> */}
                      {/* <p>License :  <span>{constractor?.name}</span></p> */}
                      {/* <p>Initial : <span>{constractor?.name}</span></p> */}
                      <p>
                        Contract amount :{" "}
                        <span style={{ color: "green" }}>
                          ${data?.total_cost}
                        </span>
                      </p>
                      <p>
                        Royalties : <span>{data?.royalties}</span>
                      </p>
                    </div>
                  </div>
                </Grid>
                <Grid item lg={8} md={8} sm={12} xs={12}>
                  <div className="rightDiv">
                    <div className="solutionDetails">
                      <div className="HeaderDiv">
                        <h3>Roof job type</h3>
                      </div>
                      <div className="data">
                        <Grid container columnns={12} spacing={2}>
                          <Grid item lg={4} md={4}>
                            <p>
                              Job Type: <span>{data?.job_type?.name}</span>
                            </p>
                          </Grid>
                          {data?.job_type.id === 1 && (
                            <>
                              <Grid item lg={4} md={4}>
                                <p>
                                  Tile relay Material:{" "}
                                  <span>{data?.material.name}</span>
                                </p>
                              </Grid>
                              <Grid item lg={4} md={4}>
                                <p>
                                  Number of Tile Relay squares:{" "}
                                  <span>{data?.squares}</span>
                                </p>
                              </Grid>
                              <Grid item lg={4} md={4}>
                                <p>
                                  Number of Broken tiles:{" "}
                                  <span>{data?.broken_tiles}</span>
                                </p>
                              </Grid>
                            </>
                          )}
                          {data?.job_type.id === 2 && (
                            <>
                              <Grid item lg={4} md={4}>
                                <p>
                                  Existing Roof Material:{" "}
                                  <span>{data?.existing_material.name}</span>
                                </p>
                              </Grid>
                              <Grid item lg={4} md={4}>
                                <p>
                                  New Roof Material:{" "}
                                  <span>{data?.material.name}</span>
                                </p>
                              </Grid>
                              <Grid item lg={4} md={4}>
                                <p>
                                  Number of Existing sqs:{" "}
                                  <span>{data?.existing_squares}</span>
                                </p>
                              </Grid>
                              <Grid item lg={4} md={4}>
                                <p>
                                  Number of Layers: <span>{data?.layers}</span>
                                </p>
                              </Grid>
                              <Grid item lg={4} md={4}>
                                <p>
                                  Roof Pitch Degree:{" "}
                                  <span>{data?.pitch_degree}</span>
                                </p>
                              </Grid>
                              <Grid item lg={4} md={4}>
                                <p>
                                  Number of steep sqs:{" "}
                                  <span>{data?.steep_squares}</span>
                                </p>
                              </Grid>
                            </>
                          )}
                          {data?.job_type.id === 3 && (
                            <>
                              <Grid item lg={4} md={4}>
                                <p>
                                  Existing material:{" "}
                                  <span>{data?.existing_material.name}</span>
                                </p>
                              </Grid>
                              <Grid item lg={4} md={4}>
                                <p>
                                  Number of Layers: <span>{data?.layers}</span>
                                </p>
                              </Grid>
                              <Grid item lg={4} md={4}>
                                <p>
                                  Number of Existing Sqrs:{" "}
                                  <span>{data?.existing_material.name}</span>
                                </p>
                              </Grid>
                              <Grid item lg={4} md={4}>
                                <p>
                                  Roof Pitch Degree:{" "}
                                  <span>{data?.pitch_degree}</span>
                                </p>
                              </Grid>
                              <Grid item lg={4} md={4}>
                                <p>
                                  Number of Steep Sqrs:{" "}
                                  <span>{data?.existing_material.name}</span>
                                </p>
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </div>
                      {data?.plan_job_type?.id !== undefined &&
(
                          <>
                            <div className="HeaderDiv">
                              <h3>Added Roof Planes</h3>
                            </div>
                            <div className="data">
                              <Grid container columns={12} spacing={2}>
                                {data?.plan_job_type?.id === 1 && (
                                  <>
                                    <Grid item lg={4} md={4}>
                                      <p>
                                        Job Type:{" "}
                                        <span>{data?.plan_job_type?.name}</span>
                                      </p>
                                    </Grid>
                                    <Grid item lg={4} md={4}>
                                      <p>
                                        Tile relay Material:{" "}
                                        <span>{data?.plan_material?.name}</span>
                                      </p>
                                    </Grid>
                                    <Grid item lg={4} md={4}>
                                      <p>
                                        Number of Tile Relay squares:{" "}
                                        <span>{data?.plan_squares}</span>
                                      </p>
                                    </Grid>
                                    <Grid item lg={4} md={4}>
                                      <p>
                                        Number of Broken tiles:{" "}
                                        <span>{data?.plan_broken_tiles}</span>
                                      </p>
                                    </Grid>
                                  </>
                                )}
                                {data?.plan_job_type?.id === 2 && (
                                  <>
                                    <Grid item lg={4} md={4}>
                                      <p>
                                        Job Type:{" "}
                                        <span>{data?.plan_job_type.name}</span>
                                      </p>
                                    </Grid>
                                    <Grid item lg={4} md={4}>
                                      <p>
                                        Roof Overlay Material:{" "}
                                        <span>
                                          {data?.plan_existing_material.name}
                                        </span>
                                      </p>
                                    </Grid>
                                    <Grid item lg={4} md={4}>
                                      <p>
                                        New Roof Material:{" "}
                                        <span>{data?.plan_material.name}</span>
                                      </p>
                                    </Grid>
                                    <Grid item lg={4} md={4}>
                                      <p>
                                        Number of Existing Sq:{" "}
                                        <span>
                                          {data?.plan_existing_squares}
                                        </span>
                                      </p>
                                    </Grid>
                                    <Grid item lg={4} md={4}>
                                      <p>
                                        Number of Layers:{" "}
                                        <span>{data?.plan_layers}</span>
                                      </p>
                                    </Grid>
                                    <Grid item lg={4} md={4}>
                                      <p>
                                        Roof Pitch degree:{" "}
                                        <span>{data?.plan_pitch_degree}</span>
                                      </p>
                                    </Grid>
                                    <Grid item lg={4} md={4}>
                                      <p>
                                        Number of Steep Squares:{" "}
                                        <span>{data?.plan_steep_squares}</span>
                                      </p>
                                    </Grid>
                                  </>
                                )}
                                {data?.plan_job_type?.id === 3 && (
                                  <>
                                    <Grid item lg={4} md={4}>
                                      <p>
                                        Job Type:{" "}
                                        <span>{data?.plan_job_type?.name}</span>
                                      </p>
                                    </Grid>
                                    <Grid item lg={4} md={4}>
                                      <p>
                                        Number Of layers:{" "}
                                        <span>{data?.plan_layers}</span>
                                      </p>
                                    </Grid>
                                    <Grid item lg={4} md={4}>
                                      <p>
                                        Number of old sqs:{" "}
                                        <span>
                                          {data?.plan_existing_squares}
                                        </span>
                                      </p>
                                    </Grid>
                                    <Grid item lg={4} md={4}>
                                      <p>
                                        Existing Roof Material:{" "}
                                        <span>
                                          {data?.plan_existing_material.name}
                                        </span>
                                      </p>
                                    </Grid>
                                    <Grid item lg={4} md={4}>
                                      <p>
                                        Roof Pitch Degree:{" "}
                                        <span>{data?.plan_pitch_degree}</span>
                                      </p>
                                    </Grid>
                                    <Grid item lg={4} md={4}>
                                      <p>
                                        Number of steep sqs:{" "}
                                        <span>{data?.plan_steep_squares}</span>
                                      </p>
                                    </Grid>
                                  </>
                                )}
                              </Grid>
                            </div>
                            {data?.job_type.id === 3 && (
                              <>
                                <div className="HeaderDiv">
                                  <h3>New Roofing</h3>
                                </div>
                                <div className="data">
                                  <Grid container columns={12} spacing={2}>
                                    <Grid item lg={4} md={4}>
                                      <p>
                                        New Roof Material:{" "}
                                        <span>{data?.material?.name}</span>
                                      </p>
                                    </Grid>
                                    <Grid item lg={4} md={4}>
                                      <p>
                                        Number of Squares:{" "}
                                        <span>{data?.material?.name}</span>
                                      </p>
                                    </Grid>
                                  </Grid>
                                </div>
                              </>
                            )}
                          </>
                        )}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Section>
        </>
      )}
    </>
  );
};

export default SingleRoofingSolution;
