import styled from "styled-components";

export const LeadStyled = styled.div`
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-template-rows: minimum(260px);
    gap: 2rem;
    background: #f5f5f5;
    padding: 2rem 1rem;
    border-radius: 1rem;
    .list-name {
      display: flex;
      align-content: center;
      justify-content: space-around;
      font-weight: bold;
      text-transform: capitalize;
      display: none;
      padding: 1rem;
      background: #f6f6f6;
    }
  }
  .btn_see_more {
    background: #f6f6f6;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    min-height: 260px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    box-shadow: 5px 5px 6px #dadada, -5px -5px 6px #ffffff;
    color: #0ea474;
    font-size: 1.3rem;
  }
  .paginateContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 1rem 0;
  }
  .pageLinkClassName {
    padding: 1rem;
  }
  .prevPaginate,
  .nextPaginate {
    padding: 0 0.5rem;
    cursor: pointer;
    color: #0ea474;
    font-weight: bold;
  }
  .pageClassName {
    margin: 0 0.3rem;
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #888;
    background: #58c5a230;
    cursor: pointer;
  }
  .activePaginate {
    color: #fff;
    background: #58c5a2;
  }
  .disabledPaginate {
    color: #999;
    cursor: none;
  }
  .btn_conatiner {
    padding: 1rem 0;
    text-align: center;
    position: relative;
    .btn {
      background: #58c5a2;
      color: #fff;
      position: relative;
      top: 0;
      display: inline-block;
      left: 0;
      z-index: 2;
      border-radius: 1rem;
      padding: 0.5rem 1rem;
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 0.5px;
        width: 500px;
        display: inline-block;
        background: #58c5a2;
        z-index: -1;
      }
    }
  }
`;
