import { Container } from "@mui/material";
import React from "react";
import styled from "styled-components";
import headerContract from "../../assets/headerContract.png";

const PageOneContract = ({ cost }) => {
  return (
    <>
      <ContPageOneStyled>
        <div className="imageHeader">
          <img src={headerContract} alt="pdf" />
        </div>
        <Container>
          <div className="p">
            <p>
              This disclosure shall be printed on the front page or cover page
              of every solar energy contract for the installation of a solar
              energy system on a residential building. The TOTAL COST for the
              solar energy system (including financing and energy / power cost
              if applicable) is: $ {cost}
            </p>
            <p>
              To make a complaint against a contractor who installs this system
              and/or the home improvement salesperson who sold this system,
              contact the Contractors State License Board (CSLB) through their
              website at www.cslb.ca.gov (search: “complaint form”), by
              telephone at 800‐321‐CSLB (2752), or by writing to P.O. Box 26000,
              Sacramento, CA 95826.
            </p>

            <p>
              To make a complaint against a contractor who installs this system
              and/or the home improvement salesperson who sold this system,
              contact the Contractors State License Board (CSLB) through their
              website at www.cslb.ca.gov (search: “complaint form”), by
              telephone at 800‐321‐CSLB (2752), or by writing to P.O. Box 26000,
              Sacramento, CA 95826.
            </p>
            <p>
              To make a complaint against a contractor who installs this system
              and/or the home improvement salesperson who sold this system,
              contact the Contractors State License Board (CSLB) through their
              website at www.cslb.ca.gov (search: “complaint form”), by
              telephone at 800‐321‐CSLB (2752), or by writing to P.O. Box 26000,
              Sacramento, CA 95826.
            </p>
          </div>
        </Container>
      </ContPageOneStyled>
    </>
  );
};

const ContPageOneStyled = styled.div`
  .imageHeader {
    /* height: 40vh; */
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }
  .p {
    padding: 3rem;

    background: #eee;
    p {
      margin: 2rem 0;
      letter-spacing: 1px;
      line-height: 1.8;
    }
  }
`;
export default PageOneContract;
