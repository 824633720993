import React from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  useJsApiLoader,
  Circle,
} from "@react-google-maps/api";

const mapStyles = {
  width: "100%",
  height: "400px",
};

const InstallerMaps = ({ singlead, installerId, allInstallers }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyCrr_bBNHxQ0lZKadmJYpqS2vOebczD1m4",
    libraries: ["places"],
  });
  const findInstaller = (installerId) => {
    return allInstallers?.data?.find(
      (installer) => installer.id === installerId
    );
  };

  const findLisence = (arr, state) => {
    let myLicense = arr?.find((liecense) => liecense.state === state);
    console.log("arr", arr, "state", state);
    console.log("myLicense", myLicense);
    return myLicense;
  };
  const [installerLisence, setInstallerLisence] = React.useState();
  React.useEffect(() => {
    let check = async () => {
      let installerInfo = await findInstaller(installerId);
      let installerLicense = await findLisence(
        installerInfo?.licenses,
        singlead?.state.name
      );
      setInstallerLisence(installerLicense);
    };
    check();
  }, [installerId]);
  //   console.log("singlead", singlead);
  //   console.log("findInstaller", findInstaller(installerId));
  //   console.log("allInstallers", allInstallers);
  //   console.log("installerLicense", installerLicense);
  let coordinates = {
    lat: installerLisence?.lat,
    lng: installerLisence?.lng,
  };

  if (!isLoaded) {
    return <p>loading...</p>;
  }
  return (
    <div>
      {installerLisence ? (
        <>
          <GoogleMap
            mapContainerStyle={mapStyles}
            zoom={25}
            center={coordinates}
            mapTypeId={"satellite"}
          >
            <Circle
              center={coordinates}
              radius={Number(installerLisence?.max_radius_in_miles) * 100.6}
              onClick={(e) => {
                console.log("eeeeeeee", e.latLng.lat());
              }}
              fillColor="#FF0000"
            ></Circle>
          </GoogleMap>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default React.memo(InstallerMaps);
