import {
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { GOOGLE_API_URL } from "../../../../API/Api";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import swal from "sweetalert";

const GoogleCalender = () => {
  //open modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //get All EVENTS DATA

  const [events, setEvents] = useState([]);

  //naigate
  const Navigate = useNavigate();

  //get the search
  const { search } = useLocation();

  //get the token
  const token = localStorage.getItem("token");
  const GoolgeToken = JSON.parse(sessionStorage.getItem("google-token"));
  //statr data
  console.log("GoolgeToken",GoolgeToken)
  const [data, setData] = useState({
    token: GoolgeToken ? GoolgeToken : null,
    summary: "",
    description: "",
    start: null,
    end: null,
  });

  console.log(events, "events");

  useEffect(() => {
    const urlParams = Object.fromEntries([...new URLSearchParams(search)]);
    if (search) {
      sessionStorage.setItem("google-token", JSON.stringify(urlParams));
    }
  }, [search]);

  // get the evente from

  useEffect(() => {
    const GetEvets = async (data) => {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      };
      await axios({
        method: "POST",
        baseURL: `${GOOGLE_API_URL}/get-all`,
        data: data,
        headers: headers,
      })
        .then((res) => {
          setEvents(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    GetEvets(data);
  }, [search]);

  //Auth the google calnder
  const handelAuth = async () => {
    const headers = {
      Accept: "application/json",
    };
    await axios
      .get(`${GOOGLE_API_URL}/oauth`, headers)
      .then((data) => {
        console.log(data.data.data);
        Navigate(`//${data.data.data}`);
        sessionStorage.removeItem("google-token");
      })
      .catch((e) => {
        console.log(e.data);
      });
  };

  //Add Google Event

  const AddGoogleEvent = async (e) => {
    e.preventDefault();

    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    await axios({
      method: "POST",
      baseURL: `${GOOGLE_API_URL}/create`,
      data: data,
      headers: headers,
    })
      .then((res) => {
        console.log(res.data);
        swal({
          icon: "success",
          title: "Event Added SucessFully ",
        });
      })
      .catch((err) => {
        console.log(err);
        swal({
          icon: "error",
          title: "There is proplem ",
        });
      });
  };

  return (
    <>
      <StyledButton>
        <Button onClick={handelAuth}>Sync With Google</Button>
      </StyledButton>

      {GoolgeToken && (
        <>
          <GoogleCalenderStyled>
            <h3>Google Calender</h3>
            <EventButton onClick={handleOpen}>Add Events</EventButton>
          </GoogleCalenderStyled>
          {/* // google table */}

          {events.length != 0 && (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell width={300}>Summary</TableCell>
                    <TableCell width={400}>Description</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                    {/* <TableCell>Actions</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {events?.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.summary}
                      </TableCell>
                      <TableCell>{row.description}</TableCell>
                      <TableCell>
                        {row.start ? (
                          <>
                            <span>
                              {new Date(row.start).toLocaleDateString("en-GB")}{" "}
                              {""}
                            </span>
                            <span>
                              {new Date(row.start).toLocaleTimeString("en-GB")}
                            </span>
                          </>
                        ) : (
                          "There is no date "
                        )}
                      </TableCell>
                      <TableCell>
                        {row.end ? (
                          <>
                            <span>
                              {new Date(row.end).toLocaleDateString("en-GB")}{" "}
                              {""}
                            </span>
                            <span>
                              {new Date(row.end).toLocaleTimeString("en-GB")}
                            </span>
                          </>
                        ) : (
                          "There is no date "
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <BoxModal>
          <h2>Add Your Event</h2>
          <form onSubmit={AddGoogleEvent}>
            <Grid container columns={18} spacing={2}>
              <Grid item xs={18} mb={2}>
                <TextField
                  fullWidth
                  label="Summary"
                  type="text"
                  required
                  value={data.summary}
                  onChange={(e) =>
                    setData({ ...data, summary: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={18} mb={2}>
                <TextField
                  fullWidth
                  required
                  label="Description"
                  type="text"
                  value={data.description}
                  onChange={(e) =>
                    setData({ ...data, description: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={18} mb={2}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    renderInput={(props) => (
                      <TextField fullWidth required {...props} />
                    )}
                    label="Start Date"
                    value={data.start}
                    onChange={(e) =>
                      setData({ ...data, start: e.toISOString() })
                    }
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={18} mb={2}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    renderInput={(props) => (
                      <TextField fullWidth required {...props} />
                    )}
                    label="End Date"
                    value={data.end}
                    onChange={(e) => setData({ ...data, end: e.toISOString() })}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Button variant="contained" fullWidth type="submit">
              Add Event
            </Button>
          </form>
        </BoxModal>
      </Modal>
    </>
  );
};

const StyledButton = styled.div`
  margin: 1rem 0;
  text-align: center;
  width: 100%;
  Button {
    padding: 1rem 2rem;
    border-radius: 1rem;
    background: #e04a4a;
    color: #fff;
    &:hover {
      background: #962b2b;
    }
  }
`;

const BoxModal = styled.div`
  background: #fff;
  border-radius: 1rem;
  padding: 3rem;
  width: 70%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const EventButton = styled(Button)`
  && {
    width: 250px;
    /* margin: 2rem auto; */
    display: block;
    background: #0c8e5c;
    padding: 1rem 2rem;
    border-radius: 1rem;
    color: #fff;
    &:hover {
      background: #0a6a46;
    }
  }
`;

const GoogleCalenderStyled = styled.div`
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 2rem;
  border-radius: 1rem;
  background: #eeeeee;
  h3 {
    color: #888;
  }
`;
export default GoogleCalender;
