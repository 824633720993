
import { INSTALLER_ADDER_ID } from "../Actions/AddersAction";
const inittialState = {
  addersID: [],
};
const AddersReducer = (state = inittialState, action) => {
  switch (action.type) {
    case INSTALLER_ADDER_ID:
      return {
        ...state,
        adders: action.payload,
      };

    default:
      return { ...state };
  }
};
export default AddersReducer;
