import { useState } from "react";
import ChooseLead from "./ChooseLead";
import FormForLead from "./FormForLead";
import Confimation from "./Confimation";
import { Paper, Stepper, Step, Typography, StepLabel } from "@mui/material";
import styled from "styled-components";

//name of step
const steps = ["Choose customer type", "Create New Lead"];

const AddLeadForm = () => {
  //start a active step
  const [activeStep, setActiveStep] = useState(1);

  const nextStep = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);
  const backStep = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const next = (data) => {
    nextStep();
  };

  //start a form to add
  const Form = () =>
    activeStep === 0 ? <ChooseLead /> : <FormForLead next={next} />;

  return (
    <>
      <MainLayout>
        <Paper className="paper">
          <Typography variant="h4" align="center">
            Add Lead
          </Typography>
          <Stepper activeStep={activeStep} className="stepper">
            {steps.map((step) => (
              <Step key={step}>
                <StepLabel>{step}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length ? <Confimation /> : <Form />}
        </Paper>
      </MainLayout>
    </>
  );
};

const MainLayout = styled.main`
  padding: 5rem;
  @media (max-width: 768px) {
    padding: 1rem;
  }
  .paper {
    padding: 1.5rem;
  }
`;
export default AddLeadForm;
