import { NavLink } from "react-router-dom";
import styled from "styled-components";
import {
  SupervisedUserCircle,
  CalendarToday,
  TaskAlt,
  Article,
  Groups,
  AppRegistration,
  Report,
} from "@mui/icons-material";
import CurrencyBitcoinIcon from "@mui/icons-material/CurrencyBitcoin";
import { useSelector } from "react-redux";

const NavBarRouts = () => {
  const { authLogIn, token } = useSelector((state) => state.auth);

  if (!authLogIn && token === null) return null;
  return (
    <>
      <nav>
        <FlexUL>
          <li>
            <NavLink to="leads">
              <SupervisedUserCircle />
              Customers
            </NavLink>
          </li>
          <li>
            <NavLink to="calendar">
              <CalendarToday />
              Calendar
            </NavLink>
          </li>
          {/* <li>
            <NavLink to="tasks">
              <TaskAlt />
              Tasks
            </NavLink>
          </li> */}
          <li>
            <NavLink to="goals">
              <Article /> Achievements
            </NavLink>
          </li>
          <li>
            <NavLink to="meetings">
              <Groups /> Meetings
            </NavLink>
          </li>
          <li>
            <NavLink to="training">
              <AppRegistration />
              Training
            </NavLink>
          </li>
          {/* <li>
            <NavLink to="reports">
              <Report /> Report
            </NavLink>
          </li> */}
          <li>
            <NavLink to="coins">
              <CurrencyBitcoinIcon /> Coins
            </NavLink>
          </li>
        </FlexUL>
      </nav>
    </>
  );
};

const FlexUL = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 0;
  border-bottom: 1px solid #99999955;
  /* overflow-x: auto; */
  /* overflow-y: hidden; */
  gap: 1.25rem;
  /* padding-bottom: 2rem; */
  /* overflow-y: auto; */
  /* min-height: 5rem; */

  li {
    a {
      padding: 1rem 0;
      color: #999999;
      transition: all 0.3s ease-out;
      font-weight: 600;
      display: flex;
      align-items: center;
      position: relative;
      &:hover {
        color: #0dbf7c;
      }
      &.active {
        color: #0dbf7c;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          top: 120%;
          left: 0;
          width: 110%;
          height: 5px;
          border-radius: 1rem;
          background: #0dbf7c;
        }
      }
      svg {
        margin-right: 5px;
      }
      svg {
        @media screen and (max-width: 768px) {
          font-size: 2.8vw;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    font-size: 1.8vw;
    /* overflow-x: auto; */
    /* height: 95px; */
  }
  @media screen and (max-width: 768px) {
    /* font-size: 2.8vw; */
    overflow-x: auto;
    height: 95px;
  }
`;

export default NavBarRouts;
