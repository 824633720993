import { PushPin } from "@mui/icons-material";
import { Button, Modal, TextField } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  AddQuoteAction,
  LoadProjectAction,
  SendPrequal,
  SendPullSataus,
  // SubmitCreditAction,
} from "../../../Redux/Actions/sunlightActions";
import Typography from "@mui/material/Typography";
import { MAIN_URL } from "../../../API/Api";
import swal from "sweetalert";
import { ShowLoading, HideLoading } from "../../../Redux/Actions/loadingAction";
import Lottie from "react-lottie";
import animationData from "../loader/98695-loader.json";
/////////////////////////////////////////////

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#fff",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const ProjectsList = ({ LeadId }) => {
  //state for toggle button
  //const [openButton, setOpenButton] = useState(false);
  // const [open, setOpen] = useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  // const [data, setData] = useState({
  //   loanAmount: "",
  // });
  //start to add a project in the leads
  const dispatch = useDispatch();
  const handlePrequal = (id) => {
    dispatch(SendPrequal(id));
    console.log("done");
  };

  // const handlePullStatus = (id) => {
  //   dispatch(SendPullSataus(id));
  // };
  // const handleAddQuote = (ID, loanAmout) => {
  //   dispatch(AddQuoteAction(ID, loanAmout));
  //   setLoanAmount("");
  // };
  // const hadelSubmitCredit = (id) => {
  //   dispatch(SubmitCreditAction(id));
  // };
  useEffect(() => {
    dispatch(LoadProjectAction(LeadId));
  }, [LeadId]);

  const sendP = (id) => {
    dispatch(SendPullSataus(id));
  };

  //get all projects

  const { allProjects } = useSelector((state) => state.sunlight);
  const [loader, setLoader] = React.useState(false);
  //const { pullStatus } = useSelector((state) => state.sunlight);
  // TRY
  const [results, setResults] = React.useState("");
  const [name, setName] = React.useState("Ahmed");
  console.log("name", name);
  console.log("results", results);
  let finalResult = results?.data?.projects || [""];
  const [thisResult, setResult] = React.useState({
    stage: "",
    isCreditAuth: "",
    approvedForPayments: "",
    isAch: "",
    requestedLoanAmount: "",
    selfMinLoanAmount: "",
  });
  const lastResult = finalResult.map((e) => {
    thisResult.stage = e.stage;
    thisResult.isCreditAuth = e.isCreditAuthorized;
    thisResult.approvedForPayments = e.approvedForPayments;
    thisResult.isAch = e.isACH;
    thisResult.requestedLoanAmount = e.requestedLoanAmount;
    thisResult.selfMinLoanAmount = e.slfProductMinLoanAmount;
  });
  console.log("thisResult", thisResult);

  // axios
  const token = localStorage.getItem("token");

  // Modal for get Results
  const [openResult, setOpenResult] = React.useState(false);
  const handleOpenForResult = () => setOpenResult(true);
  const handleCloseForResult = () => setOpenResult(false);
  // const deleteData= ()=>{
  //   setThisResult("")
  // }
  const initialState = {
    stage: "",
    isCreditAuth: "",
    approvedForPayments: "",
    isAch: "",
    requestedLoanAmount: "",
    selfMinLoanAmount: "",
  };

  return (
    <>
      <TableConatiner className="TableConatiner">
        <Box className="title-Grid">
          <h4>Project ID</h4>
          <h4>Contract Amount</h4>
          <h4>Status</h4>
          <h4>Prequal App</h4>
          <h4>Credit App</h4>
          {/* <h4>Submit Credit</h4> */}
          {/* <h4>Refresh status</h4> */}
        </Box>
        <div className="ProjectMainConatiner">
          {allProjects[0] != "" &&
            allProjects?.map((project) => (
              <Box className="Project-Grid" key={project.id}>
                <div className="project-name">
                  <p>{project.project_id}</p>
                </div>
                <div className="project-name">
                  <p>$ {Math.ceil(project.loan_amount)}</p>
                </div>
                <div className="status">
                  <div>
                    {/* <span>
                      <PushPin />
                    </span> */}
                    {/* {() => sendP(project.id)} */}
                    <GetStatus
                      onClick={() => {
                        thisResult.stage = "";
                        thisResult.isCreditAuth = "";
                        thisResult.approvedForPayments = "";
                        thisResult.isAch = "";
                        thisResult.requestedLoanAmount = "";
                        thisResult.selfMinLoanAmount = "";
   
                        handleOpenForResult();
                        setLoader(true);
                        axios
                          .get(
                            `${MAIN_URL}/api/v1/sunlight/pull-status/${project.project_id}`,
                            {
                              headers: {
                                Authorization: `Bearer ${token}`,
                              },
                            }
                          )
                          .then(function (response) {
                            setLoader(false);
                            // handle success
                            // console.log(response);
                            setResults(response);
                          })
                          .catch(function (error) {
                            setLoader(false);
                            // handle error
                            console.log(error);
                            swal({
                              title: " error ",
                              text: " There is a problem ",
                              icon: "error",
                              button: "OK",
                            });
                          })
                          .then(function () {
                            // always executed
                          });
                      }}
                    >
                      Get Status
                    </GetStatus>
                    {/* <span>{results==="error"? <p>error</p>:results}</span> */}
                  </div>
                </div>
                <div className="prequal">
                  <Button
                    disabled={project.is_prequaled === 0 ? false : true}
                    onClick={() => {
                      handlePrequal(project.id);
                    }}
                  >
                    Send Prequal App
                  </Button>
                </div>

                <div className="quote">
                  <Button
                    disabled={project.is_credit_check === 0 ? false : true}
                    className="quote_btn"
                    onClick={() => dispatch(AddQuoteAction(project.id))}
                  >
                    Send Credit App
                  </Button>
                </div>
                {/* <div className="credit">
                  <Button
                    className="credit_btn"
                    onClick={() => {
                      hadelSubmitCredit(project.project_id);
                    }}
                  >
                    Submit Credit
                  </Button>
                </div> */}

                {/* <div className="pull">
                    <Button
                      className="pull_btn"
                      onClick={() => {
                        handlePullStatus(project.project_id);
                      }}
                    >
                      Pull Status
                    </Button>
                  </div> */}
              </Box>
            ))}
        </div>
      </TableConatiner>
      <Modal
        open={openResult}
        onClose={handleCloseForResult}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {loader === true ? (
              <p>
                {" "}
                <Lottie options={defaultOptions} height={40} width={80} />
              </p>
            ) : (
              <>
              <p>
              Status :{" "}
              {thisResult.stage === undefined ? <p></p> : thisResult.stage}
            </p>
            <p>
              Approved For Payments :{" "}
              {thisResult.approvedForPayments === undefined ? (
                <p></p>
              ) : (
                thisResult.approvedForPayments.toString()
              )}
            </p>
            <p>
              Is Ach :{" "}
              {thisResult.isAch === undefined ? (
                <p></p>
              ) : (
                thisResult.isAch.toString()
              )}
            </p>
            <p>
              Is Credit Auth :{" "}
              {thisResult.isCreditAuth === undefined ? (
                <p></p>
              ) : (
                thisResult.isCreditAuth.toString()
              )}
            </p>
            <p>
              Requested Loan Amount :{" "}
              {thisResult.requestedLoanAmount === undefined ? (
                <p></p>
              ) : (
                thisResult.requestedLoanAmount
              )}
            </p>
            <p>
              Self MinLoan Amount :{" "}
              {thisResult.selfMinLoanAmount === undefined ? (
                <p></p>
              ) : (
                thisResult.selfMinLoanAmount
              )}
            </p>
            </>
            )}

          </Typography>
        </Box>
      </Modal>
      {/* <button onClick={deleteData}>dele</button> */}
    </>
  );
};
const TableConatiner = styled.div`
  .title-Grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    text-align: center;
    @media screen and (max-width: 1200px) {
      display: none;
    }
    h4 {
      font-weight: normal;
      font-size: 1.1rem;
      color: #197553;
      letter-spacing: 1.5px;
    }
  }
  .ProjectMainConatiner {
    display: grid;
    margin-top: 1rem;
    @media screen and (max-width: 1200px) {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      place-items: center;
      gap: 16px;
    }
    .Project-Grid {
      display: grid;

      grid-template-columns: repeat(5, 1fr);
      margin: 0.3rem 0;
      border-radius: 1rem;
      background: #fff;
      text-align: center;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      place-items: center;

      button {
        margin: 0;
        padding: 0;
      }
      & > div {
        padding: 0.8rem;
      }
      .project-name {
        letter-spacing: 1.5px;
        color: #555;
        font-size: 0.9rem;
        span {
          font-size: 0.8rem;
        }
      }
      .prequal {
        button {
          background: #82cac2;
          color: #fff;
          padding: 0.2rem 1.5rem;
          border-radius: 2rem;
          &.Mui-disabled {
            background: #acacac;
          }
        }
      }
      .status {
        font-size: 0.9rem;
        color: #666;
        div {
          display: flex;
          align-items: center;
          svg {
            font-size: 0.9rem;
            margin-right: 0.5rem;
          }
        }
      }
      .pull {
        .pull_btn {
          background: #4baee7;
          color: #fff;
          padding: 0.2rem 1rem;
          border-radius: 2rem;
        }
      }
      .quote {
        position: relative;
        .quote_form {
          position: absolute;
          top: -150%;
          left: 0;
          width: 100%;
          height: 100%;
          background: #fff;
        }
        .quote_btn {
          background: #f0c679;
          color: #fff;
          padding: 0.2rem 1rem;
          border-radius: 2rem;
          &.Mui-disabled {
            background: #acacac;
          }
        }
      }
      .credit {
        .credit_btn {
          background: #2f2f2f;
          color: #fff;
          padding: 0.2rem 1rem;
          border-radius: 2rem;
        }
      }
      @media screen and (max-width: 1200px) {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      }
    }
  }
`;
const GetStatus = styled.button`
  background-color: black;
  color: white;
  border: none;
  width: 130px;
  height: 1.8rem;
  border-radius: 15px;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
`;

export default ProjectsList;
