import {
  Delete,
  Email,
  EmailRounded,
  NoteAlt,
  PictureAsPdf,
  Send,
} from "@mui/icons-material";
import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import LoadingSoultion from "../../components/loading/loadingSolution";
import { singleLeadAction } from "../../Redux/Actions/singleLeadAction";
import {
  createContractAction,
  DeleteSolutionAction,
  sendMailPropsals,
} from "../../Redux/Actions/solutionsActions";
import {
  CreateRoofingScontract,
  DeleteRoofingContract,
  SendRoofingContract,
} from "../../Redux/Actions/addRoofingAction";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const PropsalConatiner = ({ opportunities, LeadId }) => {
  // const opportunity_id = "";
  // dispatch
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.proposal);
  const { singlead } = useSelector((state) => state.leads);
  const stand_alone_roof_proposals = singlead?.stand_alone_roof_proposals;
  const location = useLocation();
  LeadId = location.pathname.split("/")[2];

  console.log("singlead", singlead);
  const YearFn = (date) => {
    return new Date(date).getFullYear();
  };
  const DayFn = (date) => {
    const day = new Date(date).getDate();
    if (day < 10) {
      return `0${day}`;
    } else {
      return day;
    }
  };
  const MonthFn = (date) => {
    const month = new Date(date).getMonth() + 1;
    if (month < 10) {
      return `0${month}`;
    } else {
      return month;
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <OpportunitiesStyled>
        <div className="container">
          <Button variant="contained" className="btn_soltion">
            <Link to={`/crm/leads/${LeadId}`}> Create Solution</Link>
          </Button>
          {singlead.opportunities?.length !== 0 ? (
            <>
              <h2>Solar Solutions</h2>
              <hr></hr>
              <div className="buttons">
                {singlead.opportunities?.map((prop) => (
                  <div key={prop.id}>
                    <div className="prpo_data_flex">
                      <div className="prposals">
                        Solution
                        {prop.is_finance === 1 ? (
                          <p>
                            {" "}
                            <span>
                              {" "}
                              {prop.financial_institution
                                ? prop.financial_institution
                                : "Sunlight"}
                            </span>{" "}
                          </p>
                        ) : (
                          <p>
                            {" "}
                            <span>Cash</span>
                          </p>
                        )}
                      </div>
                      <div className="prposals_Data">
                        {/* Edited */}
                        <Link
                          className="opportunity"
                          to={`single-solar/${prop.id}`}
                        >
                          <h3>Solution Details: Solar </h3>
                        </Link>
                        <p className="date">
                          {MonthFn(prop.opportunity_date_time) +
                            "-" +
                            DayFn(prop.opportunity_date_time) +
                            "-" +
                            YearFn(prop.opportunity_date_time)}
                        </p>
                        <div className="oppIcons">
                          <a href={prop.pdf_path} target="_blank">
                            <PictureAsPdf className="pdf" />
                          </a>
                          <a
                            className="mail"
                            onClick={() => dispatch(sendMailPropsals(prop.id))}
                          >
                            <EmailRounded />
                          </a>
                          <a
                            onClick={async () => {
                              await dispatch(DeleteSolutionAction(prop.id));
                              await dispatch(singleLeadAction(LeadId));
                            }}
                          >
                            <Delete className="delete" />
                          </a>
                        </div>
                        {prop.has_contract && (
                          <Button disabled={true} className="send hasContract">
                            has Contract
                          </Button>
                        )}

                        {!prop.has_contract && (
                          <Button
                            className="send"
                            onClickCapture={async () => {
                              await dispatch(
                                createContractAction({
                                  opportunity_id: prop.id,
                                })
                              );
                              await dispatch(singleLeadAction(LeadId));
                            }}
                            // onClick={async () => {
                            //   if (prop.financial_institution === "goodleap") {
                            //     if (
                            //       singlead.financial_profile == null ||
                            //       singlead.financial_profile?.income == null ||
                            //       singlead.financial_profile?.job_title ==
                            //         null ||
                            //       singlead.financial_profile?.ssn == null ||
                            //       singlead.financial_profile?.dob == null
                            //     ) {
                            //       console.log("financial_profile", prop);
                            //       handleOpen();
                            //     } else {
                            //       await dispatch(
                            //         createContractAction({
                            //           opportunity_id: prop.id,
                            //         })
                            //       );
                            //       await dispatch(singleLeadAction(LeadId));
                            //     }
                            //   } else {
                            //     await dispatch(
                            //       createContractAction({
                            //         opportunity_id: prop.id,
                            //       })
                            //     );
                            //     await dispatch(singleLeadAction(LeadId));
                            //   }
                            // }}
                          >
                            create Contract
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <br />
          )}
          {/* Roofing Solution */}
          {singlead?.stand_alone_roof_proposals?.length > 0 ? (
            <>
              <h2>Roofing Solutions</h2>
              <hr></hr>
              <div className="buttons">
                {singlead?.stand_alone_roof_proposals?.map((prop) => (
                  <div key={prop.id}>
                    <div className="prpo_data_flex">
                      <div className="prposals">
                        Solution
                        {/* <p>Status: </p> */}
                      </div>
                      <div className="prposals_Data">
                        {/* Edited */}
                        {/* <Link className="opportunity" to={`single-roofing/:id/:id${prop.id}`}> */}
                        <Link
                          className="opportunity"
                          to={`single-roofing/${prop.id}`}
                        >
                          <h3>Solution Details: Roofing </h3>
                        </Link>
                        <p className="date">
                          {new Date(prop.created_at).toLocaleDateString(
                            "en-US",
                            {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }
                          )}
                        </p>
                        <div className="oppIcons">
                          <a href={prop.pdf_path} target="_blank">
                            <PictureAsPdf className="pdf" />
                          </a>
                          <a
                            className="mail"
                            onClick={() =>
                              dispatch(SendRoofingContract(prop.id))
                            }
                          >
                            <EmailRounded />
                          </a>
                          <a
                            onClick={async () => {
                              await dispatch(DeleteRoofingContract(prop.id));
                              await dispatch(singleLeadAction(LeadId));
                            }}
                          >
                            <Delete className="delete" />
                          </a>
                        </div>
                        {prop.has_contract && (
                          <Button disabled={true} className="send hasContract">
                            has Contract
                          </Button>
                        )}

                        {!prop.has_contract && (
                          <Button
                            className="send"
                            onClick={async () => {
                              await dispatch(CreateRoofingScontract(prop.id));
                              await dispatch(singleLeadAction(LeadId));
                            }}
                          >
                            create Contract
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <br />
          )}
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              align="center"
            >
              GoodLeap Requirements
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2 }}
              align="center"
            >
              <p>You need to Finish Financial Profile First</p>
              <p>Incom, Date Of birth , SSN , Job Title </p>
            </Typography>
          </Box>
        </Modal>
      </OpportunitiesStyled>
      {isLoading && <LoadingSoultion />}
    </>
  );
};

const OpportunitiesStyled = styled.div`
  font-family: "Poppins", sans-serif;
  padding: 24px;
  width: "100%";
  .btn_soltion {
    border-radius: 2rem;
    padding: 0.5rem 1.5rem;
    margin: 1rem 0;
    border-bottom: 1px solid #999;

    a {
      color: #fff;
    }
  }
  .buttons {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 1rem;
    .prpo_data_flex {
      display: flex;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
      border-radius: 1rem;
      margin: 1rem 0;
      transition: all 0.3s;
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0px 2px 9px 1px #118ff555;
      }
      .prposals {
        padding: 2rem;
        background: #f6f6f6;
        color: #777;
        font-weight: 700;
        font-size: 1.1rem;
        box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
        border-radius: 1rem;
        p {
          color: black;
          font-size: 1rem;
          padding: 0 !important;
          margin: 0 !important;
          font-weight: 300;
        }
        span {
          color: green;
          font-weight: 500;
          text-transform: capitalize;
        }
      }
      .prposals_Data {
        padding: 0.5rem 1rem;
        color: #888;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h3 {
          color: #6f7d8a;
        }
        .date {
          font-size: 0.8rem;
        }
        .oppIcons {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          svg {
            margin: 0.5rem 0 0 0;
            font-size: 1.4rem;
            margin-right: 1.5rem;
            cursor: pointer;
          }
          .pdf {
            color: #45c8f1;
          }
          .download {
            color: #777;
          }
          .mail {
            color: #118ff5;
          }
          .delete {
            color: #cc5555;
          }
        }
        .send {
          color: #fff;
          font-size: 0.7rem;
          text-transform: capitalize;
          background: #68b2eeee;
          border-radius: 0.8rem;
          &.hasContract {
            background: #999;
          }
        }
      }
    }
  }
`;
export default PropsalConatiner;
