import { configureStore } from "@reduxjs/toolkit";
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from "@reduxjs/toolkit/query";
import { leadsApi } from "./toolKit/leads";
import rootRducer from "./Reducers";
import { addersApi } from "./toolKit/adders";
import { coinsApi } from "./toolKit/coins";
import { AddersFormApi } from "./toolKit/addersForm";
import { leadsBankApi } from "./toolKit/leadsBank";
import { distributor } from "./toolKit/distributor";
import { EnfinApi } from "./toolKit/enfin";
import { salesForm } from "./toolKit/salesForm";
import { templateApi } from "./toolKit/template";
import { salesAddersApi } from "./toolKit/salesAdders";

export const store = configureStore({
  // reducer: {
  //   [leadsApi.reducerPath]: leadsApi.reducer,
  // },
  reducer: rootRducer,
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      addersApi.middleware,
      coinsApi.middleware,
      AddersFormApi.middleware,
      leadsApi.middleware,
      leadsBankApi.middleware,
      distributor.middleware,
      EnfinApi.middleware,
      salesForm.middleware,
      templateApi.middleware,
      salesAddersApi.middleware
    ),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
