import axios from "axios";
import React from "react";
import { GET_ADDERSBY_INSTALLER } from "../../API/Api";
import swal from "sweetalert";
import { ShowLoading, HideLoading } from "./loadingAction";

// TYPES
export const INSTALLER_ADDER_ID = "INSTALLER_ADDER_ID";

// Get Adder by ID
export const getInstallerAdderById = (id) => async (dispatch) => {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  dispatch(ShowLoading());

  await axios({
    method: "GET",
    baseURL: `${GET_ADDERSBY_INSTALLER}/${id}`,
    headers: headers,
  })
    .then((res) => {
      dispatch(HideLoading());
      dispatch({
        type: INSTALLER_ADDER_ID,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(HideLoading());
      console.log(err.data);
    });
};

