import axios from "axios";
import Swal from "sweetalert2";
import {
  get_mosaic_offers,
  get_mosaic_payments,
  mosiac_create_application,
  mosiac_share_application,
  mosiac_get_timeLine,
  mosiac_get_status,
  mosiac_check_offer,
  mosiac_get_offer_notFoud,
  mosiac_submit_offer_notFoud,
  mosiac_get_offer_found,
  mosiac_submit_offer_found,
  mosaic_offer_status,
  mosaic_downlaod_contract_document,
} from "../../API/Api";
import { HideLoading, ShowLoading } from "./loadingAction";

export const MOSAIC_GET_ALL_OFFERS = "MOSAIC_GET_ALL_OFFERS";
export const MOSAIC_GET_PAYMENTS = "MOSAIC_GET_PAYMENTS";
export const MOSIAC_ALL_DATA = "MOSIAC_ALL_DATA";
export const MOSIAC_CREATE_PROJECT = "MOSIAC_CREATE_PROJECT";
export const MOSIAC_SHARE_APPLICATION = "MOSIAC_SHARE_APPLICATION";
export const MOSIAC_GET_STATUS = "MOSIAC_GET_STATUS";
export const MOSIAC_GET_TIMELINE = "MOSIAC_GET_TIMELINE";
export const MOSIAC_CHECK_OFFER = "MOSIAC_CHECK_OFFER";
export const MOSIAC_GET_OFFERNOTFOUND = "MOSIAC_GET_OFFERNOTFOUND";
export const MOSIAC_SUBMIT_OFFERNOTFOUND = "MOSIAC_SUBMIT_OFFERNOTFOUND";
export const MOSIAC_SUBMIT_OFFERFOUND = "MOSIAC_SUBMIT_OFFERFOUND";
export const MOSIAC_GET_OFFERFOUND = "MOSIAC_SUBMIT_OFFERNOTFOUND";
export const MOSIAC_CHECK_OFFER_STATUS = "MOSIAC_CHECK_OFFER_STATUS";
export const MOSIAC_DOWNLOAD_CONTRACT_DOCUMENT =
  "MOSIAC_DOWNLOAD_CONTRACT_DOCUMENT";

export const getMosiacOffers = () => async (dispatch) => {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  dispatch(ShowLoading());

  await axios({
    method: "GET",
    baseURL: `${get_mosaic_offers}`,
    headers: headers,
  })
    .then((res) => {
      dispatch(HideLoading());
      dispatch({
        type: MOSAIC_GET_ALL_OFFERS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(HideLoading());

      console.log(err.data);
      const message = err?.response?.data?.error;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${message}`,
      });
    });
};

export const getMosiacPayments = (data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  dispatch(ShowLoading());

  await axios({
    method: "POST",
    baseURL: `${get_mosaic_payments}`,
    headers: headers,
    data: data,
  })
    .then((res) => {
      dispatch(HideLoading());
      dispatch({
        type: MOSAIC_GET_PAYMENTS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(HideLoading());
      console.log(err.data);
      const message = err?.response?.data?.error;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${message}`,
      });
    });
};

export const createMosiacApplication = (data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  dispatch(ShowLoading());
  await axios({
    method: "POST",
    baseURL: `${mosiac_create_application}`,
    headers: headers,
    data: data,
  })
    .then((res) => {
      dispatch(HideLoading());
      dispatch({
        type: MOSIAC_CREATE_PROJECT,
        payload: res.data.data,
      });
      Swal.fire({
        icon: "success",
        title: "Your Application has been created",
        timer: 5500,
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      });
    })
    .catch((err) => {
      dispatch(HideLoading());
      console.log(err.data);
      const message = err?.response?.data?.error;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${message}`,
      });
    });
};

export const shareMosiacApplication = (data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  dispatch(ShowLoading());
  await axios({
    method: "POST",
    baseURL: `${mosiac_share_application}`,
    headers: headers,
    data: data,
  })
    .then((res) => {
      dispatch(HideLoading());
      dispatch({
        type: MOSIAC_SHARE_APPLICATION,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(HideLoading());
      console.log(err.data);
      const message = err?.response?.data?.error;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${message}`,
      });
    });
};

export const getMosiacTimeLine = (id) => async (dispatch) => {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  dispatch(ShowLoading());
  await axios({
    method: "GET",
    baseURL: `${mosiac_get_timeLine}/${id}`,
    headers: headers,
  })
    .then((res) => {
      dispatch(HideLoading());
      dispatch({
        type: MOSIAC_GET_TIMELINE,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(HideLoading());
      console.log(err.data);
      const message = err?.response?.data?.error;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${message}`,
      });
    });
};

export const getMosiacStatus = (leadID, proposalID) => async (dispatch) => {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  dispatch(ShowLoading());
  await axios({
    method: "GET",
    baseURL: `${mosiac_get_status}/${leadID}/${proposalID}`,
    headers: headers,
  })
    .then((res) => {
      dispatch(HideLoading());
      dispatch({
        type: MOSIAC_GET_STATUS,
        payload: res.data?.data,
      });
    })
    .catch((err) => {
      dispatch(HideLoading());
      console.log(err.data);
      const message = err?.response?.data?.error;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${message}`,
      });
    });
};

export const checkMosiacOffer = (data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  dispatch(ShowLoading());
  await axios({
    method: "POST",
    baseURL: `${mosiac_check_offer}`,
    headers: headers,
    data: data,
  })
    .then((res) => {
      dispatch(HideLoading());
      dispatch({
        type: MOSIAC_CHECK_OFFER,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(HideLoading());
      console.log("Error", err?.response?.data?.error);
      const message = err?.response?.data?.error;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${message}`,
      });
    });
};

export const getMosiacOfferNotFound = (data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  dispatch(ShowLoading());
  await axios({
    method: "POST",
    baseURL: `${mosiac_get_offer_notFoud}`,
    headers: headers,
    data: data,
  })
    .then((res) => {
      dispatch(HideLoading());
      dispatch({
        type: MOSIAC_GET_OFFERNOTFOUND,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(HideLoading());
      console.log(err.data);
      const message = err?.response?.data?.error;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${message}`,
      });
    });
};

export const submitMosiacOfferNotFound = (data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  dispatch(ShowLoading());
  await axios({
    method: "POST",
    baseURL: `${mosiac_submit_offer_notFoud}`,
    headers: headers,
    data: data,
  })
    .then((res) => {
      dispatch(HideLoading());
      dispatch({
        type: MOSIAC_SUBMIT_OFFERNOTFOUND,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(HideLoading());
      console.log(err.data);
      const message = err?.response?.data?.error;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${message}`,
      });
    });
};

export const submitMosiacOfferFound = (data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  dispatch(ShowLoading());
  await axios({
    method: "POST",
    baseURL: `${mosiac_submit_offer_found}`,
    headers: headers,
    data: data,
  })
    .then((res) => {
      dispatch(HideLoading());
      Swal.fire({
        icon: "success",
        title: "Offer has been Submitted",
      });
      dispatch({
        type: MOSIAC_SUBMIT_OFFERFOUND,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(HideLoading());
      console.log(err.data);
      const message = err?.response?.data?.error;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${message}`,
      });
    });
};

export const getMosiacOfferFound = (data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  dispatch(ShowLoading());
  await axios({
    method: "POST",
    baseURL: `${mosiac_get_offer_found}`,
    headers: headers,
    data: data,
  })
    .then((res) => {
      dispatch(HideLoading());
      dispatch({
        type: MOSIAC_GET_OFFERFOUND,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(HideLoading());
      console.log(err.data);
      const message = err?.response?.data?.error;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${message}`,
      });
    });
};

export const getMosiacOfferStatus = (data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  dispatch(ShowLoading());
  await axios({
    method: "GET",
    baseURL: `${mosaic_offer_status}/${data}`,
    headers: headers,
    data: data,
  })
    .then((res) => {
      dispatch(HideLoading());
      dispatch({
        type: MOSIAC_CHECK_OFFER_STATUS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(HideLoading());
      console.log(err.data);
      const message = err?.response?.data?.error;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${message}`,
      });
    });
};

export const getMosiacContractDoucment = (data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  dispatch(ShowLoading());
  await axios({
    method: "POST",
    baseURL: `${mosaic_downlaod_contract_document}`,
    headers: headers,
    data: data,
  })
    .then((res) => {
      dispatch(HideLoading());
      dispatch({
        type: MOSIAC_DOWNLOAD_CONTRACT_DOCUMENT,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(HideLoading());
      console.log(err.data);
      const message = err?.response?.data?.error;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${message}`,
      });
    });
};

// Selected Data
export const mosiacCalc = (data) => {
  return async (dispatch) => {
    await dispatch({
      type: "MOSIAC_ALL_DATA",
      allMosiacData: data,
    });
  };
};
