import {
  GET_ALL_MESSAGES,
  GET_UNSEEN_MESSAGE,
  GET_ALL_DEPARTMENT,
  GET_ALL_DEPARTMENT_USER,
  GET_SINGLE_MESSAGES,
  CHANGE_STATUS_CHAT,
} from "../Actions/type";

const initialState = {
  unseenMessages: [],
  allChats: [],
  deparments: [],
  allUsers: {},
  allSinglChat: [],
  changeStatus: null,
};
const MessageReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_UNSEEN_MESSAGE:
      return {
        ...state,
        unseenMessages: action.unseenMessages,
      };
    case GET_ALL_MESSAGES:
      return {
        ...state,
        allChats: action.allChats,
      };
    case GET_ALL_DEPARTMENT:
      return {
        ...state,
        deparments: action.deparments,
      };
    case GET_ALL_DEPARTMENT_USER:
      return {
        ...state,
        allUsers: action.users,
      };
    case GET_SINGLE_MESSAGES:
      return {
        ...state,
        allSinglChat: action.allSinglChat,
      };
    case CHANGE_STATUS_CHAT:
      return {
        ...state,
        changeStatus: action.changeStatus,
      };

    default:
      return { ...state };
  }
};

export default MessageReducer;
