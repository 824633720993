import Challenges from "./Challenges/Challenges";
import Coins from "./Conis/Coins";
import LeadsCount from "./LeadsCount/LeadsCount";
import Rating from "./Rating/Rating";
import { GridConatiner } from "./RightStyled";
import Royalties from "./Royalties/Royalties";
import SolutionCard from "./SolutionCard/SolutionCard";
import Teams from "./Teams/Teams";
import Achievements from "./ِAchevments/Achievements";

const UserCard = ({ SalseUser }) => {
  return (
    <>
      <GridConatiner>
        <LeadsCount SalseUser={SalseUser} />
        <SolutionCard SalseUser={SalseUser} />
        <Challenges SalseUser={SalseUser} />
        <Achievements SalseUser={SalseUser} />
        <Coins SalseUser={SalseUser} />
        <Teams SalseUser={SalseUser} />
        <Royalties SalseUser={SalseUser} />
        <Rating SalseUser={SalseUser} />
      </GridConatiner>
    </>
  );
};

export default UserCard;
