import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Loading from "../../../../components/loading/Loading";
import { singleLeadForChild } from "../../../../Redux/Actions/singleLeadAction";
import HeaderLead from "./HeaderLead";
import TabsLead from "./TabsLead";

const LeadProfileChild = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const Lead_ID = id.split("_")[0];
  const User_ID = id.split("_")[1];
  const { isSingleLeadLoading, singleadCild } = useSelector(
    (state) => state.leads
  );

  useEffect(() => {
    if (Lead_ID && User_ID) dispatch(singleLeadForChild(Lead_ID, User_ID));
  }, [Lead_ID, User_ID]);
  return (
    <>
      {isSingleLeadLoading && <Loading />}
      {!isSingleLeadLoading && (
        <LeadStyled>
          <header className="header">
            <HeaderLead LeadChild={singleadCild} />
          </header>
          <div className="tabs">
            <TabsLead LeadChild={singleadCild} />
          </div>
        </LeadStyled>
      )}
    </>
  );
};

const LeadStyled = styled.div`
  .header,
  .tabs {
    margin: 2rem 1rem;
    padding: 1rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 1rem;
    background: #ffffff;
  }
`;
export default LeadProfileChild;
