import { Outlet } from "react-router-dom";
import NavCalender from "../../../../components/calendar/calenderComponents";

const CalnderPage = () => {
  return (
    <>
      <NavCalender />
      <Outlet />
    </>
  );
};

export default CalnderPage;
