import styled from "styled-components";

export const BigSection = styled.div`
  .container {
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    background-color: rgba(17, 25, 40, 0.25);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
    /* padding: 38px; */
    filter: drop-shadow(0 30px 10px rgba(0, 0, 0, 0.125));
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 3rem 0.5rem;
    width: 100%;
    &.selected {
      background-color: #43c501 !important;
    }
    .price {
      color: white;
      padding-top: 1rem;
    }
  }

  .wrapper {
    width: 100%;
    height: 100%;
  }

  .banner-image {
    /* background-image: url(https://images.unsplash.com/photo-1641326201918-3cafc641038e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1887&q=80); */
    background-position: center;
    background-size: cover;
    height: 300px;
    width: 100%;
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.255);
  }

  h1 {
    font-family: "Righteous", sans-serif;
    color: rgba(255, 255, 255, 0.98);
    text-transform: uppercase;
    font-size: 1.5rem;
  }

  p {
    color: #fff;
    font-family: "Lato", sans-serif;
    text-align: center;
    font-size: 0.8rem;
    line-height: 150%;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  .button-wrapper {
    margin-top: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn {
    border: none;
    padding: 12px 24px;
    border-radius: 24px;
    font-size: 12px;
    font-size: 0.8rem;
    letter-spacing: 2px;
    cursor: pointer;
  }

  .btn + .btn {
    /* margin-left: 10px; */
  }

  .outline {
    background: transparent;
    color: rgba(0, 212, 255, 0.9);
    border: 1px solid rgba(0, 212, 255, 0.6);
    transition: all 0.3s ease;
  }

  .outline:hover {
    transform: scale(1.125);
    color: rgba(255, 255, 255, 0.9);
    border-color: rgba(255, 255, 255, 0.9);
    transition: all 0.3s ease;
  }

  .fill {
    background: rgba(0, 212, 255, 0.9);
    color: rgba(255, 255, 255, 0.95);
    filter: drop-shadow(0);
    font-weight: bold;
    transition: all 0.3s ease;
  }

  .fill:hover {
    transform: scale(1.125);
    border-color: rgba(255, 255, 255, 0.9);
    filter: drop-shadow(0 10px 5px rgba(0, 0, 0, 0.125));
    transition: all 0.3s ease;
  }
  .filterContainer {
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
    padding: 1rem;
    width: 100%;
    label {
      display: block;
    }
    button {
      display: inline-block;
      outline: 0;
      border: none;
      cursor: pointer;
      line-height: 1.2rem;
      font-weight: 900;
      background: #eef5ff;
      padding: 8px 54px 9px;
      font-size: 15px;
      border-radius: 4px;
      color: #fff;
      height: 36px;
      transition: all 75ms ease-in-out;
      cursor: pointer;
      color: #000;
      &:hover {
        box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
      }
      &:active {
        transform: scale(0.9);
      }
    }
  }
  .tabsContainer {
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
    padding: 1rem;
    width: 100%;
  }
`;
