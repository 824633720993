import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import background from "../../assets/RoofingBackGround.jpg";
import Header from "../../components/Header/Header";
import BadgeIcon from "@mui/icons-material/Badge";
import { useNavigate } from "react-router-dom";
import { GET_SINGLE_ROOFING } from "../../API/Api";
import axios from "axios";
import LoadingSoultion from "../../components/loading/loadingSolution";
import Loading from "../../components/loading/Loading";
import { useDispatch } from "react-redux";
import { SingleContractData } from "../../Redux/Actions/SingleOpportunitieAction";
import { useSelector } from "react-redux";
import { DisabledByDefault } from "@mui/icons-material";
import Swal from "sweetalert2";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import AddProfileFinancial from "./AddProfileFinancial";
import { singleLeadAction } from "../../Redux/Actions/singleLeadAction";
import { LoadingContainer } from "../Left/Routs/customers/AddProposals/SolarForm/SolarFormStyle";
import EditIcon from "@mui/icons-material/Edit";
import { goodleapFinance } from "../../Redux/Actions/goodLeapAction";
import {
  checkMosiacOffer,
  createMosiacApplication,
  getMosiacContractDoucment,
  getMosiacOfferNotFound,
  getMosiacOffers,
  getMosiacOfferStatus,
  getMosiacPayments,
  getMosiacStatus,
  getMosiacTimeLine,
  submitMosiacOfferFound,
  submitMosiacOfferNotFound,
} from "../../Redux/Actions/MosaicAction";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import {
  BackButton,
  Section,
  Table,
  Wrapper,
} from "./finance/SingleContractStyle";
import {
  getConcerteligibilities,
  preQualifyConcert,
  submitConcertOffer,
} from "../../Redux/Actions/ConcertAction";
// style

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const solarProduct = [
  { name: "Choice", value: "Choice" },
  { name: "Plus", value: "Plus" },
  // { name: "Light", value: "Light" },
  { name: "Zero", value: "Zero" },
  // {
  //   name: "ReducedRateApr",
  //   value: "ReducedRateApr",
  // },
  // { name: "DeferredInterest", value: "DeferredInterest" },
];
const SingleContract = () => {
  const { id } = useParams();
  const disptach = useDispatch();
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const { singlead, isSingleLeadLoading } = useSelector((state) => state.leads);
  const { contractData } = useSelector((state) => state.leads);
  const {
    shareProject,
    status,
    timeLine,
    createProject,
    checkOffer,
    offersNotFound,
    offerNotFoundSubmit,
    offerFound,
    offerFoundSubmit,
    checkOfferStatus,
    loanStatus,
    allMosiacData,
    allOffers,
    payments,
    doucment,
  } = useSelector((state) => state.Mosiac);
  const { concertHook } = useSelector((state) => state?.Concert);
  console.log("concertHook", concertHook);
  const [data, setData] = React.useState({
    mosaic_is_ach: true,
    newLoan: contractData?.cost,
  });
  console.log("status status", status);

  const [showForm, setShowForm] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openTwo, setOpenTwo] = React.useState(false);
  const handleOpenTwo = () => setOpenTwo(true);
  const handleCloseTwo = () => setOpenTwo(false);

  const location = useLocation();
  const LeadId = location.pathname.split("/")[3];
  const solutionID = contractData?.opportunity?.id;
  console.log("checkOffer?.status ", checkOffer?.status);
  const { isLoading } = useSelector((state) => state.proposal);
  const theOfferMosiac = allOffers?.find(
    (e) => e.id === data?.finance_product_id
  );
  let mosiacDealerFee = theOfferMosiac?.dealerFee || 0;

  const loanAmount =
    Math.ceil((contractData?.cost * 100) / (100 - mosiacDealerFee)) || 0;
  const mosiacData = {
    lead_id: LeadId,
    solution_id: solutionID,
    // mosaic_finance_product_id: allMosiacData?.finance_product_id,
    mosaic_financing_product_type: payments?.financingProductType,
    mosaic_total_periods: payments?.totalPeriods,
    mosaic_rate: payments?.rate,
    mosaic_rate_without_ach_discount: payments?.rateWithoutAchDiscount,
    mosaic_dealer_fee: payments?.dealerFee,
    mosaic_monthly_payments: payments?.monthlyPayments,
    // mosaic_is_ach: +allMosiacData?.mosaic_is_ach,
    loan_amount: loanAmount,
    mosaic_finance_product_id: data?.finance_product_id,
    mosaic_is_ach: +data?.mosaic_is_ach,
    mosaic_prepayment_amount: payments?.prePaymentAmount,
    mosaic_monthly_payments_with_prepayment:
      payments?.monthlyPaymentsWithPrePayment,
  };
  React.useEffect(async () => {
    disptach(SingleContractData(id));
    disptach(singleLeadAction(LeadId));
    if (contractData?.opportunity?.financial_institution === "mosaic") {
      disptach(getMosiacOffers());
    }
  }, []);
  React.useMemo(() => {
    if (status?.application?.decision?.updated) {
      setOpenTwo(true);
    }
  }, [status]);
  React.useEffect(() => {
    if (checkOffer?.status === 1 || checkOffer?.status === 0) {
      setOpen(true);
    }
    if (checkOffer?.status === 0) {
      disptach(
        getMosiacOfferNotFound({ lead_id: LeadId, solution_id: solutionID })
      );
    }
  }, [checkOffer]);

  React.useEffect(() => {
    if (
      solutionID &&
      contractData?.opportunity?.financial_institution === "mosaic"
    ) {
      disptach(getMosiacTimeLine(solutionID));
    }
  }, [solutionID]);
  React.useEffect(() => {
    if (contractData?.opportunity?.financial_institution === "concert") {
      disptach(getConcerteligibilities({ lead_id: LeadId }));
    }
  }, [contractData]);

  // React.useEffect(()=>{
  //   if(checkOffer?.status===2){
  //     Swal.fire({
  //       icon: "error",
  //       title: "Oops...",
  //       text: `${checkOffer?.check}`,
  //     });
  //   }
  // },[checkOffer?.status])

  const lead = contractData?.opportunity?.lead;
  const user = contractData?.opportunity?.user;
  const opportunity = contractData?.opportunity;
  const allLogsInfo = contractData.logs || [];
  let document_created = allLogsInfo.find(
    (e) => e?.event_type === "document_created"
  );
  let document_sent = allLogsInfo.find(
    (e) => e?.event_type === "document_sent"
  );
  let document_viewed = allLogsInfo.find(
    (e) => e?.event_type === "document_viewed"
  );
  let document_signed = allLogsInfo.find(
    (e) => e?.event_type === "document_signed"
  );
  let document_completed = allLogsInfo.find(
    (e) => e?.event_type === "document_completed"
  );
  const createLoan = async () => {
    if (contractData?.opportunity?.financial_institution === "goodleap") {
      window.open("https://origin.goodleap.com/loans", "_blank");
      // if (contractData?.eversign_document?.is_completed === 1) {
      //   // if (singlead?.financial_profile) {
      //   disptach(goodleapFinance(contractData?.id));
      //   // }
      // } else {
      //   Swal.fire({
      //     icon: "error",
      //     title: "Oops...",
      //     text: "The customer must sign the contract",
      //   });
      // }
    }
    if (contractData?.opportunity?.financial_institution === "mosaic") {
      // await disptach(
      //   createMosiacApplication({
      //     lead_id: LeadId,
      //     solution_id: solutionID,
      //   })
      // );
      // await disptach(getMosiacTimeLine(solutionID));
      window.open("https://login.financing.joinmosaic.com/sign-in", "_blank");
    }
    if (contractData?.opportunity?.financial_institution === "concert") {
      // await disptach(
      //   submitConcertOffer({
      //     lead_id: LeadId,
      //     solution_id: solutionID,
      //     loan_amount: contractData?.cost,
      //   })
      // );
      // await disptach(getConcerteligibilities({ lead_id: LeadId }));
      window.open(
        "https://switch.concertfin.com/login?at=https://switch.concertfin.com/",
        "_blank"
      );
    }
    if (contractData?.opportunity?.financial_institution === "sunlight") {
      // await disptach(
      //   submitConcertOffer({
      //     lead_id: LeadId,
      //     solution_id: solutionID,
      //     loan_amount: contractData?.cost,
      //   })
      // );
      // await disptach(getConcerteligibilities({ lead_id: LeadId }));
      window.open("https://slfportal.com/", "_blank");
    }
  };

  const concertPreQualify = async () => {
    if (contractData?.opportunity?.financial_institution === "concert") {
      await disptach(
        preQualifyConcert({
          lead_id: LeadId,
        })
      );
      await disptach(getConcerteligibilities({ lead_id: LeadId }));
    }
  };
  const showFormBtn = () => {
    setShowForm((preValue) => !preValue);
  };
  // console.log("mosiacData", mosiacData);

  console.log("loanAmount", loanAmount);

  const handleSubmitOffer = async (e) => {
    e.preventDefault();
    await disptach(
      getMosiacPayments({
        finance_product_id: data?.finance_product_id,
        principal: loanAmount,
      })
    );
  };
  const submitOfferFound = () => {
    if (mosiacData?.mosaic_monthly_payments) {
      disptach(submitMosiacOfferNotFound(mosiacData));
    }
  };
  const hookArr = concertHook?.results || [];

  let filterOffers =
    offersNotFound?.financingProducts?.filter(
      (e) => e.financingProductType === data?.solar_product
    ) || [];
  let findOffer = (id) => {
    return allOffers?.find((e) => e.id === id);
  };
  let CommaFormatted = (num) => {
    // return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return Intl.NumberFormat("en-US").format(num);
  };
  return (
    <>
      {!isSingleLeadLoading && (
        <Section>
          <Header></Header>
          <div className="blur">
            <Grid container columns={12} spacing={4} alignItems="start">
              <Grid item lg={12} md={12} sm={12}>
                {" "}
                <BackButton onClick={goBack}>Go Back to Profile</BackButton>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <div className="LeadInfo">
                  <div className="HeaderDiv">
                    <h3>User Info</h3>
                  </div>
                  <div className="userData">
                    <p>
                      Name : <span>{user?.name}</span>
                    </p>
                    <p>
                      Email : <span>{user?.email}</span>
                    </p>
                    <p>
                      Phone : <span>{user?.phone}</span>
                    </p>
                    <p>
                      Mantras : <span>{user?.name}</span>
                    </p>
                    <p>
                      Business :<span> {user?.name}</span>
                    </p>
                    <p>
                      Lead Count : <span>{user?.leads_count}</span>
                    </p>
                    <p>
                      Opportunities count :
                      <span> {user?.opportunities_count}</span>
                    </p>
                    <p>
                      Solar contract count :{" "}
                      <span>{user?.solar_contracts_count}</span>
                    </p>
                    <p>
                      Job title :<span> {user?.Job_title} </span>
                    </p>
                  </div>
                  <div className="HeaderDiv">
                    <h3>Lead Info</h3>
                  </div>
                  <div className="userData">
                    <p>
                      Name :{" "}
                      <span>
                        {lead?.first_name} {lead?.last_name}
                      </span>{" "}
                    </p>
                    <p>
                      Created At :{" "}
                      <span>
                        {new Date(contractData?.created_at).toLocaleDateString(
                          "en-US",
                          { day: "2-digit", month: "2-digit", year: "numeric" }
                        )}
                      </span>
                    </p>
                    <p>
                      Address : <span>{lead?.location}</span>
                    </p>
                    <p>
                      Status : <span>{lead?.is_active}</span>
                    </p>
                    <p>
                      Mobile : <span>{lead?.mobile}</span>
                    </p>
                    <p>
                      Prefered Language :{" "}
                      <span>{lead?.preferred_language}</span>
                    </p>
                    <p>
                      Contract amount :{" "}
                      <span style={{ color: "green" }}>
                        ${contractData?.cost}
                      </span>
                    </p>
                    <p>
                      Royalties :{" "}
                      <span style={{ color: "green" }}>
                        {opportunity?.royalties}
                      </span>
                    </p>
                  </div>
                  {contractData.is_finance === 1 && (
                    <>
                      <div className="HeaderDiv">
                        <h3> Finance status</h3>
                      </div>

                      <div className="userData">
                        <p style={{ textAlign: "center" }}>
                          {/* Financial Status : */}
                          <span
                            style={{ letterSpacing: "2px", fontSize: "23px" }}
                          >
                            {" "}
                            {
                              contractData?.opportunity?.goodleap_loan?.status
                            }{" "}
                          </span>
                        </p>
                        {/* {contractData?.opportunity?.financial_institution ===
                        "goodleap" ? (
                          <>
                            {" "}
                            <button
                              style={{
                                width: "100%",
                                padding: ".2rem 0",
                                cursor: "pointer",
                                color: "green",
                                background: "white",
                                fontSize: "1.5rem",
                              }}
                              // disabled={
                              //   singlead?.state?.abbr === "FL" ||
                              //   singlead?.state?.abbr === "CA" ||
                              //   singlead?.state?.abbr === "KY" ||
                              //   singlead?.state?.abbr === "CO" ||
                              //   singlead?.state?.abbr === "NY" ||
                              //   singlead?.state?.abbr === "OH" ||
                              //   singlead?.state?.abbr === "SC"
                              //     ? false
                              //     : true
                              // }
                              onClick={() => {
                                createLoan();
                              }}
                            >
                              Finance
                            </button>
                          </>
                        ) : (
                          <></>
                        )} */}
                        <button
                          style={{
                            width: "100%",
                            padding: ".2rem 0",
                            cursor: "pointer",
                            color: "green",
                            background: "white",
                            fontSize: "1.5rem",
                          }}
                          // disabled={
                          //   singlead?.state?.abbr === "FL" ||
                          //   singlead?.state?.abbr === "CA" ||
                          //   singlead?.state?.abbr === "KY" ||
                          //   singlead?.state?.abbr === "CO" ||
                          //   singlead?.state?.abbr === "NY" ||
                          //   singlead?.state?.abbr === "OH" ||
                          //   singlead?.state?.abbr === "SC"
                          //     ? false
                          //     : true
                          // }
                          onClick={() => {
                            createLoan();
                          }}
                        >
                          Finance
                        </button>
                        {/* {contractData?.opportunity?.financial_institution ===
                        "mosaic" ? (
                          <>
                            {" "}
                            <button
                              style={{
                                width: "100%",
                                padding: ".2rem 0",
                                cursor: "pointer",
                                color: "green",
                                background: "white",
                                fontSize: "1.5rem",
                              }}
                              onClick={() => {
                                createLoan();
                              }}
                            >
                              Finance
                            </button>
                          </>
                        ) : (
                          <></>
                        )} */}
                      </div>
                    </>
                  )}
                </div>
              </Grid>
              <Grid item lg={8} md={8} sm={12} xs={12}>
                <div className="rightDiv">
                  <div className="solutionDetails">
                    <div className="HeaderDiv">
                      <h3>Contract Status</h3>
                    </div>
                    {contractData?.eversign_document?.url ? (
                      <>
                        <div className="pdf">
                          <button
                            target="_blank"
                            onClick={() => {
                              window.open(
                                `https://crm.boxbyld.tech/profiles/${contractData?.eversign_document?.url}`
                              );
                            }}
                          >
                            open Pdf
                          </button>{" "}
                        </div>
                      </>
                    ) : (
                      <>
                        {" "}
                        <div className="pdf">Not Signed</div>
                      </>
                    )}

                    {allLogsInfo.length > 0 && (
                      <>
                        <Grid item lg={18} md={18} sm={18} xs={18}>
                          {/* <Heading>Solution Status</Heading> */}
                        </Grid>

                        <Grid item lg={18} md={18} sm={18} xs={18}>
                          <Wrapper className="wrapper">
                            <ol className="c-timeline">
                              {document_created !== undefined ? (
                                <li className="c-timeline__item">
                                  <div
                                    className="c-timeline__content_green"
                                    style={{}}
                                  >
                                    <h3 className="c-timeline__title">
                                      Document Created{" "}
                                    </h3>
                                  </div>
                                  <time>
                                    {new Date(
                                      document_created.event_time
                                    ).toLocaleDateString("en-US", {
                                      day: "2-digit",
                                      month: "2-digit",
                                      year: "numeric",
                                    })}
                                  </time>
                                </li>
                              ) : (
                                <li className="c-timeline__item">
                                  <div className="c-timeline__content">
                                    <h3 className="c-timeline__title">
                                      Document Created{" "}
                                    </h3>
                                  </div>
                                  <time></time>
                                </li>
                              )}
                              {document_sent !== undefined ? (
                                <li className="c-timeline__item">
                                  <div
                                    className="c-timeline__content_green"
                                    style={{}}
                                  >
                                    <h3 className="c-timeline__title">
                                      Document Sent{" "}
                                    </h3>
                                  </div>
                                  <time>
                                    {new Date(
                                      document_sent.event_time
                                    ).toLocaleDateString("en-US", {
                                      day: "2-digit",
                                      month: "2-digit",
                                      year: "numeric",
                                    })}
                                  </time>
                                </li>
                              ) : (
                                <li className="c-timeline__item">
                                  <div className="c-timeline__content">
                                    <h3 className="c-timeline__title">
                                      Document Sent{" "}
                                    </h3>
                                  </div>
                                  <time></time>
                                </li>
                              )}
                              {document_viewed !== undefined ? (
                                <li className="c-timeline__item">
                                  <div
                                    className="c-timeline__content_green"
                                    style={{}}
                                  >
                                    <h3 className="c-timeline__title">
                                      Document Viewed{" "}
                                    </h3>
                                  </div>
                                  <time>
                                    {new Date(
                                      document_viewed.event_time
                                    ).toLocaleDateString("en-US", {
                                      day: "2-digit",
                                      month: "2-digit",
                                      year: "numeric",
                                    })}
                                  </time>
                                </li>
                              ) : (
                                <li className="c-timeline__item">
                                  <div className="c-timeline__content">
                                    <h3 className="c-timeline__title">
                                      Document Viewed{" "}
                                    </h3>
                                  </div>
                                  <time></time>
                                </li>
                              )}
                              {document_signed !== undefined ? (
                                <li className="c-timeline__item">
                                  <div
                                    className="c-timeline__content_green"
                                    style={{}}
                                  >
                                    <h3 className="c-timeline__title">
                                      Document Signed{" "}
                                    </h3>
                                  </div>
                                  <time>
                                    {new Date(
                                      document_signed.event_time
                                    ).toLocaleDateString("en-US", {
                                      day: "2-digit",
                                      month: "2-digit",
                                      year: "numeric",
                                    })}
                                  </time>
                                </li>
                              ) : (
                                <li className="c-timeline__item">
                                  <div className="c-timeline__content">
                                    <h3 className="c-timeline__title">
                                      Document Signed{" "}
                                    </h3>
                                  </div>
                                  <time></time>
                                </li>
                              )}
                              {document_completed !== undefined ? (
                                <li className="c-timeline__item">
                                  <div
                                    className="c-timeline__content_green"
                                    style={{}}
                                  >
                                    <h3 className="c-timeline__title">
                                      Document Completed{" "}
                                    </h3>
                                  </div>
                                  <time>
                                    {new Date(
                                      document_completed.event_time
                                    ).toLocaleDateString("en-US", {
                                      day: "2-digit",
                                      month: "2-digit",
                                      year: "numeric",
                                    })}
                                  </time>
                                </li>
                              ) : (
                                <li className="c-timeline__item">
                                  <div className="c-timeline__content">
                                    <h3 className="c-timeline__title">
                                      Document Completed{" "}
                                    </h3>
                                  </div>
                                  <time></time>
                                </li>
                              )}
                              {/* {document_completed !== document_completed ? (
                        <li className="c-timeline__item">
                          <div
                            className="c-timeline__content_red"
                            style={{}}
                          >
                            <h3 className="c-timeline__title">
                              financial situation{" "}
                            </h3>
                          </div>
                          <time>
                            {new Date(
                              document_completed.event_time
                            ).toLocaleDateString("en-US", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            })}
                          </time>
                        </li>
                      ) : (
                        <li className="c-timeline__item">
                          <div className="c-timeline__content">
                            <h3 className="c-timeline__title">
                              financial situation{" "}
                            </h3>
                          </div>
                          <time></time>
                        </li>
                      )} */}
                            </ol>
                          </Wrapper>
                        </Grid>
                        <br></br>
                      </>
                    )}
                  </div>

                  <div className="solutionDetails">
                    <div className="HeaderDiv">
                      <h3>Financial Profile Details</h3>

                      {singlead?.financial_profile &&
                      contractData?.opportunity?.financial_institution ===
                        "goodleap" ? (
                        <>
                          {" "}
                          <div className="child">
                            <Button
                              onClick={showFormBtn}
                              endIcon={<EditIcon></EditIcon>}
                            >
                              Edit Financial Profile Details
                            </Button>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="content">
                      <div className="userData">
                        {contractData.is_finance === 1 ? (
                          <>
                            <div className="userData">
                              <p>
                                Financial Institution :
                                <span>
                                  {" "}
                                  {
                                    contractData?.opportunity
                                      ?.financial_institution
                                  }{" "}
                                </span>
                              </p>

                              {/* <button
                        style={{
                          width: "100%",
                          padding: ".2rem 0",
                          cursor: "pointer",
                          color: "green",
                          background: "white",
                          fontSize: "1.5rem",
                        }}
                        onClick={() => {
                          createLoan();
                        }}
                      >
                        Finance
                      </button> */}
                              {contractData?.opportunity
                                ?.financial_institution === "goodleap" ? (
                                <>
                                  {singlead?.financial_profile ? (
                                    <>
                                      {showForm ? (
                                        <>
                                          {" "}
                                          <AddProfileFinancial></AddProfileFinancial>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <AddProfileFinancial></AddProfileFinancial>
                                    </>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                              {contractData?.opportunity
                                ?.financial_institution === "mosaic" ? (
                                <>
                                  <Grid item xs={18} sx={{ display: "none" }}>
                                    <Timeline position="alternate">
                                      <TimelineItem>
                                        <TimelineOppositeContent color="text.secondary">
                                          <button
                                            className="check"
                                            onClick={async () => {
                                              await disptach(
                                                createMosiacApplication({
                                                  lead_id: LeadId,
                                                  solution_id: solutionID,
                                                })
                                              );
                                              await disptach(
                                                getMosiacTimeLine(solutionID)
                                              );
                                            }}
                                            disabled={
                                              timeLine?.application_is_created
                                                ? true
                                                : false
                                            }
                                          >
                                            {timeLine?.application_is_created
                                              ? "You have already pre-qualified"
                                              : "Pre-qualify"}
                                          </button>
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                          <TimelineDot
                                            sx={{
                                              backgroundColor:
                                                timeLine?.application_is_created ===
                                                1
                                                  ? "green"
                                                  : "red",
                                            }}
                                          />
                                          <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                          {timeLine?.application_is_created ===
                                          1 ? (
                                            <> Application has been created</>
                                          ) : (
                                            <> Application is not created</>
                                          )}
                                        </TimelineContent>
                                      </TimelineItem>
                                      <TimelineItem>
                                        <TimelineOppositeContent color="text.secondary">
                                          <button
                                            className="check"
                                            onClick={async () => {
                                              await disptach(
                                                getMosiacStatus(
                                                  LeadId,
                                                  solutionID
                                                )
                                              );
                                              await disptach(
                                                getMosiacTimeLine(solutionID)
                                              );
                                              await Swal.fire({
                                                icon: "success",
                                                title:
                                                  "Timeline has been updated",
                                                timer: 5500,
                                              });
                                            }}
                                          >
                                            check status
                                          </button>
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                          <TimelineDot
                                            sx={{
                                              backgroundColor:
                                                timeLine?.application_is_decision_complete ===
                                                1
                                                  ? "green"
                                                  : "red",
                                            }}
                                          />
                                          <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                          {timeLine?.application_is_decision_complete ===
                                          1 ? (
                                            <> Decision completed</>
                                          ) : (
                                            <> Decision is not completed</>
                                          )}
                                        </TimelineContent>
                                      </TimelineItem>
                                      <TimelineItem>
                                        <TimelineOppositeContent color="text.secondary">
                                          <button
                                            className="check"
                                            onClick={async () => {
                                              await disptach(
                                                checkMosiacOffer({
                                                  lead_id: LeadId,
                                                  solution_id: solutionID,
                                                })
                                              );
                                              await disptach(
                                                getMosiacTimeLine(solutionID)
                                              );
                                            }}
                                          >
                                            check offer
                                          </button>
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                          <TimelineDot
                                            sx={{
                                              backgroundColor:
                                                timeLine?.offer_is_created === 1
                                                  ? "green"
                                                  : "red",
                                            }}
                                          />
                                          <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                          {timeLine?.offer_is_created === 1 ? (
                                            <> Offer has been created</>
                                          ) : (
                                            <> Offer is not created</>
                                          )}
                                        </TimelineContent>
                                      </TimelineItem>
                                      <TimelineItem>
                                        <TimelineOppositeContent color="text.secondary">
                                          {/* 9:00 am */}
                                          {timeLine?.offer_is_created === 1 ? (
                                            <button
                                              className="check"
                                              onClick={() => {
                                                disptach(
                                                  getMosiacContractDoucment({
                                                    solution_id: solutionID,
                                                  })
                                                );
                                              }}
                                            >
                                              Download file
                                            </button>
                                          ) : (
                                            <></>
                                          )}
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                          <TimelineDot
                                            sx={{
                                              backgroundColor:
                                                timeLine?.offer_is_shared === 1
                                                  ? "green"
                                                  : "red",
                                            }}
                                          />
                                          <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                          {timeLine?.offer_is_shared === 1 ? (
                                            <> Offer has been shared</>
                                          ) : (
                                            <> Offer is not shared</>
                                          )}
                                        </TimelineContent>
                                      </TimelineItem>
                                      <TimelineItem>
                                        <TimelineOppositeContent color="text.secondary">
                                          <button
                                            onClick={async () => {
                                              await disptach(
                                                getMosiacOfferStatus(solutionID)
                                              );
                                            }}
                                            className="check"
                                          >
                                            Check loan status
                                          </button>
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                          <TimelineDot
                                            sx={{
                                              backgroundColor:
                                                timeLine?.offer_is_submitted ===
                                                1
                                                  ? "green"
                                                  : "red",
                                            }}
                                          />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                          {loanStatus?.contract?.status ? (
                                            <> {loanStatus?.contract?.status}</>
                                          ) : (
                                            <>Offer has been submitted</>
                                          )}
                                        </TimelineContent>
                                      </TimelineItem>
                                    </Timeline>
                                  </Grid>
                                </>
                              ) : (
                                <></>
                              )}
                              {contractData?.opportunity
                                ?.financial_institution === "concert" ? (
                                <>
                                  <Timeline position="alternate">
                                    <TimelineItem>
                                      <TimelineOppositeContent>
                                        {concertHook?.results?.length > 0 ? (
                                          <>Pre-qualified</>
                                        ) : (
                                          <>Not pre-qualifed</>
                                        )}
                                      </TimelineOppositeContent>
                                      <TimelineSeparator>
                                        <TimelineDot
                                          sx={{
                                            backgroundColor:
                                              concertHook?.results?.length > 0
                                                ? "green"
                                                : "red",
                                          }}
                                        />
                                        <TimelineConnector />
                                      </TimelineSeparator>
                                      <TimelineContent>
                                        <button onClick={concertPreQualify}>
                                          Pre-qualify
                                        </button>
                                      </TimelineContent>
                                    </TimelineItem>
                                    <TimelineItem>
                                      <TimelineOppositeContent color="text.secondary">
                                        {hookArr[0]?.overallStatus ===
                                        "approved" ? (
                                          <>Submitted</>
                                        ) : (
                                          <>Not submitted</>
                                        )}
                                      </TimelineOppositeContent>
                                      <TimelineSeparator>
                                        <TimelineDot
                                          sx={{
                                            backgroundColor:
                                              hookArr[0]?.overallStatus ===
                                              "approved"
                                                ? "green"
                                                : "red",
                                          }}
                                        />
                                      </TimelineSeparator>
                                      <TimelineContent>
                                        <button onClick={createLoan}>
                                          Submit offer
                                        </button>
                                      </TimelineContent>
                                    </TimelineItem>
                                  </Timeline>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </>
                        ) : (
                          <>
                            {" "}
                            <div className="userData">
                              <p>
                                Financial Info : <span> Cash</span>
                              </p>
                            </div>{" "}
                          </>
                        )}
                        {/* <Grid item sm={18} xs={18}>
                          <h3
                            style={{
                              textAlign: "center",
                              paddingBottom: "30px",
                            }}
                          >
                            Financial Information
                          </h3>
                        </Grid> */}
                        {singlead?.financial_profile ? (
                          <>
                            {" "}
                            {contractData?.opportunity
                              ?.financial_institution === "goodleap" ? (
                              <Grid container columns={18} spacing={3} mt={2}>
                                <Grid item lg={9} md={9} xs={9}>
                                  Job Title:
                                  <span>
                                    {" "}
                                    {singlead?.financial_profile?.job_title}
                                  </span>
                                </Grid>
                                <Grid item lg={9} md={9} xs={9}>
                                  Date Of Birth:{" "}
                                  <span>
                                    {singlead?.financial_profile?.dob}
                                  </span>
                                </Grid>
                                <Grid item lg={9} md={9} xs={9}>
                                  Income:
                                  <span>
                                    {" "}
                                    {singlead?.financial_profile?.income}
                                  </span>
                                </Grid>
                                <Grid item lg={9} md={9} xs={9}>
                                  How many Years in Job :
                                  <span>
                                    {singlead?.financial_profile?.years}
                                  </span>
                                </Grid>
                                <Grid item lg={9} md={9} xs={9}>
                                  How many Months in Job :{" "}
                                  <span>
                                    {singlead?.financial_profile?.months}
                                  </span>
                                </Grid>
                                <Grid item lg={9} md={9} xs={9}>
                                  SSN :{" "}
                                  <span>
                                    XXXXX-{singlead?.financial_profile?.ssn}
                                  </span>
                                </Grid>
                              </Grid>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          {isLoading && <LoadingSoultion />}
        </Section>
      )}

      {isSingleLeadLoading && (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            align="center"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{ width: "10%" }}
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAb1BMVEX////6zn/uh05Rj6s2v7v6y3b60or6zXv73KbvkWD76eHuhkskvLjm9vbuhEjzrId50s9imLGJscT+9ehNxMDtfj2/5uTC1N5Fiafp8PT++O76z4L/3KP86uPuilP85M3D4tjtezbypn/FyMulz9WXRBhdAAABM0lEQVR4nO3aWVICARBEwVFRFHFBxR0Q9P5n9AwVTsSUROYFut5/DwMAAAAAAAAAAEzuMfQ+9eDYx3nmc+rBsYv5SWJ+OfXgmEKF/RQq7KdQYT+FCvspVNhPocJ+ChX2U6iwn0KF/RQq7KdQYT+FCvspVNhPocJ+ChX2+3ffl5vtVWS7Cz9ov2ZPmZuRCxfr68h6ER54Xs4iy/ELTyMKFSpUqFChQoUKFSpUqFChQoUKFSpUqFChQoUKFSpUqFChQoUKFSpUqFChwj8V7m8j+7wwNHbh5nAXOXy/ZH7uQ2MXxlavmYepB8dWZxGFhRQq7KdQYT+FCvspVNhPocJ+ChX2U6iwn0KF/RQq7KdQYT+FCvspVNhPocJ+ChX2U3gEhUf/ffmWmnowAAAAAAAAAAAMwy++F6aTVE0RRgAAAABJRU5ErkJggg=="
            ></img>
            <p>Mosaic</p>
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            align="center"
          >
            {checkOffer?.status === 1 ? (
              <>
                <div>
                  <Table>
                    <thead>
                      <tr>
                        <th>Project Cost</th>
                        <th style={{ textAlign: "center" }}>APR</th>
                        <th style={{ textAlign: "center" }}>Term in Year</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ textAlign: "left" }}>
                          ${CommaFormatted(contractData?.cost)}
                        </td>{" "}
                        <td>
                          {" "}
                          {
                            findOffer(
                              checkOffer?.mosaic_loan?.finance_product_id_init
                            )?.rate
                          }
                          %
                        </td>
                        <td>
                          {" "}
                          {findOffer(
                            checkOffer?.mosaic_loan?.finance_product_id_init
                          )?.totalPeriods / 12}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  {/* <p>DealerFee: {findOffer(checkOffer?.mosaic_loan?.finance_product_id_init)?.dealerFee}</p>  */}
                </div>
                <button
                  style={{ width: "100%", padding: "0.8rem", margin: "1rem 0" }}
                  onClick={() => {
                    disptach(
                      submitMosiacOfferFound({
                        lead_id: LeadId,
                        solution_id: solutionID,
                        loan_amount: contractData?.cost,
                      })
                    );
                  }}
                >
                  Submit Offer
                </button>
              </>
            ) : (
              <></>
            )}
            {checkOffer?.status === 0 ? (
              <>
                <form onSubmit={handleSubmitOffer}>
                  <Grid container columns={12} alignItems="center" spacing={3}>
                    <Grid item md={4} sm={9} xs={12}>
                      <span>None Ach</span>{" "}
                      <Switch
                        value={data.mosaic_is_ach}
                        onChange={(e) =>
                          setData({
                            ...data,
                            mosaic_is_ach: e.target.checked,
                          })
                        }
                        defaultChecked={data.mosaic_is_ach ? true : false}
                      />{" "}
                      <span>Ach</span>
                    </Grid>
                    <Grid item md={4} sm={9} xs={12}>
                      <FormControl fullWidth className="formControl">
                        <InputLabel id="demo-simple-select-label">
                          Solar products
                        </InputLabel>
                        <Select
                          id="demo-simple-select"
                          required
                          value={data.solar_product}
                          // disabled={disable}
                          label="Solar products"
                          onChange={async (e) => {
                            setData({
                              ...data,
                              solar_product: e.target.value,
                            });
                          }}
                        >
                          {solarProduct?.map((product) => (
                            <MenuItem key={product.value} value={product.value}>
                              {product?.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={4} sm={9} xs={12}>
                      <FormControl fullWidth className="formControl">
                        <InputLabel id="demo-simple-select-label">
                          All Offers
                        </InputLabel>
                        <Select
                          id="demo-simple-select"
                          required
                          // value={data.finance_product_id}
                          // disabled={disable}
                          label="All Offers"
                          onChange={async (e) => {
                            setData({
                              ...data,
                              finance_product_id: e.target.value,
                            });
                          }}
                        >
                          {filterOffers?.map((offer) => (
                            <MenuItem key={offer.id} value={offer.id}>
                              {offer.totalPeriods / 12} Years Rate{" "}
                              {data.mosaic_is_ach ? (
                                <>{offer.rate}</>
                              ) : (
                                <>{offer.rateWithoutDiscount}</>
                              )}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={4} sm={9} xs={12}>
                      <FormControl fullWidth>
                        <TextField
                          disabled
                          label="Project cost"
                          value={loanAmount}
                          // onChange={(e) =>
                          //   setData({
                          //     ...data,
                          //     loanAmount: Number(e.target.value),
                          //   })
                          // }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      {payments?.monthlyPayments?.length > 0 ? (
                        <Table id="customers">
                          <tr>
                            <th>Months</th>
                            <th>Monthly payment</th>
                          </tr>

                          {payments?.monthlyPayments?.map((e, i) => {
                            return (
                              <tr key={i}>
                                <td>{e?.months}</td>
                                <td>
                                  {data?.mosaic_is_ach ? (
                                    <>${e?.monthlyPayment}</>
                                  ) : (
                                    <>${e?.monthlyPaymentWithoutAchDiscount}</>
                                  )}{" "}
                                </td>
                              </tr>
                            );
                          })}
                        </Table>
                      ) : (
                        <></>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <button
                        type="submit"
                        style={{
                          width: "100%",
                          padding: ".5rem 3rem",
                          fontSize: "18px",
                          color: "green",
                        }}
                      >
                        Get payments
                      </button>
                    </Grid>
                  </Grid>
                  {payments?.monthlyPayments?.length > 0 ? (
                    <>
                      {" "}
                      <Grid item xs={12}>
                        <button
                          type="button"
                          style={{
                            width: "100%",
                            padding: ".5rem 3rem",
                            fontSize: "18px",
                            color: "green",
                            margin: "10px 0",
                          }}
                          onClick={() => {
                            submitOfferFound();
                          }}
                        >
                          Submit Offer
                        </button>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                </form>
              </>
            ) : (
              <></>
            )}
          </Typography>
        </Box>
      </Modal>
      <Modal
        open={openTwo}
        onClose={handleCloseTwo}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            align="center"
          >
            Application Status
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <h3>
              Time :{" "}
              {new Date(
                status?.application?.decision?.updated
              )?.toLocaleDateString("en-US", {
                month: "2-digit",
                year: "numeric",
                day: "2-digit",
              })}
            </h3>
            <h3>Status : {status?.application?.decision?.status}</h3>
            <h3>
              Expires :
              {new Date(
                status?.application?.decision?.expires
              )?.toLocaleDateString("en-US", {
                month: "2-digit",
                year: "numeric",
                day: "2-digit",
              })}
            </h3>
            <h3>Description : {status?.application?.decision?.description}</h3>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default SingleContract;
