import axios from "axios";
import {
  GET_COUNTRIES,
  GET_STATES,
  GET_CITIES,
  GET_SERVICES,
  GET_STATE_ID,
} from "./type";
import {
  COUNTRIES_URL,
  GET_STATE_ID_FROM_MAP,
  MAIN_URL,
  ServicesURL,
} from "../../API/Api";

export const loadCountries = () => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    await axios
      .get(`${COUNTRIES_URL}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      })
      .then((res) => {
        dispatch({
          type: GET_COUNTRIES,
          countries: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err.data);
      });
  };
};

export const loadStates = (id) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    await axios
      .get(`${MAIN_URL}/api/v1/countries/country/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      })
      .then((res) => {
        dispatch({
          type: GET_STATES,
          states: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err.data);
      });
  };
};

export const loadCities = (id) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    await axios
      .get(`${MAIN_URL}/api/v1/countries/country/state/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      })
      .then((res) => {
        dispatch({
          type: GET_CITIES,
          cities: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err.data);
      });
  };
};

export const loadServices = () => {
  return async (dispatch) => {
    dispatch({
      type: "IS_SERVICES",
    });
    const token = localStorage.getItem("token");
    await axios
      .get(`${ServicesURL}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      })
      .then((res) => {
        dispatch({
          type: GET_SERVICES,
          services: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err.data);
      });
  };
};

export const GetIdStateByState = (state) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    await axios({
      method: "GET",
      baseURL: `${GET_STATE_ID_FROM_MAP}/${state}`,
      headers: headers,
    })
      .then((res) => {
        dispatch({
          type: GET_STATE_ID,
          countryData: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err.data);
      });
  };
};
