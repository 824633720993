import {
  CONCERT_GET_OFFER,
  GET_CONCERT_PAYMENTS,
  GET_PRODUCT_METHODS,
  PREQUALIFY_CONCERT_PAYMENTS,
  SUBMIT_CONCERT_OFFER,
  GET_CONCERT_HOOK,
} from "../Actions/ConcertAction";

const inittialState = {
  createOffer: "",
  productMethods: [],
  concertPayments: "",
  concertAllData: "",
  preQualify: "",
  concertSubmitOffer: "",
  concertHook: "",
};

const ConcertReducer = (state = inittialState, action) => {
  switch (action.type) {
    case CONCERT_GET_OFFER:
      return {
        ...state,
        createOffer: action.payload,
      };
    case GET_PRODUCT_METHODS:
      return {
        ...state,
        createOffer: action.payload,
      };
    case GET_CONCERT_PAYMENTS:
      return {
        ...state,
        concertPayments: action.payload,
      };
    case PREQUALIFY_CONCERT_PAYMENTS:
      return {
        ...state,
        preQualify: action.payload,
      };
    case SUBMIT_CONCERT_OFFER:
      return {
        ...state,
        concertSubmitOffer: action.payload,
      };
    case GET_CONCERT_HOOK:
      return {
        ...state,
        concertHook: action.payload,
      };
    case "CONCERT_ALL_DATA":
      return {
        ...state,
        concertAllData: action.concertAllData,
      };

    default:
      return { ...state };
  }
};
export default ConcertReducer;
