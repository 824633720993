import styled from "styled-components";

export const DataContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 14%);
  margin: 0;
  border-radius: 10px;
  font-weight: 200;
  padding: 1rem;
  margin: 0 0 1rem;
  background-color: #fff;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  .user {
    flex-basis: 50%;
    border-radius: 1rem;
    &__data {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &--img {
        padding: 0.4rem;
        background-image: linear-gradient(135deg, #5efce8 10%, #736efe 100%);
        height: 6rem;
        width: 6rem;
        overflow: hidden;
        border-radius: 50%;
        margin-right: 10px;
        margin-bottom: 0.5rem;

        svg {
          color: #1dbce5;
          width: 100%;
          height: 100%;
          /* font-size: 6rem; */
          object-fit: cover;
          border-radius: 100%;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 100%;
        }
      }

      &--content {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        margin-left: 0.5rem;
        a {
          color: var(--title);
        }
        .title {
          font-size: 1rem;
          font-weight: 600;
          text-transform: capitalize;
          color: var(--title);
          padding: 0;
          margin: 0;
        }
        .captain {
          display: block;
          font-size: 14px;
          color: var(--captain);
          text-transform: capitalize;
          margin: 0.2rem 0;
        }
      }
    }
  }
  .search {
    flex-basis: 50%;
    display: flex;
    align-items: flex-end;
    width: 100%;
    justify-content: flex-end;
    .icon {
      font-size: 1.7rem;
      color: var(--dark-gray);
      flex-basis: 0%;
      cursor: pointer;
    }
    .search-input {
      flex-basis: 80%;
      margin-left: 2rem;
      position: relative;
      form {
        position: relative;
        width: 100%;
      }
      .form-control {
        position: relative;
        font-size: 15px;
        width: 100%;
        border-radius: 3rem;
        height: 44px;
        border: none;
        padding: 16px;
        border: 2px solid #e6eaf0;
        overflow: hidden;
        &:focus {
          outline: none;
          border-top: 2px solid #76adff;
          border-left: 2px solid #76adff;
          border-right: 2px solid #76adff;
        }
        &::placeholder {
          color: var(--title);
        }
      }
      .searchIcon {
        position: absolute;
        right: 12px;
        font-size: 18px;
        top: 50%;
        transform: translateY(-50%);
        color: #b4b7bd;
      }

      .suggestMenu {
        display: none;
        position: absolute;
        top: 44px;
        width: 100%;
        left: 0;
        border-radius: 0.4rem;
        z-index: 999;
        box-shadow: -1px 0px 8px 0px rgba(0, 0, 0, 0.76);
        -webkit-box-shadow: -1px 0px 8px 0px rgba(0, 0, 0, 0.76);
        -moz-box-shadow: -1px 0px 8px 0px rgba(0, 0, 0, 0.76);
        background-color: white;
        border-top: 2px solid #76adff;
        border-left: 2px solid #76adff;
        border-right: 2px solid #76adff;
        border-bottom: 2px solid #76adff;
        box-shadow: 0px 3px 6px rgb(0 0 0 / 14%);
        margin: 0;
        border-radius: 10px;
        font-weight: 200;
        padding: 1rem;
        margin: 0 0 1rem;
        li {
          padding: 2px 0px;
          transition: 0.5s all;

          a {
            color: black;
            padding-left: 0.5rem;
          }
          &:hover {
            color: black;
            padding-left: 8px;
            a {
              color: #76adff;
            }
          }
        }
      }
      &:focus-within {
        .suggestMenu {
          display: block;
        }
      }
    }
  }
`;
