import React from "react";
import Header from "../../../../components/Header/Header";
import { useLocation, useSearchParams } from "react-router-dom";
import { Bigsection } from "./style";
import { Grid } from "@mui/material";

const InstallerAdders = () => {
  const [search, setSearchParams] = useSearchParams();
  const arr = JSON.parse(search.get("arr"));

  console.log("arr", arr);
  const mapping = () => {
    return arr?.items?.map((e, index) => {
      return (
        <tr key={index}>
          <td>{e?.Item}</td>
          <td>{e?.Quantity}</td>
          <td>${e?.UnitPrice}</td>
          <td>${e?.Total}</td>
          <td>{e?.Explain}</td>
          {/* <td>{e?.condition}</td> */}
        </tr>
      );
    });
  };

  return (
    <>
      <Header />
      <Bigsection>
        <Grid container columns={18} spacing={2}>
          <Grid item xs={18}></Grid>
          <Grid item xs={18} sx={{ m: "2rem" }}>
            <table>
              <tr>
                <th>Adder</th>
                <th>Quantity</th>
                <th>Adder Price</th>
                <th>Total</th>
                <th>Explain</th>
                {/* <th>condition</th> */}
              </tr>
              {mapping()}
            </table>
          </Grid>
        </Grid>
      </Bigsection>
    </>
  );
};

export default InstallerAdders;
