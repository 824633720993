import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import styled from "styled-components";

const Div = styled.div`
  height: 250px;
  width: 100%;
  margin: 30px auto;
  padding: 0;
  text-align: center;
`;

const RoyalitiesCharts = ({ SalseUser }) => {
  const potentialEarning = SalseUser?.potential_earning || [];
  const royalities = SalseUser?.royalties || [];
  // const [allPotentialEarnings, setAllPotentialEarnings] = React.useState([]);
  // const [allRoyalities,setAllRoyalities]=React.useState([])
  const FilteredpotentialEarning = potentialEarning.filter(
    (e) => e.royalties !== null
  );
  const filteredArrayRoyaliites = royalities.filter(
    (e) => e.royalties !== null
  );

  let potentialroyalitites = FilteredpotentialEarning.map((e) => e.royalties);
  let royalitites = filteredArrayRoyaliites.map((e) => e.royalties);
  // console.log("potentialroyalitites",potentialroyalitites)

  let allPotentialNUmbers = potentialroyalitites.map(Number);
  let allRoyalities = royalitites.map(Number);

  // console.log("allPotentialNUmbers",allPotentialNUmbers)
  let sumForPotential = 0;
  let sumForRoyalities = 0;

  for (let i = 0; i < allPotentialNUmbers.length; i++) {
    sumForPotential += allPotentialNUmbers[i];
  }
  for (let i = 0; i < allRoyalities.length; i++) {
    sumForRoyalities += allRoyalities[i];
  }
  // console.log("sum",sumForPotential)
  // console.log("sum",sumForRoyalities)
  // All Royalitites

  const data = [
    {
      name: "potential Earnings",
      uv: 0,
      pv: sumForPotential,
      amt: 0,
    },
    {
      name: "Royalties",
      uv: 3000,
      pv: sumForRoyalities,
      amt: 2210,
    },
  ];

  return (
    <>
      <Div>
        <p>
        Royalties :{" "}
          <span style={{ color: "#85d4bb" }}>$ {sumForRoyalities}</span>{" "}
        </p>
        <p>
          Potential Earnings :{" "}
          <span style={{ color: "#85d4bb" }}>$ {sumForPotential}</span>{" "}
        </p>

        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            barSize={18}
            // barCategoryGap={1}
          >
            <XAxis
              dataKey="name"
              scale="point"
              padding={{ left: 10, right: 10 }}
            />
            <YAxis />
            <Tooltip />
            {/* <Legend /> */}
            <CartesianGrid strokeDasharray="3 3" />
            <Bar dataKey="pv" fill="#85d4bb" background={{ fill: "#eee" }} />
            {/* <Bar dataKey="uv" fill="#88d8" background={{ fill: '#eee' }} /> */}
            {/* <Bar dataKey="amit" fill="#8884" background={{ fill: '#eee' }} /> */}
          </BarChart>
        </ResponsiveContainer>
      </Div>
    </>
  );
};

export default RoyalitiesCharts;
