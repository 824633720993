import React, { useState } from "react";

const PdfPage = () => {
  return (
    <div>
      <h2>Upload and View PDF</h2>
    </div>
  );
};

export default PdfPage;
