import { GET_ZOOM, POST_ZOOM } from "../Actions/type";

const initialState = {
  meetings: [],
  topic: "",
  start_time: "",
  duration: "",
  password: "",
};

const zoomReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ZOOM:
      return {
        ...state,
        meetings: action.payload.meetings.data,
      };

    case POST_ZOOM:
      return {
        ...state,
        topic: action.topic,
        start_time: action.start_time,
        duration: action.duration,
        password: action.password,
      };

    default:
      return { ...state };
  }
};

export default zoomReducer;
