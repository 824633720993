import { ADD_ROOFING_SOLUTION } from "../Actions/addRoofingAction";
import { CREATE_ROOFING_CONTRACT } from "../Actions/addRoofingAction";
import { IS_SUCCESSFUL } from "../Actions/addRoofingAction";
import { DELETE_ROOFING_CONTRACT } from "../Actions/addRoofingAction";
import { SEND_ROOFING_CONTRACT } from "../Actions/addRoofingAction";
import { SEND_CONTRACT_TO_SIGN } from "../Actions/addRoofingAction";
const inittialState = {
  allData: "",
  success: false,
  contract: "",
  delete: "",
  sendContract: "",
  senMail: "",
};
const RoofingReducer = (state = inittialState, action) => {
  switch (action.type) {
    case ADD_ROOFING_SOLUTION:
      return {
        ...state,
        allData: action.payload,
      };

    case IS_SUCCESSFUL:
      return {
        ...state,
        isSuccess: true,
      };
    case CREATE_ROOFING_CONTRACT:
      return {
        ...state,
        contract: action.payload,
      };
    case DELETE_ROOFING_CONTRACT:
      return {
        ...state,
        contract: action.payload,
      };
    case SEND_ROOFING_CONTRACT:
      return {
        ...state,
        contract: action.payload,
      };
    case SEND_CONTRACT_TO_SIGN:
      return {
        ...state,
        senMail: action.payload,
      };

    default:
      return { ...state };
  }
};
export default RoofingReducer;
