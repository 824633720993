import { GET_LICENSE, CREATE_LICENSE } from "../Actions/type";

const initialState = {
  allLicenses: "",
  createLicenseErr: "",
};

const licenseReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LICENSE:
      return {
        ...state,
        allLicenses: action.payload,
      };
    case CREATE_LICENSE:
      return {
        ...state,
        createLicenseErr: action.payload,
      };
      return {
        ...state,
        editAcievement: action.payload,
      };

    default:
      return { ...state };
  }
};

export default licenseReducer;
