import React from "react";
import { FormProvider, useForm, SubmitHandler } from "react-hook-form";
import StanderInput from "../../../../components/inputs/standerInput";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitButton } from "../../../../components/common/submitButton";
import { Grid } from "@mui/material";
import { useInviteSalesMutation } from "../../../../Redux/toolKit/salesForm";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";

const schema = yup.object().shape({
  email: yup.string().email("Ivalid Email").required(),
  name: yup.string().required(),
});

const InviteUser = () => {
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const [invite, { isLoading, isError, error }] = useInviteSalesMutation();
  const onSubmit = async (data) => {
    try {
      await invite(data).unwrap();
      toast.success("Invitation has been sent successful");
      methods.reset();
      methods.reset({
        email: "",
        name: "",
      });
    } catch (e) {
      console.log("error?.status", e.status);
      toast.error(`Error ${e?.status == 500 ? ": Server Error" : ""}`);
    }
  };
  const renderErrors = () => {
    if (error.status == 422) {
      return Object?.keys(error?.data?.error || {})?.map((key) => {
        return (error?.data?.error[key] || [])?.map((err, index) => (
          <p
            style={{ color: "red" }}
            key={`${key}-${index}`}
          >{`${key}: ${err}`}</p>
        ));
      });
    }
    if (error.status == 503) {
      return (
        <p style={{ color: "red" }}>
          The mail server could not deliver mail, account or domain may not
          exist, they may be blacklisted, or missing the proper dns entries.
        </p>
      );
    }
    if (error.status == 500) {
      <p style={{ color: "red" }}>Server Error</p>;
    }
    return;
  };

  return (
    <div>
      <h1>Invite User</h1>
      {error && (
        <>
          <h1>Error</h1>
          {renderErrors()}
        </>
      )}

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container columns={12} spacing={3} sx={{ pr: "2rem" }}>
            <Grid item xs={12}>
              <StanderInput name="email" Label={"Email"} />
            </Grid>
            <Grid item xs={12}>
              <StanderInput name="name" Label="name" />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <SubmitButton
                fullWidth
                type="submit"
                disabled={isLoading}
                endIcon={
                  isLoading && (
                    <CircularProgress sx={{ color: "white" }} size={20} />
                  )
                }
              >
                Submit
              </SubmitButton>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </div>
  );
};

export default InviteUser;
