import { Add } from "@mui/icons-material";
import _ from "lodash";
import { Button, Grid } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  UploadSiteMedia,
  UploadUtilityBill,
} from "../../../Redux/Actions/solutionsActions";
import LoadingSoultion from "../../../components/loading/loadingSolution";
import "photoswipe/dist/photoswipe.css";

import { Gallery, Item } from "react-photoswipe-gallery";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import { MAIN_URL } from "../../../API/Api";
import { CreateTicket } from "../../../Redux/Actions/CreateTicket";

const smallItemStyles = {
  cursor: "pointer",
  // objectFit: "cover",
  width: "100%",
  maxHeight: "100%",
};
const DocumentsTab = ({ LeadId }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { isLoading } = useSelector((state) => state.proposal);
  const { singlead } = useSelector((state) => state.leads);
  const location = useLocation();
  LeadId = location.pathname.split("/")[2];

  console.log("singlead", singlead?.solar_contracts);
  const [images, setImages] = React.useState([]);

  const [utiltyBill, setUtiltyBill] = useState({
    media: [],
  });
  const [sitMedia, setSitMedia] = useState({
    fileData: [],
  });
  console.log("site", sitMedia, "bill", utiltyBill);
  console.log("name", sitMedia?.fileData[0]?.name);
  /////////////////////
  const handleUtility = (e) => {
    e.preventDefault();
    const formData = new FormData();
    for (let i = 0; i < utiltyBill.media.length; i++) {
      formData.append("media[]", utiltyBill.media[i]);
    }
    dispatch(UploadUtilityBill(LeadId, formData));
  };
  //////////////////
  const handleSiteMedia = (e) => {
    e.preventDefault();
    const data = new FormData();
    for (let i = 0; i < sitMedia.fileData.length; i++) {
      data.append("media[]", sitMedia.fileData[i]);
    }
    dispatch(UploadSiteMedia(LeadId, data));
  };

  const token = localStorage.getItem("token");
  React.useEffect(() => {
    axios({
      method: "get",
      url: `${MAIN_URL}/api/v1/leads/${id}/media`,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    }).then(function (response) {
      setImages(response.data.data);
    });
  }, []);
  console.log("utiltyBill?.media", utiltyBill?.media);
  let newArrUtlitiy = Object.values(utiltyBill?.media);
  let newArrSiteMedia = Object.values(sitMedia?.fileData);
  let ticektData = {
    lead_id: id,
  };
  const createTicket = () => {
    dispatch(CreateTicket(ticektData));
  };
  console.log(
    "singlead?.solar_contracts?.length",
    singlead?.solar_contracts?.length > 0
  );
  return (
    <>
      {isLoading && <LoadingSoultion />}
      <FormConatiner>
        <h3>Document</h3>

        <Grid container columns={18} spacing={6}>
          <Grid item md={9} sm={9} mb={2}>
            <form onSubmit={handleUtility}>
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*,video/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  // value={utiltyBill.media}
                  onChange={(e) =>
                    setUtiltyBill({
                      ...utiltyBill,
                      media: e.target.files,
                    })
                  }
                />
                <Button component="span" className="btn_upload">
                  <span className="icon">
                    <Add />
                  </span>
                  <span className="title">Upload Utility Bill</span>
                </Button>
                {newArrUtlitiy.map((e, i) => {
                  return (
                    <p>
                      <span style={{ color: "green" }}> {++i}</span>{" "}
                      <span style={{ color: "blue" }}>{e.name}</span>{" "}
                      <span style={{ padding: "0 .5rem" }}>
                        {e.size / 1024} Kb
                      </span>
                    </p>
                  );
                })}
              </label>
              <Button
                variant="contained"
                type="submit"
                fullWidth
                className="submitDoc"
              >
                Submit
              </Button>
            </form>
          </Grid>
          <Grid item md={9} sm={9} mb={2}>
            <form onSubmit={handleSiteMedia}>
              <label htmlFor="site-media">
                <Input
                  accept="image/*,video/*"
                  id="site-media"
                  multiple
                  type="file"
                  onChange={(e) =>
                    setSitMedia({
                      ...sitMedia,
                      fileData: e.target.files,
                    })
                  }
                />
                <Button component="span" className="btn_upload">
                  <span className="icon">
                    <Add />
                  </span>
                  <span className="title">Upload Site Media</span>
                </Button>
                {newArrSiteMedia.map((e, i) => {
                  return (
                    <p>
                      <span style={{ color: "green" }}> {++i}</span>{" "}
                      <span style={{ color: "blue" }}>{e.name}</span>{" "}
                      <span style={{ padding: "0 .5rem" }}>
                        {e.size / 1024} Kb
                      </span>
                    </p>
                  );
                })}
              </label>
              <Button
                variant="contained"
                type="submit"
                fullWidth
                className="submitDoc"
              >
                Submit
              </Button>
            </form>
          </Grid>
        </Grid>

        {/* {images.length == 0 ? (
        <span>No photos for the user</span>
      ) : (
        <div>
          {images.map((e) => {
            return (
              <Grid container columns={12}>
                <Grid item lg={4} md={4}>
                  <Gallery withDownloadButton>
                    <div
                    // style={{
                    //   display: "grid",
                    //   // gridTemplateColumns: "240px 171px 171px",
                    //   // gridTemplateRows: "114px 114px",
                    //   gridGap: 2,
                    // }}
                    >
                      <Item
                        original={e?.media_url?.display}
                        width="1024"
                        height="768"
                      >
                        {({ ref, open }) => (
                          <img
                            // style={{ cursor: "pointer" }}
                            style={smallItemStyles}
                            ref={ref}
                            onClick={open}
                            // src={e?.media_url?.display}
                            src={e?.media_url?.thumbnail}
                          />
                        )}
                      </Item>
                    </div>
                  </Gallery>
                </Grid>
              </Grid>
            );
          })}
        </div>
      )} */}
        {images.length > 0 ? (
          <>
            <div>
              <h3>Photos</h3>
              <br />
              {images.map((e) => {
                return (
                  <Gallery>
                    {/* <Div> */}
                    <Item
                      original={e?.media_url?.display}
                      // thumbnail="https://placekitten.com/80/60?image=1"
                      width="1024"
                      height="768"
                    >
                      {({ ref, open }) => (
                        <img
                          style={{
                            verticalAlign: "middle",
                            cursor: "pointer",
                            width: "15%",
                            padding: "0 10px",
                          }}
                          ref={ref}
                          onClick={open}
                          src={e?.media_url?.thumbnail}
                        />
                      )}
                    </Item>
                    {/* </Div> */}
                  </Gallery>
                );
              })}
              {/* {singlead?.solar_contracts?.length > 0 ? (
              <>
                {singlead?.has_ticket !== 1 ? (
                  <CreateButtton onClick={createTicket}>
                    Create Ticket
                  </CreateButtton>
                ) : (
                  <CreateButtton>Ticket has been already Created</CreateButtton>
                )}
              </>
            ) : (
              <p style={{ color: "red", fontSize: "15px" }}>
                You need to create Ticket First
              </p>
            )} */}
            </div>
          </>
        ) : (
          <span>No photos for the user</span>
        )}
      </FormConatiner>
    </>
  );
};

const FormConatiner = styled.div`
  padding: 24px;
  .btn_upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #81a7ca;
    background: #eee;
    justify-content: space-around;
    padding: 3rem 0;
    border-radius: 2rem;
    margin: 2rem 0;
    text-transform: capitalize;
    .icon {
      svg {
        font-size: 4rem;
      }
    }
    .title {
      font-size: 1.1rem;
    }
  }
  .submitDoc {
    padding: 0.5rem 0;
    border-radius: 2rem;
    font-size: 1.2rem;
    background: #5588b8;
  }
`;
const Input = styled.input`
  display: none;
`;
const Div = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 10px;
  padding: 10px;
`;
const CreateButtton = styled.button`
  width: 100%;
  background-color: #0085ff;
  color: white;
  padding: 0.8rem 0;
  border-radius: 4px;
  border: none;
  margin-top: 2rem;
  font-size: 1.3rem;
  transition: 0.3s;
  font-weight: 700;
  cursor: pointer;
  &:hover {
    transform: translateY(-0.3rem);
  }
`;

export default DocumentsTab;
