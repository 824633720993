import { GET_ALL_EVENTS } from "../../Redux/Actions/allEventsAction";
import { POST_EVENT } from "../../Redux/Actions/allEventsAction";
import {DELETE_EVENT} from "../../Redux/Actions/allEventsAction";
import {ADD_NOTE} from "../../Redux/Actions/allEventsAction"

const inittialState = {
    allEvents: "",
    loading: false,
    data: "",
    deletedData:"",
    AddNote:""
}
const allEventsReducer = (state = inittialState, action) => {
    switch (action.type) {
        case GET_ALL_EVENTS:
            return {
                ...state,
                allEvents: action.payload
            };
        case POST_EVENT:
            return {
                ...state,
                data: action.payload
            }
            case ADD_NOTE:
                return{
                    ...state,
                    addNote:action.payload
                }

            case DELETE_EVENT:
                return{
                    ...state,
                    deletedData:action.payload     
                }


        default:
            return { ...state }
    }

}
export default allEventsReducer