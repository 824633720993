import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./helper/index.js";
export const EnfinApi = createApi({
  reducerPath: "EnfinApi",
  baseQuery,
  tagTypes: ["enfin", "singleLead"],
  endpoints: (builder) => ({
    getEnfinRates: builder.query({
      query: () => ({
        url: "/enfin/rates",
        method: "get",
      }),
    }),

    clacEnfin: builder.mutation({
      query: (body) => ({
        url: "/enfin/calculator",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useClacEnfinMutation, useGetEnfinRatesQuery } = EnfinApi;
