import { Notifications, NotificationsActive } from "@mui/icons-material";
import Pusher from "pusher-js";
import { useEffect, useState } from "react";
// import { pusher } from "../../lib";
import Notified from "./Notified";
import { ProdSection } from "./ProductivityStyle";

// import { pusher } from "../../lib";

const Productivity = () => {
  const [fullData, setFullData] = useState({});

  useEffect(() => {
    const PusherFun = () => {
      const pusher = new Pusher("45749c0efdaffb06cce4", {
        cluster: "us3",
      });

      const channel = pusher.subscribe("opportunity-placed");
      channel.bind("App\\Notifications\\OpportunityPlaced", function (data) {
        // setFullData({ ...fullData, ...data.opportunity });
        setFullData({ ...fullData, ...data.opportunity });
      });
      Pusher.logToConsole = true;
    };
    if (fullData) PusherFun();
    return;
  }, [fullData]);
  // console.log(fullData, "fullData");

  return (
    <ProdSection>
      <div className="prodConatiner">
        <div className="header">
          <h4>
            <span>productivity</span> <NotificationsActive />
          </h4>

          {/* <Notified fullData={fullData} /> */}
        </div>
      </div>
    </ProdSection>
  );
};

export default Productivity;
