import styled from "styled-components";

export const ProdSection = styled.section`
  .prodConatiner {
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 14%);

    background: #fff;
    .header {
      h4 {
        display: flex;
        align-items: center;
        font-size: 1.6rem;
        text-transform: capitalize;
        color: #919191;
      }
    }
  }
`;
