import { InputLabel } from "@mui/material";
import styled from "styled-components";

export const Bigsection = styled.div`
  margin: 1rem 0;
  h3 {
    margin-bottom: 2rem;
  }
  .btContainer {
    text-align: center;
  }
  .create {
    padding: 1rem 0;
    width: 50%;
    margin: 0 auto;
    cursor: pointer;
    border: none;
    border-radius: 2rem;
    padding: 0.7rem 3rem;
    background-size: 400% 100%;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    background-image: linear-gradient(
      132.6deg,
      rgba(71, 139, 214, 1) 23.3%,
      rgba(37, 216, 211, 1) 84.7%
    );
    color: white;
    &:hover {
      background-position: 100% 0;
      -webkit-text-decoration: none;
      text-decoration: none;
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
      background-image: linear-gradient(
        132.6deg,
        rgba(71, 139, 214, 1) 23.3%,
        rgba(37, 216, 211, 1) 84.7%
      );
    }
  }
  .form {
    padding: 1rem;
  }
  .talbeOfTargets {
    table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
    }

    td,
    th {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }

    tr:nth-child(even) {
      background-color: #dddddd;
    }
  }
`;
export const MyInputLable = styled(InputLabel)`
  && {
    font-size: 12px;
  }
`;
export const Loading = styled.div`
  display: flex;
  min-height: 60vh;
  justify-content: center;
  align-items: center;
  .loader {
    width: 48px;
    height: 48px;
    border: 5px solid #fff;
    border-bottom-color: rgb(33, 43, 35);
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
export const MainTabsStyled = styled.div`
  margin: 2rem;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  padding: 2rem 1rem;
  border-radius: 1rem;
  .headerNavLink {
    a {
      position: relative;
      &.active {
        color: #2bc78c;
        &:after {
          position: absolute;
          width: 100%;
          content: "";
          background-color: #2bc78c;
          bottom: 0;
          left: 0;
          height: 0.1rem;
        }
      }
    }
  }
`;
