import { Container } from "@mui/material";
import PageFIvePdf from "../../components/proposalsPdfComponents/SolarPdfComponents/PageFIvePdf";
import PageFourPdf from "../../components/proposalsPdfComponents/SolarPdfComponents/PageFourPdf";
import PageOnePdf from "../../components/proposalsPdfComponents/SolarPdfComponents/PageOnePdf";
import PageSixPdf from "../../components/proposalsPdfComponents/SolarPdfComponents/PageSixPdf";
import PageThreePdf from "../../components/proposalsPdfComponents/SolarPdfComponents/PageThreePdf";
import PageTwoPdf from "../../components/proposalsPdfComponents/SolarPdfComponents/PageTwoPdf";
import PageSevenPdf from "../../components/proposalsPdfComponents/SolarPdfComponents/PageSevenPdf";
import { useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
import { singleLeadAction } from "../../Redux/Actions/singleLeadAction";
import {singleOpportunitiesAction} from "../../Redux/Actions/SingleOpportunitieAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const ProposalsInfo = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const LEAD_ID = location.pathname.split("/")[2];
  const { id } = useParams();
  console.log(id);
  useEffect(() => {
    dispatch(singleLeadAction(LEAD_ID));
  }, [dispatch]);
  useEffect(() => {
    dispatch(singleOpportunitiesAction(id));
  }, [dispatch]);

  const { singlead, singleOpportunity } = useSelector((state) => state.leads);
  const { name } = useSelector((state) => state.auth);

  return (
    <>
      <Container>
        <PageOnePdf
          firstName={singlead.first_name}
          lastName={singlead.last_name}
          email={singlead.email}
          location={singlead.location}
          nameBy={name}
        />
        <PageTwoPdf />
        <PageThreePdf singleOpportunity={singleOpportunity} />
        <PageFourPdf singleOpportunity={singleOpportunity} />
        <PageFIvePdf />
        <PageSixPdf singleOpportunity={singleOpportunity} />
        <PageSevenPdf
          singlead={singlead}
          singleOpportunity={singleOpportunity}
        />
      </Container>
    </>
  );
};

export default ProposalsInfo;
