import axios from "axios";
import { GET_NOTIFICATIONS_URL, MAIN_URL } from "../../API/Api";
import { SignOut } from "./authActions";
import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_READ,
  IS_NOTIFICATION,
} from "./type";

export const getNotifications = () => async (dispatch) => {
  dispatch({
    type: IS_NOTIFICATION,
  });
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  await axios({
    method: "GET",
    baseURL: `${GET_NOTIFICATIONS_URL}`,
    headers: headers,
  })
    .then((res) => {
      dispatch({
        type: GET_NOTIFICATIONS,
        notification: res.data.data,
      });
    })
    .catch((err) => {
      console.log(err.data);
      if (err?.response?.status === 401) {
        dispatch(SignOut());
      }
    });
};

export const getNotificationsRead = (id) => async (dispatch) => {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  await axios({
    method: "GET",
    baseURL: `${MAIN_URL}/api/v1/notifications/notification/${id}`,
    headers: headers,
  })
    .then((res) => {
      dispatch({
        type: GET_NOTIFICATIONS_READ,
        notificationRead: res.data.data,
      });
    })
    .catch((err) => {
      console.log(err.data);
      if (err?.response?.status === 401) {
        dispatch(SignOut());
      }
    });
};
