import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import React, { useState } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";

import "react-big-calendar/lib/css/react-big-calendar.css";
// import DatePicker from "react-datepicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
//Redux
import { useSelector, useDispatch } from "react-redux";
import { getEvents } from "../../../../Redux/Actions/allEventsAction";
import { DeleteEventt } from "../../../../Redux/Actions/allEventsAction";
import styled from "styled-components";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import axios from "axios";
import { AddEventt } from "../../../../Redux/Actions/allEventsAction";
import { AddNotes } from "../../../../Redux/Actions/allEventsAction";
import CloseIcon from "@mui/icons-material/Close";
import "./Calender.css";
import { MAIN_URL } from "../../../../API/Api";
const Div = styled.div`
  text-align: center;
  width: 100%;
`;
const Inputs = styled.div`
  display: inline-block !important;
`;
const Button2 = styled.button`
  border: none;
  border-radius: 5px;
  padding: 7px 50px;
  margin-top: 5px;
  background-color: #d2d2d2;
  &:foucs {
    border: none;
  }
`;
const EventDIv = styled.div``;
const Label = styled.label`
  color: blue;
`;
const ThisEvent = styled.p`
  font-size: 20px;
  color: blue;
  font-weight: 700;
`;
const DataInput = styled.input`
  text-align: center;
  border-radius: 0;
  width: 100%;
`;
const DeleteButton = styled.button`
  background-color: red;
  padding: 5px 15px;
  border: none;
  border-radius: 15px;
  color: white;
  text-align: left;
`;
const AddNote = styled.button`
  background-color: white;
  border: none;
  border-radius: 15px;
`;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};
const styleSelectevent = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

const locales = {
  "en-US": require("date-fns/locale/en-US"),
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

// const events = [{}, {}, {}];

const Calender = () => {
  var currentDate = new Date();
  const [newEvent, setNewEvent] = useState({
    id: Number(""),
    title: "",
    description: "",
    start: "",
    end: "",
    type: "add",
    notes: "",
    comments: "",
  });

  const eventURl = `${MAIN_URL}/api/v1/events`;
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
    "X-Requested-With": "XMLHttpRequest",
  };
  // API get EVENTS
  const dispatch = useDispatch();
  const allEventsFromRedux =
    useSelector((state) => state.events.allEvents) || [];

  allEventsFromRedux.map((e) => {
    e.start = new Date(Date.parse(e.start));
    e.end = new Date(Date.parse(e.end));
  });

  React.useEffect(() => {
    dispatch(getEvents());
  }, [dispatch]);
  // ENd OF GET EVENTS

  // Moadal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  // API POST EVENTS
  const handleAddEvent = async () => {
    // await axios({
    //   method: "post",
    //   url: eventURl,
    //   headers: headers,
    //   data: newEvent,
    // });
    await dispatch(AddEventt(newEvent));

    handleClose();

    window.location.reload();
  };
  // ENd OF POST EVENTS

  // Select Event
  const [selectEvent, setSelectedEvent] = React.useState();

  // Select Event MOdal

  const [openEvent, setOpenEvent] = React.useState(false);
  const handleOpenEvent = () => setOpenEvent(true);
  const handleCloseEvent = () => setOpenEvent(false);

  const getRestOfEvent = (event_id) => {
    const thisEvent = allEventsFromRedux.find((e) => e.id === event_id);
    setSelectedEvent(thisEvent);
  };
  // Delete Event

  const deleteEvent = {
    type: "delete",
    id: selectEvent == undefined ? <div></div> : selectEvent.id,
  };
  // console.log("deleteEvent",deleteEvent)

  const DeleteEvent = async () => {
    const id = selectEvent.id;
    // console.log(id, "id");
    // await axios({
    //   method: "POST",
    //   url: `https://crm.boxbyld.tech/api/v1/events/delete`,
    //   headers: headers,
    //   data: deleteEvent
    // })
    await dispatch(DeleteEventt(deleteEvent));
    window.location.reload();
  };

  // Add Notes
  const AddNotesData = {
    task_id: selectEvent == undefined ? <div></div> : selectEvent.id,
    note: newEvent.notes,
    author: "ahmed",
    password: "123",
  };

  const addNotes = async () => {
    const id = selectEvent.id;
    // await axios({
    //   method: "POST",
    //   url: `https://crm.boxbyld.tech/api/v1/notes`,
    //   headers: headers,
    //   data: AddNotesData
    // })
    await dispatch(AddNotes(AddNotesData));
    window.location.reload();
  };

  // Selected Event NOtes
  const SelectedEventNotes = [""];
  selectEvent == undefined ? (
    <div></div>
  ) : (
    selectEvent.notes.map((e) => {
      SelectedEventNotes.push(e);
    })
  );
  // Try
  const handleSubmitNote = (event) => {
    event.preventDefault();
  };

  // Modal to make sure delete
  const [openDelete, setOpenDelete] = React.useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);
  return (
    <Div>
      {/* Add Event Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* <form> */}
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item lg={12}>
                <label>Title </label>
                <DataInput
                  required
                  value={newEvent.title}
                  onChange={(e) =>
                    setNewEvent({ ...newEvent, title: e.target.value })
                  }
                  type="text"
                ></DataInput>
              </Grid>
              <Grid item lg={12}>
                <label>Description </label>
                <DataInput
                  type="text"
                  value={newEvent.description}
                  onChange={(e) =>
                    setNewEvent({ ...newEvent, description: e.target.value })
                  }
                ></DataInput>
              </Grid>
              <Grid item lg={12}>
                <label>Start </label>
                <DataInput
                  className="myInput"
                  type="datetime-local"
                  placeholder="start date"
                  value={newEvent.start}
                  onChange={(e) =>
                    setNewEvent({ ...newEvent, start: e.target.value })
                  }
                ></DataInput>
              </Grid>

              <Grid item lg={12}>
                <span>End </span>
                <DataInput
                  className="myInput"
                  type="datetime-local"
                  placeholder="Date"
                  label="Date"
                  value={newEvent.end}
                  onChange={(e) =>
                    setNewEvent({ ...newEvent, end: e.target.value })
                  }
                ></DataInput>
              </Grid>
            </Grid>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Button2 type="submit" onClick={handleAddEvent}>
              Add Event
            </Button2>
            <button
              style={{
                position: "absolute",
                top: "0",
                right: "0",
                border: "none",
              }}
              onClick={handleClose}
            >
              <CloseIcon></CloseIcon>
            </button>
          </Typography>
        </Box>
        {/* </form> */}
      </Modal>
      <Inputs>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid Item>
            <h4 style={{ color: "#1976D2" }}>Add New Event</h4>

            <Button onClick={handleOpen}>Click me to create Event</Button>
          </Grid>
        </Grid>
      </Inputs>

      {/* Event Modal */}

      <Modal
        open={openEvent}
        onClose={handleCloseEvent}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleSelectevent}>
          <button
            style={{
              position: "absolute",
              top: "0",
              right: "0",
              border: "none",
            }}
            onClick={handleCloseEvent}
          >
            <CloseIcon></CloseIcon>{" "}
          </button>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="stretch"
            >
              {/* First */}
              <Grid item lg={10}>
                <ThisEvent>SELECTED EVENT</ThisEvent>
                <div style={{ textAlign: "left" }}>
                  <p>
                    <Label>EVENT TITLE</Label>{" "}
                    <span style={{ color: " #FF9900" }}>
                      {selectEvent == undefined ? (
                        <div></div>
                      ) : (
                        selectEvent.title
                      )}
                    </span>
                  </p>
                  <p style={{ padding: "15px 0" }}>
                    <Label>EVENT description</Label>{" "}
                    <span style={{ color: " #FF9900" }}>
                      {selectEvent == undefined ? (
                        <div></div>
                      ) : (
                        selectEvent.description
                      )}
                    </span>
                  </p>
                  <p>
                    <Label>EVENT START DATE</Label>{" "}
                    <span style={{ color: " #FF9900" }}>
                      {selectEvent == undefined ? (
                        <div></div>
                      ) : (
                        new Date(selectEvent.start).toLocaleDateString(
                          "en-US",
                          { day: "2-digit", month: "2-digit", year: "numeric" }
                        )
                      )}
                      <span style={{ padding: "0 20px;" }}></span>{" "}
                      {selectEvent == undefined ? (
                        <div></div>
                      ) : (
                        new Date(selectEvent.start).toLocaleTimeString("en-US")
                      )}
                    </span>
                  </p>
                  <p style={{ padding: "15px 0" }}>
                    <Label>EVENT END DATE</Label>{" "}
                    <span style={{ color: " #FF9900" }}>
                      {selectEvent == undefined ? (
                        <div></div>
                      ) : (
                        new Date(selectEvent.end).toLocaleDateString("en-US", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        })
                      )}
                      <span style={{ padding: "0 20px;" }}></span>{" "}
                      {selectEvent == undefined ? (
                        <div></div>
                      ) : (
                        new Date(selectEvent.end).toLocaleTimeString("en-US")
                      )}
                    </span>
                  </p>
                </div>

                <div style={{ textAlign: "left" }}>
                  <DeleteButton onClick={handleOpenDelete}>
                    Delete Event
                  </DeleteButton>
                  <Modal
                    open={openDelete}
                    onClose={handleCloseDelete}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <p>Are you sure you want delete the Event</p>
                        <button
                          style={{ padding: "1px 10px" }}
                          onClick={DeleteEvent}
                        >
                          yes
                        </button>{" "}
                        <button
                          style={{ padding: "1px 10px" }}
                          onClick={handleCloseDelete}
                        >
                          No
                        </button>
                      </Typography>
                    </Box>
                  </Modal>
                </div>
              </Grid>
              {/* Second */}
              <Grid item lg={8}>
                <Label>Notes </Label>
                <div
                  style={{
                    height: "150px",
                    overflow: "auto",
                    textAlign: "center",
                  }}
                >
                  <p>
                    {SelectedEventNotes.map((e) => {
                      return <p style={{ color: "purple" }}>{e.note}</p>;
                    })}
                  </p>
                </div>

                <Grid item lg={12}>
                  <form onSubmit={handleSubmitNote}>
                    <DataInput
                      required
                      value={newEvent.notes}
                      onChange={(e) =>
                        setNewEvent({ ...newEvent, notes: e.target.value })
                      }
                      type="text"
                    ></DataInput>
                    <Button type="submit" onClick={addNotes} color="secondary">
                      Add Notes And Comments
                    </Button>
                  </form>
                </Grid>
                <Grid item lg={12} style={{}}></Grid>
              </Grid>
            </Grid>
          </Typography>
        </Box>
      </Modal>
      {/* End OF Modal */}

      <Calendar
        localizer={localizer}
        events={allEventsFromRedux}
        showMultiDayTimes
        startAccessor="start"
        endAccessor="end"
        step={15}
        onSelectEvent={(e) => {
          getRestOfEvent(e.id);
          handleOpenEvent();
        }}
        style={{ height: 500, margin: "50px" }}
      />
    </Div>
  );
};

export default Calender;
