import React from "react";
import styled from "styled-components";
import Grid from "@mui/material/Grid";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import ContactUs from "../../../../assets/ContactUs.png";
import location from "../../../../assets/location.png";
import workingHours from "../../../../assets/workingHours.png";
const BigSection = styled.div`
  margin: 100px 0;
  @media (max-width: 425px) {
    margin: 10px 0;
  }
`;
const FirstDiv = styled.div`
  background-color: #212121;
  position: relative;
  color: white;
  text-align: center;
  padding:20px 5px;
font-size:15px;
height:168px;
@media(max-width:425px){
    margin:40px 0;
}
`;
const SecondDiv = styled.div`
  background-color: #0a0a0a;
  position: relative;
  color: white;
  text-align: center;
  padding: 25px 10px;
  bottom: 32px;
  height: 200px;
  @media (max-width: 599px) {
    margin: 100px 0;
  }
  @media (max-width: 425px) {
    margin: 40px 0;
    bottom: 0;
  }
`;
const Image = styled.img`
position:absolute;
top:-15px;
left:50%;
width:20%;
transform: translate(-50%, -50%);
@media(max-width:599px){
  width:15%;
}
`
const ServiceName = styled.p`
  font-size: 20px;
  font-weight: 700;
`;
const Description = styled.p`
  @media (max-width: 899px) {
    font-size: 12px;
  }
`;
const Content = styled.div`
  padding-top: 20px;
`;
const NewServices = () => {
  return (
    <BigSection>
      <Grid
        container
        spacing={0}
        col={12}
        direction="row"
        justifyContent="center"
        alignItems="stretch"
      >
        <Grid item xs={12} md={4} lg={3} sm={4}>
          <FirstDiv>
            <Content>
              <Image src={workingHours}></Image>
              <ServiceName>Working Hours</ServiceName>
              <Description>24 hours a Day 7 days a week</Description>
            </Content>
          </FirstDiv>
        </Grid>
        <Grid item xs={12} md={3.5} lg={3.5} sm={4}>
          <SecondDiv>
            <Content>
              <Image src={location}></Image>
              <ServiceName>Our Address </ServiceName>
              <Description>22647 Ventura Blvd, Woodland Hills CA 91364</Description>
            </Content>
          </SecondDiv>
        </Grid>
        <Grid item xs={12} md={3} lg={3} sm={4}>
          <FirstDiv>
            <Content>
              <Image src={ContactUs}></Image>
              <ServiceName>Contact Us </ServiceName>
              <Description>
                <p> Phone: 1-818-641-1240</p>
                E-Mail: support@allpremiumhome.com
              </Description>
            </Content>
          </FirstDiv>
        </Grid>
        
      </Grid>
    </BigSection>
  );
};
export default NewServices;
