import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { MAIN_URL } from "../../../API/Api";

const Main_url = `https://crm.boxbyld.tech/api/v1`;
export const baseQuery = fetchBaseQuery({
  baseUrl: Main_url,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    // If we have a token set in state, let's assume that we should be passing it.
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    headers.set("Accept", "application/json");

    return headers;
  },
});
