import React, { useEffect, useLayoutEffect, useState } from "react";
import { CardContainer } from "./style";
import { Box, Modal, Rating, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  updateInstaller,
  updateSalesAdders,
} from "../../../../../../Redux/toolKit/formData";
import Swal from "sweetalert2";
import {
  useGetDistanceQuery,
  useLazyGetDistanceQuery,
} from "../../../../../../Redux/toolKit/addersForm";
import axios from "axios";
import { Link, createSearchParams, useSearchParams } from "react-router-dom";
import swal from "sweetalert";
import img from "../../../../../../assets/samples/WhatsApp Image 2024-02-19 at 3.00.07 PM.jpeg";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import InfoIcon from "@mui/icons-material/Info";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const InstallerCard = ({
  installer,
  distance,
  miles,
  totalSum,
  isloading,
  setDisabled,
  disabled,
  defaultAdders,
  singleadData,
}) => {
  const { myFormData } = useSelector((state) => state);
  const { installerId } = useSelector((state) => state?.myFormData);

  const [selectInstaller, setSelectInstaller] = React.useState(installerId);
  const { formdata, systemSize, totalPanels, salesAdders } = myFormData;
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const panelArr = formdata[104] || 0;
  let lat = singleadData?.data?.lat;
  let lng = singleadData?.data?.lng;

  const [getDistance, { data: distanceData, isLoading: distanceLoading }] =
    useLazyGetDistanceQuery();

  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(updateInstaller(selectInstaller));
  }, [selectInstaller]);

  const clacDefaultSum = (data) => {
    let defaultSum = 0;
    // return data?.user_adders?.map((adder) => {
    data?.map((singleAdder) => {
      if (singleAdder?.condition_on === "panels") {
        if (singleAdder?.operator === "less_than") {
          if (totalPanels < singleAdder?.condition_number) {
            defaultSum += singleAdder?.price;
          }
        }
        if (singleAdder?.operator === "greater_than") {
          if (totalPanels > singleAdder?.condition_number) {
            defaultSum += singleAdder?.price;
          }
        }
        if (singleAdder?.operator === "equal") {
          if (singleAdder?.condition_number === totalPanels) {
            defaultSum += singleAdder?.price;
          }
        }
      }
      if (singleAdder?.condition_on === "miles") {
        if (singleAdder?.operator === "less_than") {
          if (miles < singleAdder?.condition_number) {
            defaultSum += singleAdder?.price;
          }
        }
        if (singleAdder?.operator === "greater_than") {
          if (miles > singleAdder?.condition_number) {
            defaultSum += singleAdder?.price;
          }
        }
        if (singleAdder?.operator === "equal") {
          if (singleAdder?.condition_number === miles) {
            defaultSum += singleAdder?.price;
          }
        }
      }
      if (singleAdder?.condition_on === "arrays") {
        if (singleAdder?.operator === "less_than") {
          if (Number(panelArr) < singleAdder?.condition_number) {
            defaultSum += singleAdder?.price;
          }
        }
        if (singleAdder?.operator === "greater_than") {
          if (Number(panelArr) > singleAdder?.condition_number) {
            defaultSum += singleAdder?.price;
          }
        }
        if (singleAdder?.operator === "equal") {
          if (singleAdder?.condition_number === Number(panelArr)) {
            defaultSum += singleAdder?.price;
          }
        }
      }
      if (singleAdder?.condition_on === "kwh") {
        if (singleAdder?.operator === "less_than") {
          if (systemSize < singleAdder?.condition_number) {
            defaultSum += singleAdder?.price;
          }
        }
        if (singleAdder?.operator === "greater_than") {
          if (systemSize > singleAdder?.condition_number) {
            defaultSum += singleAdder?.price;
          }
        }
        if (singleAdder?.operator === "equal") {
          if (singleAdder?.condition_number === systemSize) {
            defaultSum += singleAdder?.price;
          }
        }
      }
    });
    // });
    return defaultSum;
  };

  const calcAdders = (data) => {
    let sumAdders = 0;
    data?.map((singleAdder) => {
      [formdata]?.map((e) => {
        for (const property in e) {
          if (singleAdder?.adder_category_id === Number(property)) {
            if (singleAdder?.condition_on === "none") {
              if (singleAdder?.per === "watt") {
                sumAdders += singleAdder?.price * e[property] * systemSize;
              }
              if (singleAdder?.per === "panel") {
                sumAdders += singleAdder?.price * e[property] * totalPanels;
              }
              if (singleAdder?.per === "array") {
                sumAdders += singleAdder?.price * e[property] * panelArr;
              }
              if (["unit", "ft", "sq"].includes(singleAdder?.per)) {
                sumAdders += singleAdder?.price * e[property];
              }
              if (singleAdder?.per === "mile") {
                sumAdders += singleAdder?.price * e[property] * miles;
              }
            }
          }
        }
      });
    });
    // console.log("sumAdders", sumAdders);
    return sumAdders;
  };
  const findAdderData = (ids) => {
    let allAdders = [];
    let names = [];
    defaultAdders?.data?.map((e) => {
      e?.children?.map((child) => {
        allAdders?.push(child);
      });
    });
    ids?.map((id, index) => {
      let findAdder = allAdders.find((e) => Number(e?.id) == Number(id));
      names.push(`${++index}. ${findAdder?.name.replace("\n", "")}`);
    });
    return names;
  };
  const handleSelectInstaller = (installer) => {
    handleOpen();
    let salesAdders = [];
    let installerAdders = [];
    [formdata]?.map((e) => {
      for (const property in e) {
        if (Number(property) > 0) {
          if (e[property] > 0) {
            // console.log("Number(property)", Number(property));
            salesAdders.push(Number(property));
          }
        }
      }
    });
    installer?.user_adders?.map((singleAdder) => {
      installerAdders.push(Number(singleAdder?.adder_category_id));
    });
    // console.log("salesAdders", salesAdders);
    // console.log("installerAdders", installerAdders);
    if (salesAdders?.every((id) => installerAdders?.includes(id))) {
      setSelectInstaller(installer?.id);
      dispatch(updateInstaller(installer?.id));
    } else {
      const missingIds = salesAdders.filter(
        (id) => !installerAdders?.includes(id)
      );
      // console.log("missingIds", missingIds);
      // console.log("False");
      // console.log("checkkkkkkkkkk", findAdderData(missingIds));
      swal({
        title: "The installer doesn't support this/these Adders",
        text: `${findAdderData(missingIds).join("\n")}`,
        icon: "warning",
        buttons: {
          confirm: {
            text: "Please select another installer",
            className: "btn-primary",
          },
        },
      });
    }
    // setSelectInstaller(installer?.user?.id);
    // dispatch(updateInstaller(installer?.user?.id));
  };

  console.log("installer", installer);
  return (
    <CardContainer>
      <div className="singleCard">
        <div className="firstRow">
          <div className="flex"></div>
          <div className="flex">
            <p style={{ color: "#0085FF" }}>EPC</p>
            <InfoIcon />
            <EmojiEventsIcon sx={{ color: "gold" }} />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              // className="verfied"
            >
              <path
                d="M8.88 12.0001L11.29 14.4201L16.12 9.58008"
                stroke="#0085FF"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.25 2.45007C11.94 1.86007 13.07 1.86007 13.77 2.45007L15.35 3.81007C15.65 4.07007 16.21 4.28007 16.61 4.28007H18.31C19.37 4.28007 20.24 5.15007 20.24 6.21007V7.91007C20.24 8.30007 20.45 8.87007 20.71 9.17007L22.07 10.7501C22.66 11.4401 22.66 12.5701 22.07 13.2701L20.71 14.8501C20.45 15.1501 20.24 15.7101 20.24 16.1101V17.8101C20.24 18.8701 19.37 19.7401 18.31 19.7401H16.61C16.22 19.7401 15.65 19.9501 15.35 20.2101L13.77 21.5701C13.08 22.1601 11.95 22.1601 11.25 21.5701L9.67 20.2101C9.37 19.9501 8.81 19.7401 8.41 19.7401H6.68C5.62 19.7401 4.75 18.8701 4.75 17.8101V16.1001C4.75 15.7101 4.54 15.1501 4.29 14.8501L2.94 13.2601C2.36 12.5701 2.36 11.4501 2.94 10.7601L4.29 9.17007C4.54 8.87007 4.75 8.31007 4.75 7.92007V6.20007C4.75 5.14007 5.62 4.27007 6.68 4.27007H8.41C8.8 4.27007 9.37 4.06007 9.67 3.80007L11.25 2.45007Z"
                stroke="#0085FF"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <FavoriteBorderIcon />
          </div>
        </div>
        <div className="container">
          <div className="column">
            <div className="firstColumnContainer">
              <div className="img_time">
                <div className="imgContainer">
                  <p className="name"> {installer?.name}</p>

                  <img className="img" src={installer?.img} alt="" />

                  <div className="reviews">
                    <div>
                      <Rating
                        className="stars"
                        name="size-large"
                        value={5}
                        size="small"
                        readOnly
                      />
                    </div>
                    <div>
                      <span>Reviews</span>
                    </div>
                  </div>
                </div>
                <div className="timeWarranty">
                  <p className="number">{installer?.year_warranty}</p>
                  <p className="years">year</p>
                  <p className="others">workmanship</p>
                  <p className="others">warranty</p>
                </div>
              </div>
            </div>
          </div>
          <div className="middleColumn">
            <div className="buttons">
              <p>License</p>
              <p>Bonded</p>
              <p>Insured</p>
            </div>
            <div className="">
              <h5>
                In business since : <span>2008</span>
              </h5>
            </div>
            <div className="buttons">
              <h5>License type: </h5>
              {/* <p>Roofing</p> */}
              {/* <p>{installer?.category}</p> */}
              <p>C10 - Electrical</p>
              {/* <p>Solar</p> */}
            </div>
            {/* <div className="moneyLocation"> */}
            {/* <div className="middleContainer"> */}
            <div className="locationDiv">
              <div className="sinlgeicon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 21 20"
                  fill="none"
                >
                  <path
                    d="M18 8.33325C18 14.1666 10.5 19.1666 10.5 19.1666C10.5 19.1666 3 14.1666 3 8.33325C3 6.34413 3.79018 4.43647 5.1967 3.02995C6.60322 1.62343 8.51088 0.833252 10.5 0.833252C12.4891 0.833252 14.3968 1.62343 15.8033 3.02995C17.2098 4.43647 18 6.34413 18 8.33325Z"
                    stroke="#98A2B3"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.5 10.8333C11.8807 10.8333 13 9.71396 13 8.33325C13 6.95254 11.8807 5.83325 10.5 5.83325C9.11929 5.83325 8 6.95254 8 8.33325C8 9.71396 9.11929 10.8333 10.5 10.8333Z"
                    stroke="#98A2B3"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p>
                  {/* {distanceData?.data?.rows[0]?.elements[0]?.distance} */}
                  {installer?.miles} Mi
                </p>
              </div>
              <div className="sinlgeicon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 21 20"
                  fill="none"
                >
                  <path
                    d="M13.8333 17.5V4.16667C13.8333 3.72464 13.6577 3.30072 13.3451 2.98816C13.0326 2.67559 12.6087 2.5 12.1666 2.5H8.83329C8.39127 2.5 7.96734 2.67559 7.65478 2.98816C7.34222 3.30072 7.16663 3.72464 7.16663 4.16667V17.5M3.83329 5.83333H17.1666C18.0871 5.83333 18.8333 6.57953 18.8333 7.5V15.8333C18.8333 16.7538 18.0871 17.5 17.1666 17.5H3.83329C2.91282 17.5 2.16663 16.7538 2.16663 15.8333V7.5C2.16663 6.57953 2.91282 5.83333 3.83329 5.83333Z"
                    stroke="#98A2B3"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p>{installer?.exp} Experience</p>
              </div>
              <div className="sinlgeicon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                >
                  <path
                    d="M13.8333 17.5V4.16667C13.8333 3.72464 13.6577 3.30072 13.3451 2.98816C13.0326 2.67559 12.6087 2.5 12.1666 2.5H8.83329C8.39127 2.5 7.96734 2.67559 7.65478 2.98816C7.34222 3.30072 7.16663 3.72464 7.16663 4.16667V17.5M3.83329 5.83333H17.1666C18.0871 5.83333 18.8333 6.57953 18.8333 7.5V15.8333C18.8333 16.7538 18.0871 17.5 17.1666 17.5H3.83329C2.91282 17.5 2.16663 16.7538 2.16663 15.8333V7.5C2.16663 6.57953 2.91282 5.83333 3.83329 5.83333Z"
                    stroke="#98A2B3"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p> {installer?.recorded} Recorded</p>
              </div>
            </div>
            <div className="completionTime">
              <h5>
                {" "}
                Average completion time : {installer?.completion_time} days
              </h5>
            </div>
            {/* </div> */}
            {/* </div> */}
          </div>
          <div className="lastColumn">
            <div className="moneyDiv">
              <>
                <p>
                  {/* Price: */}
                  <span
                    style={{
                      color: "green",
                      fontWeight: "bold",
                      fontSize: "1.3rem",
                    }}
                  >
                    {/* {`$${installer?.adders_invoice?.total
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`} */}
                    ${" "}
                    {installer?.price
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </span>
                </p>
                <p className="ppw">$ {installer?.ppw} ppw </p>
              </>
            </div>
            <div className="buttonsContainer">
              <Link
                to={{
                  pathname: `/installer-Adders?arr=${JSON.stringify(
                    installer?.adders_invoice
                  )}`,
                }}
                // state={{ arr: installer?.adders_invoice }}
                target="_blank"
              >
                <button className="details" type="button">
                  Details
                </button>
              </Link>

              <button
                className="approve"
                onClick={() => {
                  handleSelectInstaller(installer);
                }}
                type="button"
              >
                {Number(installerId) == Number(installer?.id)
                  ? "selected"
                  : "select"}
              </button>
            </div>
          </div>
        </div>

        {Number(installerId) === Number(installer?.id) && (
          <div className="selected">
            <p>selected</p>
          </div>
        )}
      </div>
    </CardContainer>
  );
};

export default React.memo(InstallerCard);
