import styled from "styled-components";

const Notified = ({ fullData }) => {
  const emtyData = Object.keys(fullData).length;
  return (
    <NotifieHeader className={emtyData !== 0 ? "show" : ""}>
      New Solution Add {fullData.id}
    </NotifieHeader>
  );
};

const NotifieHeader = styled.h3`
  font-size: 1.2rem;
  opacity: 0;

  &.show {
    opacity: 1;
    animation: PopUp 1s 1 forwards;
  }
  @keyframes PopUp {
    0% {
      transform: translateY(-10px) Scale(0);
      color: #555;
    }
    50% {
      transform: translateY(5px) Scale(1.05);
      color: #555;
    }
    100% {
      transform: translateY(0) Scale(1);
      color: #44a1c5;
    }
  }
`;
export default Notified;
