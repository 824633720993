import {
  Alert,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import styled from "styled-components";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { CreateNewUser } from "../../../../Redux/Actions/authActions";
import { getRolsForUser } from "../../../../Redux/Actions/EditUserAction";
import { useEffect, useState } from "react";
import Loading from "../../../../components/loading/Loading";

const validationSchema = yup.object({
  name: yup.string("Enter your Name").required("Name is Required"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  password_confirmation: yup
    .string("Confirme your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  role_id: yup.string("Enter your Role").required("Role is required"),
  business: yup.string("Enter your Business").required("Business is required"),
  // ppw: yup.string("PWW").required("PPW is required"),
});
const CreateUser = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  useEffect(() => {
    dispatch(getRolsForUser());
  }, []);

  const { rols, isLoading } = useSelector((state) => state.editUser);
  const RolsUser = rols || "";
  // console.log(rols);
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
      role_id: "",
      business: "",
      ppw: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // if (values.password === values.password_confirmation) {
      //   dispatch(CreateNewUser(values));
      //   setError(false);
      // } else {
      //   setError(true);
      // }
      dispatch(CreateNewUser(values));
    },
  });
  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && (
        <Container>
          <UserConatiner>
            <h2>create user</h2>
            <form onSubmit={formik.handleSubmit}>
              <FieldConatiner>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  label="Full Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </FieldConatiner>
              <FieldConatiner>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </FieldConatiner>
              <FieldConatiner>
                <TextField
                  fullWidth
                  id="password"
                  name="password"
                  label="password"
                  type="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
              </FieldConatiner>

              <FieldConatiner>
                <TextField
                  fullWidth
                  id="password_confirmation"
                  name="password_confirmation"
                  label=" Confirme Password "
                  type="password"
                  value={formik.values.password_confirmation}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password_confirmation &&
                    Boolean(formik.errors.password_confirmation)
                  }
                  helperText={
                    formik.touched.password_confirmation &&
                    formik.errors.password_confirmation
                  }
                />
              </FieldConatiner>
              {error && (
                <Stack spacing={2} sx={{ width: "100%" }}>
                  <Alert severity="error">
                    Confirm Password Does't Match the Password!
                  </Alert>
                </Stack>
              )}
              <FieldConatiner>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Role</InputLabel>
                  <Select
                    id="role_id"
                    name="role_id"
                    label="Role "
                    value={formik.values.role_id}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.role_id && Boolean(formik.errors.role_id)
                    }
                    helperText={formik.touched.role_id && formik.errors.role_id}
                  >
                    {RolsUser &&
                      RolsUser?.map((role) => (
                        <MenuItem value={role.id} key={role}>
                          {role.title}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </FieldConatiner>
              <FieldConatiner>
                <TextField
                  fullWidth
                  id="business"
                  name="business"
                  label="Business"
                  value={formik.values.business}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.business && Boolean(formik.errors.business)
                  }
                  helperText={formik.touched.business && formik.errors.business}
                />
              </FieldConatiner>
              <FieldConatiner>
                <TextField
                  fullWidth
                  id="ppw"
                  name="ppw"
                  label="PPW"
                  value={formik.values.ppw}
                  onChange={formik.handleChange}
                  error={formik.touched.ppw && Boolean(formik.errors.ppw)}
                  helperText={formik.touched.ppw && formik.errors.ppw}
                />
              </FieldConatiner>
              <Button type="submit" fullWidth className="btn_submit">
                Add User
              </Button>
            </form>
          </UserConatiner>
        </Container>
      )}
    </>
  );
};
const UserConatiner = styled.div`
  margin: 1rem;
  padding: 1rem;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  h2 {
    text-align: center;
    font-size: 2rem;
    margin: 1rem 0;
    text-transform: capitalize;
    color: #003c4c;
  }
  .btn_submit {
    background: #003c4c;
    color: #fff;
    font-size: 1.1rem;
    border-radius: 1rem;
    border: 1px solid #003c4c;
    &:hover {
      color: #003c4c;
      border: 1px solid #003c4c;
    }
  }
`;
const FieldConatiner = styled.div`
  margin: 1rem 0;
`;

export default CreateUser;
