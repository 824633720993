import { Slider } from "@mui/material";
import styled from "styled-components";
export const CalcToggle = styled.div`
  position: fixed;
  top: 20%;
  right: 1%;
  transform: translate(-20%, -1%);
  width: 60px;
  height: 60px;
  background: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  padding: 0.2rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  cursor: pointer;
  svg {
    font-size: 2.8rem;
    color: #0dc091;
  }
`;
export const FormConatiner = styled.div`
  position: relative;
  .hvac {
    position: relative;
  }
  .btn {
    /* position: absolute; */
    /* left: 0; */
    /* top: 1.5rem; */
    font-size: 0.8rem;
    color: black;
    background-color: #f0f0f0;
  }
`;
export const SolarHeading = styled.div`
  text-align: center;
  text-transform: uppercase;
  h2 {
    font-size: 2.5rem;
    color: #0dbf7c55;
    margin-bottom: 1.5rem;
    margin-top: 1rem;
  }
`;
export const ClacButton = styled.div`
  width: 50%;
  margin: 1rem auto;
  button {
    border-radius: 2rem;
    letter-spacing: 2px;
    word-spacing: 6px;
    font-size: 0.8rem;
    padding: 0.7rem 1rem;
    background: #eea700;
  }
`;
export const ButtonContainer = styled.div`
  text-align: center;
  button {
    border-radius: 2rem;
    padding: 0.7rem 3rem;
    background-size: 400% 100%;
    transition: all 0.4s ease-in-out;
    background-image: linear-gradient(
      132.6deg,
      rgba(71, 139, 214, 1) 23.3%,
      rgba(37, 216, 211, 1) 84.7%
    );
    &:hover {
      background-position: 100% 0;
    }
  }
`;

export const ContractorStyled = styled.div`
  padding: 0.5rem 2rem 1.5rem;
  border-bottom: 1px solid #999;
  @media (max-width: 600px) {
    padding: 0.5rem 0rem 0.5rem;
  }
  .contactorData {
    display: flex;
    align-items: center;
    .title {
      display: flex;
      align-items: center;
      width: 200px;
      color: #69aaf0;
      font-weight: 600;
      @media (max-width: 600px) {
        width: auto;
        margin-right: 0.2rem;
        font-size: 1rem;
      }
      svg {
        font-size: 1.5rem;
        margin-right: 0.5rem;
      }
    }
    .value {
      font-size: 1.1rem;
      color: #777;
      text-transform: capitalize;
      font-weight: 400;
      @media (max-width: 600px) {
        font-size: 1rem;
      }
    }
  }
`;
export const PrettoSlider = styled(Slider)({
  color: "#52af77",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#52af77",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

export const LoadingContainer = styled.div`
  width: 100%;
  height: 50vh;
`;
export const UserData = styled.div`
  .leadInfo {
    text-align: center;
    color: #0dbf7c55;
    margin-bottom: 1rem;
  }
  .data {
    display: flex;
    margin: 0.3rem;
    .key {
      text-transform: capitalize;
      margin-right: 0.5rem;
      min-width: 5rem;
      color: #474747;
      display: flex;
      align-items: center;
      svg {
        margin-right: 0.5rem;
      }
    }
    .value {
      color: #888;
    }
  }
`;
export const ModelConatiner = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  .icon {
    svg {
      color: #0dc091;
      font-size: 5rem;
    }
  }
  p {
    font-size: 1.5rem;
    text-transform: capitalize;
    margin: 1.5rem;
    color: #777;
  }
  .btn_sucess {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin: 1rem 0;
    .go_lead {
      background: #0dc091;
    }

    .add_lead {
      background: #979696;
    }
  }
`;
export const CalcSunLightData = styled.div`
  border: 1px solid #eea700;
  padding: 2rem;
  border-radius: 1rem;
  text-align: center;
  height: 190px;
  span {
    display: block;
    font-size: 2.2rem;
    color: #eea700;
    font-weight: bold;
  }
`;

export const ColorRoofing = styled.div`
  cursor: pointer;
  color: #b8b8b8;
  width: 100%;
  .select-box {
    position: relative;
    width: 100%;
    color: #fff;

    &__current {
      position: relative;
      width: 100%;
      &:focus {
        & + .select-box__list {
          opacity: 1;
          z-index: 10;
          .select-box__option {
            cursor: pointer;
          }
        }
      }
    }
    &__value {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__input {
      display: none;
      &:checked + .select-box__input-text {
        display: block;
        background: #444;
      }
    }
    &__input-text {
      display: none;
      cursor: pointer;
      width: 100%;
      height: 55px;
      margin: 0;
      padding: 13px;
      font-weight: bold;

      &.show {
        background: #444;
        display: block;
      }
      &.work {
        background: #222;
        display: block;
      }
    }
    &__list {
      position: absolute;
      width: 100%;
      height: 60px;
      list-style: none;
      background: #fff;
      padding-top: 0.5rem;
      z-index: 1;
      opacity: 0;
      box-shadow: 0 5px 15px rgba(56, 59, 61, 0.5);
      transition: all 0.3s;
      &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: -8%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 20px solid #fff;
      }
    }
    &__option {
      padding: 1rem;
      display: block;
      margin: 0.2rem 0;
      transition: all 0.3s;
      &:hover {
        opacity: 0.9;
      }
    }
  }
`;
