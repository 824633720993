import axios from "axios";
import swal from "sweetalert";
import { Add_Roofing_Solution } from "../../API/Api";
import { Create_roofing_contract } from "../../API/Api";
import { Send_roofing_contract } from "../../API/Api";
import { Delete_roofing_contract } from "../../API/Api";
import {Roofing_send_contract_to_sign} from "../../API/Api"
import { ShowLoading, HideLoading } from "./loadingAction";
// Type


export const ADD_ROOFING_SOLUTION = "ADD_ROOFING_SOLUTION";

export const IS_SUCCESSFUL = "IS_SUCCESSFUL";

export const CREATE_ROOFING_CONTRACT = "CREATE_ROOFING_CONTRACT";

export const DELETE_ROOFING_CONTRACT = "CREATE_ROOFING_CONTRACT";

export const SEND_ROOFING_CONTRACT = "CREATE_ROOFING_CONTRACT";

export const SEND_CONTRACT_TO_SIGN="SEND_CONTRACT_TO_SIGN"


export const AddRoofingSolution = (data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch(ShowLoading());
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  await axios({
    method: "POST",
    baseURL: `${Add_Roofing_Solution}`,
    headers: headers,
    data: data,
  })
    .then((res) => {
      dispatch(HideLoading());
      dispatch({
        type: ADD_ROOFING_SOLUTION,
        allData: res.data,
      });
      if (res.status === 201) {
        dispatch({
          type: IS_SUCCESSFUL,
        });
      }
    })
    .catch((err) => {
      dispatch(HideLoading());
      console.log(err.data);
      console.log("try", err.response.data.message);
      swal({
        title: `Error ${err.response.data.message}`,
        message: `${err}`,
        icon: "warning",
        button: "OK",
      });
    });
};

export const CreateRoofingScontract = (data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch(ShowLoading());
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  await axios({
    method: "POST",
    baseURL: `${Create_roofing_contract}/${data}`,
    headers: headers,
    data: data,
  })
    .then((res) => {
      dispatch(HideLoading());
      dispatch({
        type: CREATE_ROOFING_CONTRACT,
        contract: res.data,
      });
      if (res.status === 201) {
        dispatch({
          type: IS_SUCCESSFUL,
        });
      }
    })
    .catch((err) => {
      dispatch(HideLoading());
      console.log(err.data);
      console.log("try Error", err.response.data);
      swal({
        title: `Error ${err.response.data.message}`,
        message: `${err}`,
        icon: "warning",
        button: "OK",
      });
    });
};

export const SendRoofingContract = (data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch(ShowLoading());
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  await axios({
    method: "POST",
    baseURL: `${Send_roofing_contract}/${data}`,
    headers: headers,
    data: data,
  })
    .then((res) => {
      dispatch(HideLoading());
      dispatch({
        type: SEND_ROOFING_CONTRACT,
        contract: res.data,
      });
      // if (res.status === 201) {
        swal({
          title: `Solution Has been Sent To User Email`,
          icon: "success",
          text: "Done",
          button: "OK",
        });
      // }
    })
    .catch((err) => {
      dispatch(HideLoading());
      console.log(err.data);
      console.log("try", err.response.data.message);
      swal({
        title: `Error ${err.response.data.message}`,
        message: `${err}`,
        icon: "warning",
        button: "OK",
      });
    });
};

export const DeleteRoofingContract = (data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch(ShowLoading());
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  await axios({
    method: "DELETE",
    baseURL: `${Delete_roofing_contract}/${data}`,
    headers: headers,
    data: data,
  })
    .then((res) => {
      dispatch(HideLoading());
      dispatch({
        type: DELETE_ROOFING_CONTRACT,
        contract: res.data,
      });
      if (res.status === 201) {
        dispatch({
          type: IS_SUCCESSFUL,
        });
      }
    })
    .catch((err) => {
      dispatch(HideLoading());
      console.log(err.data);
      console.log("try", err.response.data.message);
      swal({
        title: `Error ${err.response.data.message}`,
        message: `${err}`,
        icon: "warning",
        button: "OK",
      });
    });
};

export const SendContractToSign = (data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch(ShowLoading());
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  await axios({
    method: "POST",
    baseURL: `${Roofing_send_contract_to_sign}/${data}`,
    headers: headers,
    data: data,
  })
    .then((res) => {
      dispatch(HideLoading());
      console.log("res in then ",res.status)
      if(res.status===200||res.status===201){
      swal({
        title: `Success `,
        text: `Contract has been sent`,
        icon: "success",
        button: "OK",
      });
    }
    dispatch({
      type: SEND_CONTRACT_TO_SIGN,
      contract: res.data,
    });
    })
    .catch((err) => {
      dispatch(HideLoading());
      console.log("err in catch",err);
      // swal({
      //   title: `Error `,
      //   // message: `${err}`,
      //   icon: "warning",
      //   button: "OK",
      // });
    });
};
