import React from "react";
import styled from "styled-components";
import Grid from "@mui/material/Grid";
import mangementimg from "../../../../assets/mangementimage.png";
const BigSection = styled.div`
  background-color: #d7d6db;
  position: relative;
  padding: 100px 0;
`;
const Header = styled.p`
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  @media (max-width: 425px) {
    font-size: 20px;
  }
`;
const DivWhoWeAre = styled.div`
  position: relative;
  padding: 40px 0;
  &:after {
    width: 125px;
    position: absolute;
    height: 5px;
    content: "";
    background-color: #707070;
    bottom: 30px;
  }
`;
const WhoWeAre = styled.p`
  font-size: 30px;
  font-weight: 800;
  @media (max-width: 425px) {
    font-size: 20px;
  }
`;
const Image = styled.img`
  position: absolute;
  top: 150px;
  right: 0;
  width: 45%;
  @media (max-width: 1980px) {
    top: 70px;
    right: 100px;
    width: 30%;
  }
  @media (max-width: 1600px) {
    top: 150px;
  }
  @media (max-width: 1440px) {
    top: 170px;
    right: 100px;
    width: 30%;
  }
  @media (max-width: 1024px) {
    top: 250px;
  }
  @media (max-width: 899px) {
    position: relative;
    width: 50%;
    top: 0;
    left: 100px;
  }
`;

const Description = styled.p`
  max-width: 95%;
`;
const Mangement = () => {
  return (
    <BigSection id="whyus">
      <Grid
        container
        spacing={0}
        col={12}
        direction="row"
        justifyContent="center"
        alignItems="stretch"
      >
        <Grid item lg={8} md={8} sm={8} xs={12}>
          <Header>
            BoxBYLD is a platform that provides the necessary tools to create, generate, enhance, support and stabilize your business, income and lifestyle.
          </Header>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={0}
        col={12}
        direction="row"
        justifyContent="center"
        alignItems="stretch"
      >
        <Grid item lg={5} md={6} sm={8} xs={12}>
          <DivWhoWeAre>
            <WhoWeAre> Why Choose Us</WhoWeAre>
          </DivWhoWeAre>
          <Description>
            <p>
              Our platform is very easy to use on any device. You can create leads, solutions, contracts, drive deals to closure, and run finance all in one place.
              A unified view of customer contacts and all their details, including expanded sales capabilities with adding roofing, HVAC and more.
              See where all deals stand at a glance. Streamline your entire sales process with colorful and visual interface.
            </p>
            {/* <p>
              A unified view of contacts Access all their details in one place,
              including auto enriched social profiles, a summary of all recent
              activities and more.
            </p>
            <p>
              See where all deals stand at a glance. Streamline your entire
              sales process with colorful and visual interface.
            </p> */}
          </Description>
        </Grid>
        <Grid item lg={4} md={6} sm={12} xs={12}>
          <Image src={mangementimg}></Image>
        </Grid>
      </Grid>
    </BigSection>
  );
};
export default Mangement;
