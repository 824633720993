import styled from "styled-components";

export const FormConatiner = styled.div`
  position: relative;
  .hvac {
    position: relative;
  }
  .btn {
    /* position: absolute; */
    /* left: 0; */
    /* top: 1.5rem; */
    font-size: 0.8rem;
    color: black;
    background-color: #f0f0f0;
  }
  label {
    font-size: 0.7rem;
    display: block;
  }
  input {
    width: 100%;
    /* padding: 0.7rem 5px; */
  }
  .err {
    color: red;
    font-size: 12px;
  }
  .block {
    display: block;
  }
  .submit {
    &:hover {
      width: 100%;
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      cursor: pointer;
      margin: 20px;
      height: 55px;
      text-align: center;
      border: none;
      background-size: 300% 100%;
      border-radius: 50px;
      moz-transition: all 0.4s ease-in-out;
      -o-transition: all 0.4s ease-in-out;
      -webkit-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
    }

    &:hover {
      background-position: 100% 0;
      moz-transition: all 0.4s ease-in-out;
      -o-transition: all 0.4s ease-in-out;
      -webkit-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
    }

    &:focus {
      outline: none;
    }

    background-image: linear-gradient(
      to right,
      #0ba360,
      #3cba92,
      #30dd8a,
      #2bb673
    );
    box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75);
    /* width: 160px; */
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin: 20px;
    height: 55px;
    text-align: center;
    border: none;
    border-radius: 50px;
    width: 100%;
  }
`;
export const SolarHeading = styled.div`
  text-align: center;
  text-transform: uppercase;
  h2 {
    font-size: 2.5rem;
    color: #0dbf7c55;
    margin-bottom: 1.5rem;
    margin-top: 1rem;
  }
`;
export const UserData = styled.div`
  .leadInfo {
    text-align: center;
    color: #0dbf7c55;
    margin-bottom: 1rem;
  }
  .data {
    display: flex;
    margin: 0.3rem;
    .key {
      text-transform: capitalize;
      margin-right: 0.5rem;
      min-width: 5rem;
      color: #474747;
      display: flex;
      align-items: center;
      svg {
        margin-right: 0.5rem;
      }
    }
    .value {
      color: #888;
    }
  }
`;
