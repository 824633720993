import {
  Button,
  Grid,
  Modal,
  MenuItem,
  Select,
  TextField,
  Box,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Calculate } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CalcoulateActions,
  // loadAprCalc,
  loadSunLightCalc,
  officalCalc,
  TotalAmount,
} from "../../Redux/Actions/CalculatorActions";
import styled from "styled-components";
import img from "../../assets/sunlightLogo.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "1rem",
  p: 4,
};

// const termsInYears = [
//   { id: 5, value: "5 Years " },
//   { id: 10, value: "10 Years " },
//   { id: 12, value: "12 Years " },
//   { id: 15, value: "15 Years " },
//   { id: 20, value: "20 Years " },
//   { id: 25, value: "25 Years " },
// ];

const Calc = ({ open, setOpen }) => {
  /*--------------- start a state --------- */
  const dispatch = useDispatch();
  // const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const { singlead } = useSelector((state) => state.leads);
  const StatName = singlead.state?.name;

  const handleOpen = () => {
    setOpen(true);
  };

  const [data, setData] = useState({
    // termId: "",
    // term: "",
    // state_name: "",
    // loanAmount: "",
    // paydownPercentage: "30",
    // apr: "",
    // alternatePaydown: "",
    // dealer_fee: "",
    // deposit: "",
  });

  const { sunlightCalc, isCalc, totalAmount } = useSelector(
    (state) => state.calc
  );

  const TermID = data.termId;
  const singleTerm = !isCalc
    ? sunlightCalc?.find((item) => item.id == TermID)
    : null;

  //console.log(FindTheTerm, "falsee");
  /************************************************ */
  const handleChangeCalc = () => {
    dispatch(officalCalc({ ...data }));
  };
  // useEffect(() => {
  //   dispatch(officalCalc({ ...data }));
  // }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // dispatch(CalcoulateActions({ ...data }));
    dispatch(officalCalc({ ...data }));
    handleClose();
  };

  // console.log("Delaer feee", data);

  return (
    <>
      {!isCalc && (
        <div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <form onSubmit={handleSubmit}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h2>Sunlight Financial Calculator </h2>
                  <Img src={img}></Img>
                </div>
                <br />

                <Grid item container spacing={2} columns={18}>
                  <Grid item md={6} sm={9} xs={18} mb={3}>
                    <FormControl fullWidth>
                      <TextField
                        label="Term"
                        value={data.term}
                        onChange={async (e) => {
                          await setData({
                            ...data,
                            term: Number(e.target.value),
                          });
                        }}
                        required
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6} sm={9} xs={18} mb={3}>
                    <FormControl fullWidth className="formControl">
                      <TextField
                        label="Interest rate"
                        value={data.interest_rate}
                        onChange={(e) => {
                          const value = parseFloat(e.target.value);
                          if (value >= 0 && value <= 100) {
                            setData({ ...data, interest_rate: value });
                          } else if (value < 0) {
                            setData({ ...data, interest_rate: 0 });
                          } else if (value > 100) {
                            setData({ ...data, interest_rate: 100 });
                          }
                        }}
                        type="number"
                        InputProps={{
                          inputProps: { min: 0, max: 100 },
                        }}
                        required
                      />
                    </FormControl>
                  </Grid>

                  <Grid item md={6} sm={9} xs={18} mb={3}>
                    <TextField
                      className="formControl"
                      fullWidth
                      label="dealer fee"
                      // disabled={true}
                      value={data.dealer_fee}
                      onChange={(e) => {
                        const value = parseFloat(e.target.value);
                        if (value >= 0 && value <= 100) {
                          setData({ ...data, dealer_fee: value });
                        } else if (value < 0) {
                          setData({ ...data, dealer_fee: 0 });
                        } else if (value > 100) {
                          setData({ ...data, dealer_fee: 100 });
                        }
                      }}
                      type="number"
                      InputProps={{
                        inputProps: { min: 0, max: 100 },
                      }}
                      required
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sm={9}
                    xs={18}
                    mb={3}
                    // sx={{ display: "none" }}
                  >
                    <TextField
                      label="First payment"
                      className="formControl"
                      fullWidth
                      value={data.first_payment}
                      onChange={(e) => {
                        const value = parseFloat(e.target.value);
                        if (value >= 0) {
                          setData({ ...data, first_payment: value });
                        } else if (value < 0) {
                          setData({ ...data, first_payment: 0 });
                        }
                      }}
                      type="number"
                      InputProps={{
                        inputProps: { min: 0 },
                      }}
                      required
                    />
                  </Grid>

                  <Grid item md={6} sm={9} xs={18} mb={3}>
                    <TextField
                      label="Second payment"
                      className="formControl"
                      fullWidth
                      type="number"
                      InputProps={{
                        inputProps: { min: 0 },
                      }}
                      value={data.second_payment}
                      onChange={(e) => {
                        const value = parseFloat(e.target.value);
                        if (value >= 0) {
                          setData({ ...data, second_payment: value });
                        } else if (value < 0) {
                          setData({ ...data, second_payment: 0 });
                        }
                      }}
                      required
                    />
                  </Grid>

                  <Grid
                    container
                    xs={18}
                    item
                    alignItems="center"
                    justifyContent="center"
                  >
                    <ButtonStyled
                      // onClick={handleSubmit}
                      fullWidth
                      type="submit"
                      className="btnCalc"
                      endIcon={<Calculate />}
                      variant="contained"
                    >
                      Confirm
                    </ButtonStyled>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Modal>
        </div>
      )}
    </>
  );
};

const ButtonStyled = styled(Button)`
  background: #ff9800;
  border-radius: 2rem;
  padding: 0.8rem 2rem;
  width: 250px;
  margin: auto;
`;
const Img = styled.img`
  /* position: absolute;
  width: 5%;
  top: 0;
  right: 0; */
  width: 5%;
`;
export default React.memo(Calc);
