import axios from "axios";
import React from "react";
import { MAIN_URL } from "../../API/Api";

export const GET_ALL_EVENTS = "GET_ALL_EVENTS";
export const POST_EVENT = "POST_EVENT";
export const DELETE_EVENT = "DELETE_EVENT";
export const ADD_NOTE = "ADD_NOTE";
const eventURl = `${MAIN_URL}/api/v1/events`;

// GEt All EVENTS
export const getEvents = () => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    try {
      const res = await axios.get(eventURl, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      });
      dispatch({
        type: GET_ALL_EVENTS,
        payload: res.data.data,
      });
    } catch (e) {
      console.log("Get All Events Api ", e);
    }
  };
};

// Post An EVENT
export const AddEventt = (data) => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    try {
      await axios
        .post(`${MAIN_URL}/api/v1/events`, data, {
          headers: {
            Accept: "application/json",
            "X-Requested-With": "XMLHttpRequest",
          },
        })
        .then((res) => {
          dispatch({
            type: POST_EVENT,
            payload: res.data.data,
          });
        })
        .catch((err) => {
          console.log(err.data);
        });
    } catch (e) {
      console.log("post Api event ", e);
    }
  };
};

// Delete AN Event
export const DeleteEventt = (data) => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    try {
      await axios
        .post(`${MAIN_URL}/api/v1/events/delete`, data, {
          headers: {
            Accept: "application/json",
            "X-Requested-With": "XMLHttpRequest",
          },
        })
        .then((res) => {
          dispatch({
            type: DELETE_EVENT,
            payload: res.data.data,
          });
        })
        .catch((err) => {
          console.log(err.data);
        });
    } catch (e) {
      console.log("post Api event ", e);
    }
  };
};
// Add Notes
export const AddNotes = (data) => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    try {
      await axios
        .post(`${MAIN_URL}/api/v1/notes`, data, {
          headers: {
            Accept: "application/json",
            "X-Requested-With": "XMLHttpRequest",
          },
        })
        .then((res) => {
          dispatch({
            type: ADD_NOTE,
            payload: res.data.data,
          });
        })
        .catch((err) => {
          console.log(err.data);
        });
    } catch (e) {
      console.log("post Api Add Event ", e);
      // swal({
      //   title: " error ",
      //   text: " There is a problem ",
      //   icon: "error",
      //   button: "OK",
      // });
    }
  };
};
