import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Button, Tab } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const TabsLead = ({ LeadChild }) => {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const PropList = LeadChild?.opportunities?.map((prop) => {
    return (
      <div className="solution" key={prop.id}>
        <Link to={`${prop.id}`}>
          <h3 className="heading">{prop.service_type.title}</h3>
        </Link>

        <p>
          {new Date(prop.opportunity_date_time).toLocaleDateString("en-US", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })}
        </p>
        <Button variant="contained" fullWidth className="btn_pdf">
          <a href={prop.pdf_path} target="_blank">
            Show
          </a>
        </Button>
      </div>
    );
  });
  const ContractList = LeadChild?.solar_contracts?.map((prop) => {
    return (
      <div className="contarct" key={prop.id}>
        <h3 className="heading">Contract </h3>
        <p>
          {new Date(prop.created_at).toLocaleDateString("en-US", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })}
        </p>
        <Button variant="contained" fullWidth className="btn_pdf">
          <a href={prop.contract_url} target="_blank">
            Show
          </a>
        </Button>
      </div>
    );
  });
  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Proposals" value="1" />
            <Tab label="Contracts" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <LeadConatiner>{PropList}</LeadConatiner>
        </TabPanel>
        <TabPanel value="2">
          <LeadConatiner>{ContractList}</LeadConatiner>
        </TabPanel>
      </TabContext>
    </Box>
  );
};

const LeadConatiner = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  .solution,
  .contarct {
    padding: 1rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 1rem;
    background: #295b69;
    text-align: center;
    /* margin: 1rem 0; */
    .heading {
      font-weight: 400;
      text-transform: uppercase;
      color: #77aebd;
      margin: 0.5rem 0;
    }
    p {
      margin: 0.5rem 0;
      color: #b4b3b3;
    }
    .btn_pdf {
      background-color: rgba(0, 0, 0, 0.5);
      a {
        color: #a7bec5;
      }
    }
  }
  .contarct {
    background: #dadada;
    .heading {
      color: #003c4c;
    }
    p {
      color: #535353;
    }
    .btn_pdf {
      background-color: rgba(0, 0, 0, 0.335);
      a {
        color: #fafafa;
      }
    }
  }
`;
export default TabsLead;
