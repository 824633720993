import { Box } from "@mui/system";
import { useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { loadServices } from "../../../../../Redux/Actions/CountriesActions";
import { useDispatch, useSelector } from "react-redux";

const AddServices = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadServices());
  }, [dispatch]);

  const { services } = useSelector((state) => state.countries);
  console.log("services")
  return (
    <>
      <GridContent>
        <h2>choose your service </h2>
        {services && (
          <Box className="box_content">
            {services.map((s) => (
              <div className="box_content--item" key={s.id}>
                <Link to={`${s.id}`}>
                  <img src={s.image_path} alt={s.title} />
                  <h3>{s.title}</h3>
                </Link>
              </div>
            ))}
          </Box>
        )}
      </GridContent>
    </>
  );
};

const GridContent = styled.section`
  background: #ffffff;
  width: 100%;
  /* height: 100vh; */
  /* position: fixed;
  left: 0;
  top: 0; */
  padding: 3rem;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h2 {
    margin-block: 2.5rem;
    font-size: 2rem;
    font-weight: 400;
    text-transform: uppercase;
    color: #758491;
    letter-spacing: 1.5px;
  }
  .box_content {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    &--item {
      padding: 2.5rem;
      border-radius: 0.5rem;
      background: #bde3ec;
      box-shadow: 0px 8px 15px 0px rgb(0 52 60 / 23%);
      transition: all 0.3s;
      img {
        width: 80px;
        height: 80px;
        object-fit: cover;
        object-position: center;
      }
      &:hover {
        transform: translateY(-5px);
      }

      &:nth-child(even) {
        background: #adc2cd;
      }
      h3 {
        color: #547794;
        text-transform: uppercase;
      }
    }
  }
`;

export default AddServices;
