import styled from "styled-components";

export const BigSection = styled.div`
  position: relative;
  width: 95%;
  min-height: 80vh;
  padding: 3rem 1rem;
  margin: 2rem auto;
  border-radius: 1.5rem;
  box-shadow: 0 5px 15px inset rgb(0 0 0 / 7%);
  -webkit-box-shadow: 0 5px 15px inset rgb(0 0 0 / 7%);
  -moz-box-shadow: 0 5px 15px inset rgb(0 0 0 / 7%);
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #dddddd;
  }
  .checkout {
    background-color: #85d4bb;
    color: white;
  }
`;
export const PaySection = styled.div`
  .buy {
    width: 80%;
    padding: 0.5rem 0;
    cursor: pointer;
    background-color: #75b600;
    border: none;
    border-radius: 1rem;
    color: white;
  }
  .delete {
    width: 80%;
    padding: 0.5rem 0;
    cursor: pointer;
    background-color: red;
    border: none;
    border-radius: 1rem;
    color: white;
  }
`;
