import styled from "styled-components";
import cont3 from "../../assets/cont3.png";
import cont4 from "../../assets/cont4.png";
import cont5 from "../../assets/cont5.png";
import cont6 from "../../assets/cont6.png";
import cont7 from "../../assets/cont7.png";
import cont8 from "../../assets/cont8.png";
import cont9 from "../../assets/cont9.png";
import cont10 from "../../assets/cont10.png";
import cont11 from "../../assets/cont11.png";
import cont12 from "../../assets/cont12.png";
import cont13 from "../../assets/cont13.png";
import cont14 from "../../assets/cont14.png";
import cont15 from "../../assets/cont15.png";
import { Container } from "@mui/material";
import PageOneContract from "./PageOneContarct";
import PageTowContract from "./PageTowContarct";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import {singleOpportunitiesAction} from "../../Redux/Actions/SingleOpportunitieAction";

const ContractSolar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const OpportunityId = location.pathname.split("/")[4];

  useEffect(() => {
    dispatch(singleOpportunitiesAction(OpportunityId));
  }, [OpportunityId]);

  const { singleOpportunity } = useSelector((state) => state.leads);

  return (
    <ContarctSolarStyled>
      <Container>
        <PageOneContract cost={singleOpportunity.estimated_cost} />
        <PageTowContract singleOpportunity={singleOpportunity} />
        <div className="pageThree">
          <div className="image_Container">
            <img src={cont3} alt="alt" />
          </div>
          <div className="image_Container">
            <img src={cont4} alt="alt" />
          </div>
          <div className="image_Container">
            <img src={cont5} alt="alt" />
          </div>
          <div className="image_Container">
            <img src={cont6} alt="alt" />
          </div>
          <div className="image_Container">
            <img src={cont7} alt="alt" />
          </div>
          <div className="image_Container">
            <img src={cont8} alt="alt" />
          </div>
          <div className="image_Container">
            <img src={cont9} alt="alt" />
          </div>
          <div className="image_Container">
            <img src={cont10} alt="alt" />
          </div>
          <div className="image_Container">
            <img src={cont11} alt="alt" />
          </div>
          <div className="image_Container">
            <img src={cont12} alt="alt" />
          </div>
          <div className="image_Container">
            <img src={cont13} alt="alt" />
          </div>
          <div className="image_Container">
            <img src={cont14} alt="alt" />
          </div>
          <div className="image_Container">
            <img src={cont15} alt="alt" />
          </div>
        </div>
      </Container>
    </ContarctSolarStyled>
  );
};

const ContarctSolarStyled = styled.div`
  .image_Container {
    width: 100%;
    img {
      width: 100%;
    }
  }
`;

export default ContractSolar;
