import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { url } from "../../API/Api";
// import { baseQuery } from "./helper/index.js";
export const distributor = createApi({
  reducerPath: "distributorApi",
  tagTypes: ["distributor"],
  baseQuery: fetchBaseQuery({
    baseUrl: "https://distributor.boxbyld.tech/api/admin/",
  }),
  endpoints: (builder) => ({
    getdistributors: builder.query({
      query: (stateId, page) => ({
        url: `/distributors`,
        method: "get",
        params: {
          state_id: stateId,
          paginate: page,
        },
      }),
      providesTags: ["distributor"],
    }),
  }),
});

export const { useGetdistributorsQuery } = distributor;
