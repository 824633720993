"use client";
import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import FirstForm from "./firstForm";
import SecondForm from "./secondForm";
import ThirdForm from "./thirdForm";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import MyStepper from "./steppr";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { BigSection } from "./style";
import {
  adderNames,
  batteriesAdders,
  electricalAdders,
  generatorsAdders,
  hvacsAdders,
  roofingAdders,
} from "./thirdForm/staticData";
import {
  useCreateSalesAccountMutation,
  useGetTokenInfoQuery,
  useInviteSalesByTokenMutation,
} from "../../../../Redux/toolKit/salesForm";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../../components/loading/Loading";

const FormContainer = () => {
  const navigate = useNavigate();
  const [createAccount, { isLoading, isError, isSuccess, error }] =
    useInviteSalesByTokenMutation();
  const params = useParams();
  const { id } = params;
  const {
    data,
    isLoading: tokenLoading,
    error: tokenError,
  } = useGetTokenInfoQuery(id);
  const validationSchema = yup.object().shape({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    referrer: yup.string("Enter your email").email("Enter a valid email"),
    phone: yup
      .string("Enter your phone")
      .required("phone is required")
      .min(10, "Enter valid numbers"),
    password: yup
      .string("Enter your password")
      .required("Password is required")
      .min(8, "Password should be of minimum 8 characters length"),
    password_confirmation: yup
      .string("Enter your password confirm")
      .required("Password Confirmation is required")
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .min(8, " should be of minimum 8 characters length"),
    name: yup
      .string("Enter your  Name")
      .min(3, "Name Should be more than 3 letters")
      .max(25, "Name Should be less than 21 letters")
      .required("Name is required"),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  useEffect(() => {
    methods.setValue("name", data?.data?.name);
    methods.setValue("email", data?.data?.email);
    methods.setValue("referrer", data?.data?.user?.email);
  }, [data?.data]);

  const createAccountForm = async (sendData) => {
    console.log("Data", { ...sendData, token: data?.data?.id });
    try {
      await createAccount({ ...sendData, token: data?.data?.id }).unwrap();
      toast.success("You have created your Account ");
      Swal.fire({
        title: "Registration Successful!",
        // text: "Please verify your account by the mail sent to you",
        icon: "success",
        confirmButtonText: "Go to Login",
        showCancelButton: true,
        cancelButtonText: "Stay Here",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/login");
        }
      });
    } catch (e) {}
  };

  const renderErrors = () => {
    if (error.status == 422) {
      return Object?.keys(error?.data?.error || {})?.map((key) => {
        return (error?.data?.error[key] || [])?.map((err, index) => (
          <p
            style={{ color: "red" }}
            key={`${key}-${index}`}
          >{`${key}: ${err}`}</p>
        ));
      });
    }
    if (error.status == 503) {
      return (
        <p style={{ color: "red" }}>
          The mail server could not deliver mail, account or domain may not
          exist, they may be blacklisted, or missing the proper dns entries.
        </p>
      );
    }
    if (error.status == 500) {
      <p style={{ color: "red" }}>Server Error</p>;
    }
    return;
  };
  console.log("tokenError", tokenError);
  const renderErrorsToken = () => {
    if (tokenError) {
      if (tokenError?.status == 422) {
        return Object?.keys(tokenError?.data?.error || {})?.map((key) => {
          return (tokenError?.data?.error[key] || [])?.map((err, index) => (
            <p
              style={{ color: "red" }}
              key={`${key}-${index}`}
            >{`${key}: ${err}`}</p>
          ));
        });
      }
      if (tokenError?.status == 503) {
        return (
          <p style={{ color: "red" }}>
            The mail server could not deliver mail, account or domain may not
            exist, they may be blacklisted, or missing the proper dns entries.
          </p>
        );
      }
      if (tokenError?.status == 500) {
        <p style={{ color: "red" }}>Server Error</p>;
      }
      if (tokenError?.status == 404) {
        console.log("Happedned");
        return <p style={{ color: "red" }}>Invitation Expired</p>;
      }
    }
    return;
  };
  if (tokenLoading) {
    return <Loading />;
  }
  if (tokenError) {
    return (
      <h1 style={{ textAlign: "center" }}>
        You can't register By this Invitaoin
      </h1>
    );
  }
  return (
    <BigSection>
      {error?.data?.error && (
        <>
          <div className="first">
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <h1>Errors</h1>
              {renderErrors()}
            </Grid>
          </div>
        </>
      )}
      {tokenError?.data?.error && (
        <>
          <div className="first">
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <h1>Errors</h1>
              {renderErrorsToken()}
            </Grid>
          </div>
        </>
      )}
      <>
        <div className="first">
          <h1>Complete your registration</h1>
        </div>
        <div className="container">
          <Box sx={{ width: "100%" }}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(createAccountForm)}>
                <>
                  <Grid
                    container
                    columns={12}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <FirstForm methods={methods} />
                  </Grid>

                  <div className="btns">
                    <button type="submit">
                      {isLoading ? "Submiting..." : "Submit"}
                    </button>
                  </div>
                </>
              </form>
            </FormProvider>
          </Box>
        </div>
      </>
    </BigSection>
  );
};

export default FormContainer;
