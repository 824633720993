import { Rating } from "@mui/material";
import { DataContent } from "./UserStyle";
import Calculator from "../../../components/calculator/Calculator";
import { AccountCircle, Search } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { GetSingleUser } from "../../../Redux/Actions/EditUserAction";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { getLeadsFiltered } from "../../../Redux/Actions/leadFilterAction.jsx";
import {
  getLeadActions,
  keyWordString,
} from "../../../Redux/Actions/leadActions";
import styled from "styled-components";
import axios from "axios";
import animationData from "../../proposals/loader/98695-loader.json";
import Lottie from "react-lottie";
// import "./userStyle.css";

// animation
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const User = () => {
  // Try
  const myToken = localStorage.getItem("token");
  // dispatch(ShowLoading())
  const headers = {
    Authorization: `Bearer ${myToken}`,
    Accept: "application/json",
  };
  const dispatch = useDispatch();
  const [keyWord, setKeyWord] = React.useState(null);
  const [keyWordNew, setKeyWordNew] = React.useState("");
  const { token, name, title, user_id } = useSelector((state) => state.auth);
  const filteredLeads =
    useSelector((state) => state.FilteredLeads.allFilteredLeads) || [];
  const Loader = useSelector((state) => state.FilteredLeads.isLoading);
  const String = useSelector((state) => state.leads.stringSearch);
  const { leadlist, isLeadLoading } = useSelector((state) => state.leads);

  // console.log("String",String)

  useEffect(() => {
    dispatch(GetSingleUser(user_id));
  }, [user_id]);

  // React.useEffect(() => {
  //   if (keyWord.length < 3) {
  //   } else {
  //     dispatch(getLeadsFiltered(keyWord));
  //   }
  //   dispatch(keyWordString(keyWord))
  // }, [keyWord]);

  // make the Request after the user finish writting

  React.useLayoutEffect(() => {
    // if (keyWord) {
    // const delayDebounceFn = setTimeout(() => {
    // dispatch(getLeadActions(keyWord));
    // if (keyWord) {
      dispatch(keyWordString(keyWord));
    // }
    // }, 550);

    // return () => clearTimeout(delayDebounceFn);
    // }
  }, [keyWord]);

  const { getMainUser } = useSelector((state) => state.editUser);
  if (token === null) return null;
  return (
    <>
      <DataContent>
        <div className="user">
          <div className="user__data">
            <div className="user__data--img">
              <Link to="/profile/main">
                {getMainUser.image_path !== "" ? (
                  <img src={getMainUser.image_path} alt="user" />
                ) : (
                  <AccountCircle />
                )}
              </Link>
            </div>
            <div className="user__data--content">
              <Link to="/profile/main">
                <h3 className="title">welcome {name}</h3>
              </Link>
              <span className="captain">{title}</span>
              <Rating name="read-only" value={5} readOnly />
            </div>
          </div>
        </div>
        <div className="search">
          {/* <div className="icon" id="calculator">
            <Calculator />
          </div> */}
          <div className="search-input">
            {/* <form onSubmit={searchFun}> */}
            <input
              type="text"
              className="form-control"
              placeholder="Who you are looking for...."
              onChange={(e) => setKeyWord(e.target.value)}
            />
            {/* {keyWord.length == 0 ? (
              <span
                style={{
                  fontSize: "10px",
                  position: "absolute",
                  left: "1.2rem",
                  top: "2.8rem",
                }}
              >
                Enter at least 3 chars
              </span>
            ) : (
              <span></span>
            )} */}
            {isLeadLoading === true ? (
              <span
                style={{
                  position: "absolute",
                  right: "12px",
                  fontSize: "18px",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                <Lottie options={defaultOptions} height={40} width={80} />
              </span>
            ) : (
              <span></span>
            )}
            <div className="suggestMenu">
              {leadlist?.map((e) => {
                return (
                  <div>
                    <ul>
                      <li>
                        {" "}
                        <Link key={e.id} to={`/profilelead/${e.id}`}>
                          {" "}
                          {e.first_name + " " + e.last_name}
                        </Link>
                      </li>
                    </ul>
                  </div>
                );
              })}
            </div>

            <Search className="searchIcon" />
            {/* </form> */}
          </div>
        </div>
      </DataContent>
    </>
  );
};

export default User;
