import { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { TabPanel, TabContext, TabList } from "@mui/lab";
import TeamMemberLeads from "./TeamMemberLeads";
const TeamMemberTabs = ({ leads, leadId }) => {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  var user = JSON.parse(localStorage.getItem("salesAgent"));
  const children=user?.children||[]
  console.log("children",children)
  console.log("user",user)
  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Leads" value="1" />
            <Tab label="Progress" value="2" />
            <Tab label="Teams" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <TeamMemberLeads leads={leads} leadId={leadId} />
        </TabPanel>
        <TabPanel value="2">Item Two</TabPanel>
        <TabPanel value="3">Item Three</TabPanel>
      </TabContext>
    </Box>
  );
};

export default TeamMemberTabs;
