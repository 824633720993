import {
  Box,
  Button,
  Container,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useFormik } from "formik";
import * as yup from "yup";
import { useEffect, useState } from "react";
import {
  getAllDeparments,
  getAllDeparmentUser,
} from "../../Redux/Actions/MessageActions";
import axios from "axios";
import swal from "sweetalert";

// const validationSchema = yup.object({
//   department: yup
//     .string("Enter your department")
//     .required("Password is required"),
//   password_confirmation: yup
//     .string("Confirm Your Password")
//     .min(8, "Password should be of minimum 8 characters length")
//     .required("Password is required"),
// });

const MessageForm = () => {
  const dispatch = useDispatch();
  const { deparments } = useSelector((state) => state.messages);

  const [deparment, setDeparment] = useState({});
  const [loading, setloading] = useState(false);
  const [file_path, setFile_path] = useState("");
  const [data, setData] = useState({
    user_receiver: {},
    body: "",
  });
  const GET_USER_ID = localStorage.getItem("user_id");
  const GET_ROLE_ID = localStorage.getItem("role_id");

  const DepartmentID = deparment?.id;
  const DepaermentTitle = deparment?.title;
  const userResiverSite = data.user_receiver?.site;
  const userResiverId = data.user_receiver?.id;

  //redux reducer
  const { allUsers } = useSelector((state) => state.messages);

  const PM_USERS = allUsers?.pm?.users ? allUsers?.pm?.users : [];
  const CRM_USERS = allUsers?.crm?.users ? allUsers?.crm?.users : [];

  console.log("data", userResiverSite);

  console.log("formCOntainer");

  const handelSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    // formData.append("_method", "PATCH");
    formData.append("user_sender", GET_USER_ID);
    formData.append("site_sender", "crm");
    formData.append("department_id_sender", GET_ROLE_ID);
    formData.append("file_path", file_path);
    formData.append("user_receiver", userResiverId);
    formData.append("site_receiver", userResiverSite);
    formData.append("department_id_receiver", DepartmentID);
    formData.append("body", data.body);

    setloading(true);

    const headers = {
      "Content-Type": "multipart/form-data",
      enctype: "multipart/form-data",
      token: "bL4r$a$c$e6JA8cU",
    };
    await axios({
      method: "POST",
      baseURL: `https://msg.boxbyld.tech/api/chat/message/store`,
      data: formData,
      headers: headers,
    })
      .then((res) => {
        setloading(false);
        swal({
          title: "success",
          text: `The messages sent successfully  `,
          icon: "success",
          button: "OK",
        });
      })
      .catch((err) => {
        console.log(err.data);
        swal({
          title: " error ",
          text: `there some problem `,
          icon: "error",
          button: "OK",
        });
      });
  };

  useEffect(() => {
    dispatch(getAllDeparments());
  }, []);

  useEffect(() => {
    if (DepaermentTitle && DepartmentID) {
      dispatch(
        getAllDeparmentUser({
          id: DepartmentID,
          title: DepaermentTitle,
        })
      );
    }
  }, [DepaermentTitle, DepartmentID]);
  return (
    <ComposeStyled>
      <h2>Send Message to Department</h2>
      <form onSubmit={handelSubmit}>
        <Box sx={{ my: "1rem" }}>
          <TextField
            required
            select
            fullWidth
            label="Department"
            value={deparment}
            onChange={(e) => {
              setDeparment(e.target.value);
            }}
          >
            {deparments.map((item) => (
              <MenuItem key={item.id} value={item}>
                {item.title}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        {DepartmentID !== 4 || DepartmentID === 3 ? (
          <Box sx={{ my: "1rem" }}>
            <TextField
              required
              select
              fullWidth
              label="CRM Users"
              value={data.user_receiver}
              onChange={(e) =>
                setData({ ...data, user_receiver: e.target.value })
              }
            >
              {CRM_USERS?.map((item) => (
                <MenuItem key={item.id} value={item}>
                  {item.name} : {item.email}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        ) : null}
        {DepartmentID === 4 || DepartmentID === 2 ? (
          <Box sx={{ my: "1rem" }}>
            <TextField
              required
              select
              fullWidth
              label="PM Users "
              value={data.user_receiver}
              onChange={(e) =>
                setData({ ...data, user_receiver: e.target.value })
              }
            >
              {PM_USERS?.map((item) => (
                <MenuItem key={item.id} value={item}>
                  {item.name} : {item.email}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        ) : null}

        <Box sx={{ my: "1rem" }}>
          <InputLabel>Message </InputLabel>
          <textarea
            placeholder="Enter your Message "
            className="text_area"
            label="Department"
            value={data.body}
            onChange={(e) => setData({ ...data, body: e.target.value })}
          />
        </Box>

        <Box>
          <InputLabel>Attach File </InputLabel>
          <Input
            accept="image/*,video/*"
            id="contained-button-file"
            type="file"
            multiple
            onChange={(e) => setFile_path(e.target.files[0])}
          />
        </Box>

        <Button
          type="submit"
          fullWidth
          sx={{
            margin: "1rem 0",
            background: "#000",
            color: "#fff",
            padding: ".7rem 0",
            "&:hover": { color: "#000" },
          }}
        >
          {loading ? " loading..." : "Send Message"}
        </Button>
      </form>
    </ComposeStyled>
  );
};

const ComposeStyled = styled.div`
  padding: 1rem;
  h2 {
    text-align: center;
    margin: 1rem 0;
  }
  .text_area {
    width: 100%;
    min-height: 250px;
    padding: 1rem;
    border-radius: 0.3rem;
  }
`;
export default MessageForm;
