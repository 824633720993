import axios from "axios";
import swal from "sweetalert";
import { MAIN_URL } from "../../API/Api";
import { POST_FINANCE } from "./type";
import { ShowLoading, HideLoading } from "./loadingAction";
import { Update_Finantial_Info } from "../../API/Api";

export  const EditFProfileAction = (data) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    await axios
      .post(`${MAIN_URL}/api/v1/financial-profile`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        swal({
          title: "The profile has been Added",
          icon: "success",
          button: "OK",
        });
        console.log(res.data);
        dispatch({
          type: POST_FINANCE,
          finanice_data: res.data,
        });
      })
      .catch((err) => {
        console.log("profile error", err);
        swal({
          title: " error ",
          text: " There is a problem when create Financial Profile ",
          icon: "error",
          button: "OK",
        });
      });
  };
};

// const UpdateFProfileAction = (data) => {
//   return async (dispatch) => {
//     const token = localStorage.getItem("token");
//     await axios
//       .patch(`${UPDATA_LEAD_PROFILE_URL}`, data, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//           Accept: "application/json",
//           "Content-Type": "multipart/form-data",
//         },
//       })
//       .then((res) => {
//         swal({
//           title: "The profile has been updated",
//           // text: "You clicked the button!",
//           icon: "success",
//           button: "OK",
//         });
//         console.log(res.data);
//         dispatch({
//           type: POST_FINANCE,
//           finanice_data: res.data,
//         });
//       })
//       .catch((err) => {
//         console.log("profile error", err);
//         swal({
//           title: " error ",
//           text: " There is a problem when create Financial Profile ",
//           icon: "error",
//           button: "OK",
//         });
//       });
//   };
// };
export const UpdateFProfileAction = (data,id) => async (dispatch) => {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  await axios({
    method: "PATCH",
    baseURL: `${Update_Finantial_Info}/${id}`,
    headers: headers,
    data:data
  })
    .then((res) => {
      swal({
        title: "The profile has been updated",
        icon: "success",
        button: "OK",
      });

      dispatch({
        type: POST_FINANCE,
        finanice_data: res.data,
      });
    })
    .catch((err) => {
      console.log(err.data);
    });
};
