import React from "react";
import { UserData } from "../../style";
import { Grid } from "@mui/material";
import {
  AccountCircle,
  AttachMoney,
  Email,
  Language,
  LocalPhone,
  LocationOn,
  Map,
  MyLocation,
} from "@mui/icons-material";

const LeadData = ({ singlead }) => {
  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return " (" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  }
  return (
    <UserData>
      <h2 className="leadInfo">Lead Info.</h2>
      <Grid container columns={18} spacing={3}>
        <Grid item md={9} xs={18}>
          <div className="data">
            <h4 className="key">
              <AccountCircle /> name
            </h4>
            <span className="value">
              {singlead?.first_name} {singlead?.last_name}
            </span>
          </div>
          <div className="data">
            <h4 className="key">
              <LocalPhone />
              phone
            </h4>
            <span className="value">{formatPhoneNumber(singlead?.phone)}</span>
          </div>
          <div className="data">
            <h4 className="key">
              <LocalPhone />
              mobile
              <span className="value">
                {formatPhoneNumber(singlead?.mobile)}
              </span>
            </h4>
          </div>
          <div className="data">
            <h4 className="key">
              <Email /> email
            </h4>
            <span className="value">{singlead?.email}</span>
          </div>
          <div className="data">
            <h4 className="key">
              <AttachMoney /> price
            </h4>
            <span className="value">${singlead?.price}</span>
          </div>
        </Grid>
        <Grid item md={9} xs={18}>
          <div className="data">
            <h4 className="key">
              <Language /> country
            </h4>
            <span className="value">{singlead?.country.name}</span>
          </div>
          <div className="data">
            <h4 className="key">
              <LocationOn /> state
            </h4>
            <span className="value">{singlead?.state.name}</span>
          </div>
          <div className="data">
            <h4 className="key">
              <MyLocation /> street
            </h4>
            <span className="value">{singlead?.street}</span>
          </div>

          <div className="data">
            <h4 className="key">
              <Map /> zip code
            </h4>
            <span className="value">{singlead?.zip_code}</span>
          </div>
        </Grid>
      </Grid>
    </UserData>
  );
};

export default React.memo(LeadData);
