import { Grid } from "@mui/material";
import React from "react";
import styled from "styled-components";
import FooterPDF from "./FooterPDF";

const PageTwoPdf = () => {
  return (
    <PageTwoStyled>
      <h2>ABOUT ALL PREMIUM CONSTRUCTION, INC.</h2>
      <Grid container columns={18} spacing={6}>
        <Grid item sm={9} md={6} xs={18}>
          <div className="gridItem">
            <h4>Proven Track Record</h4>
            <p>
              We’re California’s leading, fullservice solar energy provider,
              having installed over 7,000 solar system over the last 10 years.
              Over 95% of our customers said they were “Extremely Satisfied”
              with our work. We have a 4.9-star average review on Yelp, and A+
              rating with the Better Business Bureau.{" "}
            </p>
          </div>
        </Grid>
        <Grid item sm={9} md={6} xs={18}>
          <div className="gridItem">
            <h4>Custom Approach</h4>
            <p>
              We believe that when it comes to designing and installing an
              integrated residential solar PV system, one size does not fit all.
              We custom tailor every system to best fit our customers’ homes,
              lifestyle and savings goals. Our energy consultants will work with
              you to determine the best fit technology, system size, and
              financing offerings for your needs.
            </p>
          </div>
        </Grid>
        <Grid item sm={9} md={6} xs={18}>
          <div className="gridItem">
            <h4>Highest Quality</h4>
            <p>
              We hire and train the best installers in the business, many of
              which have been with us for over a decade. We do everything
              in-house, allowing us to design and install highperformance
              integrated solar energy systems, and then guarantee the
              performance and production. We have a rigorous 100-point quality
              assurance process and customer satisfaction process.
            </p>
          </div>
        </Grid>
      </Grid>
      <div className="customer">
        <h3>Customer Testimonials</h3>
        <p>
          I live in the Whittier area. All Premium Contractors came to my home
          and they installed my brand new solar system. I appreciated the entire
          crew who came in and did a very thorough and professional job. I would
          highly recommend them to others because they were extremely on point.
          Because of what they did at my home, I will now expect no electric
          bills, making us very happy. Thanks APC!
          <span>- ED SANCHEZ Whittier, CA</span>
        </p>
        <br />
        <p>
          They were professional from the start and not pushy like some of the
          other solar companies, that just wanted me to sign papers right away.
          They were done with the install in TWO weeks, not six months like
          other companies had estimated. Edison took a month to certify/inspect
          and credit my electric bill. my bill has dropped to Ten dollars a
          month! compared to 7- 8 hundred a month, I couldn't believe it, but
          it's true. I wish they would have knocked on my door sooner, I would
          have saved a lot of money with my solar panels.
          <span>- ALAN COTA Long Beach, CA</span>
        </p>
      </div>

      <FooterPDF />
    </PageTwoStyled>
  );
};

const PageTwoStyled = styled.div`
  min-height: 100vh;
  color: #364178;
  h2 {
    text-align: center;
    padding: 2rem 0;
    font-weight: normal;
  }
  .gridItem {
    h4 {
      color: #4e5aa1;
      font-weight: normal;
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
    p {
      font-size: 1.1rem;
      line-height: 28px;
      font-weight: 600;
      color: #626364;
    }
  }
  .customer {
    padding: 3rem 0;
    h3 {
      margin-bottom: 3rem;
    }
    p {
      font-weight: 400;
      color: #555;
      span {
        color: #4e5aa1;
        font-size: 1.2rem;
      }
    }
  }
`;

export default PageTwoPdf;
