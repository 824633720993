import axios from "axios";
import { redirect } from "react-router-dom";

import {
  ARCHIVE_LEAD,
  ASIGN_LEAD_TO_USER,
  GET_LEAD,
  GET_LEAD_ARCHIVE,
  GET_LEAD_SKIP_AND_TAKE,
  ACHIEVEMENT,
  PARENT_ACHIEVEMENT,
  SALES_ACHIEVEMENT,
} from "./type";
import {
  ASIGN_LEAD_TO_USER_URL,
  EDIT_LEAD_PROFILE_URL,
  GET_SINGLE_MEMBER_URL,
  LEAD_URL,
  MAIN_URL,
  GET_LEADS_BANK,
  CheckOut,
  GetAllInvoices,
  GetInvoice,
  Buy_invoice,
  Delete_invoice,
  achievement_api,
  get_parent_achievement_api,
  get_sales_achievement_process_api,
} from "../../API/Api";
import { HideLoading, ShowLoading } from "./loadingAction";
import swal from "sweetalert";
import { SignOut } from "./authActions";

export const getLeadActions = (param, search) => {
  return async (dispatch) => {
    dispatch({
      type: "IS_LEAD_LOADING",
    });
    const token = localStorage.getItem("token");
    await axios
      .get(`${LEAD_URL}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
        params: {
          keyword: param,
          has_contract: search,
        },
      })
      .then((res) => {
        // console.log("then");
        dispatch({
          type: GET_LEAD,
          leads: [...res.data.data],
        });
      })
      .catch((err) => {
        console.log("all Error", err?.response?.status);

        if (err?.response?.status === 401) {
          dispatch(SignOut());
        }
      });
  };
};

export const getLeadsSkipAndTakes = (skip, take) => async (dispatch) => {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  await axios({
    method: "GET",
    baseURL: `${MAIN_URL}/api/v1/leads/skip/${skip}/take/${take}`,
    headers: headers,
  })
    .then((res) => {
      dispatch({
        type: GET_LEAD_SKIP_AND_TAKE,
        leadsSkipTake: [...res.data.data],
      });
    })
    .catch((err) => {
      console.log(err.data);
      if (err?.response?.status === 401) {
        dispatch(SignOut());
      }
    });
};

export const asignLeadToUserAction = (id, data) => {
  return async (dispatch) => {
    dispatch(ShowLoading());
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    await axios({
      method: "PUT",
      baseURL: `${ASIGN_LEAD_TO_USER_URL}/${id}`,
      data: data,
      headers: headers,
    })
      .then((res) => {
        dispatch({
          type: ASIGN_LEAD_TO_USER,
          asignLead: res.data,
        });

        dispatch(HideLoading());
        swal({
          title: " Succsfully ",
          text: " The Lead Assigned successfully",
          icon: "success",
          button: "OK",
        });
      })
      .catch((err) => {
        dispatch(HideLoading());
        swal({
          title: " error ",
          text: " There is a problem when Asign the Lead ",
          icon: "error",
          button: "OK",
        });
        if (err?.response?.status === 401) {
          dispatch(SignOut());
        }
      });
  };
};

export const ArchiveLead = (id) => {
  return async (dispatch) => {
    dispatch(ShowLoading());
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    await axios({
      method: "PUT",
      baseURL: `${EDIT_LEAD_PROFILE_URL}/${id}/status`,
      headers: headers,
    })
      .then((res) => {
        dispatch({
          type: ARCHIVE_LEAD,
          payload: {
            archiveData: res.data,
          },
        });
        dispatch(HideLoading());
        swal({
          title: " Succsfully ",
          text: " The Lead Archived successfully",
          icon: "success",
          button: `OK`,
        });
      })
      .catch((err) => {
        dispatch(HideLoading());
        if (err?.response?.status === 401) {
          dispatch(SignOut());
        }
      });
  };
};

export const GetArchivedLeads = (id, data) => {
  return async (dispatch) => {
    dispatch({
      type: "IS_LEAD_LOADING",
    });
    const token = localStorage.getItem("token");
    await axios
      .get(`${GET_SINGLE_MEMBER_URL}/${id}/status/${data}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      })
      .then((res) => {
        // console.log("then");
        dispatch({
          type: GET_LEAD_ARCHIVE,
          archiveLeads: [...res.data.data.leads],
        });
      })
      .catch((err) => {
        console.log(err.data);
        if (err?.response?.status === 401) {
          dispatch(SignOut());
        }
      });
  };
};

// action for keyword for string
export const keyWordString = (data) => {
  return async (dispatch) => {
    await dispatch({
      type: "KEY_WORD",
      stringSearch: data,
    });
  };
};

// action for keyword for toggke search
export const KeyWorkToggle = (data) => {
  return async (dispatch) => {
    await dispatch({
      type: "KEY_TOGGLE",
      toggleSearch: data,
    });
  };
};
