import React from "react";
import { useGetTemplatesQuery } from "../../../../Redux/toolKit/template";
import { Bigsection } from "./style";

const ShowTemplates = () => {
  const { data, isLoading } = useGetTemplatesQuery("");
  console.log("data", data);
  const mapData = () => {
    return data?.data?.map((e) => {
      return (
        <tr>
          <td>{e?.filename}</td>
          <td>
            <a href={e?.media_url} target="_blank" style={{ color: "blue" }}>
              Pfd link
            </a>
          </td>
        </tr>
      );
    });
  };
  if (isLoading) {
    return <h1>Loading...</h1>;
  }
  return (
    <Bigsection>
      <table>
        <tr>
          <th>File name</th>
          <th>pdf</th>
        </tr>
        {mapData()}
      </table>
    </Bigsection>
  );
};

export default ShowTemplates;
