import { Button } from "@mui/material";
import styled from "styled-components";

export const FormConatiner = styled.div`
  position: relative;
  width: 95%;
  min-height: 80vh;
  padding: 3rem 1rem;
  margin: 2rem auto;
  border-radius: 1.5rem;
  box-shadow: 0 5px 15px inset rgb(0 0 0 / 7%);
  -webkit-box-shadow: 0 5px 15px inset rgb(0 0 0 / 7%);
  -moz-box-shadow: 0 5px 15px inset rgb(0 0 0 / 7%);
`;
export const SubmitButton = styled.button`
  width: 100%;
  height: 70px;
  font-size: 25px;
  border: none;
  cursor: pointer;
  font-weight: 600;
  background-image: linear-gradient(
    to right,
    #000000 0%,
    #e0e0e0 50%,
    #000000 100%
  );
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;

  &:hover {
    box-shadow: rgba(160, 160, 160, 0.9) 0 8px 15px;
    transform: translateY(-2px);
    background-position: right center;
    color: #fff;
    text-decoration: none;
  }
`;
export const AddLicense = styled.button`
  /* border: none;
  background-color: black;
  font-size: 15px;
  cursor: pointer;
  color: white;
  width: 100%;
  height: 70px; */
  border: none;
  /* padding: 5px 12px; */
  cursor: pointer;
  width: 100%;
  padding: 20px 0;
`;
export const MyButton = styled(Button)`
  width: 100%;
  height: 100%;
  &:hover {
    background-color: white !important;
    color: black;
  }
`;
export const Table = styled.table`
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  overflow: hidden;
  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  tr:nth-child(even) {
    background-color: #dddddd;
  }
`;
export const DeleteButton = styled.button`
  border: none;
  cursor: pointer;
  color: red;
  background-color: white;
  border-radius: 30px;
  transition: 1s all;
  /* &:hover{
  transform: translateY(-4px);
} */
`;
export const Result = styled.div`
  box-shadow: 5px 5px 5px #a6a6a6;
  width: 100%;
`;
export const AddBtn = styled.button`
  appearance: none;
  background-color: #000000;
  border: 2px solid #1a1a1a;
  border-radius: 15px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 20px 0;
  /* min-height: 60px; */
  min-width: 0;
  outline: none;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  /* width: 100%; */
  will-change: transform;

  &:disabled {
    pointer-events: none;
  }

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  &:active {
    box-shadow: none;
    transform: translateY(0);
  }
`;
export const Loading = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;