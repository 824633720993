import { Person } from "@mui/icons-material";
import { Link } from "react-router-dom";

import styled from "styled-components";

const TeamCardsUser = ({ Team }) => {
  return (
    <TeamCardStyled>
      <Link to={`/profile/team-member=${Team.id}`}>
        <div className="gridItem">
          <div className="icon">
            <div className="icon__content">
              {Team.image_path !== "" ? (
                <img src={Team.image_path} alt="name" />
              ) : (
                <Person />
              )}
            </div>
          </div>
          <div className="data">
            <span className="heading">{Team.name}</span>
            <span className="number">PPW ${Team.ppw} </span>
            <div className="otherData">
              <span className="penfits">{Team.mantras}</span>
              {/* <span className="penfits">address :{Team.address}</span>
              <span className="penfits"> business :{Team.business}</span> */}
            </div>
          </div>
        </div>
        {/* <div className="otherData">
          <span className="penfits">mantras :{Team.mantras}</span>
          <span className="penfits">address :{Team.address}</span>
          <span className="penfits"> business :{Team.business}</span>
        </div> */}
        <div className="Counts">
          <div className="items">
            <div className="number">{Team.leads_count}</div>
            <div className="title">leads</div>
          </div>
          <div className="items">
            <div className="number">{Team.opportunities_count}</div>
            <div className="title">opportunities</div>
          </div>
          <div className="items">
            <div className="number">{Team.solar_contracts_count}</div>
            <div className="title">solar contracts</div>
          </div>
        </div>
      </Link>
    </TeamCardStyled>
  );
};

const TeamCardStyled = styled.div`
  /* background: linear-gradient(265deg, #0b0098 0%, #4c107f 57%); */
  background: #00262f;
  box-shadow: 0 2px 10px #00000058;
  backdrop-filter: blur(2px);
  padding: 2rem;
  border-radius: 1rem;
  .gridItem {
    display: flex;
    /* flex-direction: column; */
    /* justify-content: space-around; */
    align-items: center;
    .icon {
      &__content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 2rem;
        /* background: linear-gradient(265deg, #0b0098 0%, #4c107f 57%); */
        background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
        img {
          width: 70px;
          height: 70px;
          border-radius: 50%;
        }
        svg {
          width: 100px;
          color: white;
          font-size: 4.5rem;
        }
      }
    }
    .data {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: #888;
      span {
        display: inline-block;
      }
      .heading {
        color: #f9f9f9;
        letter-spacing: 1.1px;
      }
      .number {
        font-size: 1rem;
        color: #eee;
        letter-spacing: 1.3px;
      }
    }
  }
  .otherData {
    /* border-bottom: 1px solid #e7e7e765; */
    /* padding: 0.5rem 0; */
    .penfits {
      display: block;
      color: #888;
      text-transform: capitalize;
    }
  }
  .Counts {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 1rem 0 0 0;
    .items {
      text-align: center;
      flex: 1;
      .number {
        font-size: 1.5rem;
        font-weight: bold;
        color: #0081cc;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 2px solid #007ac0;
        margin: 0.5rem auto;
      }
      .title {
        color: #bcd9e2;
        font-size: 0.7rem;
      }
    }
  }
`;
export default TeamCardsUser;
