import axios from "axios";
import Swal from "sweetalert2";
import {
  create_concert_offer,
  get_concert_products,
  concert_get_payment,
  concert_create_project,
  concert_submit_offer,
  get_eligibilities_status,
} from "../../API/Api";
import { HideLoading, ShowLoading } from "./loadingAction";

export const CONCERT_GET_OFFER = "CONCERT_GET_OFFER";
export const GET_PRODUCT_METHODS = "GET_PRODUCT_METHODS";
export const GET_CONCERT_PAYMENTS = "GET_CONCERT_PAYMENTS";
export const PREQUALIFY_CONCERT_PAYMENTS = "PREQUALIFY_CONCERT_PAYMENTS";
export const SUBMIT_CONCERT_OFFER = "SUBMIT_CONCERT_OFFER";
export const GET_CONCERT_HOOK = "GET_CONCERT_HOOK";

export const createConcertApplication = (data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  dispatch(ShowLoading());
  await axios({
    method: "POST",
    baseURL: `${create_concert_offer}`,
    headers: headers,
    data: data,
  })
    .then((res) => {
      dispatch(HideLoading());
      dispatch({
        type: CONCERT_GET_OFFER,
        payload: res.data.data,
      });
      // Swal.fire({
      //   icon: "success",
      //   title: "Your Application has been created",
      //   timer: 5500,
      //   showClass: {
      //     popup: "animate__animated animate__fadeInDown",
      //   },
      //   hideClass: {
      //     popup: "animate__animated animate__fadeOutUp",
      //   },
      // });
    })
    .catch((err) => {
      dispatch(HideLoading());
      console.log(err.data);
      const message = err?.response?.data?.error;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${message}`,
      });
    });
};

export const getConcertProductsMethods = (data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  dispatch(ShowLoading());
  await axios({
    method: "POST",
    baseURL: `${get_concert_products}`,
    headers: headers,
    data: data,
  })
    .then((res) => {
      dispatch(HideLoading());
      dispatch({
        type: GET_PRODUCT_METHODS,
        payload: res.data.data,
      });
      // Swal.fire({
      //   icon: "success",
      //   title: "Your Application has been created",
      //   timer: 5500,
      //   showClass: {
      //     popup: "animate__animated animate__fadeInDown",
      //   },
      //   hideClass: {
      //     popup: "animate__animated animate__fadeOutUp",
      //   },
      // });
    })
    .catch((err) => {
      dispatch(HideLoading());
      console.log(err.data);
      const message = err?.response?.data?.error;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${message}`,
      });
    });
};

export const getConcertPayment = (data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  dispatch(ShowLoading());
  await axios({
    method: "POST",
    baseURL: `${concert_get_payment}`,
    headers: headers,
    data: data,
  })
    .then((res) => {
      dispatch(HideLoading());
      dispatch({
        type: GET_CONCERT_PAYMENTS,
        payload: res.data.data,
      });
      // Swal.fire({
      //   icon: "success",
      //   title: "Your Application has been created",
      //   timer: 5500,
      //   showClass: {
      //     popup: "animate__animated animate__fadeInDown",
      //   },
      //   hideClass: {
      //     popup: "animate__animated animate__fadeOutUp",
      //   },
      // });
    })
    .catch((err) => {
      dispatch(HideLoading());
      console.log(err.data);
      const message = err?.response?.data?.error;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${message}`,
      });
    });
};

export const preQualifyConcert = (data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  dispatch(ShowLoading());
  await axios({
    method: "POST",
    baseURL: `${concert_create_project}`,
    headers: headers,
    data: data,
  })
    .then((res) => {
      dispatch(HideLoading());
      dispatch({
        type: PREQUALIFY_CONCERT_PAYMENTS,
        payload: res.data.data,
      });
      Swal.fire({
        icon: "success",
        title: "Your Application has been created",
        timer: 5500,
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      });
    })
    .catch((err) => {
      dispatch(HideLoading());
      console.log(err.data);
      const message = err?.response?.data?.error;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${message}`,
      });
    });
};

export const submitConcertOffer = (data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  dispatch(ShowLoading());
  await axios({
    method: "POST",
    baseURL: `${concert_submit_offer}`,
    headers: headers,
    data: data,
  })
    .then((res) => {
      Swal.fire({
        icon: "success",
        title: "Your Offer has been submitted",
        timer: 5500,
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      });
      dispatch(HideLoading());
      dispatch({
        type: SUBMIT_CONCERT_OFFER,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(HideLoading());
      console.log(err.response?.error);
      const message = err?.response?.data?.error;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `Please contact Concert for support`,
      });
    });
};

export const getConcerteligibilities = (data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  dispatch(ShowLoading());
  await axios({
    method: "POST",
    baseURL: `${get_eligibilities_status}`,
    headers: headers,
    data: data,
  })
    .then((res) => {
      dispatch(HideLoading());
      dispatch({
        type: GET_CONCERT_HOOK,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(HideLoading());
      console.log(err.data);
      const message = err?.response?.data?.error;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${message}`,
      });
    });
};

// Selected Data

export const allConcertCalc = (data) => {
  return async (dispatch) => {
    await dispatch({
      type: "CONCERT_ALL_DATA",
      concertAllData: data,
    });
  };
};
