import styled from "styled-components";
import React from "react";
import Grid from "@mui/material/Grid";
import dots from "../../../../assets/Dots.png";
import solar1 from "../../../../assets/solarimg.png";
import solar2 from "../../../../assets/solarimg2.png";
import solar3 from "../../../../assets/solarimg3.png";
import companies from "../../../../assets/companies.png";
import Qclass from "../../../../assets/Qcells.png";
import phonoSolar from "../../../../assets/phonoSolar.png";
import ironridge from "../../../../assets/ironridge.png";
import Enphase from "../../../../assets/Enphase.png";
import solaredge from "../../../../assets/solaredge.png";
const BigSection = styled.div``;
const Header1 = styled.p`
  text-align: center;
  font-size: 38px;
  font-weight: bold;
`;
const Header2 = styled.p`
  text-align: center;
  padding: 0 120px;
  @media (max-width: 768px) {
    padding: 0;
  } ;
`;
const Headerimg = styled.img`
  text-align: center;
`;
const Headerimg2 = styled.img`
  width: 80%;
  transition: transform 0.5s;
  &:hover {
    transform: scale(1.5);
  }
`;
const Image = styled.img`
  width: 100%;
  padding-top: 20px;
`;
const Date = styled.p`
  font-size: 15px;
  padding: 10px 0;
`;
const Header = styled.p`
  font-weight: 700;
`;
const Description = styled.p``;
const RecentBlog = () => {
  return (
    <BigSection>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={0}
      >
        <Grid item lg={8} md={8} sm={8} xs={12}>
          <Header1>Recent Blog Entries</Header1>
          <Header1>
            {" "}
            <Headerimg src={dots}></Headerimg>
          </Header1>
          <Header2>
            Firstly , we're an environmentally friendly renewable energy company
            offering a broad portfolio of technologies , products , & solutions
            to our clients globally
          </Header2>
        </Grid>
      </Grid>
      {/* Three Pics */}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item lg={3} md={4} sm={12} xs={12}>
          <Image src={solar1}></Image>
          <Date>DECEMBER 17 , 2016 </Date>
          <Header>Electricity is on track for ...</Header>
          <Description>
            were pretty confident that everyone knows by now just how harmful
            the use of carbon and its burning product CO2 is both for humans and
            for our planet So this is
          </Description>
        </Grid>
        <Grid item lg={3} md={4} sm={12} xs={12}>
          <Image src={solar2}></Image>
          <Date>DECEMBER 17 , 2016 </Date>
          <Header>Electricity is on track for ...</Header>
          <Description>
            were pretty confident that everyone knows by now just how harmful
            the use of carbon and its burning product CO2 is both for humans and
            for our planet So this is
          </Description>
        </Grid>
        <Grid item lg={3} md={4} sm={12} xs={12}>
          <Image src={solar3}></Image>
          <Date>DECEMBER 17 , 2016 </Date>
          <Header>Electricity is on track for ...</Header>
          <Description>
            were pretty confident that everyone knows by now just how harmful
            the use of carbon and its burning product CO2 is both for humans and
            for our planet So this is
          </Description>
        </Grid>
      </Grid>
      {/* companies */}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item lg={1.5} md={2} sm={4} xs={10}>
          <Header1>
            {" "}
            <Headerimg2 src={Qclass}></Headerimg2>
          </Header1>
        </Grid>
        <Grid item lg={1.5} md={2} sm={4} xs={10}>
          <Header1>
            {" "}
            <Headerimg2 src={phonoSolar}></Headerimg2>
          </Header1>
        </Grid>
        <Grid item lg={1.5} md={2} sm={4} xs={10}>
          <Header1>
            {" "}
            <Headerimg2 src={ironridge}></Headerimg2>
          </Header1>
        </Grid>
        <Grid item lg={1.5} md={2} sm={4} xs={10}>
          <Header1>
            {" "}
            <Headerimg2 src={Enphase}></Headerimg2>
          </Header1>
        </Grid>
        <Grid item lg={1.5} md={2} sm={4} xs={10}>
          <Header1>
            {" "}
            <Headerimg2 src={solaredge}></Headerimg2>
          </Header1>
        </Grid>
      </Grid>
    </BigSection>
  );
};
export default RecentBlog;
