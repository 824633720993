import { ThumbnailCard } from "../RightStyled";
import CoinsImage from "../../../assets/money-bag.png";

const Coins = () => {
  return (
    <>
      <ThumbnailCard>
        <div className="icon conis">
          <img src={CoinsImage} alt="achivements" />
        </div>
        <div className="data">
          <div className="title">Coins </div>
          <div className="number">0</div>
        </div>
      </ThumbnailCard>
    </>
  );
};

export default Coins;
