import { Avatar, Button, Stack, Link } from "@mui/material";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import {
  getNotifications,
  getNotificationsRead,
} from "../../Redux/Actions/NotificationActions";

const Notifications = ({ toggle, notification }) => {
  const dispatch = useDispatch();
  return (
    <NotifcationStyled className={toggle ? "show" : ""}>
      <h2>notifications</h2>
      {notification &&
        notification?.map((n) => (
          <div key={n?.id}>
            <ButtonStyled
              fullWidth
              className={n?.read_at === null ? "nuread" : "read"}
              onClick={async () => {
                await dispatch(getNotificationsRead(n?.id));
                await dispatch(getNotifications());
              }}
            >
              <a
                href={JSON.parse(n?.data)?.opportunity?.pdf_path}
                target="_blank"
              >
                <Stack direction="row" spacing={2} className="stack">
                  <Avatar
                    alt={JSON.parse(n?.data)?.opportunity?.user?.name}
                    src={JSON.parse(n?.data)?.opportunity?.user?.image_path}
                    sx={{ width: 56, height: 56 }}
                  />

                  <div className="data">
                    <h3>
                      {JSON.parse(n?.data)?.opportunity?.user?.name}
                      <span>
                        Add new solution with ID
                        {JSON.parse(n?.data)?.opportunity?.id}
                      </span>
                    </h3>

                    <p>{n.created_at}</p>
                  </div>
                </Stack>
              </a>
            </ButtonStyled>
          </div>
        ))}
    </NotifcationStyled>
  );
};

const NotifcationStyled = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 5;
  width: 400px;
  height: 600px;
  background: #fff;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 2rem;
  overflow-y: scroll;
  opacity: 0;
  transform: translateY(10px);
  pointer-events: none;
  transition: all 0.3s ease-out;

  overflow-y: scroll;
  scrollbar-color: #1dbce5;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: #1dbce5;
    border-radius: 1rem;
  }
  /* &::-webkit-scrollbar-track {
    background: #646464;
    border-radius: 1rem;
    height: 500px;
  } */
  &.show {
    opacity: 1;
    transform: translateY(0);
    pointer-events: visible;
  }

  h2 {
    color: #8eadb4;
    text-transform: capitalize;
    font-weight: bold;
    /* letter-spacing: 0.1rem; */
    margin: 0;
    padding: 1.8rem 0 2rem 0;
    text-align: center;
    background: #fff;
  }
`;

const ButtonStyled = styled(Button)`
  border-bottom: 1px solid #d4d4d4;

  &.nuread {
    background: #14829e55;
  }
  &.read {
    background: #f9f9f9;
  }
  .stack {
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 1rem;
    .data {
      text-align: left;
      h3 {
        font-weight: normal;
        color: #14829e;
        span {
          color: #333;
          font-size: 1rem;
          text-transform: capitalize;
          margin: 0 0 0 0.3rem;
          font-weight: normal;
        }
      }
      p {
        color: #999;
        font-size: 0.8rem;
      }
    }
  }
`;
export default Notifications;
