"use client";
import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import FirstForm from "./firstForm";
import SecondForm from "./secondForm";
import ThirdForm from "./thirdForm";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import MyStepper from "./steppr";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { BigSection } from "./style";
import {
  adderNames,
  batteriesAdders,
  electricalAdders,
  generatorsAdders,
  hvacsAdders,
  roofingAdders,
} from "./thirdForm/staticData";
import { useCreateSalesAccountMutation } from "../../../../Redux/toolKit/salesForm";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const steps = [
  {
    id: 0,
    main: "Personal data",
    sub: "Please provide your name and email",
  },
  {
    id: 1,
    main: "Business info",
    sub: "A few details about your company",
  },
  {
    id: 2,
    main: "Adders",
    sub: "Put your adders prices (in case you are company)",
  },
];
const SUPPORTED_FORMATS = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/webP ",
  "video/webm",
  "video/mp4",
  "image/svg",
  "video/x-ms-wmv",
  "video/wmv",
  "image/svg+xml",
];

const FormContainer = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skip, setSkip] = useState(0);
  const [createAccount, { isLoading, isError, isSuccess, error }] =
    useCreateSalesAccountMutation();
  let allAdders = [
    ...roofingAdders,
    ...batteriesAdders,
    ...generatorsAdders,
    ...hvacsAdders,
    ...electricalAdders,
  ];
  const validationSchema = [
    yup.object().shape({
      email: yup
        .string("Enter your email")
        .email("Enter a valid email")
        .required("Email is required"),
      referrer: yup.string("Enter your email").email("Enter a valid email"),
      phone: yup
        .string("Enter your phone")
        .required("phone is required")
        .min(10, "Enter valid numbers"),
      password: yup
        .string("Enter your password")
        .required("Password is required")
        .min(8, "Password should be of minimum 8 characters length"),
      password_confirmation: yup
        .string("Enter your password confirm")
        .required("Password Confirmation is required")
        .oneOf([yup.ref("password"), null], "Passwords must match")
        .min(8, " should be of minimum 8 characters length"),
      name: yup
        .string("Enter your  Name")
        .min(3, "Name Should be more than 3 letters")
        .max(25, "Name Should be less than 21 letters")
        .required("Name is required"),
    }),
    yup.object({
      business_type: yup
        .mixed()
        .oneOf(["individual", "company"])
        .required("Business type is required"),
      company_id: yup.string().when(["company_exist", "business_type"], {
        is: (company_exist, business_type) =>
          company_exist === 1 && business_type === "company",
        then: yup.string().required("Company ID is required"),
        otherwise: yup.string(), // You can specify additional validation if needed
      }),
      company_email: yup
        .string("Enter Your company_email")
        .when("company_exist", {
          is: (company_exist) => company_exist === "0",
          then: yup
            .string("company_email")
            .email("inValid Email")
            .required("company email Name is required")
            .min(2, "company email atleast has 2 letters")
            .max(127, "company email max letters is 127 letters"),
        }),
      company_address: yup
        .string("Enter Your company_address")
        .when("company_exist", {
          is: (company_exist) => company_exist === "0",
          then: yup
            .string("company_address")
            .required("company address Name is required")
            .min(2, "company address atleast has 5 letters")
            .max(127, "company address max letters is 150 letters"),
        }),
      business: yup.string("Enter Your Business Name").when("company_exist", {
        is: (company_exist) => company_exist === "0",
        then: yup
          .string("Enter Your Business name")
          .required("Business Name is required")
          .min(2, "Business atleast has 2 letters")
          .max(127, "Business max letters is 127 letters"),
      }),
      bank_name: yup.string("Enter Your bank name").when("company_exist", {
        is: (company_exist) => company_exist === "0",
        then: yup
          .string("Bank name")
          .required("Bank name is required")
          .min(2, "Bank name  atleast has 2 letters")
          .max(127, "Bank name  max letters is 127 letters"),
      }),
      routing: yup.string("Enter Your routing  Name").when("company_exist", {
        is: (company_exist) => company_exist === "0",
        then: yup
          .string("Routing ")
          .required("Routing  is required")
          .min(2, "Routing  atleast has 2 letters")
          .max(127, "Routing  max letters is 127 letters"),
      }),
      w9pdf: yup
        .mixed()
        .test("required", "w9pdf pdf is required", function (value) {
          const { business_type } = this.parent;
          return business_type !== "individual" || (value && value.size > 0);
        })
        .test("fileType", "Invalid file format, only PDF allowed", (value) => {
          if (!value) return true; // No file provided
          return value && value.type === "application/pdf";
        }),
      company_logo: yup
        .mixed()
        .test("required", "Company logo is required", function (value) {
          const { company_exist } = this.parent;
          if (company_exist === "0") {
            return value && value.size > 0;
          }
          return true;
        })
        .test("fileType", "Unsupported file format", (value) => {
          if (!value) return true; // If no file is provided, skip this test
          return SUPPORTED_FORMATS.includes(value.type);
        }),
    }),

    yup.object().shape({
      skip: yup.string().oneOf(["0", "1"]).required("Skip is required"),
      adders: yup.object().shape(
        adderNames.reduce((schema, item) => {
          schema[item.name] = yup.string().when("business_type", {
            is: "company",
            then:
              skip === "0"
                ? yup.string().required(`${item.label} is required`)
                : yup.string(),
            otherwise: yup.string(),
          });
          return schema;
        }, {})
      ),
    }),
  ];
  const handleNext = async () => {
    const isStepValid = await trigger();
    if (isStepValid) setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const currentValidationSchema = validationSchema[activeStep];

  const defaultAdders = adderNames.reduce((acc, { name }) => {
    acc[name] = 0;
    return acc;
  }, {});

  const methods = useForm({
    resolver: yupResolver(currentValidationSchema),
    shouldUnregister: false,
    mode: "all",
    defaultValues: {
      company_exist: 1,
      skip: "0",
      adders: defaultAdders,
    },
  });
  const watchBusinessType = methods.watch("business_type");
  // console.log("asddddddddddddd", watchBusinessType);
  const skipMethod = methods.watch("skip");
  useEffect(() => {
    setSkip(skipMethod);
  }, [skipMethod]);

  const { handleSubmit, reset, trigger, formState } = methods;

  const createAccountForm = async (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key !== "adders") {
        formData.append(key, data[key]);
      }
    });

    try {
      if (watchBusinessType === "company") {
        if (skip != 1) {
          Object.keys(data.adders).forEach((key) => {
            formData.append(`adders[${key}]`, data.adders[key]);
          });
          await createAccount(formData).unwrap();
          await toast.success("Has been created");
        } else {
          Object.keys(data.adders).forEach((key) => {
            formData.append(`adders[${key}]`, data.adders[key]);
          });
          // delete data.adders;
          await createAccount(formData).unwrap();
          await toast.success("Has been created");
        }
      } else {
        // delete data.adders;
        formData.delete("skip");
        await createAccount(formData).unwrap();
        await toast.success("Has been created");
      }
      Swal.fire({
        title: "Registration Successful!",
        text: "Please verify your account by the mail sent to you",
        icon: "success",
        confirmButtonText: "Go to Login",
        showCancelButton: true,
        cancelButtonText: "Stay Here",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/login");
        }
      });
    } catch (errors) {
      toast.error(`Error`);
    }
  };
  const currentForm = () => {
    switch (activeStep) {
      case 0:
        return <FirstForm methods={methods} />;
        break;
      case 1:
        return <SecondForm methods={methods} />;
        break;
      case watchBusinessType === "company" && 2:
        return <ThirdForm methods={methods} error={error} />;
        break;
    }
  };

  const renderErrors = () => {
    if (error.status == 422) {
      return Object?.keys(error?.data?.error || {})?.map((key) => {
        return (error?.data?.error[key] || [])?.map((err, index) => (
          <p
            style={{ color: "red" }}
            key={`${key}-${index}`}
          >{`${key}: ${err}`}</p>
        ));
      });
    }
    if (error.status == 503) {
      return (
        <p style={{ color: "red" }}>
          The mail server could not deliver mail, account or domain may not
          exist, they may be blacklisted, or missing the proper dns entries.
        </p>
      );
    }
    if (error.status == 500) {
      <p style={{ color: "red" }}>Server Error</p>;
    }
    return;
  };
  return (
    <BigSection>
      {error?.data?.error && (
        <>
          <div className="first">
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <h1>Errors</h1>
              {renderErrors()}
            </Grid>
          </div>
        </>
      )}
      <div className="first">
        <h1>Create sales account</h1>
      </div>
      <div className="container">
        <Box sx={{ width: "100%" }}>
          <MyStepper steps={steps} activeStep={activeStep} />
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(createAccountForm)}>
              <>
                <Grid
                  container
                  columns={12}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  {currentForm()}
                </Grid>

                <div className="btns">
                  <button
                    type="button"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    Back
                  </button>
                  {activeStep === 2 && (
                    <button type={activeStep === 2 ? "submit" : "button"}>
                      {isLoading ? "Submiting..." : "Submit"}
                    </button>
                  )}
                  {activeStep !== 2 && (
                    <button
                      onClick={handleNext}
                      // disabled={activeStep === 2}
                      type="button"
                    >
                      Next
                    </button>
                  )}
                </div>
              </>
            </form>
          </FormProvider>
        </Box>
      </div>
    </BigSection>
  );
};

export default FormContainer;
