export const timeClass = (t) => {
  if (t === 0) {
    return "new  time";
  } else if (t < 15) {
    return "green time";
  } else if (t >= 15 && t < 46) {
    return "yellow time";
  } else if (t >= 46 && t < 61) {
    return "red time";
  } else {
    return "  time";
  }
};
