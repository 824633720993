import axios from "axios";
import swal from "sweetalert";
import { GET_ROLS_URL, GET_SINGLE_MEMBER_URL, MAIN_URL } from "../../API/Api";
import { HideLoading, ShowLoading } from "./loadingAction";
import {
  PATCH_USER,
  GET_MAIN_USER,
  GET_SINGLE_MEMBER,
  GET_USER_ROLS,
} from "./type";

export const EditUserAction = (id, data, arr) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    await axios
      .patch(`${MAIN_URL}/api/v1/users/${id}`, data, arr, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      })
      .then((res) => {
        // console.log(res.data);
        dispatch({
          type: PATCH_USER,
          userData: res.data,
        });
      })
      .catch((err) => {
        swal({
          title: " error ",
          text: " There is a problem when update User",
          icon: "error",
          button: "OK",
        });
      });
  };
};

export const GetSingleUser = (id) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    await axios
      .get(`${MAIN_URL}/api/v1/users/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      })
      .then((res) => {
        // console.log(res.data);
        dispatch({
          type: GET_MAIN_USER,
          mainUser: res.data.data,
        });
      });
  };
};

// export const changePasswordSales = (data) => {
//   return async (dispatch) => {

//     const token = localStorage.getItem("token");
//     const headers = {
//       Authorization: `Bearer ${token}`,
//       Accept: "application/json",
//     };
//     await axios({
//       method: "POST",
//       baseURL: `${Opportunities_URL}`,
//       data: data,
//       headers: headers,
//     })
//       .then((res) => {
//         dispatch({
//           type: POST_SOLAR,
//           solarData: res.data,
//         });
//         dispatch(HideLoading());
//         if (res.status === 201) {
//           <Snackbar open={true} autoHideDuration={5000}>
//             <Alert severity="success" sx={{ width: "100%" }}>
//               This is a success message!
//             </Alert>
//           </Snackbar>;
//           dispatch(featchSuccess());
//         }
//       })
//       .catch((err) => {
//         dispatch(HideLoading());
//         dispatch(featchError());
//         console.log(err.data);
//       });
//   };
// };

export const getMemberData = (id) => async (dispatch) => {
  dispatch(ShowLoading());
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  await axios({
    method: "GET",
    baseURL: `${GET_SINGLE_MEMBER_URL}/${id}`,
    headers: headers,
  })
    .then((res) => {
      dispatch({
        type: GET_SINGLE_MEMBER,
        payload: {
          teamMember: res.data.data,
        },
      });

      dispatch(HideLoading());
    })
    .catch((err) => {
      dispatch(HideLoading());
    });
};

export const getRolsForUser = () => async (dispatch) => {
  dispatch(ShowLoading());
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  await axios({
    method: "GET",
    baseURL: `${GET_ROLS_URL}`,
    headers: headers,
  })
    .then((res) => {
      dispatch({
        type: GET_USER_ROLS,
        payload: {
          rols: res.data.data,
        },
      });

      dispatch(HideLoading());
    })
    .catch((err) => {
      dispatch(HideLoading());
    });
};
