import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  BuyInvoice,
  DeleteInvoice,
  GetInvoiceById,
} from "../../Redux/Actions/leadBanksAction";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Header from "../Header/Header";
import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import { PaySection } from "./LeadBankStyle";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DeleteIcon from "@mui/icons-material/Delete";
import LoadingSoultion from "../loading/loadingSolution";
import swal from "sweetalert";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const PayLeads = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const location = useLocation();
  const navigate = useNavigate();
  const items = useSelector((state) => state.LeadBankReducer.singleInvoice);
  const buy_invoice = useSelector(
    (state) => state?.LeadBankReducer?.buyInvoice?.data
  );
  const { isLoading } = useSelector((state) => state.proposal);
  const { id } = useParams();
  const [route, setRoute] = React.useState(false);
  React.useEffect(() => {
    dispatch(GetInvoiceById(id));
    setRoute(false);
  }, [id]);
  let data = {
    InvoiceId: id,
  };
  let message = location?.search?.split("=");
  console.log("message", message[1]);
  const showMessage = message[1]?.split("%20") || [];
  React.useEffect(() => {
    if (route === true) {
      console.log("condtion is true", buy_invoice);
      const newLink = buy_invoice?.paypal_link?.split("//") || [];
      console.log("newLink", newLink);
      navigate(`//${newLink[1]}`);
    }
  }, [buy_invoice, dispatch]);
  React.useEffect(() => {
    if(showMessage.length>0){
    if (showMessage[1] === "Success") {
      swal({
        title: "Good job!",
        text: "The payment was success",
        icon: "success",
        button: "Ok",
      });
    } else {
      swal({
        title: "Oops",
        text: "The payment was unsuccessful",
        icon: "warning",
        button: "Ok",
      });
    }
  }
  }, []);
  const buy = () => {
    dispatch(BuyInvoice(data));
    setRoute(true);
  };
  const delete_s = async () => {
    await dispatch(DeleteInvoice(id));
    await dispatch(GetInvoiceById(id));
    await navigate("/");
    await handleClose();
  };
  return (
    <PaySection>
      <Header />
      <Grid
        container
        spacing={2}
        columns={18}
        style={{ padding: "4rem", textAlign: "center" }}
      >
        <Grid item lg={18} md={18}>
          <h3 style={{ textAlign: "center" }}>The Leads</h3>
        </Grid>
        <Grid item lg={18} md={18}>
          <TableContainer component={Paper}>
            <Table
              //   sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center">First Name </TableCell>
                  <TableCell align="center">Last Name </TableCell>
                  <TableCell align="center">Price </TableCell>
                  <TableCell align="center">Description </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items?.cart_leads_bank?.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" align="center">
                      {row.first_name_lead}
                    </TableCell>
                    <TableCell align="center">{row.last_name_lead}</TableCell>
                    <TableCell align="center">${row.price_one_pice}</TableCell>
                    <TableCell align="center">{row.description}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item lg={9} md={9} sm={18} xs={18}>
          {items.status !== "success" ? (
            <>
              {" "}
              <Button
                startIcon={<ShoppingCartIcon></ShoppingCartIcon>}
                className="buy"
                onClick={buy}
              >
                Buy Now
              </Button>
            </>
          ) : (
            <>You have already purchased it</>
          )}
        </Grid>
        <Grid item lg={9} md={9} sm={18} xs={18}>
        {items.status !== "success" ? (
            <>
              {" "}
              <Button
            onClick={handleOpen}
            startIcon={<DeleteIcon></DeleteIcon>}
            className="delete"
          >
            Delete
          </Button>
            </>
          ) : (
            <>You have already purchased it</>
          )}
      
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            align="center"
          >
            Aru You sure you want to delete This invoice
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            align="center"
          >
            <Button style={{ color: "red" }} onClick={delete_s}>
              Yes
            </Button>
            <Button onClick={handleClose}>No</Button>
          </Typography>
        </Box>
      </Modal>
      {isLoading && <LoadingSoultion />}
    </PaySection>
  );
};

export default PayLeads;
