import { toast } from "react-toastify";
import {
  POST_FORGET_PASSWORD,
  POST_RESET_PASSWORD,
  REGISTER_USER,
} from "../Actions/type";

const initialState = {
  token: localStorage.getItem("token"),
  name: localStorage.getItem("name"),
  user_id: localStorage.getItem("user_id"),
  parent_id: "",
  address: "",
  title: localStorage.getItem("title"),
  email: "",
  phone: "",
  mantras: "",
  userPassword: {},
  resetPassword: {},
  registerUser: {},
  image_path: null,
  authLogIn: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "USER_LOADED":
    case "SIGN_IN":
      // toast("Welcome...", {
      //   position: toast.POSITION.BOTTOM_RIGHT,
      // });
      return {
        ...initialState,
        token: action.token,
        user_id: action.user_id,
        parent_id: action.parent_id,
        email: action.email,
        address: action.address,
        name: action.name,
        phone: action.phone,
        title: action.title,
        mantras: action.mantras,
        image_path: action.image_path,
        authLogIn: true,
      };
    case "SIGN_OUT":
      localStorage.removeItem("token");
      localStorage.removeItem("user_id");
      localStorage.removeItem("name");
      localStorage.removeItem("title");
      // toast("see you soon ", { position: toast.POSITION.BOTTOM_LEFT });
      return {
        token: null,
        user_id: null,
        parent_id: null,
        email: null,
        name: null,
        title: null,
        address: null,
        phone: null,
        image_path: null,
        authLogIn: false,
      };
    case POST_FORGET_PASSWORD:
      return {
        ...state,
        userPassword: action.userPassword,
      };
    case POST_RESET_PASSWORD:
      return {
        ...state,
        resetPassword: action.resetPassword,
      };
    case REGISTER_USER:
      return {
        ...state,
        registerUser: action.payload.resetPassword,
      };
    default:
      return state;
  }
};

export default authReducer;
