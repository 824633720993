import React, { useLayoutEffect } from "react";
import { Bigsection } from "./style";
import { SubmitButton } from "../../../../components/common/submitButton";
import { useCheckOutMutation } from "../../../../Redux/toolKit/coins";
import { useLocation, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const CheckOut = ({ money }) => {
  const [checkOut, { data, isLoading }] = useCheckOutMutation();
  const location = useLocation();
  const message = location.search
    ?.replace("?message=", "")
    .replaceAll("%20", " ");
  console.log("message", message);
  const handleClick = () => {
    const sendData = {
      dollar_amount: money,
    };
    if (money >= 5) {
      checkOut(sendData);
    }
  };
  useLayoutEffect(() => {
    if (data?.data?.paypal_link) {
      window.open(data.data.paypal_link, "_self");
    }
  }, [data?.data]);
  const click = () => {
    if (typeof window !== "undefined") {
      window.history.replaceState(null, "", "/dashboard/subscription");
    }
  };
  useLayoutEffect(() => {
    if (message != "") {
      if (
        message === "Transaction Canceled" ||
        message === "Please Try Again Later..." ||
        message === "Error In Add Coins"
      ) {
        Swal.fire({
          title: `${message}`,
          // showDenyButton: true,
          // showCancelButton: true,
          confirmButtonText: "Ok",
          // denyButtonText: `Don't save`,
        }).then((result) => {
          if (result.isConfirmed) {
            // Swal.fire("Saved!", "", "success");
            click();
          }
          // } else if (result.isDenied) {
          //   Swal.fire("Changes are not saved", "", "info");
          // }
        });
      }
      if (message === "Transaction Success") {
        Swal.fire({
          title: `Thanks for Payment`,
          // showDenyButton: true,
          // showCancelButton: true,
          confirmButtonText: "Ok",
          icon: "success",

          // denyButtonText: `Don't save`,
        }).then((result) => {
          if (result.isConfirmed) {
            // Swal.fire("Saved!", "", "success");
            click();
          }
          // } else if (result.isDenied) {
          //   Swal.fire("Changes are not saved", "", "info");
          // }
        });
      }
    }
  }, [message]);
  console.log("datadatadatadatadatadata", data?.data?.paypal_link);
  return (
    <Bigsection>
      <h1>The url redirect to localHost only</h1>
      <button onClick={handleClick} disabled={isLoading ? true : false}>
        {isLoading ? "Loading..." : "check out"}
      </button>
    </Bigsection>
  );
};

export default CheckOut;
