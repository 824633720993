import { Alert, Button, Container, Stack, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import logo from "../../assets/lgBox.svg";
import { useFormik } from "formik";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { resetUserPasAction } from "../../Redux/Actions/authActions";
const validationSchema = yup.object({
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  password_confirmation: yup
    .string("Confirm Your Password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

/* ------ STRAT RESET PASSWORD ACTION ------ */
const ResetPassword = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [data, setData] = useState({
    email: "",
    token: "",
  });
  useEffect(() => {
    const urlParams = Object.fromEntries([...new URLSearchParams(search)]);
    setData({ ...data, email: urlParams.mail, token: urlParams.token });
  }, [search]);

  /*---- start formik form ---- */
  const formik = useFormik({
    initialValues: {
      password: "",
      password_confirmation: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (values.password === values.password_confirmation) {
        setError(false);
        dispatch(resetUserPasAction({ ...data, ...values }));
      } else {
        setError(true);
      }
    },
  });
  /*---- start return ---- */
  return (
    <ForgotPasswordStyled>
      <Container>
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="data">
          <h2>Reset Password </h2>
          <form onSubmit={formik.handleSubmit}>
            <FormContant>
              <TextField
                fullWidth
                id="password"
                name="password"
                label="Enter Your New Password"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </FormContant>
            <FormContant>
              <TextField
                fullWidth
                id="password_confirmation"
                name="password_confirmation"
                label="Confirm Your Password"
                type="password"
                value={formik.values.password_confirmation}
                onChange={formik.handleChange}
                error={
                  formik.touched.password_confirmation &&
                  Boolean(formik.errors.password_confirmation)
                }
                helperText={
                  formik.touched.password_confirmation &&
                  formik.errors.password_confirmation
                }
              />
            </FormContant>
            {error && (
              <Stack spacing={2} sx={{ width: "100%" }}>
                <Alert severity="error">
                  Confirm Password Does't Match the Password!
                </Alert>
              </Stack>
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              className="btn_submit"
            >
              Submit
            </Button>
          </form>
          <Link to="/login" className="login">
            Login
          </Link>
        </div>
      </Container>
    </ForgotPasswordStyled>
  );
};
const ForgotPasswordStyled = styled.div`
  .logo {
    background: #000;
    display: block;
    padding: 1rem 3rem;
    border-radius: 2rem;
    width: 300px;
    margin: 1rem auto;
    text-align: center;
  }
  .data {
    width: 60%;
    margin: 3rem auto;
    @media (max-width: 768px) {
      width: 90%;
    }
    h2 {
      margin: 1rem 0;
      color: #6eb5ca;
    }
    .btn_submit {
      margin: 2rem 0;
    }
    .login {
      color: #6eb5ca;
    }
  }
`;
const FormContant = styled.div`
  margin: 1rem 0;
`;
export default ResetPassword;
