import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { url } from "../../API/Api";
// import { baseQuery } from "./helper/index.js";
export const addersApi = createApi({
  reducerPath: "newAdders",
  tagTypes: ["newAdders", "installers"],
  baseQuery: fetchBaseQuery({
    baseUrl: "https://installerv2.boxbyld.tech/api",
  }),
  endpoints: (builder) => ({
    getAdders: builder.query({
      query: ({ return_all, grouped, state_id, user_id }) => ({
        url: `categories/adders`,
        method: "get",
        params: {
          return_all,
          grouped,
          state_id,
          user_id,
        },
      }),
      providesTags: ["newAdders"],
    }),

    getInstallers: builder.query({
      query: ({ return_all, state_id }) => ({
        url: `/installers/licenses/${state_id}/get-by-state`,
        method: "get",
        params: {
          return_all,
        },
      }),
      providesTags: ["installers"],
    }),

    getInstallersAndPrices: builder.query({
      query: (stateId) => ({
        url: `/installers`,
        method: "get",
        params: {
          state_id: stateId,
        },
      }),
      providesTags: ["installers"],
    }),
  }),
});

export const {
  useGetAddersQuery,
  useGetInstallersAndPricesQuery,
  useLazyGetAddersQuery,
  useGetInstallersQuery,
} = addersApi;
