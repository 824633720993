//--------------------------

export const MAIN_URL = `https://crm.boxbyld.tech`;
// export const MAIN_URL = `https://alpha.boxbyld.tech`;

//---------------------------

export const url = `${MAIN_URL}/api/v1/users`;

export const ZoomUrl = `${MAIN_URL}/api/v1/meetings`;

export const LEAD_URL = `${MAIN_URL}/api/v1/leads`;

export const COUNTRIES_URL = `${MAIN_URL}/api/v1/countries`;

export const PostLeadUrl = `${MAIN_URL}/api/v1/leads`;

export const ServicesURL = `${MAIN_URL}/api/v1/services`;

export const UTILITY_PROVIDER_URL = `${MAIN_URL}/api/v1/states/providers`;

export const Opportunities_URL = `${MAIN_URL}/api/v1/opportunities`;

export const SingleOpportunitiesURL = `${MAIN_URL}/api/v1/opportunities/solution`;

export const APR_URL = `${MAIN_URL}/api/v1/sunlight/rates`;

export const SUNLIGHT_CLAC_URL = `${MAIN_URL}/api/v1/sunlight/rates`;

export const CREATE_PROJECT_URL = `${MAIN_URL}/api/v1/sunlight/projects/again/create`;

export const PREQUAL_URL = `${MAIN_URL}/api/v1/sunlight/prequal/again/email`;

export const GET_PROJECJS_URL = `${MAIN_URL}/api/v1/sunlight/get-submitted/projects`;

export const POST_PULL_STATUS_URL = `${MAIN_URL}/api/v1/sunlight/pull-status`;

export const POST_SUBMIT_CREDIT_URL = `${MAIN_URL}/api/v1/sunlight/submit-credit`;

export const POST_ADD_QUOTE_URL = `${MAIN_URL}/api/v1/sunlight/add-quote-to-project`;

export const CREATE_CONTRACT_URL = `${MAIN_URL}/api/v1/contracts`;

export const SEND_CONTREACT_EMAIL_URL = `${MAIN_URL}/api/v1/contracts/signing`;

export const SEND_NOTIFICATION_EMAIL_URL = `${MAIN_URL}/api/v1/opportunities/send-notification`;

export const GET_PANEL_MODEL_URL = `${MAIN_URL}/api/v1/panels`;

export const EDIT_LEAD_PROFILE_URL = `${MAIN_URL}/api/v1/leads`;

export const FORGOT_USER_PASSWORD_URL = `${MAIN_URL}/api/v1/password/send-email`;

export const RESET_USER_PASSWORD_URL = `${MAIN_URL}/api/v1/password/reset-password`;

export const GET_STATE_ID_FROM_MAP = `${MAIN_URL}/api/v1/states/states/state`;

export const ASIGN_LEAD_TO_USER_URL = `${MAIN_URL}/api/v1/leads/lead/delegate`;

export const GET_SINGLE_MEMBER_URL = `${MAIN_URL}/api/v1/leads/user`;

export const GET_NOTIFICATIONS_URL = `${MAIN_URL}/api/v1/notifications`;

export const GET_ROOF_TYPES_URL = `${MAIN_URL}/api/v1/roofing-proposals/form-data`;

export const GET_ROLS_URL = `${MAIN_URL}/api/v1/roles`;

export const POST_REGISTER_URL = `${MAIN_URL}/api/v1/users/register`;

export const GET_LEAD_FILTER = `${MAIN_URL}/api/v1/leads`;

// export const POST_INSTALLER=`${MAIN_URL}/api/v1/installer/register`

// export const GET_INSTALLER_STATES=`${MAIN_URL}/api/v1/states`

// export const GET_INSTALLER_LICENSE_TYPE=`${MAIN_URL}/api/v1/license-types`

export const POST_INSTALLER = `https://alpha.boxbyld.tech/api/v1/installer/register`;

export const GET_INSTALLER_STATES = `${MAIN_URL}/api/v1/states`;

export const GET_INSTALLER_LICENSE_TYPE = `https://alpha.boxbyld.tech/api/v1/license-types`;

export const Update_Finantial_Info = `${MAIN_URL}/api/v1/financial-profile`;

export const Add_Roofing_Solution = `${MAIN_URL}/api/v1/roofing-stand-alone`;

export const GET_SINGLE_ROOFING = `${MAIN_URL}/api/v1/roofing-stand-alone`;

export const Create_roofing_contract = `${MAIN_URL}/api/v1/roof-contract`;

export const Send_roofing_contract = `${MAIN_URL}/api/v1/roofing-stand-alone/send-notification`;

export const Delete_roofing_contract = `${MAIN_URL}/api/v1/roofing-stand-alone`;

export const Roofing_send_contract_to_sign = `${MAIN_URL}/api/v1/roof-contract/signing`;

export const Get_Single_Contract = `${MAIN_URL}/api/v1/contracts`;

export const Create_Ticket = `${MAIN_URL}/api/v1/leads/tickets/create`;

export const Add_Sales = `${MAIN_URL}/api/v1/sales-rep/register`;
export const Get_Sales_companies = `${MAIN_URL}/api/v1/sales-rep/businesses`;

export const GOOGLE_API_URL = `${MAIN_URL}/api/v1/event`;

export const GET_LEADS_BANK = `${MAIN_URL}/api/v1/lead_bank/get_leadbank_by_state`;

export const CheckOut = `${MAIN_URL}/api/v1/lead_bank/cart_leads_bank/store`;

export const GetAllInvoices = `${MAIN_URL}/api/v1/lead_bank/invoice`;

export const GetInvoice = `${MAIN_URL}/api/v1/lead_bank/invoice/show`;

export const Delete_invoice = `${MAIN_URL}/api/v1/lead_bank/invoice/delete`;

export const Buy_invoice = `${MAIN_URL}/api/v1/lead_bank/paypal/payment`;

export const MESSAGE_URL = `https://msg.boxbyld.tech/api/chat/get_last_chat`;

export const GET_ALL_MESSAGES_URL = `https://msg.boxbyld.tech/api/chat/get_users_with_last_chat`;

export const GET_ALL_DEPARTMENT_URL = `https://msg.boxbyld.tech/api/role`;

export const GET_ALL_DEPARTMENT_USER_URL = `https://msg.boxbyld.tech/api/role/get_users_by_role`;

export const GET_SINGLE_MESSAGES_URL = `https://msg.boxbyld.tech/api/chat/message`;

export const CHANGE_STATUS_CHAT_URL =
  "https://msg.boxbyld.tech/api/chat/message/change_status";

// export const Finance_goodleap = `${MAIN_URL}/api/v1/goodleap/request/solar`;
export const Finance_goodleap = `${MAIN_URL}/api/v1/goodleap/loans/solar`;

export const Finance_sunight = "";

export const GET_ADDERSBY_INSTALLER =
  "https://tech.boxbyld.tech/api/admin/adders/installer-adders";

export const get_mosaic_offers = `${MAIN_URL}/api/v1/mosaic/get-finance-products`;
export const get_mosaic_payments = `${MAIN_URL}/api/v1/mosaic/get-payment-estimate`;
export const mosiac_create_application = `${MAIN_URL}/api/v1/mosaic/create-mosaic-application`;
export const mosiac_share_application = `${MAIN_URL}/api/v1/mosaic/share-mosaic-application`;
export const mosiac_get_timeLine = `${MAIN_URL}/api/v1/mosaic/get-mosaic-timeline`;
export const mosiac_get_status = `${MAIN_URL}/api/v1/mosaic/get-mosaic-application-status`;
export const mosiac_check_offer = `${MAIN_URL}/api/v1/mosaic/check-mosaic-offer`;
export const mosiac_submit_offer_notFoud = `${MAIN_URL}/api/v1/mosaic/offer/submit-offer-not-found`;
export const mosiac_get_offer_notFoud = `${MAIN_URL}/api/v1/mosaic/get-finance-product-decision`;
export const mosiac_get_offer_found = `${MAIN_URL}/api/v1/mosaic/get-my-finance-product-decision`;
export const mosiac_submit_offer_found = `${MAIN_URL}/api/v1/mosaic/offer/submit-offer-found`;
export const mosaic_offer_status = `${MAIN_URL}/api/v1/mosaic/offer/get-offer-status`;
export const mosaic_downlaod_contract_document = `${MAIN_URL}/api/v1/mosaic/contract/document`;

export const create_concert_offer = `${MAIN_URL}/api/v1/concert/get-loan-type-options`;
export const get_concert_products = `${MAIN_URL}/api/v1/concert/get-product-methods`;
export const concert_get_payment = `${MAIN_URL}/api/v1/concert/calculation-method`;
export const concert_create_project = `${MAIN_URL}/api/v1/concert/pre-qualify-customer`;
export const concert_submit_offer = `${MAIN_URL}/api/v1/concert/submit-offer`;
export const get_eligibilities_status = `${MAIN_URL}/api/v1/concert/get-eligibilities-status`;

export const get_all_installers = `https://installerv2.boxbyld.tech/api/installers`;
export const get_single_installer_data = `https://installerv2.boxbyld.tech/api/installers`;

export const achievement_api = `${MAIN_URL}/api/v1/achievements`;
export const get_parent_achievement_api = `${MAIN_URL}/api/v1/achievements/all`;
export const get_sales_achievement_process_api = `${MAIN_URL}/api/v1/achievements/all/sales-rep`;
export const edit_achievement_process_api = `${MAIN_URL}/api/v1/achievements/achievement`;
export const get_achievement_process_api = `${MAIN_URL}/api/v1/achievements`;

export const license = `${MAIN_URL}/api/v1/sales-rep/licenses`;

export const enfinCalc = `${MAIN_URL}/api/v1/enfin/calculator`;
export const enfinRates = `${MAIN_URL}/api/v1/enfin/rates`;
