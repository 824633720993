import styled from "styled-components";
import React from "react";
import whiteShirt from "../../../../assets/whiteShirt.png";
import Grid from "@mui/material/Grid";
const BigSection = styled.div`
  background-color: #d7d6db;
  padding-top: 50px;
`;
const Image = styled.img`
  width: 100%;
`;
const Name = styled.p`
  font-size: 25px;
`;
const Description = styled.p`
  color: #4e4e4e;
`;
const OneDIv = styled.div`
  max-width: 95%;
`;

const WhiteShirt = () => {
  return (
    <BigSection>
      <Grid
        container
        spacing={0}
        col={12}
        direction="row"
        justifyContent="center"
        alignItems="stretch"
      >
        <Grid item lg={6} md={5} sm={6} xs={8}>
          <Image src={whiteShirt}></Image>
        </Grid>
        <Grid item lg={6} md={5} sm={6} xs={8} sx={{ my: "auto" }}>
          <OneDIv>
            <Name>People: Onboarding</Name>
            <Description>
              Join the BoxBYLD team with confidence - you've got the solar panel and home improvement experts here to support you, train you and drive your success. You have full access to many of our finance partners, proposal and building tools, a full line of training curriculum, and access to the entire US market. Our experienced and well versed management will guide you every step of the way to help you start and enhance your opportunity.
            </Description>
          </OneDIv>
          <OneDIv>
            <Name>Process: Technology</Name>
            <Description>
              Full access to BoxBYLD for FREE when you are an approved Partner. No more using multiple access points to create your solutions and enhance your opportunities. This is your one stop shop to everything home Improvement and solar. Access all the amazing features to drive your business model and grow your company.
            </Description>
          </OneDIv>
          <OneDIv>
            <Name>Passion: Dedicated Team</Name>
            <Description>
              BoxBYLD is partnered with All Premium Contractors. Offering Lifestyle coaches, business advisors, mentors, effective marketing and sales strategists and trainers with instant support access. In order to enhance your capabilities of becoming a more effective, efficient, productive and proactive entrepreneur. While our focus is making your customers feel secure with a 25 year workmanship warranty. Standing behind our work and supporting them every step of the way.
            </Description>
          </OneDIv>
        </Grid>
      </Grid>
    </BigSection>
  );
};
export default WhiteShirt;
