import { Grid, TextField, Typography } from "@mui/material";
import React from "react";
import Rating from "@mui/material/Rating";
import styled from "styled-components";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextareaAutosize from "@mui/base/TextareaAutosize";

const SendToLeadBank = () => {
  const [data, setData] = React.useState({
    rate: 0,
    communicateTime: new Date().toISOString().slice(0, 10),
    appointment_time: new Date(),
  });
  console.log("data", data);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };
  let product_types = [
    { name: "solar ", value: "solar", id: 1 },
    { name: "roof", value: "roof", id: 2 },
    { name: "hvac", value: "hvac", id: 3 },
  ];
  let lead_types = [
    { name: "appointment", value: "appointment", id: 1 },
    { name: "purchasing", value: "purchasing", id: 2 },
    { name: "shared lead", value: "shared_lead", id: 3 },
  ];
  let transaction_types = [
    { name: "immediate", value: "immediate", id: 1 },
    { name: "commission based", value: "commission_based", id: 2 },
  ];
  let percentage_types = [
    { name: "flatrate", value: "flat_rate", id: 1 },
    { name: "split earning", value: "split_earning", id: 2 },
    { name: "percentage of sale", value: "percentage_of_sale", id: 3 },
  ];
  return (
    <Bigsection>
      <form>
        <h3>Send this lead to leads bank</h3>
        {/* <hr /> */}
        <br />
        <Grid container columns={18} spacing={2}>
          <Grid item xs={18} md={6}>
            <Typography component="legend">
              Rate this lead from 1 to 5 where 5 is the best
            </Typography>
            <Rating
              name="simple-controlled"
              value={data.rate}
              onChange={(event, newValue) => {
                setData({ ...data, rate: newValue });
              }}
            />
          </Grid>
          <Grid item xs={18} md={6}>
            <TextField
              label="communicateTime"
              value={data.communicateTime}
              type="date"
              name="communicateTime"
              onChange={handleChange}
              fullWidth
            ></TextField>
          </Grid>
          <Grid item xs={18} md={6}>
            <TextField
              label="lead source"
              value={data.lead_source}
              name="lead_source"
              onChange={handleChange}
              fullWidth
            ></TextField>
          </Grid>
          <Grid item xs={18} md={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Product type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={data.product_type}
                label="product type"
                name="product_type"
                onChange={handleChange}
                fullWidth
              >
                {product_types?.map((e) => {
                  return <MenuItem value={e.value}>{e.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={18} md={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Lead type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={data.lead_type}
                label="Lead type"
                name="lead_type"
                onChange={handleChange}
                fullWidth
              >
                {lead_types?.map((e) => {
                  return <MenuItem value={e.value}>{e.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>
          {data.lead_type === "appointment" ? (
            <>
              <Grid item xs={18} md={6}>
                <TextField
                  label="appointment_time"
                  type="datetime-local"
                  value={data.appointment_time}
                  name="appointment_time"
                  onChange={handleChange}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                ></TextField>
              </Grid>
            </>
          ) : (
            <></>
          )}
          {data.lead_type === "purchasing" ? (
            <>
              <Grid item xs={18} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    transaction type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={data.transaction_type}
                    label="transaction type"
                    name="transaction_type"
                    onChange={handleChange}
                    fullWidth
                  >
                    {transaction_types?.map((e) => {
                      return <MenuItem value={e.value}>{e.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </>
          ) : (
            <></>
          )}
          {data.transaction_type === "immediate" ? (
            <>
              <Grid item xs={18} md={6}>
                <TextField
                  label="lead value"
                  value={data.lead_value}
                  name="lead_value"
                  onChange={handleChange}
                  fullWidth
                ></TextField>
              </Grid>
            </>
          ) : (
            <></>
          )}
          {data.transaction_type === "commission_based" ? (
            <>
              <Grid item xs={18} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    percentage
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={data.percentage}
                    label="Drop down"
                    name="percentage"
                    onChange={handleChange}
                    fullWidth
                  >
                    {percentage_types?.map((e) => {
                      return <MenuItem value={e.value}>{e.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </>
          ) : (
            <></>
          )}

          <Grid item xs={18}>
            <TextField
              label="notes"
              value={data.lead_source}
              name="notes"
              onChange={handleChange}
              fullWidth
              minRows={3}
            ></TextField>
          </Grid>
        </Grid>
      </form>
    </Bigsection>
  );
};
const Bigsection = styled.div`
  padding: 1rem;
`;
export default SendToLeadBank;
