import { Alert, Snackbar } from "@mui/material";
import axios from "axios";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { Opportunities_URL } from "../../API/Api";
import { SignOut } from "./authActions";
import {
  featchError,
  featchSuccess,
  HideLoading,
  ShowLoading,
} from "./loadingAction";
import { POST_SOLAR } from "./type";

export const addSolarAction = (data) => {
  return async (dispatch) => {
    dispatch(ShowLoading());
    const token = localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    await axios({
      method: "POST",
      baseURL: `${Opportunities_URL}`,
      data: data,
      headers: headers,
    })
      .then((res) => {
        dispatch({
          type: POST_SOLAR,
          solarData: res.data,
        });
        dispatch(HideLoading());
        if (res.status === 201) {
          <Snackbar open={true} autoHideDuration={5000}>
            <Alert severity="success" sx={{ width: "100%" }}>
              This is a success message!
            </Alert>
          </Snackbar>;
          dispatch(featchSuccess());
        }
      })
      .catch((err) => {
        dispatch(HideLoading());
        dispatch(featchError());
        // console.log("Error Action Action Action",err);
        const errors = err?.response?.data?.errors || err?.response?.data?.error;
        console.log("err.response.data", err);

        for (const property in errors) {
          console.log(`${property}: ${errors[property]}`);
        }
        if (errors?.sunlight?.length > 0) {
          for (const property in errors) {
            console.log("Error", errors[property]);
            errors[property] ||
              []?.map((e) => {
                for (const property_e in e) {
                  console.log(`${property_e}: ${e[property_e]}`);
                  Swal.fire({
                    title: " Error",
                    text: ` ${property_e}: ${e[property_e]}`,
                    icon: "error",
                    button: "OK",
                  });
                }
              });
          }
        } else {
          swal({
            title: " Error",
            text: `${errors}`,
            icon: "error",
            button: "OK",
          });
        }
        if (err?.response?.status === 401) {
          dispatch(SignOut());
        }
        // errors?.sunlight?.map((e) => {
        //   if (e.length > 0) {
        //     console.log("eeeeeeeeeeeeeeeeeeeeeeee", e);
        //     e.map((s) => {
        //       console.log("ssssssssssssssssssssssss", s);
        //       for (const property in s) {
        //         console.log(`${property}: ${s[property]}`);
        //         swal({
        //           title: " Error",
        //           text: `${property}: ${s[property]}`,
        //           icon: "error",
        //           button: "OK",
        //         });
        //       }
        //     });
        //   } else {
        //     swal({
        //       title: " Error",
        //       text: `${e.toString()}`,
        //       icon: "error",
        //       button: "OK",
        //     });
        //   }
        // });
        // console.log("err.response.status",err.response.status);
        // console.log("err.response.headers",err.response.headers);
      });
  };
};
