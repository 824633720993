import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./helper/index.js";
export const leadsApi = createApi({
  reducerPath: "leadsApi",
  baseQuery,
  tagTypes: ["leads", "singleLead"],
  endpoints: (builder) => ({
    getLeads: builder.query({
      query: ({ page, return_all }) => ({
        url: "/leads",
        method: "get",
        params: {
          page: page,
          return_all,
        },
      }),
    }),

    getSingleLead: builder.query({
      query: (id) => ({
        url: `/leads/${id}`,
        method: "get",
      }),
      providesTags: ["singleLead"],
    }),

    createLead: builder.mutation({
      query: (body) => ({
        url: "/api/v1/leads",
        method: "POST",
        body,
      }),
      invalidatesTags: ["leads"],
    }),
  }),
});

export const {
  useGetLeadsQuery,
  useGetSingleLeadQuery,
  useLazyGetSingleLeadQuery,
} = leadsApi;
