import { Badge, FormControlLabel, Switch } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { styled } from '@mui/material/styles';
import { FilterLeadStyled } from "./LeadFilterStyle";
import {KeyWorkToggle} from "../../../../../Redux/Actions/leadActions"


const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
const LeadFilter = () => {
  const { leadlist, archiveLeads } = useSelector((state) => state.leads);
  const ListLength = leadlist?.length;
  const ArchiveLength = archiveLeads?.length;
  const dispatch=useDispatch();
  const [toggle,setToggle]=React.useState()

  // console.log("toggle in leadFilter",toggle)

  React.useEffect(()=>{
    dispatch(KeyWorkToggle(toggle))
  },[toggle])
  return (
    <FilterLeadStyled>
      <div className="filter">
        <nav className="filter__left">
          <ul>
            <li>
              <NavLink to="/crm/leads">
                Active Leads
                <Badge
                  badgeContent={ListLength}
                  color="primary"
                  className="badge"
                />
              </NavLink>
            </li>
            <li>
              <NavLink to="/crm/archive">
                Archived Leads
                <Badge
                  badgeContent={ArchiveLength}
                  color="primary"
                  className="badge"
                />
              </NavLink>
            </li>
          </ul>
          {/* <div>
            <img src="image/filter.svg" alt="" />
            <span>filter</span>
            <i className="fas fa-caret-down"></i>
          </div> */}
        </nav>
        {/* <div className="filter__center">
          <label className="switch">
            <input  checked={toggle}  onChange={(e)=>setToggle(e.target.checked)} type="checkbox" />
            <span className="slider">
              <span className="text">
                <span className="yes"> salse</span>
                <span className="no"> ops</span>
              </span>
            </span>
          </label>
        </div> */}
          <span style={{fontSize:"17px", color:"#707070"}}>Pending Deals</span>
          <FormControlLabel
        control={<IOSSwitch sx={{ m: 1 }}  checked={toggle}  onChange={(e)=>setToggle(e.target.checked)} />}
        label=""
      />
        <div className="filter__right" id="filterGrid">
          <i className="fas fa-list-ul" id="iconGrid"></i>
        </div>
      </div>
    </FilterLeadStyled>
  );
};

export default LeadFilter;
