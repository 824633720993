import axios from "axios";
import { SingleOpportunitiesURL } from "../../API/Api";
import { GET_OPPORTUNITY } from "./type";
import {Get_Single_Contract} from "../../API/Api"
import { ShowLoading, HideLoading } from "./loadingAction";
import swal from "sweetalert";

export const GET_CONTRACT="GET_CONTRACT"

export const singleOpportunitiesAction = (id) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    await axios
      .get(`${SingleOpportunitiesURL}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      })
      .then((res) => {
        dispatch({
          type: GET_OPPORTUNITY,
          singleOpportunity: res.data,
        });
      })
      .catch((err) => {
        console.log(err.data);
      });
  };
};

export const SingleContractData = (data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch(ShowLoading());
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  await axios({
    method: "GET",
    baseURL: `${Get_Single_Contract}/${data}`,
    headers: headers,
  })
    .then((res) => {
      dispatch(HideLoading());
      dispatch({
        type: GET_CONTRACT,
        contractData: res.data.data,
      });
    })
    .catch((err) => {
      dispatch(HideLoading());
      console.log(err.data);
      console.log("try", err.response.data.message);
      swal({
        title: `Error ${err.response.data.message}`,
        message: `${err}`,
        icon: "warning",
        button: "OK",
      });
    });
};
