import axios from "axios";
import React from "react";
import swal from "sweetalert";
import {
  Get_Sales_companies,
  POST_INSTALLER,
  get_all_installers,
  get_single_installer_data,
} from "../../API/Api";
import { GET_INSTALLER_STATES } from "../../API/Api";
import { GET_INSTALLER_LICENSE_TYPE } from "../../API/Api";
import { Add_Sales } from "../../API/Api";
import { ShowLoading, HideLoading } from "./loadingAction";

export const NEW_INSTALLER = "NEW_INSTALLER";

export const START_LOADING = "START_LOADING";

export const END_LOADING = "END_LOADING";

export const ALL_INSTALLER_STATES = "ALL_INSTALLER_STATES";

export const ALL_INSTALLER_LICENSE_TYPES = "ALL_INSTALLER_LICENSE_TYPES";

export const ADD_SALES_AGENT = "ADD_SALES_AGENT";

export const GET_SALES_COMPANIES = "GET_SALES_COMPANIES";

export const GET_ALL_INSTALLERS_DATA = "GET_ALL_INSTALLERS_DATA";
export const GET_SINGLE_INSTALLER_DATA = "GET_SINGLE_INSTALLER_DATA";

export const AddInstaller = (data) => async (dispatch) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
  };
  dispatch({
    type: START_LOADING,
  });
  await axios({
    method: "POST",
    baseURL: `${POST_INSTALLER}`,
    headers: headers,
    data: data,
    // paramsSerializer: function (params) {
    //   // return qs.stringify(params, { arrayFormat: 'comma' })
    //   return qs.stringify(params,{ arrayFormat: 'indices' })
    // },
  })
    .then((res) => {
      dispatch({
        type: END_LOADING,
      });
      dispatch({
        type: NEW_INSTALLER,
        payload: res.data,
      });
      // if(res.status=200){
      swal({
        title: "register Installer succsfully ",
        icon: "success",
        button: "ok",
      });
      // }
      console.log("res", res);
    })
    .catch((err) => {
      dispatch({
        type: END_LOADING,
      });
      console.log("err", err);
      swal({
        title: "Error",
        text: `${err}`,
        icon: "warning",
        button: true,
        dangerMode: true,
      });
    });
};

export const GetInstallerStates = (data) => async (dispatch) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
  };
  await axios({
    method: "GET",
    baseURL: `${GET_INSTALLER_STATES}`,
    headers: headers,
    data: data,

    // paramsSerializer: function (params) {
    //   // return qs.stringify(params, { arrayFormat: 'comma' })
    //   return qs.stringify(params,{ arrayFormat: 'indices' })
    // },
  })
    .then((res) => {
      dispatch({
        type: ALL_INSTALLER_STATES,
        payload: res.data.data.data,
      });
    })
    .catch((err) => {
      console.log("err", err);
      swal({
        title: "Error",
        text: `${err}`,
        icon: "warning",
        button: true,
        dangerMode: true,
      });
    });
};

export const GetInstallerLecenseType = (data) => async (dispatch) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
  };
  await axios({
    method: "GET",
    baseURL: `${GET_INSTALLER_LICENSE_TYPE}`,
    headers: headers,
    data: data,

    // paramsSerializer: function (params) {
    //   // return qs.stringify(params, { arrayFormat: 'comma' })
    //   return qs.stringify(params,{ arrayFormat: 'indices' })
    // },
  })
    .then((res) => {
      dispatch({
        type: ALL_INSTALLER_LICENSE_TYPES,
        payload: res.data.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: END_LOADING,
      });
      console.log("err", err);
      swal({
        title: "Error",
        text: `${err}`,
        icon: "warning",
        button: true,
        dangerMode: true,
      });
    });
};

export const AddSalesAgent = (data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch(ShowLoading());
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  await axios({
    method: "POST",
    baseURL: `${Add_Sales}`,
    headers: headers,
    data: data,
  })
    .then((res) => {
      swal({
        title: "Success",
        text: `Sales Agent has been Added`,
        icon: "success",
        button: true,
      });
      dispatch(HideLoading());
    })
    .catch((err) => {
      dispatch({
        type: ADD_SALES_AGENT,
        payload: err,
      });
      dispatch(HideLoading());
      console.log("What is Error", err.response);
    });
};

export const GetSalesCompanies = (data) => async (dispatch) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
  };
  await axios({
    method: "GET",
    baseURL: `${Get_Sales_companies}`,
    headers: headers,
    data: data,
  })
    .then((res) => {
      dispatch({
        type: GET_SALES_COMPANIES,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: END_LOADING,
      });
      console.log("err", err);
      swal({
        title: "Error",
        text: `${err}`,
        icon: "warning",
        button: true,
        dangerMode: true,
      });
    });
};

export const GetAllInstallers = (data) => async (dispatch) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
  };
  await axios({
    method: "GET",
    baseURL: `${get_all_installers}`,
    headers: headers,
    data: data,
  })
    .then((res) => {
      dispatch({
        type: GET_ALL_INSTALLERS_DATA,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: END_LOADING,
      });
      console.log("err", err);
      swal({
        title: "Error",
        text: `${err}`,
        icon: "warning",
        button: true,
        dangerMode: true,
      });
    });
};

export const GetSingleInstallerData = (id) => async (dispatch) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
  };
  await axios({
    method: "GET",
    baseURL: `${get_single_installer_data}/${id}/adders`,
    headers: headers,
  })
    .then((res) => {
      dispatch({
        type: GET_SINGLE_INSTALLER_DATA,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: END_LOADING,
      });
      console.log("err", err);
      swal({
        title: "Error",
        text: `${err}`,
        icon: "warning",
        button: true,
        dangerMode: true,
      });
    });
};
