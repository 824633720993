import { Person } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { asignLeadToUserAction } from "../../../Redux/Actions/leadActions";

const TeamCardsUser = ({ Team, LeadID }) => {
  const dispatch = useDispatch();

  return (
    <TeamCardStyled>
      <div className="gridItem">
        <div className="icon">
          <div className="icon__content">
            {Team.image_path !== "" ? (
              <img src={Team.image_path} alt="name" />
            ) : (
              <Person />
            )}
          </div>
        </div>
        <div className="data">
          <span className="heading">{Team.name}</span>
          <span className="number">${Team.ppw} </span>
          <span className="penfits">{Team.business}</span>
        </div>
        <Button
          className="asign"
          onClick={() =>
            dispatch(asignLeadToUserAction(LeadID, { user_id: Team.id }))
          }
        >
          Assign
        </Button>
      </div>
    </TeamCardStyled>
  );
};

const TeamCardStyled = styled.div`
  .gridItem {
    background: #ffffff;
    box-shadow: 0 2px 8px #0000002d;
    padding: 1rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    .icon {
      &__content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90px;
        height: 90px;
        border-radius: 50%;
        overflow: hidden;
        background: linear-gradient(265deg, #0b0098 0%, #4c107f 57%);
        img {
          width: 80px;
          height: 80px;
          border-radius: 50%;
        }
        svg {
          width: 100px;
          color: white;
          font-size: 4.5rem;
        }
      }
    }
    .data {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #888;
      span {
        display: inline-block;
      }
      .number {
        font-size: 2rem;
        color: #222;
        font-weight: bold;
      }
    }
    .asign {
      background: linear-gradient(265deg, #0b0098 0%, #4c107f 57%);
      color: #fff;
      padding: 0.2rem 1.2rem;
      margin: 0.3rem 0;
      border-radius: 0.5rem;
    }
  }
`;
export default TeamCardsUser;
