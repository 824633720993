import styled from "styled-components";

export const Bigsection = styled.div`
  margin: 3.5rem 0;
  #customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    max-width: 100%;
  }

  #customers td,
  #customers th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  #customers tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  #customers tr:hover {
    background-color: #ddd;
  }

  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #0dbf7c;
    color: white;
    text-transform: capitalize;
  }
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 1rem 0;
    nav {
      ul {
        li {
          .Mui-selected {
            background-color: #0dbf7c !important;
          }
        }
      }
    }
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
  }
  h3 {
    font-size: 1rem;
    font-weight: 600;
    text-transform: capitalize;
    color: var(--title);
    padding: 0;
    margin: 0;
  }
`;
