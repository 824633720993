import { Grid } from "@mui/material";
import Header from "../../../components/Header/Header";
import SalesProfileLeft from "./SalesProfileLeft";
import SalesProfileRight from "./SalesProfileRight";
import { loadUser } from "../../../Redux/Actions/authActions";
import { GetSingleUser } from "../../../Redux/Actions/EditUserAction";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
const SalesProfile = () => {
  const dispatch = useDispatch();

  const { name, title, user_id } = useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(loadUser());
    dispatch(GetSingleUser(user_id));
  }, [dispatch]);
  const { getMainUser } = useSelector((s) => s.editUser);
  const media = getMainUser?.media;

  return (
    <>
      <Header />
      <div className="profile">
        <Grid container columns={18} spacing={5}>
          <Grid item lg={5} md={8} sm={18} xs={18}>
            <SalesProfileLeft
              getMainUser={getMainUser}
              title={title}
              name={name}
            />
          </Grid>
          <Grid item lg={13} md={10} sm={18} xs={18}>
            <SalesProfileRight getMainUser={getMainUser} />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default SalesProfile;
