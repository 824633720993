import {
  GET_LEAD_BANK,
  GET_ALL_INVOICES,
  GET_SINGLE_INVOICE,
  BUY_INVOICE,
  DELETE_INVOICE,
  CHECK_OUT_LEADS,
} from "../Actions/leadBanksAction";

const inittialState = {
  leadBanks: [],
  all_invoices: "",
  singleInvoice: "",
  buyInvoice: "",
  deleteInvoice: "",
  CHECK_OUT_LEADS: "",
};
const LeadBankReducer = (state = inittialState, action) => {
  switch (action.type) {
    case GET_LEAD_BANK:
      return {
        ...state,
        leadBanks: action.leadBanks,
      };
    case GET_ALL_INVOICES:
      return {
        ...state,
        all_invoices: action.all_invoices,
      };
    case GET_SINGLE_INVOICE:
      return {
        ...state,
        singleInvoice: action.singleInvoice,
      };
    case BUY_INVOICE:
      return {
        ...state,
        buyInvoice: action.buyInvoice,
      };
    case DELETE_INVOICE:
      return {
        ...state,
        deleteInvoice: action.deleteInvoice,
      };
    case CHECK_OUT_LEADS:
      return {
        ...state,
        checkOut: action.checkOut,
      };

    default:
      return { ...state };
  }
};
export default LeadBankReducer;
