import { IS_NOTIFICATION, GET_NOTIFICATIONS } from "../Actions/type";

const initialState = {
  notification: [],
  notificationRead: [],
  isNotification: false,
};
const NotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return {
        ...state,
        notification: action.notification,
        isNotification: true,
      };
    case GET_NOTIFICATIONS:
      return {
        ...state,
        notificationRead: action.notificationRead,
      };
    case IS_NOTIFICATION:
      return {
        ...state,
        isNotification: false,
      };
    default:
      return { ...state };
  }
};

export default NotificationReducer;
