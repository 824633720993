import React from "react";
import { Bigsection } from "./style";
import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import {
  useGetCoinsQuery,
  useGetcoinsResultMutation,
} from "../../../../Redux/toolKit/coins";
import { useForm } from "react-hook-form";
import { SubmitButton } from "../../../../components/common/submitButton";
import MultipleStopIcon from "@mui/icons-material/MultipleStop";
import CheckOut from "../checkOut";
const MyCoins = () => {
  const { data, isLoading } = useGetCoinsQuery("");
  const [getcoinsResult, { data: newData, isLoading: loading }] =
    useGetcoinsResultMutation("");
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const coins = data?.data?.balance?.balance;
  const onSubmit = (data) => {
    console.log(data);
    const sendData = {
      is_coin: data.coins > 0 ? 1 : 0,
      balance: data.coins > 1 ? data.coins : data.usd,
    };
    getcoinsResult(sendData);
  };
  const watchUsd = watch("usd");
  const watchCoins = watch("coins");
  React.useEffect(() => {
    if (watchCoins > 0) {
      setValue("usd", watchCoins);
    }
  }, [watchCoins]);
  React.useEffect(() => {
    if (watchUsd > 0) {
      setValue("coins", watchUsd);
    }
  }, [watchUsd]);
  if (isLoading) {
    return <h1>Loading...</h1>;
  }
  return (
    <Bigsection>
      <div className="container">
        <div className="firstDiv">
          <p>
            <span className="coins">{coins}</span> Coins
          </p>
          <p>remaining</p>
        </div>
        <div className="secondDiv">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              columns={18}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid item xs={12} md={6}>
                {" "}
                <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
                  <InputLabel htmlFor="filled-adornment-amount">USD</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    {...register("usd")}
                    label="USD"
                    type="number"
                  ></OutlinedInput>
                </FormControl>
              </Grid>
              <MultipleStopIcon />
              <Grid item xs={12} md={6}>
                <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
                  <InputLabel htmlFor="filled-adornment-amount">
                    Coins
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    startAdornment={
                      <InputAdornment position="start">coins</InputAdornment>
                    }
                    label="Coins"
                    {...register("coins")}
                    type="number"
                  ></OutlinedInput>
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <SubmitButton type="submit">
                  {" "}
                  {loading ? "loading..." : "Calc"}
                </SubmitButton>
              </Grid> */}
              <Grid item xs={12}>
                <CheckOut money={watchUsd} />
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </Bigsection>
  );
};

export default MyCoins;
