import { NotificationsActive, Close } from "@mui/icons-material";
import Pusher from "pusher-js";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { getNotifications } from "../../Redux/Actions/NotificationActions";
const PopupNotification = () => {
  const [fullData, setFullData] = useState({});
  const [toggle, setoggle] = useState(false);
  const emtyData = Object.keys(fullData).length;
  const dispatch = useDispatch();
// console.log("fullData",fullData)
  useEffect(() => {
    const PusherFun = () => {
      const pusher = new Pusher("45749c0efdaffb06cce4", {
        cluster: "us3",
      });
      const channel = pusher.subscribe("opportunity-placed");
      channel.bind("App\\Notifications\\OpportunityPlaced", function (data) {
        setFullData({ ...data.opportunity });
        setoggle(true);
      });
      Pusher.logToConsole = true;
    };
    if (fullData) {
      PusherFun();
      dispatch(getNotifications());
    }
    return () => {};
  }, [fullData]);

  return (
    <PopupNotificationStyle className={toggle ? "show" : ""}>
      <div className="info">
        {/* <Link to={`/profilelead/${fullData?.lead_id}`}> */}
        <div className="icon">
          <NotificationsActive />
        </div>
        {/* </Link> */}
        {/* <Link to={`/profilelead/${fullData?.lead_id}`}> */}
        {emtyData !== 0 && (
          <div className="name">
            {fullData?.user.name} Created Soluation for&nbsp;
            {fullData?.lead.first_name}&nbsp;
            {""}
            {fullData?.lead.last_name}
          </div>
        )}
        {/* </Link> */}

        <div className="delete " onClick={() => setoggle(!toggle)}>
          <Close />
        </div>
      </div>
    </PopupNotificationStyle>
  );
};

const PopupNotificationStyle = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  padding: 1.5rem;
  position: fixed;
  z-index: 9999;
  bottom: 5%;
  right: 0;
  min-width: 350px;
  border-radius: 2rem 0 0 2rem;
  transition: all 0.3s;
  transform: translate3d(100%, 0, 0);
  &.show {
    transform: translate3d(0, 0, 0);
  }
  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .icon {
      padding: 0.5rem;
      border-radius: 50%;
      background: #f5f5f5;
      backdrop-filter: blur(10px);
      display: flex;
      align-items: center;
      svg {
        font-size: 2rem;
        color: #1dbce5;
      }
    }
    .name {
      color: #fff;
      font-weight: 300;
      font-size: 0.9rem;
      padding: 0 0.5rem;
    }
    .delete {
      padding: 0.1rem;
      border-radius: 50%;
      background: #f5f5f5;
      backdrop-filter: blur(10px);
      display: flex;
      align-items: center;
      svg {
        font-size: 1.1rem;
        color: #3bb6d4;
      }
    }
  }
`;
export default PopupNotification;
