import React from "react";
import {
  Button,
  Grid,
  Modal,
  MenuItem,
  Select,
  TextField,
  Box,
  FormControl,
  InputLabel,
} from "@mui/material";
// import Chip from "@mui/material/Chip";
// import OutlinedInput from "@mui/material/OutlinedInput";
import { Calculate } from "@mui/icons-material";
// import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  getChannels,
  getCategories,
  getOffers,
  getPromotions,
  getPayments,
  goodLeapCalc,
} from "../../Redux/Actions/goodLeapAction";
import { TotalAmount } from "../../Redux/Actions/CalculatorActions";
import axios from "axios";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import img from "../../assets/goodleapLogo.jpg";
import swal from "sweetalert";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "1rem",
  p: 4,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      // width: 250,
    },
  },
};
const ButtonStyled = styled(Button)`
  /* background: #ff9800; */
  background-color: #d26e04 I !important;
  background-image: linear-gradient(45deg, #d26e04 0%, #f7b334 100%) !important;
  border-radius: 2rem;
  padding: 0.8rem 2rem;
  width: 250px;
  margin: auto;
  &:hover {
    color: black !important;
  }
`;
const Img = styled.img`
  position: absolute;
  width: 10%;
  top: -5px;
  right: 0;
`;
const CalcGoodLeadp = ({ servicId, disable }) => {
  const dispatch = useDispatch();
  const { singlead } = useSelector((state) => state.leads);
  const StatName =
    singlead?.state?.abbr === "FL" ||
    singlead?.state?.abbr === "CA" ||
    singlead?.state?.abbr === "KY" ||
    singlead?.state?.abbr === "CO" ||
    singlead?.state?.abbr === "NY" ||
    singlead?.state?.abbr === "OH" ||
    singlead?.state?.abbr === "SC"
      ? singlead?.state?.abbr
      : "CA";
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  // Get the data from Apis
  const { totalAmount } = useSelector((state) => state.calc);
  const allChannels =
    useSelector((state) => state.GoodLeapReducer.allChannels) || [];
  const allCategories =
    useSelector((state) => state.GoodLeapReducer.allGetegories) || [];
  const allPromotions =
    useSelector((state) => state.GoodLeapReducer.allPrmotions.data) || [];
  const allOffers =
    useSelector((state) => state.GoodLeapReducer.allOffers) || [];
  const getThePayment =
    useSelector((state) => state.GoodLeapReducer.payment) || [];
  // const Loader = useSelector((state) => state.GoodLeapReducer.showLoader) || "";

  console.log("allPromotions", allPromotions);
  const roofingChannel = allChannels.find((e) => e.name === "AdminRoof");
  const solarChannels =
    allChannels.filter((e) => {
      return e.name !== "AdminRoof" && e.name !== "AdminHI";
    }) || [];
  console.log("solarChannels", solarChannels);
  const [data, setData] = React.useState({
    promotion: ["a92d13dd-5c2c-45ea-a178-3206994fdeee"],
    channels: [],
    // production
    // category: "3b4acb46-e937-4bee-b134-a54c36568536",
    // Beta
    // category:"b1543acb-2f4d-488a-a2ae-d8041c02e4a9",
    category: allCategories[0]?.id,
    offers: [],
    extraPaydownAmount: "",
    payDownRadio: "",
    extraPaydownPercent: Number("") / 100,
    states: [StatName],
    loanAmount: "",
    channel: "",
  });
  console.log("GoodLeap Data ", data);
  console.log("GoodLeap Data ", data);
  const achPromotion = allPromotions.find((e) => e.name === "ACH");
  console.log("achPromotion", achPromotion);
  React.useEffect(() => {
    dispatch(goodLeapCalc({ ...data }));
  }, [data]);
  React.useEffect(() => {
    if (servicId === 1) {
      data.category = allCategories[0]?.id;
      data.promotion = [`${achPromotion?.id}`];
    }
    if (servicId === 2) {
      data.category = allCategories.find((e) => e.name.includes("ROOFING"))?.id;
    }
  }, [achPromotion, allCategories]);
  React.useEffect(() => {
    if (servicId === 2) {
      data.channel = [roofingChannel?.name];
      data.promotion = [`${achPromotion?.id}`];
    }
  }, [roofingChannel, achPromotion]);
  // console.log("data.category",data.category)
  // const [channels, setChannelsarr] = React.useState([]);
  const [offerIds, setOfferIds] = React.useState([]);

  // console.log("allCategories",allCategories[0].name)
  // Dispatch All Apis
  const fetchApis = async () => {
    await dispatch(getChannels(data.states));
    await dispatch(getPromotions());
    await dispatch(getCategories());
  };
  React.useEffect(() => {
    fetchApis();
  }, []);

  React.useEffect(() => {
    if (servicId === 1) {
      if (data.channel || (data.category && data.states && achPromotion)) {
        dispatch(
          getOffers(data.channel, data.category, data.promotion, data.states)
        );
      }
    }
    if (servicId === 2) {
      if (data.channel && data.category && data.states && achPromotion) {
        dispatch(
          getOffers(data.channel, data.category, data.promotion, data.states)
        );
      }
    }
  }, [data.channel, data.category, data.promotion]);

  var resultFromPayment;
  getThePayment.map((e) => {
    resultFromPayment = e;
  });
  // console.log("resultFromPayment", resultFromPayment);
  const handleOpen = () => {
    setOpen(true);
    // dispatch(loadSunLightCalc());
  };

  //set array of channels
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    // setChannelsarr(
    //   // On autofill we get a stringified value.
    //   typeof value === "string" ? value.split(",") : value
    // );
  };
  // set offers of array
  const handleChangeForOffers = (event) => {
    const {
      target: { value },
    } = event;
    setOfferIds(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  // console.log("channels", channels);
  // console.log("offerIds", offerIds);
  // console.log("data.offers", data.offers);
  const TheOffer = allOffers?.find((e) => e.id == data.offers);
  // console.log("TheOffer", TheOffer);
  // console.log("TheOffer Rate", TheOffer?.rate);

  // To Make Sure That the it sends empty string for Amount or Peccent
  if (data.payDownRadio === "amount") {
    data.extraPaydownPercent = "";
  }
  if (data.payDownRadio === "percent") {
    data.extraPaydownAmount = "";
  }
  var percent = data.extraPaydownPercent / 100;
  if (percent === 0) {
    percent = "";
  }
  // console.log("percentNumber ", percent);

  let amount = totalAmount;
  // Submit Button
  const GetThePayment = (e) => {
    e.preventDefault();
    if (totalAmount < 5001) {
      swal({
        title: "Error",
        text: `The total Amout must be at least $ 5001`,
        icon: "warning",
        button: true,
        dangerMode: true,
      });
    } else {
      dispatch(
        getPayments(
          data.offers,
          totalAmount,
          data.promotion,
          data.extraPaydownAmount,
          percent
        )
      );
    }
    handleClose();
  };

  // totalAmount = totalAmount + (totalAmount * TheOffer?.dealerFee || 0);

  React.useEffect(() => {
    // dispatch(TotalAmount(amount));
    data.states = [StatName];
  }, [data]);
  console.log("totalAmount goodleap", totalAmount);
  console.log("amount goodleap", amount);
  return (
    <div>
      <ButtonStyled
        onClick={handleOpen}
        startIcon={<Calculate />}
        variant="contained"
        fullWidth
      >
        GoodLeap Financial Calculator
      </ButtonStyled>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form>
            <div style={{ position: "relative" }}>
              <h2>Good Leap Financial Calculator</h2>
              <Img src={img}></Img>
            </div>
            <br />
            {totalAmount < 5001 ? (
              <p style={{ color: "red", fontSize: "12px" }}>
                {" "}
                Loan Amount sould be Atleast 5001 ${" "}
              </p>
            ) : (
              <span> </span>
            )}
            {data.offers.length == 0 ? (
              <p style={{ color: "red", fontSize: "12px" }}>
                {" "}
                You Must Select One Offer{" "}
              </p>
            ) : (
              <span></span>
            )}
            {data.promotion === "" ? (
              <p style={{ color: "red", fontSize: "12px" }}>
                {" "}
                You Must Select One Promotion{" "}
              </p>
            ) : (
              <span></span>
            )}
            <br />
            <Grid item container spacing={2} columns={18}>
              {/* Get Channels signle*/}
              {servicId === 1 ? (
                <>
                  <Grid item md={6} sm={9} xs={18} mb={3}>
                    <FormControl fullWidth className="formControl">
                      <InputLabel id="demo-simple-select-label">
                        All Channels
                      </InputLabel>
                      <Select
                        id="demo-simple-select"
                        required
                        value={data.channel}
                        disabled={disable}
                        label="All Channels"
                        onChange={async (e) => {
                          setData({ ...data, channel: [e.target.value] });
                        }}
                      >
                        {solarChannels?.map((data) => (
                          <MenuItem key={data.id} value={data.name}>
                            {data.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              ) : (
                <>
                  {" "}
                  <Grid item md={6} sm={9} xs={18} mb={3}>
                    <FormControl fullWidth className="formControl">
                      <InputLabel id="demo-simple-select-label">
                        All Channels
                      </InputLabel>
                      <Select
                        id="demo-simple-select"
                        required
                        value={data.channel}
                        disabled={disable}
                        label="All Channels"
                        onChange={async (e) => {
                          setData({ ...data, channel: [e.target.value] });
                        }}
                      >
                        {allChannels?.map((data) => (
                          <MenuItem key={data.id} value={data.name}>
                            {data.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}

              {/*Get Channels Try multi select */}
              {/* <Grid item md={6} sm={9} xs={18} mb={3}>
                <FormControl fullWidth className="formControl">
                  <InputLabel id="demo-multiple-chip-label">
                    All Channels
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-chip-label"
                    label="All Channels"
                    id="demo-multiple-chip"
                    required
                    multiple
                    value={channels}
                    // onChange={async (e) => {
                    //   setData({ ...data, channels: e.target.value });
                    //   // await handleChangeCalc();
                    //   // dispatch(officalCalc({ ...data }));
                    //   // await dispatch(TotalAmount(totalAmount));
                    // }}
                    onChange={handleChange}
                    input={
                      <OutlinedInput
                        id="select-multiple-chip"
                        label="All Channels"
                      />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {allChannels?.map((data) => (
                      <MenuItem key={data.id} value={data.name}>
                        {data.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid> */}
              {/* get Promotions */}
              <Grid item md={6} sm={9} xs={18} mb={3}>
                <FormControl fullWidth className="formControl">
                  <InputLabel id="demo-simple-select-label">
                    All Promotions
                  </InputLabel>
                  <Select
                    id="demo-simple-select"
                    required
                    value={data.promotion}
                    label="Term in Years "
                    onChange={async (e) => {
                      await setData({ ...data, promotion: [e.target.value] });
                      // await handleChangeCalc();
                      // dispatch(officalCalc({ ...data }));
                      // await dispatch(TotalAmount(amount));
                    }}
                  >
                    {allPromotions?.map((data) => (
                      <MenuItem key={data.id} value={data.id}>
                        {data.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* Get categories */}
              <Grid item md={6} sm={9} xs={18} mb={3}>
                <FormControl fullWidth className="formControl">
                  <InputLabel id="demo-simple-select-label">
                    All Categories
                  </InputLabel>
                  <Select
                    id="demo-simple-select"
                    required
                    value={data.category}
                    disabled
                    label="All Categories "
                    onChange={async (e) => {
                      setData({ ...data, category: e.target.value });
                      // await handleChangeCalc();
                      // dispatch(officalCalc({ ...data }));
                      // await dispatch(TotalAmount(totalAmount));
                    }}
                  >
                    {allCategories?.map((data) => (
                      <MenuItem key={data.id} value={data.id}>
                        {data.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* Get Offers */}
              <Grid item md={6} sm={9} xs={18} mb={3}>
                <FormControl fullWidth className="formControl">
                  <InputLabel id="demo-simple-select-label">
                    All Offers
                  </InputLabel>
                  <Select
                    id="demo-simple-select"
                    required
                    value={data.offers}
                    label="All Offers"
                    onChange={async (e) => {
                      await setData({ ...data, offers: [e.target.value] });
                      // await handleChangeCalc();
                      // dispatch(officalCalc({ ...data }));
                      // await dispatch(TotalAmount(totalAmount));
                      // await dispatch(TotalAmount(amount));
                    }}
                  >
                    {allOffers?.map((data) => (
                      <MenuItem key={data.id} value={data.id}>
                        {data.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* State */}
              {/* <Grid item md={6} sm={9} xs={18} mb={3}>
                <FormControl fullWidth className="formControl">
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    variant="outlined"
                    value={StatName}
                    label="State"
                    type="text"
                    disabled              
                    onChange={(e) => {
                      setData({ ...data, states: [e.target.value] });
                    }}
                  />
                </FormControl>
              </Grid> */}
              {/* Total Amount */}
              <Grid item md={6} sm={9} xs={18} mb={3}>
                <FormControl fullWidth>
                  <TextField
                    disabled
                    label="Loan Amount"
                    value={(data.loanAmount = Math.ceil(totalAmount) || "")}
                    onChange={(e) =>
                      setData({
                        ...data,
                        loanAmount: Number(e.target.value),
                      })
                    }
                  />
                </FormControl>
              </Grid>
              {/* Dealer Fee */}
              {/* <Grid item md={6} sm={9} xs={18} mb={3}>
                <TextField
                  label="Dealar Fees"
                  className="formControl"
                  fullWidth
                  disabled={true}
                  // value={(data.dealer_fee = singleTerm?.dealer_fee || "")}
                  value={TheOffer?.dealerFee || ""}
                  // onChange={(e) =>
                  //   setData({ ...data, dealer_fee: e.target.value }) ||
                  //   false
                  // }
                />
              </Grid> */}
              {/* Select PayDown Percent or Amount */}
              {servicId === 1 && (
                <>
                  {" "}
                  <Grid item md={6} sm={9} xs={18} mb={3}>
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        PayDown
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          value="amount"
                          control={<Radio />}
                          label="Amount"
                          onChange={(e) => {
                            setData({ ...data, payDownRadio: e.target.value });
                          }}
                        />
                        <FormControlLabel
                          value="percent"
                          control={<Radio />}
                          label="Percent"
                          onChange={(e) => {
                            setData({ ...data, payDownRadio: e.target.value });
                          }}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {/* Pyadown Amount */}
                  {data.payDownRadio === "amount" ? (
                    <Grid item md={6} sm={9} xs={18} mb={3}>
                      <FormControl fullWidth className="formControl">
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          variant="outlined"
                          label="extra Paydown Amount"
                          type="text"
                          // step="any"
                          // disabled={true}
                          value={data.extraPaydownAmount}
                          onChange={(e) =>
                            setData(
                              { ...data, extraPaydownAmount: e.target.value },
                              { ...data, extraPaydownPercent: "" }
                            )
                          }
                        />
                      </FormControl>
                    </Grid>
                  ) : (
                    <div></div>
                  )}
                  {data.payDownRadio === "percent" ? (
                    <Grid item md={6} sm={9} xs={18} mb={3}>
                      <FormControl fullWidth className="formControl">
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          variant="outlined"
                          label="extra Paydown Percent"
                          type="text"
                          // step="any"
                          // disabled={true}
                          value={data.extraPaydownPercent}
                          onChange={(e) =>
                            setData({
                              ...data,
                              extraPaydownPercent: e.target.value,
                            })
                          }
                        />
                      </FormControl>
                    </Grid>
                  ) : (
                    <div></div>
                  )}
                </>
              )}

              {/* extra Paydown Amount*/}
              {/* <Grid item md={6} sm={9} xs={18} mb={3}>
                <FormControl fullWidth className="formControl">
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    variant="outlined"
                    label="extra Paydown Amount"
                    type="text"
                    // step="any"
                    // disabled={true}
                    value={data.extraPaydownAmount}
                    onChange={(e) =>
                      setData({ ...data, extraPaydownAmount: e.target.value })
                    }
                  />
                </FormControl>
              </Grid> */}
              {/* extra Paydown Percent*/}
              {/* <Grid item md={6} sm={9} xs={18} mb={3}>
                <FormControl fullWidth className="formControl">
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    variant="outlined"
                    label="extra Paydown Percent"
                    type="number"
                    // step="any"
                    // disabled={true}
                    // value={value.rate}
                    // onChange={(e) =>
                    //   setValue({ ...value, rate: e.target.value })
                    // }
                  />
                </FormControl>
              </Grid> */}
              {/* P */}
              {/* Get all offers multi select */}
              {/* <Grid item md={6} sm={9} xs={18} mb={3}>
                <FormControl fullWidth className="formControl">
                  <InputLabel id="demo-multiple-chip-label">
                    All Offers
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-chip-label"
                    label="All Channels"
                    id="demo-multiple-chip"
                    multiple
                    value={offerIds}
                    // onChange={async (e) => {
                    //   setData({ ...data, channels: e.target.value });
                    //   // await handleChangeCalc();
                    //   // dispatch(officalCalc({ ...data }));
                    //   // await dispatch(TotalAmount(totalAmount));
                    // }}
                    onChange={handleChangeForOffers}
                    input={
                      <OutlinedInput
                        id="select-multiple-chip"
                        label="All Offers"
                      />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {allOffers?.map((data) => (
                      <MenuItem key={data.id} value={data.id}>
                        {data.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid> */}
              {/*
            <Grid
              item
              md={6}
              sm={9}
              xs={18}
              mb={3}
              sx={{ display: "none" }}
            >
              <TextField
                className="formControl"
                fullWidth
                label="Term in Month"
                disabled={true}
                value={(data.term = singleTerm?.term) || ""}
                onChange={(e) =>
                  setData({ ...data, term: e.target.value }) || false
                }
              />
            </Grid>
            <Grid
              item
              md={6}
              sm={9}
              xs={18}
              mb={3}
              sx={{ display: "none" }}
            >
              <TextField
                label="APR"
                className="formControl"
                fullWidth
                disabled={true}
                value={(data.apr = singleTerm?.apr) || ""}
                onChange={(e) =>
                  setData({ ...data, apr: e.target.value }) || false
                }
              />
            </Grid>

            <Grid item md={6} sm={9} xs={18} mb={3}>
              <TextField
                label="Dealar Fees"
                className="formControl"
                fullWidth
                disabled={true}
                value={(data.dealer_fee = singleTerm?.dealer_fee || "")}
                onChange={(e) =>
                  setData({ ...data, dealer_fee: e.target.value }) ||
                  false
                }
              />
            </Grid>

            <Grid item md={6} sm={9} xs={18} mb={3}>
              <FormControl fullWidth>
                <TextField
                  label="State Name"
                  disabled={true}
                  value={(data.state_name = StatName) || ""}
                  onChange={(e) =>
                    setData({ ...data, state_name: e.target.value }) ||
                    false
                  }
                />
              </FormControl>
            </Grid>

            <Grid item md={6} sm={9} xs={18} mb={3}>
              <FormControl fullWidth>
                <TextField
                  disabled
                  label="Loan Amount"
                  value={(data.loanAmount = Math.ceil(totalAmount) || "")}
                  onChange={(e) =>
                    setData({
                      ...data,
                      loanAmount: Number(e.target.value),
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid item md={6} sm={9} xs={18} mb={3}>
              <FormControl fullWidth>
                <TextField
                  required
                  disabled
                  label="Voluntary Payment Percentage"
                  step="0.01"
                  value={data.paydownPercentage}
                  onChange={(e) =>
                    setData({
                      ...data,
                      paydownPercentage: Number(e.target.value),
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid
              container
              xs={18}
              item
              alignItems="center"
              justifyContent="center"
            >

            </Grid> */}
            </Grid>
            <Grid
              container
              xs={18}
              item
              alignItems="center"
              justifyContent="center"
            >
              <ButtonStyled
                onClick={GetThePayment}
                fullWidth
                type="submit"
                className="btnCalc"
                endIcon={<Calculate />}
                variant="contained"
              >
                Calculate
              </ButtonStyled>
            </Grid>
          </form>
        </Box>
      </Modal>
    </div>
  );
};
export default React.memo(CalcGoodLeadp);
