import Header from "../../../components/Header/Header";
import React, { useEffect, useState } from "react";
import {
  InputLabel,
  MenuItem,
  Button,
  Grid,
  Typography,
  Switch,
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
  Modal,
  FormControl,
} from "@mui/material";
// Google Maps
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from "react-places-autocomplete";
import Geocode from "react-geocode";
import {
  GoogleMap,
  LoadScript,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
// End OF Google Maps
import { LocalizationProvider, TimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useDispatch, useSelector } from "react-redux";
import {
  loadCountries,
  loadStates,
  loadCities,
  loadServices,
  GetIdStateByState,
} from "../../../Redux/Actions/CountriesActions";
import styled from "styled-components";
// import addLeadActions from "../../../../../Redux/Actions/addLeadActions";
import { useLocation, useNavigate } from "react-router-dom";
import { CheckCircle } from "@mui/icons-material";
import { typography } from "@mui/system";
import { singleLeadAction } from "../../../Redux/Actions/singleLeadAction";
import { editLeadAction } from "../../../Redux/Actions/EditLeadAction";
import NumberFormat from "react-number-format";
import Loading from "../../../components/loading/Loading";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  p: 4,
};
const EditLeadProfile = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const LeadID = location.pathname.split("/")[2];

  const [showData, setShowData] = React.useState(false);
  const [address, setAddress] = React.useState("");
  const [full_address, setFullAddress] = React.useState("");
  const [zip_code, setPostalCode] = React.useState("");
  const [street, setStreetLoc] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [state, setState] = React.useState("");
  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    setCoordinates(latLng);
    setShowData(true);
  };
  // const california = "california";
  //get the ID From state
  useEffect(() => {
    dispatch(GetIdStateByState(state));
  }, [state]);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyCrr_bBNHxQ0lZKadmJYpqS2vOebczD1m4",
    libraries: ["places"],
  });
  const { errors, status } = useSelector((state) => state.leads);
  const { singlead, isSingleLeadLoading } = useSelector((state) => state.leads);
  const { countryData } = useSelector((state) => state.countries);
  console.log("singlead use Selector", singlead, isSingleLeadLoading);
  const [coordinates, setCoordinates] = React.useState({
    lat: Number(singlead?.lat) || 35.6541977,
    lng: Number(singlead?.lng) || -105.1424589,
  });

  // Google Maps style
  const mapStyles = {
    width: "100%",
    height: "100%",
  };
  // Geocoding
  Geocode.setLocationType("ROOFTOP");

  Geocode.setApiKey("AIzaSyCrr_bBNHxQ0lZKadmJYpqS2vOebczD1m4");
  React.useEffect(() => {
    Geocode.fromLatLng(coordinates.lat, coordinates.lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        let street, city, state, country, postalCode, route;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "street_number":
                street = response.results[0].address_components[i].long_name;
                break;
              case "locality":
                city = response.results[0].address_components[i].long_name;
                break;
              case "postal_code":
                postalCode =
                  response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                break;

              case "route":
                route = response.results[0].address_components[i].long_name;
                break;
            }
          }
        }
        // Set the Normal states
        // setPostalCode({...data,zip_code:postalCode});
        setPostalCode(postalCode);
        setFullAddress(address);
        setStreetLoc(street);
        setCountry(country);
        setState(state);
        // setRoute(route);

        // Not Normal
        data.zip_code = postalCode;
        data.street = route + " " + street;
        data.city_name = city;
        data.location = address;
      },
      (error) => {
        console.error(error);
      }
    );
  }, [coordinates]);

  const navigate = useNavigate();
  //add the satets
  const [allCountries, setAllCountries] = useState([]);
  const [selectCountry, setSelectCountry] = useState("");
  const [allstates, setAllStates] = useState([]);
  const [selectStates, setselectStates] = useState("");
  const [allCities, setAllCites] = useState([]);
  const [selectCity, setSelectCity] = useState("");

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleAddLead = () => {
    navigate("/crm/addlead");
    window.location.reload(false);
  };
  // const handleGoToLeads = () => {
  //   navigate("/crm/leads");
  // };

  const customerTypes = [
    { name: "Residential", value: "residential" },
    { name: "Commercial", value: "commercial" },
  ];
  const languages = [
    { name: "English", value: "english" },
    { name: "Spanish", value: "spanish" },
  ];
  const buildingTypes = [
    { name: "Single Family Residence", value: "single_family" },
    // { name: "Residence", value: "residence" },
    { name: "Trailer", value: "trailer" },
    { name: "Town Home", value: "town_home" },
  ];
  const conatctway = [
    { name: "Phone", value: "phone" },
    { name: "Email", value: "email" },
  ];
  const ownerships = [
    { name: "Rental", value: "rental" },
    { name: "Owner", value: "owner" },
    { name: "Owned With Mortgage", value: "OWNED_WITH_MORTGAGE" },
  ];
  const citizeship = [
    { name: "Us citizen", value: "US_CITIZEN" },
    {
      name: "Lawful permanent resident  alien",
      value: "LAWFUL_PERMANENT_RESIDENT_ALIEN",
    },
    { name: "Other", value: "OTHER" },
  ];
  React.useEffect(() => {
    data.customer_type = singlead?.customer_type;
    data.first_name = singlead?.first_name;
    data.last_name = singlead?.last_name;
    data.mobile = singlead?.mobile;
    data.is_mobile_receives_txt = singlead?.is_mobile_receives_txt;
    data.phone = singlead?.phone;
    data.is_phone_receives_txt = singlead?.is_phone_receives_txt;
    data.email = singlead?.email;
    data.preferred_language = singlead?.preferred_language;
    data.service_type_id = singlead?.service_type_id;
    data.location = singlead?.location;
    data.time_to_contact = singlead?.time_to_contact;
    data.street = singlead?.street;
    data.unit = singlead?.unit;
    data.city_id = singlead?.city_id;
    data.is_active = singlead?.is_active;
    data.is_hoa = singlead?.is_hoa;
    data.company_name = singlead?.company_name;
    data.company_business_model = singlead?.company_business_model;
    data.building_type = singlead?.building_type;
    data.way_to_contact = singlead?.way_to_contact;
    data.is_decision_maker_present = singlead?.is_decision_maker_present;
    data.house_ownership = singlead?.house_ownership;
    data.zip_code = singlead?.zip_code;
    data.citizenship_status = singlead?.citizenship_status;
    coordinates.lat = singlead?.lat;
    coordinates.lng = singlead?.lng;
  }, [singlead,isSingleLeadLoading]);

  const initialState = {
   customer_type : singlead?.customer_type,
   first_name : singlead?.first_name,
   last_name : singlead?.last_name,
   mobile : singlead?.mobile,
   is_mobile_receives_txt : singlead?.is_mobile_receives_txt,
   phone : singlead?.phone,
   is_phone_receives_txt : singlead?.is_phone_receives_txt,
   email : singlead?.email,
   preferred_language : singlead?.preferred_language,
   service_type_id : singlead?.service_type_id,
   location : singlead?.location,
   time_to_contact : singlead?.time_to_contact,
   street : singlead?.street,
   unit : singlead?.unit,
   city_id : singlead?.city_id,
   is_active : singlead?.is_active,
   is_hoa : singlead?.is_hoa,
   company_name : singlead?.company_name,
   company_business_model : singlead?.company_business_model,
   building_type : singlead?.building_type,
   way_to_contact : singlead?.way_to_contact,
   is_decision_maker_present : singlead?.is_decision_maker_present,
   house_ownership : singlead?.house_ownership,
   zip_code : singlead?.zip_code,
   citizenship_status : singlead?.citizenship_status,
    // coordinates.lat : singlead?.lat,
    // coordinates.lng : singlead?.lng,
  };
  
  const { countries, states, cities, services } = useSelector(
    (state) => state.countries
  );
  const [data, setData] = useState({});
  const CountryAndState = {
    country_id: countryData[0]?.country.id || singlead?.country_id,
    state_id: countryData[0]?.id || singlead?.state_id,
  };
  React.useEffect(()=>{
    setData({ ...initialState });

  },[])
  var building_coordinates = {
    building_coordinates: {
      lat: coordinates.lat,
      lng: coordinates.lng,
    },
  };

  useEffect(() => {
    dispatch(singleLeadAction(LeadID));
  }, []);

  useEffect(() => {
    dispatch(loadServices());
    dispatch(loadCountries());
    setAllCountries(countries);
    dispatch(loadStates(231));
    setSelectCountry(Object.keys(countries)[0]);
  }, [dispatch]);

  useEffect(() => {
    if (data.country_id) {
      dispatch(loadStates(data.country_id));
      setAllStates(states);
      setselectStates(Object.keys(states)[0]);
    }
  }, [data.country_id]);

  useEffect(() => {
    if (data.state_id) {
      dispatch(loadCities(data.state_id));
      setAllCites(cities);
      setSelectCity(Object.keys(cities)[0]);
    }
  }, [data.state_id]);

  const handelSubmit = (e) => {
    e.preventDefault();
    dispatch(
      editLeadAction(LeadID, {
        ...data,
        ...CountryAndState,
        ...coordinates,
      })
    );
    // navigate(`/profilelead/${LeadID}`);
  };

  useEffect(() => {
    if (status === 201) {
      setOpen(!open);
    }
  }, [status]);

  const handleToggleCheck = (value) => () => {
    const currentIndex = data.service_type_id.indexOf(value);
    const newChecked = [...data.service_type_id];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setData({ ...data, service_type_id: newChecked });
  };
  if (!isLoaded) {
    return  <Loading />;
  }
  console.log("singlead", singlead);
  console.log("Data State", data);
  return (
    <>
      <Header />
      {!isSingleLeadLoading && (
        <>
          <FormContianerStyled>
            <h3>Edit Lead Profile</h3>
            <form onSubmit={handelSubmit}>
              <Grid container item spacing={3} columns={18}>
                <Grid item xs={18} sm={18} md={18}>
                  <TextField
                    select
                    fullWidth
                    required
                    label="Customer Type"
                    defaultValue={data.customer_type}
                    value={data.customer_type}
                    onChange={(e) =>
                      setData({ ...data, customer_type: e.target.value })
                    }
                  >
                    {customerTypes.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={18} sm={9} md={9}>
                  <TextField
                    required
                    fullWidth
                    name="first name"
                    label="First Name"
                    value={data.first_name}
                    onChange={(e) =>
                      setData({ ...data, first_name: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={18} sm={9} md={9}>
                  <TextField
                    fullWidth
                    required
                    name="last  name"
                    label="Last Name"
                    value={data.last_name}
                    onChange={(e) =>
                      setData({ ...data, last_name: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={18} sm={9} md={9}>
                  {/* <TextField
                    fullWidth
                    name="mobile"
                    label="Mobile"
                    value={data.mobile}
                    onChange={(e) =>
                      setData({ ...data, mobile: e.target.value })
                    }
                  /> */}
                  <NumberFormat
                    format="+1 (###) ###-####"
                    fullWidth
                    allowEmptyFormatting
                    label="Mobile"
                    customInput={TextField}
                    required
                    mask="_"
                    value={data.mobile}
                    onValueChange={(values) => {
                      setData({ ...data, mobile: values.value });
                    }}
                  />
                  {errors && <div className="errorCatch">{errors.mobile} </div>}
                </Grid>
                <Grid item xs={18} sm={9} md={9}>
                  <InputLabel>Preference mobile</InputLabel>
                  <Switch
                    checked={data.is_mobile_receives_txt}
                    onChange={(e) =>
                      setData({
                        ...data,
                        is_mobile_receives_txt: e.target.checked,
                      })
                    }
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Grid>
                <Grid item xs={18} sm={9} md={9}>
                  {/* <TextField
                    fullWidth
                    // required
                    name="phone"
                    label="Phone"
                    value={data.phone}
                    onChange={(e) =>
                      setData({ ...data, phone: e.target.value })
                    }
                  /> */}
                  <NumberFormat
                    format="+1 (###) ###-####"
                    fullWidth
                    allowEmptyFormatting
                    customInput={TextField}
                    mask="_"
                    label="Phone"
                    value={data.phone}
                    // onChange={(e) => setData({ ...data, phone: e.target.value })}
                    onValueChange={(values) => {
                      setData({ ...data, phone: values.value });
                    }}
                  />
                  {errors && <div className="errorCatch">{errors.phone} </div>}
                </Grid>
                <Grid item xs={18} sm={9} md={9}>
                  <InputLabel>Preference Phone</InputLabel>
                  <Switch
                    checked={data.is_phone_receives_txt}
                    onChange={(e) =>
                      setData({
                        ...data,
                        is_phone_receives_txt: e.target.checked,
                      })
                    }
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Grid>
                <Grid item xs={18} sm={9} md={9}>
                  <TextField
                    fullWidth
                    required
                    name="email"
                    label="Email"
                    value={data.email}
                    onChange={(e) =>
                      setData({ ...data, email: e.target.value })
                    }
                  />
                </Grid>

                {/* Way to contact */}
                <Grid item xs={18} sm={9} md={9} mb={3}>
                  <TextField
                    label="Way to contact"
                    select
                    fullWidth
                    value={data.way_to_contact}
                    onChange={(e) =>
                      setData({ ...data, way_to_contact: e.target.value })
                    }
                  >
                    {conatctway.map((o) => (
                      <MenuItem key={o.value} value={o.value}>
                        {o.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={18} sm={9} md={9}>
                  <TextField
                    select
                    fullWidth
                    required
                    label="Preferred Language"
                    value={data.preferred_language}
                    onChange={(e) =>
                      setData({ ...data, preferred_language: e.target.value })
                    }
                  >
                    {languages.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                {/* House Ownership  */}
                <Grid item xs={18} sm={9} md={9} mb={3}>
                  {/* <InputLabel>House Ownership </InputLabel> */}
                  <TextField
                    select
                    required
                    fullWidth
                    label=" House Ownership"
                    value={data.house_ownership}
                    onChange={(e) =>
                      setData({ ...data, house_ownership: e.target.value })
                    }
                  >
                    {ownerships.map((o) => (
                      <MenuItem key={o.value} value={o.value}>
                        {o.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                {/* Time to Contact */}
                <Grid item xs={18} sm={9} md={9} mb={3}>
                  {/* <InputLabel>Time to Contact</InputLabel> */}
                  <FormControl fullWidth>
                    <LocalizationProvider
                      sx={{ with: "100%", color: "#414251" }}
                      dateAdapter={AdapterDateFns}
                    >
                      <TimePicker
                        mode="24h"
                        label="Time to Contact"
                        value={data.time_to_contact || null}
                        onChange={(e) =>
                          setData({ ...data, time_to_contact: e })
                        }
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                {/* Building Type */}
                <Grid item xs={18} sm={9} md={9} mb={3}>
                  <TextField
                    select
                    required
                    fullWidth
                    label="Building Type"
                    value={data.building_type}
                    onChange={(e) =>
                      setData({ ...data, building_type: e.target.value })
                    }
                  >
                    {buildingTypes.map((o) => (
                      <MenuItem key={o.value} value={o.value}>
                        {o.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {/* citixeShipStatus */}
                <Grid item md={18} sm={18} xs={18} mb={3}>
                  <InputLabel>Citizen Ship Status </InputLabel>
                  <TextField
                    select
                    required
                    fullWidth
                    value={data.citizenship_status}
                    onChange={(e) =>
                      setData({ ...data, citizenship_status: e.target.value })
                    }
                  >
                    {citizeship.map((o) => (
                      <MenuItem key={o.value} value={o.value}>
                        {o.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {/* Is Decision Maker Present  */}
                <Grid item xs={18} sm={9} md={9}>
                  <InputLabel>Is Decision Maker Present </InputLabel>
                  <typography>No</typography>
                  <Switch
                    checked={data.is_decision_maker_present}
                    onChange={(e) =>
                      setData({
                        ...data,
                        is_decision_maker_present: e.target.checked,
                      })
                    }
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <typography>Yes</typography>
                </Grid>

                {/* Is Hoa  */}
                <Grid item xs={18} sm={9} md={9}>
                  <InputLabel>Is HOA </InputLabel>
                  <typography>No</typography>
                  <Switch
                    checked={data.is_hoa}
                    onChange={(e) =>
                      setData({
                        ...data,
                        is_hoa: e.target.checked,
                      })
                    }
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <typography>Yes</typography>
                </Grid>

                {/* <Grid item xs={18} sm={9} md={9}>
                  <InputLabel id="demo-multiple-chip-label">
                    Service Types
                  </InputLabel>

                  {services.map((serve) => (
                    <span key={serve.id}>
                      <FormControlLabel
                        key={serve.id}
                        control={
                          <Checkbox
                            key={serve.id}
                            value={serve.id}
                            checked={
                              data.service_type_id.indexOf(serve.id) !== -1
                            }
                            onChange={handleToggleCheck(serve.id)}
                          />
                        }
                        label={serve.title}
                      />
                    </span>
                  ))}
                </Grid> */}

                {data.customer_type === "commercial" ? (
                  <>
                    <Grid item xs={18} sm={9} md={9}>
                      <TextField
                        fullWidth
                        required
                        label="Company Name"
                        value={data.company_name}
                        onChange={(e) =>
                          setData({ ...data, company_name: e.target.value })
                        }
                      />
                    </Grid>
                    <Grid item xs={18} sm={9} md={9}>
                      <TextField
                        fullWidth
                        required
                        label="Company Business Model"
                        value={data.company_business_model}
                        onChange={(e) =>
                          setData({
                            ...data,
                            company_business_model: e.target.value,
                          })
                        }
                      />
                    </Grid>
                  </>
                ) : null}

                <Grid container item spacing={3}>
                  {/* Fields of inputs */}
                  <Grid item xs={18} sm={9} md={9}>
                    {/* <Grid item xs={18} sm={18} md={18} sx={{ mb: 2 }}>
                      <TextField
                        select
                        fullWidth
                        required
                        label="countries"
                        value={data.country_id}
                        onChange={(e) =>
                          setData({ ...data, country_id: e.target.value })
                        }
                      >
                        {countries.map((c) => (
                          <MenuItem key={c.id} value={c.id}>
                            {c.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid> */}
                    {/* Is Ater Adress   */}
                    <Grid item xs={18} sm={9} sx={{ mb: 2 }}>
                      <InputLabel>Manual Adress ? </InputLabel>
                      <typography>No</typography>
                      <Switch
                        checked={data.is_alter_address}
                        onChange={(e) =>
                          setData({
                            ...data,
                            is_alter_address: e.target.checked,
                          })
                        }
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <typography>Yes</typography>
                    </Grid>

                    {data.is_alter_address && (
                      <>
                        <Grid container spacing={2}>
                          <Grid item xs={18} sm={9}>
                            <TextField
                              select
                              fullWidth
                              required
                              label="State"
                              value={data.alter_state}
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  alter_state: e.target.value,
                                })
                              }
                            >
                              {states?.map((s) => (
                                <MenuItem key={s.id} value={s.id}>
                                  {s.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item xs={18} sm={9}>
                            <TextField
                              fullWidth
                              name="City"
                              label="City"
                              value={data.alter_city}
                              onChange={(e) =>
                                setData({ ...data, alter_city: e.target.value })
                              }
                            />
                          </Grid>
                          <Grid item xs={18} sm={18}>
                            <TextField
                              fullWidth
                              name="Street Address"
                              label="Street Address"
                              value={data.alter_address}
                              placeholder="13th Street North 1005"
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  alter_address: e.target.value,
                                })
                              }
                            />
                          </Grid>

                          <Grid item xs={18} sm={18}>
                            <TextField
                              label=" ZIP Code"
                              value={data.alter_zip_code}
                              fullWidth
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  alter_zip_code: e.target.value,
                                })
                              }
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}

                    {!data.is_alter_address && (
                      <>
                        <Grid item xs={18} sm={18} sx={{ mb: 2 }}>
                          <PlacesAutocomplete
                            value={address}
                            onChange={setAddress}
                            onSelect={handleSelect}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div>
                                <TextField
                                  sx={{ mb: 3 }}
                                  fullWidth
                                  {...getInputProps({
                                    placeholder: "Type Your address",
                                  })}
                                ></TextField>

                                <Result>
                                  {loading ? <div>...loading</div> : null}

                                  {suggestions.map((suggestion) => {
                                    const style = {
                                      backgroundColor: suggestion.active
                                        ? "#41b6e6"
                                        : "#fff",
                                      textAlign: "center",
                                      fontSize: "13px",
                                    };

                                    return (
                                      <MenuItem
                                        style={{ border: "1px solid black" }}
                                        {...getSuggestionItemProps(suggestion, {
                                          style,
                                        })}
                                      >
                                        {suggestion.description}
                                      </MenuItem>
                                    );
                                  })}
                                </Result>
                              </div>
                            )}
                          </PlacesAutocomplete>
                        </Grid>
                        {/* Try the container */}
                        <Grid container spacing={3}>
                          {/* <Grid item xs={18} sm={9} sx={{ mb: 2 }}>
                  <TextField
                    select
                    fullWidth
                    required
                    label="State"
                    value={data.state_id}
                    onChange={(e) =>
                      setData({ ...data, state_id: e.target.value })
                    }
                  >
                    {states.map((s) => (
                      <MenuItem key={s.id} value={s.id}>
                        {s.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid> */}
                          {/* <Grid item xs={18} sm={9} sx={{ mb: 2 }}>
                  <TextField
                    select
                    fullWidth
                    required
                    label="city"
                    value={data.city_id}
                    onChange={(e) =>
                      setData({ ...data, city_id: e.target.value })
                    }
                  >
                    {cities.map((ci) => (
                      <MenuItem key={ci.id} value={ci.id}>
                        {ci.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid> */}
                        </Grid>

                        {/* Try */}
                        {showData === true ? (
                          <>
                            <Grid container spacing={2}>
                              <Grid item xs={16} sm={9} sx={{ mb: 2 }}>
                                <TextField
                                  fullWidth
                                  required
                                  name="country"
                                  label="country"
                                  disabled
                                  value={country}
                                  // onChange={(e) =>
                                  //   setData({ ...data, zip_code: e.target.value })
                                  // }
                                />
                              </Grid>
                              <Grid item xs={16} sm={9} sx={{ mb: 2 }}>
                                <TextField
                                  fullWidth
                                  required
                                  name="state"
                                  label="state"
                                  disabled
                                  value={state}
                                  // onChange={(e) =>
                                  //   setData({ ...data, zip_code: e.target.value })
                                  // }
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={18} sm={18} sx={{ mb: 2 }}>
                                <TextField
                                  fullWidth
                                  required
                                  label="Full Address"
                                  value={full_address}
                                  disabled
                                ></TextField>
                              </Grid>
                              {/* manual address */}
                              {/* <Grid item xs={18} sm={18} sx={{ mb: 2 }}>
                  <TextField
                    fullWidth
                    required
                    label="Alter Address"
                    value={data.alter_address}
                    disabled
                  ></TextField>
                </Grid> */}

                              <Grid item xs={18} sm={9} sx={{ mb: 2 }}>
                                <TextField
                                  fullWidth
                                  name="unit"
                                  label="Unit"
                                  value={data.unit}
                                  onChange={(e) =>
                                    setData({ ...data, unit: e.target.value })
                                  }
                                />
                              </Grid>
                              <Grid item xs={18} sm={9} sx={{ mb: 2 }}>
                                <TextField
                                  fullWidth
                                  name="zip_code"
                                  label="ZIP"
                                  value={zip_code}
                                  disabled
                                  // onChange={(e) => setData({ ...data, zib_code: e.target.value })}
                                />
                              </Grid>
                            </Grid>
                          </>
                        ) : (
                          <WarnDiv>
                            <h1>
                              Please Select One of the Suggested addresses After
                              You type The Address
                            </h1>{" "}
                          </WarnDiv>
                        )}
                      </>
                    )}
                    {/* Try the container */}
                    <Grid container spacing={3}>
                      {/* <Grid item xs={18} sm={9} md={9} sx={{ mb: 2 }}>
                        <TextField
                          select
                          fullWidth
                          required
                          label="State"
                          value={data.state_id}
                          onChange={(e) =>
                            setData({ ...data, state_id: e.target.value })
                          }
                        >
                          {states.map((s) => (
                            <MenuItem key={s.id} value={s.id}>
                              {s.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid> */}
                      {/* <Grid item xs={18} sm={9} md={9} sx={{ mb: 2 }}>
                        <TextField
                          select
                          fullWidth
                          required
                          label="city"
                          value={data.city_id}
                          onChange={(e) =>
                            setData({ ...data, city_id: e.target.value })
                          }
                        >
                          {cities.map((ci) => (
                            <MenuItem key={ci.id} value={ci.id}>
                              {ci.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid> */}
                    </Grid>
                  </Grid>
                  {/* Google Maps */}
                  <Grid item xs={18} sm={9} md={9}>
                    <GoogleMap
                      mapContainerStyle={mapStyles}
                      zoom={25}
                      center={coordinates}
                      mapTypeId={"satellite"}
                    >
                      <Marker
                        position={coordinates}
                        draggable={true}
                        onDragEnd={(e) =>
                          setCoordinates({
                            ...coordinates,
                            lat: e.latLng.lat(),
                            lng: e.latLng.lng(),
                          }) || undefined
                        }
                      />
                    </GoogleMap>
                  </Grid>
                </Grid>
              </Grid>
              <Box className="boxSubmit">
                <Button
                  className="btn-submit"
                  type="submit"
                  color="primary"
                  fullWidth
                >
                  Update
                </Button>
              </Box>
            </form>
          </FormContianerStyled>
        </>
      )}
      {isSingleLeadLoading && <Loading />}
    </>
  );
};

const FormContianerStyled = styled.div`
  width: 65%;
  margin: 2rem auto;
  padding: 2.5rem;
  border-radius: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
  h3 {
    font-size: 2.5rem;
    text-align: center;
    color: #63bcd8;
    padding: 1rem 0 2rem;
  }
  .btn-submit {
    margin: 2rem 0;
    background: #63bcd8;
    padding: 0.5rem 0;
    border-radius: 1rem;
    color: #fff;
    font-size: 1.3rem;
  }
`;
const ModelConatiner = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  .icon {
    svg {
      color: #56b088;
      font-size: 5rem;
    }
  }
  p {
    font-size: 1.5rem;
    text-transform: capitalize;
    margin: 1.5rem;
    color: #777;
  }
  .btn_sucess {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin: 1rem 0;
    .go_lead {
      background: #56b088;
    }

    .add_lead {
      background: #979696;
    }
  }
`;
const Result = styled.div`
  box-shadow: 5px 5px 5px #a6a6a6;
  width: 100%;
`;
const WarnDiv = styled.div`
  position: relative;
  background-color: #d94948;
  border-radius: 2rem;
  margin: 1rem;
  h1 {
    padding: 2rem;
    color: white;
  }
  &:before {
    position: absolute;
    content: "";
    top: 0.6rem;
    margin: -25px 0 0 -25px;
    left: 50%;
    width: 0;
    height: 0;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-bottom: 1rem solid #d94948;
  }
`;
export default EditLeadProfile;
