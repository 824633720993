import styled from "styled-components";

export const BigSection = styled.div`
  /* CompanyInfo.module.scss */
  .companyInfoContainer {
    display: flex;
    align-items: center;
    width: 100%;
    // padding: 1.5rem 0;
  }

  .title {
    font-size: 1.5rem;
    font-weight: bold;
    //   margin-bottom: 0.5rem;
    width: 17%;
  }

  .line {
    border: none;
    border-top: 1px solid #000000;
    width: 83%;
  }

  /* Responsive styles */
  @media (max-width: 1200px) {
    .title {
      width: 100%;
    }

    .line {
      width: 100%;
    }
    .companyInfoContainer {
      flex-direction: column;
    }
  }
`;

// @media (max-width: 480px) {
//   .title {
//     font-size: 1rem;
//   }

//   .line {
//     width: 100%;
//   }
// }
