import { Grid } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import singleLeadAction from "../../../Redux/Actions/singleLeadAction";
import FooterPDF from "./FooterPDF";

const PageThreePdf = ({ singleOpportunity }) => {
  return (
    <ThreePageStyle className="three">
      <h2>YOUR CUSTOMIZED ENERGY SOLUTION</h2>
      <div className="offers">
        <p>
          To Offset Your Home’s Current Energy Usage by {"  "}
          <span className="dataFromApi">
            {singleOpportunity.productivity_offset}%
          </span>
          , We Recommend:
        </p>
        <h3>{Math.floor(singleOpportunity.system_size)} KW DC Watt System</h3>

        <div className="table">
          <Grid container columns={18}>
            <Grid item xs={9}>
              <ul>
                <li>{singleOpportunity.number_of_panels} Panels</li>
                <li>{singleOpportunity.panel_wattage} Watt Panel Output</li>
                <li>{singleOpportunity.inverter_type} </li>
                <li>
                  {singleOpportunity.annual_kwh_usage}kWh Estimated Annual
                  Production
                </li>
              </ul>
            </Grid>
            <Grid item xs={9}>
              <ul>
                <li>Web Based Solar Production Monitoring</li>
                <li>25-year Solar Panel and Components Warranty</li>
                <li>25-year Inverter Warranty </li>
              </ul>
            </Grid>
          </Grid>
        </div>
      </div>
      <Grid container columns={18} spacing={6}>
        <Grid item sm={9} md={6} xs={18}>
          <div className="gridItem">
            <h4>Installation Warranty & System Monitoring</h4>
            <p>
              We provide a 25-year warranty that covers the workmanship of our
              installation. If any of your system components need to be replaced
              within the warranty period, we cover all costs associated with
              replacement. In addition, we provide web based monitoring during
              the warranty period so you can view your system performance in
              real time from any device
            </p>
          </div>
        </Grid>
        <Grid item sm={9} md={6} xs={18}>
          <div className="gridItem">
            <h4>Hedge Against Future Utility Rate Increases</h4>
            <p>
              Utility rates have increased at an average rate of roughly 4% per
              year over the last 30 years. When you switch to solar you can help
              reduce the impact of raising utility costs on your home costs.
              Many people believe that utility costs will rise even faster in
              the future as fossil fuel resources begin to dwindle and more
              municipalities enforce regulations reducing the amount of cheap
              dirty energy available from burning coal and other resources.
            </p>
          </div>
        </Grid>
        <Grid item sm={9} md={6} xs={18}>
          <div className="gridItem">
            <h4>What Is Net Metering?</h4>
            <p>
              Net Metering allows homeowners with solar power systems to feed
              energy back into the utility grid and receive credits when their
              systems are producing more energy than they are consuming in their
              homes. This allows homeowners to bank credits during the summer
              months when the systems produce more due to the increased sun
              exposure, and use those credits during the winter months when
              systems generate less due to shorter days.
            </p>
          </div>
        </Grid>
      </Grid>

      <FooterPDF />
    </ThreePageStyle>
  );
};

const ThreePageStyle = styled.div`
  min-height: 100vh;
  /* .dataFromApi{
    color:red;
  } */
  h2 {
    margin: 3rem 0;
    text-align: center;
    font-weight: 800;
    color: #013881;
  }
  .offers {
    p {
      font-size: 1.5rem;
      text-align: center;
      padding: 2rem 0;
      color: #013881;
    }
    h3 {
      padding: 1rem 0;
      font-size: 1.6rem;
      color: #47b900;
    }
  }
  .table {
    padding: 1rem;
    margin: 1.5rem 0;
    border: 1px solid #444;
    color: #808080;
    ul {
      li {
        padding: 0.2rem 0;
        display: block;
      }
    }
  }
  .gridItem {
    h4 {
      color: #4e5aa1;
      font-weight: 600;
      font-size: 1.5rem;

      margin-bottom: 1rem;
    }
    p {
      font-size: 1.1rem;
      line-height: 28px;
      font-weight: 600;
      color: #626364;
    }
  }
`;

export default PageThreePdf;
