import { AccountCircle } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ArchiveLead } from "../../Redux/Actions/leadActions";

const LeadMainInfo = ({ singlead }) => {
  const Status = singlead?.status;
  const dispatch = useDispatch();
  return (
    <>
      <LeadProfileStyled>
        <div className="mainInfo">
          <div className="mainInfo__icon">
            <AccountCircle />
          </div>
          <h2 className="mainInfo__title">
            {singlead?.first_name} {singlead?.last_name}
          </h2>
          <span className="price">$ {singlead?.price}</span>
        </div>
        <div className="contactInfo">
          <h3>Contact information</h3>
          <ul>
            <li>
              <span>First name : {singlead?.first_name}</span>
            </li>
            <li>
              <span>Last Name: {singlead?.last_name}</span>
            </li>
            <li>
              <span>Email : {singlead?.email}</span>
            </li>
            <li>
              <span>Phone : {singlead?.phone}</span>
            </li>
            <li>
              <span>Language : {singlead?.preferred_language}</span>
            </li>
            <li>
              <span>State : {singlead?.state?.name}</span>
            </li>
            <li>
              <span>street : {singlead?.street}</span>
            </li>
            <li>
              <span>Duration in your profile: {singlead?.days} days</span>
            </li>
            <li>
              <span>Lead's Current Value : ${singlead?.price} </span>
            </li>
            <li>
              <span>house ownership: {singlead?.house_ownership}</span>
            </li>
          </ul>

          <Button fullWidth variant="contained" className="editProfile">
            <Link to="edit">Edit lead Profile </Link>
          </Button>
          {Status !== "archive" && (
            <Button
              fullWidth
              variant="error"
              className="editProfile asign"
              onClick={() => dispatch(ArchiveLead(singlead.id))}
            >
              Archive This Lead
            </Button>
          )}

          <Button fullWidth variant="contained" className="editProfile">
            <Link to="assing-to-user">assing lead to team member</Link>
          </Button>
          <Button fullWidth variant="contained" className="editProfile">
            <Link to="send-to-leads-bank">send to leads bank</Link>
          </Button>
        </div>
      </LeadProfileStyled>
    </>
  );
};

const LeadProfileStyled = styled.div`
  margin: 2rem;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);

  border-radius: 1rem;
  .mainInfo {
    padding: 2rem;
    text-align: center;
    border-bottom: 1px solid #777;
    &__icon {
      svg {
        font-size: 5rem;
        color: #777;
      }
    }
    &__title {
      color: #5588b8;
    }
    .price {
      color: #00660e;
    }
  }
  .contactInfo {
    padding: 2rem;
    h3 {
      font-size: 1.5rem;
      font-weight: 200;
      margin-bottom: 0.5rem;
      color: #888;
    }
    ul {
      li {
        color: #888888;
        padding: 0.2rem 0;
      }
    }
  }
  .editProfile {
    border-radius: 0.6rem;
    text-transform: capitalize;
    margin: 0.3rem 0;
    &.asign {
      background: #e25858;
    }
    a {
      color: #fff;
    }
  }
  a {
    position: relative;
    &.active {
      color: #2bc78c;
      &:after {
        position: absolute;
        width: 100%;
        content: "";
        background-color: #2bc78c;
        bottom: 0;
        left: 0;
        height: 0.1rem;
      }
    }
  }
`;

export default LeadMainInfo;
