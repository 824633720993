import axios from "axios";
import swal from "sweetalert";
import { PostLeadUrl } from "../../API/Api";
import { SignOut } from "./authActions";
import { POST_LEADS } from "./type";

const addLeadActions = (data) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    await axios
      .post(`${PostLeadUrl}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      })
      .then((res) => {
        dispatch({
          type: POST_LEADS,
          leadListData: res.data,
          massage: res.data.messages,
          submit: true,
          // {state === 201 ? && navigate : ""}
        });
        if (res.status === 201) {
          // window.location.reload(false);
          dispatch({
            type: "POST_LEADS_SUCSESS",
            status: res.status,
          });
        }
      })
      .catch((err) => {
        const errors = err.response.data.error;
        console.log("errors", errors);
        swal({
          title: `Error`,
          text: `${JSON.stringify(errors)}`,
          icon: "warning",
          button: "OK",
        });
        if (err.response.data.error) {
          // const error = err.response.data.error;
          dispatch({
            type: "POST_LEAD_ERROR",
            error: err.response.data.error,
          });
          if (err?.response?.status === 401) {
            dispatch(SignOut());
          }
        }
      });
  };
};

export default addLeadActions;
