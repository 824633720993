import {
  PATCH_USER,
  GET_MAIN_USER,
  GET_SINGLE_MEMBER,
  SHOW_LOADING,
  HIDE_LOADING,
  GET_USER_ROLS,
} from "../Actions/type";

const initialState = {
  userData: {},
  getMainUser: {},
  teamMember: {},
  rols: {},
  isLoading: true,
};

const EditUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case PATCH_USER:
      return {
        ...state,
        userData: action.userData,
      };
    case GET_MAIN_USER:
      return {
        ...state,
        getMainUser: action.mainUser,
      };
    case GET_SINGLE_MEMBER:
      return {
        ...state,
        teamMember: action.payload.teamMember,
      };
    case GET_USER_ROLS:
      return {
        ...state,
        rols: action.payload.rols,
      };
    case SHOW_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case HIDE_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return { ...state };
  }
};

export default EditUserReducer;
