import styled from "styled-components";
import pdfBack from "../../../assets/p3.png";
import FooterPDF from "./FooterPDF";

const PageFIvePdf = () => {
  return (
    <PageFiveStyle>
      <h2>THE SET UP & DELIVERY PROCESS</h2>
      <div className="imageContainer">
        <img src={pdfBack} alt="name" />
      </div>
      <FooterPDF />
    </PageFiveStyle>
  );
};

const PageFiveStyle = styled.div`
  h2 {
    text-align: center;
    margin: 2.5rem 0;
    color: #113474;
  }
  .imageContainer {
    width: 100%;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
`;
export default PageFIvePdf;
