import styled from "styled-components";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
const BigSection = styled.div`
  background-color: #2e2e2e;
  margin-top: 100px;
  text-align: center;
  padding: 20px 0;
`;
const Header = styled.p`
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 30px;
`;
const MyButton = styled.button`
  padding: 6px 50px;
  font-size: 20px;
  border: 1px solid white;
  border-radius: 10px;
  background: transparent;
  color: white;
  cursor: pointer;
  transition: 1s;
  margin: 10px 20px;

  &:hover {
    color: black;
    background-color: white;
  }
`;
const Break = () => {
  return (
    <BigSection>
      <Grid
        container
        spacing={0}
        col={12}
        direction="row"
        justifyContent="center"
        alignItems="stretch"
      >
        <Grid item lg={8} md={8} sm={8} xs={12}>
          <Header>Become an Authorized Partner with us today!</Header>
          <a href="https://www.technician.boxbyld.tech/register">
            {" "}
            <MyButton> Install Partner</MyButton>{" "}
          </a>

          <Link to="/sale-form">
            {" "}
            <MyButton>Sales Partner</MyButton>
          </Link>
        </Grid>
      </Grid>
    </BigSection>
  );
};
export default Break;
