import React from "react";
import styled from "styled-components";
import pdfImg from "../../../assets/pdf.jpg";
import FooterPDF from "./FooterPDF";
const PageOnePdf = ({ firstName, lastName, email, location, nameBy }) => {
  return (
    <>
      <PageOneStyled>
        <div className="imageHeader">
          <img src={pdfImg} alt="pdf" />
        </div>
        <div className="mainData">
          <h3>PRESENTED TO:</h3>
          <p className="text">
            {firstName} <span> {lastName}</span>
          </p>
          <p className="text">{location}</p>
          <p className="text">{email}</p>
          <h3>PRESENTED BY:</h3>
          <p className="text">{nameBy}</p>
          <p className="dec">ALL PREMIUM CONSTRUCTION, INC.</p>
        </div>
        <FooterPDF />
      </PageOneStyled>
    </>
  );
};

const PageOneStyled = styled.section`
  min-height: 100vh;

  .imageHeader {
    height: 40vh;
    width: 100%;
    background: #111;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }
  .mainData {
    text-align: center;
    padding: 2rem 0;
    h3 {
      color: #364178;
      margin: 0.5rem 0;
      font-size: 2rem;
      font-weight: normal;
    }
    .text {
      font-size: 20px;
      color: #112542b9;
    }
    .dec {
      color: #364178;
      margin: 1.5rem 0 5rem 0;
    }
  }
`;
export default PageOnePdf;
