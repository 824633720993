import { useSelector } from "react-redux";
import Productivity from "../../components/Productivity/Productivity";

import { RightStyle } from "./RightStyled";
import RoyalitiesCharts from "./RoyalitiesCharts/RoyalitiesCharts";
import UserCard from "./UserCard";

const Right = () => {
  const { getMainUser } = useSelector((state) => state.editUser);
  const SalseUser = getMainUser;
  // console.log("getMainUser",getMainUser)

  return (
    <>
      {" "}
      <RightStyle>
        <Productivity />
        <RoyalitiesCharts SalseUser={SalseUser} />
        <UserCard SalseUser={SalseUser} />

      </RightStyle>
    </>
  );
};

export default Right;
