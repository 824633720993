import { ThumbnailCard } from "../RightStyled";
import recruitment from "../../../assets/recruitment.png";

const LeadsCount = ({ SalseUser }) => {
  return (
    <>
      <ThumbnailCard>
        <div className="icon leads">
          <img src={recruitment} alt="achivements" />
        </div>
        <div className="data">
          <div className="title">Leads </div>
          <div className="number">{SalseUser?.leads_count}</div>
        </div>
      </ThumbnailCard>
    </>
  );
};

export default LeadsCount;
