import axios from "axios";
import swal from "sweetalert";
import { APR_URL, MAIN_URL, SUNLIGHT_CLAC_URL } from "../../API/Api";
import {
  GET_APR_CALC,
  GET_SUNLIGHT_CALC,
  POST_CALCOULATOR,
  POST_CALC_FINANCE,
} from "./type";
import { ShowLoading, HideLoading } from "./loadingAction";
import { SignOut } from "./authActions";

export const CalcoulateActions = (data) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    dispatch(ShowLoading());
    await axios
      .post(`${MAIN_URL}/api/v1/sunlight/calculator`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        dispatch({
          type: POST_CALCOULATOR,
          data: res.data,
        });
        dispatch(HideLoading());
      })
      .catch((err) => {
        console.log(err.data);
        dispatch(HideLoading());
        swal({
          title: " error ",
          text: " There is a problem in calc  ",
          icon: "error",
          button: "OK",
        });
      });
  };
};

export const officalCalc = (data) => {
  return async (dispatch) => {
    await dispatch({
      type: "CALC_OFFICAL",
      info: data,
    });
  };
};

export const officalCalcEnfin = (data) => {
  return async (dispatch) => {
    await dispatch({
      type: "Enfin_CALC_OFFICAL",
      enfin: data,
    });
  };
};

export const TotalAmount = (data) => {
  return async (dispatch) => {
    await dispatch({
      type: "TOTAL_AMOUNT",
      totalAmount: data,
    });
  };
};

export const RunPrequalCalc = (id, data) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    await axios({
      method: "POST",
      baseURL: `${MAIN_URL}/api/v1/sunlight/add-project-applicant-quote/${id}`,
      data: data,
      headers: headers,
    })
      .then((res) => {
        console.log(res.data);

        dispatch({
          type: POST_CALC_FINANCE,
          financeCalc: res.data,
        });
      })
      .catch((err) => {
        console.log(err.data);
      });
  };
};

////////////////// get the APR API //////////////
export const loadAprCalc = (id) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    await axios({
      method: "get",
      baseURL: `${APR_URL}/${id}`,
      headers: headers,
    })
      .then((res) => {
        console.log(res.data);

        dispatch({
          type: GET_APR_CALC,
          AprCalc: res.data,
        });
      })
      .catch((err) => {
        console.log(err.data);
        if (err?.response?.status === 401) {
          dispatch(SignOut());
        }
      });
  };
};

export const loadSunLightCalc = (state) => {
  return async (dispatch) => {
    dispatch({
      type: "IS_CALC",
    });
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    await axios({
      method: "get",
      baseURL: `${SUNLIGHT_CLAC_URL}`,
      headers: headers,
      params: {
        state_name: state,
      },
    })
      .then((res) => {
        console.log(res.data);
        dispatch({
          type: GET_SUNLIGHT_CALC,
          sunlightCalc: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err.data);
        if (err?.response?.status === 401) {
          dispatch(SignOut());
        }
      });
  };
};

export const loadingToggleAction = (status) => {
  return async (dispatch) => {
    dispatch({
      type: "TOGGLE_SUBMIT_CALC",
      payload: status,
    });
  };
};
