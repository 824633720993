// import ChooseUs from "../../components/website/ChooseUs";
// import FAQComponents from "../../components/website/FAQComponents";
// import Footer from "../../components/website/Footer";
// import Header from "../../components/website/Header";
// import Massage from "../../components/website/Massage";
// import Offer from "../../components/website/Offer";
// import Ready from "../../components/website/Ready";
// import Tasks from "../../components/website/Tasks";
// import UxStructure from "../../components/website/UxStructure";

// import Header from "../landing Page/components/header/Header";
// import Discover from "../landing Page/components/Discover/Discover";
// import WhyChooseUS from "../landing Page/components/whyChooseUs/whychooseUs";
// import Footer from "../landing Page/components/Footer/Footer";
// import PerfectUxStructure from "../landing Page/components/PerfectUxStructure/PerfectUxStructure";
// import ReadyToGetStarted from "../landing Page/components/ReadyToGetStarted/ReadyToGetStarted";
// import DoYourTask from "../landing Page/components/Do your Task/DoYourTask";
// import TestMonials from "../landing Page/components/TestMonials/TestMonials";
// import Offers from "../landing Page/components/Offers/Offers";
// import DropAmessage from "../landing Page/components/Drop a message/DropAmessage";
// import MyLinks from "../landing Page/components/Links/MyLinks";

import NewLandingPage from "../NewLandingPage/NewLandingPage";
const HomePage = () => {
  return (
    <>
      <NewLandingPage />

      {/* <Header /> */}
      {/* <Discover />
      <WhyChooseUS />
      <PerfectUxStructure />
      <ReadyToGetStarted />
      <DoYourTask />
      <TestMonials />
      <Offers />
      <DropAmessage />
      <MyLinks />
      <Footer /> */}

      {/* <Header />
      <ChooseUs />
      <UxStructure />
      <Ready />
      <Tasks />
      <Offer />
      <FAQComponents />
      <Massage />
      <Footer /> */}
    </>
  );
};

export default HomePage;
