import axios from "axios";
import React from "react";
import { MAIN_URL } from "../../API/Api";
import qs from "qs";
import swal from "sweetalert";
import { ShowLoading, HideLoading } from "./loadingAction";
import { Finance_goodleap } from "../../API/Api";
import Swal from "sweetalert2";
// APIS
const GOOD_LEAP_GET_CHANNELS = `${MAIN_URL}/api/goodleap/channels`;
const GOOD_LEAP_GET_PROMOTIONS = `${MAIN_URL}/api/v1/goodleap/promotions`;
const GOOD_LEAP_GET_CATEGORIES = `${MAIN_URL}/api/v1/goodleap/categories`;
const GOOD_LEAP_GET_OFFERS = `${MAIN_URL}/api/v1/goodleap/offers`;
const GOOD_LEAP_GET_PAYMENTS = `${MAIN_URL}/api/v1/goodleap/payments`;
// const MAIN_URL = "https://alpha.boxbyld.tech/api/v1/goodleap";
// const GOOD_LEAP_GET_CHANNELS = `${MAIN_URL}/channels`;
// const GOOD_LEAP_GET_PROMOTIONS = `${MAIN_URL}/promotions`;
// const GOOD_LEAP_GET_CATEGORIES = `${MAIN_URL}/categories`;
// const GOOD_LEAP_GET_OFFERS = `${MAIN_URL}/offers`;
// const GOOD_LEAP_GET_PAYMENTS = `${MAIN_URL}/payments`;

// TYPES
export const GOOD_LEAP_GET_ALL_CHANNELS = "GOOD_LEAP_GET_ALL_CHANNELS";
export const GOOD_LEAP_GET_ALL_PROMOTIONS = "GOOD_LEAP_GET_ALL_PROMOTIONS";
export const GOOD_LEAP_GET_ALL_CATEGORIES = "GOOD_LEAP_GET_ALL_CATEGORIES";
export const GOOD_LEAP_GET_ALL_OFFERS = "GOOD_LEAP_GET_ALL_OFFERS";
export const GOOD_LEAP_PAYMENT = "GOOD_LEAP_PAYMENT";
export const GOOD_LEAP_CALC = "GOOD_LEAP_CALC";
export const FINANCE_GOODLEAP = "FINANCE_GOODLEAP";

// GET CHANNEL
// export const getChannels = (data) => {
//   const token = localStorage.getItem("token");
//   return async (dispatch) => {
//     try {
//       const res = await axios.get(GOOD_LEAP_GET_CHANNELS, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//           Accept: "application/json",
//           "X-Requested-With": "XMLHttpRequest",
//         },
//       });
//       dispatch({
//         type: GOOD_LEAP_GET_ALL_CHANNELS,
//         payload: res.data,
//       });
//     } catch (e) {
//       console.log("Good Leap Get Channels ", e);
//     }
//   };
// };
// Get Channels two
export const getChannels = (firstParm) => async (dispatch) => {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  dispatch(ShowLoading());

  await axios({
    method: "GET",
    baseURL: `${GOOD_LEAP_GET_CHANNELS}`,
    headers: headers,
    params: {
      states: firstParm,
    },
  })
    .then((res) => {
      dispatch(HideLoading());
      if (res.data.length == 0) {
        swal({
          title: "GoodLeap is not available in this State",
          // text: "You clicked the button!",
          icon: "error",
          button: "OK",
        });
      }
      dispatch({
        type: GOOD_LEAP_GET_ALL_CHANNELS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(HideLoading());

      console.log(err.data);
    });
};
// GET PROMOTION
export const getPromotions = (data) => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    dispatch(ShowLoading());
    try {
      const res = await axios.get(GOOD_LEAP_GET_PROMOTIONS, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      });
      dispatch(HideLoading());
      dispatch({
        type: GOOD_LEAP_GET_ALL_PROMOTIONS,
        payload: res.data,
      });
    } catch (e) {
      dispatch(HideLoading());
      console.log("Good Leap Get Channels ", e);
    }
  };
};
//  GET CATEGORIES
export const getCategories = () => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    dispatch(ShowLoading());

    try {
      const res = await axios.get(GOOD_LEAP_GET_CATEGORIES, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      });
      dispatch({
        type: GOOD_LEAP_GET_ALL_CATEGORIES,
        payload: res.data,
      });
      dispatch(HideLoading());
    } catch (e) {
      console.log("Good Leap Get Channels ", e);
    }
  };
};
//   Get Offeres
// export const getOffers = (data, dataTwo) => {
//   const token = localStorage.getItem("token");
//   return async (dispatch) => {
//     try {
//       const res = await axios.get(
//         // GOOD_LEAP_GET_OFFERS/`${data}`,
//         // &${dataTwo}
//         `${GOOD_LEAP_GET_OFFERS}?${data}`,

//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             Accept: "application/json",
//             "X-Requested-With": "XMLHttpRequest",
//           },
//         }
//       );
//       dispatch({
//         type: GOOD_LEAP_GET_ALL_OFFERS,
//         payload: res.data.data,
//       });
//     } catch (e) {
//       console.log("Good Leap Get Channels ", e);
//     }
//   };
// };
// Get offers two
export const getOffers =
  (firstParm, secondParam, thirdParams, fourthParams) => async (dispatch) => {
    const token = localStorage.getItem("token");
    dispatch(ShowLoading());
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    await axios({
      method: "GET",
      baseURL: `${GOOD_LEAP_GET_OFFERS}`,
      headers: headers,
      params: {
        channels: firstParm,
        categoryId: secondParam,
        promotionIds: thirdParams,
        states: fourthParams,
      },
      paramsSerializer: function (params) {
        // return qs.stringify(params, { arrayFormat: 'comma' })
        return qs.stringify(params, { arrayFormat: "indices" });
      },
    })
      .then((res) => {
        dispatch(HideLoading());
        dispatch({
          type: GOOD_LEAP_GET_ALL_OFFERS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch(HideLoading());
        console.log(err.data);
      });
  };
// Get Payments two
export const getPayments =
  (firstParm, secondParam, thirdParam, fourthParam, fifthParam) =>
  async (dispatch) => {
    const token = localStorage.getItem("token");
    dispatch(ShowLoading());
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    await axios({
      method: "GET",
      baseURL: `${GOOD_LEAP_GET_PAYMENTS}`,
      headers: headers,
      params: {
        offerIds: firstParm,
        amount: secondParam,
        promotionIds: thirdParam,
        extraPaydownAmount: fourthParam,
        extraPaydownPercent: fifthParam,
      },
      // paramsSerializer: function (params) {
      //   // return qs.stringify(params, { arrayFormat: 'comma' })
      //   return qs.stringify(params,{ arrayFormat: 'indices' })
      // },
    })
      .then((res) => {
        dispatch({
          type: GOOD_LEAP_PAYMENT,
          payload: res.data,
        });
        dispatch(HideLoading());
      })
      .catch((err) => {
        dispatch(HideLoading());
        let messages;
        const message = err.response.data;
        for (const key of Object.keys(message)) {
          messages = `${(key, message[key])}`;
        }
        console.log("messages", messages);
        console.log("Errrrrrrrrrrr", messages);
        swal({
          title: "Error",
          text: `${messages}`,
          icon: "warning",
          button: true,
          dangerMode: true,
        });
      });
  };
// Finace
export const goodleapFinance = (data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch(ShowLoading());
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  await axios({
    method: "POST",
    baseURL: `${Finance_goodleap}/${data}`,
    headers: headers,
    data: data,
  })
    .then((res) => {
      dispatch({
        type: FINANCE_GOODLEAP,
        payload: res.data,
      });
      dispatch(HideLoading());
      Swal.fire({
        icon: "success",
        title: "congratulations",
        // timer:2000,
        text: "You have finansed the loan successfully",
      });
    })
    .catch((err) => {
      dispatch(HideLoading());
      const message = err.response.data;
      console.log("message", message);
      swal({
        title: "Error",
        text: `${message?.error || message?.message}`,
        icon: "warning",
        button: true,
        dangerMode: true,
      });
    });
};

// Selected Data
export const goodLeapCalc = (data) => {
  return async (dispatch) => {
    await dispatch({
      type: "GOOD_LEAP_CALC",
      allGoodLeap: data,
    });
  };
};
