import {
  GET_SALES_COMPANIES,
  NEW_INSTALLER,
  ADD_SALES_AGENT,
  GET_SINGLE_INSTALLER_DATA,
} from "../Actions/addInstallerAction";
import { START_LOADING } from "../Actions/addInstallerAction";
import { END_LOADING } from "../Actions/addInstallerAction";
import { ALL_INSTALLER_STATES } from "../Actions/addInstallerAction";
import {
  ALL_INSTALLER_LICENSE_TYPES,
  GET_ALL_INSTALLERS_DATA,
} from "../Actions/addInstallerAction";

const inittialState = {
  installerData: "",
  isLoading: false,
  allStates: "",
  allLicnsesType: "",
  salesCompanies: [],
  salesError: {},
  installerData: {},
  allInstallers: [],
  singleINstallerData: {},
};

const installerReducer = (state = inittialState, action) => {
  switch (action.type) {
    case NEW_INSTALLER:
      return {
        ...state,
        installerData: action.payload,
      };
    case START_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case END_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case ALL_INSTALLER_STATES:
      return {
        ...state,
        allStates: action.payload,
      };
    case ALL_INSTALLER_LICENSE_TYPES:
      return {
        ...state,
        allLicnsesType: action.payload,
      };
    case ADD_SALES_AGENT:
      return {
        ...state,
        salesError: action.payload,
      };
    case GET_SALES_COMPANIES:
      return {
        ...state,
        salesCompanies: action.payload,
      };
    case GET_ALL_INSTALLERS_DATA:
      return {
        ...state,
        allInstallers: action.payload,
      };
    case GET_SINGLE_INSTALLER_DATA:
      return {
        ...state,
        singleINstallerData: action.payload,
      };
    default:
      return { ...state };
  }
};
export default installerReducer;
