import React from "react";
import { MainTabsStyled } from "../../../Left/Routs/goals/style";
import { TabContext, TabList } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { NavLink, Outlet } from "react-router-dom";

const License = () => {
  return (
    <div>
      <MainTabsStyled>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                // onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                className="headerNavLink "
              >
                <NavLink to="get">
                  {" "}
                  <Tab label="My Licenses" />
                </NavLink>
                <NavLink to="create">
                  {" "}
                  <Tab label="Create License" />
                </NavLink>
              </TabList>
            </Box>

            <Outlet></Outlet>
          </TabContext>
        </Box>
      </MainTabsStyled>
    </div>
  );
};

export default License;
