import axios from "axios";
import swal from "sweetalert";
import {
  CREATE_PROJECT_URL,
  GET_PROJECJS_URL,
  POST_ADD_QUOTE_URL,
  POST_PULL_STATUS_URL,
  POST_SUBMIT_CREDIT_URL,
  PREQUAL_URL,
} from "../../API/Api";
import {
  ADD_QUOTE,
  CREATE_PROJECT,
  GET_PREQUAL_PROJECT,
  PULL_STATUS,
  SEND_PREQUAL,
  SUBMIT_CREDIT,
} from "./type";

export const SunlightAction = (id, data) => {
  return async (dispatch) => {
    dispatch(ShowLoadingSun());
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    await axios({
      method: "POST",
      baseURL: `${CREATE_PROJECT_URL}/${id}`,
      headers: headers,
      data: data,
    })
      .then((res) => {
        dispatch(HideLoadingSun());
        dispatch({
          type: CREATE_PROJECT,
          projects: [...res.data],
        });
        console.log(res, ".....");
        if (res.status === 201) {
          dispatch({
            type: "POST_PROJECT_SUCSESS",
            statusProject: res.status,
          });
        }
      })
      .catch((err) => {
        dispatch(HideLoadingSun());
        // console.log(err.data);
        // console.log(err);
        swal({
          title: " error ",
          text: " There is a problem in sun light ",
          icon: "error",
          button: "OK",
        });
      });
  };
};
/////////////////////////////////
export const LoadProjectAction = (id) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    await axios({
      method: "GET",
      baseURL: `${GET_PROJECJS_URL}/${id}`,
      headers: headers,
    })
      .then((res) => {
        console.log(res.data);
        dispatch({
          type: GET_PREQUAL_PROJECT,
          allProjects: [...res.data.data],
        });
      })
      .catch((err) => {
        console.log(err.data);

        console.log(err);
        if (err.data === 500) {
          return alert("samesing went wrong");
        }
      });
  };
};

export const getProjectStatus = (id) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    await axios({
      method: "POST",
      baseURL: `${GET_PROJECJS_URL}/${id}`,
      headers: headers,
    })
      .then((res) => {
        console.log(res.data);
        dispatch({
          type: GET_PREQUAL_PROJECT,
          allProjects: [...res.data.data],
        });
      })
      .catch((err) => {
        // console.log(err.data);

        // console.log(err);
        // if (err.data === 500) {
        //   return alert("samesing went wrong");
        // }
        swal({
          title: " error ",
          text: " samesing went wrong ",
          icon: "error",
          button: "OK",
        });
      });
  };
};

///////////////////////////////////

/*--- with new asynk */

export const SendPrequal = (id) => async (dispatch) => {
  dispatch(ShowLoadingSun());
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  await axios({
    method: "POST",
    baseURL: `${PREQUAL_URL}/${id}`,
    headers: headers,
  })
    .then((res) => {
      swal({
        title: " succsfully ",
        text: "Sent to Prequal Successfully",
        icon: "success",
        button: "OK",
      });
      console.log("Test Success", res);
      dispatch({
        type: SEND_PREQUAL,
        prequal: res.data,
      });
      dispatch(HideLoadingSun());
    })
    .catch((err) => {
      console.log("Test Error", err);
      swal({
        title: " error Test",
        text: `${err?.response?.data}`,
        icon: "error",
        button: "OK",
      });
      dispatch(HideLoadingSun());
    });
};

// export const SendPrequal = (id) => async (dispatch) => {
//   dispatch(ShowLoadingSun());
//   const token = localStorage.getItem("token");
//   const headers = {
//     Authorization: `Bearer ${token}`,
//     Accept: "application/json",
//   };
//   await axios({
//     method: "POST",
//     baseURL: `${PREQUAL_URL}/${id}`,
//     headers: headers,
//   })
//     .then((res) => {
//       console.log(res.data);
//       swal({
//         title: " succsfully ",
//         text: "Sent to Prequal Successfully",
//         icon: "success",
//         button: "OK",
//       });
//       dispatch({
//         type: SEND_PREQUAL,
//         prequal: [...res.data],
//       });
//       dispatch(HideLoadingSun());
//     })
//     .catch((err) => {
//       dispatch(HideLoadingSun());
//       console.log(err.data);
//       console.log(err);
//       if (err.data === 500) {
//         return alert("samesing went wrong");
//       }
//     });
// };
///////////////////////////////////

export const SendPullSataus = (id) => {
  return async (dispatch) => {
    dispatch(ShowLoadingSun());
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    await axios({
      method: "GET",
      baseURL: `${POST_PULL_STATUS_URL}/${id}`,
      headers: headers,
    })
      .then((res) => {
        console.log(res.data);

        dispatch({
          type: PULL_STATUS,
          pullStatus: [...res.data.projects],
        });
        dispatch(HideLoadingSun());
      })
      .catch((err) => {
        console.log(err.data);
        dispatch(HideLoadingSun());
        // console.log(err);
        // if (err.data === 500) {
        //   return alert("samesing went wrong");
        // }
        swal({
          title: " error ",
          text: " There is a problem ",
          icon: "error",
          button: "OK",
        });
      });
  };
};

/* -----send a submit cretied ----- */

export const SubmitCreditAction = (id) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    await axios({
      method: "POST",
      baseURL: `${POST_SUBMIT_CREDIT_URL}/${id}`,
      headers: headers,
    })
      .then((res) => {
        console.log(res.data);
        swal({
          title: " succsfully ",
          text: "Submit Credit Successfully",
          icon: "success",
          button: "OK",
        });
        dispatch({
          type: SUBMIT_CREDIT,
          submitCredit: [...res.data],
        });
      })
      .catch((err) => {
        console.log(err.data);
        // swal({
        //   title: " error ",
        //   text: " There is a problem when Submit Credit",
        //   icon: "error",
        //   button: "OK",
        // });
      });
  };
};
/*--------- post add qoute -----------*/
export const AddQuoteAction = (id, data) => {
  return async (dispatch) => {
    dispatch(ShowLoadingSun());
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    await axios({
      method: "POST",
      baseURL: `${POST_ADD_QUOTE_URL}/${id}`,
      headers: headers,
      data: data,
    })
      .then((res) => {
        dispatch(HideLoadingSun());
        swal({
          title: " succsfully ",
          text: "  Credit Successfully",
          icon: "success",
          button: "OK",
        });
        console.log("sucess", res.data);
        dispatch({
          type: ADD_QUOTE,
          addQuote: [...res.data],
        });
      })
      .catch((err) => {
        dispatch(HideLoadingSun());
        console.log("Fail", err.data);
        // swal({
        //   title: " error ",
        //   text: " There is a problem ",
        //   icon: "error",
        //   button: "OK",
        // });
      });
  };
};

/******** loader when submit ****** */

export const ShowLoadingSun = () => (dispatch) => {
  dispatch({
    type: "SHOW_LOADING_SUNLIGHT",
  });
};
export const HideLoadingSun = () => (dispatch) => {
  dispatch({
    type: "HIDE_LOADING_SUNLIGHT",
  });
};
