import { Grid } from "@mui/material";
import { Outlet } from "react-router-dom";
import Header from "../../components/Header/Header";
import ListOfMessages from "./ListOfMessages";

const AllMessages = () => {
  return (
    <>
      <Header />
      <Grid container>
        <Grid item xs={3}>
          <ListOfMessages />
        </Grid>
        <Grid item xs={9}>
          <Outlet />
        </Grid>
      </Grid>
    </>
  );
};

export default AllMessages;
