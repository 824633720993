import { Button, Grid } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Link, Outlet } from "react-router-dom";
import styled from "styled-components";
import { LoadProjectAction } from "../../../Redux/Actions/sunlightActions";
import ProjectsList from "./ProjectsList";

const Finance = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const LeadId = location.pathname.split("/")[2];
  React.useEffect(() => {
    dispatch(LoadProjectAction(LeadId));
  }, []);

  const { allProjects } = useSelector((state) => state.sunlight);

  const { singlead } = useSelector((state) => state.leads);

  const sunligtSolutions = singlead?.opportunities.filter(
    (e) => e.financial_institution === "sunlight"
  )||[];
  const goodleapSolutions = singlead?.opportunities.filter(
    (e) => e.financial_institution === "goodleap"
  )||[];

  React.useEffect(() => {
    if (sunligtSolutions?.length > 0 && goodleapSolutions?.length < 1)
      navigate("finance-sunlight");
  }, []);

  React.useEffect(() => {
    if (sunligtSolutions?.length < 1 && goodleapSolutions?.length > 0)
      navigate("finance-goodleap");
  }, []);

  return (
    <BigSection>
      <Grid container columns={18} alignItems="center" justifyContent="center">
        <Grid
          item
          container
          xs={18}
          alignItems="center"
          justifyContent="center"
          sx={{ margin: "2rem 1rem 0.5rem" }}
        >
          {sunligtSolutions?.length >0 && goodleapSolutions?.length > 0?<>
            <Grid item xs={9}>
              <NavLink to="finance-sunlight">
                {" "}
                <Mybutton >SunLight</Mybutton>
              </NavLink>
            </Grid>
            <Grid item xs={9}>
              <NavLink to="finance-goodleap">
                {" "}
                <Mybutton>GoodLeap</Mybutton>
              </NavLink>
            </Grid>
          </>:<></>}
          {/* {location?.pathname.includes("finance-goodleap") ? (
            <>
              <Grid item xs={18}>
                <NavLink to="finance-goodleap">
                  {" "}
                  <Mybutton> GoodLeap</Mybutton>
                </NavLink>
              </Grid>
            </>
          ) : (
            <></>
          )}
          {location?.pathname.includes("finance-sunlight") ? (
            <>
              <Grid item xs={18}>
                <NavLink to="finance-sunlight">
                  {" "}
                  <Mybutton>SunLight</Mybutton>
                </NavLink>
              </Grid>
            </>
          ) : (
            <></>
          )}
          {location?.pathname.includes("finance-sunlight") ||
          location?.pathname.includes("finance-goodleap") ? (
            <></>
          ) : (
            <>
              {allProjects.length > 0 ? <></> : <></>}
              <Grid item xs={9}>
                <NavLink to="finance-sunlight">
                  {" "}
                  <Mybutton>SunLight</Mybutton>
                </NavLink>
              </Grid>
              <Grid item xs={9}>
                <NavLink to="finance-goodleap">
                  {" "}
                  <Mybutton> GoodLeap</Mybutton>
                </NavLink>
              </Grid>
            </>
          )} */}

          {/* {allProjects.length > 0 && (
            <>
              <Grid item xs={18}>
                <NavLink to="finance-sunlight">
                  {" "}
                  <Mybutton disabled={true}>SunLight</Mybutton>
                </NavLink>
              </Grid>
            </>
          )} */}

          {/* {location?.pathname.includes("finance-goodleap") ? (
            <Grid item xs={18}>
              <NavLink to="finance-goodleap">
                {" "}
                <Mybutton> GoodLeap</Mybutton>
              </NavLink>
            </Grid>
          ) : location?.pathname.includes("finance-sunlight") ? (
            allProjects.length > 0 && (
              <>
                <Grid item xs={18}>
                  <NavLink to="finance-sunlight">
                    {" "}
                    <Mybutton disabled={true}>SunLight</Mybutton>
                  </NavLink>
                </Grid>
              </>
            )
          ) : null} */}

          {/* {location?.pathname.includes("finance-goodleap") ? (
            <Grid item xs={18}>
              <NavLink to="finance-goodleap">
                {" "}
                <Mybutton> GoodLeap</Mybutton>
              </NavLink>
            </Grid>
          
          ) : location?.pathname.includes("finance-sunlight") ? (
            <Grid item xs={18}>
              <NavLink to="finance-sunlight">
                {" "}
                <Mybutton disabled={true}>SunLight</Mybutton>
              </NavLink>
            </Grid>
          ) : null} */}
        </Grid>
      </Grid>

      <Outlet></Outlet>
    </BigSection>
  );
};
const BigSection = styled.div`
  a.active {
    button {
      color: #69a38e;
      border: 1px solid #69a38e;
    }
  }
`;
const Mybutton = styled(Button)`
  && {
    width: 100%;
    /* border:1px 1px 1px solid black; */
    color: #939393;
  }
`;
export default Finance;
