import {
  AccountCircle,
  ArrowBackIos,
  AttachMoney,
  Email,
  Language,
  LocalPhone,
  LocationOn,
  Map,
  MyLocation,
} from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const HeaderLead = ({ LeadChild }) => {
  const navigate = useNavigate();
  return (
    <>
      <UserData>
        <Button
          fullWidth
          variant="contained"
          mb={5}
          className="btn_back"
          startIcon={<ArrowBackIos />}
          onClick={() => navigate(-1)}
        >
          Back To User Profile
        </Button>
        <Grid container columns={18} spacing={3}>
          <Grid item lg={9} md={18} sm={18} xs={18}>
            <div className="data">
              <h4 className="key">
                <AccountCircle /> name
              </h4>
              <span className="value">
                {LeadChild?.first_name} {LeadChild?.last_name}
              </span>
            </div>
            <div className="data">
              <h4 className="key">
                <LocalPhone /> phone
              </h4>
              <span className="value">{LeadChild?.phone}</span>
            </div>
            <div className="data">
              <h4 className="key">
                <Email /> email
              </h4>
              <span className="value">{LeadChild?.email}</span>
            </div>
            <div className="data">
              <h4 className="key">
                <AttachMoney /> price
              </h4>
              <span className="value">${LeadChild?.price}</span>
            </div>
          </Grid>
          <Grid item lg={9} md={18} sm={18} xs={18}>
            <div className="data">
              <h4 className="key">
                <Language /> country
              </h4>
              <span className="value">{LeadChild?.country?.name}</span>
            </div>
            <div className="data">
              <h4 className="key">
                {" "}
                <LocationOn /> state
              </h4>
              <span className="value">{LeadChild?.state?.name}</span>
            </div>
            <div className="data">
              <h4 className="key">
                <MyLocation /> street
              </h4>
              <span className="value">{LeadChild?.street}</span>
            </div>

            <div className="data">
              <h4 className="key">
                <Map /> zip code
              </h4>
              <span className="value">{LeadChild?.zip_code}</span>
            </div>
          </Grid>
        </Grid>
      </UserData>
    </>
  );
};

const UserData = styled.div`
  .btn_back {
    border-radius: 0.8rem;
    margin: 1rem 0;
    padding: 0.5rem 0;
    a {
      color: #fff;
    }
  }
  .leadInfo {
    text-align: center;
    color: #0dbf7c55;
    margin-bottom: 1rem;
  }
  .data {
    display: flex;
    margin: 0.3rem;
    .key {
      text-transform: capitalize;
      margin-right: 0.5rem;
      min-width: 5rem;
      color: #003c4cd6;
      display: flex;
      align-items: center;
      svg {
        margin-right: 0.5rem;
      }
    }
    .value {
      color: #888;
    }
  }
`;
export default HeaderLead;
