import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./helper/index.js";
export const salesForm = createApi({
  reducerPath: "salesForm",
  baseQuery,
  endpoints: (builder) => ({
    getSalesCompanies: builder.query({
      query: ({ page, return_all }) => ({
        url: "sales-rep/businesses",
        method: "get",
      }),
    }),

    createSalesAccount: builder.mutation({
      query: (body) => ({
        url: "sales-rep/register",
        method: "POST",
        body,
      }),
    }),

    inviteSales: builder.mutation({
      query: (body) => ({
        url: "sales-invitation/send",
        method: "POST",
        body,
      }),
    }),
    inviteSalesByToken: builder.mutation({
      query: (body) => ({
        url: "sales-rep/invitation/register",
        method: "POST",
        body,
      }),
    }),

    getTokenInfo: builder.query({
      query: (id) => ({
        url: `sales-invitation/${id}`,
        method: "get",
      }),
    }),
  }),
});

export const {
  useGetSalesCompaniesQuery,
  useCreateSalesAccountMutation,
  useInviteSalesMutation,
  useGetTokenInfoQuery,
  useInviteSalesByTokenMutation,
} = salesForm;
