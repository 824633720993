import axios from "axios";
import {
  GET_PANEL_MODEL_URL,
  GET_ROOF_TYPES_URL,
  MAIN_URL,
  UTILITY_PROVIDER_URL,
} from "../../API/Api";
import { SignOut } from "./authActions";
import {
  GET_ADDS_ON,
  GET_PANEL_MODEL,
  GET_ROOF_COLORS,
  GET_ROOF_TYPES,
  GET_SUB_SERVICES,
  GET_UTILITY,
} from "./type";

export const loadSubServices = (id) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    await axios
      .get(`${MAIN_URL}/api/v1/services/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      })
      .then((res) => {
        dispatch({
          type: GET_SUB_SERVICES,
          sub_services: res.data.data.sub_services,
        });
      })
      .catch((err) => {
        console.log(err.data);
        if (err?.response?.status === 401) {
          dispatch(SignOut());
        }
      });
  };
};

export const loadAddsOn = (id) => {
  return async (dispatch) => {
    dispatch({
      type: "IS_ADD_ONS",
    });
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    await axios({
      method: "get",
      baseURL: `${MAIN_URL}/api/v1/services/${id}`,
      headers: headers,
    })
      .then((res) => {
        dispatch({
          type: GET_ADDS_ON,
          add_ons: res.data.data.add_ons,
        });
      })
      .catch((err) => {
        console.log(err.data);
      });
  };
};

export const loadUtility = (id) => {
  return async (dispatch) => {
    dispatch({
      type: "IS_UTILITY",
    });
    const token = localStorage.getItem("token");
    await axios
      .get(`${MAIN_URL}/api/v1/states/providers/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      })
      .then((res) => {
        dispatch({
          type: GET_UTILITY,
          utilities: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err.data);
      });
  };
};

//---------- sent email ------------

export const getPanleModels = () => async (dispatch) => {
  dispatch({
    type: "IS_PANLE",
  });
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  await axios({
    method: "GET",
    baseURL: `${GET_PANEL_MODEL_URL}`,
    headers: headers,
  })
    .then((res) => {
      dispatch({
        type: GET_PANEL_MODEL,
        payload: {
          panels: res.data.data,
        },
      });
    })
    .catch((err) => {});
};

//-------------- GET ROOF TYPES -------------//

export const getRoofTypsAction = () => async (dispatch) => {
  dispatch({
    type: "IS_ROOF_TYPE",
  });
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  await axios({
    method: "GET",
    baseURL: `${GET_ROOF_TYPES_URL}`,
    headers: headers,
  })
    .then((res) => {
      dispatch({
        type: GET_ROOF_TYPES,
        payload: {
          roofTyps: res.data.data,
        },
      });
    })
    .catch((err) => {});
};

// --------- GET ROOFING COLORS -------------//
export const getColorRoofing = (id) => async (dispatch) => {
  dispatch({
    type: "IS_ROOF_COLOR",
  });
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  await axios({
    method: "GET",
    baseURL: `${MAIN_URL}/api/v1/roofing-proposals/material/${id}/colors`,
    headers: headers,
  })
    .then((res) => {
      dispatch({
        type: GET_ROOF_COLORS,
        payload: {
          colors: res.data.data,
        },
      });
    })
    .catch((err) => {});
};
