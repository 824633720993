"use client";
import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import FirstForm from "./firstForm";
import SecondForm from "./secondForm";
import ThirdForm from "./thirdForm";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import MyStepper from "./steppr";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { BigSection } from "./style";
import {
  adderNames,
  batteriesAdders,
  electricalAdders,
  generatorsAdders,
  hvacsAdders,
  roofingAdders,
} from "./thirdForm/staticData";
import Swal from "sweetalert2";
import { useCreateSalesAccountMutation } from "../../../../../../Redux/toolKit/salesForm";
import {
  useEditSalesAddersMutation,
  useGetSalesAddersQuery,
} from "../../../../../../Redux/toolKit/salesAdders";

const steps = [
  {
    id: 0,
    main: "Personal data",
    sub: "Please provide your name and email",
  },
  {
    id: 1,
    main: "Business info",
    sub: "A few details about your company",
  },
  {
    id: 2,
    main: "Adders",
    sub: "Put your adders prices (in case you are company)",
  },
];
const SUPPORTED_FORMATS = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/webP ",
  "video/webm",
  "video/mp4",
  "image/svg",
  "video/x-ms-wmv",
  "video/wmv",
  "image/svg+xml",
];

const FormContainer = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skip, setSkip] = useState(0);
  const [createAccount, { isLoading, isError, isSuccess }] =
    useEditSalesAddersMutation();
  const { data: dataAdders, isLoading: addersLoading } =
    useGetSalesAddersQuery("");

  let allAdders = [
    ...roofingAdders,
    ...batteriesAdders,
    ...generatorsAdders,
    ...hvacsAdders,
    ...electricalAdders,
  ];

  const validationSchema = [
    //validation for step1
    yup.object().shape({
      email: yup
        .string("Enter your email")
        .email("Enter a valid email")
        .required("Email is required"),
      referrer: yup.string("Enter your email").email("Enter a valid email"),
      phone: yup
        .string("Enter your phone")
        .required("phone is required")
        .min(10, "Enter valid numbers"),
      password: yup
        .string("Enter your password")
        .required("Password is required")
        .min(8, "Password should be of minimum 8 characters length"),
      password_confirmation: yup
        .string("Enter your password confirm")
        .required("Password Confirmation is required")
        .oneOf([yup.ref("password"), null], "Passwords must match")
        .min(8, " should be of minimum 8 characters length"),
      name: yup
        .string("Enter your  Name")
        .min(3, "Name Should be more than 3 letters")
        .max(25, "Name Should be less than 21 letters")
        .required("Name is required"),
    }),
    //validation for step2
    yup.object({
      business_type: yup
        .mixed()
        .oneOf(["individual", "company"])
        .required("Business type is required"),
      company_id: yup.string().when(["company_exist", "business_type"], {
        is: (company_exist, business_type) =>
          company_exist === 1 && business_type === "company",
        then: yup.string().required("Company ID is required"),
        otherwise: yup.string(), // You can specify additional validation if needed
      }),
      company_email: yup
        .string("Enter Your company_email")
        .when("company_exist", {
          is: (company_exist) => company_exist === "0",
          then: yup
            .string("company_email")
            .email("inValid Email")
            .required("company email Name is required")
            .min(2, "company email atleast has 2 letters")
            .max(127, "company email max letters is 127 letters"),
        }),
      company_address: yup
        .string("Enter Your company_address")
        .when("company_exist", {
          is: (company_exist) => company_exist === "0",
          then: yup
            .string("company_address")
            .required("company address Name is required")
            .min(2, "company address atleast has 5 letters")
            .max(127, "company address max letters is 150 letters"),
        }),
      business: yup.string("Enter Your Business Name").when("company_exist", {
        is: (company_exist) => company_exist === "0",
        then: yup
          .string("Enter Your Business name")
          .required("Business Name is required")
          .min(2, "Business atleast has 2 letters")
          .max(127, "Business max letters is 127 letters"),
      }),
      bank_name: yup.string("Enter Your bank name").when("company_exist", {
        is: (company_exist) => company_exist === "0",
        then: yup
          .string("Bank name")
          .required("Bank name is required")
          .min(2, "Bank name  atleast has 2 letters")
          .max(127, "Bank name  max letters is 127 letters"),
      }),
      routing: yup.string("Enter Your routing  Name").when("company_exist", {
        is: (company_exist) => company_exist === "0",
        then: yup
          .string("Routing ")
          .required("Routing  is required")
          .min(2, "Routing  atleast has 2 letters")
          .max(127, "Routing  max letters is 127 letters"),
      }),
      w9pdf: yup
        .mixed()
        .test("required", "w9pdf pdf is required", function (value) {
          const { business_type } = this.parent;
          return business_type !== "individual" || (value && value.size > 0);
        })
        .test("fileType", "Invalid file format, only PDF allowed", (value) => {
          if (!value) return true; // No file provided
          return value && value.type === "application/pdf";
        }),
      company_logo: yup
        .mixed()
        .test("required", "Company logo is required", function (value) {
          const { company_exist } = this.parent;
          if (company_exist === "0") {
            return value && value.size > 0;
          }
          return true;
        })
        .test("fileType", "Unsupported file format", (value) => {
          if (!value) return true; // If no file is provided, skip this test
          return SUPPORTED_FORMATS.includes(value.type);
        }),
    }),

    yup.object().shape({
      adders: yup.object().shape(
        adderNames.reduce((schema, item) => {
          schema[item.name] = yup.string().when("business_type", {
            is: "company",
            then:
              skip === "0"
                ? yup.string().required(`${item.label} is required`)
                : yup.string(),
            otherwise: yup.string(),
          });
          return schema;
        }, {})
      ),
    }),
  ];
  const handleNext = async () => {
    const isStepValid = await trigger();
    if (isStepValid) setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const currentValidationSchema = validationSchema[2];

  const defaultAdders = adderNames.reduce((acc, { name }) => {
    acc[name] = 0;
    return acc;
  }, {});

  const methods = useForm({
    resolver: yupResolver(currentValidationSchema),
    shouldUnregister: false,
    mode: "all",
    defaultAdders: {
      adderes: defaultAdders,
    },
  });
  useEffect(() => {
    for (const property in dataAdders?.data) {
      // methods?.SetValue(`${property}`, dataAdders?.data[property]);
      methods?.setValue(`adders.${property}`, dataAdders?.data[property]);
    }
  }, [dataAdders]);
  const watchBusinessType = methods.watch("business_type");
  // console.log("asddddddddddddd", watchBusinessType);
  const skipMethod = methods.watch("skip");
  useEffect(() => {
    setSkip(skipMethod);
  }, [skipMethod]);

  const { handleSubmit, reset, trigger, formState } = methods;
  // console.log("formState", formState.errors);
  const createAccountForm = async (data) => {
    try {
      await createAccount(data.adders)?.unwrap();
      toast.success("Has been edited");
    } catch (e) {
      toast.error("something err");
    }
    console.log("data", data?.adders);
  };
  if (addersLoading) {
    return <h1>Loading...</h1>;
  }
  console.log("Errs", methods.formState.errors);
  return (
    <BigSection>
      <div className="first">
        <h1>Edit sales adders</h1>
        {/* <p>Simplifying your installations</p> */}
      </div>
      <div className="container">
        <Box sx={{ width: "100%" }}>
          {/* <MyStepper steps={steps} activeStep={activeStep} /> */}
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(createAccountForm)}>
              <>
                <Grid
                  container
                  columns={12}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  {/* {currentForm()} */}
                  <ThirdForm methods={methods} />
                </Grid>
                <div className="btns">
                  <button type={"submit"}>
                    {isLoading ? "Editing..." : "Edit"}
                  </button>
                </div>
              </>
            </form>
          </FormProvider>
        </Box>
      </div>
    </BigSection>
  );
};

export default FormContainer;
