import {LEAD_FILTER} from '../Actions/leadFilterAction'
import {LOADER_SEARCH_START} from '../Actions/leadFilterAction'
import {LOADER_SEARCH_END} from '../Actions/leadFilterAction'

const inittialState = {
    allFilteredLeads:"",
    isLoading:false
  };
  const FilteredLeads = (state = inittialState, action) => {
    switch (action.type) {
      case LEAD_FILTER:
        return {
          ...state,
          allFilteredLeads: action.payload,
        }
        case LOADER_SEARCH_START:
          return{
          ...state,
          isLoading:true
        } 
        case LOADER_SEARCH_END:
          return{
          ...state,
          isLoading:false
        } 
      default:
        return { ...state };
    }
  };
  export default FilteredLeads;
  