import { Button, Container, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const TraningVideos = () => {
  const navigate = useNavigate();
  return (
    <>
      <Container>
        <HeaderVideo>
          <Button variant="contained" onClick={() => navigate(-1)}>
            Back to Training
          </Button>
          <h4>videos</h4>
        </HeaderVideo>

        <Grid container columns={18} spacing={3}>
          <Grid item md={6} sm={9}>
            <VideoContainer>
              <iframe
                className="framVideo"
                src="https://www.youtube.com/embed/2JxdbbH7F3o"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </VideoContainer>
          </Grid>
          <Grid item md={6} sm={9}>
            <VideoContainer>
              <iframe
                className="framVideo"
                src="https://www.youtube.com/embed/65vtxN6fWWw"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </VideoContainer>
          </Grid>

          <Grid item md={6} sm={9}>
            <VideoContainer>
              <iframe
                className="framVideo"
                src="https://www.youtube.com/embed/GgLRffb76dg"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </VideoContainer>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

const VideoContainer = styled.div`
  .framVideo {
    width: 100%;
    min-height: 250px;
  }
`;

const HeaderVideo = styled.div`
  padding: 2rem 0;
  h4 {
    font-size: 2rem;
    text-align: center;
  }
`;
export default TraningVideos;
