import React from "react";
import FormContainer from "./components/form";

const EditAdders = () => {
  return (
    <div>
      <FormContainer />
    </div>
  );
};

export default EditAdders;
