import React, { useState } from "react";
import { useGetCoinsQuery } from "../../../../Redux/toolKit/coins";
import { Bigsection } from "./style";
import { Pagination, Stack } from "@mui/material";

const americanDate = (date) => {
  console.log("date", date);
  const newDate = new Date(date)?.toLocaleDateString("en-US", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  return newDate;
};
const americanTime = (date) => {
  console.log("date", date);
  const newDate = new Date(date)?.toLocaleTimeString("en-US", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  return newDate;
};
const History = () => {
  const [page, setPage] = useState();

  const { data, isLoading } = useGetCoinsQuery(page);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  console.log("data?.data?.transactions", data?.data?.transactions?.data);
  const mapping = () => {
    return data?.data?.transactions?.data?.map((item) => {
      return (
        <tr>
          <td>{item?.amount}</td>
          <td>{item?.balance}</td>
          <td>{item?.status}</td>
          <td>{item?.type}</td>
          <td>{item?.details}</td>
          <td>{americanTime(item?.crated_at)}</td>
        </tr>
      );
    });
  };
  console.log("page", page);
  if (isLoading) {
    return <h1>Loading...</h1>;
  }
  return (
    <Bigsection>
      <h3>Transactions history</h3>
      <table id="customers">
        <tr>
          <th>amount</th>
          <th>balance</th>
          <th>status</th>
          <th>type</th>
          <th>details</th>
          <th>date</th>
        </tr>
        {mapping()}
      </table>
      <div className="pagination">
        <Pagination
          count={data?.transactions?.total_pages}
          onChange={handleChangePage}
        />
      </div>
    </Bigsection>
  );
};

export default History;
