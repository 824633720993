import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { TotalAmount } from "../../../../../../Redux/Actions/CalculatorActions";
import {
  Adder,
  RoofingPrices,
} from "../../../../../../Redux/Actions/solutionsActions";

const CostEstemation = ({
  value,
  RoofJobsTypesObject,
  ExistingRoofingObject,
  NewRoofJobTypesObject,
  // colorObject,
  panelPrice,
  NumberOfPanls,
  AC,
  DC,
  AnnualPrduction,
  InvertType,
  IsTowStory,
  IsReverseTilt,
  IsNorthFaced,
  RoofMaterials,
  IsAttic,
  NumberOfAttics,
  IsOnlineMonitoring,
  JopType,
  IsTrenching,
  HowManyTrench,
  IsTrenchingConcret,
  HowManyConcret,
  IsStandAloneRoof,
  IsDiggingSlope,
  HowManyDigging,
  ExistingElectricalPanel,
  IsStucco,
  IsDeRate,
  ISMPU,
  // ISMPUMore3,
  NumberOfMPU,
  IsSubPanel,
  IsMeterSocket,
  IsEV,
  IsEVMLO,
  IsReRoofing,
  NumberOfReroofSquares,
  IsRoofRelay,
  IsReRoofShingle,
  IsReRoofTear,
  NumberOfLayers,
  IsReRoofPresidential,
  IsReRoofSpanish,
  IsReRoofFlat,
  IsReRoofReDecking,
  IsReRoofFascia,
  NumberOfFeet,
  IsRafterExchange,
  IsAirVet,
  AirVent,
  NumberOfVent,
  IsDoubleHand,
  IsSmoke,
  NumberOfSmoke,
  IsWarrantyExtend,
  IsHVAC,
  HvacLocation,
  HvacHorsPower,
  NumberOfHvacFt,
  IsDucting,
  NumberOfDuctingRun,
  IsEnergyEfficient,
  NumberOfEfficient,
  IsolarLip,
  NumberOfSolarLip,
  IsBirdNetting,
  NumberOfBirdNetting,
  IsBattery,
  NumberOfBattery,
  BatteryPower,
  IsWarrantyPackage,
  Warranty,
  IsSteep,
  ToggleCalc,
  Royaltiy,
  ISfinance,
  // NewRoofPlanMatrial,
  // Roof_plane_squares,
  // Roof_plane_layers,
  NewRoofPlanMatrialPlanes,
  RoofJobsForAddPlanes,
  TileRelayForAddRoofPlanes,
  // RoofOverLayForAddRoofPlanes,
  ReRoofingMaterialForAddPlanes,
  TileRelayForAddRoofPlanesTileRelay,
  jobTypeForRoofTileRealy,
  ReRoofingMaterialForAddPlanesTileRelay,
  jobTypeForRoofRoofOverlay,
  ReRoofingMaterialForAddPlanesRoofOverlay,
  overlayNewMaterial,
  TileRelayMaterial,
  TileRelayForAddRoofPlanesOverLay,
  overlayNewMaterialPlanes,
  tileRelayNewMaterialPlanes,
  reroofNewMaterialPlanes,
  singleUtile,
  hvacs,
  batteries,
  updateInsurance,
  generators,
  roofingPrices,
  updateRoofingPrices,
}) => {
  const dispatch = useDispatch();
  const [myTotalAmount, setMyTotalAmount] = useState({
    value: 0,
  });
  // Hvac array

  // let myTotalAmount = 0;

  // useEffect(() => {
  //   dispatch(TotalAmount(TOTAL_AMOUNT - TootalRoofAmount));
  // }, []);

  const { getMainUser } = useSelector((state) => state.editUser);
  const { info } = useSelector((state) => state.calc);
  // GoodLeap
  const { allGoodLeap } = useSelector((state) => state.GoodLeapReducer);
  const { productMethods, createOffer, concertPayments, concertAllData } =
    useSelector((state) => state.Concert);
  const allOffersGoodLeap =
    useSelector((state) => state.GoodLeapReducer.allOffers) || [];

  const { allOffers, payments, allMosiacData } = useSelector(
    (state) => state.Mosiac
  );
  const TheOffer = allOffersGoodLeap?.find(
    (e) => e.id == allGoodLeap?.offers[0]
  );
  const theOfferMosiac = allOffers?.find(
    (e) => e.id == allMosiacData?.finance_product_id
  );
  // console.log("payments solution", payments);
  // console.log("allMosiacData solution", allMosiacData);
  // console.log("allMosiacData in solution ", allMosiacData);
  // console.log("theOfferMosiac solution", theOfferMosiac);
  const adders = useSelector((state) => state.Adders);
  const allAdders = adders?.adders?.data?.adders;
  // console.log("allGoodLeap in cost estimation ", TheOffer?.dealerFee);
  console.log("info in calc", info);
  // let PPW = Number(getMainUser?.ppw) + 0.07;
  // let PPW = Number(getMainUser?.ppw) + 0.14;
  let PPW = Number(getMainUser?.ppw);
  let StaticPPW = Number(getMainUser?.ppw);
  let ATTIC = 0;
  let ONLINE_MONITRING = 0;
  let TRENCHING = 0;
  let TRENCHING_CONCRET = 0;
  let ROOFMOUNT = 0;
  let DIGGING_SOLPE = 0;
  let MPU = 0;
  let STUCCO = 0;
  let DE_RATE = 0;
  let MPU_RELOCATION_COST = 0;
  let NEW_MPU = 0;
  let SUB_PANEL_COST = 0;
  let METER_COST = 0;
  let EV_COST = 0;
  let EV_MLO = 0;
  let OVERLAY_COST = 0;
  let ROOF_RELAY = 0;
  let ROOF_SHINGLE = 0;
  let ROOF_TEAR = 0;
  let ROOF_PRESIDENTIAL = 0;
  let ROOF_SPANISH = 0;
  let ROOF_FLAT = 0;
  let ROOF_DECKING = 0;
  let ROOF_FASCIA = 0;
  let RAFTER_EXCHANGE = 0;
  let AIR_VENT = 0;
  let DOUBLE_HAND = 0;
  let SMOKE = 0;
  let WARRANTY_EXTEND = 0;
  let HAVC = 0;
  let DUCTING = 0;
  let ENERGY = 0;
  let SOLAR_LIP = 0;
  let BIRD_NETTING = 0;
  let BATTERY = 0;
  let WARRANTY_COST = 0;
  let STEEP_COST = 0;
  let PANEL_PRICE = Number(panelPrice);
  let ROYALTY = isNaN(Royaltiy) ? "0" : Number(Royaltiy);
  let DEALER_FEE = info?.dealer_fee;
  let deposit_sunlight = info?.deposit || 0;
  let sunligthDealerFee = info.dealer_fee || 0;
  let goodleapDealerFee = TheOffer?.dealerFee || 0;
  let mosiacDealerFee = theOfferMosiac?.dealerFee || 0;
  let concertDealerFee = concertAllData?.concert_dealer_fee || 0;
  let TOTAL_AMOUNT = 0;
  let MOUAL_DEGREE = 100 - DEALER_FEE;
  let MOUAL_DEGREE_GOOD_LEAP = 100 - goodleapDealerFee;
  let ROOF_JOB_TYPE_COST = 0;
  let EXISTING_ROOF_COST = 0;
  let NEW_ROOF_JOB_TYPE_COST = 0;
  let ROOF_PLAN_MATRIAL = 0;
  let COLOR_COST = 0;
  let ROOF_PANEL_COST = 0;
  let REDECKING = 0;
  let RAFTER = 0;
  let FASCIA_COST = 0;
  let STEEP_ROOF_COST = 0;
  let HVAC_REPLACE_COST = 0;
  let AIR_VET_OLD_COST = 0;
  let NEW_AIR_VENT_COST = 0;
  let SMOKE_MONITORS_COST = 0;
  let DOUBLE_HAND_COST = 0;
  let MONOXID_DETECTORS_COST = 0;
  let ADD_COST = value.added_cost;
  let ROYALTIES_COST = value.royalties_cost;
  let Roof_planes = 0;
  let TileRelayCost = 0;
  let overlayCost = 0;
  let CostOfLayers = 0;
  // let reRoofingCost = ROOF_JOB_TYPE_COST;
  let costOfLayersOfroofPlanes = 0;
  let newRoofPlanesCost = 0;
  let roof_tile_for_add_roof_planes = 0;
  let roof_overlay_for_add_roof_planes = 0;
  let reRoofing_for_add_roof_planes = 0;
  let CostOfLayers_for_add_roof_planes = 0;
  let STEEP_ROOF_COST_for_add_roof_planes = 0;
  let Designated_amount = 0;
  let cost_add_roof_planes_tile_relay = 0;
  let cost_add_roof_planes_overlay = 0;
  let CostOfLayers_for_add_roof_planes_tile_relay = 0;
  let reRoofing_for_add_roof_planes_tile_relay = 0;
  let STEEP_ROOF_COST_for_add_roof_planes_tile_relay = 0;
  let cost_add_roof_planes_roof_overlay = 0;
  let cost_add_roof_planes_overlay_overlay = 0;
  let CostOfLayers_for_add_roof_planes_roof_overlay = 0;
  let reRoofing_for_add_roof_planes_roof_overlay = 0;
  let STEEP_ROOF_COST_for_add_roof_planes_roof_overlay = 0;
  let cost_overlay_new_roof = 0;
  let Final_Dealers_Fees = 0;
  let DealerFeesForSunLight = 0;
  let FinalDealerFeesForSunLight = 0;
  let utility_provider_cost = 0;
  let overLaySteepCost = 0;
  let overlayPlanesCost = 0;
  let tileRelayOverlayPlanesCost = 0;
  let ReRoofOverlayPlanesCost = 0;
  let totalHvac = 0;
  let totlaBatteries = 0;
  let insurance = 0;
  // Hvac Array
  let total_cost = 0;
  let total_generators = 0;
  let treeTriming = 0;
  let Structural = allAdders?.structual_calcs?.pivot?.price;
  // let supply_chain = DC * 0.07;
  // let supply_chain = DC * 0.14;
  let supply_chain = DC * 0.14 || 0;

  hvacs?.map(
    (e, i) => {
      if (e.hvac_location === "same_location") {
        if (e.hvac_horse_power === "2_ton")
          total_cost = allAdders?.same_location_2_ton?.pivot?.price;
        else if (e.hvac_horse_power === "3_ton")
          total_cost = allAdders?.same_location_3_ton?.pivot?.price;
        else if (e.hvac_horse_power === "4_ton")
          total_cost = allAdders?.same_location_4_ton?.pivot?.price;
        else if (
          e.hvac_horse_power === "4_ton_split" ||
          e.hvac_horse_power === "5_ton"
        )
          total_cost = total_cost =
            allAdders?.same_location_4_ton_split?.pivot?.price;
      } else if (
        e.hvac_location === "new_location_electrical" ||
        e.hvac_location === "new_location_plumbing"
      ) {
        if (e.hvac_horse_power !== "4_ton_split")
          total_cost = 1250 + 4800 + 50 * e.number_hvac_ft;
      } else if (e.hvac_location === "roof_unit") {
        if (e.hvac_horse_power !== "4_ton_split")
          total_cost = allAdders?.roof_unit_4_ton?.pivot?.price;
      }
      totalHvac += total_cost;
    }
    // });
    // },
    // [hvacs]
  );
  HAVC = totalHvac;

  useEffect(() => {
    myTotalAmount.value = TOTAL_AMOUNT;
    // console.log("myTotalAmount.value In cost Estimation", myTotalAmount.value);
    dispatch(TotalAmount(myTotalAmount.value));
    dispatch(Adder({ supply_chain, Structural }));
  }, [
    myTotalAmount.value,
    TOTAL_AMOUNT,
    info,
    allGoodLeap,
    value,
    allMosiacData,
  ]);

  React.useEffect(() => {
    if (updateInsurance) updateInsurance(insurance);
  }, [value.is_insurance]);

  React.useEffect(() => {
    dispatch(
      RoofingPrices({
        TileRelayCost: TileRelayCost,
        overlayCost: overlayCost,
        cost_add_roof_planes_tile_relay: cost_add_roof_planes_tile_relay,
        cost_add_roof_planes_overlay: cost_add_roof_planes_overlay,
        CostOfLayers_for_add_roof_planes_tile_relay:
          CostOfLayers_for_add_roof_planes_tile_relay,
        reRoofing_for_add_roof_planes_tile_relay:
          reRoofing_for_add_roof_planes_tile_relay,
        STEEP_ROOF_COST_for_add_roof_planes_tile_relay:
          STEEP_ROOF_COST_for_add_roof_planes_tile_relay,
        overLaySteepCost: overLaySteepCost,
        cost_add_roof_planes_roof_overlay: cost_add_roof_planes_roof_overlay,
        overlayPlanesCost: overlayPlanesCost,
        cost_add_roof_planes_overlay_overlay:
          cost_add_roof_planes_overlay_overlay,
        CostOfLayers_for_add_roof_planes_roof_overlay:
          CostOfLayers_for_add_roof_planes_roof_overlay,
        reRoofing_for_add_roof_planes_roof_overlay:
          reRoofing_for_add_roof_planes_roof_overlay,
        STEEP_ROOF_COST_for_add_roof_planes_roof_overlay:
          STEEP_ROOF_COST_for_add_roof_planes_roof_overlay,
        ROOF_JOB_TYPE_COST: ROOF_JOB_TYPE_COST,
        EXISTING_ROOF_COST: EXISTING_ROOF_COST,
        STEEP_ROOF_COST: STEEP_ROOF_COST,
        roof_tile_for_add_roof_planes: roof_tile_for_add_roof_planes,
        ReRoofOverlayPlanesCost: ReRoofOverlayPlanesCost,
        roof_overlay_for_add_roof_planes: roof_overlay_for_add_roof_planes,
        CostOfLayers_for_add_roof_planes: CostOfLayers_for_add_roof_planes,
        reRoofing_for_add_roof_planes: reRoofing_for_add_roof_planes,
        STEEP_ROOF_COST_for_add_roof_planes:
          STEEP_ROOF_COST_for_add_roof_planes,
        NEW_ROOF_JOB_TYPE_COST: NEW_ROOF_JOB_TYPE_COST,
        newRoofPlanesCost: newRoofPlanesCost,
        COLOR_COST: COLOR_COST,
        NEW_ROOF_JOB_TYPE_COST: NEW_ROOF_JOB_TYPE_COST,
      })
    );
  }, [value]);
  //equality
  value.number_of_new_planes_sq = value.number_of_planes_squares;
  // start roofing clac
  if (value.is_roofing) {
    ROOF_JOB_TYPE_COST = Number(RoofJobsTypesObject?.cost);
    if (value.roof_job_type_id === 3) {
      if (Number(value.number_of_old_layers) > 1)
        CostOfLayers = (Number(value.number_of_old_layers) - 1) * 75;
      EXISTING_ROOF_COST =
        (ROOF_JOB_TYPE_COST +
          Number(ExistingRoofingObject?.z_cost || 0) +
          Number(CostOfLayers)) *
        Number(value.number_of_old_squares);

      if (Number(value.roof_pitch_degree) > 35) {
        STEEP_ROOF_COST =
          (75 + Number(value.roof_pitch_degree - 35) * 5) *
          Number(value.number_of_steep_sq);
      }
    }
    if (value.is_roof_planes) {
      // the cost of job type tirelay for add roof planes
      if (value.roof_job_type_id_roof_planes === 1) {
        roof_tile_for_add_roof_planes =
          Number(TileRelayForAddRoofPlanes?.tile_relay) *
            Number(value.tile_relay_no_of_squares_for_add_roof_planes) +
          Number(value.roof_planes_tile_relay_broken_reroof || 0) * 5;
      }
      // the cost of job type Overlay for add roof planes
      if (value.roof_job_type_id_roof_planes === 2) {
        ReRoofOverlayPlanesCost = Number(value.reRoof_overlay_steep || 0) * 100;
        console.log("conditon fullfilled");
        roof_overlay_for_add_roof_planes =
          Number(reroofNewMaterialPlanes?.new_cost) *
            Number(value.reroof_overlay_no_of_squares) +
          ReRoofOverlayPlanesCost;
        // Number(RoofJobsForAddPlanes?.cost) *
        // Number(value.overlay_no_of_squares_for_add_roof_planes);
      }

      // the cost of job type ReRoofing for add roof planes
      if (value.roof_job_type_id_roof_planes === 3) {
        if (Number(value.number_of_planes_layers_for_add_roof_planes) > 1)
          CostOfLayers_for_add_roof_planes =
            (Number(value.number_of_planes_layers_for_add_roof_planes) - 1) *
            75;

        reRoofing_for_add_roof_planes =
          (Number(RoofJobsForAddPlanes?.cost) +
            Number(ReRoofingMaterialForAddPlanes?.replace_cost || 0) +
            Number(CostOfLayers_for_add_roof_planes)) *
          Number(value.number_of_planes_squares_for_add_planes);
      }
    }
    if (Number(value.roof_pitch_degree_for_roof_planes) > 35) {
      STEEP_ROOF_COST_for_add_roof_planes =
        (75 + Number(value.roof_pitch_degree_for_roof_planes - 35) * 5) *
        Number(value.number_of_steep_sq_for_roof_planes);
    }
    if (value.new_roof_material_id) {
      NEW_ROOF_JOB_TYPE_COST =
        Number(NewRoofJobTypesObject?.new_cost) *
        Number(value.number_of_new_squares);
    }
    if (value.is_new_roof_planes) {
      newRoofPlanesCost =
        Number(NewRoofPlanMatrialPlanes?.new_cost || 0) *
        Number(value.number_of_planes_squares);
    }
    if (value.roof_job_type_id === 1) {
      Roof_planes = 0;
      // Klapy 7al
      TileRelayCost =
        Number(TileRelayMaterial?.tile_relay) *
          Number(value.tile_relay_no_of_squares) +
        Number(value.tile_relay_broken || 0) * 5;
      // value.overlay_no_of_squares = 0
      // value.is_roof_planes = false;
      // NEW_ROOF_JOB_TYPE_COST = 0
      if (value.is_new_roof_planes_for_tile_relay) {
        if (value.add_roof_planes_job_type_id_for_roof_tile === 1) {
          cost_add_roof_planes_tile_relay =
            Number(TileRelayForAddRoofPlanesTileRelay?.tile_relay) *
              Number(
                value.tile_relay_no_of_squares_for_add_roof_planes_tile_relay
              ) +
            Number(value.roof_planes_tile_relay_broken || 0) * 5;
        }
        if (value.add_roof_planes_job_type_id_for_roof_tile === 2) {
          tileRelayOverlayPlanesCost =
            Number(value.tileRelay_overlay_steep || 0) * 100;

          cost_add_roof_planes_overlay =
            Number(tileRelayNewMaterialPlanes?.new_cost) *
              Number(value.TileRelay_overlay_no_of_squares) +
            tileRelayOverlayPlanesCost;

          console.log(
            "cost_add_roof_planes_overlay",
            cost_add_roof_planes_overlay
          );
        }
        if (value.add_roof_planes_job_type_id_for_roof_tile === 3) {
          if (
            Number(
              value.number_of_planes_layers_for_add_roof_planes_tile_relay
            ) > 1
          )
            CostOfLayers_for_add_roof_planes_tile_relay =
              (Number(
                value.number_of_planes_layers_for_add_roof_planes_tile_relay
              ) -
                1) *
              75;

          reRoofing_for_add_roof_planes_tile_relay =
            (Number(jobTypeForRoofTileRealy?.cost) +
              Number(
                ReRoofingMaterialForAddPlanesTileRelay?.replace_cost || 0
              ) +
              Number(CostOfLayers_for_add_roof_planes_tile_relay)) *
            Number(value.number_of_planes_squares_for_add_planes_tile_relay);

          if (Number(value.roof_pitch_degree_for_roof_planes_tile_relay) > 35) {
            STEEP_ROOF_COST_for_add_roof_planes_tile_relay =
              (75 +
                Number(
                  value.roof_pitch_degree_for_roof_planes_tile_relay - 35
                ) *
                  5) *
              Number(value.number_of_steep_sq_for_roof_planes_tile_relay);
          }
        }
      }
    }
    if (value.roof_job_type_id === 2) {
      // Klapy 7al
      // cost_overlay_new_roof=Number(overlayNewMaterial?.new_cost)*Number(value.overlay_number_of_new_squares)||0;

      // overlayCost =
      //   (Number(overlayNewMaterial?.new_cost) * Number(value.overlay_no_of_squares))+cost_overlay_new_roof;
      overLaySteepCost = Number(value.overlay_steep || 0) * 100;
      overlayCost =
        (Number(overlayNewMaterial?.new_cost) +
          Number(RoofJobsTypesObject.cost)) *
          Number(value.overlay_no_of_squares) +
        overLaySteepCost;

      if (value.is_new_roof_planes_for_roof_overlay === true) {
        if (value.add_roof_planes_job_type_id_for_roof_overlay === 1) {
          cost_add_roof_planes_roof_overlay =
            Number(TileRelayForAddRoofPlanesOverLay?.tile_relay) *
              Number(
                value.tile_relay_no_of_squares_for_add_roof_planes_roof_overlay
              ) +
            Number(value.roof_planes_tile_relay_broken_overlay || 0) * 5;
        }
        if (value.add_roof_planes_job_type_id_for_roof_overlay === 2) {
          overlayPlanesCost = Number(value.overlay_planes_steep || 0) * 100;
          cost_add_roof_planes_overlay_overlay =
            Number(overlayNewMaterialPlanes?.new_cost) *
              Number(value.overlay_planes_no_of_squares) +
            overlayPlanesCost;
          // console.log("overlayPlanesCost", overlayPlanesCost);

          // Number(jobTypeForRoofRoofOverlay?.cost) *
          // Number(
          //   value.overlay_no_of_squares_for_add_roof_planes_overlay_overlay
          // );
        }

        if (value.add_roof_planes_job_type_id_for_roof_overlay === 3) {
          if (
            Number(
              value.number_of_planes_layers_for_add_roof_planes_roof_overlay
            ) > 1
          )
            CostOfLayers_for_add_roof_planes_roof_overlay =
              (Number(
                value.number_of_planes_layers_for_add_roof_planes_roof_overlay
              ) -
                1) *
              75;

          reRoofing_for_add_roof_planes_roof_overlay =
            (Number(jobTypeForRoofRoofOverlay?.cost) +
              Number(
                ReRoofingMaterialForAddPlanesRoofOverlay?.replace_cost || 0
              ) +
              Number(CostOfLayers_for_add_roof_planes_roof_overlay)) *
            Number(value.number_of_planes_squares_for_add_planes_roof_overlay);

          if (
            Number(value.roof_pitch_degree_for_roof_planes_roof_overlay) > 35
          ) {
            STEEP_ROOF_COST_for_add_roof_planes_roof_overlay =
              (75 +
                Number(
                  value.roof_pitch_degree_for_roof_planes_roof_overlay - 35
                ) *
                  5) *
              Number(value.number_of_steep_sq_for_roof_planes_roof_overlay);
          }
        }
      }
    }
    console.log("ROOF_JOB_TYPE_COST", ROOF_JOB_TYPE_COST);
    //Select material color
    if (value.color_id) {
      COLOR_COST =
        Number(value.new_color_cost) *
        Number(value.number_of_new_layers) *
        Number(value.number_of_new_squares);
    }
    //Redecking
    if (value.is_redecking) {
      REDECKING =
        allAdders?.redecking?.pivot?.price *
        Number(value.number_of_redecking_squares);
    }
    //Rafter Exchange
    if (value.is_rafter)
      RAFTER =
        allAdders?.rafter?.pivot?.price * Number(value.number_of_rafter_ft);
    //Fascia Replace Board
    if (value.is_fascia)
      FASCIA_COST =
        allAdders?.fascia?.pivot?.price * Number(value.number_of_fascia_ft);
    //STEEP ROOF
    // if (value.is_steep_pitch) {

    // }

    //HVAC unit
    if (value.is_hvac_replace)
      HVAC_REPLACE_COST =
        allAdders?.hvac_replace?.pivot?.price *
        Number(value.number_of_hvac_units);

    // air vet replacement
    if (value.is_air_vent_replacement)
      AIR_VET_OLD_COST =
        allAdders?.air_vent_replacement?.pivot?.price *
        Number(value.number_of_replaced_vents);

    // new air vent
    if (value.is_new_vent)
      NEW_AIR_VENT_COST =
        allAdders?.new_air_vent?.pivot?.price *
        Number(value.number_of_new_vents);

    //Smoke Monitors
    if (value.is_smoke_monitors)
      SMOKE_MONITORS_COST =
        allAdders?.smoke_monitors?.pivot?.price *
        Number(value.number_of_smoke_monitors);

    //Double hand
    if (value.is_double_hands)
      DOUBLE_HAND_COST =
        allAdders?.double_hand?.pivot?.price *
        Number(value.number_of_double_hand_squares);

    //Carbon monoxide Monitors
    if (value.is_monoxide_detectors)
      MONOXID_DETECTORS_COST =
        allAdders?.monoxide_detectors?.pivot?.price *
        Number(value?.number_of_monoxide_detectors);
  }
  // Designated plugs
  if (value.is_designated) {
    Designated_amount =
      allAdders?.designated_plugs?.pivot?.price *
      Number(value.number_of_designated_plugs);
  }

  if (JopType === "roof_mount" || JopType === "detached_garage") {
    if (DC > 1 && DC <= 2600) {
      //---------- PPW ------
      PPW = PPW + 1.25;
    } else if (DC > 2600 && DC <= 4400) {
      PPW = PPW + 0.9;
    }
    //----------Invert TYpe ------
    console.log("InvertType", InvertType);
    if (InvertType === 1) {
      PPW = PPW + 0.15;
    }
    if (InvertType === 2) {
      PPW = PPW * 0.15 + PPW;
    }
    if (InvertType === 3) {
      PPW = PPW * 0.3 + PPW;
    }
    //-------- is tow story ----
    if (IsTowStory === true) {
      PPW = PPW + 0.05;
    }
    //--------- IS REVERSE TILL-----
    if (IsReverseTilt === true || IsNorthFaced === true) {
      PPW = PPW + 0.2;
    }

    //-------------
    switch (RoofMaterials) {
      case "flat_torch_down":
        PPW = PPW + 0.15;
        break;
      case "presidential":
        PPW = PPW + 0.1;
        break;
      case "standing_seam_metal":
      case "foam":
        PPW = PPW + 0.2;
        break;
      case "flat_tile":
      case "spanish_tile":
        PPW = PPW + 0.05;
        break;
      default:
        PPW = PPW;
    }
    // utility provider
    if (singleUtile?.id === 44) {
      utility_provider_cost = 1000;
    }
    //------------ IS ATTIC -------

    if (IsAttic === true) {
      ATTIC = NumberOfAttics * 400;
    }
  } else if (JopType === "ground_mount") {
    // PPW = PPW + 0.75;
    PPW = PPW + 0.75;
    // alert(PPW)
  }
  if (value.job_type !== "roof_mount") {
    if (IsTrenching === true) {
      TRENCHING = HowManyTrench * allAdders?.trenching_dirt?.pivot?.price;
    }
    if (IsTrenchingConcret === true) {
      TRENCHING_CONCRET =
        HowManyConcret * allAdders?.trenching_concrete?.pivot?.price;
    }
  }
  if (IsStandAloneRoof === true) {
    ROOFMOUNT = 750;
  }
  if (IsDiggingSlope === true) {
    if (HowManyDigging > 25) DIGGING_SOLPE = (HowManyDigging - 25) * 25;
  }
  if (ExistingElectricalPanel == 100)
    MPU = allAdders?.upgrade_electrical_panel_100a?.pivot?.price;
  else if (ExistingElectricalPanel < 100) MPU = 0;
  else MPU = allAdders?.upgrade_electrical_panel_125a?.pivot?.price;

  if (IsStucco) STUCCO = allAdders?.stucco?.pivot?.price;
  if (IsDeRate) DE_RATE = allAdders?.derate?.pivot?.price;
  //---------- online moniting ---
  if (IsOnlineMonitoring === true) {
    ONLINE_MONITRING = allAdders?.online_monitoring?.pivot?.price;
  }
  // if (ISMPU === true) {
  //   if (ISMPUMore3 === false) MPU_RELOCATION_COST = 1400;
  //   else if (NumberOfMPU > 6) MPU_RELOCATION_COST = 1400 + (150 * (NumberOfMPU - 6))
  // }

  if (ISMPU === true) {
    MPU_RELOCATION_COST = allAdders?.mpu_relocation?.pivot?.price;
    if (NumberOfMPU > 6) {
      MPU_RELOCATION_COST += 50 * (NumberOfMPU - 6);
    }
  }
  if (value.is_mpu_new === true) {
    NEW_MPU = 3100;
  }

  if (IsSubPanel === true) SUB_PANEL_COST = allAdders?.sub_panel?.pivot?.price;
  if (IsMeterSocket === true)
    METER_COST = allAdders?.meter_socket?.pivot?.price;
  if (IsEV === true) EV_COST = allAdders?.ev_charger?.pivot?.price;
  if (IsEVMLO === true) EV_MLO = allAdders?.ev_mlo?.pivot?.price;
  if (IsReRoofing === true) OVERLAY_COST = NumberOfReroofSquares * 450;
  if (IsRoofRelay === true) ROOF_RELAY = NumberOfReroofSquares * 550;
  if (IsReRoofShingle === true) ROOF_SHINGLE = NumberOfReroofSquares * 550;
  if (IsReRoofTear === true)
    ROOF_TEAR = NumberOfLayers * 75 + NumberOfReroofSquares * 75;
  if (IsReRoofPresidential === true)
    ROOF_PRESIDENTIAL = NumberOfReroofSquares * 800;
  if (IsReRoofSpanish) ROOF_SPANISH = NumberOfReroofSquares * 950;
  if (IsReRoofFlat) ROOF_FLAT = NumberOfReroofSquares * 1000;
  if (IsReRoofReDecking) ROOF_DECKING = NumberOfReroofSquares * 350;
  if (IsReRoofFascia) ROOF_FASCIA = NumberOfFeet * 12;
  if (IsRafterExchange) RAFTER_EXCHANGE = NumberOfFeet * 10;
  if (IsAirVet) {
    if (AirVent === "exchange") AIR_VENT = NumberOfVent * 250;
    else AIR_VENT = NumberOfVent * 350;
  }
  if (IsDoubleHand) DOUBLE_HAND = NumberOfReroofSquares * 50;
  if (IsSmoke) SMOKE = NumberOfSmoke * allAdders?.smoke_monitors?.pivot?.price;
  if (IsWarrantyExtend) WARRANTY_EXTEND = NumberOfReroofSquares * 50;
  // if (IsHVAC) {
  //   if (HvacLocation === "same_location") {
  //     if (HvacHorsPower === "2_ton") HAVC = 5200;
  //     else if (HvacHorsPower === "3_ton") HAVC = 6800;
  //     else if (HvacHorsPower === "4_ton") HAVC = 7000;
  //     else if (HvacHorsPower === "4_ton_split" || HvacHorsPower === "5_ton")
  //       HAVC = 8000;
  //   } else if (
  //     HvacLocation === "new_location_electrical" ||
  //     HvacLocation === "new_location_plumbing"
  //   ) {
  //     if (HvacHorsPower != "4_ton_split") HAVC = 1250 + 50 * NumberOfHvacFt;
  //   } else if (HvacLocation === "roof_unit") {
  //     if (HvacHorsPower != "4_ton_split") HAVC = 850;
  //   }
  // }
  // if (value.is_hvac) {
  if (IsDucting)
    DUCTING = NumberOfDuctingRun * allAdders?.ducting?.pivot?.price;
  if (IsEnergyEfficient)
    ENERGY = NumberOfEfficient * allAdders?.energy_efficient?.pivot?.price;
  if (IsolarLip)
    SOLAR_LIP = NumberOfSolarLip * allAdders?.solar_lip?.pivot?.price;
  if (IsBirdNetting)
    BIRD_NETTING = NumberOfBirdNetting * allAdders?.bird_netting?.pivot?.price;
  if (IsWarrantyPackage) {
    if (Warranty === "standard")
      WARRANTY_COST = allAdders?.warranty_standard?.pivot?.price;
    else if (Warranty === "premium")
      WARRANTY_COST = allAdders?.warranty_premium?.pivot?.price;
  }
  // }
  batteries?.map((e) => {
    // if (IsBattery) {
    if (e.battery_power === "3KwH")
      BATTERY =
        e.number_of_batteries * allAdders?.battery_power_3kwh?.pivot?.price;
    else if (e.battery_power === "10KwH")
      BATTERY =
        e.number_of_batteries * allAdders?.battery_power_10kwh?.pivot?.price;
    // }
    totlaBatteries += BATTERY;
  });
  generators?.map((e) => {
    let generator;
    if (e.generator_power === "5KwH")
      generator =
        e.number_of_generators * allAdders?.generator_power_5kwh?.pivot?.price;
    else if (e.generator_power === "10KwH")
      generator =
        e.number_of_generators * allAdders?.generator_power_10kwh?.pivot?.price;
    total_generators += generator;
  });
  BATTERY = totlaBatteries;
  if (IsBattery) {
    if (BatteryPower === "3KwH") BATTERY = NumberOfBattery * 11200;
    else if (BatteryPower === "10KwH") BATTERY = NumberOfBattery * 17500;
  }

  if (IsSteep) STEEP_COST = NumberOfReroofSquares * 150;

  // Insurance
  if (value.is_insurance) {
    insurance = 0.14 * DC;
  }
  // Tree Trimming
  if (value.is_tree_trimming) {
    treeTriming = allAdders?.tree_triming?.pivot?.price * value.number_of_trees;
  }
  let ContractAmount =
    DC * PPW +
    ATTIC +
    ONLINE_MONITRING +
    TRENCHING +
    TRENCHING_CONCRET +
    ROOFMOUNT +
    DIGGING_SOLPE +
    MPU +
    STUCCO +
    DE_RATE +
    MPU_RELOCATION_COST +
    SUB_PANEL_COST +
    METER_COST +
    EV_COST +
    EV_MLO +
    OVERLAY_COST +
    ROOF_RELAY +
    ENERGY +
    ROOF_SHINGLE +
    ROOF_TEAR +
    ROOF_PRESIDENTIAL +
    ROOF_SPANISH +
    ROOF_FLAT +
    ROOF_DECKING +
    ROOF_FASCIA +
    RAFTER_EXCHANGE +
    SMOKE +
    WARRANTY_EXTEND +
    AIR_VENT +
    DOUBLE_HAND +
    HAVC +
    DUCTING +
    SOLAR_LIP +
    BIRD_NETTING +
    BATTERY +
    WARRANTY_COST +
    STEEP_COST +
    Designated_amount +
    ROYALTY +
    NEW_MPU +
    utility_provider_cost +
    insurance +
    total_generators +
    treeTriming +
    Structural +
    supply_chain;

  let TootalRoofAmount =
    Roof_planes +
    TileRelayCost +
    overlayCost +
    roof_tile_for_add_roof_planes +
    roof_overlay_for_add_roof_planes +
    reRoofing_for_add_roof_planes +
    STEEP_ROOF_COST_for_add_roof_planes +
    EXISTING_ROOF_COST +
    NEW_ROOF_JOB_TYPE_COST +
    newRoofPlanesCost +
    COLOR_COST +
    // ROOF_PANEL_COST +
    REDECKING +
    RAFTER +
    FASCIA_COST +
    STEEP_ROOF_COST +
    HVAC_REPLACE_COST +
    AIR_VET_OLD_COST +
    NEW_AIR_VENT_COST +
    SMOKE_MONITORS_COST +
    DOUBLE_HAND_COST +
    MONOXID_DETECTORS_COST +
    ADD_COST +
    ROYALTIES_COST +
    cost_add_roof_planes_tile_relay +
    cost_add_roof_planes_overlay +
    CostOfLayers_for_add_roof_planes_tile_relay +
    reRoofing_for_add_roof_planes_tile_relay +
    STEEP_ROOF_COST_for_add_roof_planes_tile_relay +
    cost_add_roof_planes_roof_overlay +
    cost_add_roof_planes_overlay_overlay +
    CostOfLayers_for_add_roof_planes_roof_overlay +
    reRoofing_for_add_roof_planes_roof_overlay +
    STEEP_ROOF_COST_for_add_roof_planes_roof_overlay;

  console.log("value.service_type_id", value.service_type_id);
  if (value.service_type_id === 1) {
    if (ISfinance) {
      if (value.financial_institution === "") {
        TOTAL_AMOUNT = ContractAmount + TootalRoofAmount;
      }
      if (value.financial_institution === "sunlight") {
        DealerFeesForSunLight =
          ((ContractAmount + TootalRoofAmount - deposit_sunlight) * 100) /
          (100 - sunligthDealerFee);
        TOTAL_AMOUNT = DealerFeesForSunLight;
      }
      if (value.financial_institution === "enfin") {
        DealerFeesForSunLight =
          ((ContractAmount + TootalRoofAmount - deposit_sunlight) * 100) /
          (100 - sunligthDealerFee);
        TOTAL_AMOUNT = DealerFeesForSunLight;
      }
      if (value.financial_institution === "dividend") {
        DealerFeesForSunLight =
          ((ContractAmount + TootalRoofAmount - deposit_sunlight) * 100) /
          (100 - sunligthDealerFee);
        TOTAL_AMOUNT = DealerFeesForSunLight;
      }
      if (value.financial_institution === "goodleap") {
        DealerFeesForSunLight =
          ((ContractAmount + TootalRoofAmount - deposit_sunlight) * 100) /
          (100 - goodleapDealerFee);
        TOTAL_AMOUNT = DealerFeesForSunLight;
      }
      if (value.financial_institution === "mosaic") {
        DealerFeesForSunLight =
          ((ContractAmount + TootalRoofAmount) * 100) / (100 - mosiacDealerFee);
        TOTAL_AMOUNT = DealerFeesForSunLight;
      }
      if (value.financial_institution === "concert") {
        DealerFeesForSunLight =
          ((ContractAmount + TootalRoofAmount) * 100) /
          (100 - concertDealerFee);
        TOTAL_AMOUNT = DealerFeesForSunLight;
      }
    } else {
      TOTAL_AMOUNT = ContractAmount + TootalRoofAmount;
      // TOTAL_AMOUNT =  TootalRoofAmount;
    }
  }
  if (value.service_type_id === 2) {
    if (ISfinance) {
      if (value.financial_institution === "") {
        TOTAL_AMOUNT = TootalRoofAmount;
      }
      if (value.financial_institution === "sunlight") {
        TOTAL_AMOUNT =
          (TootalRoofAmount * 100) / MOUAL_DEGREE - deposit_sunlight;
      }
      if (value.financial_institution === "goodleap") {
        TOTAL_AMOUNT = (TootalRoofAmount * 100) / MOUAL_DEGREE_GOOD_LEAP;
      }
    } else {
      TOTAL_AMOUNT = TootalRoofAmount;
    }
  }
  /*----------- get total amount -----------------*/
  //
  myTotalAmount.value = TOTAL_AMOUNT;

  /*----------- PPS for Roofing -----------------*/

  let Final_Dealers_Fees_for_Roofing = 0;
  if (value.financial_institution === "sunlight") {
    // let sunLightDealerFees = Number(DEALER_FEE) / 100;
    // let percentage = TOTAL_AMOUNT * sunLightDealerFees;
    // if (value.roof_job_type_id === 1) {
    //   Final_Dealers_Fees_for_Roofing =
    //     (TOTAL_AMOUNT - percentage - FASCIA_COST - RAFTER) /
    //     value.tile_relay_no_of_squares;
    // }
    // if (value.roof_job_type_id === 2) {
    //   Final_Dealers_Fees_for_Roofing =
    //     (TOTAL_AMOUNT - percentage - FASCIA_COST - RAFTER) /
    //     value.overlay_no_of_squares;
    // }
    // if (value.roof_job_type_id === 3) {
    //   Final_Dealers_Fees_for_Roofing =
    //     (TOTAL_AMOUNT - percentage - FASCIA_COST - RAFTER) /
    //     value.number_of_new_squares;
    // }
    let percentage = TOTAL_AMOUNT * (goodleapDealerFee / 100);
    if (value.roof_job_type_id === 1) {
      Final_Dealers_Fees_for_Roofing =
        (TOTAL_AMOUNT - percentage - FASCIA_COST - RAFTER) /
        value.tile_relay_no_of_squares;
    }
    if (value.roof_job_type_id === 2) {
      Final_Dealers_Fees_for_Roofing =
        (TOTAL_AMOUNT - percentage - FASCIA_COST - RAFTER) /
        value.overlay_no_of_squares;
    }
    if (value.roof_job_type_id === 3) {
      Final_Dealers_Fees_for_Roofing =
        (TOTAL_AMOUNT - percentage - FASCIA_COST - RAFTER) /
        value.number_of_new_squares;
    }
  }
  if (value.financial_institution === "goodleap") {
    let percentage = TOTAL_AMOUNT * (goodleapDealerFee / 100);
    if (value.roof_job_type_id === 1) {
      Final_Dealers_Fees_for_Roofing =
        (TOTAL_AMOUNT - percentage - FASCIA_COST - RAFTER) /
        value.tile_relay_no_of_squares;
    }
    if (value.roof_job_type_id === 2) {
      Final_Dealers_Fees_for_Roofing =
        (TOTAL_AMOUNT - percentage - FASCIA_COST - RAFTER) /
        value.overlay_no_of_squares;
    }
    if (value.roof_job_type_id === 3) {
      Final_Dealers_Fees_for_Roofing =
        (TOTAL_AMOUNT - percentage - FASCIA_COST - RAFTER) /
        value.number_of_new_squares;
    }
  }
  if (value.financial_institution === "") {
    Final_Dealers_Fees_for_Roofing =
      (TOTAL_AMOUNT - FASCIA_COST - RAFTER) / value.number_of_new_squares;
    if (value.roof_job_type_id === 1) {
      Final_Dealers_Fees_for_Roofing =
        (TOTAL_AMOUNT - FASCIA_COST - RAFTER) / value.tile_relay_no_of_squares;
    }
    if (value.roof_job_type_id === 2) {
      Final_Dealers_Fees_for_Roofing =
        (TOTAL_AMOUNT - FASCIA_COST - RAFTER) / value.overlay_no_of_squares;
    }
    if (value.roof_job_type_id === 3) {
      Final_Dealers_Fees_for_Roofing =
        (TOTAL_AMOUNT - FASCIA_COST - RAFTER) / value.number_of_new_squares;
    }
  }
  if (value?.is_discount) {
    if (value?.discount_type === "percentage") {
      TOTAL_AMOUNT =
        TOTAL_AMOUNT - (value?.discount_percent * TOTAL_AMOUNT) / 100;
    }
    if (value?.discount_type === "fixed") {
      TOTAL_AMOUNT = TOTAL_AMOUNT - value?.discount_amount;
    }
  }
  let CommaFormatted = (num) => {
    // return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return Intl.NumberFormat("en-US").format(num);
  };
  return (
    <CalcStyled className={ToggleCalc ? "show" : ""}>
      <ClacConatiner>
        <h3>Cost Estimation </h3>

        <div className="totalAmount">
          <h2>
            {" "}
            ${isNaN(TOTAL_AMOUNT) ? 0 : CommaFormatted(Math.ceil(TOTAL_AMOUNT))}
          </h2>
          <div className="data">Total Cost</div>
        </div>
        {/*conditional render for Roofing or Solar */}
        {value.service_type_id === 1 ? (
          <>
            <h4 class="ppw">
              {" "}
              PPW : $ {isNaN(StaticPPW) ? 0 : StaticPPW.toFixed(2)}
            </h4>
            <h4 class="ppw">
              {" "}
              Gross PPW : $
              {isNaN((TOTAL_AMOUNT - HAVC - total_generators - BATTERY) / DC)
                ? 0
                : (
                    (TOTAL_AMOUNT - HAVC - total_generators - BATTERY) /
                    DC
                  ).toFixed(2)}
            </h4>
          </>
        ) : (
          <span></span>
        )}

        <CalcItem>
          {value.service_type_id === 1 ? (
            <>
              <p className="data">
                <span className="value">
                  {isNaN(NumberOfPanls) ? 0 : NumberOfPanls}
                </span>
                <span className="key">Number of Panels :</span>
              </p>
              <p className="data">
                <span className="value">{isNaN(DC) ? 0 : DC} KW</span>
                <span className="key">System Size :</span>
              </p>
              {/* <p className="data">
            <span className="value">{isNaN(AC) ? 0 : Math.ceil(AC)} KW</span>
            <span className="key">System Size AC :</span>
          </p> */}
              <p className="data">
                <span className="value">
                  {isNaN(Math.ceil(AnnualPrduction))
                    ? 0
                    : Math.ceil(AnnualPrduction)}{" "}
                  KW
                </span>
                <span className="key">Anuual production :</span>
              </p>
              {/* <p className="data">
            <span className="value">
              $ {isNaN(ContractAmount) ? 0 : Math.ceil(ContractAmount)}
            </span>
            <span className="key">Contract Amount</span>
          </p> */}
            </>
          ) : (
            <span style={{ display: "none" }}></span>
          )}
          {/* PPS for Roofing */}
          {value.is_roofing ? (
            <>
              {Final_Dealers_Fees_for_Roofing !== 0 ? (
                <p className="data">
                  <span className="value">
                    ${" "}
                    {/* {isNaN(TootalRoofAmount)
                  ? 0
                  : Math.ceil(
                      TootalRoofAmount /
                        (value.number_of_old_squares +
                          value.number_of_planes_squares_for_add_planes)
                    )} */}
                    {isNaN(Final_Dealers_Fees_for_Roofing)
                      ? 0
                      : Math.ceil(Final_Dealers_Fees_for_Roofing)}
                    {/* {Math.ceil(Final_Dealers_Fees_for_Roofing)} */}
                  </span>
                  <span className="key">Roofing PPS </span>
                </p>
              ) : null}
            </>
          ) : null}
          {/* Hide Dealer Fee */}
          <p className="data">
            <span className="value">
              ${" "}
              {/* {isNaN(DealerFeesForSunLight)
                ? 0
                : Math.ceil(DealerFeesForSunLight-(ContractAmount +TootalRoofAmount))} */}
              {DealerFeesForSunLight > 0
                ? Math.ceil(
                    DealerFeesForSunLight - (ContractAmount + TootalRoofAmount)
                  )
                : 0}
            </span>
            <span className="key">Dealer Fees </span>
          </p>

          {/* -------    START  A CHANLGED DATA ------  */}

          {/* <h4> Panel Price : $ {PANEL_PRICE}</h4> */}

          {ATTIC !== 0 ? (
            <p className="data">
              <span className="value">$ {ATTIC} </span>
              <span className="key"> Attics</span>
            </p>
          ) : null}
          {value.service_type_id === 1 ? (
            <>
              {ONLINE_MONITRING !== 0 ? (
                <p className="data">
                  <span className="value">$ {ONLINE_MONITRING} </span>
                  <span className="key">Online Monitoring</span>
                </p>
              ) : null}
            </>
          ) : (
            <span style={{ display: "none" }}></span>
          )}
          {Structural > 0 ? (
            <p className="data">
              <span className="value">$ {Structural}</span>
              <span className="key">Structual </span>
            </p>
          ) : null}
          {supply_chain > 0 ? (
            <p className="data">
              <span className="value">$ {supply_chain.toFixed(2)}</span>
              <span className="key">Supply Chain </span>
            </p>
          ) : null}

          {TRENCHING !== 0 ? (
            <p className="data">
              <span className="value">$ {TRENCHING}</span>
              <span className="key">Trenching</span>
            </p>
          ) : null}

          {TRENCHING_CONCRET !== 0 ? (
            <p className="data">
              <span className="value">$ {TRENCHING_CONCRET}</span>
              <span className="key">Trenching Concrete </span>
            </p>
          ) : null}

          {ROOFMOUNT !== 0 ? (
            <p className="data">
              <span className="value">$ {ROOFMOUNT}</span>
              <span className="key">Roof mount</span>
            </p>
          ) : null}

          {DIGGING_SOLPE !== 0 ? (
            <p className="data">
              <span className="value">$ {DIGGING_SOLPE}</span>
              <span className="key">Diggin Solpe</span>
            </p>
          ) : null}
          {value.service_type_id === 1 && (
            <>
              {MPU !== 0 ? (
                <p className="data">
                  <span className="value">$ {MPU} </span>
                  <span className="key">MPU</span>
                </p>
              ) : null}
            </>
          )}

          {STUCCO !== 0 ? (
            <p className="data">
              <span className="value">$ {STUCCO} </span>
              <span className="key">Stucco</span>
            </p>
          ) : null}

          {DE_RATE !== 0 ? (
            <p className="data">
              <span className="value">$ {DE_RATE} </span>
              <span className="key">De-Rate </span>
            </p>
          ) : null}

          {MPU_RELOCATION_COST !== 0 ? (
            <p className="data">
              <span className="value">$ {MPU_RELOCATION_COST} </span>
              <span className="key">MPU Relocation Cost </span>
            </p>
          ) : null}

          {SUB_PANEL_COST !== 0 ? (
            <p className="data">
              <span className="value">$ {SUB_PANEL_COST} </span>
              <span className="key">Sub Panel Cost </span>
            </p>
          ) : null}

          {METER_COST !== 0 ? (
            <p className="data">
              <span className="value">$ {METER_COST} </span>
              <span className="key">Meter Cost </span>
            </p>
          ) : null}

          {EV_COST !== 0 ? (
            <p className="data">
              <span className="value">$ {EV_COST} </span>
              <span className="key">EV Cost Charger </span>
            </p>
          ) : null}

          {EV_MLO !== 0 ? (
            <p className="data">
              <span className="value">$ {EV_MLO} </span>
              <span className="key">EV MLO Cost Charger </span>
            </p>
          ) : null}

          {OVERLAY_COST !== 0 ? (
            <p className="data">
              <span className="value">$ {OVERLAY_COST} </span>
              <span className="key">Overlay Cost </span>
            </p>
          ) : null}

          {/* ---------- roof  */}
          {TootalRoofAmount !== 0 ? (
            <p className="data">
              <span className="value">
                $ {isNaN(TootalRoofAmount) ? 0 : TootalRoofAmount}
              </span>
              <span className="key">Roofing Cost </span>
            </p>
          ) : // PPS
          null}
          {/* PPS for Solar */}
          {value.service_type_id === 1 ? (
            <>
              {" "}
              {TootalRoofAmount !== 0 ? (
                <p className="data">
                  <span className="value">
                    ${" "}
                    {/* {isNaN(TootalRoofAmount)
                  ? 0
                  : Math.ceil(
                      TootalRoofAmount /
                        (value.number_of_old_squares +
                          value.number_of_planes_squares_for_add_planes)
                    )} */}
                    {isNaN(ContractAmount) ? 0 : Math.ceil(ContractAmount)}
                  </span>
                  <span className="key">PPS </span>
                </p>
              ) : null}
            </>
          ) : (
            <span style={{ display: "none" }}></span>
          )}

          {ROOF_JOB_TYPE_COST !== 0 ? (
            <p className="data">
              <span className="value">
                $ {isNaN(ROOF_JOB_TYPE_COST) ? 0 : ROOF_JOB_TYPE_COST}
              </span>
              <span className="key">Job Type Cost </span>
            </p>
          ) : null}

          {/* {EXISTING_ROOF_COST !== 0 ? (
            <p className="data">
              <span className="value">
                $ {isNaN(EXISTING_ROOF_COST) ? 0 : EXISTING_ROOF_COST}{" "}
              </span>
              <span className="key">Existing Roof Cost </span>
            </p>
          ) : null} */}

          {NEW_ROOF_JOB_TYPE_COST !== 0 ? (
            <p className="data">
              <span className="value">
                $ {isNaN(NEW_ROOF_JOB_TYPE_COST) ? 0 : NEW_ROOF_JOB_TYPE_COST}
              </span>
              <span className="key">New Roof Materiels Cost </span>
            </p>
          ) : null}

          {COLOR_COST !== 0 ? (
            <p className="data">
              <span className="value">
                $ {isNaN(COLOR_COST) ? 0 : COLOR_COST}
              </span>
              <span className="key">Color Cost </span>
            </p>
          ) : null}

          {ROOF_PANEL_COST !== 0 ? (
            <p className="data">
              <span className="value">
                $ {isNaN(ROOF_PANEL_COST) ? 0 : ROOF_PANEL_COST}{" "}
              </span>
              <span className="key">New Panel Cost </span>
            </p>
          ) : null}

          {REDECKING !== 0 ? (
            <p className="data">
              <span className="value">
                $ {isNaN(REDECKING) ? 0 : REDECKING}{" "}
              </span>
              <span className="key">Redecking Cost</span>
            </p>
          ) : null}

          {RAFTER !== 0 ? (
            <p className="data">
              <span className="value">$ {isNaN(RAFTER) ? 0 : RAFTER} </span>
              <span className="key">Rafter COst </span>
            </p>
          ) : null}

          {FASCIA_COST !== 0 ? (
            <p className="data">
              <span className="value">
                $ {isNaN(FASCIA_COST) ? 0 : FASCIA_COST}{" "}
              </span>
              <span className="key">Fascia COst </span>
            </p>
          ) : null}

          {STEEP_ROOF_COST !== 0 ? (
            <p className="data">
              <span className="value">
                $ {isNaN(STEEP_ROOF_COST) ? 0 : STEEP_ROOF_COST}{" "}
              </span>
              <span className="key">Steep Roof Cost </span>
            </p>
          ) : null}

          {HVAC_REPLACE_COST !== 0 ? (
            <p className="data">
              <span className="value">
                $ {isNaN(HVAC_REPLACE_COST) ? 0 : HVAC_REPLACE_COST}{" "}
              </span>
              <span className="key">HVAC Replace Cost </span>
            </p>
          ) : null}

          {AIR_VET_OLD_COST !== 0 ? (
            <p className="data">
              <span className="value">
                $ {isNaN(AIR_VET_OLD_COST) ? 0 : AIR_VET_OLD_COST}{" "}
              </span>
              <span className="key">Air vent Replacement Cost </span>
            </p>
          ) : null}

          {NEW_AIR_VENT_COST !== 0 ? (
            <p className="data">
              <span className="value">
                $ {isNaN(NEW_AIR_VENT_COST) ? 0 : NEW_AIR_VENT_COST}{" "}
              </span>
              <span className="key">New Air vent Cost </span>
            </p>
          ) : null}

          {SMOKE_MONITORS_COST !== 0 ? (
            <p className="data">
              <span className="value">
                $ {isNaN(SMOKE_MONITORS_COST) ? 0 : SMOKE_MONITORS_COST}{" "}
              </span>
              <span className="key">Smoke MonitorsCost </span>
            </p>
          ) : null}

          {DOUBLE_HAND_COST !== 0 ? (
            <p className="data">
              <span className="value">
                $ {isNaN(DOUBLE_HAND_COST) ? 0 : DOUBLE_HAND_COST}{" "}
              </span>
              <span className="key">Double hand Cost </span>
            </p>
          ) : null}

          {MONOXID_DETECTORS_COST !== 0 ? (
            <p className="data">
              <span className="value">
                $ {isNaN(MONOXID_DETECTORS_COST) ? 0 : MONOXID_DETECTORS_COST}{" "}
              </span>
              <span className="key">MONOXID DETECTORS COST </span>
            </p>
          ) : null}

          {ROOF_TEAR !== 0 ? (
            <p className="data">
              <span className="value">$ {ROOF_TEAR} </span>
              <span className="key">Carbon monoxide Monitors Cost </span>
            </p>
          ) : null}

          {ROOF_PRESIDENTIAL !== 0 ? (
            <p className="data">
              <span className="value">$ {ROOF_PRESIDENTIAL} </span>
              <span className="key">Roof Presidential Cost </span>
            </p>
          ) : null}

          {ROOF_SPANISH !== 0 ? (
            <p className="data">
              <span className="value">$ {ROOF_SPANISH} </span>
              <span className="key">Roof Spanish Cost </span>
            </p>
          ) : null}

          {ROOF_FLAT !== 0 ? (
            <p className="data">
              <span className="value">$ {ROOF_FLAT} </span>
              <span className="key">Roof Flat Tile Cost </span>
            </p>
          ) : null}

          {ROOF_DECKING !== 0 ? (
            <p className="data">
              <span className="value">$ {ROOF_DECKING} </span>
              <span className="key">Roof Re-Decking Cost</span>
            </p>
          ) : null}

          {ROOF_FASCIA !== 0 ? (
            <p className="data">
              <span className="value">$ {ROOF_FASCIA} </span>
              <span className="key">Fascia Board Cost </span>
            </p>
          ) : null}

          {RAFTER_EXCHANGE !== 0 ? (
            <p className="data">
              <span className="value">$ {RAFTER_EXCHANGE} </span>
              <span className="key">Rafter Exchange Cost</span>
            </p>
          ) : null}

          {AIR_VENT !== 0 ? (
            <p className="data">
              <span className="value">$ {AIR_VENT} </span>
              <span className="key">Air Vent Cost</span>
            </p>
          ) : null}

          {DOUBLE_HAND !== 0 ? (
            <p className="data">
              <span className="value">$ {DOUBLE_HAND} </span>
              <span className="key">DOUBLE HANDt Cost</span>
            </p>
          ) : null}

          {SMOKE !== 0 ? (
            <p className="data">
              <span className="value">$ {SMOKE} </span>
              <span className="key">SMOKE Detectors Cost </span>
            </p>
          ) : null}

          {WARRANTY_EXTEND !== 0 ? (
            <p className="data">
              <span className="value">$ {WARRANTY_EXTEND} </span>
              <span className="key">WARRANTY EXTEND Cost</span>
            </p>
          ) : null}

          {HAVC !== 0 ? (
            <p className="data">
              <span className="value">$ {HAVC} </span>
              <span className="key">HVAC Cost</span>
            </p>
          ) : null}

          {DUCTING !== 0 ? (
            <p className="data">
              <span className="value">$ {DUCTING} </span>
              <span className="key">DUCTING Cost</span>
            </p>
          ) : null}

          {ENERGY !== 0 ? (
            <p className="data">
              <span className="value">$ {ENERGY} </span>
              <span className="key">Energy efficient Cost</span>
            </p>
          ) : null}

          {SOLAR_LIP !== 0 ? (
            <p className="data">
              <span className="value">$ {SOLAR_LIP} </span>
              <span className="key">SOLAR LIP Cost</span>
            </p>
          ) : null}

          {BIRD_NETTING !== 0 ? (
            <p className="data">
              <span className="value">$ {BIRD_NETTING} </span>
              <span className="key">BIRD NETTING Cost</span>
            </p>
          ) : null}

          {BATTERY !== 0 ? (
            <p className="data">
              <span className="value">$ {BATTERY} </span>
              <span className="key">BATTERY Cost</span>
            </p>
          ) : null}

          {total_generators !== 0 ? (
            <p className="data">
              <span className="value">$ {total_generators} </span>
              <span className="key">Generators Cost</span>
            </p>
          ) : null}

          {WARRANTY_COST !== 0 ? (
            <p className="data">
              <span className="value">$ {WARRANTY_COST} </span>
              <span className="key">WARRANTY COST Cost</span>
            </p>
          ) : null}

          {STEEP_COST !== 0 ? (
            <p className="data">
              <span className="value">$ {STEEP_COST} </span>
              <span className="key">STEEP COST Cost</span>
            </p>
          ) : null}

          {ROYALTY !== 0 ? (
            <p className="data">
              <span className="value">$ {ROYALTY} </span>
              <span className="key">Royalties</span>
            </p>
          ) : null}

          {RAFTER_EXCHANGE !== 0 ? (
            <p className="data">
              <span className="value">$ {RAFTER_EXCHANGE} </span>
              <span className="key">Rafter Exchange Cost</span>
            </p>
          ) : null}
          {utility_provider_cost > 0 ? (
            <p className="data">
              <span className="value">$ {utility_provider_cost} </span>
              <span className="key">Utility extra cost</span>
            </p>
          ) : null}
          {treeTriming > 0 ? (
            <p className="data">
              <span className="value">$ {treeTriming} </span>
              <span className="key">Tree Triming cost</span>
            </p>
          ) : null}
        </CalcItem>
      </ClacConatiner>
    </CalcStyled>
  );
};

const CalcStyled = styled.div`
  position: fixed;
  top: 8rem;
  right: 6rem;
  width: 400px;
  height: 60vh;
  padding: 1rem;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  background: white;
  border-radius: 1rem;
  z-index: 5;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 888;
  transition: all 0.4s ease-in-out;
  transform: translate3d(125%, 0, 0);
  @media (max-width: 992px) {
    height: 70vh;
    top: 5rem;
    right: 2rem;
  }
  &.show {
    transform: translate3d(0, 0, 0);
  }

  &::-webkit-scrollbar {
    width: 0.6em;
  }
  &::-webkit-scrollbar-thumb {
    background: #0dc091;
    border-radius: 2rem;
    height: 0.3rem;
    /* width: 0.5rem; */
  }

  h4 {
    margin: 0.5rem 0;
    color: #777;
  }
  h3 {
    color: #0dc091;
    text-align: center;
    color: #888;
  }
  .key {
    font-weight: bold;
  }
`;

const ClacConatiner = styled.div`
  position: relative;
  .totalAmount {
    text-align: center;
    background: #e6efeb;
    border-radius: 2rem;
    margin: 1rem 0;
    padding: 1rem 0;
    position: -webkit-sticky;
    position: sticky;
    top: 20px;
    h2 {
      font-size: 3.5rem;
      color: #0dc091;
      margin: 0rem;
      padding: 0;
    }
    .data {
      font-weight: 600;
      color: #555;
    }
  }
  .ppw {
    margin: 0.5rem 0.5rem 1rem;
    text-align: center;
    background: #e6efeb;
    border-radius: 1rem;
    padding: 0.5rem 0;

    color: #0dc091;
  }
`;
const CalcItem = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 15px;
  /* place-items: center; */
  .data {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    background: #e6efeb;
    border-radius: 1rem;
    padding: 1rem 0;

    .value {
      font-size: 1rem;
      font-weight: 400;
      padding: 0.5rem;
      color: #494949;
    }
    .key {
      font-size: 0.6rem;
      font-weight: 600;
      color: #888;
      padding: 0.3rem;
      text-align: center;
    }
  }
`;
export default CostEstemation;
