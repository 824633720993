import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import styled from "styled-components";
import ContractProposals from "../../components/ContractSolar/ContarctProposals";
import AddProfileFinancial from "./AddProfileFinancial";
import AsignLeadToUser from "./finance/AsignLeadToUser";
import DocumentsTab from "./finance/Documents";
import FinanceInfo from "./finance/FinanceInfo";
import PropsalConatiner from "./PropsalConatiner";

const LeadMainTaps = ({ LeadId, opportunities, contracts }) => {
  const [value, setValue] = useState("1");
  const { pathname } = useLocation();
  console.log("pathname", pathname);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { singlead } = useSelector((state) => state.leads);

  return (
    <>
      <MainTabsStyled>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                className="headerNavLink "
              >
                <NavLink to="solutions">
                  {" "}
                  <Tab label="Solutions " value="1" />
                </NavLink>
                <NavLink to="documents">
                  {" "}
                  <Tab label="Upload utility bill & site media" value="6" />
                </NavLink>
                {/* <NavLink to="financial-info">
                  {" "}
                  <Tab label="Add financial info" value="2" />
                </NavLink> */}
                {/* <NavLink to="financial"> */}{" "}
                {/* <Tab label="Sunlight finance" value="4" /> */}
                {/* </NavLink> */}
                <NavLink to="contracts">
                  {" "}
                  <Tab label="Contracts" value="3" />
                </NavLink>
                {pathname.includes("send-to-leads-bank") ? (
                  <>
                    <NavLink to="send-to-leads-bank">
                      {" "}
                      <Tab label="Send to leads bank" value="4" />
                    </NavLink>
                  </>
                ) : (
                  <></>
                )}
                {/* <NavLink to="finance">
                  {" "}
                  <Tab label="Finance" value="8" />
                </NavLink> */}
                {/* <Tab label="Solutions " value="1" />
                <Tab label="Upload utility bill & site media" value="6" />
                <Tab label="Add financial info." value="2" />
                <Tab label="Sunlight finance" value="4" />
                <Tab label="Contracts" value="3" />
                <Tab label="Assign To User " value="5" /> */}
              </TabList>
            </Box>
            <Outlet></Outlet>

            {/* <TabPanel value="1">
              <PropsalConatiner LeadId={LeadId} opportunities={opportunities} />
            </TabPanel>
            <TabPanel value="2">
              <AddProfileFinancial LeadId={LeadId} />
            </TabPanel>
            <TabPanel value="3">
              <ContractProposals
                contracts={contracts}
                LeadId={LeadId}
                opportunities={opportunities}
              />
            </TabPanel>
            <TabPanel value="4">
              <FinanceInfo />
            </TabPanel>
            <TabPanel value="5">
              <AsignLeadToUser />
            </TabPanel>
            <TabPanel value="6">
              <DocumentsTab LeadId={LeadId} />
            </TabPanel> */}
          </TabContext>
        </Box>
      </MainTabsStyled>
    </>
  );
};

const MainTabsStyled = styled.div`
  margin: 2rem;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  /* @media (max-width: 768px) {
    .css-heg063-MuiTabs-flexContainer {
      flex-direction: column;
    }
  } */
  .headerNavLink {
    a {
      position: relative;
      &.active {
        color: #2bc78c;
        &:after {
          position: absolute;
          width: 100%;
          content: "";
          background-color: #2bc78c;
          bottom: 0;
          left: 0;
          height: 0.1rem;
        }
      }
    }
  }
`;

export default LeadMainTaps;
