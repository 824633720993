import styled from "styled-components";

export const CardStyle = styled.div`

    cursor: pointer;
    background: #f6f6f6;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-direction: column;
    padding: 2rem 1rem .5rem 1rem ;
    font-size: 11px;
    text-align: left;
    text-transform: capitalize;
    color: #4d4d4d;
    box-shadow:    5px 5px 6px #dadada,-5px -5px 6px #ffffff;
    position: relative;

    .time{
      position: absolute;
      right: 0;
      top: 0;
      background: #44a1c5;
      display: flex;
      align-items: center;
      font-size: 12px;
      color:#fff;
      padding:  .2rem .4rem;
      &.green{
        background: #58c5a2;
      }
       &.blue{
        background: #4e9ec5;
      }
       &.yellow{
        background: #e5a922;
      }
       &.red{
        background: #e74848;
      }
      svg{
        font-size: 18px;
        margin-right: 5px;
      }

    }
    &.add {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
    }
    a{
      color:#333
    }
    h4 {
      font-size: 18px;
    }
    .phone,
    .email,
    .location,
    .price{
      display: flex;
      align-items: center;
       .price-data{
        font-size: 15px;
        font-weight: 600;
      color:#4e9ec5;
      margin-left: 5px
      }
    }
    .icon {
      
      display: inline-block;
      margin-right: 0.5rem;
      svg{
        font-size: 18px;
      }
    }
    .project {
      font-size: 16px;
      font-weight: bold;
      width: 100%;
      h5{
        margin: .5rem 0 0 0 ;
      }

      .buttons {
        /* display: grid;
        grid-template-columns:  repeat( auto-fit, minmax(50px, 1fr) );; */
        width: 100%;
        display: flex;
        
        font-weight: lighter;
        font-size: 11px;
        margin-top: 0.5rem;
        border-top: 1px solid #e0e0e0;
        padding: 0.5rem 0;
        overflow-X: scroll;
      &::-webkit-scrollbar {
        width: 5px;
        height: 6px;
      }


    &::-webkit-scrollbar-track {
      background: #eee;
    
    }


    &::-webkit-scrollbar-thumb {
        background: #94e5c0;
        /* height: 500px; */
        border-radius: 10px;
    }


      &::-webkit-scrollbar-thumb:hover {
        background: #748574;
      }
        .opportunity{
          background: #94e5c0;
          display: inline-block;
          padding:  .2rem .5rem;
          margin: .2rem .3rem 0 0;
          border: 2px solid #94e5c0;
          border-radius: .5rem;
          color: #747474;
          transition: all 0.3s;
          &:hover{
            background: transparent;
            
          }
        
        }
        /* span {
          padding: 0.1rem 0.5rem;
          border-radius: 3px;
          margin-right: 10px;
          &.solar {
            background: #94e5c0;
          }
          &.roof {
            background: #addcca;
          }
          &.create {
            background: #dfe6ff;
          }
        } */

      }

      .create{
        text-align: center;
        display: block;
        background: #DFE6FF;
        color:#555;
        font-weight: 400;
        padding: .2rem 0;
        margin: .5rem  0 ;
        font-size: 13px;
        border-radius:.5rem;

      }
    }

    a {
      text-decoration: none;
      .text {
        color: #5a5a5a;
      }
    }
  }

  &.list {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    overflow-y: scroll;
    height: 56vh;
    gap: 0;
    @media (max-width: 992px) {
      overflow-x: scroll;
    }

    .list-name {
      display: flex;
      justify-content: flex-start;
      position: sticky;
      top: 0;
      color: #24272a;
      left: 0;
      border-radius: 10px 10px 0 0;
      @media (max-width: 992px) {
        font-size: 14px;
      }
      span {
        &:first-child {
          flex-basis: 11%;
        }
        &:nth-child(2) {
          flex-basis: 15%;
        }
        flex-basis: 25%;
        text-align: center;

        // @media (max-width: 992px) {
        //   flex-basis: 100%;
        //   overflow-x: scroll;
        // }
      }
    }

    .card {
      min-height: 7vh;
      border-radius: 0;
      flex-direction: row;
      align-items: center;
      padding: 0;
      overflow: initial;

      &.add {
        .text {
          font-weight: bold;
          color: #1dbce5;
        }
      }
      .name,
      .phone,
      .email,
      .location,
      .project {
        flex-basis: 25%;
        width: 100%;
        white-space: initial;
        overflow: hidden;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
        padding-inline: 0.5rem;
      }
      .name,
      .phone {
        flex-basis: 15%;
      }
      .icon {
        display: none;
      }
      .project {
        width: auto;
        .project-hidden {
          display: none;
        }

        .buttons {
          margin-top: 0;
          border-top: 0;
          padding: 0;
          span {
            padding: 0.1rem 0.2rem;
            border-radius: 3px;
          }
          a{
            background: #000;
          }
        }
      }

      &:nth-child(even) {
        background: #fcfdff;
      }

      img {
        width: 40px;
      }
      .text {
        font-size: 14px;
      }
    }
  
`;
