import styled from "styled-components";

export const Section = styled.section`
  .blur {
    padding: 1rem 2rem;
    position: relative;
  }
  .LeadInfo {
    /* backdrop-filter: blur(16px) saturate(180%);
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 100%;
    color: white; */
    width: 100%;
    /* border: 1px solid black; */
    border-radius: 1rem;
    background: white;
    box-shadow: 0px 0px 5px 2px rgba(126, 122, 122, 0.75);

    h3 {
      text-align: center;
      padding: 0.5rem;
    }
    .userData {
      padding: 1rem;
      span {
        color: #7c7b7b;
      }
    }
    .HeaderDiv {
      position: relative;
      &:after {
        position: absolute;
        width: 100%;
        height: 0.0099rem;
        content: "";
        background-color: black;
      }
    }
  }
  .child {
    position: absolute;
    right: 0;
    top: 0;
    button {
      padding: 0.5rem;
      background-color: #94949587;
    }
  }
  .rightDiv {
    width: 100%;
    position: relative;
    border-radius: 1rem;
    background: white;
    box-shadow: 0px 0px 5px 2px rgba(126, 122, 122, 0.75);
    h3 {
      padding: 0.5rem;
    }
    .pdf {
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
      button {
        background-color: white;
        color: black;
        padding: 5px 8px;
        border-radius: 25px;
        cursor: pointer;
      }
    }
    .solutionDetails {
      .HeaderDiv {
        position: relative;
        &:after {
          position: absolute;
          width: 100%;
          height: 0.0099rem;
          content: "";
          background-color: black;
        }
      }
    }
    .data {
      padding: 1rem;
      span {
        color: #7c7b7b;
      }
    }
  }
  .content {
    padding: 0.5rem;
    span {
      color: #7c7b7b;
    }
  }
  .check {
    padding: 0.2rem 2rem;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 520;
  }
`;
export const BackButton = styled.button`
  padding: 10px 10px;
  background-color: black;
  color: white;
  border-radius: 2rem;
  &:hover {
    cursor: pointer;
  }
`;
export const Wrapper = styled.div`
  max-width: 1000px;
  margin: 2rem auto 0;
  .c-timeline__item {
    position: relative;
    display: flex;
    gap: 1.5rem;
    //outline: solid 1px;

    &:last-child {
      .c-timeline__content,
      .c-timeline__content_green,
      .c-timeline__content_red {
        &:before {
          display: none;
        }
      }
    }
  }

  .c-timeline__content {
    flex: 1;
    position: relative;
    order: 1;
    padding-left: 1.5rem;
    padding-bottom: 3rem;

    &:before {
      content: "";
      position: absolute;
      right: 100%;
      top: 0;
      height: 100%;
      width: 2px;
      background-color: lightgrey;
    }

    &:after {
      content: "";
      position: absolute;
      left: calc(0px - 13px);
      top: 0;
      width: 20px;
      height: 20px;
      background-color: #fff;
      /* background-color: black; */
      z-index: 1;
      border: 2px solid lightgrey;
      border-radius: 50%;
      /* background-color: ${(props) =>
        props.completed !== undefined ? "green" : "red"}; */
    }
  }
  .c-timeline__content_green {
    flex: 1;
    position: relative;
    order: 1;
    padding-left: 1.5rem;
    padding-bottom: 3rem;

    &:before {
      content: "";
      position: absolute;
      right: 100%;
      top: 0;
      height: 100%;
      width: 2px;
      background-color: lightgrey;
    }

    &:after {
      content: "";
      position: absolute;
      left: calc(0px - 13px);
      top: 0;
      width: 20px;
      height: 20px;
      background-color: #fff;
      background-color: green;
      z-index: 1;
      border: 2px solid lightgrey;
      border-radius: 50%;
    }
  }
  .c-timeline__content_red {
    flex: 1;
    position: relative;
    order: 1;
    padding-left: 1.5rem;
    padding-bottom: 3rem;

    &:before {
      content: "";
      position: absolute;
      right: 100%;
      top: 0;
      height: 100%;
      width: 2px;
      background-color: lightgrey;
    }

    &:after {
      content: "";
      position: absolute;
      left: calc(0px - 13px);
      top: 0;
      width: 20px;
      height: 20px;
      background-color: #fff;
      background-color: red;
      z-index: 1;
      border: 2px solid lightgrey;
      border-radius: 50%;
    }
  }
  .c-timeline__title {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .c-timeline__desc {
    color: grey;
  }

  time {
    text-align: end;
    flex: 0 0 100px;
    min-width: 0;
    overflow-wrap: break-word;
    padding-bottom: 1rem;
  }
`;
export const Table = styled.table`
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;

  td,
  th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tr:hover {
    background-color: #ddd;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #04aa6d;
    color: white;
  }
`;
