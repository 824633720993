import {
  Container,
  Grid,
  TextField,
  Button,
  InputLabel,
  Switch,
  FormControl,
} from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { EditFProfileAction } from "../../Redux/Actions/EditFinainceProfileAction";
import { UpdateFProfileAction } from "../../Redux/Actions/EditFinainceProfileAction";
import axios from "axios";
import { useSelector } from "react-redux";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { singleLeadAction } from "../../Redux/Actions/singleLeadAction";
import { useLocation } from "react-router-dom";

const AddProfileFinancial = ({ LeadId }) => {
  const { singlead, isSingleLeadLoading } = useSelector((state) => state.leads);
  console.log("singlead", singlead?.financial_profile);
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [images, setImages] = React.useState([]);
  const location = useLocation();
  LeadId = location.pathname.split("/")[3];
  const [data, setData] = useState({
    lead_id: LeadId,
    dob: new Date(
      singlead?.financial_profile?.dob || Date.now()
    )?.toISOString(),
    income: singlead?.financial_profile?.income,
    // is_retired: 0,
    job_title: singlead?.financial_profile?.job_title,
    years: singlead?.financial_profile?.years,
    months: singlead?.financial_profile?.months,
    image_path: "",
    // credit_score: "",
    ssn: singlead?.financial_profile?.ssn,
  });
  const initialValue={
    lead_id: LeadId,
    dob: new Date(
      singlead?.financial_profile?.dob || Date.now()
    )?.toISOString(),
    income: singlead?.financial_profile?.income,
    // is_retired: 0,
    job_title: singlead?.financial_profile?.job_title,
    years: singlead?.financial_profile?.years,
    months: singlead?.financial_profile?.months,
    image_path: "",
    // credit_score: "",
    ssn: singlead?.financial_profile?.ssn,
  }
  React.useEffect(() => {
    setData(initialValue)
  }, [singlead?.financial_profile]);
  const handelSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("lead_id", data.lead_id);
    formData.append("dob", data.dob);
    formData.append("income", data.income);
    // formData.append("is_retired", data.is_retired);
    formData.append("job_title", data.job_title);
    formData.append("years", data.years);
    formData.append("months", data.months);
    formData.append("image_path", data.image_path);
    // formData.append("credit_score", data.credit_score);
    formData.append("ssn", data.ssn);
    if (singlead?.financial_profile == null) {
      await dispatch(EditFProfileAction(formData));
      await dispatch(singleLeadAction(LeadId));
    }
    if (singlead?.financial_profile !== null) {
      await dispatch(
        UpdateFProfileAction(formData, singlead?.financial_profile?.id)
      );
      await dispatch(singleLeadAction(LeadId));
    }
    // await axios
    //   .post("https://crm.boxbyld.tech/api/v1/financial-profile", formData, {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //       Accept: "application/json",
    //       //"Content-Type": "multipart/form-data",
    //     },
    //   })
    //   .then((res) => {
    //     console.log(res.data);
    //   });
    console.log(formData);
  };
  const onKeyDown = (e) => {
    e.preventDefault();
  };
  ///////////////////////////////////////////////////////////
  return (
    <>
      <FinancalProfileStyled>
        <Container>
          <form onSubmit={handelSubmit}>
            <Grid container columns={18} spacing={3}>
              <Grid item sm={18} xs={18}>
                {/* <h2> Profile</h2> */}
              </Grid>

              <Grid item sm={9} xs={18}>
                <TextField
                  fullWidth
                  // required
                  label=" Job Title"
                  value={data.job_title}
                  onChange={(e) =>
                    setData({ ...data, job_title: e.target.value })
                  }
                />
              </Grid>
              <Grid item sm={9} xs={18}>
                {/* <TextField
                  fullWidth
                  required
                  label="Date of Birth"
                  type="date"
                  value={data.dob}
                  onChange={(e) => setData({ ...data, dob: e.target.value })}
                /> */}
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      fullWidth
                      label="Date Of Birth"
                      value={data.dob}
                      // onChange={(e) => setData({ ...data, dob:new Date(e).toISOString().split("T")[0] })}
                      // onChange={(e) => setData({ ...data, dob:e})}
                      onChange={(e) =>
                        setData({ ...data, dob: new Date(e).toISOString() })
                      }
                      renderInput={(params) => (
                        <TextField onKeyDown={onKeyDown} {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item sm={9} xs={18}>
                <TextField
                  fullWidth
                  // required
                  type="number"
                  label="Income"
                  value={data.income}
                  onChange={(e) => setData({ ...data, income: e.target.value })}
                />
              </Grid>

              {/* <Grid item sm={9} xs={18}>
                <InputLabel>is retired</InputLabel>
                <Switch
                  checked={data.is_retired}
                  onChange={(e) =>
                    setData({
                      ...data,
                      is_retired: e.target.checked,
                    })
                  }
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Grid> */}
              <Grid item sm={9} xs={18}>
                <TextField
                  fullWidth
                  // required
                  type="number"
                  label="how many years in Job"
                  value={data.years}
                  onChange={(e) => setData({ ...data, years: e.target.value })}
                />
              </Grid>
              <Grid item sm={9} xs={18}>
                <TextField
                  fullWidth
                  // required
                  type="number"
                  label="How many Months in Job"
                  value={data.months}
                  onChange={(e) => setData({ ...data, months: e.target.value })}
                />
              </Grid>
              <Grid item sm={9} xs={18}>
                <TextField
                  fullWidth
                  // required
                  label="SSN last 4 digits"
                  type="number"
                  value={data.ssn}
                  onChange={(e) => setData({ ...data, ssn: e.target.value })}
                />
              </Grid>
              {/* <Grid item sm={9} xs={18}>
                <TextField
                  fullWidth
                  required
                  type="number"
                  label="Edit Your Credit Score"
                  value={data.credit_score}
                  onChange={(e) =>
                    setData({ ...data, credit_score: e.target.value })
                  }
                />
              </Grid> */}
              <Grid item sm={18} xs={18}>
                <label>
                  Driving License{" "}
                  <span style={{ fontSize: "0.9rem" }}>
                    (Financial Institution may request it){" "}
                  </span>
                </label>
                <TextField
                  fullWidth
                  // required
                  type="file"
                  className="imageUploade"
                  onChange={(e) =>
                    setData({ ...data, image_path: e.target.files[0] })
                  }
                />
              </Grid>
            </Grid>

            <Button variant="contained" className="btn-edit" type="submit">
              Save
            </Button>
          </form>

          {/* <Grid item sm={18} xs={18}>
            <h3 style={{ textAlign: "center", paddingBottom: "30px" }}>
              Financial Information
            </h3>
          </Grid>
          <Grid container lg={18} sm={18} xs={18} spacing={3}>
            <Grid item lg={9} md={9} sx={9}>
              Job Title:<span> {singlead?.financial_profile?.job_title}</span>
            </Grid>
            <Grid item lg={9} md={9} sx={9}>
              Date Of Birth: <span>{singlead?.financial_profile?.dob}</span>
            </Grid>
            <Grid item lg={9} md={9} sx={9}>
              Income:<span> {singlead?.financial_profile?.income}</span>
            </Grid>
            <Grid item lg={9} md={9} sx={9}>
              How many Years in Job :
              <span>{singlead?.financial_profile?.years}</span>
            </Grid>
            <Grid item lg={9} md={9} sx={9}>
              How many Months in Job :{" "}
              <span>{singlead?.financial_profile?.months}</span>
            </Grid>
            <Grid item lg={9} md={9} sx={9}>
              SSN : <span>XXXXX-{singlead?.financial_profile?.ssn}</span>
            </Grid>
          </Grid>
          <Grid item sm={18} xs={18}>
            <h3 style={{ textAlign: "center", paddingTop: "25px" }}>Files</h3>
            <a href={singlead?.financial_profile?.image_path} target="_blank">
              Downlaod
            </a> */}
            {/* {images.length == 0 ? (
        <span>No photos for the user</span>
      ) : (
        <div>
          <h3>Photos</h3>
          <br />
          {images.map((e) => {
            return (
              <Gallery>
                <Item
                  original={e?.media_url?.display}
                  width="1024"
                  height="768"
                >
                  {({ ref, open }) => (
                    <img
                      style={{ verticalAlign: "middle", cursor: "pointer",width:"15%",padding:"0 10px" }}
                      ref={ref}
                      onClick={open}
                      src={e?.media_url?.thumbnail}
                    />
                  )}
                </Item>
              </Gallery>
            );
          })}
        </div>
      )} */}
          {/* </Grid> */}
        </Container>
      </FinancalProfileStyled>
    </>
  );
};

const FinancalProfileStyled = styled.div`
  h2 {
    color: #6faebd;
  }
  .btn-edit {
    display: block;
    width: 100%;
    margin: 2rem 0;
    padding: 0.7rem;
    border-radius: 1.5rem;
  }
  span {
    color: gray;
  }
`;

export default AddProfileFinancial;
