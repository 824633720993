import {
  LocalPhone,
  Email,
  AddLocationAlt,
  AccessTime,
  MonetizationOn,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { timeClass } from "../../../../../utilty/utilty";
import { CardStyle } from "./LeadCardStyled";
const CustomerCard = ({
  id,
  name,
  days,
  lastName,
  E_mail,
  phone,
  price,
  opportunities,
  street,
}) => {
  return (
    <>
      <CardStyle key={id}>
        <Link to={`/profilelead/${id}/solutions`}>
          <div className={timeClass(days)}>
            <AccessTime /> {days != 0 ? `${days} days` : <span>new</span>}
          </div>
          <h4 className="name">
            {name} {lastName}
          </h4>
          <div className="phone">
            <span className="icon">
              <LocalPhone />
            </span>
            {phone}
          </div>
          <div className="email">
            <span className="icon">
              <Email />
            </span>
            {E_mail}
          </div>
          <div className="location">
            <span className="icon">
              <AddLocationAlt />
            </span>
            {street}
          </div>
          <div className="price">
            <span className="icon">
              <MonetizationOn />
            </span>
            Leads Value <span className="price-data"> ${price}</span>
          </div>
        </Link>
        <div className="project">
          {opportunities?.length !== 0 ? (
            <>
              <h5>Projects</h5>
              <div className="buttons">
                {opportunities.map((prop) => (
                  <div key={prop.id}>
                    <Link
                      className="opportunity"
                      to={`/profilelead/${prop.lead_id}`}
                    >
                      {prop.service_type.title}
                    </Link>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <br />
          )}

          <Link className="create" to={`${id}`}>
            Create Solutions
          </Link>
        </div>
      </CardStyle>
    </>
  );
};

export default CustomerCard;
