import { ThumbnailCard } from "../RightStyled";
import documents from "../../../assets/documents.png";

const SolutionCard = ({ SalseUser }) => {
  return (
    <>
      <ThumbnailCard>
        <div className="icon solution">
          <img src={documents} alt="achivements" />
        </div>
        <div className="data">
          <div className="title">opportunities </div>
          <div className="number">{SalseUser?.opportunities_count}</div>
        </div>
      </ThumbnailCard>
    </>
  );
};

export default SolutionCard;
