import {
  MOSAIC_GET_ALL_OFFERS,
  MOSAIC_GET_PAYMENTS,
  MOSIAC_ALL_DATA,
  MOSIAC_CHECK_OFFER,
  MOSIAC_CREATE_PROJECT,
  MOSIAC_GET_STATUS,
  MOSIAC_GET_TIMELINE,
  MOSIAC_SHARE_APPLICATION,
  MOSIAC_SUBMIT_OFFERNOTFOUND,
  MOSIAC_GET_OFFERNOTFOUND,
  MOSIAC_GET_OFFERFOUND,
  MOSIAC_SUBMIT_OFFERFOUND,
  MOSIAC_CHECK_OFFER_STATUS,
  MOSIAC_DOWNLOAD_CONTRACT_DOCUMENT,
} from "../Actions/MosaicAction";

const inittialState = {
  allOffers: [],
  payments: "",
  allMosiacData: "",
  createProject: "",
  timeLine: "",
  status: "",
  shareProject: "",
  checkOffer: "",
  offersNotFound: "",
  offerNotFoundSubmit: "",
  offerFound: "",
  offerFoundSubmit: "",
  checkOfferStatus: "",
  loanStatus: "",
  doucment: "",
};
const MosiacReducer = (state = inittialState, action) => {
  switch (action.type) {
    case MOSAIC_GET_ALL_OFFERS:
      return {
        ...state,
        allOffers: action.payload,
      };
    case MOSAIC_GET_PAYMENTS:
      return {
        ...state,
        payments: action.payload,
      };
    case "MOSIAC_ALL_DATA":
      return {
        ...state,
        allMosiacData: action.allMosiacData,
      };
    case MOSIAC_CREATE_PROJECT:
      return {
        ...state,
        createProject: action.payload,
      };
    case MOSIAC_SHARE_APPLICATION:
      return {
        ...state,
        shareProject: action.payload,
      };
    case MOSIAC_GET_STATUS:
      return {
        ...state,
        status: action.payload,
      };
    case MOSIAC_GET_TIMELINE:
      return {
        ...state,
        timeLine: action.payload,
      };
    case MOSIAC_CHECK_OFFER:
      return {
        ...state,
        checkOffer: action.payload,
      };
    case MOSIAC_GET_OFFERNOTFOUND:
      return {
        ...state,
        offersNotFound: action.payload,
      };
    case MOSIAC_SUBMIT_OFFERNOTFOUND:
      return {
        ...state,
        offerNotFoundSubmit: action.payload,
      };
    case MOSIAC_GET_OFFERFOUND:
      return {
        ...state,
        offerFound: action.payload,
      };
    case MOSIAC_SUBMIT_OFFERNOTFOUND:
      return {
        ...state,
        offerFoundSubmit: action.payload,
      };
    case MOSIAC_CHECK_OFFER_STATUS:
      return {
        ...state,
        loanStatus: action.payload,
      };
    case MOSIAC_DOWNLOAD_CONTRACT_DOCUMENT:
      return {
        ...state,
        doucment: action.payload,
      };

    default:
      return { ...state };
  }
};
export default MosiacReducer;
