import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./helper/index.js";

export const AddersFormApi = createApi({
  reducerPath: "AdderForm",
  tagTypes: ["AddersForm", "roofingProposals"],
  baseQuery,
  endpoints: (builder) => ({
    getUtlities: builder.query({
      query: (stateID) => ({
        method: "GET",
        url: `/states/providers/${stateID}`,
      }),
      providesTags: ["AddersForm"],
    }),
    getPanels: builder.query({
      query: () => ({
        method: "GET",
        url: `/panels`,
      }),
      providesTags: ["AddersForm"],
    }),
    getFormData: builder.query({
      query: () => ({
        method: "GET",
        url: `/roofing-proposals/form-data`,
      }),
      providesTags: ["roofingProposals"],
    }),
    getDistributor: builder.query({
      query: () => ({
        method: "GET",
        url: `/panels`,
      }),
      providesTags: ["AddersForm"],
    }),
    getDistance: builder.query({
      query: ({
        destinations_lat,
        destinations_lng,
        origins_lat,
        origins_lng,
      }) => ({
        method: "GET",
        url: `/google-maps/distance-matrix`,
        params: {
          destinations_lat,
          destinations_lng,
          origins_lat,
          origins_lng,
        },
      }),
      providesTags: ["AddersForm"],
    }),
    clacAdders: builder.mutation({
      query: (body) => ({
        url: `installer-adders`,
        method: "post",
        body,
      }),
    }),
  }),
});

export const {
  useGetUtlitiesQuery,
  useLazyGetUtlitiesQuery,
  useGetPanelsQuery,
  useGetDistanceQuery,
  useLazyGetDistanceQuery,
  useClacAddersMutation,
  useGetFormDataQuery,
} = AddersFormApi;
