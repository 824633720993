import { FormHelperText, Grid } from "@mui/material";
import React from "react";
import { BigSection } from "../firstForm/style";
import CheckBox from "../../../../../components/inputs/checkBox";
import RadioButton from "../../../../../components/inputs/radio";
import ImageUploader from "../../../../../components/inputs/imageUploader";
import StanderInput from "../../../../../components/inputs/standerInput";
import { Controller } from "react-hook-form";
import { useGetSalesCompaniesQuery } from "../../../../../Redux/toolKit/salesForm";
import SelectInput from "../../../../../components/inputs/SelectInput/SelectInput";
const data = [{}];
const radioList = [
  { id: "individual", title: "individual" },
  { id: "company", title: "company" },
];
const radioListCompanyExits = [
  { id: "1", title: "true" },
  { id: "0", title: "false" },
];

const SecondForm = ({ methods }) => {
  const watchBusinessType = methods.watch("business_type");
  const watchCompany_exist = methods.watch("company_exist");
  console.log("watchBusinessType", watchBusinessType);
  console.log("watchCompany_exist", watchCompany_exist);
  const { data, isLoading, isFetching } = useGetSalesCompaniesQuery("");
  console.log("data,", data?.data);
  console.log("isLoading,", isLoading);
  const companiesArr = data?.data?.map((e) => {
    return { id: e?.id, name: e?.title };
  });
  if (isLoading || isFetching) {
    return <h1>Loading...</h1>;
  }
  return (
    <BigSection>
      <Grid container columns={12} spacing={3} alignItems={"center"}>
        <Grid item xs={12} md={6} lg={6}>
          <RadioButton
            list={radioList}
            name={"business_type"}
            Label={"Business Type"}
          ></RadioButton>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          {watchBusinessType === "individual" ? (
            <>
              <ImageUploader
                Label={"Upload w9 file"}
                name={"w9pdf"}
                required={true}
              ></ImageUploader>
            </>
          ) : null}
          {/* </Grid> */}
        </Grid>
        {watchBusinessType === "company" ? (
          <>
            <Grid item xs={12} md={6} lg={6}>
              <SelectInput
                data={companiesArr}
                name={"company_id"}
                label={"Companies"}
              ></SelectInput>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <RadioButton
                name={"company_exist"}
                Label={"Can you find your company ?"}
                list={radioListCompanyExits}
              ></RadioButton>
            </Grid>
            {watchCompany_exist === "0" && (
              <>
                <Grid item xs={12} md={6} lg={6}>
                  <StanderInput
                    fullWidth
                    id="business"
                    name="business"
                    Label="Business Name"
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <StanderInput
                    fullWidth
                    id="company_email"
                    type="email"
                    name="company_email"
                    Label="Company email"
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <StanderInput
                    fullWidth
                    id="company_address"
                    name="company_address"
                    Label="Company address"
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <StanderInput
                    fullWidth
                    id="business"
                    name="bank_name"
                    Label="Bank name "
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <StanderInput
                    fullWidth
                    id="business"
                    name="routing"
                    Label="Bank routing number"
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <ImageUploader
                    Label={"Upload company logo"}
                    name={"company_logo"}
                    required={true}
                  ></ImageUploader>
                </Grid>
              </>
            )}
          </>
        ) : null}
      </Grid>
    </BigSection>
  );
};

export default SecondForm;
