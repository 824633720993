import styled from "styled-components";

export const FilterLeadStyled = styled.div`
  .filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    widows: 100%;
    margin: 1rem 0;
    padding: 0.5rem;
    border-radius: 1rem;
    background-color: #f6f6f6;
    &__left {
      flex-basis: 80%;
      padding: 0.5rem;

      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 10px;

      ul {
        list-style: none;
        flex-basis: 50%;
        display: flex;
        /* justify-content: space-between; */

        li {
          text-transform: capitalize;
          color: #707070;
          font-weight: 600;
          margin: 0 0.9rem;
          cursor: pointer;
          a {
            color: #888;
            .badge {
              margin-left: 1rem;
              span {
                background: #888;
              }
            }

            &.active {
              color: #0dbf7c;
            }
          }
          @media screen and (max-width: 768px) {
            margin-right: 0.4rem;
            font-size: 2vw;
          }
        }
      }
      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        color: #707070;
        @media screen and (max-width: 768px) {
          font-size: 2vw;
          img {
            width: 15px;
          }
        }
        span {
          margin-inline: 0.5rem;
          text-transform: capitalize;
          font-weight: 600;
        }
      }
    }

    &__center {
      display: flex;
      align-items: center;
      @media screen and (max-width: 768px) {
        display: none;
      }
      .switch {
        position: relative;
        display: inline-block;
        width: 110px;
        height: 37px;
        overflow: hidden;
        .slider {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 74px;
          height: 36px;
          border-radius: 50%;
          cursor: pointer;
          border-radius: 2rem;
          transition: 0.4s;
          background: #1dbce5;

          .text {
            width: 100%;
            height: 100%;
            display: flex;
            padding: 0 7px;
            font-size: 12px;
            align-items: center;
            justify-content: space-between;
            transition: 0.3s;
            span {
              color: #fff;
              font-weight: bold;
            }
            .yes {
              visibility: hidden;
            }
          }

          &::before {
            content: "";
            position: absolute;
            left: 5px;
            top: 5px;
            width: 22px;
            height: 22px;
            border-radius: 50%;
            background: #ffffff;
            border: 1px solid #1dbce5;
            transition: 0.4s;
          }
        }
        input {
          display: none;
        }

        input:checked {
          & + .slider {
            background: #24272a;

            &::before {
              transform: translateX(40px);
              background: #ffffffa4;
            }
            .text {
              .yes {
                color: #fff;
                visibility: visible;
              }
              .no {
                visibility: hidden;
              }
            }
          }
        }
      }
    }

    &__right {
      font-size: 2rem;
      cursor: pointer;
      color: #5a5a5a;
    }
  }
`;
