import {
  Business,
  LocationOn,
  Mail,
  MobiledataOff,
  MoneyRounded,
  Paid,
  Person,
  PhoneIphone,
  Work,
} from "@mui/icons-material";
import { Grid } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Loading from "../../../../components/loading/Loading";
import { getMemberData } from "../../../../Redux/Actions/EditUserAction";
import TeamMemberTabs from "./TeamMemberTabs";
const TeamMember = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { teamMember, isLoading } = useSelector((state) => state.editUser);
  const TeamsUserData = teamMember?.user;
  const TeamUserLeads = teamMember?.leads;

  useEffect(() => {
    if (id) {
      dispatch(getMemberData(id));
    }
  }, [id]);

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && (
        <TeamMamberStyle>
          <div className="heading">
            <div className="name">
              <h2>{TeamsUserData?.name}</h2>
              <p>{TeamsUserData?.mantras}</p>
            </div>
            <div className="image">
              <img src={TeamsUserData?.image_path} alt="" />
            </div>
          </div>
          <div className="mainContiner">
            <Grid container columns={18} spacing={3}>
              <Grid item lg={5} md={18} sm={18} xs={18}>
                <div className="mainData">
                  <div className="items">
                    <span className="key">
                      <Work className="user" />
                    </span>
                    <span className="value">
                      {" "}
                      {TeamsUserData?.roles[0].title}
                    </span>
                  </div>
                  <div className="items">
                    <span className="key">
                      <Paid className="money" />
                    </span>
                    <span className="value"> {TeamsUserData?.ppw}</span>
                  </div>
                  <div className="items">
                    <span className="key">
                      <PhoneIphone className="phone" />
                    </span>
                    <span className="value"> {TeamsUserData?.phone}</span>
                  </div>
                  <div className="items">
                    <span className="key">
                      <Mail className="mail" />
                    </span>
                    <span className="value"> {TeamsUserData?.email}</span>
                  </div>
                  <div className="items">
                    <span className="key">
                      <LocationOn className="location" />
                    </span>
                    <span className="value"> {TeamsUserData?.address}</span>
                  </div>
                  <div className="items">
                    <span className="key">
                      <Business className="business" />
                    </span>
                    <span className="value"> {TeamsUserData?.business}</span>
                  </div>
                </div>
              </Grid>
              <Grid item lg={13} md={18} sm={18} xs={18}>
                <div className="extraData">
                  <TeamMemberTabs leadId={id} leads={TeamUserLeads} />
                </div>
              </Grid>
            </Grid>
          </div>
        </TeamMamberStyle>
      )}
    </>
  );
};
const TeamMamberStyle = styled.div`
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  margin: 1rem;
  padding: 1rem;
  border-radius: 2rem;
  .heading {
    height: 20vh;
    width: 100%;
    position: relative;
    display: grid;
    place-items: center;
    .name {
      z-index: 5;
      text-align: center;
      h2 {
        color: #4a737e;
        font-size: 2rem;
      }
      p {
        font-size: 1.2rem;
        color: #999;
      }
    }
    .image {
      background: #003c4c;
      border-radius: 2rem 1rem 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
      border-radius: 50%;
      outline: none;
      background: #003c4c;
      padding: 0.5rem;
      position: absolute;
      left: 0;
      bottom: -30px;
    }
  }
  .mainContiner {
    margin: 3rem 0 1rem;
    padding: 2rem;
    .mainData {
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
      padding: 1rem;
      border-radius: 1rem;
      margin: 0.5rem;
      .items {
        /* display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
        .key {
          font-weight: bold;
          margin-right: 0.3rem;
          color: #003c4c9d;
        }
        .value {
          color: #888;
          font-size: 0.85rem;
        } */
        display: flex;
        align-items: center;
        margin: 0.5rem;
        border-radius: 2rem;
        background: #8888881c;
        backdrop-filter: blur(20px);
        overflow: hidden;
        padding: 0.5rem;
        font-size: 0.75rem;
        color: #222;
        .key {
          display: flex;
          align-items: center;
          margin-right: 0.1rem;
          /* font-weight: bold; */
          svg {
            color: #fff;
            padding: 0.2rem;
            border-radius: 50%;
            font-size: 1rem;
            width: 30px;
            height: 30px;
            margin-right: 0.5rem;
            &.user {
              background: #d32f2f;
            }
            &.phone {
              background: #003c4c;
            }

            &.money {
              background: #67ac5e;
            }
            &.mail {
              background: #03a9f4;
            }
            &.location {
              background: #ffa000;
            }
            &.business {
              background: #616161;
            }
          }
        }
        .name {
          font-size: 1.1rem;
          color: #ffffff;
        }
      }
    }
    .extraData {
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
      padding: 1rem;
      border-radius: 1rem;
      margin: 0.5rem;
    }
  }
`;
export default TeamMember;
