import { useDispatch, useSelector } from "react-redux";
import ComingSoon from "../../../../components/soon/CommingSoon";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  FormHelperText,
  Grid,
  InputLabel,
  Tab,
  TextField,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import {
  getParentAchievement,
  getSalesAchievement,
  postAchievement,
} from "../../../../Redux/Actions/achievementsActions";
import { useEffect } from "react";
import { Bigsection, Loading, MyInputLable } from "./style";
import { TabContext, TabList } from "@mui/lab";
import { NavLink, Outlet } from "react-router-dom";
import { MainTabsStyled } from "./style";
const Goals = () => {
  const dispatch = useDispatch();
  const {
    salesAcievement,
    parentAchievement,
    editAcievement,
    singleAcievement,
    achievement,
  } = useSelector((state) => state?.achievements);
  const salesInfo = JSON.parse(localStorage.getItem("salesAgent"));
  const checkRoleAdmin = () => {
    return salesInfo.roles.some((item) => item.title === "admin");
  };

  const { isLoading } = useSelector((state) => state.proposal);
  // console.log("isLoading", isLoading);
  // console.log("salesAcievement", salesAcievement);
  // console.log("parentAchievement", parentAchievement);
  const getApis = async () => {
    await dispatch(getParentAchievement());
    await dispatch(getSalesAchievement());
    await checkRoleAdmin();
  };
  useEffect(() => {
    getApis();
  }, []);

  return (
    <>
      {isLoading ? (
        <>
          <Loading>
            <span className="loader"></span>
          </Loading>
        </>
      ) : (
        <>
          {" "}
          <Bigsection>
            {" "}
            <div>
              <MainTabsStyled>
                <Box sx={{ width: "100%", typography: "body1" }}>
                  <TabContext>
                    {salesInfo?.children.length > 0 ? (
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList
                          // onChange={handleChange}
                          variant="scrollable"
                          scrollButtons="auto"
                          aria-label="scrollable auto tabs example"
                          className="headerNavLink "
                        >
                          <NavLink to="create-target">
                            {" "}
                            <Tab label="Create Target" />
                          </NavLink>
                          <NavLink to="my-target">
                            {" "}
                            <Tab label="My targets" />
                          </NavLink>
                        </TabList>
                      </Box>
                    ) : null}

                    <Outlet></Outlet>
                  </TabContext>
                </Box>
              </MainTabsStyled>
            </div>
          </Bigsection>
        </>
      )}
    </>
  );
};

export default Goals;
