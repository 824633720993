import React from "react";
import MyCoins from "./components/myCoins";
import History from "./components/history";
import CheckOut from "./components/checkOut";
import { useGetCoinsQuery } from "../../Redux/toolKit/coins";
import { Bigsection } from "./style";
const Wallet = () => {
  return (
    <Bigsection>
      <MyCoins />
      {/* <CheckOut /> */}
      <History />
    </Bigsection>
  );
};

export default Wallet;
