import styled from "styled-components";
import React from "react";
import Grid from "@mui/material/Grid";
import footerbackimg from "../../../../assets/footerbackimg.png";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MapIcon from '@mui/icons-material/Map';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
const BigSection = styled.div`
  text-align: center;
  background-image: url(${footerbackimg});
  background-repeat: no-repeat;
  background-position: center;
  color: white;
  padding: 100px 0;
  transition: 1s all;
`;

const LogoHeader = styled.p`
  text-align: left;
  font-size: 35px;
`;
const Description = styled.p`
  text-align: left;
`;
const ContactDescription = styled.p`
text-align: center;
  p{
    display:flex;
  align-items:center;
  flex-wrap: wrap;
  word-spacing: 3px;
  justify-content: center;

  }`
const ContactUs = styled.p`
  font-size: 25px;
`;
const SignUP = styled.p`
  text-align: left;
  font-size: 25px;
`;
const SingUpInput = styled.input`
  width: 100%;
  padding: 10px 0;
`;

const FormContainer = styled.div`
  position: relative;
`;
const SendButton = styled.button`
  position: absolute;
  right: 0px;
  padding: 12px 20px;
  border: none;
  background-color: #2c2d30;
  color: white;
`;
const Footer = () => {
  return (
    <BigSection id="footer">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item lg={3} md={3} sm={4} xs={12}>
          <LogoHeader>BoxBYLD </LogoHeader>
          <Description>
            Boxbyld extensive range of robust and reliable products backed by
            its cutting-edge R&D and more than two decades of expertise are
            designed to performance.
          </Description>
        </Grid>
        <Grid item lg={3} md={3} sm={4} xs={12}>
          <ContactUs>Contact Us</ContactUs>
          <ContactDescription>
           
            <p><EmailIcon></EmailIcon> support@allpremiumhome.com</p>
            <p> <LocalPhoneIcon></LocalPhoneIcon>: 1-818-641-1240</p>
            <p><MapIcon></MapIcon>  7160 Asman Avenue, CA 95355 </p>

          </ContactDescription>
        </Grid>
        <Grid item lg={3} md={3} sm={4} xs={12}>
          <SignUP>Sign up today!</SignUP>
          <Description>stay up to date on the latest</Description>
          <FormContainer>
            <SingUpInput placeholder="Enter Your Email"></SingUpInput>
            <SendButton>Send</SendButton>
          </FormContainer>
        </Grid>
      </Grid>
    </BigSection>
  );
};
export default Footer;
