//import the library

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GlobalStyle } from "./components/GlopleStyled";
import { loadUser } from "./Redux/Actions/authActions";
import { Routes, Route, Navigate } from "react-router-dom";
// import LogRocket from 'logrocket';

//import pages

import Login from "./pages/Login/Login";
import AddLeadForm from "./pages/Left/Routs/customers/AddLead/AddLeadForm";
import Calender from "./pages/Left/Routs/calendar/Calender";
import Tasks from "./pages/Left/Routs/tasks/Tasks";
import Goals from "./pages/Left/Routs/goals/Goals";
import Meetings from "./pages/Left/Routs/meetings/Meetings";
import Training from "./pages/Left/Routs/training/Training";
import Reports from "./pages/Left/Routs/reports/Reports";
import Leads from "./pages/Left/Routs/customers/Leads";
import Home from "./pages/Home";
import AddProposals from "./pages/Left/Routs/customers/AddProposals/AddProposals";
import AddServices from "./pages/Left/Routs/customers/AddProposals/AddServices";
import ProposalsInfo from "./pages/proposals/PorposalsInfo";
import SalesProfile from "./pages/Profile/SolarProfile/SalesProfile";
import MainContentProfile from "./pages/Profile/SolarProfile/MainContent";
import EditProfileMain from "./pages/Profile/SolarProfile/EditProfile";

///////////////////

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LeadProfile from "./pages/proposals/LeadProfile";
import ContractSolar from "./components/ContractSolar/ContractSolar";
import HomePage from "./pages/Home/Home";
import SalesChangePassword from "./pages/Profile/SolarProfile/SalesChangePassword";
import EditLeadProfile from "./pages/proposals/finance/EditLeadProfile";
import ForgotPassword from "./pages/Login/ForgotPassword";
import ResetPassword from "./pages/Login/ResetPassword";
import TeamMember from "./pages/Profile/SolarProfile/TeamsMember/TeamMember";
import LeadProfileChild from "./pages/Profile/SolarProfile/TeamsMember/LeadprofileChild";
import ProposalAllInfo from "./pages/proposals/ProposalAllInfo";
import SolutionDetails from "./pages/Profile/SolarProfile/TeamsMember/SolutionDetails";
import ArchiveLeads from "./pages/Left/Routs/customers/ArchiveLeads";
import CreateUser from "./pages/Profile/SolarProfile/TeamsMember/CreateUser";
import TraningVideos from "./pages/Left/Routs/training/TraningVideos";
import InstallPartnerPage from "./pages/installParterPage/installPartnerPage";
import SingleRoofingSolution from "./pages/proposals/SingleRoofingSolution";
import SingleContract from "./pages/proposals/SingleContract";
import SalesPage from "./pages/SalesPage/SalesPage";
import EditProposal from "./pages/Left/Routs/customers/AddProposals/SolarForm/EditProposal";
import NavCalender from "./components/calendar/calenderComponents";
import CalnderPage from "./pages/Left/Routs/calendar/CalnderPage";
import GoogleCalender from "./pages/Left/Routs/calendar/GoogleCalender";
import LeadBanks from "./components/LeadBanks/LeadBanks";
import PayLeads from "./components/LeadBanks/PayLeads";
import AllMessages from "./pages/Messages/Messages";
import ListOfMessages from "./pages/Messages/ListOfMessages";
import NotFound from "./pages/Messages/NotFound";
import MessageChat from "./components/Message/MessageChat";
import PropsalConatiner from "./pages/proposals/PropsalConatiner";
import DocumentsTab from "./pages/proposals/finance/Documents";
import AsignLeadToUser from "./pages/proposals/finance/AsignLeadToUser";
import FinanceInfo from "./pages/proposals/finance/FinanceInfo";
import AddProfileFinancial from "./pages/proposals/AddProfileFinancial";
import ContractProposals from "./components/ContractSolar/ContarctProposals";
import Finance from "./pages/proposals/finance/Finance";
import { Redirect } from "react-router-dom";
import Alert from "./components/Akerts/Alert";
import SendToLeadBank from "./pages/proposals/finance/SendToLeadBank";
import CreateTarget from "./pages/Left/Routs/goals/CreateTarget";
import MyTargets from "./pages/Left/Routs/goals/MyTargets";
import License from "./pages/Profile/SolarProfile/licenses";
import CreateLicense from "./pages/Profile/SolarProfile/licenses/CreateLicense";
import SalesLicense from "./pages/Profile/SolarProfile/licenses/SalesLicense";
import Wallet from "./pages/wallet";
import NewLeadsBank from "./pages/newLeadsBank";
import InstallerAdders from "./pages/newProposal/components/installersAdders";
import SalesRegister from "./pages/salesRegister/page";
import PdfPage from "./pages/pdfUploader";
import UploadTemplate from "./pages/Profile/SolarProfile/uploadTemplate";
import ShowTemplates from "./pages/Profile/SolarProfile/showTemplates";
import EditAdders from "./pages/Profile/SolarProfile/editAddes";
import InviteUser from "./pages/Profile/SolarProfile/inviteUser";
import Invitaion from "./pages/invitation";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);

  const { token, authLogIn, title } = useSelector((state) => state.auth);
  useEffect(() => {
    if (token && authLogIn) {
      console.log("token", token);
      console.log("authLogIn", authLogIn);
    }
  }, [token]);
  const RoleUser = title === "admin";

  const salesInfo = JSON.parse(localStorage.getItem("salesAgent"));
  console.log("token", token);
  console.log("authLogIn", authLogIn);
  return (
    <>
      <GlobalStyle />
      <Routes>
        {!token && !authLogIn ? (
          <>
            <Route path="*" element={<Navigate to="/login" />}></Route>
            <Route path="/" element={<HomePage />} />
            <Route path="login" element={<Login />} />
            <Route path="login/forgot-password" element={<ForgotPassword />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="invitation/:id" element={<Invitaion />} />
            <Route
              path="installPartner"
              element={<InstallPartnerPage />}
            ></Route>
            <Route path="sale-form" element={<SalesRegister />}></Route>
            <Route path="pdf-upload" element={<PdfPage />}></Route>
          </>
        ) : (
          <>
            <Route path="/" element={<Navigate to="/crm/leads" />} />
            <Route path="/login" element={<Navigate to="/crm/leads" />} />
          </>
        )}
        {/* <Route path="/:id" element={<Error />} /> */}
        {!token && !authLogIn ? (
          <>
            <Route path="/crm" element={<Login />} />
            <Route path="/crm/leads" element={<Login />} />
          </>
        ) : (
          <>
            <Route path="/crm/*" element={<Home />}>
              {/* <Route path="*" element={<h1>Not Found</h1>}></Route> */}
              <Route path="leads" element={<Leads />} />
              {/* <Route path="get-leads" element={<LeadBanks />} /> */}
              <Route path="get-leads" element={<NewLeadsBank />} />
              <Route path="archive" element={<ArchiveLeads />} />
              <Route path="leads/:id" element={<AddServices />} />
              <Route path="leads/:id/:id" element={<AddProposals />} />
              <Route
                path="editSolution/:id/:id/:id"
                element={<EditProposal />}
              />
              <Route path="addlead" element={<AddLeadForm />} />

              <Route path="calendar" element={<CalnderPage />}>
                <Route path="bigcalender" element={<Calender />} />
                <Route path="googlecalender" element={<GoogleCalender />} />
              </Route>
              <Route path="tasks" element={<Tasks />} />
              <Route path="goals" element={<Goals />}>
                <Route path="create-target" element={<CreateTarget />} />
                <Route path="my-target" element={<MyTargets />} />
                <Route
                  index
                  element={
                    salesInfo?.children.length > 0 ? (
                      <Navigate to="/crm/goals/create-target"></Navigate>
                    ) : (
                      <Navigate to="/crm/goals/my-target"></Navigate>
                    )
                  }
                ></Route>
              </Route>
              <Route path="meetings" element={<Meetings />} />
              <Route path="training" element={<Training />} />
              <Route path="training/videos" element={<TraningVideos />} />
              <Route path="reports" element={<Reports />} />
              <Route path="coins" element={<Wallet />} />
            </Route>
            <Route path="all-messages" element={<AllMessages />}>
              <Route index element={<NotFound />} />
              <Route path=":id/:id/:id/:id" element={<MessageChat />} />
            </Route>
            <Route path="payment-leads/:id" element={<PayLeads></PayLeads>} />
            <Route path="profilelead/:id" element={<LeadProfile />}>
              <Route
                path="solutions"
                element={<PropsalConatiner></PropsalConatiner>}
              ></Route>
              <Route
                path="financial-info"
                element={<AddProfileFinancial></AddProfileFinancial>}
              ></Route>
              <Route
                path="contracts"
                element={<ContractProposals></ContractProposals>}
              ></Route>
              <Route path="financial" element={<FinanceInfo />}></Route>
              <Route
                path="assing-to-user"
                element={<AsignLeadToUser></AsignLeadToUser>}
              ></Route>
              <Route
                path="send-to-leads-bank"
                element={<SendToLeadBank></SendToLeadBank>}
              ></Route>
              <Route
                path="documents"
                element={<DocumentsTab></DocumentsTab>}
              ></Route>
              <Route path="finance" element={<Finance></Finance>}>
                <Route
                  path="finance-goodleap"
                  element={<AddProfileFinancial></AddProfileFinancial>}
                ></Route>
                <Route
                  path="finance-sunlight"
                  element={<FinanceInfo></FinanceInfo>}
                ></Route>
              </Route>
            </Route>
            <Route path="profilelead/:id/edit" element={<EditLeadProfile />} />
            {/* new Route for signal */}
            <Route
              path="profilelead/:id/solutions/single-solar/:id"
              element={<ProposalAllInfo />}
            />
            <Route
              path="profilelead/:id/solutions/single-roofing/:id"
              element={<SingleRoofingSolution />}
            />
            <Route
              path="profilelead/single-contract/:id/:id"
              element={<SingleContract />}
            />
            <Route
              path="installer-Adders"
              element={<InstallerAdders />}
            ></Route>
            {/* <Route path="profilelead/:id/:id" element={<ProposalsInfo />} />
            <Route
              path="profilelead/:id/:id/contract"
              element={<ContractSolar />}
            /> */}
            <Route path="/profile" element={<SalesProfile />}>
              <Route path="main" element={<MainContentProfile />}></Route>
              <Route path="edit" element={<EditProfileMain />} />
              <Route path="change-password" element={<SalesChangePassword />} />
              <Route path="team-member=:id" element={<TeamMember />} />
              <Route
                path="team-member=:id/child=:id"
                element={<LeadProfileChild />}
              />
              <Route
                path="team-member=:id/child=:id/:id"
                element={<SolutionDetails />}
              />
              <Route path="sales-license" element={<License />}>
                <Route path="get" element={<SalesLicense />}></Route>
                <Route index element={<Navigate to="get" />}></Route>
                <Route path="create" element={<CreateLicense />}></Route>
              </Route>
              <Route
                path="upload-template"
                element={<UploadTemplate />}
              ></Route>
              <Route path="show-template" element={<ShowTemplates />}></Route>
              <Route path="edit-adders" element={<EditAdders />}></Route>
              <Route path="invite-user" element={<InviteUser />}></Route>
              {RoleUser && (
                <Route path="create-user" element={<CreateUser />} />
              )}
            </Route>
          </>
        )}
      </Routes>
    </>
  );
}

export default App;
