import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
// import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import xsAllPrimamLogo from "../../assets/xsall.png";
import xsBoxLogo from "../../assets/xsbox.svg";
import lgBoxLogo from "../../assets/lgBox.svg";
import lgAllLogo from "../../assets/lgAll.svg";
import { makeStyles } from "@mui/styles";
import { Button, Modal, Typography } from "@mui/material";
import MoodIcon from "@mui/icons-material/Mood";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import { useDispatch, useSelector } from "react-redux";
import { SignOut } from "../../Redux/Actions/authActions";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Notifications from "../Notifications/Notifications";
import { getNotifications } from "../../Redux/Actions/NotificationActions";
import PopupNotification from "../PopUpNotifiaction/PopupNotification";
import newLogo from "../../assets/newLogoBox.svg";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Cart from "../Cart/Cart";
import Messages from "../Message/Messages";
import { getUnseenMessages } from "../../Redux/Actions/MessageActions";
import Pusher from "pusher-js";
import Alert from "../Akerts/Alert";

/* ------- start styled --------- */

const useStyles = makeStyles((theme) => ({
  imageCont: {
    display: "flex",
    alignItems: "center",
  },
  lgImage: {},
  navflex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  iconBtn: {
    background: "#f00",
  },
}));

const NotificationStyled = styled.div`
  background: #fff;
  .iconBtn {
    background: red;
  }
`;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);

  const { pathname } = useLocation();

  // console.log("location ", pathname);

  /**************************************************** */
  /*--------------- START MESSAGE DROPDOWN ------------ */
  /**************************************************** */

  const [message, setMessage] = useState(null);
  const { getMainUser } = useSelector((state) => state.editUser);
  const openMessage = Boolean(message);
  const { unseenMessages } = useSelector((state) => state.messages);

  const MessageLength = unseenMessages?.count_chat;
  // console.log(MESSAGE_DATA, "MESSAGE_DATA");

  const GET_USER_ID = getMainUser ? getMainUser?.id : "";

  // const newMap = GetUserSender?.map((key) => {
  //   console.log(key);
  //   return key;
  // });
  // console.log("map");

  const HandleClick = (e) => {
    setMessage(e.currentTarget);
  };
  const HandleClose = () => {
    setMessage(null);
  };

  useEffect(() => {
    const PusherFun = async () => {
      const pusher = new Pusher("2c551729f5c7d105fc99", {
        cluster: "eu",
      });
      const channel = pusher.subscribe("my-channel");
      channel.bind(`my-event-crm-${GET_USER_ID}`, function (data) {
        if (data && pathname === "/crm/leads")
          if (GET_USER_ID)
            dispatch(
              getUnseenMessages({
                auth_user: GET_USER_ID,
                site_auth_user: "crm",
              })
            );
      });
      Pusher.logToConsole = true;
    };
    PusherFun();

    return () => {};
  }, [GET_USER_ID, dispatch]);

  useEffect(() => {
    if (GET_USER_ID)
      dispatch(
        getUnseenMessages({ auth_user: GET_USER_ID, site_auth_user: "crm" })
      );
  }, [GET_USER_ID]);

  /**************************************************** */
  /*--------------- END MESSAGE DROPDOWN ------------ */
  /**************************************************** */

  const { notification } = useSelector((state) => state.notifications);

  const unReadNotification = notification?.filter((e) => e.read_at == null);
  // console.log(unReadNotification, "read");
  useEffect(() => {
    const noti = () => {
      dispatch(getNotifications());
    };
    noti();
    return () => {};
  }, [toggle]);

  //console.log(JSON.parse({ notification?.data}), "ahmed");
  const handleLogOut = (e) => {
    e.preventDefault();
    setAnchorEl(null);
    handleMobileMenuClose();
    dispatch(SignOut());
    navigate("/");
  };

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const [showDiv, setShow] = React.useState(false);

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>
        <Link to="/profile/main">Profile</Link>
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
      <MenuItem onClick={handleLogOut}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton
          size="large"
          onClick={HandleClick}
          sx={{ ml: 2 }}
          aria-controls={openMessage ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openMessage ? "true" : undefined}
        >
          <Badge badgeContent={MessageLength} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButtonStyled
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={0} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButtonStyled>
        <p>Notifications</p>
      </MenuItem>

      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>

      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <MoodIcon />
        </IconButton>
        <p>Motivate Me</p>
      </MenuItem>
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <LeaderboardIcon />
        </IconButton>
        <p>Get Leads</p>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <PopupNotification />
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" color="primary" sx={{ zIndex: 999 }}>
          <Toolbar className={classes.navflex}>
            <Box>
              <Link to="/crm/leads">
                <Box
                  sx={{
                    display: { xs: "none", md: "flex" },
                  }}
                >
                  <img
                    src={newLogo}
                    alt="logo"
                    width="120"
                    style={{ marginRight: "10px", marginTop: "5px" }}
                  />
                  <img
                    src={lgAllLogo}
                    alt="logo"
                    width="120"
                    style={{ marginRight: "10px" }}
                  />
                </Box>
              </Link>
              <Link to="/crm/leads">
                <Box sx={{ display: { xs: "flex", md: "none" } }}>
                  <img
                    src={xsBoxLogo}
                    alt="logo"
                    width="40"
                    style={{ marginRight: "10px" }}
                  />
                  <img
                    src={xsAllPrimamLogo}
                    alt="logo"
                    width="40"
                    style={{ marginRight: "10px" }}
                  />
                </Box>
              </Link>
            </Box>

            <Box
              sx={{
                display: { xs: "none", sm: "flex" },
              }}
            >
              <Button color="inherit" variant="outlined" sx={{ mr: 2 }}>
                motivation
              </Button>{" "}
              <Button color="inherit" variant="outlined" sx={{ mr: 2 }}>
                <Link style={{ color: "inherit" }} to="/crm/get-leads">
                  {" "}
                  get lead{" "}
                </Link>
              </Button>
            </Box>

            <Box
              sx={{ display: { xs: "none", md: "flex" }, position: "relative" }}
            >
              <IconButton
                size="large"
                onClick={HandleClick}
                sx={{ ml: 2 }}
                aria-controls={openMessage ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openMessage ? "true" : undefined}
                color="inherit"
              >
                <Badge badgeContent={MessageLength} color="error">
                  <MailIcon />
                </Badge>
              </IconButton>
              <MenuDiv>
                <IconButton>
                  <ShoppingCartIcon
                    style={{ color: "white" }}
                    onClick={() => setShow(!showDiv)}
                  ></ShoppingCartIcon>
                  {showDiv && (
                    <>
                      {" "}
                      <div className="menu">
                        <Cart></Cart>
                      </div>
                    </>
                  )}
                </IconButton>
              </MenuDiv>
              <IconButtonStyled
                onClick={() => setToggle(!toggle)}
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
              >
                <Badge badgeContent={unReadNotification?.length} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButtonStyled>
              <Notifications toggle={toggle} notification={notification} />
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
        <Messages
          open={openMessage}
          handleClose={HandleClose}
          anchorEl={message}
        />
      </Box>
      <Alert></Alert>
    </>
  );
}

const IconButtonStyled = styled(IconButton)`
  position: relative;
  transition: all 0.3s ease-out;
  .menu {
    position: absolute;
    left: 0;
    top: 0;
    width: 10rem;
  }
`;
const MenuDiv = styled.div`
  position: relative;
  transition: all 0.3s ease-out;
  display: flex;
  align-items: center;
  .menu {
    position: absolute;
    z-index: 5;
    top: 3.5rem;
    right: 0;
    width: 14rem;
    background-color: white;
    color: black;
    box-shadow: 2px 2px 2px;
    text-align: center;
    padding: 1rem 0;
  }
`;
