import React from "react";
import FormContainer from "./components/form/index";
import { BigSection } from "./style";
import NewHeader from "../NewLandingPage/LandingPageComponents/Header/NewHeader";
import ResponsiveAppBar from "../landing Page/components/header/Header";
const Invitation = () => {
  return (
    <>
      <div style={{ background: "black", padding: "2rem 0" }}>
        <ResponsiveAppBar />
      </div>
      <BigSection>
        <div className="container">
          <FormContainer />
        </div>
      </BigSection>
    </>
  );
};

export default Invitation;
