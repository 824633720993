import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
// import FinanceForm from "./financeForm";
import PrequalProjects from "./prequalProjects";
// import SendPrequal from "./SendPrequal";

const FinanceInfo = () => {
  const location = useLocation();
  const LeadId = location.pathname.split("/")[2];

  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <MainTabsStyled>
        <Box sx={{ width: "100%", typography: "body1", margin: "2rem auto" }}>
          <TabContext value={value}>
            {/* <Box sx={{ margin: "2rem " }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Sunlight " value="1" className="btn_finance" />
              </TabList>
            </Box> */}

            <TabPanel value="1">
              <PrequalProjects LeadId={LeadId} />
            </TabPanel>
          </TabContext>
        </Box>
      </MainTabsStyled>
    </>
  );
};

const MainTabsStyled = styled.div`
  margin: 2rem;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  .btn_finance {
    color: white;
    /* border-radius: 0.5rem; */
    margin: 1rem 0;
    border: 1px solid #18727e;
    color: #18727e;
    background: #fff;
  }
  .Mui-selected {
    background: #18727e;
    color: white;
  }
`;

export default FinanceInfo;
