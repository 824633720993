import { POST_FINANCE } from "../Actions/type";

const initialState = {
  finaniceData: {},
};

const EditFinnanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_FINANCE:
      return {
        ...state,
        finaniceData: action.finanice_data,
      };
    default:
      return { ...state };
  }
};

export default EditFinnanceReducer;
