import {
  FINANCE_GOODLEAP,
  GOOD_LEAP_GET_ALL_CHANNELS,
} from "../../Redux/Actions/goodLeapAction";
import { GOOD_LEAP_GET_ALL_PROMOTIONS } from "../../Redux/Actions/goodLeapAction";
import { GOOD_LEAP_GET_ALL_CATEGORIES } from "../../Redux/Actions/goodLeapAction";
import { GOOD_LEAP_GET_ALL_OFFERS } from "../../Redux/Actions/goodLeapAction";
import { GOOD_LEAP_PAYMENT } from "../../Redux/Actions/goodLeapAction";
import { GOOD_LEAP_CALC } from "../../Redux/Actions/goodLeapAction";
import { SHOW_LOADING, HIDE_LOADING } from "../Actions/type";
const inittialState = {
  allChannels: "",
  allPrmotions: "",
  allGetegories: "",
  allOffers: "",
  payment: "",
  allGoodLeap: {},
  finance: "",
};
const GoodLeapReducer = (state = inittialState, action) => {
  switch (action.type) {
    case GOOD_LEAP_GET_ALL_CHANNELS:
      return {
        ...state,
        allChannels: action.payload,
      };
    case GOOD_LEAP_GET_ALL_PROMOTIONS:
      return {
        ...state,
        allPrmotions: action.payload,
      };
    case GOOD_LEAP_GET_ALL_CATEGORIES:
      return {
        ...state,
        allGetegories: action.payload,
      };
    case GOOD_LEAP_GET_ALL_OFFERS:
      return {
        ...state,
        allOffers: action.payload,
      };
    case GOOD_LEAP_PAYMENT:
      return {
        ...state,
        payment: action.payload,
      };
    case GOOD_LEAP_CALC:
      return {
        ...state,
        allGoodLeap: action.allGoodLeap,
      };
    case FINANCE_GOODLEAP:
      return {
        ...state,
        finance: action.finance,
      };

    default:
      return { ...state };
  }
};
export default GoodLeapReducer;
