import { CREATE_TICKET } from "../Actions/CreateTicket";
const inittialState = {
  ticketData: "",
};
const CreateTicketReducer = (state = inittialState, action) => {
  switch (action.type) {
    case CREATE_TICKET:
      return {
        ...state,
        allGoodLeap: action.allGoodLeap,
      };
    default:
      return { ...state };
  }
};
export default CreateTicketReducer;
