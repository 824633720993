import axios from "axios";
import { HideLoading, ShowLoading } from "./loadingAction";
import swal from "sweetalert";
import { SignOut } from "./authActions";
import {
  ACHIEVEMENT,
  EDIT_ACHIEVEMENT,
  GET_ACHIEVEMENT,
  PARENT_ACHIEVEMENT,
  SALES_ACHIEVEMENT,
} from "./type";

import {
  achievement_api,
  edit_achievement_process_api,
  get_achievement_process_api,
  get_parent_achievement_api,
  get_sales_achievement_process_api,
} from "../../API/Api";

export const postAchievement = (data) => {
  return async (dispatch) => {
    dispatch(ShowLoading());
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    await axios({
      method: "POST",
      baseURL: `${achievement_api}`,
      headers: headers,
      data,
    })
      .then((res) => {
        dispatch({
          type: ACHIEVEMENT,
          payload: res,
        });

        dispatch(HideLoading());
        swal({
          title: "Succsfully",
          text: "The Target has been created successfully",
          icon: "success",
          button: `OK`,
        });
      })
      .catch((err) => {
        console.log("Errrrrrrrr", err.response);
        swal({
          title: "Error",
          text: "The Target has not been created",
          icon: "error",
          button: `OK`,
        });
        dispatch({
          type: ACHIEVEMENT,
          payload: err.response,
        });
        dispatch(HideLoading());
        if (err?.response?.status === 401) {
          dispatch(SignOut());
        }
      });
  };
};

export const getParentAchievement = (data) => {
  return async (dispatch) => {
    dispatch(ShowLoading());
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    await axios({
      method: "GET",
      baseURL: `${get_parent_achievement_api}`,
      headers: headers,
    })
      .then((res) => {
        dispatch({
          type: PARENT_ACHIEVEMENT,
          payload: res.data,
        });
        dispatch(HideLoading());
      })
      .catch((err) => {
        dispatch(HideLoading());
        if (err?.response?.status === 401) {
          dispatch(SignOut());
        }
      });
  };
};

export const getSalesAchievement = (data) => {
  return async (dispatch) => {
    dispatch(ShowLoading());
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    await axios({
      method: "GET",
      baseURL: `${get_sales_achievement_process_api}`,
      headers: headers,
      data,
    })
      .then((res) => {
        dispatch({
          type: SALES_ACHIEVEMENT,
          payload: res.data,
        });
        dispatch(HideLoading());
      })
      .catch((err) => {
        dispatch(HideLoading());
        if (err?.response?.status === 401) {
          dispatch(SignOut());
        }
      });
  };
};

export const getAchievement = (id) => {
  return async (dispatch) => {
    dispatch(ShowLoading());
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    await axios({
      method: "GET",
      baseURL: `${get_achievement_process_api}/${id}`,
      headers: headers,
    })
      .then((res) => {
        dispatch({
          type: GET_ACHIEVEMENT,
          payload: res.data,
        });
        dispatch(HideLoading());
      })
      .catch((err) => {
        dispatch(HideLoading());
        if (err?.response?.status === 401) {
          dispatch(SignOut());
        }
      });
  };
};

export const editSalesAchievement = ({ data, id }) => {
  return async (dispatch) => {
    dispatch(ShowLoading());
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    await axios({
      method: "PATCH",
      baseURL: `${edit_achievement_process_api}/${id}/status`,
      headers: headers,
      data,
    })
      .then((res) => {
        dispatch({
          type: EDIT_ACHIEVEMENT,
          payload: res.data,
        });
        dispatch(HideLoading());
      })
      .catch((err) => {
        dispatch(HideLoading());
        if (err?.response?.status === 401) {
          dispatch(SignOut());
        }
      });
  };
};
