import { AppBar, Button, Grid } from "@mui/material";
import React, { Children } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Route, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { singleOpportunitiesAction } from "../../Redux/Actions/SingleOpportunitieAction";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Header from "../../components/Header/Header";
import PhotoCamera from "@mui/icons-material/PhotoCamera";

// Style
const BigSection = styled.div`
  padding: 30px 0;
  font-weight: 600;
  /* p {  margin-top: 0.0em; margin-bottom: 0em; } */
  table {
    width: 100%;
    th,
    td {
      border: 1px solid black;
      padding: 1rem;
      font-size: 14px;
    }
    th {
      text-transform: capitalize;
      font-size: 16px;
    }
    td {
      /* color: red; */
    }
  }
`;
const UserDiv = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  /* border:1px solid gray; */
  border-radius: 30px;
  -webkit-box-shadow: -1px -1px 20px #929292;
  box-shadow: -1px -1px 20px #929292;
  /* min-height: 100vh; */
  word-wrap: break-word;
`;

const Content = styled.div`
  padding: 2rem 1rem;
  /* height: 80vh; */
  &:nth-child(1) {
    font-size: 16px;
  }
`;
const Heading = styled.div`
  position: relative;
  &:after {
    position: absolute;
    width: 95%;
    bottom: -5px;
    left: 0;
    height: 1px;
    background-color: black;
    content: "";
  }
  &:nth-child(1) {
    font-size: 25px;
  }
`;
const ButtonBack = styled.button`
  background: rgb(28, 134, 134);
  margin: 2rem 0px 0px 1rem;
  border-radius: 1rem;
  font-size: 0.7rem;
  color: rgb(255, 255, 255);
  padding: 15px 30px;
  font-size: 15px;
  border: none;
  cursor: pointer;
`;

const Value = styled.span`
  color: rgb(136, 136, 136);
`;
const PdfButton = styled.button`
  background-color: white;
  color: black;
  padding: 5px 8px;
  border-radius: 25px;
  cursor: pointer;
`;
const MyButton = styled(Button)`
  background-color: #007cff !important;
  color: white !important;
`;
const Wrapper = styled.div`
  max-width: 1000px;
  margin: 2rem auto 0;
  .c-timeline__item {
    position: relative;
    display: flex;
    gap: 1.5rem;
    //outline: solid 1px;

    &:last-child {
      .c-timeline__content,
      .c-timeline__content_green,
      .c-timeline__content_red {
        &:before {
          display: none;
        }
      }
    }
  }

  .c-timeline__content {
    flex: 1;
    position: relative;
    order: 1;
    padding-left: 1.5rem;
    padding-bottom: 3rem;

    &:before {
      content: "";
      position: absolute;
      right: 100%;
      top: 0;
      height: 100%;
      width: 2px;
      background-color: lightgrey;
    }

    &:after {
      content: "";
      position: absolute;
      left: calc(0px - 13px);
      top: 0;
      width: 20px;
      height: 20px;
      background-color: #fff;
      /* background-color: black; */
      z-index: 1;
      border: 2px solid lightgrey;
      border-radius: 50%;
      /* background-color: ${(props) =>
        props.completed !== undefined ? "green" : "red"}; */
    }
  }
  .c-timeline__content_green {
    flex: 1;
    position: relative;
    order: 1;
    padding-left: 1.5rem;
    padding-bottom: 3rem;

    &:before {
      content: "";
      position: absolute;
      right: 100%;
      top: 0;
      height: 100%;
      width: 2px;
      background-color: lightgrey;
    }

    &:after {
      content: "";
      position: absolute;
      left: calc(0px - 13px);
      top: 0;
      width: 20px;
      height: 20px;
      background-color: #fff;
      background-color: green;
      z-index: 1;
      border: 2px solid lightgrey;
      border-radius: 50%;
    }
  }
  .c-timeline__content_red {
    flex: 1;
    position: relative;
    order: 1;
    padding-left: 1.5rem;
    padding-bottom: 3rem;

    &:before {
      content: "";
      position: absolute;
      right: 100%;
      top: 0;
      height: 100%;
      width: 2px;
      background-color: lightgrey;
    }

    &:after {
      content: "";
      position: absolute;
      left: calc(0px - 13px);
      top: 0;
      width: 20px;
      height: 20px;
      background-color: #fff;
      background-color: red;
      z-index: 1;
      border: 2px solid lightgrey;
      border-radius: 50%;
    }
  }
  .c-timeline__title {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .c-timeline__desc {
    color: grey;
  }

  time {
    text-align: end;
    flex: 0 0 100px;
    min-width: 0;
    overflow-wrap: break-word;
    padding-bottom: 1rem;
  }
`;
const ProposalAllInfo = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(singleOpportunitiesAction(`${id}`));
  }, [dispatch]);
  const { singleOpportunity } = useSelector((state) => state.leads);
  //offest
  const NumberOfPanelsNeeded = Math.ceil(
    singleOpportunity.data?.annual_kwh_usage /
      365 /
      4.2 /
      (singleOpportunity.data?.panel_wattage / 1000)
  );

  const TotalPanle =
    NumberOfPanelsNeeded +
    Number(singleOpportunity.data?.number_of_solar_arrays);
  const SystemSize = TotalPanle * singleOpportunity.data?.panel_wattage;
  const AnnualPrduction = (SystemSize / 1000) * 365 * 4.2;
  const OffsetPercentage = Math.ceil(
    (AnnualPrduction / singleOpportunity.data?.annual_kwh_usage) * 100
  );

  // opprtunity
  const singData = singleOpportunity.data || "";
  // Utility provider
  const utilityProvider = singData.utility_provider || "";
  // User
  const user = singData.user || "";
  // contractor
  const contractor = singData.contractor || "";
  // lead
  const lead = singData.lead || "";

  // const [value, setValue] = React.useState(0);
  // const handleTabs = (e, val) => {
  //   console.warn(val);
  //   setValue(val);
  // };
  // const TabPanel = (props) => {
  //   const { children, value, index } = props;
  //   return <div>{value === index && <p>{children}</p>}</div>;
  // };
  // The Image
  const imageOfDesing = singData.aerial_photo || "";

  let allLogsInfo = singData?.logs || [];
  let document_created = allLogsInfo.find(
    (e) => e?.event_type === "document_created"
  );
  let document_sent = allLogsInfo.find(
    (e) => e?.event_type === "document_sent"
  );
  let document_viewed = allLogsInfo.find(
    (e) => e?.event_type === "document_viewed"
  );
  let document_signed = allLogsInfo.find(
    (e) => e?.event_type === "document_signed"
  );
  let document_completed = allLogsInfo.find(
    (e) => e?.event_type === "document_completed"
  );
  // Good Leap Status
  const glStatus = singData?.fi_Logs || [];
  const lastItem = glStatus[glStatus.length - 1];
  const location = useLocation();
  let arr = location?.pathname?.split("/");
  console.log("location", arr[2], arr[4]);
  let leadId = arr[2];
  let solutionID = arr[4];
  console.log("solutionID", solutionID);
  console.log("What is this", singData?.goodleap_loan);
  let stipulations = JSON.parse(singData?.goodleap_loan?.stipulations || "[]");
  console.log("stipulations", stipulations);
  const [data, setData] = React.useState([]);
  console.log("data", data);
  return (
    <div>
      <Header></Header>
      <Grid item lg={18} md={18} sm={18} xs={18}>
        <ButtonBack startIcon onClick={() => navigate(-1)}>
          go back Solutions{" "}
        </ButtonBack>
      </Grid>
      <BigSection>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          columns={18}
          spacing={5}
        >
          {/* Left div*/}
          <Grid item lg={5} md={6.5} sm={12} xs={12}>
            <UserDiv>
              <Content>
                <Grid item lg={18} md={18} sm={18} xs={18}>
                  <Heading>User</Heading>
                </Grid>
                <Grid item lg={18} sx={{ pt: 2 }}>
                  <p>
                    {" "}
                    Name: <Value> {user.name} </Value>
                  </p>
                </Grid>
                <Grid item lg={18}>
                  <p>
                    {" "}
                    Email: <Value>{user.email}</Value>{" "}
                  </p>
                </Grid>
                <Grid item lg={18}>
                  <p>
                    {" "}
                    Phone:<Value>{user.phone}</Value>{" "}
                  </p>
                </Grid>
                <Grid item lg={18}>
                  <p>
                    Mantras:<Value> {user.mantras} </Value>
                  </p>
                </Grid>
                <Grid item lg={18}>
                  <p>
                    Adress: <Value> {user.address} </Value>{" "}
                  </p>
                </Grid>
                <Grid item lg={18}>
                  <p>
                    Business: <Value>{user.business}</Value>
                  </p>
                </Grid>
                <Grid item lg={18}>
                  <p>
                    Lead Count:<Value>{user.leads_count} </Value>{" "}
                  </p>
                </Grid>
                <Grid item lg={18}>
                  <p>
                    Opportunities count:{" "}
                    <Value>{user.opportunities_count} </Value>
                  </p>
                </Grid>
                <Grid item lg={18}>
                  <p>
                    {" "}
                    Solar contract count:{" "}
                    <Value> {user.solar_contracts_count}</Value>{" "}
                  </p>
                </Grid>
                <Grid item lg={18}>
                  <p>
                    {" "}
                    Job title: <Value>{user.Job_title}</Value>
                  </p>
                </Grid>
                {/* Contractor */}
                <Grid item lg={18} md={18} sm={18} xs={18}>
                  <Heading>Contractor</Heading>
                </Grid>
                <Grid item lg={18} md={8} sm={12} xs={12} sx={{ pt: 2 }}>
                  <p>
                    Name: <Value> {contractor.name}</Value>
                  </p>
                </Grid>
                <Grid item lg={18} md={8} sm={12} xs={12}>
                  <p>
                    Address: <Value> {contractor.address}</Value>
                  </p>
                </Grid>
                <Grid item lg={18} md={8} sm={12} xs={12}>
                  <p>
                    {" "}
                    Status: <Value>{contractor.status}</Value>
                  </p>
                </Grid>
                <Grid item lg={18} md={8} sm={12} xs={12}>
                  <p>
                    Expiry Date:{" "}
                    <Value>
                      {" "}
                      {new Date(contractor.expiry_date).toLocaleDateString(
                        "en-US",
                        { day: "2-digit", month: "2-digit", year: "numeric" }
                      )}
                    </Value>
                  </p>
                </Grid>
                <Grid item lg={18} md={8} sm={12} xs={12}>
                  <p>
                    Entity: <Value> {contractor.entity}</Value>{" "}
                  </p>
                </Grid>
                <Grid item lg={18} md={8} sm={12} xs={12}>
                  <p>
                    License: <Value> {contractor.license}</Value>
                  </p>
                </Grid>
                <Grid item lg={18} md={18} sm={12} xs={12}>
                  <p>
                    Initial: <Value> {contractor.initial}</Value>
                  </p>
                </Grid>
                <Grid item lg={18} md={18} sm={12} xs={12}>
                  <p>
                    Contract amount:{" "}
                    <Value style={{ fontWeight: "bold", color: "green" }}>
                      $ {Math.ceil(singData.estimated_cost || 0).toFixed(2)}
                    </Value>
                  </p>
                </Grid>
                <Grid item lg={18} md={18} sm={12} xs={12}>
                  <p>
                    Royalties:{" "}
                    <Value style={{ fontWeight: "bold", color: "green" }}>
                      $ {singData.royalties}
                    </Value>
                  </p>
                </Grid>
              </Content>
            </UserDiv>
          </Grid>

          {/* <Grid item lg={6.5} md={6.5} sm={12} xs={12}>
                                        <UserDiv> 
                    <AppBar position="static" style={{ backgroundColor: "white",width:"100%",boxShadow:"none",border:"1px solid black",borderTopLeftRadius:"30px",borderTopRightRadius:"30px",overflow:"hidden"}}>
                        <Tabs value={value} onChange={handleTabs}>
                            <Tab label="User Data"></Tab>
                            <Tab label="Contractor"></Tab>
                        </Tabs>
                    </AppBar>
                    <Content> */}
          {/* User Data */}
          {/* <TabPanel value={value} index={0} >
                        <Grid container columns={18} spacing={1}>
                    <Grid item lg={18} md={18} sm={12} xs={12}>
                                <p>  Name:  <Value> {user.name} </Value></p>
                            </Grid>
                            <Grid item lg={18} md={18} sm={12} xs={12}>
                                <p>  Email:  <Value>{user.email}</Value> </p>
                            </Grid>
                            <Grid item lg={18} md={18} sm={12} xs={12}>
                                <p> Phone:<Value>{user.phone}</Value>  </p>
                            </Grid>
                            <Grid item lg={18} md={18} sm={12} xs={12}>
                                <p>Mantras:<Value> {user.mantras} </Value></p>
                            </Grid>
                            <Grid item lg={18} md={18} sm={12} xs={12}>
                                <p>Adress: <Value> {user.address} </Value> </p>
                            </Grid>
                            <Grid item lg={18} md={18} sm={12} xs={12}>
                                <p>Business:  <Value>{user.business}</Value></p>
                            </Grid>
                            <Grid item lg={18} md={18} sm={12} xs={12}>
                                <p>Lead Count:<Value>{user.leads_count} </Value> </p>
                            </Grid>
                            <Grid item lg={18} md={18} sm={12} xs={12}>
                                <p>Opportunities count: <Value>{user.opportunities_count} </Value></p>
                            </Grid>
                            <Grid item lg={18} md={18} sm={12} xs={12}>
                                <p> Solar contract count: <Value> {user.solar_contracts_count}</Value> </p>
                            </Grid>
                            <Grid item lg={18} md={18} sm={12} xs={12}>
                                <p> Job title:  <Value>{user.Job_title}</Value></p>
                            </Grid>
                            </Grid>
                    </TabPanel> */}
          {/* Contractor Data */}
          {/* <TabPanel value={value} index={1}>
                    <Grid container columns={18} spacing={1}>
                            <Grid item lg={18} md={18} sm={12} xs={12}>
                                <p>Name: <Value> {contractor.name}</Value></p>
                            </Grid>
                            <Grid item lg={18} md={18} sm={12} xs={12}>
                                <p>Address: <Value> {contractor.address}</Value></p>
                            </Grid>
                            <Grid item lg={18} md={18} sm={12} xs={12}>
                                <p> Status: <Value>{contractor.status}</Value></p>
                            </Grid>
                            <Grid item lg={18} md={18} sm={12} xs={12}>
                                <p>Expiry Date: <Value> {new Date(contractor.expiry_date).toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })}</Value></p>
                            </Grid>
                            <Grid item lg={18} md={18} sm={12} xs={12}>
                                <p>Entity: <Value> {contractor.entity}</Value> </p>
                            </Grid>
                            <Grid item lg={18} md={18} sm={12} xs={12}>
                                <p>License: <Value> {contractor.license}</Value></p>
                            </Grid>
                            <Grid item lg={18} md={18} sm={12} xs={12}>
                                <p>Initial: <Value> {contractor.initial}</Value></p>
                            </Grid>
                    </Grid>
                    </TabPanel>
                    </Content>
                    </UserDiv>
                   
                </Grid> */}
          {/* Right Div */}
          <Grid item lg={11} md={11} sm={11} xs={11}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="baseline"
              columns={18}
              spacing={4}
              style={{ textAlign: "left" }}
            >
              <div className="rightDiv">
                {/* <div className="solutionDetails"></div> */}
              </div>
              {/* Lead */}
              <Grid item lg={18} md={18} sm={18} xs={18}>
                <Heading>Lead</Heading>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Cutomer Type: <Value> {lead.customer_type}</Value>{" "}
                </p>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  First name: <Value> {lead.first_name}</Value>{" "}
                </p>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  {" "}
                  Last Name: <Value> {lead.last_name}</Value>{" "}
                </p>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Phone: <Value> {lead.phone}</Value>
                </p>
              </Grid>
              {lead.is_phone_receives_txt === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is phone receives txt:{" "}
                    <Value>
                      {Boolean(lead.is_phone_receives_txt).toString()}
                    </Value>{" "}
                  </p>
                </Grid>
              )}

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Mobile: <Value> {lead.mobile}</Value>{" "}
                </p>
              </Grid>
              {lead.is_mobile_receives_txt === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is mobile receives txt :{" "}
                    <Value>
                      {Boolean(lead.is_mobile_receives_txt).toString()}
                    </Value>{" "}
                  </p>
                </Grid>
              )}

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  {" "}
                  Email: <Value>{lead.email}</Value>{" "}
                </p>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Perferred language: <Value> {lead.preferred_language}</Value>
                </p>
              </Grid>
              {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                            <p>Location: <Value>  {lead.location}</Value> </p>
                        </Grid> */}
              {lead.is_hoa === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is hoa: <Value> {Boolean(lead.is_hoa).toString()}</Value>{" "}
                  </p>
                </Grid>
              )}

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Street: <Value> {lead.street}</Value>
                </p>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  {" "}
                  Zip Code: <Value>{lead.zip_code}</Value>
                </p>
              </Grid>
              {lead.unit === null ? (
                <span style={{ display: "hidden" }}></span>
              ) : (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Unit: <Value> {lead.unit}</Value>{" "}
                  </p>
                </Grid>
              )}

              {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is active:{" "}
                  <Value> {Boolean(lead.is_active).toString()}</Value>
                </p>
              </Grid> */}
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Created: <Value> {lead.days} Days ago </Value>
                </p>
              </Grid>
              {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Is priceactive:{" "}
                  <Value> {Boolean(lead.is_priceactive).toString()}</Value>
                </p>
              </Grid> */}
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  {" "}
                  Status: <Value>{lead.status}</Value>{" "}
                </p>
              </Grid>
              {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Source: <Value>{lead.source}</Value>
                </p>
              </Grid> */}
              {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Modified at: <Value>{lead.modified_at}</Value>
                </p>
              </Grid> */}
              {lead.company_name === null ? (
                <span style={{ display: "hidden" }}></span>
              ) : (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Company name: <Value>{lead.company_name}</Value>{" "}
                  </p>
                </Grid>
              )}
              {lead.company_business_model === null ? (
                <span style={{ display: "hidden" }}></span>
              ) : (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Company business model:{" "}
                    <Value>{lead.company_business_model}</Value>
                  </p>
                </Grid>
              )}

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Building type: <Value>{lead.building_type}</Value>
                </p>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  {" "}
                  Date:{" "}
                  <Value>
                    {new Date(lead.time_to_contact).toLocaleDateString(
                      "en-US",
                      { day: "2-digit", month: "2-digit", year: "numeric" }
                    )}
                  </Value>
                </p>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Way to contact: <Value>{lead.way_to_contact}</Value>{" "}
                </p>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                {/* <p>
                  Type: <Value>{lead.lead_type}</Value>
                </p> */}
              </Grid>
              {lead.is_decision_maker_present === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is decision maker present:
                    <Value>
                      {Boolean(lead.is_decision_maker_present).toString()}{" "}
                    </Value>
                  </p>
                </Grid>
              )}

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  {" "}
                  House ownership: <Value>{lead.house_ownership} </Value>
                </p>
              </Grid>

              {/* opportunity */}
              <Grid item lg={18} md={18} sm={18} xs={18}>
                <Heading>
                  <p>Opportunity</p>
                  <div
                    style={{ position: "absolute", right: "3rem", top: "0" }}
                  >
                    <Link to={`/crm/editSolution/${leadId}/${id}/1`}>
                      {" "}
                      <MyButton>Edit Solution</MyButton>
                    </Link>
                  </div>
                </Heading>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Opportunity date time:{" "}
                  <Value>
                    {new Date(
                      singData.opportunity_date_time
                    ).toLocaleDateString("en-US", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </Value>
                </p>
              </Grid>
              {singData.is_any_non_permitted_structures === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is any non permitted structures:{" "}
                    <Value>
                      {Boolean(
                        singData.is_any_non_permitted_structures
                      ).toString()}
                    </Value>
                  </p>
                </Grid>
              )}

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Installation type: <Value>{singData.installation_type}</Value>
                </p>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Rate: <Value>{singData.rate}</Value>
                </p>
              </Grid>
              {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Avg monthly bill: <Value>{singData.avg_monthly_bill}</Value>
                </p>
              </Grid> */}
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  avg annual bill: <Value>$ {singData.avg_annual_bill}</Value>
                </p>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Monthly cost:{" "}
                  <Value>$ {(singData.monthly_cost || 0.0).toFixed(2)}</Value>
                </p>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Aannual increases Percentage:{" "}
                  <Value> {singData.annual_increases_percentage}%</Value>{" "}
                </p>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Roof Materials: <Value>{singData.roof_materials}</Value>
                </p>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Shading: <Value>{singData.shading}</Value>
                </p>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Panels: <Value>{singData.panels}</Value>
                </p>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Number of panels: <Value> {singData.number_of_panels} </Value>
                </p>
              </Grid>
              {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Panel price: <Value>{singData.panel_price} </Value>
                </p>
              </Grid> */}
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  {" "}
                  Panel wattage: <Value>{singData.panel_wattage}</Value>
                </p>
              </Grid>
              {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Add on id <Value>{singData.add_on_id}</Value>
                </p>
              </Grid> */}
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Pdf path:{" "}
                  <PdfButton
                    target="_blank"
                    onClick={() => {
                      window.open(`${singData.pdf_path}`);
                    }}
                  >
                    <Value> open Pdf</Value>
                  </PdfButton>{" "}
                </p>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Status: <Value>{singData.status}</Value>
                </p>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Job type: <Value>{singData.job_type}</Value>
                </p>
              </Grid>
              {singData.number_of_solar_arrays === 0 ? (
                <span style={{ display: "hidden" }}></span>
              ) : (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Added/(Subtracted Panels):{" "}
                    <Value>{singData.number_of_solar_arrays} </Value> Panels
                  </p>
                </Grid>
              )}

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Offset percentage: <Value>{OffsetPercentage}%</Value>
                </p>
              </Grid>
              {singData.is_attic === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is attic:{" "}
                    <Value>{Boolean(singData.is_attic).toString()}</Value>
                  </p>
                </Grid>
              )}
              {/* {singData.number_of_conduit_arrays === 0 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Number of attic arrays:{" "}
                    <Value>{singData.number_of_conduit_arrays}</Value>
                  </p>
                </Grid>
              )} */}
              {singData.number_of_conduit_arrays === 0 ? (
                <span style={{ display: "hidden" }}></span>
              ) : (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Number of attic arrays:{" "}
                    <Value>{singData.number_of_conduit_arrays}</Value>
                  </p>
                </Grid>
              )}

              {singData.is_reverse_tilt === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is reverse tilt:{" "}
                    <Value>
                      {Boolean(singData.is_reverse_tilt).toString()}
                    </Value>
                  </p>
                </Grid>
              )}

              {singData.is_north_faced === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is north faced:{" "}
                    <Value>{Boolean(singData.is_north_faced).toString()}</Value>
                  </p>
                </Grid>
              )}
              {singData.two_story_home === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Two story home:{" "}
                    <Value>
                      {Boolean(singData.two_story_home).toString()}{" "}
                    </Value>
                  </p>
                </Grid>
              )}

              {singData.is_online_monitoring === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is online monitoring:{" "}
                    <Value>
                      {Boolean(singData.is_online_monitoring).toString()}
                    </Value>
                  </p>
                </Grid>
              )}

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Inverter type: <Value>{singData.inverter_type}</Value>
                </p>
              </Grid>
              {singData.no_of_ft_from_main_service === 0 ? (
                <span style={{ display: "hidden" }}></span>
              ) : (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Number of ft from main service:{" "}
                    <Value>{singData.no_of_ft_from_main_service}</Value>
                  </p>
                </Grid>
              )}
              {singData.how_many_ft_trench_dirt === 0 ? (
                <span style={{ display: "hidden" }}></span>
              ) : (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    How many ft trench dirt:{" "}
                    <Value>{singData.how_many_ft_trench_dirt}</Value>
                  </p>
                </Grid>
              )}

              {singData.is_trenching_dirt === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is trenching dirt:{" "}
                    <Value>
                      {Boolean(singData.is_trenching_dirt).toString()}
                    </Value>
                  </p>
                </Grid>
              )}
              {singData.how_many_ft_trench_concrete === 0 ? (
                <span style={{ display: "hidden" }}></span>
              ) : (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    How many ft trench concrete:{" "}
                    <Value>{singData.how_many_ft_trench_concrete}</Value>
                  </p>
                </Grid>
              )}

              {singData.is_trenching_concrete === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is trenching concrete{" "}
                    <Value>
                      {" "}
                      {Boolean(singData.is_trenching_concrete).toString()}{" "}
                    </Value>
                  </p>
                </Grid>
              )}

              {singData.digging_slope === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Digging slope:{" "}
                    <Value> {Boolean(singData.digging_slope).toString()}</Value>
                  </p>
                </Grid>
              )}
              {singData.slope_degree === 0 ? (
                <span style={{ display: "hidden" }}></span>
              ) : (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Slope degree: <Value>{singData.slope_degree}</Value>
                  </p>
                </Grid>
              )}
              {singData.how_many_digging_ft === 0 ? (
                <span style={{ display: "hidden" }}></span>
              ) : (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    How many digging ft:{" "}
                    <Value>{singData.how_many_digging_ft}</Value>{" "}
                  </p>
                </Grid>
              )}

              {singData.is_separate_electrical_meter === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is separate electrical meter:{" "}
                    <Value>
                      {Boolean(
                        singData.is_separate_electrical_meter
                      ).toString()}
                    </Value>
                  </p>
                </Grid>
              )}
              {singData.is_electric_work_currently_on_garage === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is electric work currently on garage:{" "}
                    <Value>
                      {Boolean(
                        singData.is_electric_work_currently_on_garage
                      ).toString()}
                    </Value>
                  </p>
                </Grid>
              )}
              {singData.is_sub_panel_on_detached_garage === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is sub panel on detached garage:{" "}
                    <Value>
                      {Boolean(
                        singData.is_sub_panel_on_detached_garage
                      ).toString()}
                    </Value>
                  </p>
                </Grid>
              )}

              {singData.finalMonthlyPayment === null ? (
                <span style={{ display: "hidden" }}></span>
              ) : (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Final monthly payment:{" "}
                    <Value>$ {singData.finalMonthlyPayment}</Value>
                  </p>
                </Grid>
              )}

              {singData.finalEscalatedMonthlyPayment === null ? (
                <span style={{ display: "hidden" }}></span>
              ) : (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Final Escalated Monthly Payment:{" "}
                    <Value>$ {singData.finalEscalatedMonthlyPayment}</Value>
                  </p>
                </Grid>
              )}
              {singData.monthlyPayment === null ? (
                <span style={{ display: "hidden" }}></span>
              ) : (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Monthly payment: <Value>$ {singData.monthlyPayment}</Value>
                  </p>
                </Grid>
              )}

              {singData.escalatedMonthlyPayment === null ? (
                <span style={{ display: "hidden" }}></span>
              ) : (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Escalated monthly payment:{" "}
                    <Value>$ {singData.escalatedMonthlyPayment}</Value>
                  </p>
                </Grid>
              )}

              {singData.paydownPercentage === 0 ? (
                <span style={{ display: "hidden" }}></span>
              ) : (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Paydown Percentage:{" "}
                    <Value>{singData.paydownPercentage}%</Value>
                  </p>
                </Grid>
              )}
              {singData.alternateMonthlyPayment === null ? (
                <span style={{ display: "hidden" }}></span>
              ) : (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Alternate monthly payment:{" "}
                    <Value>{singData.alternateMonthlyPayment}</Value>
                  </p>
                </Grid>
              )}

              {singData.is_add_on === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is add on:{" "}
                    <Value>{Boolean(singData.is_add_on).toString()}</Value>
                  </p>
                </Grid>
              )}

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  System size: <Value>{singData.system_size} k.W</Value>
                </p>
              </Grid>
              {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Solar years plan: <Value>{singData.solar_years_plan}</Value>
                </p>
              </Grid> */}
              {singData.is_finance === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is finance:{" "}
                    <Value>{Boolean(singData.is_finance).toString()}</Value>
                  </p>
                </Grid>
              )}
              {singData.is_sandbox === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is sandbox:{" "}
                    <Value>{Boolean(singData.is_sandbox).toString()}</Value>
                  </p>
                </Grid>
              )}

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Term: <Value>{singData.loan_years} </Value>
                </p>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Productivity offset:{" "}
                  <Value>{singData.productivity_offset} %</Value>
                </p>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Federal tax: <Value>$ {singData.federal_tax}</Value>
                </p>
              </Grid>
              {singData.apr === null ? (
                <span style={{ display: "hidden" }}></span>
              ) : (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Apr: <Value>{singData.apr} </Value>
                  </p>
                </Grid>
              )}

              {singData.is_re_roofing === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is re roofing:{" "}
                    <Value>{Boolean(singData.is_re_roofing).toString()} </Value>
                  </p>
                </Grid>
              )}
              {singData.is_roof_overlay === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is roof overlay:{" "}
                    <Value>
                      {Boolean(singData.is_roof_overlay).toString()}
                    </Value>
                  </p>
                </Grid>
              )}
              {singData.is_roof_relay === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is roof relay:{" "}
                    <Value>{Boolean(singData.is_roof_relay).toString()} </Value>
                  </p>
                </Grid>
              )}
              {singData.is_re_roof_shingle === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is re roof shingle:{" "}
                    <Value>
                      {Boolean(singData.is_re_roof_shingle).toString()}
                    </Value>
                  </p>
                </Grid>
              )}

              {singData.is_re_roof_tear_off === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is re roof tear off:{" "}
                    <Value>
                      {Boolean(singData.is_re_roof_tear_off).toString()}
                    </Value>
                  </p>
                </Grid>
              )}

              {singData.is_re_roofing === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is re roofing:{" "}
                    <Value>{Boolean(singData.is_re_roofing).toString()}</Value>
                  </p>
                </Grid>
              )}

              {singData.number_of_layers === 0 ? (
                <span style={{ display: "hidden" }}></span>
              ) : (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Number of layers: <Value>{singData.number_of_layers}</Value>
                  </p>
                </Grid>
              )}

              {singData.is_re_roof_presidential === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    is re roof presidential:{" "}
                    <Value>
                      {Boolean(singData.is_re_roof_presidential).toString()}
                    </Value>
                  </p>
                </Grid>
              )}
              {singData.is_re_roof_spanish_tiles === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is re roof spanish tiles:{" "}
                    <Value>
                      {Boolean(singData.is_re_roof_spanish_tiles).toString()}
                    </Value>
                  </p>
                </Grid>
              )}
              {singData.is_re_roof_flat_tiles === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is re roof flat tiles:{" "}
                    <Value>
                      {Boolean(singData.is_re_roof_flat_tiles).toString()}{" "}
                    </Value>
                  </p>
                </Grid>
              )}

              {singData.is_re_roof_re_decking === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is re roof re decking:{" "}
                    <Value>
                      {Boolean(singData.is_re_roof_re_decking).toString()}
                    </Value>
                  </p>
                </Grid>
              )}
              {singData.is_re_roof_fascia_board === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is re roof fascia board:{" "}
                    <Value>
                      {Boolean(singData.is_re_roof_fascia_board).toString()}
                    </Value>
                  </p>
                </Grid>
              )}
              {singData.number_of_feet === 0 ? (
                <span style={{ display: "hidden" }}></span>
              ) : (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Number of feet: <Value>{singData.number_of_feet}</Value>
                  </p>
                </Grid>
              )}

              {singData.is_warranty_extend === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is warranty extend:{" "}
                    <Value>
                      {Boolean(singData.is_warranty_extend).toString()}
                    </Value>
                  </p>
                </Grid>
              )}
              {singData.is_air_vent === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is air vent:{" "}
                    <Value> {Boolean(singData.is_air_vent).toString()}</Value>
                  </p>
                </Grid>
              )}
              {singData.air_vent === "" ? (
                <span style={{ display: "hidden" }}></span>
              ) : (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Air vent: <Value>{singData.air_vent}</Value>
                  </p>
                </Grid>
              )}

              {singData.number_of_vents === 0 ? (
                <span style={{ display: "hidden" }}></span>
              ) : (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Number of vents: <Value>{singData.number_of_vents}</Value>
                  </p>
                </Grid>
              )}

              {singData.is_double_hand === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is double hand:{" "}
                    <Value>{Boolean(singData.is_double_hand).toString()}</Value>
                  </p>
                </Grid>
              )}
              {singData.is_smoke_detectors === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is smoke detectors:{" "}
                    <Value>
                      {Boolean(singData.is_smoke_detectors).toString()}
                    </Value>
                  </p>
                </Grid>
              )}
              {singData.number_of_smoke_detectors === 0 ? (
                <span style={{ display: "hidden" }}></span>
              ) : (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Number of smoke detectors:{" "}
                    <Value>{singData.number_of_smoke_detectors}</Value>
                  </p>
                </Grid>
              )}
              {singData.number_of_re_roof_squares === 0 ? (
                <span style={{ display: "hidden" }}></span>
              ) : (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Number of re roof squares:{" "}
                    <Value>{singData.number_of_re_roof_squares}</Value>
                  </p>
                </Grid>
              )}

              {singData.is_led_lighting === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is led lightning:{" "}
                    <Value>
                      {Boolean(singData.is_led_lighting).toString()}
                    </Value>
                  </p>
                </Grid>
              )}
              {singData.number_of_led_lighting === 0 ? (
                <span style={{ display: "hidden" }}></span>
              ) : (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Number of led lighting:{" "}
                    <Value>{singData.number_of_led_lighting}</Value>
                  </p>
                </Grid>
              )}

              {singData.is_energy_efficient_plugs === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is energy efficient plugs:{" "}
                    <Value>
                      {Boolean(singData.is_energy_efficient_plugs).toString()}
                    </Value>
                  </p>
                </Grid>
              )}
              {singData.is_solar_lip === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is solar lip:{" "}
                    <Value>{Boolean(singData.is_solar_lip).toString()}</Value>
                  </p>
                </Grid>
              )}
              {singData.number_of_solar_lip === 0 ? (
                <span style={{ display: "hidden" }}></span>
              ) : (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Number of solar lip:{" "}
                    <Value>{singData.number_of_solar_lip}</Value>
                  </p>
                </Grid>
              )}

              {singData.is_bird_netting === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is bird netting:{" "}
                    <Value>
                      {Boolean(singData.is_bird_netting).toString()}
                    </Value>
                  </p>
                </Grid>
              )}
              {singData.number_of_bird_netting === 0 ? (
                <span style={{ display: "hidden" }}></span>
              ) : (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Number of bird netting:{" "}
                    <Value>{singData.number_of_bird_netting}</Value>
                  </p>
                </Grid>
              )}

              {singData.is_battery === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is battery:{" "}
                    <Value>{Boolean(singData.is_battery).toString()}</Value>
                  </p>
                </Grid>
              )}
              {singData.battery_power === "" ? (
                <span style={{ display: "hidden" }}></span>
              ) : (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Battery power: <Value>{singData.battery_power}</Value>
                  </p>
                </Grid>
              )}

              {singData.is_warranty_package === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is warranty package:{" "}
                    <Value>
                      {Boolean(singData.is_warranty_package).toString()}
                    </Value>
                  </p>
                </Grid>
              )}
              {singData.warranty === "" ? (
                <span style={{ display: "hidden" }}></span>
              ) : (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Warranty: <Value> {singData.warranty}</Value>
                  </p>
                </Grid>
              )}

              {singData.is_hvac === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is hvac:{" "}
                    <Value>{Boolean(singData.is_hvac).toString()}</Value>
                  </p>
                </Grid>
              )}
              {singData.hvac_location === "" ? (
                <span style={{ display: "hidden" }}></span>
              ) : (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Hvac location: <Value>{singData.hvac_location}</Value>
                  </p>
                </Grid>
              )}
              {singData.hvac_horse_power === "" ? (
                <span style={{ display: "hidden" }}></span>
              ) : (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    hvac horse power: <Value>{singData.hvac_horse_power}</Value>
                  </p>
                </Grid>
              )}

              {singData.is_ducting === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is ducting:{" "}
                    <Value>{Boolean(singData.is_ducting).toString()}</Value>
                  </p>
                </Grid>
              )}
              {singData.number_of_ducting_run === 0 ? (
                <span style={{ display: "hidden" }}></span>
              ) : (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Number of ducting run:{" "}
                    <Value>{singData.number_of_ducting_run}</Value>
                  </p>
                </Grid>
              )}

              {singData.is_standalone_roof_mount === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is standalone roof mount{" "}
                    <Value>
                      {Boolean(singData.is_standalone_roof_mount).toString()}
                    </Value>
                  </p>
                </Grid>
              )}

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  MPU: <Value>{singData.existing_electrical_panel} A</Value>
                </p>
              </Grid>
              {Number(singData.upgrade_electrical_panel) === 0 ? (
                <span style={{ display: "hidden" }}></span>
              ) : (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Upgrade elctrical panel:{" "}
                    <Value>{singData.upgrade_electrical_panel} </Value>
                  </p>
                </Grid>
              )}

              {singData.is_ev_charger === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is ev charger:{" "}
                    <Value>{Boolean(singData.is_ev_charger).toString()}</Value>
                  </p>
                </Grid>
              )}
              {singData.is_meter_socket === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is meter socket:{" "}
                    <Value>
                      {Boolean(singData.is_meter_socket).toString()}
                    </Value>
                  </p>
                </Grid>
              )}
              {singData.is_ev_mlo === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is ev mlo:{" "}
                    <Value>{Boolean(singData.is_ev_mlo).toString()}</Value>
                  </p>
                </Grid>
              )}
              {singData.is_sub_panel === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is sub Panel:{" "}
                    <Value>{Boolean(singData.is_sub_panel).toString()} </Value>
                  </p>
                </Grid>
              )}
              {singData.is_mpu_relocation === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is mpu relocation:{" "}
                    <Value>
                      {Boolean(singData.is_mpu_relocation).toString()}
                    </Value>
                  </p>
                </Grid>
              )}
              {singData.number_of_mpu_relocation_foot === 0 ? (
                <span style={{ display: "hidden" }}></span>
              ) : (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Number of mpu relocation foot:{" "}
                    <Value>{singData.number_of_mpu_relocation_foot}</Value>
                  </p>
                </Grid>
              )}

              {singData.is_de_rate === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is de rate:{" "}
                    <Value>{Boolean(singData.is_de_rate).toString()}</Value>
                  </p>
                </Grid>
              )}
              {singData.is_stucco === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    {" "}
                    Is stucco:{" "}
                    <Value>{Boolean(singData.is_stucco).toString()}</Value>
                  </p>
                </Grid>
              )}
              {singData.is_rafter_exchange === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Is rafter exchange:{" "}
                    <Value>
                      {Boolean(singData.is_rafter_exchange).toString()}
                    </Value>
                  </p>
                </Grid>
              )}
              {singData.number_hvac_ft === 0 ? (
                <span style={{ display: "hidden" }}></span>
              ) : (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Number hvac ft: <Value>{singData.number_hvac_ft}</Value>
                  </p>
                </Grid>
              )}
              {singData.number_of_battaries === null ? (
                <span style={{ display: "hidden" }}></span>
              ) : (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Number of batteries:{" "}
                    <Value>{singData.number_of_battaries}</Value>
                  </p>
                </Grid>
              )}

              {singData.is_steep === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    {" "}
                    Is steep:{" "}
                    <Value>{Boolean(singData.is_steep).toString()}</Value>
                  </p>
                </Grid>
              )}
              {singData.roof_number_of_sq === null ? (
                <span style={{ display: "hidden" }}></span>
              ) : (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Roof number of sq:{" "}
                    <Value> {singData.roof_number_of_sq}</Value>
                  </p>
                </Grid>
              )}

              {/* {singData.has_contract === true && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Has contract:{" "}
                    <Value>{Boolean(singData.has_contract).toString()} </Value>
                  </p>
                </Grid>
              )} */}
              {singData.has_contract === true ? (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Has contract:{" "}
                    <Value>{Boolean(singData.has_contract).toString()} </Value>
                  </p>
                </Grid>
              ) : (
                <span style={{ display: "hidden" }}></span>
              )}
              {singData.roof_area === "" ? (
                <div></div>
              ) : (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Roof Area: <Value>{singData.roof_area} sqft </Value>
                  </p>
                </Grid>
              )}
              {singleOpportunity.solar_notes !== null ? (
                <div></div>
              ) : (
                <Grid item xs={12}>
                  <p>
                    Notes: <Value>{singleOpportunity.solar_notes} </Value>
                  </p>
                </Grid>
              )}

              {imageOfDesing === "" ? (
                <div></div>
              ) : (
                <Grid item lg={18} md={18} sm={18} xs={18}>
                  <img
                    style={{
                      width: "100%",
                      borderRadius: "2rem",
                      display: "block",
                      margin: "auto",
                    }}
                    src={imageOfDesing}
                  ></img>
                </Grid>
              )}

              <Grid item lg={6} md={6} sm={12} xs={12}>
                {/* <p>Has contract:  <Value>{(singData.has_contract||"").toString()} </Value></p> */}
              </Grid>

              {/* Utility Provider */}
              <Grid item lg={18} md={18} sm={18} xs={18}>
                <Heading>Utility Provider</Heading>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Title: <Value>{utilityProvider.title}</Value>
                </p>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Rate: <Value> {utilityProvider.rate}</Value>{" "}
                </p>
              </Grid>
              {utilityProvider.discounted_rate === 0 ? (
                <span style={{ display: "hidden" }}></span>
              ) : (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Discount rate:{" "}
                    <Value> {utilityProvider.discounted_rate}</Value>
                  </p>
                </Grid>
              )}

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p>
                  Annual Increase Percentage :
                  <Value> {utilityProvider.annual_increase_percentage}%</Value>
                </p>
              </Grid>
              {utilityProvider.meter_rate === null ? (
                <span style={{ display: "hidden" }}></span>
              ) : (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Meter rate: <Value> {utilityProvider.meter_rate}</Value>
                  </p>
                </Grid>
              )}
              {utilityProvider.rebates === null ? (
                <span style={{ display: "hidden" }}></span>
              ) : (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <p>
                    Rebates: <Value> {utilityProvider.rebates} </Value>
                  </p>
                </Grid>
              )}
              {allLogsInfo.length > 0 && (
                <>
                  <Grid item lg={18} md={18} sm={18} xs={18}>
                    <Heading>Solution Status</Heading>
                  </Grid>

                  <Grid item lg={18} md={18} sm={18} xs={18}>
                    <Wrapper className="wrapper">
                      <ol className="c-timeline">
                        {document_created !== undefined ? (
                          <li className="c-timeline__item">
                            <div
                              className="c-timeline__content_green"
                              style={{}}
                            >
                              <h3 className="c-timeline__title">
                                Document Created{" "}
                              </h3>
                            </div>
                            <time>
                              {new Date(
                                document_created.event_time
                              ).toLocaleDateString("en-US", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              })}
                            </time>
                          </li>
                        ) : (
                          <li className="c-timeline__item">
                            <div className="c-timeline__content">
                              <h3 className="c-timeline__title">
                                Document Created{" "}
                              </h3>
                            </div>
                            <time></time>
                          </li>
                        )}
                        {document_sent !== undefined ? (
                          <li className="c-timeline__item">
                            <div
                              className="c-timeline__content_green"
                              style={{}}
                            >
                              <h3 className="c-timeline__title">
                                Document Sent{" "}
                              </h3>
                            </div>
                            <time>
                              {new Date(
                                document_sent.event_time
                              ).toLocaleDateString("en-US", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              })}
                            </time>
                          </li>
                        ) : (
                          <li className="c-timeline__item">
                            <div className="c-timeline__content">
                              <h3 className="c-timeline__title">
                                Document Sent{" "}
                              </h3>
                            </div>
                            <time></time>
                          </li>
                        )}
                        {document_viewed !== undefined ? (
                          <li className="c-timeline__item">
                            <div
                              className="c-timeline__content_green"
                              style={{}}
                            >
                              <h3 className="c-timeline__title">
                                Document Viewed{" "}
                              </h3>
                            </div>
                            <time>
                              {new Date(
                                document_viewed.event_time
                              ).toLocaleDateString("en-US", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              })}
                            </time>
                          </li>
                        ) : (
                          <li className="c-timeline__item">
                            <div className="c-timeline__content">
                              <h3 className="c-timeline__title">
                                Document Viewed{" "}
                              </h3>
                            </div>
                            <time></time>
                          </li>
                        )}
                        {document_signed !== undefined ? (
                          <li className="c-timeline__item">
                            <div
                              className="c-timeline__content_green"
                              style={{}}
                            >
                              <h3 className="c-timeline__title">
                                Document Signed{" "}
                              </h3>
                            </div>
                            <time>
                              {new Date(
                                document_signed.event_time
                              ).toLocaleDateString("en-US", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              })}
                            </time>
                          </li>
                        ) : (
                          <li className="c-timeline__item">
                            <div className="c-timeline__content">
                              <h3 className="c-timeline__title">
                                Document Signed{" "}
                              </h3>
                            </div>
                            <time></time>
                          </li>
                        )}
                        {document_completed !== undefined ? (
                          <li className="c-timeline__item">
                            <div
                              className="c-timeline__content_green"
                              style={{}}
                            >
                              <h3 className="c-timeline__title">
                                Document Completed{" "}
                              </h3>
                            </div>
                            <time>
                              {new Date(
                                document_completed.event_time
                              ).toLocaleDateString("en-US", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              })}
                            </time>
                          </li>
                        ) : (
                          <li className="c-timeline__item">
                            <div className="c-timeline__content">
                              <h3 className="c-timeline__title">
                                Document Completed{" "}
                              </h3>
                            </div>
                            <time></time>
                          </li>
                        )}
                        {/* {document_completed !== document_completed ? (
                          <li className="c-timeline__item">
                            <div
                              className="c-timeline__content_red"
                              style={{}}
                            >
                              <h3 className="c-timeline__title">
                                financial situation{" "}
                              </h3>
                            </div>
                            <time>
                              {new Date(
                                document_completed.event_time
                              ).toLocaleDateString("en-US", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              })}
                            </time>
                          </li>
                        ) : (
                          <li className="c-timeline__item">
                            <div className="c-timeline__content">
                              <h3 className="c-timeline__title">
                                financial situation{" "}
                              </h3>
                            </div>
                            <time></time>
                          </li>
                        )} */}
                      </ol>
                    </Wrapper>
                  </Grid>
                  <br></br>
                </>
              )}

              {singData?.financial_institution === "goodleap" ? (
                <>
                  <Grid item lg={18} md={18} sm={18} xs={18}>
                    <Heading>Finantial Status</Heading>
                  </Grid>
                  <Grid item lg={18} md={18}>
                    {singData?.goodleap_loan && (
                      <>
                        <p>
                          Status:{" "}
                          <Value> {singData?.goodleap_loan?.status}</Value>{" "}
                        </p>
                      </>
                    )}
                    {stipulations && (
                      <>
                        <table>
                          <tr>
                            <th>name </th>
                            <th>Documents to clear </th>
                          </tr>
                          {stipulations?.map((e, i) => {
                            return (
                              <tr key={i}>
                                <td>
                                  {e.name} <p>Status: {e?.status}</p>
                                  <p style={{ color: "red" }}>
                                    Please Upload one of the following docs.
                                  </p>
                                </td>
                                <td>
                                  {e.documentsToClear?.map((d, index) => {
                                    return (
                                      <p
                                        style={{
                                          padding: ".3rem 0",
                                          fontSize: "15px",
                                        }}
                                        key={index}
                                      >
                                        {d?.name} :{" "}
                                        <Button
                                          variant="contained"
                                          component="label"
                                          style={{
                                            maxWidth: "20px",
                                            maxHeight: "20px",
                                            minWidth: "20px",
                                            minHeight: "20px",
                                          }}
                                          // size="small"
                                        >
                                          <PhotoCamera
                                            style={{ fontSize: "15px" }}
                                          ></PhotoCamera>
                                          <input
                                            hidden
                                            accept="image/*"
                                            multiple
                                            type="file"
                                            onChange={(event) => {
                                              setData({
                                                ...data,
                                                id: stipulations[i]?.id,
                                                file: event.target.files[0],
                                                status: stipulations[i]?.status,
                                                // type: stipulations[i]?.type,
                                                reasonCodes:
                                                  stipulations[i]?.reasonCodes,
                                                type:
                                                  e.documentsToClear[index]
                                                    ?.type || "",
                                              });
                                            }}
                                          />
                                        </Button>
                                        <p
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "13px",
                                          }}
                                        >
                                          {" "}
                                          {d?.description}
                                        </p>
                                      </p>
                                    );
                                  })}
                                </td>
                              </tr>
                            );
                          })}
                        </table>
                      </>
                    )}

                    {/* {glStatus.length > 0 ? (
                      <>
                        {glStatus.map((e) => {
                          return (
                            <>
                              <Grid item lg={18} md={18} sm={18} xs={18}>
                                <Wrapper className="wrapper">
                                  <ol className="c-timeline">
                                    <li className="c-timeline__item">
                                      <div
                                        className="c-timeline__content_green"
                                        style={{}}
                                      >
                                        <h3 className="c-timeline__title">
                                          {e.event}{" "}
                                        </h3>
                                      </div>
                                      <time>
                                        {new Date(
                                          e.created_at
                                        ).toLocaleDateString("en-US", {
                                          day: "2-digit",
                                          month: "2-digit",
                                          year: "numeric",
                                        })}
                                      </time>
                                    </li>
                                  </ol>
                                </Wrapper>
                              </Grid>
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <span> None</span>
                    )} */}
                  </Grid>
                </>
              ) : (
                <span style={{ display: "none" }}></span>
              )}
            </Grid>
          </Grid>
        </Grid>
      </BigSection>
    </div>
  );
};
export default ProposalAllInfo;
