import React from "react";
import { Grid } from "@mui/material";
import Right from "./Right/Right";
import User from "./Left/User/User";
import { Box } from "@mui/system";
import styled from "styled-components";
import Routs from "./Left/Routs/Routs";
import { useSelector } from "react-redux";
import Header from "../components/Header/Header";
import { useDispatch } from "react-redux";
import { loadUser } from "../Redux/Actions/authActions";
import { useEffect } from "react";

const Home = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);

  const { authLogIn } = useSelector((state) => state.auth);
  return (
    <>
      <Header />
      <GridBox>
        <Grid container spacing={2} columns={18}>
          <Grid item xl={13} lg={13} md={18} xs={18}>
            <Box>
              <User />
              <Routs />
            </Box>
          </Grid>
          <Grid item xl={5} lg={5} md={18} xs={18}>
            {authLogIn && <Right />}
          </Grid>
        </Grid>
      </GridBox>
    </>
  );
};

const GridBox = styled.section`
  padding: 2rem 2rem 0 2rem;
  background: #f7f7f7;
  min-height: 94vh;
`;
export default Home;
