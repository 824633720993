import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { GetSingleUser } from "../../../Redux/Actions/EditUserAction";
import TeamCardsUser from "./TeamCards";

const AsignLeadToUser = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const LeadID = location.pathname.split("/")[2];
  useEffect(() => {
    if (LeadID) dispatch(GetSingleUser(LeadID));
  }, [LeadID]);
  const { getMainUser } = useSelector((s) => s.editUser);
  const ChildTeams = getMainUser?.descendants;
  return (
    <>
      <ProfileRightStyle>
        <div className="gridContiner">
          {ChildTeams?.map((child) => (
            <TeamCardsUser key={child.id} Team={child} LeadID={LeadID} />
          ))}
        </div>
      </ProfileRightStyle>
    </>
  );
};

const ProfileRightStyle = styled.section`
  padding: 3rem;
  background: #fefefe;

  .gridContiner {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 2rem;
  }
`;

export default AsignLeadToUser;
