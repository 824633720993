import React, { useEffect, useState } from "react";
import {
  useAddLeadsToBankMutation,
  useGetLeadsBankQuery,
} from "../../../Redux/toolKit/leadsBank";
import { Checkbox, Pagination, Rating, Stack } from "@mui/material";
import { toast } from "react-toastify";

const ShowData = ({ stateID, transactionType }) => {
  const params = { state_id: stateID, transaction_type: transactionType };
  const { data, isLoading, isFetching } = useGetLeadsBankQuery(params);
  const [page, setPage] = useState();
  const [arr, setArr] = useState([]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [addLeadsToBank, { isLoading: addLoading, isSuccess, isError }] =
    useAddLeadsToBankMutation();
  const checkValue = (event) => {
    var updatedList = [...arr];
    if (event.target.checked) {
      updatedList = [...arr, Number(event.target.value)];
    } else {
      updatedList.splice(arr.indexOf(Number(event.target.value)), 1);
    }
    setArr(updatedList);
  };
  useEffect(() => {
    setArr([]);
  }, [transactionType]);
  useEffect(() => {
    if (isSuccess) {
      setArr([]);
      toast.success("Success");
    }
  }, [isSuccess]);

  const mapping = () => {
    return data?.data?.data?.map((item) => {
      return (
        <tr key={item?.id}>
          <td>
            <Checkbox
              size="small"
              onChange={checkValue}
              name={item?.id}
              value={Number(item.id)}
            />
          </td>
          <td>{item?.first_name}</td>
          <td>{item?.last_name}</td>
          <td>
            <Rating name="read-only" value={item?.rate} readOnly />
          </td>
          <td>{item?.price_percentage}</td>
        </tr>
      );
    });
  };
  const checkOut = async () => {
    const sendData = {
      transaction_type: transactionType,
      leads_bank_ids: [...new Set(arr)],
    };
    await addLeadsToBank(sendData);
  };
  if (isFetching) {
    return <div className="text-center">Loading...</div>;
  }
  console.log("arr", arr);
  return (
    <div>
      {data?.data?.data.length > 0 ? (
        <>
          {" "}
          <table>
            <tr>
              <td>select</td>
              <th>first name</th>
              <th>Last Name</th>
              <th>rate</th>
              <th>price</th>
            </tr>
            {mapping()}
          </table>
          <div className="pagination">
            <Pagination
              count={data?.data?.total_pages}
              onChange={handleChangePage}
            />
            <button
              className="submit"
              onClick={checkOut}
              disabled={addLoading ? true : false}
            >
              {addLoading ? "Loading..." : "check out"}
            </button>
          </div>
        </>
      ) : (
        <>
          <h2>No data</h2>
        </>
      )}
    </div>
  );
};

export default ShowData;
