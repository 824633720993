import React from "react";
import { FormControlLabel, FormHelperText, Grid } from "@mui/material";
import { BigSection } from "../firstForm/style";

import {
  adderNames,
  batteriesAdders,
  electricalAdders,
  generatorsAdders,
  hvacsAdders,
  roofingAdders,
} from "./staticData";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Checkbox } from "@mui/material";
import StanderInput from "../../../../../../../components/inputs/standerInput";

const data = [
  { id: "ground_mount", name: "Ground mount", value: "ground_mount" },
  { id: "roof_mount", name: "Roof mount", value: "roof_mount" },
];
const radioList = [
  { id: 1, title: "Yes" },
  { id: 2, title: "No" },
];
const roofTypes = [
  { id: "shingle", name: "shingle", value: "shingle" },
  { id: "spanish", name: "spanish", value: "spanish" },
  { id: "metal", name: "metal", value: "metal" },
  { id: "concrete", name: "concrete", value: "concrete" },
];
const ThirdForm = ({ methods }) => {
  const skip = methods.watch("skip");

  return (
    <BigSection>
      <Grid container columns={12} spacing={3}>
        <>
          <Grid item xs={12}>
            <div>
              <h3>Electrical</h3>
              <hr />
            </div>
          </Grid>
          {electricalAdders?.map((e) => {
            return (
              <Grid item xs={12} md={6} lg={6} key={e?.id}>
                <StanderInput
                  fullWidth
                  id={e?.id}
                  type="number"
                  name={`adders.${e?.name}`}
                  Label={e?.label}
                  defaultValue={0}
                  inputProps={{ min: 0 }}
                />
              </Grid>
            );
          })}
          <Grid item xs={12}>
            <div>
              <h3>Hvacs</h3>
              <hr />
            </div>
          </Grid>
          {hvacsAdders?.map((e) => {
            return (
              <Grid item xs={12} md={6} lg={6} key={e?.id}>
                <StanderInput
                  fullWidth
                  id={e?.id}
                  type="number"
                  name={`adders.${e?.name}`}
                  Label={e?.label}
                  defaultValue={0}
                  inputProps={{ min: 0 }}
                />
              </Grid>
            );
          })}
          <Grid item xs={12}>
            <div>
              <h3>Generators</h3>
              <hr />
            </div>
          </Grid>
          {generatorsAdders?.map((e) => {
            return (
              <Grid item xs={12} md={6} lg={6} key={e?.id}>
                <StanderInput
                  fullWidth
                  id={e?.id}
                  type="number"
                  name={`adders.${e?.name}`}
                  Label={e?.label}
                  defaultValue={0}
                  inputProps={{ min: 0 }}
                />
              </Grid>
            );
          })}
          <Grid item xs={12}>
            <div>
              <h3>Batteries</h3>
              <hr />
            </div>
          </Grid>
          {batteriesAdders?.map((e) => {
            return (
              <Grid item xs={12} md={6} lg={6} key={e?.id}>
                <StanderInput
                  fullWidth
                  id={e?.id}
                  type="number"
                  name={`adders.${e?.name}`}
                  Label={e?.label}
                  defaultValue={0}
                  inputProps={{ min: 0 }}
                />
              </Grid>
            );
          })}{" "}
          <Grid item xs={12}>
            <div>
              <h3>Roofing</h3>
              <hr />
            </div>
          </Grid>
          {roofingAdders?.map((e) => {
            return (
              <Grid item xs={12} md={6} lg={6} key={e?.id}>
                <StanderInput
                  fullWidth
                  id={e?.id}
                  type="number"
                  name={`adders.${e?.name}`}
                  Label={e?.label}
                  defaultValue={0}
                  inputProps={{ min: 0 }}
                />
              </Grid>
            );
          })}{" "}
        </>
      </Grid>
    </BigSection>
  );
};

export default ThirdForm;
