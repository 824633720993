import {
  ADD_SUCCESSFULLY,
  CREATE_CONTRACT,
  DELETE_SOLUTION,
  FEATCH_ERROR,
  HIDE_LOADING,
  POST_SOLAR,
  SEND_CONTRACT_TO_SIGN,
  SEND_MAIL_PROP_NOTIFICATION,
  SHOW_LOADING,
  UPLOAD_SITE_MEDIA,
  UPLOAD_UTILITY_BILL,
  ADDERS
} from "../Actions/type";

const initialState = {
  postSolar: {},
  createContact: {},
  sendEmail: {},
  sendMailProp: {},
  deleteSolution: {},
  uploadUtility: {},
  uploadMedia: {},
  isLoading: false,
  isSuccess: false,
  isError: false,
  totalArea:{},
  screenShot:{},
  all_Adders:{},
  all_roofing:""
};

const ProposalsReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_SOLAR:
      return {
        ...state,
        postSolar: action.solarData,
      };
    case CREATE_CONTRACT:
      return {
        ...state,
        createContact: action.payload.createContact,
      };
    case SEND_CONTRACT_TO_SIGN:
      return {
        ...state,
        sendEmail: action.payload.sendEmail,
      };
    case SEND_MAIL_PROP_NOTIFICATION:
      return {
        ...state,
        sendMailProp: action.payload.sendMailProp,
      };
    case DELETE_SOLUTION:
      return {
        ...state,
        deleteSolution: action.payload.deleteSolution,
      };
    case UPLOAD_UTILITY_BILL:
      return {
        ...state,
        uploadUtility: action.payload.uploadUtility,
      };
    case UPLOAD_SITE_MEDIA:
      return {
        ...state,
        uploadMedia: action.payload.uploadMedia,
      };
    case SHOW_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case HIDE_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case ADD_SUCCESSFULLY:
      return {
        ...state,
        isSuccess: true,
      };
    case FEATCH_ERROR:
      return {
        ...state,
        isError: true,
      };
      case "TOTAL_AREA":
      return {
        ...state,
        totalArea: action.totalArea,
      };
      case "SCREEN_SHOT":
        return {
          ...state,
          screenShot: action. screenShot,
        };
        case "ADDERS":
          return {
            ...state,
            all_Adders: action. all_Adders,
          };
          case "ROOFINGPRICES":
            return {
              ...state,
              all_roofing: action. all_roofing,
            };
    default:
      return { ...state };
  }
};

export default ProposalsReducer;
