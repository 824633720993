export const FATCH_CUSTOMER = "FATCH_CUSTOMER";
export const GET_ZOOM = "GET_ZOOM";
export const POST_ZOOM = "POST_ZOOM";
export const GET_LEAD = "GET_LEAD";
export const GET_SINGLE_LEAD = "GET_SINGLE_LEAD";
export const POST_LEAD = "POST_LEAD";
export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_STATES = "GET_STATES";
export const GET_CITIES = "GET_CITY";
export const POST_LEADS = "POST_LEADS";
export const GET_SERVICES = "GET_SERVICES";
export const GET_SUB_SERVICES = "GET_SUB_SERVICES";
export const GET_Utility = "GET_Utility";
export const GET_ADDS_ON = "GET_ADDS_ON";
export const GET_UTILITY = "GET_UTILITY";
export const POST_SOLAR = "POST_SOLAR";
export const PATCH_USER = "PATCH_USER";
export const POST_FINANCE = "POST_FINANCE";
export const GET_MAIN_USER = "GET_MAIN_USER";
export const POST_CALCOULATOR = "POST_CALCOULATOR";
export const GET_OPPORTUNITY = "GET_OPPORTUNITY";
export const POST_CALC_FINANCE = "POST_CALC_FINANCE";
export const GET_APR_CALC = "GET_APR_CALC";
export const GET_SUNLIGHT_CALC = "GET_SUNLIGHT_CALC";
export const CREATE_PROJECT = "CREATE_PROJECT";
export const SEND_PREQUAL = "SEND_PREQUAL";
export const GET_PREQUAL_PROJECT = "GET_PREQUAL_PROJECT";
export const PULL_STATUS = "PULL_STATUS";
export const SUBMIT_CREDIT = "SUBMIT_CREDIT";
export const ADD_QUOTE = "ADD_QUOTE";
export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";
export const ADD_SUCCESSFULLY = "ADD_SUCCESSFULLY";
export const FEATCH_ERROR = "FEATCH_ERROR";
export const CREATE_CONTRACT = "CREATE_CONTRACT";
export const SEND_CONTRACT_TO_SIGN = "SEND_CONTRACT_TO_SIGN";
export const SEND_MAIL_PROP_NOTIFICATION = "SEND_MAIL_PROP_NOTIFICATION";
export const GET_PANEL_MODEL = "GET_PANEL_MODEL";
export const EDIT_LEAD_PRFILE = "EDIT_LEAD_PRFILE";
export const POST_FORGET_PASSWORD = "POST_FORGET_PASSWORD";
export const POST_RESET_PASSWORD = "POST_RESET_PASSWORD";
export const GET_STATE_ID = "GET_STATE_ID";
export const GET_LEAD_SKIP_AND_TAKE = "GET_LEAD_SKIP_AND_TAKE";
export const ASIGN_LEAD_TO_USER = "ASIGN_LEAD_TO_USER";
export const GET_SINGLE_MEMBER = "GET_SINGLE_MEMBER";
export const GET_SINGLE_LEAD_CHILD = "GET_SINGLE_LEAD_CHILD";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_NOTIFICATIONS_READ = "GET_NOTIFICATIONS_READ";
export const IS_NOTIFICATION = "IS_NOTIFICATION";
export const GET_ROOF_TYPES = "GET_ROOF_TYPES";
export const GET_ROOF_COLORS = "GET_ROOF_COLORS";
export const ARCHIVE_LEAD = "ARCHIVE_LEAD";
export const GET_LEAD_ARCHIVE = "GET_LEAD_ARCHIVE";
export const DELETE_SOLUTION = "DELETE_SOLUTION";
export const REGISTER_USER = "REGISTER_USER";
export const GET_USER_ROLS = "GET_USER_ROLS";
export const UPLOAD_SITE_MEDIA = "UPLOAD_SITE_MEDIA";
export const UPLOAD_UTILITY_BILL = "UPLOAD_UTILITY_BILL";
export const ADD_GOOGLE_EVENT = "ADD_GOOGLE_EVENT";
export const GET_UNSEEN_MESSAGE = "GET_UNSEEN_MESSAGE";
export const GET_ALL_MESSAGES = `GET_ALL_MESSAGES`;
export const GET_ALL_DEPARTMENT = "GET_ALL_DEPARTMENT";
export const GET_ALL_DEPARTMENT_USER = "GET_ALL_DEPARTMENT_USER";
export const GET_SINGLE_MESSAGES = `GET_SINGLE_MESSAGES`;
export const CHANGE_STATUS_CHAT = `CHANGE_STATUS_CHAT`;
export const ADDERS = "ADDERS";

export const ACHIEVEMENT = "ACHIEVEMENT";
export const PARENT_ACHIEVEMENT = "PARENT_ACHIEVEMENT";
export const SALES_ACHIEVEMENT = "SALES_ACHIEVEMENT";
export const GET_ACHIEVEMENT = "GET_ACHIEVEMENT";
export const EDIT_ACHIEVEMENT = "EDIT_ACHIEVEMENT";

export const CREATE_LICENSE = "CREATE_LICENSE";
export const GET_LICENSE = "GET_LICENSE";
