import axios from "axios";
import swal from "sweetalert";
import { EDIT_LEAD_PROFILE_URL } from "../../API/Api";
import { EDIT_LEAD_PRFILE } from "./type";
import {ShowLoading,HideLoading} from "./loadingAction"
import { useDispatch } from "react-redux";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";


export const editLeadAction = (id, data) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    await axios({
      method: "PATCH",
      baseURL: `${EDIT_LEAD_PROFILE_URL}/${id}`,
      data: data,
      headers: headers,
    })
      .then((res) => {
        swal({
          icon: "success",
          title:"Lead has been edited"
        });
        dispatch({
          type: EDIT_LEAD_PRFILE,
          editLead: res.data,
        });
        // navigate(`/profilelead/${LeadID}`);
        // if (res.status === 201) {
        //   dispatch({
        //     type: "POST_LEADS_SUCSESS",
        //     status: res.status,
        //   });
        // }
      })
      .catch((err) => {
        let errors
        for (const property in err.response.data.error){ 
          errors=`${property}: ${err.response.data.error[property]}`;
          }
        swal({
          icon: "error",
          title:"Lead has not been edited",
          text:`${errors}`
        });
        console.log("err.response.data",err.response.data.error)
        // if (err.response.data.error) {
        //   dispatch({
        //     type: "POST_LEAD_ERROR",
        //     error: err.response.data.error,
        //   });
        // }
        // swal({
        //   title: " error ",
        //   text: " There is a problem when Edit the Lead ",
        //   icon: "error",
        //   button: "OK",
        // });
      });
  };
};
